import React, { useMemo, useRef, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { ContentIdentifier } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/ContentIdentifier';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

// LIBRARY
import { DropdownV2 } from '@warehouse/shared/ui';
import WillClearFieldsConfirmModal from '../../../../../../../components/titles/WillClearFieldsConfirmModal';

// JSON
import workTypesJSON from '../../../../../../../assets/json-administration-profiles/workTypes.json';
import { CellWrapper } from '../../../../../../../components/library/SimpleTable/style';

export default function CellScope({
	getValue,
	table,
}: CellContext<Inherited<ContentIdentifier>, Inherited<ContentIdentifier>>) {
	const { displayValue } = getValue();
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;
	const [openModal, setOpenModal] = useState(false);
	const updateValueRef = useRef<string>('');

	const options = useMemo(
		() =>
			workTypesJSON.map(({ label, value }) => ({
				label,
				value,
			})),
		[workTypesJSON],
	);

	const setValueInRecord = (value: string) => {
		editRecord({
			...getValue(),
			displayValue: {
				...getValue().displayValue,
				scope: {
					scope: value,
				},
			},
		});
	};

	const handleChange = (value: string) => {
		const { displayValue: dv } = getValue();
		if (dv.scope?.subscope === undefined || dv.scope?.subscope.trim() === '') {
			setValueInRecord(value);
		} else {
			updateValueRef.current = value;
			setOpenModal(true);
		}
	};

	return (
		<>
			<CellWrapper>
				<DropdownV2
					options={options}
					value={displayValue.scope?.scope}
					onChange={handleChange}
					transparentBorder
					backgroundColor="transparent"
				/>
			</CellWrapper>
			<WillClearFieldsConfirmModal
				open={openModal}
				handleClose={() => setOpenModal(false)}
				onConfirm={() => {
					setValueInRecord(updateValueRef.current);
					setOpenModal(false);
				}}
			/>
		</>
	);
}
