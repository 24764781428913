import React from 'react';
import { Control, FieldErrors, UseFieldArrayAppend, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';
import { Locale } from '../../localeType';

// LIBRARIES
import CountriesOfOriginField from '../fields/CountriesOfOrigin';
import ReleaseDate from '../fields/ReleaseDate';
import OriginalLanguagesField from '../fields/OriginalLanguages';
import RunLengthField from '../fields/RunLength';
import SortTitleField from '../fields/SortTitle';
import { OriginalLanguageCreationType } from '../OriginalLanguagesTable';

// STYLES
import { Row } from './styles';
import MadeForRegionField from '../fields/MadeForRegion';

interface SeasonProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	originalLanguages: OriginalLanguageCreationType[];
	appendOriginalLanguage: UseFieldArrayAppend<any, 'originalLanguages'>;
	inheritedValues?: Title | undefined;
	defaultLocale?: Locale;
}

function Season({
	control,
	errors,
	setValue,
	trigger,
	originalLanguages,
	appendOriginalLanguage,
	inheritedValues,
	defaultLocale,
}: SeasonProps) {
	return (
		<div>
			<Row>
				<CountriesOfOriginField
					control={control}
					setValue={setValue}
					trigger={trigger}
					isRequired
					errors={errors}
					inheritedValues={inheritedValues}
				/>
				<ReleaseDate
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					inheritedValues={inheritedValues}
					isRequired
				/>
			</Row>
			<Row>
				<SortTitleField
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					inheritedValues={inheritedValues}
				/>
				<RunLengthField
					control={control}
					setValue={setValue}
					isRequired
					trigger={trigger}
					inheritedValues={inheritedValues}
					errors={errors}
				/>
			</Row>
			<Row>
				<MadeForRegionField
					control={control}
					setValue={setValue}
					trigger={trigger}
					inheritedValues={inheritedValues}
					errors={errors}
				/>
			</Row>
			<OriginalLanguagesField
				control={control}
				setValue={setValue}
				defaultLocale={defaultLocale}
				trigger={trigger}
				errors={errors}
				appendOriginalLanguage={appendOriginalLanguage}
				isRequired
				inheritedValues={inheritedValues}
				originalLanguages={originalLanguages}
			/>
		</div>
	);
}

export default Season;
