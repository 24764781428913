import React, { RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { mdiInformation } from '@mdi/js';
import { parse } from 'iso8601-duration';
import {
	IconWrapper,
	InputIcons,
	Label,
	ReadOnlyValue,
	SubMessageLayoutWrapper,
	TooltipIcon,
	Wrapper,
	LabelBlockWrapper,
} from './InputUtils';
import Tooltip from './Tooltip';
import { PlaceHolderText } from './DropdownUtils';
import InputError, { WrapperInputError } from './InputError';
import isSafari from '../../utils/isSafari';

export type TimeInputVariant = 'default' | 'prefilled';

export interface TimeInputProps {
	label?: string;
	tooltip?: string;
	inlineLabel?: boolean;
	value?: number;
	error?: string;
	onChange: (value: number) => void;
	readOnly?: boolean;
	disabled?: boolean;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	className?: string;
	height?: number;
	transparentBorder?: boolean;
	placeholder?: string;
	labelIcon?: React.ReactNode;
	disabledMargin?: boolean;
	fullWidth?: boolean;
	onBlur?: () => void;
	variant?: TimeInputVariant;
}

type InputWrapperProps = {
	error?: string;
	disabled?: boolean;
	height?: number;
	transparentBorder?: boolean;
};

const ArrowsWrapper = styled.div(
	({ theme }) => css`
		display: none;
		flex-direction: column;
		justify-content: center;

		svg {
			color: ${theme.palette.blue.main};
			height: 14px;
		}
	`,
);

const InputWrapper = styled.div<InputWrapperProps>(
	({ theme, error, disabled, height, transparentBorder }) => css`
		align-items: stretch;
		background-color: ${theme.palette.light.background};
		border: 1px solid ${transparentBorder ? 'transparent' : theme.palette.light.backgroundAlt};
		border-radius: 8px;
		box-sizing: border-box;
		input {
			color: ${theme.palette.text.secondary};
		}
		cursor: pointer;
		display: flex;
		flex-grow: 1;
		font-size: 14px;
		height: ${height ? `${height}px` : '48px'};
		justify-content: space-between;
		position: relative;

		//Hiding Number input arrows
		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type='number'] {
			-moz-appearance: textfield;
		}

		&:hover {
			background-color: ${theme.palette.light.main};
			border-color: ${theme.palette.blue.main};

			input {
				color: ${theme.palette.text.primary};
			}
			${IconWrapper} {
				color: ${theme.palette.light.backgroundAlt};
			}

			${!disabled &&
			css`
				.editIcon {
					display: block !important;
				}
			`}
		}

		&:focus-within {
			background-color: ${theme.palette.light.main};
			border-color: ${theme.palette.blue.main};
			${IconWrapper} {
				color: ${theme.palette.light.backgroundAlt};
			}

			input {
				background-color: ${theme.palette.blue.background};
			}

			${!disabled &&
			css`
				${ArrowsWrapper} {
					display: flex;
				}
			`}
		}

		:focus-visible {
			outline: none;
		}

		${error && !disabled
			? css`
					background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
					border-bottom-right-radius: 0;
					border-color: ${theme.palette.error.main} !important;
					color: ${theme.palette.error.text};

					input {
						color: ${theme.palette.error.text};
					}
			  `
			: null}

		${disabled &&
		css`
			background: none !important;
			border: 1px solid ${theme.palette.light.backgroundAlt} !important;
			cursor: not-allowed;

			input {
				color: ${theme.palette.action.disabled};
				cursor: not-allowed;
			}
		`}
	`,
);

const TimeInputsWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		color: ${theme.palette.text.secondary};
		display: flex;
		padding: ${theme.spacing(1)} ${theme.spacing(1)};

		input,
		span {
			vertical-align: middle;
			margin: 0;
			padding: 1px 0 0 0;
			font-size: 14px;
			line-height: 1.5;
			box-sizing: border-box;
		}

		&:focus-within {
			align-items: center;

			input {
				align-self: stretch;
				margin: 0 1px;
				width: 25px;
			}
		}
	`,
);

const Input = styled.input<{
	height?: number;
	width: number;
}>(
	({ theme, width, height }) => css`
		background: none;
		border: none;
		border-radius: 4px;
		font-size: 16px;
		font-style: inherit;
		line-height: 1.5;
		margin: 0;
		padding: ${theme.spacing(1)} 0;
		text-align: center;

		${height &&
		css`
			line-height: ${height}px;
			padding: 0;
		`}

		&::placeholder {
			color: ${theme.palette.action.placeholder};
		}

		&:focus-visible {
			outline: none;
		}

		width: ${width}px !important;
	`,
);

export function IBMDurationFormatToSeconds(duration: string | undefined): number {
	const matches = duration?.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/);
	if (!matches) {
		return 0;
	}

	const hours = matches[1] ? parseInt(matches[1], 10) : 0;
	const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
	const seconds = matches[3] ? parseInt(matches[3], 10) : 0;

	return hours * 3600 + minutes * 60 + seconds;
}

export function secondsToIBMDurationFormat(totalSeconds: number): string {
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
	const seconds = totalSeconds - hours * 3600 - minutes * 60;

	const parts = [hours > 0 ? `${hours}H` : null, minutes > 0 || hours > 0 ? `${minutes}M` : null, `${seconds}S`].filter(
		Boolean,
	);

	return `PT${parts.join('')}`;
}

export function EIDRtoTime(eidr: string | undefined): string | undefined {
	if (!eidr) return undefined;
	try {
		const parsed = parse(eidr);
		const parts = [];
		if (parsed.hours) parts.push(`${parsed.hours}h`);
		if (parsed.minutes || (parsed.hours && parsed.seconds)) parts.push(`${parsed.minutes}m`);
		if (parsed.seconds || (!parsed.hours && !parsed.minutes)) parts.push(`${parsed.seconds}s`);
		return parts.join(' ')?.trim();
	} catch (e) {
		return undefined;
	}
}

export function secondsToTime(seconds: number): string {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const secs = Math.floor(seconds % 60);

	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');
	const formattedSeconds = secs.toString().padStart(2, '0');

	return `${formattedHours}H ${formattedMinutes}M ${formattedSeconds}S`;
}

function computeInputWidth(inputValue: number, ref: RefObject<HTMLDivElement>, isFocused: boolean | null): number {
	const focusPadding = isFocused ? 8 : 2;
	if (ref.current && inputValue > 99) {
		const { width } = ref.current.getBoundingClientRect();
		return width + focusPadding;
	}
	return 18 + focusPadding;
}

function handleMinMax(value: number) {
	return Math.min(9999, Math.max(0, value));
}

function TimeInput(props: TimeInputProps) {
	const {
		label,
		inlineLabel,
		readOnly,
		disabled,
		value,
		onChange,
		error,
		className,
		startIcon,
		endIcon,
		tooltip,
		height,
		transparentBorder,
		placeholder,
		labelIcon,
		variant = 'default',
		fullWidth,
		disabledMargin,
		onBlur,
		...rest
	} = props;
	const [secondsValue, setSecondsValue] = useState<number>(value ? value % 60 : 0);
	const [minutesValue, setMinutesValue] = useState<number>(value ? Math.floor((value % 3600) / 60) : 0);
	const [hoursValue, setHoursValue] = useState<number>(value ? Math.floor(value / 3600) : 0);
	const lastEmittedValueRef = useRef(-1);
	const [focusedInput, setFocusedInput] = useState<'hours' | 'minutes' | 'seconds' | null>(null);
	const [showInputs, setShowInputs] = useState<boolean>(Boolean(value));

	const hoursRef = useRef<HTMLInputElement>(null);
	const ghostHoursRef = useRef<HTMLDivElement>(null);
	const minutesRef = useRef<HTMLInputElement>(null);
	const ghostMinutesRef = useRef<HTMLDivElement>(null);
	const secondsRef = useRef<HTMLInputElement>(null);
	const ghostSecondsRef = useRef<HTMLDivElement>(null);

	const handleSecondsChange = (newSecondsValue: number) => {
		setSecondsValue(newSecondsValue);
		const newValue = hoursValue * 3600 + minutesValue * 60 + newSecondsValue;
		if (newValue !== value && newValue !== lastEmittedValueRef.current) {
			onChange(newValue);
			lastEmittedValueRef.current = newValue;
		}
	};

	const handleMinutesChange = (newMinutesValue: number) => {
		setMinutesValue(newMinutesValue);
		const newValue = hoursValue * 3600 + newMinutesValue * 60 + secondsValue;
		if (newValue !== value && newValue !== lastEmittedValueRef.current) {
			onChange(newValue);
			lastEmittedValueRef.current = newValue;
		}
	};

	const handleHoursChange = (newHoursValue: number) => {
		setHoursValue(newHoursValue);
		const newValue = newHoursValue * 3600 + minutesValue * 60 + secondsValue;
		if (newValue !== value && newValue !== lastEmittedValueRef.current) {
			onChange(newValue);
			lastEmittedValueRef.current = newValue;
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			const isArrowButtonClick = target.closest('.arrow-up') || target.closest('.arrow-down');

			if (
				!hoursRef.current?.contains(target) &&
				!minutesRef.current?.contains(target) &&
				!secondsRef.current?.contains(target) &&
				!isArrowButtonClick
			) {
				setFocusedInput(null);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	// Sync the input values with the value prop
	useEffect(() => {
		if (value !== lastEmittedValueRef.current) {
			setHoursValue(value ? Math.floor(value / 3600) : 0);
			setMinutesValue(value ? Math.floor((value % 3600) / 60) : 0);
			setSecondsValue(value ? value % 60 : 0);
		}
	}, [value]);

	const formatTimeValue = (timeValue: string, leadingZero: boolean) => {
		if (leadingZero) {
			return timeValue.replace(/^0+(?!$)/, '');
		}
		return timeValue.padStart(2, '0');
	};

	const formattedString = `${formatTimeValue(hoursValue.toString(), false)} H ${formatTimeValue(
		minutesValue.toString(),
		false,
	)} M ${formatTimeValue(secondsValue.toString(), false)} S`;

	const setCaretToEndOfInput = (focusedInputRef: RefObject<HTMLInputElement>) => {
		setTimeout(() => {
			focusedInputRef?.current?.focus();
			focusedInputRef?.current?.setSelectionRange(
				focusedInputRef?.current?.value?.length,
				focusedInputRef?.current?.value?.length,
			);
		}, 30);
	};

	const addTimeToFocusedInput = (valueToAdd: number) => {
		if (focusedInput === 'hours') {
			handleHoursChange(handleMinMax(hoursValue + valueToAdd));
			setCaretToEndOfInput(hoursRef);
		} else if (focusedInput === 'minutes') {
			handleMinutesChange(handleMinMax(minutesValue + valueToAdd));
			setCaretToEndOfInput(minutesRef);
		} else if (focusedInput === 'seconds') {
			handleSecondsChange(handleMinMax(secondsValue + valueToAdd));
			setCaretToEndOfInput(secondsRef);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		let valueToAdd = 0;
		if (e.key === 'ArrowUp') {
			valueToAdd = 1;
		} else if (e.key === 'ArrowDown') {
			valueToAdd = -1;
		}

		if (valueToAdd !== 0) {
			e.preventDefault();
			addTimeToFocusedInput(valueToAdd);
		}
	};

	const onFocus = (_focusedInput: 'hours' | 'minutes' | 'seconds', focusedInputRef: RefObject<HTMLInputElement>) => {
		setFocusedInput(_focusedInput);
		setCaretToEndOfInput(focusedInputRef);
	};

	const parseNumberOrZero = (_value: string | undefined): number => {
		if (!_value) return 0;
		const parsed = parseInt(_value, 10);
		return Number.isNaN(parsed) ? 0 : parsed;
	};

	return (
		<Wrapper
			disabledMargin={disabledMargin}
			inlineLabel={inlineLabel}
			disabled={disabled}
			className={className}
			fullWidth={fullWidth}
		>
			<WrapperInputError>
				{label && (
					<Label variant="buttonLargeMedium" error={error}>
						<>
							<LabelBlockWrapper>
								{label}
								{tooltip && (
									<Tooltip title={tooltip} placement="right">
										<TooltipIcon path={mdiInformation} size="16px" />
									</Tooltip>
								)}
							</LabelBlockWrapper>
							{labelIcon}
						</>
					</Label>
				)}
				{readOnly ? (
					<ReadOnlyValue>{formattedString || ''}</ReadOnlyValue>
				) : (
					<SubMessageLayoutWrapper>
						<InputWrapper
							error={error}
							{...rest}
							disabled={disabled}
							className={classNames({ inherited: variant === 'prefilled' }, 'input-wrapper')}
							height={height}
							transparentBorder={transparentBorder}
							onBlur={(event) => {
								if (event.currentTarget.contains(event.relatedTarget)) {
									return;
								}
								if (onBlur) {
									onBlur();
								}
							}}
						>
							<TimeInputsWrapper>
								{startIcon && <IconWrapper $start>{startIcon}</IconWrapper>}
								{!showInputs && placeholder ? (
									<PlaceHolderText onClick={() => setShowInputs(true)}>{placeholder}</PlaceHolderText>
								) : (
									<>
										<Input
											width={computeInputWidth(hoursValue, ghostHoursRef, focusedInput !== null)}
											type="text"
											aria-label="Hours"
											height={height}
											disabled={disabled}
											value={formatTimeValue(hoursValue.toString(), focusedInput !== null)}
											min={0}
											max={9999}
											ref={hoursRef}
											onFocus={() => onFocus('hours', hoursRef)}
											onChange={(e) => {
												const newHoursValue = handleMinMax(parseNumberOrZero(e.target.value));
												handleHoursChange(newHoursValue);
											}}
											onClick={(e) => e.stopPropagation()}
											onKeyDown={handleKeyDown}
										/>
										<div
											ref={ghostHoursRef}
											style={{
												position: 'absolute',
												top: 0,
												visibility: 'hidden',
											}}
										>
											{formatTimeValue(hoursValue.toString(), focusedInput !== null)}
										</div>
										<span
											style={{
												marginTop: isSafari() ? '2px' : undefined,
											}}
										>
											H
										</span>
										<Input
											width={computeInputWidth(minutesValue, ghostMinutesRef, focusedInput !== null)}
											type="text"
											height={height}
											aria-label="Minutes"
											value={formatTimeValue(minutesValue.toString(), focusedInput !== null)}
											disabled={disabled}
											min={0}
											max={9999}
											ref={minutesRef}
											onFocus={() => onFocus('minutes', minutesRef)}
											onChange={(e) => {
												const newMinutesValue = handleMinMax(parseNumberOrZero(e.target.value));
												handleMinutesChange(newMinutesValue);
											}}
											onClick={(e) => e.stopPropagation()}
											onKeyDown={handleKeyDown}
										/>
										<div
											ref={ghostMinutesRef}
											style={{
												position: 'absolute',
												top: 0,
												visibility: 'hidden',
											}}
										>
											{formatTimeValue(minutesValue.toString(), focusedInput !== null)}
										</div>
										<span
											style={{
												marginTop: isSafari() ? '2px' : undefined,
											}}
										>
											M
										</span>
										<Input
											width={computeInputWidth(secondsValue, ghostSecondsRef, focusedInput !== null)}
											type="text"
											aria-label="Seconds"
											height={height}
											value={formatTimeValue(secondsValue.toString(), focusedInput !== null)}
											disabled={disabled}
											ref={secondsRef}
											onBlur={() => setFocusedInput(null)}
											onFocus={() => onFocus('seconds', secondsRef)}
											onChange={(e) => {
												const newSecondsValue = handleMinMax(parseNumberOrZero(e.target.value));
												handleSecondsChange(newSecondsValue);
											}}
											min={0}
											max={9999}
											onClick={(e) => e.stopPropagation()}
											onKeyDown={handleKeyDown}
										/>
										<div
											ref={ghostSecondsRef}
											style={{
												position: 'absolute',
												top: 0,
												visibility: 'hidden',
											}}
										>
											{formatTimeValue(secondsValue.toString(), focusedInput !== null)}
										</div>
										<span
											style={{
												marginTop: isSafari() ? '2px' : undefined,
											}}
										>
											S
										</span>
									</>
								)}
							</TimeInputsWrapper>

							<InputIcons>
								<ArrowsWrapper>
									<KeyboardArrowUpIcon
										className="arrow-up"
										onMouseDown={(e) => {
											e.preventDefault();
											setFocusedInput(focusedInput);
											addTimeToFocusedInput(1);
										}}
									/>
									<KeyboardArrowDownIcon
										className="arrow-down"
										onMouseDown={(e) => {
											e.preventDefault();
											setFocusedInput(focusedInput);
											addTimeToFocusedInput(-1);
										}}
									/>
								</ArrowsWrapper>
							</InputIcons>
							{endIcon && <IconWrapper>{endIcon}</IconWrapper>}
						</InputWrapper>
						{error ? <InputError message={error} /> : null}
					</SubMessageLayoutWrapper>
				)}
			</WrapperInputError>
		</Wrapper>
	);
}

export default TimeInput;
