import { CellContext } from '@tanstack/react-table';
import { CustomColumnDef } from '../../../../src/components/library/SimpleTable/customColumnDef';
import { ChildrenColumnType } from '../children-compilation-extended.model';
import { EntryNumberEditCell } from '../inline-edit/entry-number/EntryNumberEditCell';

export function EntryNumberColumn<T extends ChildrenColumnType>(): CustomColumnDef<T, T> {
	return {
		header: 'Entry Number',
		id: 'entryNumber',
		accessorFn: (row) => row,
		renderReadOnly: (row) => ('entryNumber' in row ? row.entryNumber : ''),
		width: 150,
		highlightOptions: {
			highlight: true,
			onlyOnEditMode: true,
		},
		cell: (info: CellContext<T, T>) => EntryNumberEditCell(info),
	};
}

export function EntryClassColumn<T extends ChildrenColumnType>(): CustomColumnDef<T, string> {
	return {
		header: 'Entry Class',
		id: 'entryClass',
		accessorFn: (row) => ('entryClass' in row ? row.entryClass : ''),
		renderReadOnly: (v) => v,
		editable: false,
	};
}
