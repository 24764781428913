import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { TableTextInput } from '../../style';
import { CastAndCrewContext } from '../providers/castAndCrewProvider';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

interface TextInputCellProps {
	info: CellContext<any, any>;
	width?: number;
	pathPrefix?: string;
	ariaLabel?: string;
	autoFocusOnEmpty?: boolean;
}

function TextInputCell({ info, width, pathPrefix, ariaLabel, autoFocusOnEmpty }: TextInputCellProps) {
	const { editData, isInherited } = useContext(CastAndCrewContext);
	const { getValue, column, row } = info;
	const initialValue = getValue();
	const [value, setValue] = useState<string>(initialValue);

	useEffect(() => {
		setValue(getValue());
	}, [getValue()]);

	const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
		setValue(evt.target.value);
	};

	const onBlur = useCallback(() => {
		editData(`${pathPrefix}.${row.index}.${column.id}`, value?.trim(), true);
	}, [row.index, editData, value]);

	return useMemo(
		() => (
			<CellWrapper>
				<TableTextInput
					variant={isInherited ? 'prefilled' : 'default'}
					autoFocus={autoFocusOnEmpty && !initialValue}
					aria-label={ariaLabel}
					width={width}
					onBlur={onBlur}
					value={value ?? ''}
					onChange={onChange}
				/>
			</CellWrapper>
		),
		[onBlur, value, initialValue, row.index],
	);
}

// eslint-disable-next-line import/no-default-export
export default TextInputCell;
