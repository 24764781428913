import React from 'react';
import styled from 'styled-components';
import { TitleCard } from '@warehouse/title/ui';
import { TitleFull } from '@warehouse/title/core';
import { Label } from '../../../../components/library/DropdownUtils';
import TitleTooltip from '../../../../components/titles/TitleTooltip';

export interface ParentTitleCardProps {
	selectedParentTitle?: TitleFull;
}

export const TitleCardWrapper = styled.div`
	flex: 1;
`;

export function ParentTitleCard({ selectedParentTitle }: ParentTitleCardProps) {
	const tooltipPath = 'coreMetadata.basic.parent';
	if (!selectedParentTitle?.readonly?.titleSearchDisplayNameFullHTML) return null;

	return (
		<TitleCardWrapper>
			<Label variant="buttonLargeMedium" color="text.secondary">
				Parent Title
				<TitleTooltip path={tooltipPath} />
			</Label>
			<TitleCard variant="colored" isLink={false} titleLight={selectedParentTitle.readonly} />
		</TitleCardWrapper>
	);
}
