import { defineStore } from '@warehouse/shared/util';
import { RelationshipType } from '@warehouse/title/core';

interface AddChildrenStore {
	searchValue: string;
	workTypeUuids: string[] | null;
	licensorDisplayName?: string;
	selectedWorkTypeUuid: string | null;
	relationshipType: RelationshipType | null;
	actions: {
		setSearchValue(searchValue: string): void;
		setWorkTypeUuids(workTypeUuids: string[] | null): void;
		setLicensorDisplayName(licensorDisplayName: string): void;
		setSelectedWorkTypeUuid(selectedWorkTypeUuid: string | null): void;
		setRelationshipType(relationshipType: RelationshipType | null): void;
	};
}

export const [AddChildrenStoreProvider, useAddChildrenStore] = defineStore<AddChildrenStore>((set) => ({
	searchValue: '',
	workTypeUuids: null,
	licensorDisplayName: undefined,
	selectedWorkTypeUuid: null,
	relationshipType: null,
	actions: {
		setSearchValue: (searchValue) => set({ searchValue }),
		setSelectedWorkTypeUuid: (selectedWorkTypeUuid) => set({ selectedWorkTypeUuid }),
		setWorkTypeUuids: (workTypeUuids) => set({ workTypeUuids }),
		setLicensorDisplayName: (licensorDisplayName) => set({ licensorDisplayName }),
		setRelationshipType: (relationshipType) => set({ relationshipType }),
	},
}));

export const addChildrenStoreSelector = {
	actions: (state: AddChildrenStore) => state.actions,
	selectedWorkTypeUuid: (state: AddChildrenStore) => state.selectedWorkTypeUuid,
	searchValue: (state: AddChildrenStore) => state.searchValue,
	workTypeUuids: (state: AddChildrenStore) => state.workTypeUuids,
	licensorDisplayName: (state: AddChildrenStore) => state.licensorDisplayName,
	relationshipType: (state: AddChildrenStore) => state.relationshipType,
};
