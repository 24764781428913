import React, { useContext, useMemo } from 'react';
import { CellContext } from '@tanstack/react-table';
import { DisplayString } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/TitleMetadata';
import { DropdownV2 } from '@warehouse/shared/ui';
import { CompanyDisplayCreditContext } from '../providers/companyDisplayCreditsProvider';
import WrittenLanguages from '../../../../../../assets/json-administration-profiles/availableLanguageWritten.json';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

interface CellLanguageProps {
	info: CellContext<DisplayString, string>;
	companyDisplayCreditDisplayIndex: number;
}

export default function CellLanguage({ info, companyDisplayCreditDisplayIndex }: CellLanguageProps) {
	const companyDisplayCreditContext = useContext(CompanyDisplayCreditContext);
	const { isInherited } = companyDisplayCreditContext;

	const currentUsedLocal = useMemo(
		() => (info.table.options.data ? info.table.options.data.map(({ language }) => language ?? '') : []),
		[info.table.options.data],
	);

	const options = useMemo(
		() =>
			WrittenLanguages.filter(({ uuid }) => (uuid === info.getValue() ? true : !currentUsedLocal.includes(uuid))).map(
				({ uuid, label }) => ({ value: uuid, label }),
			),
		[WrittenLanguages, currentUsedLocal, info.getValue()],
	);

	return (
		<CellWrapper>
			<DropdownV2
				variant={isInherited ? 'prefilled' : 'default'}
				transparentBorder
				backgroundColor="transparent"
				options={options}
				value={info.getValue()}
				withSearch
				onChange={(e) => {
					companyDisplayCreditContext.editLanguage(e, info.row.index, companyDisplayCreditDisplayIndex);
					companyDisplayCreditContext.commit();
				}}
			/>
		</CellWrapper>
	);
}
