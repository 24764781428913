import React, { useState } from 'react';
import { CharacterInfo } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/People';
import { v4 as uuid } from 'uuid';
import CollapsableCard from '../../../../../../components/titles/CollapsableCard';
import CharacterCard from './CharacterCard';
import AddCharacterButton from './AddCharacterButton';

interface CharactersListProps {
	characters: CharacterInfo[] | undefined;
	peopleIndex: number;
	roleIndex: number;
}

export default function CharactersList({ peopleIndex, roleIndex, characters }: CharactersListProps) {
	const [uuids, setUuids] = useState<string[]>(characters?.map(() => uuid()) ?? []);

	const list = characters?.map((character, idx) => (
		<CharacterCard
			setUuids={setUuids}
			key={uuids[idx]}
			characterInfo={character}
			peopleIndex={peopleIndex}
			roleIndex={roleIndex}
			characterIndex={idx}
		/>
	));

	return (
		<CollapsableCard label="Characters">
			{list?.length && list.length > 0 ? list : null}
			<AddCharacterButton setUuids={setUuids} peopleIndex={peopleIndex} roleIndex={roleIndex} />
		</CollapsableCard>
	);
}
