import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';

export const TopWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);

export const BackgroundColorOpacity = styled.div<{
	color: 'red' | 'blue' | 'orange';
}>(
	({ theme, color }) => css`
		${color === 'orange' &&
		css`
			background-color: ${alpha(theme.palette.primary.main, 0.5)};
		`}
		${color === 'red' &&
		css`
			background-color: ${alpha('#8C271E', 0.5)};
		`}
    ${color === 'blue' &&
		css`
			background-color: ${alpha('#2196F3', 0.5)};
		`}
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		height: ${theme.spacing(2)};
		width: 100%;
	`,
);

export const Wrapper = styled.div(
	({ theme }) => css`
		align-items: stretch;
		background-color: ${theme.palette.light.main};
		border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		display: flex;
		max-height: 384px;
		padding: 0;
		width: 100%;
	`,
);

export const TopBarWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		border-right: 1px solid ${theme.palette.light.backgroundAlt};
		box-sizing: border-box;
		display: flex;
		grid-area: topbar;
		padding: 0 ${theme.spacing(2)};
		width: 100%;

		> div {
			box-sizing: border-box;
			width: 100%;
		}
	`,
);

export const CollapsedWrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: 0 0 8px 8px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		width: 100%;
	`,
);

export const ActionsWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;

		> div[role='group'] {
			margin-left: ${theme.spacing(1)};
		}
		> button {
			margin-left: ${theme.spacing(2)};
		}
	`,
);

export const CollapseAction = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		height: 16px;
		justify-content: center;
	`,
);

export const RightWrapper = styled.div<{ displayVideoPlayer?: boolean }>(
	({ theme, displayVideoPlayer }) => css`
		background-color: ${theme.palette.light.background};
		display: flex;
		gap: 40px;
		padding: ${theme.spacing(2)};

		${displayVideoPlayer
			? css`
					width: 40%;
			  `
			: css``}
	`,
);

export const PlaybackWrapper = styled.div(
	({ theme }) => css`
		align-items: stretch;
		align-self: center;
		background-color: ${theme.palette.light.main};
		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		padding: ${theme.spacing(4)} ${theme.spacing(2)};
		text-align: center;
		width: 60%;

		> div {
			height: 100%;
			width: 100%;
		}
	`,
);

export const VideoWrapper = styled.div(
	() => css`
		align-items: stretch;
		align-self: center;
		border-radius: 6px;
		display: flex;
		max-height: 320px;
		overflow: hidden;
		position: relative;
		width: 60%;

		button {
			position: absolute;
			z-index: 20;
			top: 4px;
			left: 0;
		}

		> div {
			height: 100%;
			max-height: 320px;
			width: 100%;
		}
	`,
);

export const PosterWrapper = styled.div<{ displayVideoPlayer?: boolean }>(
	({ displayVideoPlayer }) => css`
		${displayVideoPlayer
			? css`
					width: 40%;
			  `
			: css`
					min-width: 150px;
			  `}
		align-items: center;
		border-radius: 8px;
		display: flex;
		height: 100%;
		justify-content: center;
	`,
);

export const GoToParentButtonWrapper = styled.div(
	({ theme }) => css`
		margin-left: auto;
		margin-right: ${theme.spacing(2)};
	`,
);
