import { graphql } from '../codegen/gql';

export const CREATE_TITLE_V2 = graphql(`
	mutation CreateTitleV2($input: CreateTitleV2!) {
		createTitleV2(input: $input) {
			...TitleFragment
		}
	}
`);

export const EDIT_TITLE_V2 = graphql(`
	mutation EditTitleV2($input: EditTitleV2!) {
		editTitleV2(input: $input) {
			...TitleFragment
		}
	}
`);

export const ADD_RECORD = graphql(`
	mutation AddRecord($input: AddRecord!) {
		addRecord(input: $input) {
			recordUuid
			title {
				...TitleFragment
			}
		}
	}
`);

export const REMOVE_RECORD = graphql(`
	mutation RemoveRecord($input: RemoveRecord!) {
		removeRecord(input: $input) {
			title {
				...TitleFragment
			}
		}
	}
`);

export const TRANSACTIONAL_BATCH_OPERATIONS = graphql(`
	mutation TransactionBatchOperations($input: BatchOperations!) {
		transactionalBatchOperations(input: $input) {
			title {
				...TitleFragment
			}
		}
	}
`);

export const ADD_PARENTS = graphql(`
	mutation AddParents($input: AddParentsInput!) {
		addParents(input: $input) {
			childUuid
			success
			errors
			output {
				...TitleFragment
			}
		}
	}
`);
