import { CardContent as MUICardContent, Link } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo.svg';
import Alert from '../library/Alert';
import Card from '../library/Card';
import NxLoader from '../library/NxLoader';

export interface AuthLoaderErrorMessage {
	message: string;
	retryable: boolean;
}
export interface AuthLoaderProps {
	isLoading?: boolean;
	isAuthenticated?: boolean;
	errorMessage?: AuthLoaderErrorMessage;
	onRetry?: () => void;
	onLogout?: () => void;
}

const Wrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.palette.dark.black};
	display: flex;
	height: 100vh;
	justify-content: center;
	position: relative;
	width: 100vw;
`;

const Logo = styled.img`
	position: absolute;
	top: 60px;
`;

const LoaderWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
`;

const CardContent = styled(MUICardContent)`
	padding: ${({ theme }) => theme.spacing(3, 2)} !important;
	text-align: center;
`;

const RetryLink = styled(Link)`
	cursor: pointer;
	padding-right: 2em;
`;

const LogoutLink = styled(Link)`
	cursor: pointer;
`;

export function AuthLoader({ isLoading = true, errorMessage, isAuthenticated, onLogout, onRetry }: AuthLoaderProps) {
	return (
		<Wrapper>
			<Logo src={logo} alt="Warehouse logo" />
			<Card style={{ maxWidth: '30vw' }}>
				<CardContent>
					{isLoading && (
						<LoaderWrapper>
							<NxLoader />
						</LoaderWrapper>
					)}
					{errorMessage && (
						<>
							<Alert severity="error">{errorMessage?.message}</Alert>
							{errorMessage?.retryable && (
								<RetryLink onClick={() => onRetry?.()} variant="body2">
									Retry
								</RetryLink>
							)}
							{onLogout && (
								<LogoutLink onClick={() => onLogout()} variant="body2">
									Logout
								</LogoutLink>
							)}
						</>
					)}
					{isAuthenticated && <Alert severity="success">Authenticated</Alert>}
				</CardContent>
			</Card>
		</Wrapper>
	);
}
