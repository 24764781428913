import React, { useMemo, useState } from 'react';
import { Checkbox } from '@warehouse/shared/legacy-ui';
import { Typography } from '@mui/material';
import { editPreventerPopupStoreSelector, useEditPreventerPopupStore } from './EditPreventerStore';
import ConfirmationModal from '../../../src/components/library/ConfirmationModal';

export function EditPreventerPopup() {
	const { disablePopup } = useEditPreventerPopupStore(editPreventerPopupStoreSelector.actions);
	const open = useEditPreventerPopupStore(editPreventerPopupStoreSelector.open);
	const confirm = useEditPreventerPopupStore(editPreventerPopupStoreSelector.confirm);
	const deny = useEditPreventerPopupStore(editPreventerPopupStoreSelector.deny);
	const variant = useEditPreventerPopupStore(editPreventerPopupStoreSelector.variant);

	const [doNotShowAgainChecked, setDoNotShowAgainChecked] = useState(false);
	const message = useMemo(() => {
		switch (variant) {
			case 'primary-edit':
				return 'Your edit will affect the parent title because this title is the primary edit';
			case 'inherited-l2':
				return 'Your edit will only affect the current title because this value is inherited';
			default:
				throw new Error(`Unknown variant ${variant}`);
		}
	}, [variant]);

	return (
		<ConfirmationModal
			open={open}
			title="Are you sure?"
			message={message}
			onConfirm={() => {
				if (doNotShowAgainChecked) disablePopup();
				confirm();
			}}
			onClose={() => {
				setDoNotShowAgainChecked(false);
				deny();
			}}
			element={
				<div style={{ display: 'flex', gap: 8, alignItems: 'center', paddingLeft: 32, paddingTop: 16 }}>
					<Checkbox
						onChange={(event) => setDoNotShowAgainChecked(event.target.checked)}
						value={doNotShowAgainChecked}
					/>
					<Typography>Do not show again</Typography>
				</div>
			}
		/>
	);
}
