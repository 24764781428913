export type CombinationOperator = 'AND' | 'OR';

export class SearchFilters {
	static buildSimpleStep(filters: (Filter | FilterStep)[], combinationOperator: CombinationOperator): FilterStep {
		return {
			step: {
				combinationOperator,
				filters,
			},
		};
	}

	static buildNestedStep(nested: string, filters: Filter[], combinationOperator: CombinationOperator): FilterStep {
		return {
			nested,
			step: {
				combinationOperator,
				filters,
			},
		};
	}
}
