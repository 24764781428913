import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';

export function InheritedReadOnlyText({ children, isInherited }: { isInherited: boolean; children: ReactNode }) {
	return (
		<Typography
			style={{ marginLeft: 12 }}
			className={isInherited ? 'inherited' : 'default'}
			fontWeight={400}
			fontSize="14px"
		>
			{children}
		</Typography>
	);
}
