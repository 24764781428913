export function testFullDateRegex(value: string) {
	const fullDateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;

	return fullDateRegex.test(value);
}

export function testYearMonthRegex(value: string) {
	const yearMonthRegex = /^(\d{4})-(\d{2})$/;

	return yearMonthRegex.test(value);
}

export function testYearRegex(value: string) {
	const yearRegex = /^(\d{4})$/;

	return yearRegex.test(value);
}

export function getParsedDateElement(value: string) {
	return value.split('-').map(Number);
}

export function handleBackSpaces(eValue: string, inputValue: string, setInputValue: (value: string) => void) {
	if (eValue.length < inputValue.length) {
		if (inputValue[inputValue.length - 1] === '-') {
			setInputValue(eValue.slice(0, -1));
		} else if (inputValue[inputValue.length - 2] === '/') {
			setInputValue(eValue.slice(0, -3));
		} else {
			setInputValue(eValue);
		}
		return true;
	}
	return false;
}

export function parseDateFromInput(eValue: string) {
	if (testFullDateRegex(eValue)) {
		const [year, month, day] = getParsedDateElement(eValue);
		const date = new Date(year, month - 1, day);
		if (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day) {
			return date;
		}
	} else if (testYearMonthRegex(eValue)) {
		const [year, month] = getParsedDateElement(eValue);
		const date = new Date(year, month - 1);
		if (date.getFullYear() === year && date.getMonth() === month - 1) {
			return date;
		}
	} else if (testYearRegex(eValue)) {
		const year = Number(eValue);
		const date = new Date(year.toString());
		if (date.getFullYear() === year) {
			return date;
		}
	}
	return undefined;
}
