import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { CheckCircleOutline } from '@mui/icons-material';
import UpdateIcon from '@mui/icons-material/Update';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';

// GRAPHQL
import { Maybe } from 'graphql/jsutils/Maybe';

// LIBRARY
import StatusChips from '../../../../../components/library/StatusChips';
import Tag from '../../../../../components/library/Tag';
import ProgressionBar from '../../../../../components/library/ProgressionBar';

const Wrapper = styled.div(
	() => css`
		display: grid;
		gap: 7px;
		grid-template-columns: 1fr auto;
		min-width: 164px;
		text-transform: none !important;
	`,
);

const PercentageTypography = styled(Typography)(
	({ theme }) => css`
		color: ${theme.palette.text.secondary};
		font-size: ${theme.typography.buttonSmallMedium.fontSize};
		font-weight: ${theme.typography.buttonSmallMedium.fontWeight};
		width: 40px;
	`,
);

// TODO: Change type when we got real data
const columns: ColumnDef<Maybe<any>, any>[] = [
	{
		id: 'title',
		header: 'Title',
		cell: () => (
			<div style={{ minWidth: 600 }}>
				<Typography variant="bodyMediumRegular">No Title</Typography>
			</div>
		),
	},
	{
		id: 'profileName',
		header: 'Profile Name',
		accessorFn: (row) => row.profileName,
		minSize: 150,
	},
	{
		id: 'territory',
		header: 'Territory',
		accessorFn: (row) => row.territory,
		minSize: 150,
	},
	{
		id: 'alid',
		header: 'ALID',
		accessorFn: (row) => row.alid,
		minSize: 150,
	},
	{
		id: 'orderGroup',
		header: 'Order Group',
		accessorFn: (row) => row.orderGroup,
		cell: (info) => {
			const { orderGroup } = info.row.original;
			if (orderGroup)
				return (
					<Tag nxstyle="default" $uppercase={false} fullWidth style={{ justifyContent: 'flex-start' }}>
						{orderGroup}
					</Tag>
				);
			return null;
		},
		minSize: 150,
	},
	{
		id: 'dueDate',
		header: 'Due Date',
		accessorFn: (row) => row.dueDate,
		minSize: 150,
	},
	{
		id: 'completionDate',
		header: 'Completion Date',
		accessorFn: (row) => row.completionDate,
		minSize: 150,
	},
	{
		id: 'trackStatus',
		header: 'Track Status',
		accessorFn: (row) => row.territory,
		minSize: 100,
		cell: (info) => {
			const { trackStatus } = info.row.original;
			switch (trackStatus) {
				case 'Available':
					return <StatusChips variant="outlined" label="Available" color="success" icon={<CheckCircleOutline />} />;
				case 'Processing':
					return <StatusChips variant="outlined" label="Processing" color="info" icon={<UpdateIcon />} />;
				case 'Rejection':
					return <StatusChips variant="outlined" label="Rejected" color="error" icon={<DoDisturbAltIcon />} />;
				case 'Error':
					return <StatusChips variant="outlined" label="Error" color="primary" icon={<WarningIcon />} />;
				case 'Missing':
					return <StatusChips variant="outlined" label="Missing" color="warning" icon={<ErrorOutlineIcon />} />;
				default:
					return null;
			}
		},
	},
	{
		id: 'progress',
		header: 'Progress',
		accessorFn: (row) => row.progress,
		maxSize: 164,
		cell: (info) => {
			const { progress } = info.row.original;
			const [numerator, denominator] = progress.split('/').map(Number);
			const numericPercentage = (numerator / denominator) * 100;

			return (
				<Wrapper>
					<ProgressionBar value={numericPercentage} color="primary" variant="determinate" />
					<PercentageTypography>{progress}</PercentageTypography>
				</Wrapper>
			);
		},
	},
];

export default columns;
