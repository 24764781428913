import React from 'react';
import { StyledTH } from './style';
import Checkbox from '../Checkbox';
import { TableStyle } from './TableStyle';

interface ActionHeadProps {
	ariaColindex?: number;
	colSpan: number;
	tableStyle: TableStyle;
	checked: boolean;
	onCheck: () => void;
	showActionCheckbox?: boolean;
}

export function ActionHead({
	ariaColindex,
	colSpan,
	tableStyle,
	checked,
	onCheck,
	showActionCheckbox,
}: ActionHeadProps) {
	return (
		<StyledTH
			aria-colindex={ariaColindex}
			colSpan={colSpan}
			tableStyle={tableStyle}
			style={{ textAlign: 'center', width: '25px' }}
		>
			<div>{showActionCheckbox && <Checkbox checked={checked} onChange={onCheck} />}</div>
		</StyledTH>
	);
}
