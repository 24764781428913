import { getKey } from './hooks/useTerritories';

type CountryOrCountryRegion = {
	country?: string;
	countryRegion?: string;
};

export default function mapToCountryOrCountryRegion(values: string[]): CountryOrCountryRegion[] {
	return values.map((co: string) => ({ [getKey(co)]: co })).filter((v) => !!v) as CountryOrCountryRegion[];
}
