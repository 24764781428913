import React from 'react';
import styled, { css } from 'styled-components';
import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import { lighten } from '@mui/material';

interface ITooltipProps extends TooltipProps {
	variant?: 'dark' | 'light' | 'info' | 'success' | 'warning' | 'error';
}

const Tooltip = styled(({ className, ...props }: ITooltipProps) => (
	<MUITooltip arrow followCursor {...props} classes={{ popper: className }} />
))(
	({ theme, variant = 'dark' }) => css`
		& .MuiTooltip-tooltip,
		.MuiTooltip-arrow::before {
			${variant &&
			(() => {
				switch (variant) {
					case 'dark':
						return css`
							background-color: ${theme.palette.dark.background};
						`;
					case 'light':
						return css`
							background-color: white;
						`;
					case 'success':
					case 'error':
					case 'info':
					case 'warning':
						return css`
							background-color: ${lighten(theme.palette[variant].main, 0.8)};
							color: ${theme.palette.text[variant]};
						`;

					default:
						return css`
							background-color: ${theme.palette.dark.background};
						`;
				}
			})}
		}
		& .MuiTooltip-tooltip {
			border-radius: 8px;
			font-size: 14px;
			padding: ${theme.spacing(1)};
		}
		filter: drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.2));
	`,
);

export default Tooltip;
