import { useQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { GET_LICENSORS } from '../../../graphql/queries/licensor';
import { Licensor } from '../../../graphql/codegen/graphql';

function useLicensors({ pollInterval }: { pollInterval?: number } = {}) {
	const { loading, refetch, error, data, startPolling } = useQuery<{
		getLicensors: any[];
	}>(GET_LICENSORS);

	useEffect(() => {
		if (pollInterval) startPolling(pollInterval);
	}, [startPolling, pollInterval]);

	const getDefault = useCallback(() => data?.getLicensors?.find((licensor: Licensor) => licensor.default), [data?.getLicensors]);

	return { loading, getDefault, refetch, error, data };
}

export default useLicensors;
