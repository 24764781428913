import React, { useEffect, useMemo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import styled, { css } from 'styled-components';
import {
	TitleSelector,
	TitleSelectorProvider,
	titleSelectorSelector,
	useTitleSelectorStore,
} from '@warehouse/title/title-selector-feature';
import { Button } from '@warehouse/shared/legacy-ui';
import { RelationshipsService, useAddChildrenMutation, useTitle } from '@warehouse/title/domain';
import { getWorkTypeObject } from '@warehouse/title/infra';
import { RelationshipType } from '@warehouse/title/core';
import DialogTitle, { CloseButton } from '../../../src/components/library/DialogTitle';
import DialogActions from '../../../src/components/library/DialogActions';
import Loader from '../../../src/components/library/Loader';
import Dialog from '../../../src/components/library/Dialog';
import useWorkTypes from '../../../src/utils/hooks/titles/useWorkTypes';
import { addChildrenStoreSelector, useAddChildrenStore } from './add-children.store';

export function AddChildrenPromptComponent({
	open,
	onClose,
	titleUuid,
	searchAndSelectedPluralLabel,
	forceRelationshipType,
	headerSlot,
	headerText,
}: AddChildrenPromptProps) {
	const { data: titleData } = useTitle({ uuid: titleUuid });
	const licensor = useMemo(() => titleData?.readonly.licensor.label, [titleData?.readonly.licensor.label]);
	// Before the first render, the relationship type is not set in the store, so we need to get it from the props
	const relationshipType = forceRelationshipType ?? useAddChildrenStore(addChildrenStoreSelector.relationshipType);
	const actions = useAddChildrenStore(addChildrenStoreSelector.actions);
	const selectedTitles = useTitleSelectorStore(titleSelectorSelector.selectedTitles);
	const theme = useTheme();

	// If a relationship type is forced, set it in the store
	useEffect(() => {
		if (forceRelationshipType) actions.setRelationshipType(forceRelationshipType);
	}, [actions, forceRelationshipType]);

	const { getWorkTypesByRelationshipSource } = useWorkTypes();
	const supportedChildrenTypes = useMemo(
		() => (relationshipType ? getWorkTypesByRelationshipSource(relationshipType) : []),
		[getWorkTypesByRelationshipSource, relationshipType],
	);

	// run when relationship type change to update children type supported
	useEffect(() => {
		// set work type uuids when relationship type change, but only if forceRelationshipType is not set
		if (!forceRelationshipType && relationshipType)
			actions.setWorkTypeUuids(getWorkTypesByRelationshipSource(relationshipType).map((type) => type.value));
	}, [actions, forceRelationshipType, getWorkTypesByRelationshipSource, relationshipType]);

	const { mutate, loading } = useAddChildrenMutation({
		options: {
			onCompleted: () => onClose(),
		},
	});

	if (!titleData) {
		return null;
	}

	const handleSubmit = () => {
		mutate({
			titleUuid,
			workTypeUuid: getWorkTypeObject(titleData.readonly.workType).uuid,
			children: selectedTitles.map((title) => ({ uuid: title.uuid, workType: title.workType ?? undefined })),
			relationshipType: relationshipType ?? undefined,
		});
	};

	const defaultWorkType = relationshipType
		? RelationshipsService.getDefaultWorkTypeForRelationshipTypeSoft(relationshipType)
		: null;
	const defaultWorkTypeUuid = defaultWorkType ? getWorkTypeObject(defaultWorkType).uuid : null;

	return (
		<Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
			<DialogTitle>
				{headerText}
				<CloseButton onClose={onClose} />
			</DialogTitle>
			<DialogContent>
				<ContentWrapper>
					<TitleSelector
						licensor={licensor}
						defaultWorkTypeUuid={defaultWorkTypeUuid}
						supportedWorkTypeUuids={supportedChildrenTypes.map((type) => type.value)}
						searchAndSelectedPluralLabel={searchAndSelectedPluralLabel}
						headerSlot={headerSlot}
					/>
				</ContentWrapper>
			</DialogContent>
			<DialogActions>
				<Button $fullHeight onClick={onClose}>
					Cancel
				</Button>
				<Button
					$fullHeight
					type="button"
					disabled={loading || selectedTitles.length < 1 || !relationshipType}
					onClick={handleSubmit}
					nxstyle="primary-blue"
				>
					{loading ? <Loader size={20} color={theme.palette.light.main} /> : <span>Confirm</span>}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export function AddChildrenPrompt({
	open,
	onClose,
	titleUuid,
	forceRelationshipType,
	searchAndSelectedPluralLabel,
	headerSlot,
	headerText,
}: AddChildrenPromptProps) {
	if (!open) return null;

	return (
		<TitleSelectorProvider>
			<AddChildrenPromptComponent
				open={open}
				onClose={onClose}
				forceRelationshipType={forceRelationshipType}
				titleUuid={titleUuid}
				searchAndSelectedPluralLabel={searchAndSelectedPluralLabel}
				headerSlot={headerSlot}
				headerText={headerText}
			/>
		</TitleSelectorProvider>
	);
}

export interface AddChildrenPromptProps {
	open: boolean;
	onClose: () => void;
	titleUuid: string;
	forceRelationshipType?: RelationshipType;
	headerText: string;
	searchAndSelectedPluralLabel: string;
	headerSlot?: React.ReactNode;
}

const ContentWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
	`,
);
