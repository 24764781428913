import React from 'react';
import ReactLoading from 'react-loading';
import { useTheme } from '@mui/material';

interface LoaderProps {
	size?: number;
	color?: string;
}

function Loader({ size = 60, color }: LoaderProps) {
	const theme = useTheme();

	return <ReactLoading type="bars" color={color || theme.palette.primary.main} height={size} width={size} />;
}

export default Loader;
