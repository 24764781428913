import React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle, { AlertTitleProps } from '@mui/material/AlertTitle';
import styled, { css } from 'styled-components';
import { Warning, Info } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface AlertSnackBarProps extends AlertProps {
	width?: number;
}

const StyledAlert = styled(Alert)<AlertSnackBarProps>`
	border-radius: 8px;

	${({ width }) => {
		if (width) {
			return css`
				width: ${width}px;
			`;
		}
		return null;
	}}

	// Text-color
	& > .MuiAlert-message {
		${({ theme, severity }) => {
			if (severity) {
				return css`
					color: ${theme.palette[severity].text};
				`;
			}
			return null;
		}}
	}

	// Background-color
	${({ theme, severity, variant }) => {
		if (variant !== 'outlined') {
			return css`
				background-color: ${theme.palette[severity || 'success'].background};
			`;
		}
		return null;
	}}

	// Border-color
  ${({ theme, severity, variant }) => {
		if (variant === 'outlined') {
			return css`
				border-color: ${theme.palette[severity || 'success'].main};
			`;
		}
		return null;
	}}


  & > .MuiAlert-icon {
		display: flex;
		align-items: center;
		// Icon color
		${({ theme, severity }) => {
			if (severity) {
				return css`
					color: ${theme.palette[severity].text};
				`;
			}
			return null;
		}}
	}

	.MuiAlert-action {
		padding: 2px 0 0 16px;
	}

	.MuiAlert-action > * {
		${({ theme, severity }) => {
			if (severity) {
				return css`
					color: ${theme.palette[severity].text} !important;
				`;
			}
			return null;
		}}
	}

	font-size: 12px;
	font-weight: 500;
	padding: 8px 12px;
`;

const StyledAlertTitle = styled(AlertTitle)<AlertTitleProps>`
	font-size: 16px;
	font-weight: 500;
	margin: 0;
	text-align: left;
`;

function AlertSnackBarComponent(props: AlertSnackBarProps) {
	const { children, title, width } = props;
	return (
		<StyledAlert
			iconMapping={{
				success: <CheckCircleOutlineIcon fontSize="inherit" />,
				error: <Warning fontSize="inherit" />,
				warning: <Warning fontSize="inherit" />,
				info: <Info fontSize="inherit" />,
			}}
			width={width}
			{...props}
		>
			{title && <StyledAlertTitle>{title}</StyledAlertTitle>}
			{children}
		</StyledAlert>
	);
}

export default AlertSnackBarComponent;
