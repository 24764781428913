export type PathStep = string | number;

export type JsonPointer = PathStep[];

function getDeepProperty<T>(obj: Record<string, any> | undefined, path: JsonPointer): T | undefined {
	if (!obj) return undefined;

	return path.reduce((current, step: PathStep) => current?.[step], obj) as T | undefined;
}
export default getDeepProperty;
