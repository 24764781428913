import React from 'react';
import styled, { css } from 'styled-components';
import { Chip, ChipProps } from '@mui/material';

interface StyledStatusChipProps extends ChipProps {
	height?: number;
}

const StyledStatusChip = styled(Chip)<StyledStatusChipProps>(
	({ theme, color }) => css`
		font-size: ${theme.typography.buttonSmallMedium.fontSize};
		font-weight: ${theme.typography.buttonSmallMedium.fontWeight};
		height: 28px;
		justify-content: flex-start;
		padding: 6px 8px;
		width: 128px;

		${color === 'info' &&
		css`
			border-color: ${theme.palette.info.main};
			color: ${theme.palette.info.text};
			svg {
				color: ${theme.palette.info.main} !important;
				height: 16px;
				width: 16px;
			}
		`}

		${color === 'success' &&
		css`
			border-color: ${theme.palette.success.main};
			color: ${theme.palette.success.text};
			svg {
				color: ${theme.palette.success.main} !important;
				height: 16px;
				width: 16px;
			}
		`}

    ${color === 'warning' &&
		css`
			border-color: ${theme.palette.warning.main};
			color: ${theme.palette.warning.text};
			svg {
				color: ${theme.palette.warning.main} !important;
				height: 16px;
				width: 16px;
			}
		`}

    ${color === 'primary' &&
		css`
			border-color: ${theme.palette.primary.main};
			color: ${theme.palette.secondary.text};
			svg {
				color: ${theme.palette.primary.main} !important;
				height: 16px;
				width: 16px;
			}
		`}
		
		${color === 'error' &&
		css`
			border-color: ${theme.palette.error.main};
			color: ${theme.palette.error.text};
			svg {
				color: ${theme.palette.error.main} !important;
				height: 16px;
				width: 16px;
			}
		`}
	`,
);

function StatusChips(props: StyledStatusChipProps) {
	return <StyledStatusChip {...props} />;
}

export default StatusChips;
