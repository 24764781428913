import React from 'react';
import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import styled, { css } from 'styled-components';

const StyledDialog = styled(MUIDialog)<DialogProps>(
	() => css`
		& .MuiDialog-paper {
			//Mandatory for absolutely positioned items within to overflow
			overflow-y: inherit;
		}

		& .MuiPaper-rounded {
			border-radius: 8px;
		}

		& .MuiDialogContent-root {
			//Mandatory for absolutely positioned items within to overflow
			overflow-y: inherit;
			padding: 0;
		}
	`,
);

function Dialog(props: DialogProps) {
	return <StyledDialog {...props} />;
}

export default Dialog;
