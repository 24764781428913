import React, { ReactNode, ReactElement, useState } from 'react';
import { ClickAwayListener, Paper, Popper, Fade, Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import styled, { css } from 'styled-components';
import Overlay from './Overlay';

interface ModalMenuProps {
	content?: ReactElement;
	enableArrow?: boolean;
	position?: 'right' | 'left' | 'bottom' | 'bottom-start' | 'bottom-end' | 'top' | 'top-start' | 'top-end';
	open: boolean;
	anchorEl: HTMLButtonElement | null;
	onClose: () => void;
	disableOverlay?: boolean;
}

interface MenuListItemProps {
	icon?: ReactNode;
	text: string;
	onClick: () => void;
	height?: number;
}

const PopoverRoot = styled(Paper)(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
	`,
);

const PopperPaper = styled(Paper)(
	() => css`
		border-radius: 8px;
	`,
);

const WrapperContent = styled(Box)(
	() => css`
		box-shadow: none;
		display: flex;
		flex-direction: column;
		text-transform: none;
	`,
);

const StyledPopper = styled(Popper)(({ theme }) => ({
	zIndex: 98,
	'& > div': {
		position: 'relative',
	},
	'&[data-popper-placement*="bottom"]': {
		'& > div': {
			marginTop: 12,
		},
		'& .MuiPopper-arrow': {
			top: '4px',
			left: 0,
			marginTop: '-0.9em',
			width: '24px',
			height: '24px',
			'&::before': {
				width: '24px',
				height: '24px',
				backgroundColor: `${theme.palette.light.main}`,
				clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
				transform: 'rotate(135deg)',
				borderRadius: '0 0 0 4px',
			},
		},
	},
	'&[data-popper-placement*="bottom-start"]': {
		'& > div': {
			bottom: '20px',
			left: '20px',
		},
		'& .MuiPopper-arrow': {
			top: '4px',
			left: 0,
			marginTop: '-0.9em',
			width: '24px',
			height: '24px',
			'&::before': {
				width: '24px',
				height: '24px',
				backgroundColor: `${theme.palette.light.main}`,
				clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
				transform: 'rotate(135deg)',
				borderRadius: '0 0 0 4px',
			},
		},
	},
	'&[data-popper-placement*="bottom-end"]': {
		'& > div': {
			bottom: '20px',
			right: '20px',
		},
		'& .MuiPopper-arrow': {
			top: '4px',
			left: 0,
			marginTop: '-0.9em',
			width: '24px',
			height: '24px',
			'&::before': {
				width: '24px',
				height: '24px',
				backgroundColor: `${theme.palette.light.main}`,
				clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
				transform: 'rotate(135deg)',
				borderRadius: '0 0 0 4px',
			},
		},
	},
	'&[data-popper-placement*="top"]': {
		'& > div': {
			marginBottom: 12,
		},
		'& .MuiPopper-arrow': {
			bottom: '4px',
			left: 0,
			marginBottom: '-0.9em',
			width: '24px',
			height: '24px',
			'&::before': {
				width: '24px',
				height: '24px',
				backgroundColor: `${theme.palette.light.main}`,
				clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
				transform: 'rotate(-45deg)',
				borderRadius: '0 0 0 4px',
			},
		},
	},
	'&[data-popper-placement*="top-start"]': {
		'& > div': {
			top: '20px',
			left: '20px',
		},
		'& .MuiPopper-arrow': {
			bottom: '4px',
			left: 0,
			marginBottom: '-0.9em',
			width: '24px',
			height: '24px',
			'&::before': {
				width: '24px',
				height: '24px',
				backgroundColor: `${theme.palette.light.main}`,
				clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
				transform: 'rotate(-45deg)',
				borderRadius: '0 0 0 4px',
			},
		},
	},
	'&[data-popper-placement*="top-end"]': {
		'& > div': {
			top: '20px',
			right: '20px',
		},
		'& .MuiPopper-arrow': {
			bottom: '4px',
			left: 0,
			marginBottom: '-0.9em',
			width: '24px',
			height: '24px',
			'&::before': {
				width: '24px',
				height: '24px',
				backgroundColor: `${theme.palette.light.main}`,
				clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
				transform: 'rotate(-45deg)',
				borderRadius: '0 0 0 4px',
			},
		},
	},
	'&[data-popper-placement*="right"]': {
		'& > div': {
			marginLeft: 2,
		},
		'& .MuiPopper-arrow': {
			left: '4px',
			marginLeft: '-0.9em',
			width: '24px',
			height: '24px',
			'&::before': {
				width: '24px',
				height: '24px',
				backgroundColor: `${theme.palette.light.main}`,
				clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
				transform: 'rotate(45deg)',
				borderRadius: '0 0 0 4px',
			},
		},
	},
	'&[data-popper-placement*="left"]': {
		'& > div': {
			marginRight: 2,
		},
		'& .MuiPopper-arrow': {
			right: '-10px',
			marginLeft: '-0.9em',
			width: '24px',
			height: '24px',
			'&::before': {
				width: '24px',
				height: '24px',
				backgroundColor: `${theme.palette.light.main}`,
				clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
				transform: 'rotate(225deg)',
				borderRadius: '0 0 0 4px',
			},
		},
	},
}));

const StyledArrow = styled('div')({
	position: 'absolute',
	width: '3em',
	height: '3em',
	'&::before': {
		content: '""',
		margin: 'auto',
		display: 'block',
		width: 0,
		height: 0,
	},
});

export const MenuTitleWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
	`,
);

export const MenuTitleTextWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		padding: ${theme.spacing(2)};

		svg {
			color: ${theme.palette.text.secondary};
			margin: 0 ${theme.spacing(1)};
		}

		.MuiTypography-root {
			color: ${theme.palette.text.primary};
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.01em;
			text-transform: uppercase;
		}
	`,
);

const MenuDivider = styled.div(
	({ theme }) => css`
		align-self: center;
		background: ${theme.palette.light.backgroundAlt};
		height: 1px;
		width: calc(100% - 32px);
	`,
);

const StyledMenuItem = styled(MenuItem)<{ height?: number }>(
	({ theme, height }) => css`
		padding: ${theme.spacing(1.5)};

		:hover {
			.MuiTypography-root {
				color: ${theme.palette.text.primary};
			}

			svg {
				color: ${theme.palette.text.primary};
			}
		}

		svg {
			color: ${theme.palette.text.secondary};
		}

		${height &&
		css`
			height: ${height}px;
		`}
	`,
);

const StyledListItemText = styled(ListItemText)(
	({ theme }) => css`
		.MuiTypography-root {
			color: ${theme.palette.text.secondary};
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 120%;
		}
	`,
);

export function MenuListTitle({ children }: { children: ReactNode }) {
	return (
		<MenuTitleWrapper>
			<MenuTitleTextWrapper>{children}</MenuTitleTextWrapper>
			<MenuDivider />
		</MenuTitleWrapper>
	);
}

export function MenuListItem({ icon, text, onClick = () => {}, height, ...rest }: MenuListItemProps) {
	return (
		<StyledMenuItem height={height} onClick={onClick} {...rest}>
			{icon && <ListItemIcon>{icon}</ListItemIcon>}
			<StyledListItemText>{text}</StyledListItemText>
		</StyledMenuItem>
	);
}

export const MenuListActions = styled.div(
	({ theme }) => css`
		display: flex;
		padding: ${theme.spacing(0.5)} ${theme.spacing(1)};

		button {
			margin: ${theme.spacing(0.5)};
		}
	`,
);

export function ModalMenu({
	position,
	enableArrow = false,
	content,
	open,
	onClose,
	anchorEl,
	disableOverlay = false,
}: ModalMenuProps) {
	const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

	return (
		<>
			{open && !disableOverlay && <Overlay />}
			<StyledPopper
				open={open}
				anchorEl={anchorEl}
				placement={position}
				disablePortal
				transition
				modifiers={[
					{
						name: 'preventOverflow',
						enabled: true,
						options: {
							altBoundary: true,
							tether: true,
							rootBoundary: 'document',
							padding: 8,
						},
					},
					{
						name: 'arrow',
						enabled: enableArrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{/* eslint-disable-next-line @typescript-eslint/naming-convention */}
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<PopperPaper elevation={1}>
							<ClickAwayListener onClickAway={onClose}>
								<PopoverRoot elevation={0}>
									{enableArrow ? <StyledArrow ref={setArrowRef} className="MuiPopper-arrow" /> : null}
									<WrapperContent>{content}</WrapperContent>
								</PopoverRoot>
							</ClickAwayListener>
						</PopperPaper>
					</Fade>
				)}
			</StyledPopper>
		</>
	);
}

export default ModalMenu;
