import React, { ReactNode, RefObject } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import { alpha, Divider, Typography } from '@mui/material';
import Button from './Button';

export function SideModal(props: SideModalProps) {
	const { side, open, children, handleClose, wrapperRef, maxWidth, overflow, header, title } = props;

	if (!open) return null;

	const defaultHeader = () => (
		<>
			<Header>
				<Typography variant="h4Regular">{title}</Typography>
				<Button style={{ padding: 0 }} nxstyle="tertiary-light" onClick={handleClose}>
					<Icon aria-label="Close Drawer Icon" path={mdiClose} size="24px" />
				</Button>
			</Header>
			<Divider />
		</>
	);

	return (
		<>
			<OverlayWrapper
				aria-label="overlay-drawer"
				parentHeight={wrapperRef ? wrapperRef.current?.clientHeight : undefined}
				onClick={() => handleClose()}
			/>
			<Wrapper
				aria-label="drawer"
				side={side}
				parentHeight={wrapperRef ? wrapperRef.current?.clientHeight : undefined}
				disableRadius
				maxWidth={maxWidth}
				overflow={overflow}
			>
				{open && (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{header || defaultHeader()}
						<Content>{children}</Content>
					</div>
				)}
			</Wrapper>
		</>
	);
}

SideModal.Content = SideModalContent;

function SideModalContent({ children }: { children: React.ReactNode }) {
	return <PaddingWrapper>{children}</PaddingWrapper>;
}

const PaddingWrapper = styled.div<{ height?: string }>(
	({ theme }) => `
		body {
			overflow: hidden !important;
		}
		
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	
		overflow-y: auto;
		height: calc(100vh - 80px);
		padding: ${theme.spacing(3, 2)};
		width: 700px;
		box-sizing: border-box;
	`,
);

type SideDrawerProps = {
	side: 'left' | 'right';
	children?: React.ReactNode;
	open: boolean;
	handleClose: () => void;
	wrapperRef?: RefObject<HTMLDivElement>;
	maxWidth?: number;
	overflow?: 'auto' | 'visible' | 'scroll' | 'hidden';
};

type SideModalProps = SideDrawerProps & {
	header?: ReactNode;
	title?: string;
};

type WrapperProps = {
	side?: 'left' | 'right';
	parentHeight?: number;
	disableRadius?: boolean;
	maxWidth?: number;
	overflow?: 'auto' | 'visible' | 'scroll' | 'hidden';
};

type TriggerProps = {
	side?: 'left' | 'right';
	open?: boolean;
	onClick?: () => void;
};

type HeaderProps = {
	title: string;
	handleClose: () => void;
	action?: ReactNode;
};

const openAnimation = keyframes`
 0% { max-width: 0 }
 100% {max-width: 80vw;  }
`;

const OverlayWrapper = styled.div<WrapperProps>`
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	width: 100vw;
	z-index: 98;

	${({ parentHeight }) => css`
		height: ${parentHeight ? `${parentHeight}px` : '100%'};
	`}
`;

const Wrapper = styled.div<WrapperProps>`
	${({ theme, side, parentHeight }) => css`
		animation-duration: 0.5s;
		animation-name: ${openAnimation};
		background-color: ${theme.palette.light.main};
		border: 1px solid ${alpha(theme.palette.dark.main, 0.1)};
		box-shadow: -12px 0 12px rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		display: flex;
		flex-direction: ${side !== 'left' ? 'row' : 'row-reverse'};
		height: ${parentHeight ? `${parentHeight}px` : '100%'};
		position: absolute;
		width: auto;
		z-index: 99;
	`}

	${({ side, disableRadius }) => {
		switch (side) {
			case 'right':
				return css`
					${!disableRadius &&
					css`
						border-bottom-left-radius: 8px;
						border-top-left-radius: 8px;
					`}
					right: 0;
				`;
			case 'left':
			default:
				return css`
					${!disableRadius &&
					css`
						border-bottom-right-radius: 8px;
						border-top-right-radius: 8px;
					`} left: 0;
				`;
		}
	}}
	
	${({ maxWidth }) =>
		maxWidth
			? css`
					max-width: ${maxWidth}px;
			  `
			: null}

	${({ overflow }) =>
		overflow
			? css`
					overflow: ${overflow};
			  `
			: null}
`;

const TriggerWrapper = styled.div<TriggerProps>(
	({ theme, side }) => css`
		align-items: center;
		background-color: ${theme.palette.light.main};
		cursor: pointer;
		display: flex;
		justify-content: center;
		width: 16px;

		${side === 'right'
			? css`
					border-right: 1px solid ${theme.palette.light.backgroundAlt};
			  `
			: css`
					border-left: 1px solid ${theme.palette.light.backgroundAlt};
			  `}

		svg {
			color: ${alpha(theme.palette.dark.main, 0.5)};
		}
	`,
);

const Content = styled.div(
	() => css`
		flex-grow: 1;
	`,
);

const HeaderWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		padding: ${theme.spacing(2)};
		width: 100%;
		.MuiTypography-root {
			margin-right: ${theme.spacing(1)};
		}
	`,
);

const HeaderActionsWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
	`,
);

export const SideDrawerContent = styled.div<{ minWidth?: number }>(
	({ theme, minWidth }) => css`
		padding: ${theme.spacing(2)};

		${minWidth &&
		css`
			min-width: ${minWidth}px;
		`}
	`,
);

export function SideDrawerHeader({ title, handleClose, action }: HeaderProps) {
	return (
		<HeaderWrapper>
			<Typography variant="s1Medium" component="div">
				{title}
			</Typography>
			<HeaderActionsWrapper>
				{action}
				<Button nxstyle="tertiary-light" onClick={handleClose}>
					<Icon path={mdiClose} size="24px" />
				</Button>
			</HeaderActionsWrapper>
		</HeaderWrapper>
	);
}

export function Trigger(props: TriggerProps) {
	const { side, open, onClick } = props;

	return (
		<TriggerWrapper side={side} open={open} onClick={() => (onClick ? onClick() : null)}>
			{(side === 'right' && !open) || (side === 'left' && open) || (!side && open) ? (
				<Icon path={mdiChevronDoubleLeft} size="24px" />
			) : null}
			{(side === 'left' && !open) || (side === 'right' && open) || (!side && !open) ? (
				<Icon path={mdiChevronDoubleRight} size="24px" />
			) : null}
		</TriggerWrapper>
	);
}

function SideDrawer(props: SideDrawerProps) {
	const { side, open, children, handleClose, wrapperRef, maxWidth, overflow } = props;

	if (!open) return null;

	return (
		<Wrapper
			side={side}
			parentHeight={wrapperRef ? wrapperRef.current?.clientHeight : undefined}
			maxWidth={maxWidth}
			overflow={overflow}
			disableRadius
		>
			<Trigger onClick={() => handleClose()} side={side} open={open} />
			{open && <Content>{children}</Content>}
		</Wrapper>
	);
}

const Header = styled.div(
	({ theme }) => `
		padding: ${theme.spacing(3, 2)};
		width: 700px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	`,
);

export default SideDrawer;
