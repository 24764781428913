import { ToggleButtonGroup, ToggleButton as ToggleButtonMUI } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';

export function ToggleButtons<T extends string>({ options, value, onChange, defaultValue }: ToggleButtonProps<T>) {
	const [localValue, setLocalValue] = useState<T | undefined>(defaultValue);

	const handleChange = (_: React.MouseEvent<HTMLElement>, newValue: T) => {
		if (localValue && !newValue) return; // Prevent unselecting

		setLocalValue(newValue);
		onChange?.(newValue);
	};

	useEffect(() => {
		if (value) setLocalValue(value);
	}, [value]);

	return (
		<ToggleButtonGroup
			color={'blue' as ToggleButtonGroupProps['color']}
			value={localValue}
			exclusive
			onChange={handleChange}
			aria-label="Platform"
		>
			{options.map((option, index) => (
				<CustomToggleButtonMUI key={`${option.value}-${index}`} value={option.value}>
					{option.label}
				</CustomToggleButtonMUI>
			))}
		</ToggleButtonGroup>
	);
}

interface ToggleButtonProps<T> {
	options: ToggleButtonOption<T>[];
	value?: T;
	onChange?: (value: T) => void;
	defaultValue?: T;
}

export interface ToggleButtonOption<T> {
	value: T;
	label: string;
}

const CustomToggleButtonMUI = styled(ToggleButtonMUI)(
	({ theme }) => css`
		border-radius: 8px;
		padding: ${theme.spacing(1, 6)};
		text-transform: capitalize;
	`,
);
