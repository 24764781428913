export default function sortAlphabeticallyByLabel(a: { label: string }, b: { label: string }) {
	if (!a.label && !b.label) return 0;
	if (!a.label) return -1;
	if (!b.label) return 1;

	return a.label.localeCompare(b.label);
}

export function sortAlphabetically(a: string, b: string) {
	return a.localeCompare(b);
}
