import { defineStore } from '@warehouse/shared/util';
import { useEffect } from 'react';
import { TitleFull, TitleRepository } from '@warehouse/title/core';
import { titleRepositorySingleton } from '@warehouse/title/infra';
import { useTitle } from './useTitle';

interface TitleEditorStore {
	titleUuid: string | undefined;
	editMode: boolean;
	title: TitleFull;
	actions: {
		setTitle: (title: TitleFull | undefined) => void;
		setTitleUuid: (titleUuid: string | undefined) => void;
		setEditMode: (editMode: boolean) => void;
		editTitle: TitleRepository['editTitle'];
	};
}

export const [TitleEditorStoreProvider, useTitleEditorStore] = defineStore<TitleEditorStore>((set) => {
	const repo = titleRepositorySingleton.get();

	return {
		titleUuid: undefined,
		editMode: false,
		/**
		 * This is a hack to make the Title be defined within the scope of the AdminTitleView.
		 * AdminTitleView has a loader that renders a spinner until the title is actually loaded.
		 */
		title: {} as TitleFull,
		actions: {
			setTitleUuid: (titleUuid: string | undefined) => {
				if (titleUuid === undefined) {
					// TODO: Set title to undefined once we fix the title typing
					set({ title: {} as TitleFull });
				}
				set({ titleUuid });
			},
			setEditMode: (editMode: boolean) => set({ editMode }),

			// TODO: Determine if we really can set the title to undefined. Maybe we want to have this type set to `Title` instead of `Title | undefined`
			// and another function to clear the title.
			setTitle: (title: TitleFull | undefined) => set({ title }),
			editTitle: async (edits, options) => {
				const editedTitle = await repo.editTitle(edits, options);
				set({ title: editedTitle });
				return editedTitle;
			},
		},
	};
});

export const titleEditorStoreSelector = {
	actions: (state: TitleEditorStore) => state.actions,
	titleUuid: (state: TitleEditorStore) => state.titleUuid,
	isTitleHidden: (state: TitleEditorStore) => state.title?.readonly?.titleStatus === 'hidden',
	title: (state: TitleEditorStore) => state.title,
	titleRequired: (state: TitleEditorStore) => {
		if (!state.title) throw new Error('This component should not render without a title having been set');
		return state.title;
	},
	editMode: (state: TitleEditorStore) => state.editMode,
	// TODO: Simply check that title is defined once we fix the title typing
	isPending: (state: TitleEditorStore) => !state.title?.uuid,
};

export function TitleEditorStoreEffects() {
	const { setTitleUuid, setTitle, setEditMode } = useTitleEditorStore(titleEditorStoreSelector.actions);

	const titleUuid = useTitleEditorStore(titleEditorStoreSelector.titleUuid);
	const titleV2Output = useTitle({ uuid: titleUuid, skipable: true });
	const title = titleV2Output?.data;

	// Set title in store once it is loaded
	useEffect(() => {
		setTitle(title);
	}, [setTitle, title]);

	// TODO: Call teardown action on the store
	// TODO: Is this really necessary ? If setTitleUuid does everything, we don't need additional teardown logic
	// Reset store on teardown
	useEffect(
		() => () => {
			setEditMode(false);
			setTitleUuid(undefined);
		},
		[setEditMode, setTitleUuid],
	);

	return null;
}
