import React from 'react';
import MUIDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import styled, { css } from 'styled-components';
import { Icon } from '@mdi/react';
import { mdiClose } from '@mdi/js';
import Button from './Button';

interface IDialogTitleProps extends DialogTitleProps {
	titleVariant?: 'error' | 'info' | 'success' | 'warning';
}

const StyledDialogTitle = styled(MUIDialogTitle)<IDialogTitleProps>`
	${({ titleVariant, theme }) => {
		switch (titleVariant) {
			case 'error':
				return css`
					background-color: ${theme.palette.error.main};
					color: ${theme.palette.text.reverseMain};
				`;
			case 'info':
				return css`
					background-color: ${theme.palette.info.main};
					color: ${theme.palette.text.reverseMain};
				`;
			case 'success':
				return css`
					background-color: ${theme.palette.success.main};
					color: ${theme.palette.text.reverseMain};
				`;
			case 'warning':
				return css`
					background-color: ${theme.palette.warning.background};
					color: ${theme.palette.text.reverseMain};
				`;
			default:
				return css`
					background-color: ${theme.palette.dark.background};
					color: ${theme.palette.text.reverseMain};
				`;
		}
	}}

	${({ theme }) => css`
		align-items: center;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		box-sizing: border-box;
		display: flex;
		font-size: 24px;
		justify-content: center;
		min-height: 45px;
		padding: 0;
		position: relative;

		svg {
			margin: ${theme.spacing(1)};
		}
	`}
`;

const StyledCloseButton = styled(Button)`
	padding: 0;
	position: absolute;
	right: 8px;
	top: calc(50% - 16px);
`;

export function CloseButton({ onClose }: { onClose: () => void }) {
	return (
		<StyledCloseButton nxstyle="tertiary-dark" onClick={onClose}>
			<Icon path={mdiClose} size="24px" />
		</StyledCloseButton>
	);
}

function DialogTitle(props: IDialogTitleProps) {
	return <StyledDialogTitle {...props} />;
}

export default DialogTitle;
