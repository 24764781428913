import { JsonPointer, PathStep } from './getDeepProperty';

function editDeepProperty(obj: Record<string, unknown> | undefined, path: JsonPointer, value: any) {
	if (!obj) return;

	const property = path
		.slice(0, -1)
		.reduce((current, step: PathStep) => current?.[step] as Record<string, unknown>, obj);

	property[path[path.length - 1]] = value;
}

export default editDeepProperty;
