import React, { useMemo } from 'react';
import TextInput from '../../../library/TextInput';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';

interface AlidFieldProps {
	editMode: boolean;
	isInherited: boolean;
	alid: string;
	setAlid: (alid: string) => void;
	origin: string;
}

function AlidField({ editMode, isInherited, alid, setAlid, origin }: AlidFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="ALID"
				editMode={editMode}
				readComponent={<ReadOnlyTypography>ALID</ReadOnlyTypography>}
				editComponent={
					<TextInput height={38} fullWidth disableRightPadding value={alid} onChange={(e) => setAlid(e.target.value)} />
				}
				ruleOrigin={origin}
			/>
		),
		[isInherited, alid, editMode],
	);
}

export default AlidField;
