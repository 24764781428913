import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

// MOVIE
import MovieL1 from '../../assets/title-icons/movie/movie_abstraction_orange.svg';
import MovieL2 from '../../assets/title-icons/movie/movie_performance_blue.svg';

// SERIES
import SeriesL1 from '../../assets/title-icons/series/series_abstraction_orange.svg';

// SEASON
import SeasonL1 from '../../assets/title-icons/season/season_abstraction_orange.svg';

// COMPILATION
import CompilationL1 from '../../assets/title-icons/compilation/compilation_abstraction_orange.svg';

// SUPPLEMENTAL
import SupplementalL1 from '../../assets/title-icons/supplemental/supplemental_abstraction_orange.svg';
import SupplementalL2 from '../../assets/title-icons/supplemental/supplemental_performance_blue.svg';

// EPISODE
import EpisodeL1 from '../../assets/title-icons/episode/episode_abstraction_orange.svg';
import EpisodeL2 from '../../assets/title-icons/episode/episode_performance_blue.svg';

interface TitleTypeInfoProps {
	titleTypeName?: string;
	parentTitleTypeName?: string;
	iconColor?: 'red' | 'blue' | 'orange';
}

const Wrapper = styled.div(
	({ theme }) => css`
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: ${theme.spacing(0.5)};
		display: flex;
	`,
);

const TextWrapper = styled.div(
	({ theme }) => css`
		border-left: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: column;
		padding: ${theme.spacing(0.5, 1)};
	`,
);

const ImageWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		justify-content: center;
		padding: ${theme.spacing(0.5)};

		& > img {
			height: 32px;
			width: 32px;
		}
	`,
);

function getIconByTitleType(
	titleTypeName?: string,
	parentTitleTypeName?: string,
	iconColor?: 'red' | 'blue' | 'orange',
) {
	if (parentTitleTypeName === 'Movie' && titleTypeName === 'Edit' && iconColor === 'blue') return MovieL2;

	if (parentTitleTypeName === 'Episode' && titleTypeName === 'Edit' && iconColor === 'blue') return EpisodeL2;

	if (parentTitleTypeName === 'Supplemental' && titleTypeName === 'Edit' && iconColor === 'blue') return SupplementalL2;

	if (titleTypeName === 'Movie' && iconColor === 'orange') return MovieL1;

	if (titleTypeName === 'Series' && iconColor === 'orange') return SeriesL1;

	if (titleTypeName === 'Season' && iconColor === 'orange') return SeasonL1;

	if (titleTypeName === 'Compilation' && iconColor === 'orange') return CompilationL1;

	if (titleTypeName === 'Supplemental') if (iconColor === 'orange') return SupplementalL1;

	if (titleTypeName === 'Episode' && iconColor === 'orange') return EpisodeL1;

	return MovieL2;
}

function TitleTypeInfo({ titleTypeName, parentTitleTypeName, iconColor }: TitleTypeInfoProps) {
	const icon = getIconByTitleType(titleTypeName, parentTitleTypeName, iconColor);

	const subtype = !['Edit', 'Manifestation'].includes(titleTypeName || '') ? 'Abstraction' : titleTypeName;

	const type = !['Edit', 'Manifestation'].includes(titleTypeName || '') ? titleTypeName : parentTitleTypeName;

	return (
		<Wrapper>
			<ImageWrapper>
				<img src={icon || undefined} alt="eidrIcon" />
			</ImageWrapper>
			<TextWrapper>
				<Typography variant="buttonLargeMedium">{type}</Typography>
				<Typography color="text.secondary" variant="buttonSmallRegular">
					{subtype}
				</Typography>
			</TextWrapper>
		</Wrapper>
	);
}

export default TitleTypeInfo;
