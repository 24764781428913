import { useMemo } from 'react';
import { isEpisodeOf, TitleLightWithSelectedParent } from '@warehouse/title/core';
import useWorkTypes from '../../../src/utils/hooks/titles/useWorkTypes';
import {
	IDColumn,
	ReleaseDateColumn,
	RunLengthColumn,
	SequenceNumberColumn,
	TitleCardColumn,
} from '../ui/columns/common-columns';
import { EntryClassColumn, EntryNumberColumn } from './columns/compilation-columns';
import { DistributionNumberColumn, HouseSequenceColumn } from './columns/episode-columns';
import { EditClassesColumn, EditUseColumn, MadeForRegionsColumn } from './columns/edit-columns';
import { CustomColumnDef } from '../../../src/components/library/SimpleTable/customColumnDef';

const getEntriesColumn = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	EntryNumberColumn(),
	EntryClassColumn(),
	RunLengthColumn(),
	IDColumn(),
];

const getSeasonsColumns = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	ReleaseDateColumn(),
	SequenceNumberColumn(),
	IDColumn(),
];

const getEpisodeColumns = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	ReleaseDateColumn(),
	SequenceNumberColumn(),
	DistributionNumberColumn(),
	HouseSequenceColumn(),
	RunLengthColumn(),
	IDColumn(),
];

const getEditsColumns = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	EditUseColumn(),
	EditClassesColumn(),
	MadeForRegionsColumn(),
	RunLengthColumn(),
	IDColumn(),
];
export const manifestationsColumns: CustomColumnDef<TitleLightWithSelectedParent>[] = [
	TitleCardColumn(),
	RunLengthColumn(),
	IDColumn(),
];
export const defaultColumns: CustomColumnDef<TitleLightWithSelectedParent>[] = [TitleCardColumn(), IDColumn()];

const DEFAULT_SEARCH_FIELDS: string[] = ['indexed.titleSearchDisplayNameFull', 'indexed.releaseDate', 'indexed.uuid'];
const ENTRIES_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.runLength',
	'indexed.uuid',
];
const EPISODES_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.sequenceInfo.number',
	'indexed.sequenceInfo.distributionNumber.distributionNumber',
	'indexed.sequenceInfo.houseSequence.houseSequence',
	'indexed.runLength',
	'indexed.uuid',
];
const SEASONS_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.sequenceInfo.number',
	'indexed.uuid',
];
const EDITS_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.editUse',
	'indexed.editClasses',
	'indexed.madeForRegions.label',
	'indexed.madeForRegionsExclude.label',
	'indexed.runLength',
	'indexed.uuid',
];

export function useChildrenColumnsPerWorkType(
	workTypeUuid: string,
	relationshipType?: string,
): [CustomColumnDef<TitleLightWithSelectedParent>[], string[]] {
	const workTypes = useWorkTypes();

	return useMemo(() => {
		switch (workTypeUuid) {
			case workTypes.compilationUuid():
				return [getEntriesColumn(), ENTRIES_SEARCH_FIELDS];
			case workTypes.seasonsUuid():
				return [getEpisodeColumns(), EPISODES_SEARCH_FIELDS];
			case workTypes.seriesUuid():
				if (relationshipType === isEpisodeOf()) return [getEpisodeColumns(), EPISODES_SEARCH_FIELDS];
				return [getSeasonsColumns(), SEASONS_SEARCH_FIELDS];
			case workTypes.movieUuid():
			case workTypes.episodesUuid():
				return [getEditsColumns(), EDITS_SEARCH_FIELDS];
			default:
				return [defaultColumns, DEFAULT_SEARCH_FIELDS];
		}
	}, [workTypes, workTypeUuid, relationshipType]);
}
