import { WorkType } from '@warehouse/title/worktype';
import { TitleCommon } from './title';
import { RelationshipType } from './relationship-types';

export function isPrimaryEdit(title: TitleCommon): boolean {
	if (title.readonly.workType !== WorkType.Edit) return false;

	const { parents } = title.readonly;
	if (!parents) return false;

	return parents.some((parent) => parent.primary && parent.relationshipType === RelationshipType.DerivedFrom);
}
