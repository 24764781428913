import { RelationshipType } from '@warehouse/title/core';

export function shouldRequireConfirmationForPrimaryUpdate(
	relationshipType: RelationshipType,
	currentPrimary: boolean | undefined,
): boolean {
	return (
		!currentPrimary &&
		[RelationshipType.SeasonOf, RelationshipType.EpisodeOf, RelationshipType.DerivedFrom].includes(relationshipType)
	);
}
