import { SortingState } from '@warehouse/title/core';
import { useMemo } from 'react';
import { RelationshipSorters } from '@warehouse/relationships/domain';

export function useRelationshipSorters() {
	return useMemo<SortingState>(
		() => [
			RelationshipSorters.byRelationshipTypeFromParentRelationship(),
			RelationshipSorters.bySequenceNumberFromParentRelationship(),
			RelationshipSorters.byTitleSearchSortFull(),
		],
		[],
	);
}
