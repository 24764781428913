import React, { ChangeEvent, useCallback, useContext, useEffect, useRef } from 'react';
import { CellContext } from '@tanstack/react-table';
import { DisplayString } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/TitleMetadata';
import { TableTextInput } from '../../style';
import { CompanyDisplayCreditContext } from '../providers/companyDisplayCreditsProvider';
import useBlurAndDebounceValue from '../../../../hooks/useTitleRecordsAutoSave/useBlurAndDebounceValue';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

interface DisplayNameProps {
	info: CellContext<DisplayString, string>;
	companyDisplayCreditDisplayIndex: number;
}

export default function CellDisplayName({ info, companyDisplayCreditDisplayIndex }: DisplayNameProps) {
	const initialValue = info.getValue();
	const initialValueRef = useRef(initialValue);
	const companyDisplayCreditContext = useContext(CompanyDisplayCreditContext);
	const { isInherited } = companyDisplayCreditContext;

	useEffect(() => {
		initialValueRef.current = info.getValue();
	}, [info.getValue()]);

	const saveRef = useRef<(val: string | undefined) => void>();

	useEffect(() => {
		saveRef.current = (val: string | undefined) => {
			if (val) {
				companyDisplayCreditContext.editDisplayName(val, info.row.index, companyDisplayCreditDisplayIndex);
				companyDisplayCreditContext.commit();
			}
		};
	}, [companyDisplayCreditContext.editDisplayName, companyDisplayCreditContext.commit]);

	const {
		value,
		setValue: setValueBlurAndDebounce,
		commit: commitBlurAndDebounce,
	} = useBlurAndDebounceValue<string>({
		initialValue: initialValueRef.current,
		save: useCallback((val) => saveRef.current?.(val), [saveRef]),
	});

	useEffect(() => {
		setValueBlurAndDebounce(info.getValue(), false);
	}, [info.getValue()]);

	const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
		setValueBlurAndDebounce(evt.target.value);
	};

	return (
		<CellWrapper>
			<TableTextInput
				variant={isInherited ? 'prefilled' : 'default'}
				autoFocus={!initialValue}
				onBlur={commitBlurAndDebounce}
				value={value || ''}
				onChange={onChange}
			/>
		</CellWrapper>
	);
}
