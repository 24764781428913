import React, { useState } from 'react';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, ConfirmationModal } from '@warehouse/shared/legacy-ui';
import styled from 'styled-components';

export function DeleteButtonField({ disabled = false, onDelete }: DeleteButtonPropsProps) {
	const [confirmOpen, setConfirmOpen] = useState(false);

	return (
		<ButtonWrapper>
			<Button onClick={() => setConfirmOpen(true)} nxstyle="secondary-red" disabled={disabled}>
				<Icon path={mdiDelete} size="25px" />
			</Button>
			<ConfirmationModal
				open={confirmOpen}
				onConfirm={() => {
					setConfirmOpen(false);
					if (onDelete) onDelete();
				}}
				onClose={() => setConfirmOpen(false)}
				title="Remove relationship?"
				message="Are you sure you want to remove this relationship?"
				confirmText="Remove"
			/>
		</ButtonWrapper>
	);
}

const ButtonWrapper = styled.div`
	margin-top: auto;
	text-align: right;
`;

export interface DeleteButtonPropsProps {
	disabled?: boolean;
	onDelete?: () => void;
}
