import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

export const Wrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		display: flex;
		height: 800px;
		max-width: 1800px;
		min-width: 1400px;
		width: 100%;
	`,
);

export const FilesListColumn = styled.div(
	({ theme }) => css`
		border-right: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		width: 25%;
	`,
);

export const MidColumn = styled.div<{ isActive?: boolean }>(
	({ theme, isActive }) => css`
		background-color: ${isActive ? theme.palette.light.main : theme.palette.light.backgroundAlt};
		border-right: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 50%;
	`,
);

export const RightColumn = styled.div<{ isActive?: boolean }>(
	({ theme, isActive }) => css`
		background-color: ${isActive ? theme.palette.light.main : theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 25%;
	`,
);

export const FileList = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	`,
);

export const FileItem = styled.div<{ selected: boolean }>(
	({ theme, selected }) => css`
		align-items: center;
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		cursor: default;
		display: flex;
		flex: 1;
		justify-content: space-between;
		padding: ${theme.spacing(2)};

		button {
			background-color: ${theme.palette.light.main};
			border: solid 1px ${theme.palette.light.backgroundAlt};
			color: ${theme.palette.text.secondary};
		}

		${selected &&
		css`
			p {
				color: ${theme.palette.text.primary};
			}

			background-color: ${theme.palette.blue.background};

			button {
				background-color: ${theme.palette.blue.background};
				border: solid 1px ${theme.palette.blue.main};
				color: ${theme.palette.blue.main};
				transition-delay: 0s;
				transition-duration: 0s;

				svg {
					color: ${theme.palette.blue.main};
				}
			}
		`}

		:hover {
			p {
				color: ${theme.palette.text.primary};
			}

			background-color: ${theme.palette.blue.background};

			button {
				background-color: ${theme.palette.blue.background};
				border: solid 1px ${theme.palette.blue.main};
				color: ${theme.palette.blue.main};
				transition-delay: 0s;
				transition-duration: 0s;
			}
		}
	`,
);

export const FileItemButtonContainer = styled.div(
	() => css`
		display: flex;
		justify-content: flex-end;
	`,
);

export const FileItemName = styled(Typography)<{ failed?: boolean }>(
	({ theme, failed }) => css`
		color: ${failed ? `${theme.palette.error.text} !important` : theme.palette.text.secondary};
		font-size: 14px;
		font-weight: 500;
		max-width: 230px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		svg {
			vertical-align: middle;
		}
	`,
);

export const TopRowMidColumn = styled.div(
	({ theme }) => css`
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		height: 24px;
		padding: ${theme.spacing(1.5)};

		p {
			color: ${theme.palette.text.primary};
			font-size: 14px;
			font-weight: 500;
		}
	`,
);

export const TitleRow = styled.div<{ errored?: boolean }>(
	({ theme, errored }) => css`
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		height: 44px;
		padding: ${theme.spacing(1.5)};

		p {
			color: ${errored ? theme.palette.error.text : theme.palette.text.secondary};
			font-size: 24px;
			font-weight: 400;
		}
	`,
);

export const TrackList = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
	`,
);

export const TrackItem = styled.div<{ selected: boolean }>(
	({ theme, selected }) => css`
		align-items: center;
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		height: 65px;
		padding: ${theme.spacing(0, 3)};

		.track-dropdown {
			width: 95%;
		}

		p {
			color: ${theme.palette.text.secondary};
			font-size: 14px;
			font-weight: 500;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 320px;
		}

		button {
			background-color: ${theme.palette.light.main};
			border: solid 1px ${theme.palette.light.backgroundAlt};
			color: ${theme.palette.text.secondary};
		}

		&:hover {
			background-color: ${theme.palette.blue.background};

			p {
				color: ${theme.palette.text.primary};
			}

			button {
				background-color: ${theme.palette.blue.background};
				border: solid 1px ${theme.palette.blue.main};
				color: ${theme.palette.blue.main};
				transition-delay: 0s;
				transition-duration: 0s;
				svg {
					color: ${theme.palette.blue.main};
				}
			}
		}

		${selected &&
		css`
			background-color: ${theme.palette.blue.background};

			p {
				color: ${theme.palette.text.primary};
			}

			button {
				background-color: ${theme.palette.blue.background};
				border: solid 1px ${theme.palette.blue.main};
				color: ${theme.palette.blue.main};
				transition-delay: 0s;
				transition-duration: 0s;
				svg {
					color: ${theme.palette.blue.main};
				}
			}
		`}
	`,
);

export const TrackListHeader = styled.div(
	({ theme }) => css`
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		color: ${theme.palette.text.secondary};
		display: flex;
		padding: ${theme.spacing(2, 3)};

		svg {
			margin-left: ${theme.spacing(1)};
		}

		& div:first-child {
			align-items: center;
			display: flex;
			width: 320px;
		}

		& div:last-child {
			align-items: center;
			display: flex;
		}
	`,
);

export const LoaderWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		flex: 1;
		justify-content: center;
	`,
);

export const TrackIndex = styled.span(
	({ theme }) => css`
		border: 1px solid ${theme.palette.blue.main};
		border-radius: 6px;
		font-weight: 500;
		height: 32px;
		line-height: 32px;
		text-align: center;
		width: 32px;
	`,
);

export const TrackTitle = styled(Typography)(
	() => css`
		margin-left: 1em;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

// export const PreJsonMediaInfo = styled.pre(
// 	({ theme }) => css`
// 		overflow: scroll;
// 		padding: ${theme.spacing(1)};
// 		width: 100%;
// 	`,
// );

export const UploadFilesTitle = styled.div(
	({ theme }) => css`
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		padding: ${theme.spacing(2)};
	`,
);

export const TabsWrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	`,
);
