import React from 'react';
import { Data } from './style';

export default function getPlaceholderArea({
	bottom = false,
	right = false,
	left = false,
	name,
}: {
	bottom?: boolean;
	right?: boolean;
	left?: boolean;
	name: string;
}) {
	return <Data enableBottomBorder={bottom} enableRightBorder={right} enableLeftBorder={left} area={name} />;
}
