import React from 'react';
import MUIDialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import styled, { css } from 'styled-components';

const StyledDialogActions = styled(MUIDialogActions)<DialogActionsProps>(
	({ theme }) => css`
		justify-content: center;
		padding: ${theme.spacing(3)};

		& .MuiButton-root {
			flex-grow: 1;
			height: 32px;
		}
	`,
);

function DialogActions(props: DialogActionsProps) {
	return <StyledDialogActions {...props} />;
}

export default DialogActions;
