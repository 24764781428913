import React, { useState } from 'react';
import { People } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/People';
import { v4 as uuid } from 'uuid';
import RoleCard from './RoleCard';
import CollapsableCard from '../../../../../../components/titles/CollapsableCard';
import AddRoleButton from './AddRoleButton';

interface RolesListProps {
	people: People | undefined;
	peopleIndex: number;
}

export default function RolesList({ peopleIndex, people }: RolesListProps) {
	const [uuids, setUuids] = useState<string[]>(people?.jobs?.map(() => uuid()) ?? []);

	return (
		<CollapsableCard label="Roles">
			{people?.jobs?.map((job, idx) => (
				<RoleCard
					setUuids={setUuids}
					key={uuids[idx]}
					peopleIndex={peopleIndex}
					roleIndex={idx}
					listLength={people?.jobs?.length ?? 0}
					role={job}
				/>
			))}
			<AddRoleButton setUuids={setUuids} peopleIndex={peopleIndex} />
		</CollapsableCard>
	);
}
