import React from 'react';
import styled, { css } from 'styled-components';

// HOOKS
import {
	AspectRatioField,
	CountriesOfOriginField,
	FrameRateField,
	OriginalLanguagesField,
	ProductionCountriesField,
	ReleaseDateField,
	RunLengthField,
} from '@warehouse/title/feature-title-overview';
import useTiniestSummaryTitleData from '../../../../views/title/hooks/useTiniestSummaryTitleData';

// LIBRARY
import TiniestSummary from '../fields/TiniestSummary';
import { TitleOverviewByTypeProps } from './type';
import { TitleOverviewLeftWrapperBase, Data, NonCenteredData } from './style';
import { LicensorField } from '../fields';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar topbar'
			'licensor countriesOfOrigin synopsis'
			'releaseDate productionCountries synopsis'
			'aspectRatio originalLanguage synopsis'
			'frameRate runLength synopsis';
		grid-template-columns: 25% 25% 50%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

function Series({ editMode, topBar, displayVideoPlayer, title }: TitleOverviewByTypeProps) {
	const tiniestSummary = useTiniestSummaryTitleData({
		title,
	});

	return (
		<TitleOverviewLeftWrapper displayVideoPlayer={displayVideoPlayer}>
			{topBar}
			<NonCenteredData enableRightBorder enableBottomBorder={false} area="synopsis">
				<TiniestSummary
					tiniestSummary={tiniestSummary?.data?.displayValue || ''}
					origin=""
					isInherited={tiniestSummary.isInherited}
				/>
			</NonCenteredData>
			<Data enableLeftBorder={false} area="licensor">
				<LicensorField editMode={editMode} origin="" title={title} />
			</Data>
			<Data area="countriesOfOrigin">
				<CountriesOfOriginField />
			</Data>
			<Data enableLeftBorder={false} area="releaseDate">
				<ReleaseDateField />
			</Data>
			<Data area="productionCountries">
				<ProductionCountriesField />
			</Data>
			<Data enableLeftBorder={false} area="aspectRatio">
				<AspectRatioField />
			</Data>
			<Data area="originalLanguage">
				<OriginalLanguagesField />
			</Data>
			<Data enableBottomBorder={false} enableLeftBorder={false} area="frameRate">
				<FrameRateField />
			</Data>
			<Data enableBottomBorder={false} area="runLength">
				<RunLengthField />
			</Data>
		</TitleOverviewLeftWrapper>
	);
}

export default Series;
