import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { GridCell } from '@warehouse/shared/ui';
import { MultiTitleSearch } from './MultipleTitleSearch';
import { SelectedTitles } from './selected-titles/SelectedTitles';

export function TitleSelector({
	defaultWorkTypeUuid,
	licensor,
	supportedWorkTypeUuids,
	searchAndSelectedPluralLabel,
	headerSlot,
}: TitleSelectorProps) {
	return (
		<Wrapper>
			<GridCell gridArea="topbarTitle" spacing={2}>
				<Typography variant="h4Regular">{`Search for ${searchAndSelectedPluralLabel}`}</Typography>
			</GridCell>
			<GridCell gridArea="topbarSelected" spacing={2}>
				<Typography variant="h4Regular">{`Selected ${searchAndSelectedPluralLabel}`}</Typography>
			</GridCell>
			<MultiTitleSearch
				headerSlot={headerSlot}
				licensor={licensor}
				defaultWorkTypeUuid={defaultWorkTypeUuid}
				supportedWorkTypeUuids={supportedWorkTypeUuids}
				placeholder={`Search ${searchAndSelectedPluralLabel}`}
			/>
			<SelectedTitles workTypePluralLabel={searchAndSelectedPluralLabel} />
		</Wrapper>
	);
}

interface TitleSelectorProps {
	defaultWorkTypeUuid: string | null;
	licensor?: string;
	supportedWorkTypeUuids: string[];
	searchAndSelectedPluralLabel: string;
	headerSlot?: ReactNode;
}

const Wrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		display: grid;
		grid-template-areas:
			'topbarTitle topbarSelected'
			'searchBar selectedItems'
			'resultsItems selectedItems'
			'pagination selectedItems';
		grid-template-columns: 65% 35%;
		grid-template-rows: 60px 110px 40vh 50px;

		width: 100%;

		> * {
			border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
			border-right: 1px solid ${theme.palette.light.backgroundAlt};
		}
	`,
);
