import styled, { css } from 'styled-components';

export const Table = styled.table(
	({ theme }) => css`
		border-spacing: 0;
		color: ${theme.palette.text.primary};
		width: 100%;

		th {
			padding: ${theme.spacing(1)};
		}
		td {
			padding: ${theme.spacing(2)};
		}

		.sticky-shadow {
			box-shadow: 6px 0 12px -4px rgba(0, 0, 0, 0.1);
		}
	`,
);

export const TH = styled.th<{ sticky?: number; $pointer?: boolean }>(
	({ theme, sticky, $pointer = false }) => css`
		border: 1px solid transparent;
		${$pointer &&
		css`
			cursor: pointer;
		`}
		position: sticky;
		text-align: left;
		text-transform: uppercase;
		top: 0;

		${sticky !== undefined
			? css`
					background-color: inherit;
					left: ${sticky}px;
					z-index: 10;
			  `
			: null}

		&.selected {
			background-color: ${theme.palette.blue.background};
			border-left: 1px solid ${theme.palette.blue.main};
			border-right: 1px solid ${theme.palette.blue.main};
			border-top: 1px solid ${theme.palette.blue.main};
		}
	`,
);

export const TD = styled.td<{ sticky?: number; enablePadding?: boolean }>(
	({ theme, sticky, enablePadding }) => css`
		border: 1px solid transparent;
		${enablePadding
			? css`
					padding: ${theme.spacing(0, 2)} !important;
			  `
			: css`
					padding: 0 !important;
			  `}
		scroll-snap-align: start;
		text-align: left;

		${sticky !== undefined
			? css`
					background-color: inherit;
					left: ${sticky}px;
					position: sticky;
					z-index: 9;
			  `
			: null}

		&.selected {
			border-left: 1px solid ${theme.palette.blue.main};
			border-right: 1px solid ${theme.palette.blue.main};
		}
	`,
);

export const TR = styled.tr<{
	pointer?: boolean;
	rowIndex?: number;
	canExpand?: boolean;
}>(
	({ pointer, theme, rowIndex, canExpand }) => css`
		${rowIndex !== undefined && rowIndex % 2 === 0
			? css`
					background-color: ${theme.palette.light.main};
			  `
			: css`
					background-color: ${theme.palette.light.background};
			  `}

		${pointer &&
		css`
			cursor: pointer;
		`}

		height: ${canExpand ? '40px' : '48px'};
		text-align: left;

		.show-on-hover {
			visibility: hidden;
		}

		&:hover {
			.highlighted-cell {
				border: solid 1px ${theme.palette.blue.main};
				border-radius: 9px;
				box-sizing: border-box;
			}
		}
		.highlighted-cell {
			border: solid 1px transparent;
		}
	`,
);

export const THead = styled.thead(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		position: sticky;
		top: 0;
		z-index: 19;

		tr:first-child th:first-child {
			border-top-left-radius: 8px;
		}
		tr:first-child th:last-child {
			border-top-right-radius: 8px;
		}
	`,
);

export const TBody = styled.tbody(
	({ theme }) => css`
		tr:hover {
			background-color: ${theme.palette.blue.background};
		}
	`,
);

export const ResizeHandle = styled.div(
	() => css`
		background: transparent;
		cursor: col-resize;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		touch-action: none;
		user-select: none;
		width: 5px;
	`,
);
