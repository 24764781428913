import { ColumnDef } from '@tanstack/react-table';
import { FilteringState, FilterSortModalItem } from '../../types/table';

export type ColumnDefType<T> = ColumnDef<T, any> & {
	indexedTitleKey: string;
	highlight?: boolean;
};

export function convertColumnsToSortFilterModalItems(columns: ColumnDefType<any>[]) {
	const res: FilterSortModalItem[] = [];
	columns.forEach((column) => {
		if (column.id) {
			res.push({
				id: column.indexedTitleKey || column.id,
				label: column.header?.toString(),
			});
		}
	});
	return res.sort((a, b) => (a.label ?? a.id).localeCompare(b.label ?? b.id));
}

export function validateSubmit(filteringState: FilteringState) {
	let isValid: boolean = false;
	if (filteringState.combinationOperator.length > 0) {
		if (filteringState.filters.length > 0) {
			filteringState.filters.every((value) => {
				if (value.field.length > 0 && value.operation.length > 0 && value.value.length > 0) {
					isValid = true;
				} else {
					isValid = false;
					return false;
				}
				return isValid;
			});
		} else {
			// If filter empty we can still submit
			return true;
		}
	} else {
		isValid = false;
	}
	return isValid;
}
