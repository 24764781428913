import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_POSTER = gql`
	query GetPoster($uuid: ID!) {
		getPoster(titleUuid: $uuid) {
			backdrop
			poster
		}
	}
`;
