import React, { ReactElement, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { autoUpdate, FloatingFocusManager, FloatingPortal, size, useFloating } from '@floating-ui/react';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, Typography } from '@mui/material';
import { mdiClose, mdiInformation, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { FixedSizeList, FixedSizeList as List, ListOnItemsRenderedProps } from 'react-window';

import WarningIcon from '@mui/icons-material/Warning';
import InfiniteLoader from 'react-window-infinite-loader';
import classNames from 'classnames';
import Chip from '../Chip';
import Tooltip from '../Tooltip';
import {
	computeItemCount,
	filterOptions,
	isItemLoaded,
	Label,
	LabelBlockWrapper,
	PlaceHolderText,
	ReadOnlyValue,
	SearchInput,
	SearchWrapper,
	TriggerIcons,
	Wrapper,
} from '../DropdownUtils';
import { SubMessage, SubMessageWrapper, TooltipIcon } from '../InputUtils';
import ConditionalWrapper from '../../../utils/ConditionalWrapper';
import { DropdownOption, DropdownProps } from './types';
import {
	MenuList,
	MenuWrapper,
	PlaceholderChip,
	SelectedChipHidden,
	SelectedItemChipWrapper,
	StyledCustomBadge,
	TriggerWrapper,
} from './styles';
import Row from './Row';
import escapeStringForRegexp from '../../../utils/escapeStringForRegexp';

function SelectDropdown(props: DropdownProps) {
	const {
		ariaLabel,
		options,
		values,
		onSelect,
		onBlur,
		label,
		inlineLabel,
		readOnly,
		disabled,
		searchPlaceHolder,
		placeholder,
		placeholderArray,
		placeholderStyle,
		error,
		height,
		enablePortal,
		width,
		forceActive,
		defaultOptions,
		disabledMargin,
		tooltip,
		transparentBorder,
		portalRef,
		placement = 'bottom',
		infiniteLoaderProps,
		backendSearch,
		labelIcon,
		bottomActions,
		variant,
	} = props;
	const [active, setActive] = useState(forceActive || false);
	const [searchValue, setSearchValue] = useState('');
	const [displayValues, setDisplayValues] = useState<DropdownOption[]>([]);
	const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>([]);
	const [visibleNb, setVisibleNb] = useState<number>(-1);
	const [isFloatingOpen, setIsFloatingOpen] = useState<boolean>(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(0);

	const portalWrapperRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<FixedSizeList>(null);
	const menuWrapperEl = useRef<HTMLDivElement>(null);
	const hiddenContainerEl = useRef<HTMLDivElement>(null);
	const chipContainerEl = useRef<HTMLDivElement>(null);
	const searchInputEl = useRef<HTMLInputElement>(null);

	const itemCount = computeItemCount(
		infiniteLoaderProps,
		!!backendSearch,
		defaultOptions?.length || 0,
		searchValue.length,
		filteredOptions.length,
		options.length,
	);

	useEffect(() => {
		if (!!backendSearch && searchValue !== backendSearch.searchValue) {
			backendSearch.setSearchValue(searchValue);
		}
	}, [searchValue]);

	useEffect(() => {
		if (!!backendSearch && searchValue !== backendSearch.searchValue) {
			setSearchValue(backendSearch.searchValue);
		}
	}, [backendSearch?.searchValue]);

	useEffect(() => {
		if (searchValue !== '' && highlightedIndex < 0) setHighlightedIndex(0);
		if (searchValue !== '' && itemCount !== 0 && highlightedIndex >= itemCount) setHighlightedIndex(itemCount - 1);
	}, [searchValue, itemCount]);

	const { refs, floatingStyles, context } = useFloating({
		open: isFloatingOpen,
		onOpenChange: setIsFloatingOpen,
		middleware: [
			size({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.reference.width - 2}px`,
					});
				},
			}),
		],
		placement,
		whileElementsMounted: autoUpdate,
	});

	const handleSelect = (selected: string | null, close = true) => {
		if (selected === null) return;

		onSelect(selected);
		if (searchInputEl.current && close) {
			searchInputEl.current.blur();
		}
	};

	const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
		if (
			!e.currentTarget?.contains(e.relatedTarget as Node) &&
			!portalWrapperRef?.current?.contains(e.relatedTarget as Node)
		) {
			setActive(false);
			if (onBlur) onBlur();
		}
	};

	const onKeyPressed = (e: KeyboardEvent<HTMLDivElement>) => {
		let newIndex;
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			newIndex = Math.min(highlightedIndex + 1, itemCount - 1);
			setHighlightedIndex(newIndex);
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			newIndex = Math.max(highlightedIndex - 1, 0);
			setHighlightedIndex(newIndex);
		} else if (e.key === 'Enter' && highlightedIndex >= 0) {
			if (searchValue?.length && filteredOptions?.length) {
				handleSelect(filteredOptions[highlightedIndex].value, false);
			} else if (defaultOptions?.length) {
				if (highlightedIndex < defaultOptions.length + 1) {
					handleSelect(defaultOptions[highlightedIndex - 1].value, false);
				} else {
					handleSelect(options[highlightedIndex - defaultOptions.length - 1].value, false);
				}
			} else {
				handleSelect(options[highlightedIndex].value, false);
			}
		} else if (e.key === 'Escape') {
			setHighlightedIndex(0);
			handleSelect(null);
		}

		if (newIndex !== undefined) {
			listRef.current?.scrollToItem(newIndex);
		}

		if (!e.altKey && !e.ctrlKey && e.key.length === 1) {
			searchInputEl.current?.focus();
		}
		if (!e.altKey && !e.ctrlKey && e.key === 'Tab') {
			searchInputEl.current?.blur();
			// @ts-ignore
			refs.reference.current?.focus();
		}
	};

	useEffect(() => {
		if (searchValue.length) {
			const regexStr = `.*${escapeStringForRegexp(searchValue).toLowerCase()}.*`;
			const searchRegex = new RegExp(regexStr);
			setFilteredOptions(options.filter(filterOptions(searchRegex)));
		}
	}, [searchValue]);

	useEffect(() => {
		const res: DropdownOption[] = [];
		values?.forEach((value) => {
			const option = options.find((opt) => opt.value === value) ?? defaultOptions?.find((opt) => opt.value === value);
			if (option) res.push(option);
		});
		setDisplayValues(res);
	}, [values, options]);

	const renderDisplayValues = (dv: DropdownOption[]) =>
		dv.map((option) => (
			<Chip
				height={height ? height - 20 : undefined}
				maxWidth="calc(100% - 30px)"
				label={option.label}
				key={option.value}
			/>
		));

	const calculateOverflow = () => {
		if (chipContainerEl.current && displayValues.length) {
			const el = chipContainerEl.current;
			const margin = 50;
			const effectiveWidth = el.clientWidth - margin;

			let totalWidth = 0;
			let cutoffIndex = 0;

			Array.from(hiddenContainerEl?.current?.children || []).forEach((child, index) => {
				totalWidth += (child as HTMLElement).offsetWidth;
				if (totalWidth < effectiveWidth) {
					cutoffIndex = index + 1;
				}
			});

			const computedCutoffIndex = Math.max(cutoffIndex, 1);
			setVisibleNb(computedCutoffIndex);
		}
		if (!displayValues.length) setVisibleNb(-1);
	};

	useEffect(() => {
		calculateOverflow();
		const resizeObserver = new ResizeObserver(() => {
			calculateOverflow();
		});

		if (chipContainerEl.current) resizeObserver.observe(chipContainerEl.current);
		return () => {
			if (chipContainerEl.current) resizeObserver.unobserve(chipContainerEl.current);
		};
	}, [values, displayValues]);

	const hideOverflownElement = (elem: DropdownOption[]) => {
		if (visibleNb === -1 || !elem.length) return [];
		if (visibleNb === 0) return elem;
		return elem.slice(0, visibleNb);
	};

	const renderDisplayInput = (hideOverflow: boolean = false) => {
		if (displayValues.length > 0)
			return hideOverflow
				? renderDisplayValues(hideOverflownElement(displayValues))
				: renderDisplayValues(displayValues);

		if (placeholderArray && placeholderArray.length > 0) {
			return placeholderArray?.map((value) => (
				<PlaceholderChip height={height ? height - 20 : undefined} label={value} key={value} />
			));
		}

		return <PlaceHolderText placeholderStyle={placeholderStyle}>{placeholder}</PlaceHolderText>;
	};

	const portalWrapper = (children: ReactElement) => (
		<FloatingPortal root={portalRef?.current || document.body}>
			<FloatingFocusManager context={context} modal={false} returnFocus={false}>
				<div ref={portalWrapperRef}>{children}</div>
			</FloatingFocusManager>
		</FloatingPortal>
	);

	function renderList(
		onItemsRendered?: (props: ListOnItemsRenderedProps) => any | undefined,
		ref?: (ref: any) => void | undefined,
	) {
		const sufficientHeight = itemCount * (height || 48);

		const getHeight = () => {
			if (sufficientHeight < 224) return sufficientHeight;
			return 224;
		};

		return (
			<List
				onItemsRendered={onItemsRendered}
				ref={ref}
				height={getHeight()}
				itemCount={itemCount}
				itemData={{
					highlightedIndex,
					options,
					values,
					searchValue,
					handleSelect,
					defaultOptions,
					filteredOptions,
					height,
				}}
				itemSize={height || 48}
				width={menuWrapperEl?.current?.clientWidth!}
			>
				{Row}
			</List>
		);
	}

	return (
		<Wrapper
			aria-label={`${ariaLabel} Dropdown Wrapper`}
			disabledMargin={disabledMargin}
			inlineLabel={inlineLabel}
			disabled={disabled}
			width={width}
			className={classNames({ inherited: variant === 'prefilled' })}
		>
			{label && (
				<Label variant="buttonLargeMedium" error={error}>
					<>
						<LabelBlockWrapper>
							{label}
							{tooltip && (
								<Tooltip title={tooltip} placement="right">
									<TooltipIcon path={mdiInformation} size="16px" />
								</Tooltip>
							)}
						</LabelBlockWrapper>
						{labelIcon}
					</>
				</Label>
			)}
			{readOnly || disabled ? (
				<div className="displayValues">
					<ReadOnlyValue $height={height ?? 46}>
						{renderDisplayValues(displayValues) || placeholder || ''}
					</ReadOnlyValue>
				</div>
			) : (
				<div>
					<TriggerWrapper
						ref={refs.setReference}
						className="input-wrapper"
						active={active}
						tabIndex={0}
						onKeyDown={onKeyPressed}
						transparentBorder={transparentBorder}
						onClick={() => {
							if (searchInputEl.current) searchInputEl.current.focus();
						}}
						onFocus={() => setActive(true)}
						onBlur={handleBlur}
						error={error}
						height={height}
						placement={placement}
					>
						<SelectedItemChipWrapper ref={chipContainerEl}>
							{visibleNb !== -1 && (
								<>
									{renderDisplayInput(true)}
									{displayValues.length - visibleNb > 0 && (
										<Tooltip
											followCursor={false}
											placement="top"
											variant="dark"
											title={
												<>
													{displayValues.slice(visibleNb).map((value, index) => (
														<div key={index}>
															<Typography variant="buttonSmallRegular" color="inherit">
																{value.label}
																{index < displayValues.length - index - 1 && ', '}
															</Typography>
														</div>
													))}
												</>
											}
										>
											<StyledCustomBadge badgeContent={`+${displayValues.length - visibleNb}`} />
										</Tooltip>
									)}
								</>
							)}
							<SelectedChipHidden ref={hiddenContainerEl}>{renderDisplayInput()}</SelectedChipHidden>
						</SelectedItemChipWrapper>
						<TriggerIcons>
							<DownIcon className="caretIcon" />
						</TriggerIcons>

						<ConditionalWrapper condition={!!enablePortal} wrapper={portalWrapper}>
							<MenuWrapper
								className="menu-wrapper"
								ref={enablePortal ? refs.setFloating : menuWrapperEl}
								enablePortal={enablePortal}
								active={active}
								height={height}
								style={enablePortal ? floatingStyles : undefined}
								bottomActions={bottomActions !== undefined}
								placement={placement}
							>
								<SearchWrapper height={height}>
									<Icon size="20px" path={mdiMagnify} />
									<SearchInput
										ref={searchInputEl}
										placeholder={searchPlaceHolder || 'Search'}
										value={searchValue}
										onChange={(e) => setSearchValue(e.target.value)}
									/>
									<IconButton onClick={() => setSearchValue('')}>
										<Icon size="20px" path={mdiClose} />
									</IconButton>
								</SearchWrapper>
								<MenuList>
									{infiniteLoaderProps ? (
										<InfiniteLoader
											isItemLoaded={(index) =>
												isItemLoaded(
													index,
													infiniteLoaderProps,
													!!backendSearch,
													defaultOptions,
													searchValue,
													filteredOptions,
													options,
												)
											}
											itemCount={itemCount}
											loadMoreItems={infiniteLoaderProps!.loadMoreItems}
										>
											{({ onItemsRendered, ref }) =>
												renderList(onItemsRendered, (listInstance) => {
													ref(listInstance);
													// @ts-ignore
													listRef.current = listInstance;
												})
											}
										</InfiniteLoader>
									) : (
										renderList(undefined, (listInstance) => {
											// @ts-ignore
											listRef.current = listInstance;
										})
									)}
								</MenuList>
								{bottomActions}
							</MenuWrapper>
						</ConditionalWrapper>
					</TriggerWrapper>
					{error && (
						<SubMessageWrapper style={{ position: 'absolute', right: 0, bottom: -36 }}>
							<SubMessage color="error">
								<WarningIcon />
								<Typography>{error}</Typography>
							</SubMessage>
						</SubMessageWrapper>
					)}
				</div>
			)}
		</Wrapper>
	);
}

export default SelectDropdown;
