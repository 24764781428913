import { Rating } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

export default function computeErrors(ratings: Inherited<Rating>[]): {
	[columnId: string]: number[];
} {
	const result: { [columnId: string]: number[] } = {
		territory: [],
		ratingSystem: [],
		ratingValue: [],
	};
	ratings.forEach((rating, index) => {
		if (!rating?.displayValue?.region?.country && !rating?.displayValue?.region?.countryRegion) {
			result.territory.push(index);
		}

		if (!rating?.displayValue?.system) {
			result.ratingSystem.push(index);
		}

		if ((rating?.displayValue?.value || []).length <= 0) {
			result.ratingValue.push(index);
		}
	});

	return result;
}
