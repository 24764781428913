import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ExportTitleInput, ExportTitleOutput } from '../../../graphql/codegen/graphql';
import { EXPORT_TITLE } from '../../../graphql/queries/title';

// eslint-disable-next-line import/prefer-default-export
export function useExportTitle(uuid: string) {
	const [loadExport, { data, loading, error }] = useLazyQuery<
		{
			exportTitle: ExportTitleOutput;
		},
		{
			input: ExportTitleInput;
		}
	>(EXPORT_TITLE, {
		variables: {
			input: {
				uuid,
			},
		},
		fetchPolicy: 'no-cache',
	});

	const wrappedLoadExport = useCallback((type: string) => (
		loadExport({ variables: { input: { uuid, type } }})
	), [loadExport, uuid])

	return { loadExport: wrappedLoadExport, data, loading, error };
}
