import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { TitleCommon } from './title';
import { isPrimaryEdit } from './parent.service';

export function resetInheritedArrayValues<T>(newValue: T[] | undefined, title: TitleCommon) {
	return (prev: OptionalInherited<T[]>) => {
		const hasToReInherit = !newValue?.length && !isPrimaryEdit(title);
		return {
			...prev,
			displayValue: hasToReInherit ? prev?.inherited.value || [] : newValue,
		};
	};
}
