import React, { SyntheticEvent, ReactNode } from 'react';
import { alpha, Box } from '@mui/material';
import { TreeView, TreeItem, TreeItemProps, TreeViewProps } from '@mui/lab';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import styled, { css } from 'styled-components';
import { mdiInformation } from '@mdi/js';

import Tooltip from '../library/Tooltip';
import { TooltipIcon } from '../library/InputUtils';
import DraggableItem from './DraggableItem';

interface ContentLibTreeViewProps {
	data: Items[];
	expanded?: string[];
	selected?: string[];
	handleNodeToggle?: (event: SyntheticEvent, value: string[]) => void;
	handleNodeSelect?: (event: SyntheticEvent, value: string[]) => void;
}

export type Items = {
	title: string;
	root?: boolean;
	tooltip?: string;
	id: string;
	startIcon?: ReactNode;
	action?: ReactNode;
	children?: Items[];
};

interface ITreeItemProps extends TreeItemProps {
	$isRoot: boolean;
	$hasChildren: boolean;
}

export const StyledTreeItem = styled(TreeItem)<ITreeItemProps>(
	({ theme, $isRoot, $hasChildren }) => css`
		position: relative;
		& .MuiTreeItem-label {
			background-color: white;
			z-index: 9999;
			border: ${!$hasChildren && !$isRoot ? `1px solid ${alpha(theme.palette.dark.main, 0.1)} !important` : null};
			border-radius: 4px;
			margin: 4px 0;
			padding: ${$hasChildren ? '2px 0 ' : '8px 0 !important'};
			color: ${$hasChildren && !$isRoot
				? `${theme.palette.text.primary}`
				: `${theme.palette.text.secondary} !important`};
			font-size: 12px;
			font-weight: 500;

			${$isRoot &&
			css`
				color: ${theme.palette.text.primary} !important;
				font-size: 14px;
			`}

			${!$hasChildren &&
			!$isRoot &&
			css`
				margin-left: -27px !important;
			`}
		}

		${!$isRoot &&
		css`
			&:before {
				border-bottom: 1.5px solid ${alpha(theme.palette.dark.main, 0.1)};
				border-bottom-left-radius: 4px;
				content: '';
				height: 1.1em;
				left: -14px;
				position: absolute;
				top: 0;
				width: 8px;
			}

			&:last-child::before {
				border-left: 1.5px solid ${alpha(theme.palette.dark.main, 0.1)};
			}

			&:after {
				border-left: 1.5px solid ${alpha(theme.palette.dark.main, 0.1)};
				bottom: 0;
				content: '';
				height: 100%;
				left: -14px;
				position: absolute;
				width: 8px;
			}
		`}

		& .MuiTreeItem-content {
			box-sizing: border-box;
			padding-bottom: ${$hasChildren ? '4px' : '0'};
		}

		& .Mui-selected {
			${!$hasChildren &&
			css`
				border: transparent !important;
				.MuiTreeItem-label {
					background-color: transparent !important;
					border: 1px solid ${theme.palette.blue.main} !important;
					border-radius: 4px;
				}
			`}

			${$hasChildren &&
			css`
				background-color: transparent !important;
				border: 1px solid ${theme.palette.blue.main};
				border-radius: 4px;
			`}
		}

		&:hover {
			.MuiTreeItem-content {
				background-color: transparent !important;
			}
		}

		//&:last-child::after {
		//	display: none;
		//}
	`,
);

const StyledTreeView = styled(TreeView)<TreeViewProps>(
	() => css`
		& .MuiCollapse-root {
			margin-left: 30px;
		}

		& .Mui-focused {
			background-color: transparent !important;
		}

		& .Mui-selected.Mui-focused {
			background-color: transparent !important;
		}
	`,
);

const Wrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		justify-content: space-between;
	`,
);

const LabelWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
	`,
);
const StartIconWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		margin: 0 ${theme.spacing(1)};
	`,
);

const ActionWrapper = styled.div(
	() => css`
		& .MuiButtonBase-root {
			height: 26px;
			width: 56px;
		}
	`,
);

const CustomExpandMore = styled(ExpandMore)`
	font-size: 16px !important;
`;

const CustomChevronRight = styled(ChevronRight)`
	font-size: 16px !important;
`;

const StyledToolTipIcon = styled(TooltipIcon)(
	({ theme }) => css`
		color: ${theme.palette.text.secondary};
	`,
);

export function TreeItemOverlay({ item }: { item: Items }) {
	if (item) {
		const { id, title, startIcon } = item;
		return (
			<DraggableItem id={id}>
				<StyledTreeItem
					key={title}
					nodeId={id}
					$isRoot={false}
					$hasChildren={false}
					label={
						<Box>
							<Wrapper>
								<LabelWrapper>
									{startIcon && <StartIconWrapper>{startIcon}</StartIconWrapper>}
									{title}
								</LabelWrapper>
							</Wrapper>
						</Box>
					}
				/>
			</DraggableItem>
		);
	}
	return null;
}

function ContentLibTreeView({ data, expanded, selected, handleNodeToggle, handleNodeSelect }: ContentLibTreeViewProps) {
	const renderTree = (nodes: Items) => {
		if (!nodes || Object.keys(nodes).length === 0) return null;

		const item = (
			<Wrapper>
				<LabelWrapper>
					{nodes.startIcon && <StartIconWrapper>{nodes.startIcon}</StartIconWrapper>}
					{nodes.title}
					{nodes.tooltip && (
						<Tooltip title={nodes.tooltip} placement="right">
							<StyledToolTipIcon path={mdiInformation} size="12px" />
						</Tooltip>
					)}
				</LabelWrapper>
				{nodes.action && <ActionWrapper>{nodes.action}</ActionWrapper>}
			</Wrapper>
		);

		return (
			<DraggableItem id={nodes.id}>
				<StyledTreeItem
					key={nodes.title}
					nodeId={nodes.id}
					$isRoot={!!nodes.root}
					$hasChildren={!!nodes.children}
					label={
						<Box>
							{/* {!nodes.children ? ( */}
							{/*	<DraggableItem id={nodes.id}>{item}</DraggableItem> */}
							{/* ) : ( */}
							{/*	item */}
							{/* )} */}
							{item}
						</Box>
					}
				>
					{Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
				</StyledTreeItem>
			</DraggableItem>
		);
	};

	return (
		<>
			{data.map((node) => (
				<StyledTreeView
					defaultCollapseIcon={<CustomExpandMore />}
					defaultExpandIcon={<CustomChevronRight />}
					expanded={expanded}
					selected={selected}
					onNodeToggle={handleNodeToggle}
					onNodeSelect={handleNodeSelect}
					key={node.id}
				>
					{renderTree(node)}
				</StyledTreeView>
			))}
		</>
	);
}

export default ContentLibTreeView;
