import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { CountryOfOriginRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// HOOKS
import useTerritories from '../../../../utils/hooks/useTerritories';

// LIBRARIES
import { TerritoriesSelector } from '../../../../components/titles/TerritoriesSelector';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface CountriesOfOriginFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	inheritedValues?: Title | undefined;
}

function CountriesOfOriginField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	inheritedValues,
}: CountriesOfOriginFieldProps) {
	const { territories } = useTerritories();
	const inheritedCOO = getTitleData<OptionalInherited<CountryOfOriginRegion[]>>(
		inheritedValues,
		fromBasic(['countriesOfOrigin']),
	);
	const tooltip = useTooltip('coreMetadata.basic.countriesOfOrigin');

	return useMemo(
		() => (
			<Controller
				name="countriesOfOrigin"
				control={control}
				render={({ field }) => (
					<TerritoriesSelector
						tooltip={tooltip?.tooltip}
						label={getLabelWithRequired('Countries of Origin', isRequired)}
						width={576}
						multiple
						error={errors?.countriesOfOrigin?.message as string}
						placeholder={
							(inheritedCOO?.displayValue?.map(
								(coo) => territories?.find((territory) => territory.value === coo.country)?.label,
							) as string[]) || []
						}
						value={field.value}
						onSelect={async (value) => {
							setValue('countriesOfOrigin', [...field.value, value]);
							if (isRequired && !inheritedCOO?.displayValue?.length) {
								await trigger('countriesOfOrigin');
							}
						}}
						onDeselect={async (value) => {
							setValue(
								'countriesOfOrigin',
								field.value.filter((item: any) => item !== value),
							);
							if (isRequired && !inheritedCOO?.displayValue?.length) {
								await trigger('countriesOfOrigin');
							}
						}}
					/>
				)}
			/>
		),
		[control, inheritedCOO?.displayValue, territories, errors?.countriesOfOrigin?.message, trigger],
	);
}

export default CountriesOfOriginField;
