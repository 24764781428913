import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import TimeInput from '../../../../components/library/TimeInput';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface RunLengthFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	inheritedValues?: Title | undefined;
}

function RunLengthField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	inheritedValues,
}: RunLengthFieldProps) {
	const runLengthTooltip = useTooltip('coreMetadata.basic.runLength');
	const inheritedRunLength = getTitleData<OptionalInherited<string>>(inheritedValues, fromBasic(['runLength']));

	return useMemo(
		() => (
			<Controller
				name="approximateLength"
				control={control}
				render={({ field }) => (
					<TimeInput
						placeholder={inheritedRunLength?.displayValue}
						variant="prefilled"
						tooltip={runLengthTooltip?.tooltip}
						error={errors?.approximateLength?.message as string}
						value={field.value}
						onChange={async (e) => {
							setValue('approximateLength', e);
							if (isRequired && !inheritedRunLength?.displayValue) {
								await trigger('approximateLength');
							}
						}}
						label={getLabelWithRequired('Run Length', isRequired)}
					/>
				)}
			/>
		),
		[control, errors?.approximateLength?.message, inheritedRunLength?.displayValue, setValue, trigger],
	);
}

export default RunLengthField;
