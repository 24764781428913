import React, { ChangeEvent } from 'react';
import { FormControlLabel, RadioGroup, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import Radio from '../library/Radio';

const StyledRadioGroup = styled(RadioGroup)<{ spacing: number }>(
	({ theme, spacing }) => css`
		border-top: 1px solid rgba(177, 188, 195, 0.45);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		container-type: inline-size;
		display: flex;
		gap: ${theme.spacing(spacing)};
		height: 48px;

		justify-content: center;
		padding: ${theme.spacing(0, 1)};
	`,
);

const StyledRadio = styled(Radio)(
	({ theme }) => css`
		&.Mui-checked svg {
			color: ${theme.palette.blue.main} !important;
		}
		svg {
			height: 13px;
			width: 13px;
		}

		&.MuiRadio-root {
			padding: ${theme.spacing(0.5)};
		}
	`,
);

const StyledFormControlLabel = styled(FormControlLabel)(
	() => css`
		&.MuiFormControlLabel-root {
			margin: 0;
			padding: 0;
			& .MuiButtonBase-root {
				padding: 0;
			}
		}

		& > p {
			margin-left: 8px !important;
		}
	`,
);

interface IncludeExcludeBottomActionProps {
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	value: string;
	defaultValue: string;
	spacing?: number;
}

function includeExcludeBottomAction({ value, onChange, defaultValue, spacing = 2 }: IncludeExcludeBottomActionProps) {
	return (
		<div id="include-exclude-wrapper">
			<StyledRadioGroup
				spacing={spacing}
				id="mui-form-group-include-exclude"
				row
				aria-labelledby="demo-radio-buttons-group-label"
				defaultValue={defaultValue}
				value={value}
				name="radio-buttons-group"
				onChange={(e) => onChange(e)}
			>
				<StyledFormControlLabel
					value="includes"
					control={<StyledRadio />}
					label={
						<Typography fontSize="14px" color="text.secondary">
							Includes
						</Typography>
					}
				/>
				<StyledFormControlLabel
					value="excludes"
					control={<StyledRadio />}
					label={
						<Typography fontSize="14px" color="text.secondary">
							Excludes
						</Typography>
					}
				/>
			</StyledRadioGroup>
		</div>
	);
}

export default includeExcludeBottomAction;
