import React, { ChangeEvent, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

// TYPES
import { DropdownV2 } from '@warehouse/shared/ui';
import { CastAndCrewContext, castRoles, getMaxBillingBlockOrder } from '../providers/castAndCrewProvider';

// LIBRARY
import TextInput from '../../../../../../components/library/TextInput';

// JSON
import RoleJSON from '../../../../../../assets/json-administration-profiles/availableRoleType.json';

interface RoleAndBBOProps {
	role?: string;
	bbo?: number;
	peopleIndex: number;
	roleIndex: number;
}

const RoleWrapper = styled.div(
	({ theme }) => `
		display: flex;
		gap: ${theme.spacing(2)};
	`,
);

const castOptions = RoleJSON.filter((role) => ['actor', 'self', 'guest'].includes(role.value)).map((role) => ({
	label: role.label,
	value: role.value,
}));

export default function RoleAndBBO({ peopleIndex, roleIndex, role, bbo }: RoleAndBBOProps) {
	const { data, defaultLocale, isInherited, editData, rolesOptions, addNewRoleOption, castData, crewData } =
		useContext(CastAndCrewContext);

	const characters = useMemo(
		() => data?.displayValue?.[peopleIndex]?.jobs[roleIndex]?.characterInfos || [],
		[data, peopleIndex, roleIndex],
	);

	const onRoleChange = (e: string | null) => {
		if (e === null) return;
		if (e.trim() === '') return;

		const newRole = e.trim();
		const newBBO = castRoles.includes(newRole)
			? getMaxBillingBlockOrder(castData) + 1
			: getMaxBillingBlockOrder(crewData) + 1;

		const lastBBO = data?.displayValue?.[peopleIndex]?.jobs[roleIndex]?.billingBlockOrders?.[0]?.billingBlockOrder;

		let characterInfos;
		if (castRoles.includes(newRole) && (!characters || characters.length === 0)) {
			characterInfos = [
				{
					characterNames: [
						{
							characterName: '',
							language: defaultLocale,
						},
					],
				},
			];
		}

		editData(
			`people.${peopleIndex}.jobs.${roleIndex}`,
			{
				...data?.displayValue?.[peopleIndex]?.jobs[roleIndex],
				billingBlockOrders: [{ billingBlockOrder: lastBBO || newBBO }],
				jobFunction: newRole,
				...(characterInfos && { characterInfos }),
			},
			true,
		);
	};

	const updateBBO = (value: string, isBlur: boolean) => {
		if (value.trim() === '') {
			editData(`people.${peopleIndex}.jobs.${roleIndex}.billingBlockOrders`, [], isBlur);
		} else {
			editData(
				`people.${peopleIndex}.jobs.${roleIndex}.billingBlockOrders`,
				[
					{
						billingBlockOrder: parseInt(value, 10),
					},
				],
				isBlur,
			);
		}
	};

	const onBBOChange = ({ target }: ChangeEvent<HTMLInputElement>) => updateBBO(target.value, false);
	const onBBOBlur = ({ target }: ChangeEvent<HTMLInputElement>) => updateBBO(target.value, true);

	return (
		<RoleWrapper>
			<div style={{ flex: 9 }}>
				<Typography style={{ marginBottom: 4 }} component="p" color="text.secondary" variant="buttonMediumRegular">
					Role
				</Typography>
				<DropdownV2
					variant={isInherited ? 'prefilled' : 'default'}
					onNewOptionAdded={addNewRoleOption}
					pickList={characters?.length === 0}
					withSearch
					disabledMargin
					height={38}
					listItemHeight={38}
					value={role ?? null}
					onChange={onRoleChange}
					options={characters?.length > 0 ? castOptions : rolesOptions}
					error={role?.toLowerCase() ? undefined : ''}
				/>
			</div>
			<div style={{ flex: 1 }}>
				<Typography style={{ marginBottom: 4 }} component="p" color="text.secondary" variant="buttonMediumRegular">
					Billing Block Order
				</Typography>
				<TextInput
					variant={isInherited ? 'prefilled' : 'default'}
					height={38}
					disabledMargin
					value={bbo?.toString()}
					onBlur={onBBOBlur}
					placeholder="Auto"
					onChange={onBBOChange}
					type="number"
				/>
			</div>
		</RoleWrapper>
	);
}
