import React, { useMemo } from 'react';
import NumberInput from '../../../src/components/library/NumberInput';

export function IntegerInput({ ariaLabel, value, onChange, required, onBlur, placeholder }: NumberInputProps) {
	const stringValue = useMemo(() => (value ? String(value) : undefined), [value]);

	const onStringChange = required
		? (event: React.ChangeEvent<HTMLInputElement>) => {
				const newStringValue = event.target.value;
				const parsedValue = parseIntRequired(newStringValue);
				if (Number.isNaN(parsedValue)) return;
				onChange(parsedValue);
		  }
		: (event: React.ChangeEvent<HTMLInputElement>) => {
				const newStringValue = event.target.value;
				const parsedValue = parseIntNullable(newStringValue);
				if (Number.isNaN(parsedValue)) return;
				onChange(parsedValue);
		  };

	const stringPlaceholder = useMemo(() => (placeholder ? String(placeholder) : undefined), [placeholder]);

	return (
		<NumberInput
			ariaLabel={ariaLabel}
			value={stringValue}
			onChange={onStringChange}
			onBlur={onBlur}
			disabledMargin
			disablePenIcon
			placeholder={stringPlaceholder}
		/>
	);
}

type NumberInputProps = (Input | RequiredInput) & CommonProps;

type Input = {
	value: number | undefined;
	onChange: (value: number | undefined) => void;
	required: false;
	placeholder?: string | number;
};

type RequiredInput = {
	value: number;
	onChange: (value: number) => void;
	required: true;
	placeholder?: undefined;
};

type CommonProps = {
	ariaLabel?: string;
	onBlur?: () => void;
};

function parseIntRequired(value: string): number {
	if (value === '') return 0;
	return parseInt(value, 10);
}

function parseIntNullable(value: string): number | undefined {
	if (value === '') return undefined;
	return parseInt(value, 10);
}
