import React, { ReactNode } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import AntdProvider from '../components/library/AntdProvider';
import { theme } from './theme';

function ThemeProviders({ children }: { children: ReactNode }) {
	return (
		<ThemeProvider theme={theme}>
			<SCThemeProvider theme={theme}>
				<AntdProvider theme={theme}>{children}</AntdProvider>
			</SCThemeProvider>
		</ThemeProvider>
	);
}

export default ThemeProviders;
