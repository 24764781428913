import { Component, ErrorInfo } from 'react';
import { ChildNotFoundError, SelectedParentNotFoundError } from './common-relationship.error';

interface State {
	hasError: boolean;
	error?: Error;
}

interface Props {
	children: React.ReactNode;
	onErrorRender: () => void;
	fallback?: React.ReactNode;
}

export class ErrorBoundary extends Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Error caught in ErrorBoundary: ', error, errorInfo);
	}

	render() {
		const { hasError, error } = this.state;
		const { children, fallback, onErrorRender } = this.props;

		if (hasError) {
			if (error instanceof ChildNotFoundError) {
				onErrorRender();
				return fallback || null;
			}
			if (error instanceof SelectedParentNotFoundError) {
				onErrorRender();
				return fallback || null;
			}
			throw new Error(error?.message);
		}
		// Render the children without displaying any error message
		return children;
	}
}
