function deepEquality(obj1: any, obj2: any): boolean {
	// Check for primitive types
	if (obj1 === obj2) return true;
	if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) return false;

	// Check for arrays
	if (Array.isArray(obj1) && Array.isArray(obj2)) {
		if (obj1.length !== obj2.length) return false;
		for (let i = 0; i < obj1.length; i += 1) {
			if (!deepEquality(obj1[i], obj2[i])) return false;
		}
		return true;
	}

	// Check for objects
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	if (keys1.length !== keys2.length) return false;

	for (let i = 0; i < keys1.length; i += 1) {
		const key = keys1[i];
		if (!keys2.includes(key) || !deepEquality(obj1[key], obj2[key])) return false;
	}

	return true;
}

export default deepEquality;
