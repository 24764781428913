import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';

type TriggerWrapperProps = {
	active: boolean;
	error?: boolean;
	errorMessage?: boolean;
	height?: number;
	verticalPadding?: number;
	placement?: 'bottom' | 'top';
	transparentBorder?: boolean;
	backgroundColor?: string;
};

type MenuWrapperProps = {
	active: boolean;
	canReset?: boolean;
	height?: number;
	enablePortal?: boolean;
	placement?: 'bottom' | 'top';
	maxHeight?: number;
};

type MenuItemProps = {
	height?: number;
	withDivider?: boolean;
	highlighted?: boolean;
};

export const TriggerWrapper = styled.div<TriggerWrapperProps>(
	({
		theme,
		active,
		error,
		errorMessage,
		height,
		verticalPadding,
		placement,
		transparentBorder,
		backgroundColor,
	}) => css`
		align-items: center;
		background-color: ${transparentBorder ? 'inherit' : backgroundColor ?? theme.palette.light.background};
		border: 1px solid ${transparentBorder ? 'transparent' : theme.palette.light.backgroundAlt};
		border-radius: 8px;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		font-size: 1rem;
		min-height: ${height ? `${height}px` : '48px'};
		justify-content: space-between;
		padding: ${theme.spacing(verticalPadding ?? 1)} ${theme.spacing(2)};
		position: relative;
		flex-grow: 1;

		&:hover {
			border-color: ${theme.palette.blue.main};
		}

		:focus-visible {
			outline: none;
		}

		${error
			? css`
					background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
					${errorMessage &&
					css`
						border-bottom-right-radius: 0;
					`}
					border-color: ${theme.palette.error.main} !important;
					color: ${theme.palette.error.text};

					input {
						color: ${theme.palette.error.text};
					}
			  `
			: null}

		// Active
		${active &&
		css`
			.caretIcon {
				transform: rotate(180deg);
			}
			background-color: ${theme.palette.light.main};
			border: 1px solid ${theme.palette.blue.main};
			${placement === 'top'
				? css`
						border-top: 1px solid transparent !important;
						border-top-left-radius: 0 !important;
						border-top-right-radius: 0 !important;
				  `
				: css`
						border-bottom: 1px solid transparent !important;
						border-bottom-left-radius: 0 !important;
						border-bottom-right-radius: 0 !important;
				  `}
			filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.1));
			z-index: 99;
		`};
	`,
);

export const MenuWrapper = styled.div<MenuWrapperProps>(
	({ theme, active, canReset, maxHeight, height, enablePortal, placement }) => css`
		background-color: ${theme.palette.light.main};
		height: auto;
		max-height: 0;
		left: -1px;
		position: absolute;
		transition: max-height 0.2s ease-in-out;
		width: 100%;
		overflow: hidden;

		// Portal
		${enablePortal &&
		css`
			z-index: 1800;
		`};

		// Active
		${active &&
		css`
			border: 1px solid ${theme.palette.blue.main};

			${canReset
				? css`
						max-height: ${maxHeight || 290 + 44}px;
				  `
				: css`
						max-height: ${maxHeight || 290}px;
				  `}

			${placement === 'top'
				? css`
						border-bottom: 1px solid ${theme.palette.action.divider};
				  `
				: css`
						border-top: 1px solid ${theme.palette.action.divider};
				  `}
		`};

		${placement === 'top'
			? css`
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
					top: ${height ? `-${height - 1}px` : '-47px'};
			  `
			: css`
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
					top: ${height ? `${height - 1}px` : '47px'};
			  `}
	`,
);

export const MenuList = styled.ul<{
	placement: 'top' | 'bottom';
	maxHeight?: number;
}>(
	({ placement, maxHeight }) => css`
		margin: 0;
		${placement === 'bottom' &&
		css`
			max-height: ${maxHeight || 190}px;
		`}
		margin: 0;
		overflow: hidden;
		padding: 0;
	`,
);

export const Ellipsis = styled.div(
	() => css`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

export const MenuItem = styled.li<MenuItemProps>(
	({ theme, height, withDivider, highlighted }) => css`
		box-sizing: border-box;
		color: ${theme.palette.text.primary};
		cursor: pointer;
		display: block;
		font-size: 14px;
		list-style: none;
		min-height: ${height ? `${height}px` : '48px'};
		overflow: hidden;
		padding: ${theme.spacing(1)} ${theme.spacing(2)};
		text-overflow: ellipsis;
		white-space: nowrap;

		${withDivider &&
		css`
			border-bottom: 1px solid ${theme.palette.action.divider};
		`}

		&:hover {
			background-color: ${alpha(theme.palette.blue.main, 0.3)};
		}

		${highlighted &&
		css`
			background-color: ${alpha(theme.palette.blue.main, 0.3)};
		}
		`}
	`,
);
