import React, { useMemo } from 'react';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';
import TextInput from '../../../library/TextInput';

interface DistributionNumberFieldProps {
	editMode: boolean;
	distributionNumber: string;
	setDistributionNumber: (distributionNumber: string) => void;
	origin: string;
	isInherited: boolean;
	onBlur: () => void;
}

function DistributionNumberField({
	editMode,
	distributionNumber,
	setDistributionNumber,
	origin,
	onBlur,
}: DistributionNumberFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="Distribution Number:"
				path="coreMetadata.basic.sequenceInfo.distributionNumber.distributionNumber"
				jumpToParentButtonDisabled
				editMode={editMode}
				ruleOrigin={origin}
				readComponent={<ReadOnlyTypography>{distributionNumber}</ReadOnlyTypography>}
				editComponent={
					<TextInput
						fullWidth
						width="50%"
						value={distributionNumber}
						onChange={(e) => setDistributionNumber(e.target.value)}
						onBlur={onBlur}
						height={38}
					/>
				}
			/>
		),
		[distributionNumber, origin, editMode],
	);
}

export default DistributionNumberField;
