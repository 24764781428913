import React, { Dispatch, SetStateAction, useContext } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { v4 as uuid } from 'uuid';
import Button from '../../../../../../components/library/Button';
import { CastAndCrewContext } from '../providers/castAndCrewProvider';

type AddRoleButtonProps = {
	peopleIndex: number;
	setUuids: Dispatch<SetStateAction<string[]>>;
};

export default function AddRoleButton({ setUuids, peopleIndex }: AddRoleButtonProps) {
	const { addToArray } = useContext(CastAndCrewContext);

	const onClickAdd = () => {
		setUuids((prevUuids) => [...prevUuids, uuid()]);
		addToArray(`people.${peopleIndex}.jobs`, {
			characterInfos: [],
			billingBlockOrders: [
				{
					billingBlockOrder: '',
				},
			],
		});
	};

	return (
		<Button onClick={onClickAdd} nxstyle="secondary-black" startIcon={<Icon path={mdiPlus} size={1} />}>
			Add Role
		</Button>
	);
}
