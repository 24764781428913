import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { mdiDelete, mdiMenuRight } from '@mdi/js';
import Icon from '@mdi/react';
import { Accordion, AccordionDetails, AccordionSummary, alpha, Typography, useTheme } from '@mui/material';
import Button from '../library/Button';

type CollapsableCardProps = {
	children: ReactNode;
	label: string;
	showDeleteIcon?: boolean;
	onClickDeleteIcon?: () => void;
	deletedIconIsDisabled?: boolean;
	defaultExpanded?: boolean;
};

const Wrapper = styled(Accordion)(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: ${theme.spacing(2)} !important;
		box-shadow: none !important;
		margin: 0 !important;
		width: 100%;

		&::before {
			opacity: 0 !important;
		}

		.MuiAccordionSummary-root {
			min-height: 0;
		}
		.MuiAccordionSummary-content {
			margin: 0;
			min-height: 0;
		}
	`,
);
const TopBar = styled(AccordionSummary)(
	({ theme }) => css`
		&.Mui-expanded {
			border-bottom: solid 1px ${theme.palette.light.backgroundAlt} !important;
		}
		border-bottom: none !important;
		border-top-left-radius: ${theme.spacing(2)};
		border-top-right-radius: ${theme.spacing(2)};
		display: flex;
		flex-direction: row-reverse;
		padding: ${theme.spacing(0.5)};

		& .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
			transform: rotate(90deg);
		}
		width: 100%;

		.MuiAccordionSummary-content {
			justify-content: space-between !important;
		}
		svg {
			color: ${theme.palette.text.secondary};
		}

		.label-icon {
			align-items: center;
			display: flex;
		}
	`,
);

const ChildrenWrapper = styled(AccordionDetails)(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-bottom-left-radius: ${theme.spacing(2)};
		border-bottom-right-radius: ${theme.spacing(2)};
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		padding: ${theme.spacing(2)};
	`,
);

const StyledButton = styled(Button)(
	({ theme }) => css`
		height: auto;
		margin-right: ${theme.spacing(1)};
		padding: ${theme.spacing(0)} ${theme.spacing(0.5)};
	`,
);

function CollapsableCard({
	label,
	showDeleteIcon = false,
	deletedIconIsDisabled = false,
	onClickDeleteIcon,
	children,
	defaultExpanded = true,
}: CollapsableCardProps) {
	const theme = useTheme();

	const handleClickDeleteIcon = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (onClickDeleteIcon) {
			onClickDeleteIcon();
		}
	};

	return (
		<Wrapper defaultExpanded={defaultExpanded}>
			<TopBar expandIcon={<Icon path={mdiMenuRight} size={1} />}>
				<div className="label-icon">
					<Typography color="text.secondary" variant="buttonMediumRegular">
						{label}
					</Typography>
				</div>
				{showDeleteIcon && onClickDeleteIcon && (
					<StyledButton onClick={handleClickDeleteIcon} disabled={deletedIconIsDisabled} nxstyle="tertiary-light">
						<Icon
							path={mdiDelete}
							color={deletedIconIsDisabled ? alpha(theme.palette.error.main, 0.3) : theme.palette.error.main}
							size={0.7}
						/>
					</StyledButton>
				)}
			</TopBar>
			<ChildrenWrapper>{children}</ChildrenWrapper>
		</Wrapper>
	);
}

export default CollapsableCard;
