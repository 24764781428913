import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Chip, Tag, FormControlLabel } from '@warehouse/shared/legacy-ui';
import { paginatorStoreSelector, usePaginatorStore } from '@warehouse/shared/ui';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { titleSelectorSelector, useTitleSelectorStore } from '../title-selector.store';

export function TitleListActionsTopBar({ titles }: TitleListActionsTopBarProps) {
	const actions = useTitleSelectorStore(titleSelectorSelector.actions);
	const allTitlesInThisPageAreSelected = useTitleSelectorStore(titleSelectorSelector.areTitlesSelected(titles));
	const selectedTitlesIds = useTitleSelectorStore(titleSelectorSelector.selectedTitles);
	const totalCount = usePaginatorStore(paginatorStoreSelector.totalCount);

	const onChange = () => {
		if (allTitlesInThisPageAreSelected) actions.unselectTitles(titles);
		else actions.selectTitles(titles);
	};

	return (
		<TopActionsWrapper>
			<div>
				<FormControlLabel
					control={<Checkbox checked={allTitlesInThisPageAreSelected} />}
					label="Select All"
					onChange={onChange}
				/>
				<Chip label={`${selectedTitlesIds.length} items selected`} height={24} />
			</div>
			<Tag height={24} $uppercase={false}>{`${totalCount} Records Found `}</Tag>
		</TopActionsWrapper>
	);
}

interface TitleListActionsTopBarProps {
	titles: IndexedTitleV2[];
}

const TopActionsWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		box-sizing: border-box;
		display: flex;
		grid-area: resultsItems;
		justify-content: space-between;
		margin-top: ${theme.spacing(1)};
		width: 100%;
	`,
);
