import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline, mdiCircleSmall } from '@mdi/js';
import { IndexText, IndexWrapper } from './IndexWrapper';
import { NonCollapsiblePadding } from './CollapseComponents';

interface NodeItemProps {
	title: string;
	type: string;
	description?: string;
	isChecked?: boolean;
	isSelected?: boolean;
	iconPath: string;
	index?: number;
}

interface SelectedProps {
	$isSelected: boolean;
}

interface ContainerProps extends SelectedProps {}

const DescriptionText = styled((props) => <Typography {...props} variant="buttonSmallRegular" />)(
	({ theme }) => css`
		color: ${theme.palette.text.secondary} !important;
		font-size: 12px;
		font-weight: 400;
		margin-top: ${theme.spacing(0.75)};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

export const NodeItemContainer = styled.div<ContainerProps>(
	({ $isSelected, theme }) => css`
		background-color: ${theme.palette.light.main};
		border: solid 1px transparent;
		border-radius: 8px;
		display: flex;
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.05));
		flex-direction: row;
		width: 100%;

		svg {
			color: ${theme.palette.text.secondary};
		}

		${$isSelected &&
		css`
			p,
			span,
			svg {
				color: ${theme.palette.blue.main};
			}

			border: solid 1px ${theme.palette.blue.main};
		`}
	`,
);

const LogoWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		border-right: 1px solid ${theme.palette.light.backgroundAlt};
		display: flex;
		height: auto;
		justify-content: center;
		width: 56px;
	`,
);

export const NodeItemBody = styled.div(
	({ theme }) => css`
		box-sizing: border-box;
		height: 100%;
		overflow: hidden;
		padding: ${theme.spacing(1.25)};
		width: 100%;
	`,
);

const TypeText = styled((props) => <Typography {...props} variant="buttonSmallRegular" />)<SelectedProps>(
	({ theme, $isSelected }) => css`
		color: ${$isSelected ? theme.palette.blue.main : theme.palette.text.secondary};
	`,
);

const TitleText = styled(Typography)<SelectedProps>(
	({ theme, $isSelected }) => css`
		color: ${$isSelected ? theme.palette.blue.main : theme.palette.text.secondary};
		margin-top: ${theme.spacing(0.75)};
	`,
);

const BodyDivider = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.action.divider};
		height: 1px;
		margin-top: ${theme.spacing(0.75)};
	`,
);

const TopBody = styled.div(
	() => css`
		display: flex;
	`,
);

const TypeTitleWrapper = styled.div<{ isChecked: boolean }>(
	({ isChecked }) => css`
		display: flex;
		flex-direction: column;
		width: ${isChecked ? '80%' : '100%'};
	`,
);

const CheckedWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		justify-content: center;
		width: 52px;
	`,
);

function NodeItem({ title, type, description, isChecked = false, isSelected = false, iconPath, index }: NodeItemProps) {
	const NodeItemComp = (
		<NodeItemContainer $isSelected={isSelected}>
			<LogoWrapper>
				<Icon path={iconPath} size="32px" />
			</LogoWrapper>
			<NodeItemBody>
				<TopBody>
					<TypeTitleWrapper isChecked={isChecked}>
						<TypeText $isSelected={isSelected}>{type}</TypeText>
						<TitleText $isSelected={isSelected} variant="buttonLargeMedium">
							{title}
						</TitleText>
					</TypeTitleWrapper>
					{isChecked && (
						<CheckedWrapper>
							<Icon size="20px" path={mdiCheckCircleOutline} />
						</CheckedWrapper>
					)}
				</TopBody>
				{description && (
					<>
						<BodyDivider />
						<DescriptionText>{description}</DescriptionText>
					</>
				)}
			</NodeItemBody>
		</NodeItemContainer>
	);

	return (
		<IndexWrapper>
			<NonCollapsiblePadding>
				<Icon path={mdiCircleSmall} size="24px" />
			</NonCollapsiblePadding>
			{index !== undefined && <IndexText>{index}</IndexText>}
			{NodeItemComp}
		</IndexWrapper>
	);
}

export default NodeItem;
