import { CellContext } from '@tanstack/react-table';
import React, { useContext, useMemo } from 'react';
import Dropdown from '../../../../../../components/library/Dropdown/Dropdown';
import LocaleJSON from '../../../../../../assets/json-administration-profiles/availableLanguageWritten.json';
import { CastAndCrewContext } from '../providers/castAndCrewProvider';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

export default function LocaleCell(info: CellContext<any, any>, placement: 'bottom' | 'top', pathPrefix: string) {
	const { getValue, row, table } = info;
	const currentUsedLocal = useMemo(
		() => (table.options.data ? table.options.data.map(({ language }) => language ?? '') : []),
		[table.options.data],
	);
	const { editData, isInherited } = useContext(CastAndCrewContext);

	const handleChange = (value: string | null) => {
		editData(`${pathPrefix}.${row.index}.language`, value, true);
	};

	return (
		<CellWrapper>
			<Dropdown
				placement={placement}
				withSearch
				valueStyle={isInherited ? 'italic' : 'default'}
				value={getValue()}
				enablePortal
				transparentBorder
				onChange={(e) => e && handleChange(e)}
				options={useMemo(
					() =>
						LocaleJSON.filter(({ uuid }) => (uuid === getValue() ? true : !currentUsedLocal.includes(uuid))).map(
							({ uuid, label }) => ({ value: uuid, label }),
						),
					[LocaleJSON, currentUsedLocal, getValue()],
				)}
			/>
		</CellWrapper>
	);
}
