import { datadogRum } from '@datadog/browser-rum';

export const initDatadogMonitoring = () => {
	if (process.env.REACT_APP_DATADOG_APP_ID && process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
			process.env.REACT_APP_DATADOG_SITE && process.env.REACT_APP_DATADOG_SERVICE && process.env.REACT_APP_DATADOG_ENV) {
		datadogRum.init({
			applicationId: process.env.REACT_APP_DATADOG_APP_ID,
			clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
			site: process.env.REACT_APP_DATADOG_SITE,
			service: process.env.REACT_APP_DATADOG_SERVICE,
			env: process.env.REACT_APP_DATADOG_ENV,
			sessionSampleRate: 100,
			sessionReplaySampleRate: 33,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: 'allow',
		});
	}
}
