import styled, { css } from 'styled-components';

export const IndexWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		flex-direction: row;
	`,
);

export const IndexText = styled.div(
	({ theme }) => css`
		color: ${theme.palette.dark.black};
		flex-basis: 15px;
		flex-shrink: 0;
		font-size: 12px;
		font-weight: 500;
		margin: 0;
		margin-right: ${theme.spacing(1)};
		width: 15px;
	`,
);
