import React, { createContext, useCallback, useMemo, useState } from 'react';
import { DropdownOption } from '../../../../../src/components/library/Dropdown/types';
import availableAlternateTitleTypes from '../../../../../src/assets/json-administration-profiles/availableTitleAlternateType.json';

interface AlternateTitleTypePicklistContextInterface {
	options: DropdownOption[];
	onNewOptionAdded: (option: string) => void;
	initializeOptions: (initialOptions: DropdownOption[]) => void;
}

export const AlternateTitleTypePicklistContext = createContext<AlternateTitleTypePicklistContextInterface>({
	options: [],
	onNewOptionAdded: () => {},
	initializeOptions: () => {},
});

export function AlternateTitleTypePicklistContextProvider({ children }: { children: React.ReactNode }) {
	const [options, setOptions] = useState<DropdownOption[]>([]);
	const jsonOptions = useMemo(
		() =>
			availableAlternateTitleTypes.map((type) => ({
				value: type.name,
				label: type.label,
			})),
		[],
	);

	const initializeOptions = useCallback(
		(titleOptions: DropdownOption[]) => {
			const combinedTypeOptions = [...jsonOptions, ...titleOptions].filter(
				(option, index, array) =>
					array.findIndex((o) => o.value === option.value) === index && !!option.value && !!option.label,
			);
			setOptions([...combinedTypeOptions]);
		},
		[jsonOptions, setOptions],
	);

	const onNewOptionAdded = useCallback(
		(newOption: string) => {
			if (newOption.trim() === '') return;
			setOptions((prev) => [...prev, { value: newOption, label: newOption }]);
		},
		[setOptions],
	);

	return (
		<AlternateTitleTypePicklistContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				options,
				onNewOptionAdded,
				initializeOptions,
			}}
		>
			{children}
		</AlternateTitleTypePicklistContext.Provider>
	);
}
