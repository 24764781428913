import { gql } from '@apollo/client';

export const SEARCH_TITLE_ENTITIES_V2 = gql`
	query searchTitlesV2($search: SearchInput!) {
		searchTitlesV2(search: $search) {
			documents {
				titleSearchDisplayNameFull
				titleSearchDisplayNameFullHTML {
					workType
					workTypeDetails
					titleFrontEnd
					titleSortFrontEnd
					parentTitleSearchDisplayNameFullForChild
					sequenceNumber
					editUse
					editClasses
					madeForRegions
					madeForRegionsExclude
					versionLanguages
					identification
				}
				titleSort
				titleDisplayUnlimited
				titleSearchSortFull
				releaseDate
				partOfCompilations {
					compilationId
					contentId
					compilationEntryClass {
						label
						uuid
					}
					compilationEntryNumber
				}
				originalTitle
				titleStatus
				titleAbbreviated
				ratings
				releaseYear
				createdAt
				createdBy {
					name
				}
				editedAt
				editedBy {
					name
				}
				licensor {
					label
					uuid
				}
				workType {
					name
					label
					uuid
				}
				parentAbstractionWorkType {
					name
					uuid
				}
				parents {
					parentContentId
					primary
					relationshipType
					relationshipSubtypes {
						label
						uuid
					}
					sequenceInfo {
						distributionNumber {
							distributionNumber
							domain
						}
						houseSequence {
							houseSequence
							domain
						}
						number
					}
				}
				runLength
				originalLanguages {
					uuid
					label
					descriptor
				}
				uuid
				eidrId
				aspectRatio {
					aspectRatio
				}
				frameRate {
					frameRate
				}
				summary190
				summary400
				summary4000
				sequenceNumber
				houseSequence
				distributionNumber
				countriesOfCreation {
					label
					uuid
				}
				countriesOfProduction {
					label
					uuid
				}
				workTypeDetails {
					name
					label
					uuid
				}
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`;

export const SEARCH_TITLE_TITLE_DISPLAY_UNLIMITED_V2 = gql`
	query searchTitlesV2TitleDisplayUnlimited($search: SearchInput!) {
		searchTitlesV2(search: $search) {
			documents {
				titleDisplayUnlimited
				uuid
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`;

export const SEARCH_TITLE_TITLE_SORT_V2 = gql`
	query searchTitlesV2TitleSort($search: SearchInput!) {
		searchTitlesV2(search: $search) {
			documents {
				titleSort
				uuid
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`;
