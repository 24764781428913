import React from 'react';

import SideMenuTabs from '../../../../components/library/SideMenuTabs';
import CommentsTab from './comments';
import AttachmentsTab from './attachments';

function ActivityTab() {
	const tabs = [
		{
			label: 'Comments',
			urlState: 'activity/comments',
			component: <CommentsTab />,
		},
		{
			label: 'Attachments',
			urlState: 'activity/attachments',
			component: <AttachmentsTab />,
		},
	];

	return (
		<div>
			<SideMenuTabs leftColumnWidth={350} tabs={tabs} />
		</div>
	);
}

export default ActivityTab;
