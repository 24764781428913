import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const FullWidthHeightWrapper = styled.div(
	() => css`
		display: flex;
		height: 100%;
		width: 100%;

		& > div:last-child {
			border-bottom-right-radius: 8px;
		}
	`,
);
