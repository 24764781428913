import styled, { css } from 'styled-components';

const InlineIcon = styled.span(
	({ theme }) => css`
		align-items: center;
		display: flex;

		svg {
			color: ${theme.palette.text.secondary};
		}
	`,
);

export default InlineIcon;
