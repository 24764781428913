import React from 'react';
import { Button } from '@warehouse/shared/legacy-ui';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiTrashCan } from '@mdi/js';
import { alpha } from '@mui/material';

export function BottomActionClear({ action }: { action: () => void }) {
	return (
		<StyledButton startIcon={<Icon path={mdiTrashCan} size="16px" />} nxstyle="tertiary-light" onClick={() => action()}>
			Clear
		</StyledButton>
	);
}

export const StyledButton = styled(Button)(
	({ theme }) => css`
		border: 1px solid ${alpha(theme.palette.light.backgroundAlt, 0.45)};
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		height: 36px;
		justify-content: flex-start;
		width: 100%;

		svg {
			margin-bottom: 2px;
		}
	`,
);
