import React from 'react';
import {
	CommonReadonly,
	CombinedWorkType,
	isDerived,
	isPrimaryAndDerivedFrom as getIsPrimaryAndDerivedFrom,
} from '@warehouse/title/core';
import titleSearchDisplayNameBuilder from '../../../../src/utils/titles/titleSearchDisplayNameBuilder';
import { ColoredTitleCard } from './ColoredTitleCard';
import { DefaultTitleCard } from './DefaultTitleCard';
import { TitleCardLink } from './TitleCardLink';

interface TitleCardProps {
	titleLight: CommonReadonly;
	isLink?: boolean;
	/**
	 * @default 'default' Used in table rows
	 * @default 'colored' Used in the title card list, and when the title card is used as a standalone component
	 */
	variant?: 'default' | 'colored';
	minWidth?: number;
	hideReleaseYear?: boolean;
}

export function TitleCard({
	titleLight,
	minWidth,
	variant = 'default',
	isLink = true,
	hideReleaseYear = false,
}: TitleCardProps) {
	const { title, subTitle, releaseYear, isPrimaryAndDerivedFrom, workType } =
		mapTitleLightToTitleCardV2Props(titleLight);

	const titleCardCompProps = {
		hideReleaseYear,
		minWidth,
		workType,
		title,
		subTitle,
		releaseYear,
		isPrimaryAndDerivedFrom: isPrimaryAndDerivedFrom ?? false,
	};

	const TitleCardVariant = variant === 'colored' ? ColoredTitleCard : DefaultTitleCard;

	if (isLink) {
		return (
			<TitleCardLink uuid={titleLight.uuid} fullWidth>
				<TitleCardVariant {...titleCardCompProps} />
			</TitleCardLink>
		);
	}
	return <TitleCardVariant {...titleCardCompProps} />;
}

function mapTitleLightToTitleCardV2Props(titleLight: CommonReadonly): TitleCardV2Props {
	const { title, subTitle1 } = titleSearchDisplayNameBuilder({
		tokens: titleLight.titleSearchDisplayNameFullHTML,
	});

	const combinedWorkType = getCombinedWorktype(titleLight);

	return {
		title,
		subTitle: subTitle1,
		releaseYear: titleLight.releaseYear,
		workType: combinedWorkType,
		isPrimaryAndDerivedFrom: titleLight.parents ? getIsPrimaryAndDerivedFrom(titleLight.parents) : false,
	};
}

export type TitleCardV2Props = {
	title: string;
	subTitle?: string;
	releaseYear: string;
	workType: CombinedWorkType;
	isPrimaryAndDerivedFrom?: boolean;
};

function getCombinedWorktype(titleLight: CommonReadonly): CombinedWorkType {
	if (isDerived(titleLight)) {
		return {
			workType: titleLight.workType,
			parentAbstractionWorkType: titleLight.parentAbstractionWorkType,
		};
	}

	return titleLight.workType;
}
