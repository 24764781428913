import { Snackbar } from '@mui/material';
import React from 'react';
import AlertSnackbar from '../../../components/library/AlertSnackbar';

interface ErrorSnackbarProps {
	open: boolean;
	onClose: () => void;
	title: string;
	errors: string[];
}

function ErrorSnackbar({ open, onClose, title, errors }: ErrorSnackbarProps) {
	return (
		<Snackbar
			autoHideDuration={5000}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			open={open}
			onClose={onClose}
		>
			<div>
				<AlertSnackbar severity="error" title={title} width={478}>
					<ul>
						{errors?.map((err, index) => (
							<li key={err + index}>{err}</li>
						))}
					</ul>
				</AlertSnackbar>
			</div>
		</Snackbar>
	);
}

export default ErrorSnackbar;
