import styled, { css } from 'styled-components';
import { alpha, Typography } from '@mui/material';

import Button from '../../library/Button';

export const TitleSearchBottomActionsWrapper = styled.div(
	({ theme }) => css`
		border-top: 1px solid ${theme.palette.light.backgroundAlt};
		display: flex;
		flex: 1;
		justify-content: center;
		padding: ${theme.spacing(1, 2)};
	`,
);

export const Wrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);

export const SubWrapper = styled.div<{ active?: boolean; error?: boolean }>(
	({ theme, active, error }) => css`
		border: solid 2px transparent;
		border-radius: 8px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 100%;

		:hover {
			border: solid 2px ${theme.palette.primary.main};
		}

		${active &&
		css`
			border: solid 2px ${theme.palette.primary.main};
		`}

		${error &&
		css`
			& .input-wrapper > * {
				background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
				color: ${theme.palette.error.text} !important;
			}
			border: solid 1px ${theme.palette.error.main} !important;

			border-bottom-right-radius: 0;
		`}

		& .input-wrapper:hover,
		.input-wrapper:focus-within {
			background-color: ${theme.palette.light.main};
			border-color: ${theme.palette.light.backgroundAlt};

			input {
				color: ${theme.palette.text.primary};
			}

			svg {
				color: ${theme.palette.text.secondary};
			}
		}
	`,
);

export const ErrorInputWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
	`,
);

export const MenuList = styled.ul<{ backgroundColor?: string }>(
	({ theme, backgroundColor }) => css`
		${backgroundColor &&
		css`
			background-color: ${theme.palette.light.background};
		`}
		margin: 0;
		padding: 0;
	`,
);

export const NoResultWrapper = styled.div(
	({ theme }) => css`
		padding: ${theme.spacing(2)};
	`,
);

export const MenuWrapper = styled.div<{
	active?: boolean;
	width?: number;
	maxHeight?: number;
}>(
	({ theme, active, width, maxHeight }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: 8px;
		box-shadow: 0 ${theme.spacing(1)} ${theme.spacing(3)} 0 #00000040;
		height: auto;
		left: -1px;
		${active ? `max-height: ${maxHeight}px;` : `max-height: 0;`}
		overflow: hidden;
		width: ${width ? `${width}px` : '100%'};

		z-index: 1800;
	`,
);

export const InputWrapper = styled.div(
	() => css`
		display: flex;
		min-width: 150px;
		width: 100%;

		& .search-input {
			width: 100%;
		}
	`,
);

export const StyledButton = styled(Button)<{
	$active?: boolean;
	$error?: boolean;
}>(
	({ theme, $active, $error }) => css`
		border-bottom-right-radius: 0;
		border-right-width: 0;
		border-top-right-radius: 0;
		color: ${theme.palette.text.secondary};
		display: flex;
		font-size: 14px;
		font-weight: 500;

		${$error &&
		css`
			background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
			color: ${theme.palette.error.text};

			& .MuiButton-endIcon,
			.MuiButton-startIcon {
				svg {
					color: ${theme.palette.error.text} !important;
				}
			}
		`}

		& .MuiButton-endIcon {
			margin-left: auto;
		}

		height: 40px;
		justify-content: flex-start;
		min-width: 150px;

		&:hover {
			background-color: ${theme.palette.light.backgroundAlt};
		}

		${$active &&
		css`
			background-color: ${theme.palette.light.backgroundAlt};
		`}
	`,
);

export const StyledButtonTypography = styled(Typography)(
	({ theme }) => css`
		color: ${theme.palette.text.secondary};
		font-size: ${theme.typography.buttonMediumMedium.fontSize};
		font-weight: ${theme.typography.buttonMediumMedium.fontWeight};
	`,
);
