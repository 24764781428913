import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import SelectDropdown from '../../../../components/library/SelectDropdown/SelectDropdown';
import editClassJson from '../../../../assets/json-administration-profiles/editClass.json';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface EditClassFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	inheritedValues?: Title | undefined;
}

function EditClassField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	inheritedValues,
}: EditClassFieldProps) {
	const tooltip = useTooltip('coreMetadata.basic.versionIntent.editClasses');

	const inheritedEditClass = getTitleData<OptionalInherited<string[]>>(
		inheritedValues,
		fromBasic(['versionIntent', 'editClasses']),
	);

	return useMemo(
		() => (
			<Controller
				name="editClass"
				control={control}
				render={({ field }) => (
					<SelectDropdown
						placeholderArray={inheritedEditClass?.displayValue}
						placeholderStyle="italic"
						tooltip={tooltip?.tooltip}
						options={editClassJson}
						enablePortal
						values={field.value}
						onSelect={async (value) => {
							if (field.value.includes(value)) {
								setValue(
									'editClass',
									field.value.filter((item: any) => item !== value),
								);
							} else {
								setValue('editClass', [...field.value, value]);
							}
							if (isRequired && !inheritedEditClass?.displayValue?.length) {
								await trigger('editClass');
							}
						}}
						label={getLabelWithRequired('Edit Class', isRequired)}
						error={errors?.editClass?.message as string}
					/>
				)}
			/>
		),
		[control, setValue, trigger, inheritedEditClass?.displayValue, errors?.editClass?.message],
	);
}

export default EditClassField;
