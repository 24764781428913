import React, { useMemo } from 'react';

import { AutoButtonGroup } from '../../../components/library/AutoButtonGroup';

function ExportMMCButton() {
	const exportMmcOptions = useMemo(() => ([
		{ id: 'xml', label: 'Export MMC (XML)', default: true },
		{ id: 'json', label: 'Export MMC (JSON)' }
	]), []);

	return (
		<AutoButtonGroup
			height={32}
			nxstyle="secondary-black"
			storeKey="export-format"
			options={exportMmcOptions}
			disabled
		/>
	);
}

export default ExportMMCButton;
