import React, { useMemo } from 'react';
import { TitleFull as Title } from '@warehouse/title/core';

// LIBRARY
import styled, { css } from 'styled-components';
import { PictureColorTypeField, PictureFormatField } from '@warehouse/title/feature-ancillary-tab';
import { FullWidthHeightWrapper } from '../../../../../components/common';
import { AlternateIDs, ThreeDField, VersionLanguages, AssociatedOrgsTable } from './fields';
import { Column } from '../style';
import getDeepProperty from '../../../../../utils/getDeepProperty';
import { fromBasic } from '../../../../../utils/titleGetProperty';
import useWorkTypes from '../../../../../utils/hooks/titles/useWorkTypes';
import CompanyDisplayCreditList from './CompanyDisplayCredits/CompanyDisplayCreditList';
import { CompanyDisplayCreditsProvider } from './providers/companyDisplayCreditsProvider';

interface AncillaryTabProps {
	editMode: boolean;
	titleEntity: Title | undefined;
}

const Row = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(1)};
		margin-bottom: ${theme.spacing(1)};

		.title-editable-field {
			min-width: 0 !important;
			padding: 0 !important;
			width: 100%;
		}
	`,
);

const CompanyDisplayCreditWrapper = styled.div(
	({ theme }) => css`
		margin-top: ${theme.spacing(2)};
	`,
);

function AncillaryTab({ editMode, titleEntity }: AncillaryTabProps) {
	const { editUuid, manifestationUuid } = useWorkTypes();

	const workType = useMemo(
		() => getDeepProperty<string | undefined>(titleEntity, fromBasic(['workType'])),
		[titleEntity],
	);

	return (
		<FullWidthHeightWrapper>
			<Column width="40%">
				<Row>
					<PictureColorTypeField />
					<ThreeDField />
					<PictureFormatField />
				</Row>
				{(workType === editUuid() || workType === manifestationUuid()) && (
					<VersionLanguages editMode={editMode} titleEntity={titleEntity} />
				)}
				<AssociatedOrgsTable editMode={editMode} />
				<CompanyDisplayCreditWrapper>
					<CompanyDisplayCreditsProvider>
						<CompanyDisplayCreditList editMode={editMode} title={titleEntity} />
					</CompanyDisplayCreditsProvider>
				</CompanyDisplayCreditWrapper>
			</Column>
			<Column width="60%">
				<AlternateIDs titleEntity={titleEntity} editMode={editMode} />
			</Column>
		</FullWidthHeightWrapper>
	);
}

// eslint-disable-next-line import/no-default-export
export default AncillaryTab;
