import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiHistory } from '@mdi/js';
import { InfiniteLoaderProps } from './Dropdown/types';

type WrapperProps = {
	inlineLabel?: boolean;
	disabled?: boolean;
	width?: number;
	disabledMargin?: boolean;
};

type SelectedItemTextProps = {
	active: boolean;
	italic?: boolean;
};

type SearchWrapperProps = {
	height?: number;
	searchInputType?: 'text' | 'number';
};

export function computeItemCount(
	infiniteLoaderProps: InfiniteLoaderProps | undefined,
	isSearchBackend: boolean,
	defaultOptionsLength: number,
	searchValuesLength: number,
	filteredOptionsLength: number,
	optionsLength: number,
) {
	const defaultOptionsAreDisplayed = !!(!searchValuesLength && defaultOptionsLength);

	if (defaultOptionsAreDisplayed) {
		// If there is a backend pagination, return back end item count + 1 ( for displaying default options titles ) + default options length
		if (infiniteLoaderProps) {
			return infiniteLoaderProps.itemCount + 1 + defaultOptionsLength;
		}

		// If there is not any backend pagination, return default options length + 1 ( for displaying default options titles ) + options length
		return optionsLength + 1 + defaultOptionsLength;
	}

	if (infiniteLoaderProps) {
		return infiniteLoaderProps.itemCount;
	}

	if (!isSearchBackend && searchValuesLength) {
		return filteredOptionsLength;
	}

	return optionsLength;
}

export function isItemLoaded(
	index: number,
	infiniteLoaderProps: InfiniteLoaderProps | undefined,
	isBackendSearch: boolean,
	defaultOptions: DropdownOption[] | undefined,
	searchValue: string | undefined,
	filteredOptions: DropdownOption[] | undefined,
	options: DropdownOption[] | undefined,
) {
	const defaultOptionsAreDisplayed = !searchValue?.length && defaultOptions?.length;

	if (defaultOptionsAreDisplayed) {
		// As the first row is the default options title, it is always loaded
		if (index === 0) return true;

		// If the index is less than the default options length, check the default options
		if (index <= defaultOptions?.length) {
			const defaultOptionsIndex = index - 1;

			return !!defaultOptions[defaultOptionsIndex];
		}

		// If the index is greater than the default options length, check the options
		const optionsIndex = index - defaultOptions.length - 1;

		return !!options?.[optionsIndex];
	}

	// If there is not any search, check normal options without adjusted index
	if (!searchValue) return !!options?.[index];

	// If the search is back-end, check normal options without adjusted index
	if (isBackendSearch) return !!options?.[index];

	// If the search is front-end, check filtered options without adjusted index
	return !!filteredOptions?.[index];
}

const DefaultOptionsLabelWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		border-bottom: solid 1px ${theme.palette.action.divider};
		display: flex;
		height: 32px;

		svg {
			color: ${theme.palette.text.secondary};
			margin: 0 ${theme.spacing(1)};
		}
	`,
);

const LabelDefaultOptions = styled((props) => <Typography {...props} variant="buttonSmallMedium" />)(
	({ theme }) => css`
		color: ${theme.palette.text.secondary};
	`,
);

export function DefaultOptionsTitle() {
	return (
		<DefaultOptionsLabelWrapper>
			<Icon path={mdiHistory} size="20px" />
			<LabelDefaultOptions>FREQUENTLY USED</LabelDefaultOptions>
		</DefaultOptionsLabelWrapper>
	);
}

export function getOption(
	getMenuItem: (option: DropdownOption | undefined, withDivider?: boolean) => JSX.Element | null,
	index: number,
	searchValue: string | undefined,
	options: DropdownOption[],
	defaultOptions: DropdownOption[] | undefined,
	filteredOptions: DropdownOption[],
	isSearchBackend: boolean | undefined,
) {
	const defaultOptionsAreDisplayed = !searchValue && defaultOptions?.length;

	if (defaultOptionsAreDisplayed) {
		// We use the first row to display the default options title
		if (index === 0) return <DefaultOptionsTitle />;

		// If index is between 1 and defaultOptions.length, we display the default options
		if (index <= defaultOptions?.length) {
			const isLastDefaultOption = index === defaultOptions.length;
			const defaultOptionsIndex = index - 1;

			return getMenuItem(defaultOptions[defaultOptionsIndex], isLastDefaultOption);
		}

		// If index is greater than defaultOptions.length, we display normal options ( not filtered because searchValue is empty )
		const optionsIndex = index - defaultOptions.length - 1;
		return getMenuItem(options[optionsIndex]);
	}

	// If searchValue is empty, we display normal options, without any adjusted index
	if (!searchValue) return getMenuItem(options[index]);

	// If searchValue is not empty, and search is backend (isSearchBackend), we display normal options
	if (isSearchBackend) return getMenuItem(options[index]);

	// If searchValue is not empty, and search is frontend, we display filtered options
	return getMenuItem(filteredOptions[index]);
}

export const Label = styled(Typography)<{ error?: string }>(
	({ theme, error }) => css`
		align-items: center;
		color: ${theme.palette.text.secondary};
		display: flex;
		justify-content: space-between;
		margin-bottom: ${theme.spacing(1.25)};

		${error &&
		css`
			color: ${theme.palette.red.text};
		`}
	`,
);

export const LabelBlockWrapper = styled.div(
	() => css`
		display: flex;
	`,
);

export const SubMessageLayoutWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		position: relative;
	`,
);

export const SubMessageWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;

		.MuiSvgIcon-root {
			font-size: 18px;
		}

		.MuiTypography-root {
			font-size: 14px;
			font-weight: bold;
			margin-left: ${theme.spacing(1)};
		}
	`,
);

export const PlaceHolderText = styled.span<{
	placeholderStyle?: 'default' | 'italic';
}>(
	({ theme, placeholderStyle }) => css`
		color: ${theme.palette.action.placeholder};
		font-weight: normal;

		${placeholderStyle === 'italic' &&
		css`
			font-style: italic;
		`};
	`,
);

export const ReadOnlyValue = styled(Typography)<{ $height: number }>(
	({ theme, $height }) => css`
		color: ${theme.palette.text.primary};
		line-height: ${$height}px;
		margin: 0 !important;
	`,
);

export const Wrapper = styled.div<WrapperProps>(
	({ inlineLabel, theme, disabled, width, disabledMargin }) => css`
		align-items: center;
		&:focus-within {
			${Label} {
				color: ${theme.palette.blue.main};
			}
		}
		position: relative;

		${width &&
		css`
			width: ${width}px;
		`}

		${inlineLabel &&
		css`
			display: flex;

			${Label} {
				margin-bottom: 0;
				margin-right: ${theme.spacing(2)};
			}
		`}

		${!inlineLabel &&
		css`
			${ReadOnlyValue} {
				margin-left: ${theme.spacing(2)};
			}
		`}

		${disabled &&
		css`
			${Label}, ${ReadOnlyValue} {
				color: ${theme.palette.action.disabled};
			}
			cursor: not-allowed;
		`}
		
		${!disabledMargin &&
		css`
			margin: ${theme.spacing(1.75)} 0;
		`}
	`,
);

export const DisabledWrapper = styled.div<{ height?: number }>(
	({ theme, height }) => css`
		align-items: center;
		background-color: transparent;
		box-sizing: border-box;
		color: ${theme.palette.action.disabled};
		cursor: not-allowed;
		display: flex;
		flex-grow: 1;
		font-size: 14px;
		justify-content: space-between;
		min-height: ${height || '48'}px;
		padding: ${theme.spacing(0, 2)};
		position: relative;
	`,
);

export const SelectedItemText = styled.span<SelectedItemTextProps>(
	({ theme, active, italic }) => css`
		font-size: 14px;
		color: ${theme.palette.text.secondary};

		${italic &&
		css`
			font-style: italic;
		`}

		// Active
		${active &&
		css`
			color: ${theme.palette.text.primary};
			font-weight: bold;
		`};
	`,
);

export const TriggerIcons = styled.div(
	({ theme }) => css`
		align-items: center;
		color: ${theme.palette.text.secondary};
		display: flex;

		.MuiSvgIcon-root {
			margin-left: 5px;
		}
	`,
);

export const SearchInput = styled.input(
	({ theme }) => css`
		background-color: inherit;
		border: none;
		color: ${theme.palette.text.primary};
		flex-grow: 1;
		font-size: 14px;
		font-weight: 400;
		height: 100%;
		min-width: 0;
		outline: none;
		padding: 0 ${theme.spacing(1)};
	`,
);

export const SearchWrapper = styled.div<SearchWrapperProps>(
	({ theme, height, searchInputType }) => css`
		align-items: center;
		background-color: ${theme.palette.light.background};
		border-bottom: 1px solid ${theme.palette.action.divider};
		box-sizing: border-box;
		color: ${theme.palette.text.primary};
		display: flex;
		height: ${height ? `${height}px` : '48px'};
		padding-left: ${theme.spacing(1)};
		width: 100%;

		${searchInputType === 'number' &&
		css`
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				display: none;
			}
		`}
	`,
);

export const TooltipIcon = styled(Icon)(
	({ theme }) => css`
		padding: 0 ${theme.spacing(1)};
	`,
);

type DropdownOption = {
	label: string | ReactNode;
	value: string | null;
};

export function filterOptions(searchRegex: RegExp) {
	return (item: DropdownOption) => {
		if (typeof item.label === 'string') {
			return item.label.toLowerCase().match(searchRegex);
		}
		return false;
	};
}
