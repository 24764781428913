import React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle, { AlertTitleProps } from '@mui/material/AlertTitle';
import styled, { css } from 'styled-components';
import { Warning, Info } from '@mui/icons-material';

const StyledAlert = styled(Alert)<AlertProps>`
	border-radius: 8px;

	// Text-color
	& > .MuiAlert-message {
		${({ theme, severity }) => {
			if (severity) {
				return css`
					color: ${theme.palette[severity].text};
				`;
			}
			return null;
		}}
	}

	// Background-color
	${({ theme, severity, variant }) => {
		if (variant !== 'outlined') {
			return css`
				background-color: ${theme.palette[severity || 'success'].background};
			`;
		}
		return null;
	}}

	// Border-color
  ${({ theme, severity, variant }) => {
		if (variant === 'outlined') {
			return css`
				border-color: ${theme.palette[severity || 'success'].main};
			`;
		}
		return null;
	}}


  & > .MuiAlert-icon {
		// Icon color
		${({ theme, severity }) => {
			if (severity) {
				return css`
					color: ${theme.palette[severity].main};
				`;
			}
			return null;
		}}
	}
	font-size: 14px;
	margin: ${({ theme }) => theme.spacing(2)};
`;

const StyledAlertTitle = styled(AlertTitle)<AlertTitleProps>`
	font-weight: bold;
	text-align: left;
`;

function AlertComponent(props: AlertProps) {
	const { children, title } = props;
	return (
		<StyledAlert
			iconMapping={{
				success: <Warning fontSize="inherit" />,
				error: <Warning fontSize="inherit" />,
				warning: <Warning fontSize="inherit" />,
				info: <Info fontSize="inherit" />,
			}}
			{...props}
		>
			{title && <StyledAlertTitle>{title}</StyledAlertTitle>}
			{children}
		</StyledAlert>
	);
}

export default AlertComponent;
