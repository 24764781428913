import styled, { css } from 'styled-components';

export const GridCell = styled.div<{ gridArea: string; spacing?: number }>(
	({ theme, gridArea, spacing = 1 }) => css`
		align-items: center;
		display: flex;
		grid-area: ${gridArea};
		padding: ${theme.spacing(spacing)};
	`,
);
