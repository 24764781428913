import React from 'react';
import styled, { css } from 'styled-components';
import { Divider } from '@mui/material';
import FileInformation from './FileInformation';
import LinkedTracks from './LinkedTracks';
import useGetTrackAssets from '../../../../../utils/hooks/tracks/useGetTrackAssets';

const Wrapper = styled.div(
	() => css`
		width: 700px;
	`,
);

interface TrackAssetsProps {
	trackId: string;
}

function TrackAssets({ trackId }: TrackAssetsProps) {
	const { data } = useGetTrackAssets({
		uuid: trackId || '',
		pollInterval: 10000,
	});

	return (
		<Wrapper>
			<FileInformation trackData={data} />
			<Divider style={{ marginTop: 16, marginBottom: 16 }} />
			<LinkedTracks trackData={data} />
			{/* <Divider style={{ marginTop: 16, marginBottom: 16 }} /> */}
			{/* <Assignment data={trackAsset} /> */}
		</Wrapper>
	);
}

export default TrackAssets;
