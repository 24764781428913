import styled, { css } from 'styled-components';

export const TitleOverviewLeftWrapperBase = styled.div<{
	displayVideoPlayer?: boolean;
}>(
	({ theme, displayVideoPlayer }) => css`
		background-color: ${theme.palette.light.main};
		display: grid;
		height: 100%;

		.title-editable-field-label {
			margin-left: ${theme.spacing(0.75)} !important;
		}

		${displayVideoPlayer
			? css`
					width: 60%;
			  `
			: css`
					width: 100%;
			  `}
	`,
);

export const Data = styled.div<{
	order?: number;
	area: string;
	cols?: number;
	enableBottomBorder?: boolean;
	enableLeftBorder?: boolean;
	enableRightBorder?: boolean;
}>(
	({ theme, area, cols = 1, enableBottomBorder = true, enableRightBorder = false, enableLeftBorder = true }) => css`
		align-items: center;
		background-color: ${theme.palette.light.main};
		border-bottom: ${enableBottomBorder ? `1px solid ${theme.palette.light.backgroundAlt}` : 'none'};
		${enableLeftBorder &&
		css`
			border-left: 1px solid ${theme.palette.light.backgroundAlt};
		`}

		${enableRightBorder &&
		css`
			border-right: 1px solid ${theme.palette.light.backgroundAlt};
		`}
		display: grid;
		grid-area: ${area};

		grid-template-rows: 100%;
		padding: 0;

		${cols === 2
			? css`
					grid-template-columns: 50% 50%;
			  `
			: css`
					grid-template-columns: 100%;
			  `}
	`,
);

export const NonCenteredData = styled(Data)`
	align-items: stretch;
`;
