import React from 'react';
import { Header } from '@tanstack/react-table';
import { SubHeaderTH } from '../style';
import DatePicker from '../../DatePicker/DatePicker';

interface SubHeaderDatePickerProps {
	startDate: Date | undefined;
	handleSubHeaderDropdownChange: (columnId: string, value: Date | undefined) => void;
	header: Header<any, any>;
}

function SubHeaderDatePicker({ startDate, handleSubHeaderDropdownChange, header }: SubHeaderDatePickerProps) {
	return (
		<SubHeaderTH style={{ textTransform: 'none' }}>
			<DatePicker
				value={startDate}
				height={32}
				verticalPadding={false}
				onChange={(date: Date | undefined) => {
					handleSubHeaderDropdownChange(header.column.columnDef.id as string, date);
				}}
			/>
		</SubHeaderTH>
	);
}

export default SubHeaderDatePicker;
