import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { Divider, useTheme } from '@mui/material';
import { AssociatedOrg } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';

// TYPES
import {
	episodeUuid,
	getRelationshipType,
	getRelationshipTypeObject,
	getWorkType,
	getWorkTypeObject,
	seasonUuid,
	supplementalUuid,
} from '@warehouse/title/infra';
import { isDerivedFrom, isEpisodeOf, isSeasonOf, RelationshipType, WorkType } from '@warehouse/title/core';
import { replaceUUID } from '@warehouse/shared/util';
import { IndexedTitleV2 } from '@warehouse/graphql';

// HOOKS
import { useTitle } from '@warehouse/title/domain';
import { isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import useCreateTitleV2 from '../../../utils/hooks/titles/useCreateTitleV2';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';
import useFieldsByTitleTypeName from './useFieldsByTitleTypeName';
import { useURLQuery } from '../../../utils/hooks/useURLQuery';
import useLicensors from '../../../utils/hooks/licensor/useLicensors';
import useDryRun from '../useDryRun';
import { useColumnsMetadata, useGeneratedColumns } from './columns';

// UTILS
import validateTitlesData from './validation';
import mapTitleDataToMutation, { getRelationshipTypePerWorkTypeName } from '../mapTitleDataToMutation';

// LIBRARY
import { TitleCreationSummary } from './TitleCreationSummary';
import Alert from '../../../components/library/Alert';
import WorkTypeSelector from './WorkTypeSelector';
import WorkTypeDetailsSelector from './WorkTypeDetailsSelector';
import ParentTitleSelector from './ParentTitleSelector';
import LicensorSelector from './LicensorSelector';
import Loader from '../../../components/library/Loader';
import Button from '../../../components/library/Button';
import SimpleTable from '../../../components/library/SimpleTable';
import RelationshipTypeField from './RelationshipType';

// JSON
import availableWorkTypeDetailsByWorkType from '../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import defaultValues from './defaultValues';
import { ErrorHandlingMode } from '../../../components/library/SimpleTable/ErrorHandlingMode';
import { filterRowsByIndex } from '../../../components/library/SimpleTable/tableRowUtils';
import { fromBasic, getTitleData } from '../../../utils/titleGetProperty';
import { useChildrenTabName } from '../../title/hooks/useChildrenTabName';
import { TopBarLegacy } from '../../title/TopBarLegacy';
import { RelationshipModeHeader } from './RelationshipModeHeader';

type DefaultValuesGetter = Record<
	string,
	(_index: number) => Record<string, any> | undefined | string | (string | undefined)[] | number
>;

const workTypeDetailsIsDisplayed = ['Movie', 'Compilation', 'Episode', 'Supplemental'];

const parentFieldIsDisplayedAndRequired = {
	Movie: { displayed: false, required: false },
	Season: { displayed: true, required: true },
	Series: { displayed: false, required: false },
	Edit: { displayed: true, required: true },
	Compilation: { displayed: false, required: false },
	Episode: { displayed: true, required: true },
	Supplemental: { displayed: true, required: false },
	Manifestation: { displayed: false, required: false },
};

type TitlesData = {
	madeForRegions?: {
		madeForRegions: string[];
		isInclude: boolean;
	};
} & Record<string, any>;

function CreateMultipleTitlesView() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(true);
	const [titleCreationSummaryOpen, setTitleCreationSummaryOpen] = useState<boolean>(false);
	const [titlesData, setTitlesData] = useState<TitlesData[]>([]);
	const [titlesErrors, setTitlesErrors] = useState<{
		[columnId: string]: number[];
	}>({});
	const [parentTitleErrors, setParentTitleErrors] = useState<string>('');
	const [creationResult, setCreationResult] = useState<PromiseSettledResult<any>[]>([]);
	const [relationshipType, setRelationshipType] = useState<RelationshipType | undefined>();
	const relationshipTypeId = useMemo(
		() => (relationshipType ? getRelationshipTypeObject(relationshipType).relationshipTypeId : undefined),
		[relationshipType],
	);
	const [createTitleV2] = useCreateTitleV2();
	const { getNameByUuid, getUuidByName, getWorkTypesByRelationshipSource } = useWorkTypes();
	const { getDefault: getDefaultLicensor, loading: licensorLoading } = useLicensors();
	const [selectedWorkType, setSelectedWorkType] = useState<WorkType | undefined>();
	const selectedWorkTypeId = useMemo(
		() => (selectedWorkType ? getWorkTypeObject(selectedWorkType).uuid : undefined),
		[selectedWorkType],
	);
	const selectedWorkTypeName = useMemo(
		() => (selectedWorkType ? getWorkTypeObject(selectedWorkType).label : 'All'),
		[selectedWorkType],
	);
	const fields = useFieldsByTitleTypeName(selectedWorkTypeName);
	const [workTypeDetails, setWorkTypeDetails] = useState<string | undefined>();
	const [licensor, setLicensor] = useState('');
	const [parentType, setParentType] = useState<string | undefined>('all');
	const [parentTitle, setParentTitle] = useState<IndexedTitleV2 | undefined>();
	const urlQuery = useURLQuery();
	const initialSelectedParentUuid = urlQuery.get('selectedParentUuid');
	const initialSelectedWorkTypeUuid = urlQuery.get('selectedTitleTypeUuid');
	const createTitleMode = urlQuery.get('createTitleMode');
	if (createTitleMode && !['relationships'].includes(createTitleMode))
		throw new Error(`Invalid createTitleMode ${createTitleMode}`);
	const [parentPreselected] = useState(() => initialSelectedParentUuid !== null);
	const theme = useTheme();

	const selectedParentQuery = useTitle({
		skipable: true,
		uuid: initialSelectedParentUuid,
	});
	const { selectedParentTitleLight, selectedParentTitleLightLoading } = useMemo(() => {
		if (selectedParentQuery.isPending || !selectedParentQuery.data)
			return { selectedParentTitleLight: undefined, selectedParentTitleLightLoading: true };
		return {
			selectedParentTitleLight: selectedParentQuery.data.readonly,
			selectedParentTitleLightLoading: false,
		};
	}, [selectedParentQuery.isPending, selectedParentQuery.data]);

	const selectedParent = selectedParentQuery.data;
	const selectedParentIndexed = selectedParentQuery.data;

	const defaultLicensor = useMemo(() => {
		if (initialSelectedParentUuid) {
			const associatedOrgs = getTitleData<AssociatedOrg[]>(
				selectedParent,
				fromBasic(['associatedOrgs', 'displayValue']),
			);
			return associatedOrgs?.find((ao) => ao.role === 'licensor');
		}
		return getDefaultLicensor();
	}, [initialSelectedParentUuid, getDefaultLicensor, selectedParent]);

	const tabs = useChildrenTabName(selectedParent);

	const childrenProperties = useMemo(() => {
		if (!initialSelectedWorkTypeUuid) return undefined;
		return tabs.find(({ uuid }) => uuid === initialSelectedWorkTypeUuid);
	}, [initialSelectedWorkTypeUuid, tabs]);

	const workTypeOptions = useMemo(
		() =>
			childrenProperties?.relationshipType
				? getWorkTypesByRelationshipSource(childrenProperties.relationshipType)
				: undefined,
		[childrenProperties?.relationshipType],
	);

	const defaultValuesGetter: DefaultValuesGetter = {
		titleLanguage: () => defaultValues.titleLanguage,
		sequenceNumber: (_index: number) => (_index + 1).toString(),
		mode: () => defaultValues.mode,
		status: () => defaultValues.status,
		originalLanguages: () => [defaultValues.originalLanguage],
		countriesOfOrigin: () => [defaultValues.territory],
		madeForRegions: () => ({ madeForRegions: [], isInclude: true }),
	};

	const getLocalizedInfos = () => {
		if (selectedWorkType && getWorkTypeObject(selectedWorkType).workType === WorkType.Edit) {
			if ((parentTitle || selectedParent) && isFeatureEnabledForCurrentTenant('inheritance')) {
				return [];
			}
		}

		return [
			{
				default: true,
				titleDisplayUnlimited: titlesData[0]?.title,
				language: titlesData[0]?.titleLanguage,
			},
		];
	};

	const memoizedPayload = useMemo(
		() => ({
			metadata: {
				coreMetadata: {
					basic: {
						workTypeDetails,
						releaseYear: isFeatureEnabledForCurrentTenant('inheritance') ? undefined : '2024',
						localizedInfos: getLocalizedInfos(),
						parents:
							parentTitle || selectedParent
								? [
										{
											primary: undefined,
											parentContentId: parentTitle?.uuid || selectedParent?.uuid,
											relationshipType: getRelationshipTypePerWorkTypeName(
												getNameByUuid(selectedWorkTypeId || '') || '',
											),
										},
								  ]
								: undefined,
						workType: selectedWorkTypeId,
						terms: {
							titleStatus: isFeatureEnabledForCurrentTenant('inheritance') ? undefined : 'valid',
						},
					},
				},
			},
		}),
		[
			selectedWorkTypeId,
			parentTitle,
			selectedParent,
			workTypeDetails,
			titlesData[0]?.title,
			titlesData[0]?.titleLanguage,
		],
	);

	const { inheritedValues, errors: dryRunErrors } = useDryRun({
		// @ts-ignore
		payload: memoizedPayload?.metadata,
	});

	// Set loading state to false once every required information has been fetched
	useEffect(() => {
		if (!initialSelectedParentUuid) {
			setLoading(false);
			return;
		}
		if (selectedParentQuery?.isPending === false) setLoading(false);
	}, [selectedParentQuery?.isPending]);

	useEffect(() => {
		if (!selectedWorkTypeId) {
			let wtUuid: string | undefined;
			if (initialSelectedParentUuid && initialSelectedWorkTypeUuid) {
				if (!selectedParentQuery.isPending) wtUuid = initialSelectedWorkTypeUuid;
			} else {
				wtUuid = workTypeOptions?.[0].value;
			}

			if (wtUuid) {
				const workTypeDetailsElement =
					availableWorkTypeDetailsByWorkType[getNameByUuid(wtUuid) as keyof typeof availableWorkTypeDetailsByWorkType];
				setSelectedWorkType(getWorkType(wtUuid));
				if (workTypeDetailsElement?.defaultWorkTypeDetail[0]?.value)
					setWorkTypeDetails(workTypeDetailsElement.defaultWorkTypeDetail[0].value);
			}
		}
	}, [selectedParent, childrenProperties]);

	// Initially, or when workType changes, reset the titlesData to the default values
	useEffect(() => {
		setTitlesData(getInitialDefaultValues(fields, defaultValuesGetter));
	}, [fields, selectedWorkTypeId]);

	useEffect(() => {
		setLicensor(defaultLicensor?.displayName ?? '');
	}, [defaultLicensor?.displayName]);

	useEffect(() => {
		if (selectedWorkTypeId && selectedWorkTypeName) {
			const workTypeDetailsElement =
				availableWorkTypeDetailsByWorkType[selectedWorkTypeName as keyof typeof availableWorkTypeDetailsByWorkType];
			const defaultWorkTypeDetails = workTypeDetailsElement?.defaultWorkTypeDetail[0]?.value;

			if (defaultWorkTypeDetails) {
				setWorkTypeDetails(defaultWorkTypeDetails);
			} else {
				setWorkTypeDetails(undefined);
			}
		}

		setParentTitle(undefined); // 16 * 16
		setParentTitleErrors('');
		setTitlesErrors({});
	}, [selectedWorkTypeId]);

	useEffect(() => {
		if (titlesData.length > 0 && Object.keys(titlesErrors).some((key) => titlesErrors[key].length)) {
			validateTitlesData(fields, titlesData, setTitlesErrors, inheritedValues);
		}
	}, [titlesData]);

	useEffect(() => {
		if (parentTitle) {
			setParentTitleErrors('');
		}
	}, [parentTitle]);

	const addNewRowOnClick = (numberOfRow: number, selectedSubheaderValues: Record<string, any>) => {
		setTitlesData([
			...titlesData,
			...getDefaultedNewRows(fields, defaultValuesGetter, titlesData, numberOfRow, selectedSubheaderValues),
		]);
	};

	// This function checks if the episode sequence number is sequenced, it returns false if not, otherwise it returns the first sequence number
	const episodeSequenced = (): number | false => {
		const sequenceNumbers = titlesData.map((title) => parseInt(title.sequenceNumber, 10));
		if (sequenceNumbers.length === 0) return false;
		// Check if there is non number value in the sequenceNumbers
		if (sequenceNumbers.some((num) => Number.isNaN(num))) return false;
		// Check if the sequenceNumbers are not in sequence (next element should always equal to previous element + 1)
		if (
			!sequenceNumbers.every((num, idx, arr) => {
				if (idx === arr.length - 1) return true;
				return num + 1 === arr[idx + 1];
			})
		)
			return false;
		return sequenceNumbers[0];
	};

	const onDragEnd = () => {
		if (selectedWorkTypeId === episodeUuid() || selectedWorkTypeId === seasonUuid()) {
			const startSequenceNumber = episodeSequenced();
			if (startSequenceNumber !== false) {
				setTitlesData((prev) =>
					prev.map((item, index) => ({
						...item,
						sequenceNumber: (index + startSequenceNumber).toString(),
					})),
				);
			}
		}
	};

	const deleteRowOnClick = (indexes: string[]) => {
		setTitlesData(filterRowsByIndex(titlesData, indexes) || []);
	};

	const columnMetadata = useColumnsMetadata(titlesData?.map((title) => title?.title || '') || [], inheritedValues);

	const generatedColumns = useGeneratedColumns(
		fields,
		columnMetadata,
		selectedWorkTypeId,
		getUuidByName('Episode'),
		getUuidByName('Season'),
	);

	const onSubmit = async () => {
		if (
			selectedWorkTypeId &&
			parentFieldIsDisplayedAndRequired[
				getNameByUuid(selectedWorkTypeId) as keyof typeof parentFieldIsDisplayedAndRequired
			]?.required &&
			!parentTitle &&
			!selectedParent
		) {
			setParentTitleErrors('Parent title is required');
			return;
		}

		if (!licensor) {
			setParentTitleErrors('A licensor is required');
			return;
		}

		// Can't continue if parent is not selected on a supplemental title with relationshipType
		if (!!relationshipTypeId && !parentTitle && !selectedParent) {
			setParentTitleErrors('Parent title is required');
			return;
		}

		// Can't continue if relationshipType is not selected on a supplemental title with parents
		if (selectedWorkTypeName === 'Supplemental' && (parentTitle || selectedParent) && !relationshipTypeId) {
			return;
		}

		const isValid = validateTitlesData(fields, titlesData, setTitlesErrors, inheritedValues);

		if (isValid && !parentTitleErrors) {
			setLoading(true);
			const results = await Promise.allSettled(
				titlesData.map(async (titleData, _index) => {
					if (selectedWorkTypeId) {
						const payload = mapTitleDataToMutation(
							{
								...titleData,
								licensor,
								relationshipType: relationshipTypeId,
								workTypeDetail: workTypeDetails,
								parentTitle: {
									primary: getParentIsPrimary(_index, selectedWorkTypeId, relationshipTypeId),
									title: parentTitle || selectedParentIndexed,
								},
								originalLanguages:
									titleData?.originalLanguages?.map((ol: string, index: number) => ({
										language: ol,
										spoken: true,
										written: false,
										signed: false,
										listingOrder: index + 1,
									})) || undefined,
							},
							selectedWorkTypeId,
							createTitleMode === 'relationships' ? relationshipTypeId : childrenProperties?.relationshipType,
						);

						if (!payload) throw new Error('Invalid payload');

						await createTitleV2({
							variables: {
								input: payload,
							},
						});
					}
				}),
			);
			setLoading(false);
			setCreationResult(results);
			setTitleCreationSummaryOpen(true);
			setTitlesData((prevState) => prevState.filter((_, index) => results[index].status === 'rejected'));
		}
	};

	return (
		<Wrapper>
			<TopWrapper>
				<TopBarWrapper>
					<TopBarLegacy
						title="Create Titles"
						onClickBack={() => navigate(-1)}
						hideTitleCard
						actions={
							<Button
								nxstyle="cta"
								disabled={loading || titlesData?.length === 0}
								startIcon={loading ? undefined : <Icon path={mdiPlus} size={0.7} />}
								onClick={() => onSubmit()}
							>
								{loading ? <Loader size={20} color={theme.palette.light.main} /> : 'Create'}
							</Button>
						}
					/>
				</TopBarWrapper>
				<FormWrapper>
					{createTitleMode === 'relationships' && !selectedParentTitleLightLoading && (
						<RelationshipModeHeader
							parentTitle={selectedParentTitleLight!}
							childWorkType={selectedWorkType}
							onSelectChildWorkType={setSelectedWorkType}
							relationshipType={relationshipType}
							onSelectRelationshipType={setRelationshipType}
							workTypeDetail={workTypeDetails}
							setWorkTypeDetail={setWorkTypeDetails}
						/>
					)}
					{createTitleMode !== 'relationships' && (
						<>
							<WorkTypeSelector
								workType={selectedWorkTypeId}
								loadingParent={!!initialSelectedParentUuid && selectedParentQuery.isPending}
								setWorkType={(workTypeUuid) => setSelectedWorkType(getWorkType(workTypeUuid))}
								setParentType={setParentType}
								selectedParent={selectedParent}
								childrenProperties={childrenProperties}
							/>
							{workTypeDetailsIsDisplayed.includes(selectedWorkTypeName || '') && (
								<>
									<Divider orientation="vertical" />
									<WorkTypeDetailsSelector
										workTypeDetails={workTypeDetails}
										setWorkTypeDetails={setWorkTypeDetails}
										workTypeName={selectedWorkTypeName}
										loading={loading}
									/>
								</>
							)}
							<Divider orientation="vertical" />
							<LicensorSelector
								licensor={licensor}
								onLicensorChange={setLicensor}
								parentHasBeenSelected={!!parentTitle}
								setParent={setParentTitle}
								loading={licensorLoading}
								readOnly={!!initialSelectedParentUuid}
							/>
							<Divider orientation="vertical" />
							{parentFieldIsDisplayedAndRequired[selectedWorkTypeName as keyof typeof parentFieldIsDisplayedAndRequired]
								?.displayed && (
								<>
									{selectedWorkTypeName === 'Supplemental' && (
										<RelationshipTypeField
											relationshipType={relationshipTypeId}
											setRelationshipType={(newRelationshipTypeId) => {
												if (newRelationshipTypeId) setRelationshipType(getRelationshipType(newRelationshipTypeId));
												else setRelationshipType(undefined);
											}}
											isRequired={!!parentTitle}
											setParentTitleErrors={setParentTitleErrors}
										/>
									)}
									<ParentTitleSelector
										parentTitle={parentTitle}
										isRequired={
											parentFieldIsDisplayedAndRequired[
												(selectedWorkTypeName || '') as keyof typeof parentFieldIsDisplayedAndRequired
											]?.required || !!relationshipTypeId
										}
										setParentTitle={setParentTitle}
										setParentType={setParentType}
										selectedParent={selectedParentQuery.data?.readonly}
										workType={selectedWorkTypeId}
										parentTitleErrors={parentTitleErrors}
										parentType={parentType}
										licensor={licensor}
									/>
								</>
							)}
						</>
					)}
				</FormWrapper>
			</TopWrapper>
			<TableWrapper>
				{dryRunErrors && dryRunErrors.length !== 0 && (
					<Alert severity="error">
						<ul>
							{dryRunErrors?.map((err: any) => (
								<li>{err?.errorInfo?.titleUuid ? replaceUUID(err.message) : err.message}</li>
							))}
						</ul>
					</Alert>
				)}
				<SimpleTable
					columns={generatedColumns}
					data={titlesData}
					setData={setTitlesData}
					onRowAdd={addNewRowOnClick}
					onRowDelete={deleteRowOnClick}
					editable
					draggable
					onDragEnd={onDragEnd}
					errors={titlesErrors}
					showBottomActionsBar
					showSubHeader
					tableStyle="subheader"
					hideIndexColumn={!!fields.find(({ field }) => field === 'sequenceNumber')}
					errorHandlingMode={ErrorHandlingMode.MultiTitleCreate}
				/>
			</TableWrapper>
			<TitleCreationSummary
				open={titleCreationSummaryOpen}
				successNumber={creationResult.filter((res) => res.status === 'fulfilled').length}
				errorNumber={creationResult.filter((res) => res.status === 'rejected').length}
				creationResults={creationResult}
				handleClose={() => setTitleCreationSummaryOpen(false)}
				createdFromTitle={parentPreselected}
			/>
		</Wrapper>
	);
}

function getMaxSequenceNumber(titlesData: any[]) {
	const currentSequenceNumber = titlesData
		.map((title) => parseInt(title.sequenceNumber, 10))
		.filter((val) => !Number.isNaN(val));
	return currentSequenceNumber.length ? Math.max(...currentSequenceNumber) : 0;
}

function getDefaultedNewRows(
	fields: { field: string; required: boolean }[],
	defaultValuesGetter: DefaultValuesGetter,
	titlesData: TitlesData[],
	numberOfRow: number,
	selectedSubheaderValues: Record<string, any>,
) {
	const maxSequenceNumber = getMaxSequenceNumber(titlesData);
	return Array.from({ length: numberOfRow }, (_, index) =>
		Object.fromEntries(
			fields?.map(({ field }: { field: string }) => {
				if (field === 'sequenceNumber') {
					return [field, (maxSequenceNumber + index + 1).toString()];
				}
				if (Object.keys(defaultValuesGetter).includes(field)) {
					return [field, selectedSubheaderValues[field] || defaultValuesGetter[field](index)];
				}
				return [field, selectedSubheaderValues[field] || ''];
			}),
		),
	);
}

function getInitialDefaultValues(
	fields: { field: string; required: boolean }[],
	defaultValuesGetter: DefaultValuesGetter,
) {
	return [
		Object.fromEntries(
			fields.map(({ field }: { field: string; required: boolean }) => {
				if (Object.keys(defaultValuesGetter).includes(field)) {
					return [field, defaultValuesGetter[field](0)];
				}
				return [field, ''];
			}),
		),
	];
}

function getParentIsPrimary(index: number, workType: string, selectedRelationshipType: string | undefined) {
	const relationshipType =
		workType === supplementalUuid()
			? selectedRelationshipType
			: getRelationshipTypePerWorkTypeName(getWorkType(workType));

	if (relationshipType === isDerivedFrom()) {
		if (index === 0) return undefined;
		return false;
	}

	if ([isSeasonOf(), isEpisodeOf()].includes(relationshipType || '')) {
		return true;
	}

	return undefined;
}

export default CreateMultipleTitlesView;

const Wrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		overflow-y: auto;
		padding: ${theme.spacing(2)};
		width: 100%;
	`,
);

const TopWrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);
const TopBarWrapper = styled.div(
	({ theme }) => css`
		border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		box-sizing: border-box;
		padding: 0 ${theme.spacing(2)};
		width: 100%;
	`,
);

const FormWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		display: flex;
		justify-content: flex-start;
	`,
);

const TableWrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		padding: ${theme.spacing(2)};
	`,
);
