import { CellContext } from '@tanstack/react-table';
import React, { useMemo, useRef, useState } from 'react';
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Rating } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { DropdownV2 } from '@warehouse/shared/ui';
import { isInherited } from '@warehouse/title/core';
import { findTerritory } from './utils';
import { WithUuid } from '../../../../hooks/useTitleRecordsAutoSave/recordToArray';
import WillClearFieldsConfirmModal from '../../../../../../components/titles/WillClearFieldsConfirmModal';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

function CellSystem(info: CellContext<WithUuid<Inherited<Rating>>, WithUuid<Inherited<Rating>>>) {
	const [openModal, setOpenModal] = useState(false);
	const { getValue, table } = info;
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;
	const confirmRef = useRef<() => void>();

	const options = useMemo(
		() =>
			(findTerritory(getValue()?.displayValue)?.ratingSystems || []).map((ratingSystem) => ({
				value: ratingSystem.uuid,
				label: ratingSystem.name,
			})),
		[getValue()?.displayValue?.region?.countryRegion, getValue()?.displayValue?.region?.country],
	);

	const onChange = (e: string | null) => {
		confirmRef.current = () => {
			editRecord?.({
				...getValue(),
				displayValue: {
					...getValue()?.displayValue,
					system: e,
					value: undefined,
					reasons: [],
					descriptions: [],
				},
			});
			setOpenModal(false);
		};

		const { value, reasons, descriptions } = getValue()?.displayValue || {};

		if (value || (reasons?.length || []) > 0 || (descriptions || [])?.length > 0) {
			setOpenModal(true);
		} else {
			confirmRef.current?.();
		}
	};

	if (options?.length > 0) {
		return (
			<>
				<CellWrapper>
					<DropdownV2
						variant={isInherited({ inheritedObject: getValue() }).isInherited ? 'prefilled' : 'default'}
						autoFocus={!!getValue().displayValue.region && !getValue()?.displayValue?.system}
						withSearch
						value={getValue()?.displayValue?.system}
						backgroundColor="transparent"
						transparentBorder
						onChange={onChange}
						options={options}
					/>
				</CellWrapper>
				<WillClearFieldsConfirmModal
					open={openModal}
					handleClose={() => setOpenModal(false)}
					onConfirm={() => confirmRef?.current?.()}
				/>
			</>
		);
	}
	return null;
}

export default CellSystem;
