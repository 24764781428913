import React from 'react';
import styled, { css } from 'styled-components';
import MuiStepLabel, { StepLabelProps } from '@mui/material/StepLabel';
import CheckIcon from '@mui/icons-material/Check';

const StyledStepLabel = styled(MuiStepLabel)<StepLabelProps>`
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	& .MuiStepLabel-iconContainer {
		padding-right: 12px;
	}
`;

type CustomLabelProps = {
	completed: boolean;
	active: boolean;
	icon: number;
};

const CustomLabel = styled.div<CustomLabelProps>`
  ${({ completed, active, theme }) => {
		if (completed || active) {
			return css`
				background-color: ${theme.palette.action.active};
				color: ${theme.palette.text.reverseMain};
			`;
		}
		return css`
			background-color: ${theme.palette.action.disabled};
			color: ${theme.palette.text.reverseMain};
		`;
	}}

  ${({ active }) => {
		if (active) {
			return css`
				height: 36px;
				width: 36px;
			`;
		}
		return css`
			height: 24px;
			width: 24px;
		`;
	}}
	
	${() => css`
		align-items: center;
		border-radius: 4px;
		display: flex;
		font-weight: bold;
		justify-content: center;

		& .MuiSvgIcon-root {
			font-size: 18px;
		}
	`}
}
`;

function CustomLabelElement(props: CustomLabelProps) {
	const { icon, completed } = props;
	return <CustomLabel {...props}>{completed ? <CheckIcon /> : icon}</CustomLabel>;
}

function Stepper(props: StepLabelProps) {
	const { children } = props;
	return (
		<StyledStepLabel {...props} StepIconComponent={CustomLabelElement}>
			{children}
		</StyledStepLabel>
	);
}

export default Stepper;
