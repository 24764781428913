import { PartialDisplayName } from './comp-obj-relationship.model';
import { LanguageDropdownOption } from '../core/languages';

export function computeExistingLanguageIds(displayNames: PartialDisplayName[]): string[] {
	const uniqueLanguageIds = new Set<string>();

	displayNames.forEach((displayName) => {
		if (displayName.language) {
			uniqueLanguageIds.add(displayName.language.uuid);
		}
	});

	return Array.from(uniqueLanguageIds);
}

export function filterOutExistingLanguages(existingLanguageIds: string[], languageOptions: LanguageDropdownOption[]) {
	return languageOptions.filter((languageOption) => !existingLanguageIds.includes(languageOption.value));
}
