import React, { CSSProperties } from 'react';
import { replaceUUID } from '@warehouse/shared/util';
import Alert from '../../../src/components/library/Alert';

export function ErrorsAlert({ errorMessages, onClose, style }: ErrorAlertProps) {
	return (
		<Alert onClose={onClose} style={{ margin: 0, ...style }} severity="error">
			<ul>
				{errorMessages?.map((message: any, index: number) => (
					<li key={`${message} ${index}`}>{replaceUUID(message)}</li>
				))}
			</ul>
		</Alert>
	);
}

interface ErrorAlertProps {
	errorMessages: string[];
	onClose?: () => void;
	style?: CSSProperties;
}
