import { useParams } from 'react-router-dom';

export function useRequiredParam(paramName: string): string {
	const params = useParams();

	const param = params[paramName];
	if (!param) {
		throw new Error(`${paramName} is required`);
	}

	return param;
}
