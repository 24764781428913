import { gql } from '@apollo/client';

export const SEARCH_TRACKS = gql`
	query SearchTracks($search: SearchInput!) {
		searchTracks(input: $search) {
			documents {
				... on VideoTrack {
					trackType
					asset {
						uuid
						ingestedAt
						fileStatus
					}
					uuid
					proxyStatus
					trackName
				}
				... on AudioTrack {
					trackType
					asset {
						uuid
						fileStatus
						ingestedAt
					}
					uuid
					proxyStatus
					trackName
				}
				... on SubtitleTrack {
					trackType
					asset {
						uuid
						fileStatus
						ingestedAt
					}
					uuid
					proxyStatus
					trackName
				}
			}
			page {
				totalPage
				totalDocument
				perPage
				currentPage
			}
		}
	}
`;

export const GET_TRACK_ASSETS = gql`
	query GetTrackAssets($uuid: ID!) {
		getTrack(uuid: $uuid) {
			... on VideoTrack {
				asset {
					uuid
					fileStatus
					assetUrl
					ingestedAt
					checksums {
						etag
					}
					linkedTracks {
						... on VideoTrack {
							trackType
							trackName
						}
						... on AudioTrack {
							trackType
							trackName
						}
						... on SubtitleTrack {
							trackType
							trackName
						}
					}
					linkedTrackIds
					fileType
					storageTier
				}
				proxyStatus
				assetUuid
				uuid
			}
			... on AudioTrack {
				asset {
					fileType
					uuid
					fileStatus
					assetUrl
					ingestedAt
					checksums {
						etag
					}
					linkedTracks {
						... on VideoTrack {
							trackType
							trackName
						}
						... on AudioTrack {
							trackType
							trackName
						}
						... on SubtitleTrack {
							trackType
							trackName
						}
					}
					linkedTrackIds
					storageTier
				}
				proxyStatus
				assetUuid
				uuid
			}
			... on SubtitleTrack {
				asset {
					fileType
					uuid
					fileStatus
					assetUrl
					ingestedAt
					checksums {
						etag
					}
					linkedTracks {
						... on VideoTrack {
							trackType
							trackName
						}
						... on AudioTrack {
							trackType
							trackName
						}
						... on SubtitleTrack {
							trackType
							trackName
						}
					}
					linkedTrackIds
					storageTier
				}
				proxyStatus
				assetUuid
				uuid
			}
		}
	}
`;

export const GET_TRACK_DETAILS = gql`
	query GetTracksDetails($uuid: ID!) {
		getTrack(uuid: $uuid) {
			... on VideoTrack {
				archival
				textedType
				colorType
				encoding {
					actualLength
					bitrateAverage
					bitrateMax
					bitrateMode
					codec
					codecProfile
					mpegLevel
					mpegProfile
					vbr
					watermarks {
						data
						productAndVersionId
						vendor
					}
				}
				picture {
					Progressive
					activeHeightPixels
					activeWidthPixels
					alphaDepth
					aspectRatio {
						aspectRatio
						original
					}
					bitDepth
					cadence
					colorEncoding {
						colorDifferencing
						primaries
						transferFunction
					}
					colorSubsampling
					colorTransformMetadata {
						colorVolumeTransform
					}
					colorimetry
					displayFrameRate
					displayResolution {
						heightPixels
						widthPixels
					}
					frameRate
					masteredColorVolume {
						luminanceMax
						luminanceMin
						primaryBChromaticity {
							ChromaticityCIEx
							ChromaticityCIEy
						}
						primaryGChromaticity {
							ChromaticityCIEx
							ChromaticityCIEy
						}
						primaryRChromaticity {
							ChromaticityCIEx
							ChromaticityCIEy
						}
						whitePointChromaticity {
							ChromaticityCIEx
							ChromaticityCIEy
						}
					}
					multiplier
					pixelAspect
					scanOrder
					type3d
					validateImageAspectRatio
					xOffset
					yOffset
				}
				pictureFormat
				subTypes
				subtitleLanguages {
					closed
					disposition
					type
				}
			}
			... on AudioTrack {
				channels
				compliance {
					category
					certificate {
						MIME
						certificate
					}
					comments
					competentAuthority
					disposition
					errorDescriptions {
						categorySpecificInfo
						comments
						errorCategory
						errorReference
						errorTerm
						fullOrPartialQC
						qcReportLocation
						severity {
							originalSeverity
							resolution
							severity
						}
					}
					standard
					standardDetails
					testingDate
					testingMethod
					testingOrganization
				}
				containerReferences {
					containerFirstTrackIdentifier
					containerIdentifiers {
						identifier
						namespace
						referenceLocation
						scope {
							scope
							subscope
						}
					}
					containerIntents {
						assetReferences {
							contentIds
							description {
								description
								language
							}
							otherIdentifiers {
								identifier
								namespace
								referenceLocation
								scope {
									scope
									subscope
								}
							}
						}
						associatedOrgs
						description
						subTypes {
							ordinal
							subType
						}
						type
						wip
					}
					containerLocations {
						containerLocation
						priority
					}
					hashes
					length
					parentContainer
					subTypes
					type
					sequenceParsing {
						endsWith
						filenameEmbedding {
							filenameEmbedding
							fixedDigits
							location
							preceededBy
							sequential
							terinatedBy
						}
						patternSpec
						startsWith
					}
				}
				descriptions {
					description
					language
				}
				encoding {
					actualLength
					ambisonics {
						normalization
						order
						type
						verticalOrder
					}
					bitrateAverage
					bitrateMax
					bitrateMode
					channelMapping
					codec
					codecTypes
					isSingleTrack
					loudness {
						compliance
						deviation
						leqM
						level
					}
					sampleBitDepth
					sampleRate
					vbr
					watermarks {
						data
						productAndVersionId
						vendor
					}
				}
				languages {
					disposition
					dubbed
					forced
					forcedSubtitleRequired
					language
				}
				mcaLabelSubdescriptor {
					content
					contentDifferentiator
					contentKind
					contentSubtype
					elementKind
					useClass
				}
				peoples {
					biographies {
						biography
						language
					}
					gender
					identifiers {
						identifier
						namespace
						referenceLocation
						scope {
							scope
							subscope
						}
					}
					imageReferences {
						imageReference
						purpose
						resolution
					}
					jobs {
						billingBlockOrders {
							billingBlockOrder
							region
							topBilled
						}
						characters
						charactersInfos {
							characterDescriptions {
								characterDescription
								language
							}
							characterIds {
								identifier
								namespace
								referenceLocation
								scope {
									scope
									subscope
								}
							}
							characterNames {
								language
								characterName
							}
							genders
							groupingEntities {
								altGroupIdentifiers {
									identifier
									location
									namespace
									scope {
										scope
										subscope
									}
								}
								displayNames {
									displayName
									language
								}
								groupIdentity
								region
								type
							}
							imageReferences {
								imageReference
								purpose
								resolution
							}
							nonfictional {
								appearance
								nonfictional
							}
							pronouns {
								adjective
								objective
								possessive
								reflexive
								subjective
							}
							salutations {
								default
								title
							}
						}
						guest
						jobDisplays {
							jobDisplay
							language
						}
						jobFunctions {
							jobFunction
							scheme
						}
					}
					name {
						displayNames {
							displayName
							language
						}
						familyName
						firstGivenName
						moniker
						secondGivenName
						sortNames {
							language
							sortName
						}
						suffix
					}
					pronouns {
						adjective
						objective
						possessive
						reflexive
						subjective
					}
					salutations {
						default
						title
					}
				}
				private
				subTypes
				trackIdentifiers {
					identifier
					namespace
					referenceLocation
					scope {
						scope
						subscope
					}
				}
			}
			... on SubtitleTrack {
				format
				additionalOffset
				compliance {
					category
					certificate {
						MIME
						certificate
					}
					competentAuthority
					disposition
					comments
					errorDescriptions {
						categorySpecificInfo
						comments
						errorTerm
						errorReference
						errorCategory
						fullOrPartialQC
						qcReportLocation
						severity {
							originalSeverity
							severity
							resolution
						}
					}
					standardDetails
					standard
					testingDate
					testingMethod
					testingOrganization
				}
				containerReferences {
					containerFirstTrackIdentifier
					containerIdentifiers {
						identifier
						namespace
						referenceLocation
						scope {
							scope
							subscope
						}
					}
					containerIntents {
						assetReferences {
							contentIds
							description {
								description
								language
							}
							otherIdentifiers {
								identifier
								namespace
								referenceLocation
								scope {
									scope
									subscope
								}
							}
						}
						associatedOrgs
						description
						subTypes {
							ordinal
							subType
						}
						type
						wip
					}
					containerLocations {
						containerLocation
						priority
					}
					hashes
					length
					parentContainer
					sequenceParsing {
						endsWith
						filenameEmbedding {
							filenameEmbedding
							fixedDigits
							location
							preceededBy
							sequential
							terinatedBy
						}
						patternSpec
						startsWith
					}
					type
					subTypes
				}
				dropFrame
				formatType
				languages {
					disposition
					dubbed
					forced
					forcedSubtitleRequired
					language
				}
				pictureDetails {
					Progressive
					activeHeightPixels
					activeWidthPixels
					alphaDepth
					aspectRatio {
						aspectRatio
						original
					}
					bitDepth
					cadence
					colorEncoding {
						colorDifferencing
						primaries
						transferFunction
					}
					colorSubsampling
					colorTransformMetadata {
						colorVolumeTransform
					}
					colorimetry
					displayFrameRate
					displayResolution {
						heightPixels
						widthPixels
					}
					frameRate
					masteredColorVolume {
						luminanceMax
						luminanceMin
						primaryBChromaticity {
							ChromaticityCIEx
							ChromaticityCIEy
						}
						primaryGChromaticity {
							ChromaticityCIEx
							ChromaticityCIEy
						}
						whitePointChromaticity {
							ChromaticityCIEx
							ChromaticityCIEy
						}
						primaryRChromaticity {
							ChromaticityCIEx
							ChromaticityCIEy
						}
					}
					multiplier
					pixelAspect
					scanOrder
					type3d
					validateImageAspectRatio
					xOffset
					yOffset
				}
				properties {
					containsAnnotation
					formattingStripped
					maxCps
					maxLinesPerEvent
					writingFeatures {
						positionalData
						translatorCreditPresence
					}
				}
				subTypes
				subtitleTypes {
					archival
					subtitleType
				}
			}
		}
	}
`;

export const SEARCH_TRACKS_ASSET_UUID = gql`
	query SearchTracksAssetUuid($search: SearchInput!) {
		searchTracks(input: $search) {
			documents {
				... on VideoTrack {
					assetUuid
				}
				... on AudioTrack {
					assetUuid
				}
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`;

// TODO : Add `name` when it is available from back-end
export const SEARCH_TRACKS_NAME = gql`
	query SearchTracksName($search: SearchInput!) {
		searchTracks(input: $search) {
			documents {
				... on VideoTrack {
					uuid
					trackName
				}
				... on AudioTrack {
					uuid
					trackName
				}
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`;
