import {
	ApolloClient,
	ApolloQueryResult,
	createHttpLink,
	InMemoryCache,
	OperationVariables,
	WatchQueryOptions,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Observable } from 'rxjs';
import { createFragmentRegistry } from '@apollo/client/cache';
import { FRAGMENTS } from '@warehouse/graphql';
import { isDev } from '../../../src/utils/isDev';

function createApolloAdapter() {
	let token: string | null;

	const httpLink = createHttpLink({
		uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
	});
	const authLink = setContext((_, { headers }) => ({
		headers: {
			...headers,
			Authorization: `Bearer ${token}`,
		},
	}));

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache({
			fragments: createFragmentRegistry(FRAGMENTS),
		}),
		devtools: {
			enabled: isDev(),
		},
	});

	return {
		client,
		setToken(_token: string | null) {
			token = _token;
		},
		watchObservableQuery<T = any, TVariables extends OperationVariables = OperationVariables>(
			options: WatchQueryOptions<TVariables, T>,
		): Observable<ApolloQueryResult<T>> {
			return new Observable<ApolloQueryResult<T>>((observer) => {
				const query = client.watchQuery<T, TVariables>({ ...options, fetchPolicy: 'cache-and-network' });
				const subscription = query.subscribe(observer);
				return () => subscription.unsubscribe();
			});
		},
	};
}

export const apolloAdapter = createApolloAdapter();
