import { ChildrenColumnType } from './children-compilation-extended.model';

export function computeWarnings(titles: ChildrenColumnType[]): { [columnId: string]: number[] } {
	const result: { [columnId: string]: number[] } = {
		sequenceNumber: [],
	};

	titles.forEach((title, index) => {
		if (title.selectedParent.sequenceInfo.number == null) {
			result.sequenceNumber.push(index);
		}
	});

	return result;
}
