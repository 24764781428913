import { Column, ColumnOrderState } from '@tanstack/react-table';
import { RefObject } from 'react';

export type ColumnStickyState = string[];

export function getStickyPosition(
	columns: Column<any, any>[],
	column: Column<any, any>,
	columnsOrder: ColumnOrderState,
	trueColumnsSize: number[],
	stickyState: ColumnStickyState,
) {
	let res = 0;
	let colCopy = [...columns];
	colCopy = colCopy.filter((item) => item.getIsVisible());
	colCopy.sort((a, b) => {
		const indexA = columnsOrder.indexOf(a.id);
		const indexB = columnsOrder.indexOf(b.id);
		if (indexA !== -1 && indexB !== -1) return columnsOrder.indexOf(a.id) - columnsOrder.indexOf(b.id);
		return 0;
	});
	colCopy.some((col, index) => {
		if (stickyState.includes(col.id) && col.id !== column.id) {
			res += trueColumnsSize[index];
			return false;
		}
		return col.id === column.id;
	});

	return res;
}

export function getColumnsTrueSize(tableRef: RefObject<HTMLTableElement> | null) {
	const res: number[] = [];

	if (tableRef && tableRef.current)
		tableRef.current.querySelectorAll('th').forEach((item) => res.push(item.clientWidth - 1));
	return res;
}

export function getScrollPadding(
	columns: Column<any, any>[],
	stickyState: ColumnStickyState,
	trueColumnsSize: number[],
) {
	let res = 0;

	columns.forEach((col, index) => {
		if (stickyState.includes(col.id) && trueColumnsSize[index]) res += trueColumnsSize[index];
	});

	return res;
}
