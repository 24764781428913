import { useMutation } from '@apollo/client';
import { BatchOperations, TransactionalBatchOperationsOutput } from '../../../graphql/codegen/graphql';
import { TRANSACTIONAL_BATCH_OPERATIONS } from '../../../graphql/mutations';
import { GET_TITLE_V2 } from '../../../graphql/queries/title';

// eslint-disable-next-line import/prefer-default-export
export function useTransactionalBatchOperations() {
	return useMutation<
		{
			transactionalBatchOperations: TransactionalBatchOperationsOutput;
		},
		{ input: BatchOperations }
	>(TRANSACTIONAL_BATCH_OPERATIONS, {
		update(cache, { data }) {
			try {
				if (!data?.transactionalBatchOperations) return;

				const title = data?.transactionalBatchOperations?.title;

				cache.writeQuery({
					query: GET_TITLE_V2,
					variables: {
						uuid: title?.uuid,
					},
					data: {
						getTitleV2: title,
					},
				});
			} catch (error) {
				console.error(error);
			}
		},
	});
}
