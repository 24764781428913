import React from 'react';
import { Tooltip } from '@mui/material';
import { mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import useTooltip from '../../utils/hooks/useTooltips';

interface TitleTooltipProps {
	path: string;
}

function TitleTooltip({ path }: TitleTooltipProps) {
	const tooltip = useTooltip(path || '');

	if (!tooltip) return null;

	return (
		<Tooltip title={tooltip.tooltip} placement="right">
			<Icon path={mdiInformation} size="16px" />
		</Tooltip>
	);
}

export default TitleTooltip;
