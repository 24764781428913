import React, { useEffect, useMemo, useRef, useState } from 'react';
import { debounce, Portal, Typography, useTheme } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Icon } from '@mdi/react';
import { mdiAppleKeyboardCommand, mdiChevronDown, mdiInformation, mdiMagnify } from '@mdi/js';

// TYPES
import { autoUpdate, flip, offset, shift, size, useFloating } from '@floating-ui/react';
import { CommonTitleFilters } from '@warehouse/title/domain';
import { IndexedTitleV2 } from '@warehouse/graphql';

// HOOKS
import useDebounce from '../../../utils/hooks/useDebounce';
import useOnTabFocus from '../../../utils/hooks/useOnTabFocus';
import useSearchTitlesDropdownV2 from '../../../utils/hooks/titles/useSearchTitlesDropdownV2';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';

// UTILS
import { LabelBlockWrapper, TooltipIcon } from '../../library/InputUtils';

// LIBRARY
import TextInput from '../../library/TextInput';
import ModalMenu, { MenuListItem } from '../../library/ModalMenu';
import { Label } from '../../library/DropdownUtils';
import Tooltip from '../../library/Tooltip';
import Row from './Row';
import {
	ErrorInputWrapper,
	InputWrapper,
	MenuList,
	MenuWrapper,
	NoResultWrapper,
	StyledButton,
	SubWrapper,
	Wrapper,
	StyledButtonTypography,
} from './styles';
import compilationSVG from '../../../assets/title-icons/titleSelector/compilation.svg';
import episodeSVG from '../../../assets/title-icons/titleSelector/episode.svg';
import movieSVG from '../../../assets/title-icons/titleSelector/movie.svg';
import seriesSVG from '../../../assets/title-icons/titleSelector/series.svg';
import supplementalSVG from '../../../assets/title-icons/titleSelector/supplemental.svg';
import seasonSVG from '../../../assets/title-icons/titleSelector/season.svg';
import InputError from '../../library/InputError';
import useHandleClickOutside from '../../../utils/hooks/useHandleClickOutside';

const SEARCH_FIELDS: string[] = ['licensor.label', 'titleSearchDisplayNameFull', 'releaseDate', 'workType.label'];

function TitleSearch({
	onSelect,
	selected,
	workType,
	setWorkType,
	workTypes,
	error,
	label,
	tooltip,
	refetchOnTabFocus = true,
	pollInterval = 60000,
	bottomActions,
	excludedUuids,
	showHiddenTitle,
	maxListHeight = 280,
	licensor,
}: TitleSearchProps) {
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearch = useDebounce(searchValue, 500);
	const [openModalMenu, setOpenModalMenu] = useState<boolean>(false);
	const theme = useTheme();

	const { getNameByUuid } = useWorkTypes();

	const refButtonTitleType = useRef(null);
	const modalMenuAnchorRef = useRef(null);
	const [isFloatingOpen, setIsFloatingOpen] = useState<boolean>(false);
	const { refs, floatingStyles } = useFloating({
		open: isFloatingOpen,
		onOpenChange: setIsFloatingOpen,
		middleware: [
			flip({
				padding: 8,
			}),
			offset(8),
			shift({ padding: 8 }),
			size({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.reference.width - 2}px`,
					});
				},
			}),
		],
		whileElementsMounted: autoUpdate,
	});

	useHandleClickOutside(refs.reference, () => {
		setIsFloatingOpen(false);
	});

	const filtering: FilterStep = useMemo(() => {
		const filterConditions: Array<Filter | FilterStep> = [];

		if (workType === 'all')
			filterConditions.push(CommonTitleFilters.searchByWorkTypes(workTypes.map((wt) => wt.value)));
		else if (workType) filterConditions.push(CommonTitleFilters.searchByWorkType(workType));

		if (licensor) filterConditions.push(CommonTitleFilters.searchByLicensorDisplayName(licensor));
		if (showHiddenTitle !== true) filterConditions.push(CommonTitleFilters.excludeHiddenTitles());
		if (excludedUuids?.length) filterConditions.push(CommonTitleFilters.excludeUuids(excludedUuids));

		return CommonTitleFilters.buildSimpleStep(filterConditions, 'AND');
	}, [workType, licensor, excludedUuids, showHiddenTitle, workTypes]);

	const { data, refetch, fetchMore, loading } = useSearchTitlesDropdownV2({
		filtering,
		pollInterval,
		perPage: PER_PAGE,
		sorting: SORTING,
		searchValue: debouncedSearch,
		searchFields: SEARCH_FIELDS,
	});

	const itemHeight = 66;
	const actualItemCount = data?.searchTitlesV2?.documents.length || 0;
	const actualHeight = Math.min(actualItemCount * itemHeight, maxListHeight);

	const refetchWithParams = async () => {
		await refetch({
			search: {
				filters: JSON.stringify(filtering),
				pagination: {
					page: 1,
					perPage: PER_PAGE,
				},
				orderBy: SORTING,
			},
		});
	};

	useOnTabFocus(async () => {
		if (refetchOnTabFocus) {
			await refetchWithParams();
		}
	});

	const onFocusTextInput = debounce(async () => {
		await refetchWithParams();
	}, 500);

	const handleLoadMore = () => {
		if (!loading && fetchMore) {
			return fetchMore({
				variables: {
					search: {
						filters: JSON.stringify(filtering),
						pagination: {
							page: (data?.searchTitlesV2?.page?.currentPage || 0) + 1,
							perPage: PER_PAGE,
						},
						orderBy: SORTING,
					},
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					return {
						...fetchMoreResult,
						searchTitlesV2: {
							...fetchMoreResult?.searchTitlesV2,
							documents: [
								...(prev?.searchTitlesV2?.documents || []),
								...(fetchMoreResult?.searchTitlesV2?.documents || []),
							],
						},
					};
				},
			});
		}

		return Promise.resolve();
	};

	// When a title is selected, the search value is filled with its name.
	// When the title is unselected (by selecting a licensor for instance), the search value is cleared.
	useEffect(() => {
		if (selected) {
			setSearchValue(
				selected?.titleSearchDisplayNameFull || selected?.titleDisplayUnlimited || selected?.titleSearchSortFull || '',
			);
		} else if (searchValue) setSearchValue('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	return (
		<Wrapper>
			{label && (
				<Label variant="buttonLargeMedium" color="text.secondary" error={error}>
					<LabelBlockWrapper>
						{label}
						{tooltip && (
							<Tooltip title={tooltip} placement="right">
								<TooltipIcon path={mdiInformation} size="16px" />
							</Tooltip>
						)}
					</LabelBlockWrapper>
				</Label>
			)}
			<ErrorInputWrapper>
				<SubWrapper
					onFocus={(event) => {
						if (
							refButtonTitleType.current === event.target ||
							((event.target as any)?.tagName === 'LI' && event.target?.getAttribute('data-dataType') === 'titleType')
						) {
							setIsFloatingOpen(false);
						} else {
							setIsFloatingOpen(true);
						}
					}}
					onBlur={() => {
						setIsFloatingOpen(false);
					}}
					error={!!error}
					active={openModalMenu || isFloatingOpen}
				>
					<InputWrapper ref={refs.setReference}>
						<div ref={modalMenuAnchorRef}>
							<StyledButton
								innerRef={refButtonTitleType}
								$error={!!error}
								$active={openModalMenu}
								startIcon={
									getIconByWorktype(getNameByUuid(workType)) ? (
										<img src={getIconByWorktype(getNameByUuid(workType))} alt="eidrIcon" />
									) : (
										<Icon style={{ color: 'rgba(0,0,0,0.5)' }} size="16px" path={mdiAppleKeyboardCommand} />
									)
								}
								endIcon={<Icon size="16px" path={mdiChevronDown} />}
								onClick={() => setOpenModalMenu(true)}
							>
								<StyledButtonTypography>{getNameByUuid(workType)}</StyledButtonTypography>
							</StyledButton>
						</div>
						<ModalMenu
							open={openModalMenu}
							disableOverlay
							anchorEl={modalMenuAnchorRef.current}
							onClose={() => setOpenModalMenu(false)}
							position="bottom"
							content={
								<MenuList backgroundColor={theme.palette.light.background}>
									{workTypes?.map((_workType) => (
										<MenuListItem
											data-dataType="titleType"
											key={_workType.value}
											height={32}
											onClick={() => {
												setWorkType(_workType.value);
												setIsFloatingOpen(false);
												setOpenModalMenu(false);
											}}
											icon={
												getIconByWorktype(_workType.label) ? (
													<img src={getIconByWorktype(_workType.label)} alt="eidrIcon" />
												) : (
													<div
														style={{
															position: 'relative',
															left: '2px',
															top: '3px',
														}}
													>
														<Icon style={{ color: 'rgba(0,0,0,0.5)' }} size="16px" path={mdiAppleKeyboardCommand} />
													</div>
												)
											}
											text={_workType.label}
										/>
									))}
								</MenuList>
							}
						/>
						<TextInput
							className="search-input"
							disabledMargin
							onFocus={onFocusTextInput}
							style={{
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
								minWidth: 400,
								backgroundColor: 'white',
								width: '100%',
								...(error
									? {
											borderBottomRightRadius: 0,
									  }
									: {}),
							}}
							height={40}
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
							startIcon={<Icon size="16px" path={mdiMagnify} />}
							placeholder="Search Titles"
						/>
					</InputWrapper>
					<Portal container={document.body}>
						<MenuWrapper
							ref={refs.setFloating}
							className="menu-wrapper"
							active={isFloatingOpen}
							width={refs.reference?.current?.getBoundingClientRect()?.width || 0}
							style={floatingStyles}
							maxHeight={maxListHeight}
						>
							<MenuList>
								{data?.searchTitlesV2.documents.length === 0 ? (
									<NoResultWrapper>
										<Typography variant="s2Regular" color="text.secondary">
											No results found
										</Typography>
									</NoResultWrapper>
								) : (
									<InfiniteLoader
										isItemLoaded={(index) => !!data?.searchTitlesV2.documents[index]}
										itemCount={data?.searchTitlesV2?.page.totalDocument || 0}
										loadMoreItems={loading ? () => {} : handleLoadMore}
									>
										{({ onItemsRendered, ref }) => (
											<List
												ref={ref}
												onItemsRendered={onItemsRendered}
												height={actualHeight}
												itemCount={actualItemCount}
												itemData={{
													options: data?.searchTitlesV2.documents || [],
													handleSelect: onSelect,
													setActive: setIsFloatingOpen,
												}}
												itemSize={66}
												width={refs.reference?.current?.getBoundingClientRect()?.width || 0}
											>
												{Row}
											</List>
										)}
									</InfiniteLoader>
								)}
								{bottomActions}
							</MenuList>
						</MenuWrapper>
					</Portal>
				</SubWrapper>
				{error ? <InputError message={error} /> : null}
			</ErrorInputWrapper>
		</Wrapper>
	);
}

export default TitleSearch;

interface TitleSearchProps {
	onSelect: (title: IndexedTitleV2 | undefined) => void;
	selected: IndexedTitleV2 | null;
	workTypes: ({ value: string; label: string } | { value: 'all'; label: 'All' })[];
	error?: string;
	label?: string;
	tooltip?: string;
	workType: string;
	setWorkType: (workType: string) => void;
	refetchOnTabFocus?: boolean;
	pollInterval?: number;
	bottomActions?: React.ReactNode;
	excludedUuids?: string[];
	showHiddenTitle?: boolean;
	maxListHeight?: number;
	licensor?: string;
}

const PER_PAGE = 20;
const SORTING = [
	{
		field: 'titleSearchSortFull',
		direction: 'ASC',
	},
];

function getIconByWorktype(workType: string | undefined) {
	if (workType === 'Episode') return episodeSVG;
	if (workType === 'Movie') return movieSVG;
	if (workType === 'Series') return seriesSVG;
	if (workType === 'Season') return seasonSVG;
	if (workType === 'Compilation') return compilationSVG;
	if (workType === 'Supplemental') return supplementalSVG;

	return undefined;
}
