import IndexedAvailableWrittenLanguagesJson from '../../../src/assets/json-administration-profiles/availableLanguageWritten.map.value.json';
import AvailableWrittenLanguagesJson from '../../../src/assets/json-administration-profiles/availableLanguageWritten.json';

export type WrittenLanguage = Language;

type Language = {
	uuid: string;
	label: string;
	name: string;
};

export type LanguageDropdownOption = {
	value: string;
	label: string;
};

export function getWrittenLanguage(uuid: string): WrittenLanguage {
	const language = IndexedAvailableWrittenLanguagesJson[uuid as keyof typeof IndexedAvailableWrittenLanguagesJson];
	if (!language) throw new Error(`Language ${uuid} not found`);
	return {
		uuid: language.value,
		label: language.label,
		name: language.name,
	};
}

export function getWrittenLanguages(): WrittenLanguage[] {
	return AvailableWrittenLanguagesJson;
}

export function getWrittenLanguagesOptions(): LanguageDropdownOption[] {
	const languages = getWrittenLanguages();
	return languages.map((language) => ({
		value: language.uuid,
		label: language.label,
	}));
}
