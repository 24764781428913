import React from 'react';
import styled, { css } from 'styled-components';

// TYPES
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import {
	AspectRatioField,
	CountriesOfOriginField,
	EditClassesField,
	EditUseField,
	FrameRateField,
	MadeForRegionField,
	ProductionCountriesField,
} from '@warehouse/title/feature-title-overview';
import { TitleOverviewByTypeProps } from './type';
import { TitleOverviewLeftWrapperBase, Data, NonCenteredData } from './style';

// HOOKS
import useMemoJsonPath from '../../../../views/title/hooks/useMemoJsonPath';
import useTiniestSummaryTitleData from '../../../../views/title/hooks/useTiniestSummaryTitleData';
import useTitleAutoSave from '../../../../views/title/hooks/useTitleAutoSave/useTitleAutoSave';

// UTILS
import { fromBasic } from '../../../../utils/titleGetProperty';

// LIBRARY
import TiniestSummary from '../fields/TiniestSummary';
import { VersionLanguageField, AlidField, PlatformSpecificationField } from '../fields';
import ReleaseYearField from '../fields/ReleaseYearField';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar topbar topbar'
			'synopsis genres aspectRatio alid'
			'synopsis releaseYear frameRate platformSpec'
			'synopsis editUse versionLanguage countriesOfOrigin'
			'synopsis editClass madeForRegion productionCountries';
		grid-template-columns: 25% 25% 25% 25%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

function Manifestation({ editMode, topBar, displayVideoPlayer, title }: TitleOverviewByTypeProps) {
	const tiniestSummary = useTiniestSummaryTitleData({
		title,
	});

	const releaseYear = useTitleAutoSave<Inherited<string>>({
		title,
		path: useMemoJsonPath(fromBasic(['releaseYear'])),
		label: 'Release Year',
	});

	return (
		<TitleOverviewLeftWrapper displayVideoPlayer={displayVideoPlayer}>
			{topBar}
			<NonCenteredData enableRightBorder area="synopsis">
				<TiniestSummary
					tiniestSummary={tiniestSummary?.data?.displayValue || ''}
					origin=""
					isInherited={tiniestSummary.isInherited}
				/>
			</NonCenteredData>
			<Data area="genres" />
			<Data area="aspectRatio">
				<AspectRatioField />
			</Data>
			<Data area="alid">
				<AlidField alid="" setAlid={() => {}} editMode={editMode} origin="" isInherited={false} />
			</Data>
			<Data area="releaseYear">
				<ReleaseYearField
					editMode={editMode}
					releaseYear={editMode ? releaseYear?.readOnlyValue?.displayValue : releaseYear?.readOnlyValue?.displayValue}
					setReleaseYear={(v) =>
						releaseYear?.setValue((prev) => ({
							...prev,
							displayValue: v!,
						}))
					}
					origin=""
					isInherited={!!releaseYear?.value?.inherited?.value}
				/>
			</Data>
			<Data area="frameRate">
				<FrameRateField />
			</Data>
			<Data area="platformSpec">
				<PlatformSpecificationField
					editMode={editMode}
					platformSpecification=""
					setPlatformSpecification={() => {}}
					origin=""
					isInherited={false}
				/>
			</Data>
			<Data area="editUse">
				<EditUseField />
			</Data>
			<Data area="versionLanguage">
				<VersionLanguageField />
			</Data>
			<Data area="countriesOfOrigin">
				<CountriesOfOriginField />
			</Data>
			<Data area="editClass">
				<EditClassesField />
			</Data>
			<Data area="madeForRegion">
				<MadeForRegionField />
			</Data>
			<Data area="productionCountries">
				<ProductionCountriesField />
			</Data>
		</TitleOverviewLeftWrapper>
	);
}

export default Manifestation;
