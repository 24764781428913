import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import DisplayTitleField from '../fields/DisplayTitle';

interface SeriesProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
}

function Series({ control, errors, setValue, trigger }: SeriesProps) {
	return (
		<div>
			<DisplayTitleField control={control} setValue={setValue} errors={errors} trigger={trigger} isRequired />
		</div>
	);
}

export default Series;
