import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import styled, { css } from 'styled-components';
import { alpha, lighten } from '@mui/material';

interface IButtonProps extends ButtonProps {
	nxstyle?: 'success' | 'warning' | 'error' | 'info' | 'default' | 'primary';
	mode?: 'dark' | 'light';
	height?: number;
	$uppercase?: boolean;
	badge?: string;
}

const StyledTag = styled(Button)<IButtonProps>`
	${({ nxstyle, theme, mode }) => {
		switch (mode) {
			case 'dark':
				return css`
					background-color: ${!nxstyle || nxstyle === 'default'
						? theme.palette.dark.background
						: theme.palette[nxstyle].text};
					border: 1px solid transparent;
					color: ${!nxstyle || nxstyle === 'default'
						? theme.palette.text.reverseMain
						: lighten(theme.palette[nxstyle].main, 0.8)};
					// TODO update hover style
					&:hover {
						background-color: ${!nxstyle || nxstyle === 'default'
							? theme.palette.dark.background
							: theme.palette[nxstyle].text};
						border: 1px solid transparent;
						color: ${!nxstyle || nxstyle === 'default'
							? theme.palette.text.reverseMain
							: lighten(theme.palette[nxstyle].main, 0.8)};
					}
				`;
			default:
				if (!nxstyle) {
					return css`
						background-color: ${alpha(theme.palette.dark.main, 0.05)};
						border: 1px solid transparent;
						color: ${alpha(theme.palette.dark.main, 0.8)};
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.dark.main, 0.8)};
							}
						}
						// TODO update hover style
						&:hover {
							background-color: ${alpha(theme.palette.dark.main, 0.05)};
							border: 1px solid transparent;
							color: ${alpha(theme.palette.dark.main, 0.8)};
						}
					`;
				}
				if (nxstyle === 'default') {
					return css`
						background-color: ${alpha(theme.palette.dark.main, 0.05)};
						border: 1px solid transparent;
						color: ${alpha(theme.palette.dark.main, 0.8)};
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.dark.main, 0.8)};
							}
						}
						// TODO update hover style
						&:hover {
							background-color: ${alpha(theme.palette.dark.main, 0.05)};
							border: 1px solid transparent;
							color: ${alpha(theme.palette.dark.main, 0.8)};
						}
					`;
				}
				if (nxstyle === 'primary') {
					return css`
						background-color: ${alpha(theme.palette.primary.main, 0.2)};
						border: 1px solid transparent;
						color: ${theme.palette.secondary.main};
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.secondary.main};
							}
						}
						// TODO update hover style
						&:hover {
							background-color: ${alpha(theme.palette.primary.main, 0.2)};
							border: 1px solid transparent;
							color: ${theme.palette.secondary.main};
						}
					`;
				}
				return css`
					background-color: ${theme.palette[nxstyle!].background};
					border: 1px solid transparent;
					color: ${theme.palette[nxstyle!].text};
					& .MuiButton-startIcon,
					.MuiButton-endIcon {
						svg {
							color: ${theme.palette[nxstyle!].text};
						}
					}
					// TODO update hover style
					&:hover {
						background-color: ${theme.palette[nxstyle!].background};
						border: 1px solid transparent;
						color: ${theme.palette[nxstyle!].text};
					}
				`;
		}
	}}

	${({ height }) => css`
		height: ${height ? `${height}px` : '32px'};
	`}

	cursor: inherit;
	font-size: 12px;
	min-width: auto;
	${({ $uppercase = true }) =>
		$uppercase
			? css`
					text-transform: uppercase;
			  `
			: css`
					text-transform: none;
			  `};

	& .MuiButton-startIcon,
	.MuiButton-endIcon {
		svg {
			font-size: 14px;
		}
	}
`;

export const StyledBadge = styled(Badge)(
	({ theme }) => css`
		& .MuiBadge-badge {
			background-color: ${theme.palette.info.main};
			color: ${theme.palette.light.main};
			padding: 1px 5px;
			z-index: 0;
		}
	`,
);

export function Tag(props: IButtonProps) {
	const { badge } = props;

	return (
		<div>
			{badge ? (
				<StyledBadge badgeContent={badge}>
					<StyledTag {...props} disableFocusRipple disableTouchRipple />
				</StyledBadge>
			) : (
				<StyledTag {...props} disableFocusRipple disableTouchRipple />
			)}
		</div>
	);
}

export default Tag;
