import React, { useContext, useState } from 'react';
import { ButtonGroup, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiCog, mdiEye, mdiPencil } from '@mdi/js';

// GRAPHQL
import { TitleCommon, TitleFull as Title } from '@warehouse/title/core';
import { GoToParentButton, shouldDisplayGoToParentButton } from '@warehouse/title/go-to-parent-feature';
import { getBackgroundColor, getWorkTypeObject } from '@warehouse/title/infra';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import availableWorkTypeDetailsByWorkType from '../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import usePoster from '../../../utils/hooks/poster/usePoster';

// COMPONENTS
import Button from '../../library/Button';

// HOOKS
import Poster from './Poster';
import {
	ActionsWrapper,
	BackgroundColorOpacity,
	CollapseAction,
	CollapsedWrapper,
	PosterWrapper,
	RightWrapper,
	TopBarWrapper,
	TopWrapper,
	Wrapper,
} from './style';
import { Compilation, Episode, Manifestation, Movie, Performance, Season, Series, Supplemental } from './layouts';
import getDeepProperty from '../../../utils/getDeepProperty';
import { fromBasic } from '../../../utils/titleGetProperty';
import { TitleOverviewByTypeProps } from './layouts/type';
import { UnsavedChangesContext } from '../../../views/title/contexts/UnsavedChangesContext';
import { TitleOverviewTopBar } from '../../../../libs/title/ui/TitleOverviewTopBar';

type TitleOverviewProps = {
	titleFull: Title;
} & CommonTitleOverviewProps;

type TitleOverviewLoaderProps = {
	titleFull?: Title;
	titleSearchDataIndexed?: TitleCommon;
} & CommonTitleOverviewProps;

type CommonTitleOverviewProps = {
	posterQuery?: ReturnType<typeof usePoster>;
	editMode: boolean;
	setEditMode: (v: boolean) => void;
	onClickSettings: () => void;
};

type KeyofJson = keyof typeof availableWorkTypeDetailsByWorkType;

function getWorkTypeNameByUuid(workTypeUuid: string) {
	const keys = Object.keys(availableWorkTypeDetailsByWorkType || {});

	let found;

	keys.forEach((key) => {
		if (availableWorkTypeDetailsByWorkType[key as KeyofJson].uuid === workTypeUuid) found = key;
	});

	return found;
}

function getWorkTypeByName(name: KeyofJson) {
	return availableWorkTypeDetailsByWorkType[name];
}

function TitleOverview({
	titleFull,
	posterQuery,
	editMode,
	setEditMode: legacySetEditMode,
	onClickSettings,
}: TitleOverviewProps) {
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	// state
	const [collapsed, setCollapsed] = useState<boolean>(false);

	// variables
	const workType = getDeepProperty<string | undefined>(titleFull, fromBasic(['workType']));
	const workTypeName = getWorkTypeNameByUuid(workType || '');
	const backgroundColor = getBackgroundColor(workTypeName);
	const parentAbstractionWorkTypeName = titleFull.readonly.parentAbstractionWorkType
		? getWorkTypeObject(titleFull.readonly.parentAbstractionWorkType).label
		: undefined;

	const { setEditMode } = useTitleEditorStore(titleEditorStoreSelector.actions);

	const topBar = (
		<TopBarWrapper>
			<TitleOverviewTopBar
				titleCommon={titleFull}
				actions={
					<ActionsWrapper>
						{titleFull && shouldDisplayGoToParentButton(titleFull) && (
							<GoToParentButton style={{ marginRight: 16 }} title={titleFull} />
						)}
						<Typography variant="buttonMediumMedium">Mode</Typography>
						<ButtonGroup>
							<Button
								nxstyle="secondary-black"
								className="active"
								$disablePadding
								$forceActive={!editMode}
								onClick={() =>
									promptUserIfUnsavedChanges(() => {
										setEditMode(false);
										legacySetEditMode(false);
									})
								}
								aria-label="View Mode"
							>
								<Icon path={mdiEye} size="16px" />
							</Button>
							<Button
								nxstyle="secondary-black"
								$disablePadding
								$forceActive={editMode}
								onClick={() =>
									promptUserIfUnsavedChanges(() => {
										setEditMode(true);
										legacySetEditMode(true);
									})
								}
								aria-label="Edit Mode"
							>
								<Icon path={mdiPencil} size="16px" />
							</Button>
						</ButtonGroup>
						<Button onClick={onClickSettings} nxstyle="secondary-black">
							<Icon path={mdiCog} size="16px" />
						</Button>
					</ActionsWrapper>
				}
			/>
		</TopBarWrapper>
	);

	if (collapsed) {
		return (
			<>
				<BackgroundColorOpacity color={backgroundColor} />
				<CollapsedWrapper>
					{topBar}
					<CollapseAction onClick={() => setCollapsed(false)}>
						<Icon path={mdiChevronDown} size="16px" />
					</CollapseAction>
				</CollapsedWrapper>
			</>
		);
	}

	const titleOverviewByTitleTypeProps: TitleOverviewByTypeProps = {
		displayVideoPlayer: false, // Should be base on titleIsPerformance
		topBar,
		editMode,
		title: titleFull,
		parentAbstractionWorkType:
			workType === getWorkTypeByName('Edit')?.uuid &&
			parentAbstractionWorkTypeName &&
			['Movie', 'Episode', 'Supplemental'].includes(parentAbstractionWorkTypeName)
				? (parentAbstractionWorkTypeName as 'Movie' | 'Episode' | 'Supplemental')
				: undefined,
	};

	const titleOverviewByTitleType = {
		[getWorkTypeByName('Compilation')?.uuid || 'Compilation']: <Compilation {...titleOverviewByTitleTypeProps} />,
		[getWorkTypeByName('Episode')?.uuid || 'Episode']: <Episode {...titleOverviewByTitleTypeProps} />,
		[getWorkTypeByName('Movie')?.uuid || 'Movie']: <Movie {...titleOverviewByTitleTypeProps} />,
		[getWorkTypeByName('Edit')?.uuid || 'Edit']: <Performance {...titleOverviewByTitleTypeProps} />,
		[getWorkTypeByName('Series')?.uuid || 'Series']: <Series {...titleOverviewByTitleTypeProps} />,
		[getWorkTypeByName('Season')?.uuid || 'Season']: <Season {...titleOverviewByTitleTypeProps} />,
		[getWorkTypeByName('Supplemental')?.uuid || 'Supplemental']: <Supplemental {...titleOverviewByTitleTypeProps} />,
		[getWorkTypeByName('Manifestation')?.uuid || 'Manifestation']: <Manifestation {...titleOverviewByTitleTypeProps} />,
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const titleOverview = titleOverviewByTitleType[workType || 'Movie'];

	return (
		<TopWrapper>
			<BackgroundColorOpacity color={backgroundColor} />
			<Wrapper>
				{titleOverview}
				<RightWrapper displayVideoPlayer={false}>
					<PosterWrapper displayVideoPlayer={false}>
						{' '}
						{/* Should be base on titleIsPerformance */}
						<Poster posterQuery={posterQuery} />
					</PosterWrapper>
				</RightWrapper>
			</Wrapper>
			<CollapseAction onClick={() => setCollapsed(true)}>
				<Icon path={mdiChevronUp} size="16px" />
			</CollapseAction>
		</TopWrapper>
	);
}

function TitleOverviewLoader(props: TitleOverviewLoaderProps) {
	const { titleFull } = props;
	if (!titleFull) return null;

	return <TitleOverview {...props} titleFull={titleFull} />;
}

export default TitleOverviewLoader;
