import React, { useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';

interface SanitizedHTMLProps {
	htmlContent: string;
}

function SanitizedHTML({ htmlContent }: SanitizedHTMLProps) {
	const sanatizedHtml = useMemo(() => sanitizeHtml(htmlContent), [htmlContent]);

	// eslint-disable-next-line react/no-danger
	return <div dangerouslySetInnerHTML={{ __html: sanatizedHtml }} />;
}

export default SanitizedHTML;
