import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import React from 'react';
import styled, { css } from 'styled-components';

const SubMessageWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		bottom: 0;
		display: flex;
		flex-direction: row-reverse;
		min-height: 38px;
		position: absolute;
		right: 0;
		transform: translateY(100%);

		z-index: 10;

		.MuiSvgIcon-root {
			font-size: 18px;
		}

		.MuiTypography-root {
			font-size: 14px;
			font-weight: bold;
			margin-left: ${theme.spacing(1)};
		}
	`,
);

const SubMessage = styled.div<{ color: 'info' | 'error' }>(
	({ theme, color }) => css`
		align-items: center;
		background-color: ${theme.palette[color].main};
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		display: flex;
		.MuiTypography-root,
		svg {
			color: ${theme.palette.text.reverseMain} !important;
		}
		min-height: 38px;
		padding: ${theme.spacing(0, 1)};
	`,
);

export const WrapperInputError = styled.div(
	() => css`
		position: relative;
	`,
);

interface InputErrorProps {
	style?: React.CSSProperties;
	message: string;
}

export default function InputError({ style, message }: InputErrorProps) {
	return (
		<SubMessageWrapper style={style}>
			<SubMessage color="error">
				<WarningIcon />
				<Typography>{message}</Typography>
			</SubMessage>
		</SubMessageWrapper>
	);
}
