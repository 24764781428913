import React from 'react';
import { Button } from '@warehouse/shared/legacy-ui';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import styled, { css } from 'styled-components';
import { titleSelectorSelector, useTitleSelectorStore } from '@warehouse/title/title-selector-feature';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { TitleCard } from '@warehouse/title/ui';
import { indexedTitleToCommonReadonly } from '@warehouse/title/infra';

export function SelectedTitleListItem({ title }: SelectedTitleListItemProps) {
	const actions = useTitleSelectorStore(titleSelectorSelector.actions);

	return (
		<Item tabIndex={0} key={title.uuid}>
			<TitleCardWrapper>
				{/* TODO: This type adaptation should be done in the infra. */}
				<TitleCard isLink={false} hideReleaseYear variant="colored" titleLight={indexedTitleToCommonReadonly(title)} />
			</TitleCardWrapper>
			<Button nxstyle="tertiary-light" onClick={() => actions.unselectTitle(title)}>
				<Icon path={mdiClose} size="16px" />
			</Button>
		</Item>
	);
}

interface SelectedTitleListItemProps {
	title: IndexedTitleV2;
}

const TitleCardWrapper = styled.div`
	box-sizing: border-box;
	flex-grow: 1;
	overflow: hidden;
	padding-right: 8px;
`;

const Item = styled.li(
	({ theme }) => css`
		align-items: center;
		box-sizing: border-box;
		display: flex;
		list-style: none;
		padding: ${theme.spacing(1)};
		pointer-events: auto !important;
		width: 100%;
		z-index: 10 !important;

		&:hover {
			background-color: ${theme.palette.blue.background};
		}
	`,
);
