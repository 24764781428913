import { useCallback, useEffect, useState } from 'react';

export type UseReorderingProps<T> = {
	value: T[];
	sortingFn(a: T, b: T): number;
};

export type UseReorderingReturn<T> = {
	getBeIndexByFeIndex(feIndex: number): number;
	toFe(beArray: T[]): T[];
	updateFeToBeIndexMap(beArray: T[]): void;
	indexMap: Map<number, number>;
};

export function useReordering<T>({ value, sortingFn }: UseReorderingProps<T>): UseReorderingReturn<T> {
	const [feToBeIndexMap, setFeToBeIndexMap] = useState<Map<number, number>>(new Map());

	const updateFeToBeIndexMap = (beArray: T[]) => {
		const indexedArray = beArray.map((item, index) => ({ item, index }));
		indexedArray.sort((a, b) => sortingFn(a.item, b.item));
		const newMap = new Map<number, number>();
		indexedArray.forEach((element, newIndex) => {
			newMap.set(newIndex, element.index);
		});
		setFeToBeIndexMap(newMap);
	};

	useEffect(() => {
		updateFeToBeIndexMap(value);
	}, []);

	const getBeIndexByFeIndex = useCallback(
		(feIndex: number): number => {
			if (feToBeIndexMap.has(feIndex)) {
				return feToBeIndexMap.get(feIndex)!;
			}
			return -1;
		},
		[feToBeIndexMap],
	);

	const toFe = (beArray: T[]): T[] => [...beArray].sort(sortingFn);

	return {
		getBeIndexByFeIndex,
		toFe,
		updateFeToBeIndexMap,
		indexMap: feToBeIndexMap,
	};
}
