import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { TitleFull as Title } from '@warehouse/title/core';
import { DropdownV2 } from '@warehouse/shared/ui';
import useWorkTypes, { filterOutManifestation } from '../../../utils/hooks/titles/useWorkTypes';
import { ChildrenTabNameOutput } from '../../title/hooks/useChildrenTabName';
import { TitleInfoWrapper } from './styles';

interface WorkTypeSelectorProps {
	selectedParent?: Title | undefined;
	loadingParent?: boolean;
	workType: string | undefined;
	setWorkType: (titleType: string) => void;
	setParentType: (parentType: string) => void;
	childrenProperties?: ChildrenTabNameOutput;
}

function WorkTypeSelector({
	selectedParent,
	loadingParent = false,
	workType,
	setWorkType,
	setParentType,
	childrenProperties,
}: WorkTypeSelectorProps) {
	const { options, getWorkTypesByRelationshipSource } = useWorkTypes();

	const computedOptions = useMemo(() => {
		if (selectedParent) {
			if (!childrenProperties?.relationshipType) return [];
			const workTypeOptions = getWorkTypesByRelationshipSource(childrenProperties.relationshipType);

			// this condition is hardcoded and remove the manifestation when tabLabel is "Edit"
			if (childrenProperties.singularLabel === 'Edit') {
				return filterOutManifestation(workTypeOptions);
			}
			return workTypeOptions;
		}
		return filterOutManifestation(options);
	}, [childrenProperties, options, getWorkTypesByRelationshipSource, selectedParent]);

	return (
		<TitleInfoWrapper>
			<Typography variant="s2Medium" color="text.primary">
				Title Type* :
			</Typography>
			<div style={{ width: '150px' }}>
				<DropdownV2
					ariaLabel="Select Work Type"
					withSearch
					loading={loadingParent}
					height={40}
					listItemHeight={40}
					readOnly={!loadingParent && computedOptions.length <= 1}
					options={computedOptions}
					value={workType || null}
					onChange={(_workType) => {
						setWorkType(_workType as string);
						setParentType('all');
					}}
				/>
			</div>
		</TitleInfoWrapper>
	);
}

export default WorkTypeSelector;
