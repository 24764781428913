import styled, { css } from 'styled-components';
import { alpha, Typography } from '@mui/material';

export const ReadOnlyField = styled(Typography)<{ $height?: number; $error?: boolean }>(
	({ theme, $height = 38, $error = false }) => css`
		align-items: center;
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: ${theme.spacing(1)};
		box-sizing: border-box;
		color: ${alpha(theme.palette.text.secondary, 0.5)} !important;
		cursor: default;
		display: flex;
		font-size: 14px;
		height: ${$height}px;
		margin-top: 2px;
		padding: ${theme.spacing(1, 2)};

		${$error &&
		css`
			background-color: ${alpha(theme.palette.error.main, 0.05)};
			border: solid 1px ${theme.palette.error.main};
			border-radius: ${theme.spacing(1, 1, 0, 1)};
			color: ${theme.palette.error.text};
		`}
	`,
);
