import React, { FC, FocusEventHandler, KeyboardEventHandler, ReactNode, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import AutoComplete from '@mui/material/Autocomplete';
import styled, { css } from 'styled-components';
import { mdiInformation } from '@mdi/js';
import TextInput from './TextInput';
import Chip from './Chip';
import { Label, LabelBlockWrapper, TooltipIcon } from './InputUtils';
import Tooltip from './Tooltip';

interface StyledAutoCompleteProps {
	$hasTag: boolean;
	$height: number;
	placeholder: string | undefined; // TODO: Find out why it is not inferred from AutoComplete
}

const StyledAutoComplete = styled(AutoComplete)<StyledAutoCompleteProps>(
	({ theme, $hasTag, $height }) => css`
		.input-wrapper {
			flex-wrap: wrap;
			gap: ${theme.spacing(1)};
			justify-content: flex-start;
			min-height: ${$height}px;
			padding: ${theme.spacing(1)};

			.MuiChip-root {
				height: ${Math.max(
					Math.min($height - 2 * 8 - 2, 28),
					18,
				)}px; // height - (2 * padding) - (2 * border), range [18, 28]

				svg {
					height: ${Math.max(
						Math.min($height - 2 * 8 - 2 - 6, 22),
						14,
					)}px; // height - (2 * padding) - (2 * border) - 6, range [14, 22]
				}
			}

			input {
				border-radius: 0;
				padding: 0 ${theme.spacing($hasTag ? 0 : 1)};
				width: 100px;
			}
		}
	`,
);

const StyledChip = styled(Chip)`
	align-self: center;
`;

export type TagInputVariant = 'default' | 'prefilled';

export interface TagInputProps {
	values?: string[];
	onChange?: (values: string[]) => void;
	onTagDelete?: (value: string, nextValues: string[]) => void;
	onBlur?: FocusEventHandler<HTMLDivElement>;
	label?: string;
	labelIcon?: ReactNode;
	placeholder?: string;
	tooltip?: string;
	error?: string;
	height?: number;
	disabledMargin?: boolean;
	variant?: TagInputVariant;
	ariaLabel?: string;
}

function TagInput({
	values: externalValues,
	onChange,
	onTagDelete,
	label,
	labelIcon,
	placeholder,
	tooltip,
	error,
	height = 48,
	disabledMargin,
	onBlur,
	ariaLabel,
	variant = 'default',
}: TagInputProps) {
	const [values, setValues] = useState<string[]>(externalValues ?? []);
	const [inputValue, setInputValue] = useState<string>('');

	const tags = useMemo(
		() =>
			(externalValues ?? values).map((tagValue) => (
				<StyledChip
					key={tagValue}
					label={tagValue}
					error={error ? error.length > 0 : false}
					onDelete={() => {
						const nextValue = values.filter((v) => v !== tagValue);
						setValues(nextValue);
						if (onChange) onChange(nextValue);
						if (onTagDelete) onTagDelete(tagValue, nextValue);
					}}
				/>
			)),
		[externalValues, values, error, setValues, onChange, onTagDelete],
	);

	const handleChange = useCallback(
		(_: unknown, nextValue: string[]) => {
			const trimmedNextValue = nextValue.reduce<string[]>((acc, v) => {
				const vTrimmed = v.trim();
				if (vTrimmed !== '') acc.push(vTrimmed);
				return acc;
			}, []);
			setValues(trimmedNextValue);
			if (onChange) onChange(trimmedNextValue);
		},
		[setValues, onChange],
	);

	const handleInputChange = useCallback(
		(nextInputValue: string, forcePush: boolean = false) => {
			const options = nextInputValue.split(',');

			if (options.length > 1 || forcePush) {
				const nextValue = options.reduce<string[]>(
					(acc, v) => {
						const vTrimmed = v.trim();
						if (vTrimmed !== '' && !acc.includes(vTrimmed)) acc.push(vTrimmed);
						return acc;
					},
					[...values],
				);

				setValues(nextValue);
				if (onChange) onChange(nextValue);
				setInputValue('');
			} else {
				setInputValue(nextInputValue);
			}
		},
		[values, setValues, onChange, setInputValue],
	);

	const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
		(evt) => {
			if (evt.key === 'Tab') {
				handleInputChange((evt.target as HTMLInputElement).value, true);
			}
		},
		[handleInputChange],
	);

	return (
		<StyledAutoComplete<FC<Parameters<typeof AutoComplete<string, true, true, true>>[0] & StyledAutoCompleteProps>>
			multiple
			freeSolo
			$hasTag={values.length > 0}
			$height={height}
			value={externalValues ?? values}
			inputValue={inputValue}
			placeholder={placeholder}
			onChange={handleChange}
			onInputChange={(_, next) => handleInputChange(next)}
			renderInput={(params) => (
				<>
					{label && (
						<Label variant="buttonLargeMedium" error={error} className="input-label">
							<>
								<LabelBlockWrapper>
									{label}
									{tooltip && (
										<Tooltip title={tooltip} placement="right">
											<TooltipIcon path={mdiInformation} size="16px" />
										</Tooltip>
									)}
								</LabelBlockWrapper>
								{labelIcon}
							</>
						</Label>
					)}
					<TextInput
						ariaLabel={ariaLabel ? `${ariaLabel} Input` : undefined}
						startAdornment={params.InputProps.startAdornment}
						{...(params.inputProps as any)}
						placeholder={placeholder}
						error={error}
						disabledMargin={disabledMargin}
						onKeyDown={handleKeyDown}
						className={classNames({ inherited: variant === 'prefilled' }, params.inputProps.className)}
					/>
				</>
			)}
			renderTags={() => tags}
			options={[]}
			onBlur={onBlur}
		/>
	);
}

export default TagInput;
