import React from 'react';

// LIBRARY
import { FullWidthHeightWrapper } from '../../../../../components/common';
import Column from '../common/Column';

function ActivityTab() {
	return (
		<FullWidthHeightWrapper>
			<Column />
		</FullWidthHeightWrapper>
	);
}

export default ActivityTab;
