import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const TitleInfoWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(2)};
		padding: ${theme.spacing(0, 2)};
	`,
);
