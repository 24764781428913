import React, { createContext, useMemo, useCallback, useEffect, useState } from 'react';
import { TitleFull, isInherited, resetInheritedArrayValues } from '@warehouse/title/core';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { People } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/People';
import useMemoJsonPath from '../../../../hooks/useMemoJsonPath';
import { fromBasic } from '../../../../../../utils/titleGetProperty';
import editDeepProperty from '../../../../../../utils/editDeepProperty';
import getDeepProperty from '../../../../../../utils/getDeepProperty';
import useTitleAutoSave from '../../../../hooks/useTitleAutoSave/useTitleAutoSave';
import getDefaultLocale from '../../../../utils/getDefaultLocale';
import RoleJson from '../../../../../../assets/json-administration-profiles/availableRoleType.json';
import { DropdownOption } from '../../../../../../components/library/Dropdown/types';

export type TranslatedString = { [locale: string]: string };

export type TranslatedStringArray = {
	[locale: string]: (string | undefined)[];
};

export interface CastAndCrewMember {
	peopleIndex: number;
	name: TranslatedString;
	role: string;
	characters?: TranslatedStringArray;
	billingBlockOrder?: number;
}

export interface CastAndCrewContextInterface {
	data: OptionalInherited<People[]> | undefined;
	defaultLocale: string | undefined;
	currentLocale: string | undefined;
	isInherited: boolean;
	castData: CastAndCrewMember[] | undefined;
	crewData: CastAndCrewMember[] | undefined;
	editData: (path: string, value: any, isBlur?: boolean) => void;
	addToArray: (path: string, value: any, isBlur?: boolean) => void;
	removeFromArray: (path: string, selectedIndexes: string[]) => void;
	rolesOptions: DropdownOption[];
	addNewRoleOption: (value: string) => void;
	setCurrentLocale: (locale: string | undefined) => void;
}

export const CastAndCrewContext = createContext<CastAndCrewContextInterface>({
	data: undefined,
	castData: undefined,
	crewData: undefined,
	defaultLocale: undefined,
	currentLocale: undefined,
	isInherited: false,
	rolesOptions: [],
	editData: () => {},
	addToArray: () => {},
	removeFromArray: () => {},
	addNewRoleOption: () => {},
	setCurrentLocale: () => {},
});

export const castRoles = ['actor', 'guest', 'self'];

function peopleNameToTranslatedString(people: People): TranslatedString {
	return people.name.displayNames.reduce<TranslatedString>(
		(acc, name) => ({
			...acc,
			[name.language!]: name.displayName,
		}),
		{},
	);
}

function getAllLanguagesFromCharacter(charactersInfo?: NonNullable<People['jobs'][number]['characterInfos']>) {
	const languages: Set<string | undefined> = new Set();
	charactersInfo?.forEach((character) => {
		character.characterNames.forEach((name) => {
			languages.add(name.language);
		});
	});
	return Array.from(languages);
}

function characterInfoToTranslatedStringArray(
	charactersInfo?: NonNullable<People['jobs'][number]['characterInfos']>,
): TranslatedStringArray | undefined {
	if (!charactersInfo) return undefined;
	const languages = getAllLanguagesFromCharacter(charactersInfo);
	const languageMap = new Map<string | undefined, (string | undefined)[]>();
	languages.forEach((lang) => languageMap.set(lang, []));
	charactersInfo.forEach((character) => {
		languages.forEach((lang) => {
			const name = character.characterNames.find((char) => char.language === lang);
			languageMap.set(lang, [...languageMap.get(lang)!, name?.characterName]);
		});
	});
	return Object.fromEntries(languageMap.entries());
}

function sortCastAndCrewMembersCompareFn(a: CastAndCrewMember, b: CastAndCrewMember): number {
	if (a.billingBlockOrder === undefined && b.billingBlockOrder === undefined) return 0;
	if (a.billingBlockOrder === undefined) return 1;
	if (b.billingBlockOrder === undefined) return -1;
	return a.billingBlockOrder - b.billingBlockOrder;
}

export function getMaxBillingBlockOrder(data: CastAndCrewMember[] | undefined) {
	if (!data) return 0;
	const billingBlockOrders = data
		.filter((item) => item.billingBlockOrder?.toString() !== '' && item.billingBlockOrder != null)
		.map((item) => item.billingBlockOrder)
		.filter((order): order is number => order !== undefined);
	return billingBlockOrders.length > 0 ? Math.max(...billingBlockOrders) : 0;
}

export function CastAndCrewContextProvider({
	title,
	children,
}: {
	title: TitleFull | undefined;
	children: React.ReactNode;
}) {
	const [rolesOptions, setRolesOptions] = useState<DropdownOption[]>(
		RoleJson.map((role) => ({
			label: role.label,
			value: role.value,
		})),
	);
	const [currentLocale, setCurrentLocale] = useState<string | undefined>(undefined);
	const defaultLocale = useMemo(() => getDefaultLocale(title), [title]);

	const { value, commit, setValue } = useTitleAutoSave<OptionalInherited<People[]>, People>({
		label: 'Cast & Crew',
		path: useMemoJsonPath(fromBasic(['people'])),
		isRowValid: useCallback(
			(row: People) =>
				row.name.displayNames.length > 0 &&
				row.name.displayNames.every((name) => !!name.displayName.trim() && !!name.language?.trim()) &&
				row.jobs.length > 0 &&
				row.jobs.every((job) => {
					if (!job.jobFunction) return false;
					if (!castRoles.includes(job.jobFunction.toLowerCase())) return true;
					return (
						(job.characterInfos?.length ?? 0) > 0 &&
						job.characterInfos?.every((character) =>
							character.characterNames.every((name) => !!name.characterName.trim() && !!name.language?.trim()),
						)
					);
				}),
			[],
		),
	});

	const castAndCrewData = useMemo(() => {
		if (!value || !value.displayValue) return undefined;
		return value.displayValue.reduce<CastAndCrewMember[]>(
			(acc, people, peopleIndex) => [
				...acc,
				...people.jobs.map(
					(job): CastAndCrewMember => ({
						peopleIndex,
						name: peopleNameToTranslatedString(people),
						role: job.jobFunction,
						characters: characterInfoToTranslatedStringArray(job.characterInfos),
						billingBlockOrder: job.billingBlockOrders?.[0]?.billingBlockOrder,
					}),
				),
			],
			[],
		);
	}, [value]);

	useEffect(() => {
		const combinedOptions = [
			...(castAndCrewData
				?.map((data) => ({
					value: data.role,
					label: data.role,
				}))
				.filter((data) => !RoleJson.find((role) => role.value === data.value)) || []),
			...RoleJson.map((role) => ({
				value: role.value,
				label: role.label,
			})),
		];

		const uniqueOptions = combinedOptions.filter(
			(option, index, array) =>
				array.findIndex((o) => o.value === option.value) === index && !!option.value && !!option.label,
		);

		const capitalizedOptions = uniqueOptions.map((option) => ({
			...option,
			label: option.label.charAt(0).toUpperCase() + option.label.slice(1),
		}));

		setRolesOptions(capitalizedOptions);
	}, [castAndCrewData]);

	const castData = useMemo(
		() =>
			castAndCrewData
				?.filter((member) => castRoles.includes(member?.role?.toLowerCase()))
				.sort(sortCastAndCrewMembersCompareFn),
		[castAndCrewData],
	);

	const crewData = useMemo(
		() =>
			castAndCrewData
				?.filter((member) => !castRoles.includes(member?.role?.toLowerCase()))
				.sort(sortCastAndCrewMembersCompareFn),
		[castAndCrewData],
	);

	const editData = useCallback(
		(path: string, _value: any, hasToBeCommitted = false) => {
			if (!title) return;
			const newData = structuredClone(value);
			const jsonPath = path.split('.');

			if (jsonPath[0] === 'people') jsonPath.shift();
			editDeepProperty(newData.displayValue, jsonPath, _value);
			setValue((prev) => ({ ...prev, displayValue: newData.displayValue }));
			if (hasToBeCommitted) commit();
		},
		[value],
	);

	const addToArray = useCallback(
		(path: string, _value: any, hasToBeCommitted = false) => {
			if (!title) return;
			const newData = structuredClone(value);
			if (!newData.displayValue) newData.displayValue = [];

			const jsonPath = path.split('.');
			if (jsonPath[0] === 'people') jsonPath.shift();
			if (getDeepProperty(newData.displayValue, jsonPath) === undefined) {
				editDeepProperty(newData.displayValue, jsonPath, []);
			}
			const currentValue = getDeepProperty(newData.displayValue, jsonPath);
			if (!Array.isArray(currentValue)) throw new Error('Pointed value is not an array');
			currentValue.push(_value);
			setValue((prev) => ({ ...prev, displayValue: newData.displayValue }));
			if (hasToBeCommitted) commit();
		},
		[value],
	);

	const removeFromArray = useCallback(
		(path: string, selectedIndexes: string[]) => {
			if (!title) return;
			const newData = structuredClone(value);

			const jsonPath = path.split('.');
			if (jsonPath[0] === 'people' && jsonPath.length === 1) {
				editDeepProperty(
					newData,
					['displayValue'],
					(value?.displayValue || []).filter((_, idx) => !selectedIndexes.includes(idx.toString())),
				);

				setValue(resetInheritedArrayValues<People>(newData.displayValue, title));
				commit();
			} else {
				if (jsonPath[0] === 'people') jsonPath.shift();
				const currentValue = getDeepProperty(newData.displayValue, jsonPath);
				if (!Array.isArray(currentValue)) throw new Error('Pointed value is not an array');

				const parsedIndexes = selectedIndexes.map((index) => parseInt(index, 10)).filter((idx) => !Number.isNaN(idx));
				editDeepProperty(
					newData.displayValue,
					jsonPath,
					currentValue.filter((_, idx) => !parsedIndexes.includes(idx)),
				);

				setValue((prev) => ({ ...prev, displayValue: newData.displayValue }));
				commit();
			}
		},
		[value],
	);

	const addNewRoleOption = useCallback((_value: string) => {
		const trimmedValue = _value?.trim();
		if (!trimmedValue || trimmedValue === '') return;

		setRolesOptions((prev) => [
			{
				value: trimmedValue.toLowerCase(),
				label: trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1),
			},
			...prev,
		]);
	}, []);

	const isInheritedValue = useMemo(
		() => (value ? isInherited<People[]>({ inheritedObject: value }).isInherited : false),
		[value],
	);

	const values = useMemo(
		() => ({
			data: value,
			rolesOptions,
			addNewRoleOption,
			defaultLocale,
			currentLocale,
			castData,
			isInherited: isInheritedValue,
			crewData,
			editData,
			addToArray,
			removeFromArray,
			setCurrentLocale,
		}),
		[value, rolesOptions, castData, crewData, editData, addToArray, removeFromArray, currentLocale, isInheritedValue],
	);

	return <CastAndCrewContext.Provider value={values}>{children}</CastAndCrewContext.Provider>;
}
