import React from 'react';
import styled, { css } from 'styled-components';
import { TitleCard } from '@warehouse/title/ui';
import { indexedTitleToCommonReadonly } from '@warehouse/title/infra';
import { IndexedTitleV2 } from '@warehouse/graphql';
import Checkbox from '../../../../src/components/library/Checkbox';
import { titleSelectorSelector, useTitleSelectorStore } from '../title-selector.store';

export function TitleListItem({ title }: TitleListItemProps) {
	const actions = useTitleSelectorStore(titleSelectorSelector.actions);
	const isSelected = useTitleSelectorStore(titleSelectorSelector.isTitleSelected(title));

	const onClick = () => {
		if (isSelected) actions.unselectTitle(title);
		else actions.selectTitle(title);
	};

	return (
		<Item tabIndex={0} key={title.uuid} onClick={onClick}>
			<Checkbox checked={isSelected} />
			{/* TODO: This type adaptation should be done in the infra. */}
			<TitleCard variant="colored" isLink={false} titleLight={indexedTitleToCommonReadonly(title)} />
		</Item>
	);
}

interface TitleListItemProps {
	title: IndexedTitleV2;
}

const Item = styled.li(
	({ theme }) => css`
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		list-style: none;
		padding: ${theme.spacing(1)} ${theme.spacing(2)};
		padding-left: ${theme.spacing(0.5)};
		pointer-events: auto !important;
		width: 100%;
		z-index: 10 !important;

		&:hover {
			background-color: ${theme.palette.blue.background};
		}
	`,
);
