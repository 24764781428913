import { Typography } from '@mui/material';
import React from 'react';
import { DropdownV2 } from '@warehouse/shared/ui';
import { TitleInfoWrapper } from './styles';

interface LicensorSelectorReadonlyProps {
	licensor: string;
}

export function LicensorSelectorReadonly({ licensor }: LicensorSelectorReadonlyProps) {
	return (
		<TitleInfoWrapper>
			<Typography variant="s2Medium" color="text.primary">
				Licensor :
			</Typography>
			<div style={{ width: '200px' }}>
				<DropdownV2 height={40} readOnly value={licensor} />
			</div>
		</TitleInfoWrapper>
	);
}
