import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_LICENSORS = gql`
	query GetLicensors {
		getLicensors {
			displayName
			default
		}
	}
`;
