import { v4 as uuid } from 'uuid';
import { WithUuid } from './recordToArray';

export function generateFakeUuid(): string {
	return `tmp-${uuid()}`;
}

export function withTmpUuid<T>(data: T): WithUuid<T> {
	return {
		...data,
		__uuid: generateFakeUuid(),
	};
}

export function isValidUUID(potentialUuid: string): boolean {
	const regexExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return regexExp.test(potentialUuid);
}
