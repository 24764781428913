import { titleRepositorySingleton } from '@warehouse/title/infra';
import { useWatchObservableQuery } from '@warehouse/shared/util';

export function makeItInherited<T>(value?: any): T {
	return {
		inherited: {
			// @ts-ignore
			type: 'pickMain',
		},
		displayValue: value,
		explicitValue: value,
	} as T;
}

export type UseTitleOptions = (
	| {
			uuid: string;
	  }
	| {
			skipable: true;
			uuid?: string | null;
	  }
) & {
	pollInterval?: number;
};

export function useTitle(props: UseTitleOptions) {
	const { pollInterval, uuid } = props;
	const skipable = 'skipable' in props ? props.skipable : false;

	if (!skipable && !uuid) throw new Error('uuid is required if skipable is false');

	const repo = titleRepositorySingleton.get();

	return useWatchObservableQuery({
		queryKey: ['title', uuid],
		queryFn: () => (uuid ? repo.watchTitle(uuid, { pollInterval }) : null),
	});
}
