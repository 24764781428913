import styled, { css } from 'styled-components';

export const CaretWrapper = styled.div<{ closed?: boolean }>`
	${({ theme }) => css`
		align-items: center;
		color: ${theme.palette.blue.main};
		cursor: pointer;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		width: 28px;

		svg {
			transition: all ease-in-out 200ms;
		}
	`}

	${({ closed }) =>
		closed
			? css`
					svg {
						transform: rotate(-90deg);
					}
			  `
			: null}
`;

export const NonCollapsiblePadding = styled.div<{ closed?: boolean }>`
	${({ theme }) => css`
		align-items: center;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		width: 28px;

		svg {
			color: ${theme.palette.text.secondary};
		}
	`}
`;
