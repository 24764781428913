import React, { CSSProperties, useMemo } from 'react';
import { Button } from '@warehouse/shared/legacy-ui';
import { Link } from 'react-router-dom';
import { TitleFull as Title } from '@warehouse/title/core';
import { getGoToParentUuid } from './get-go-to-parent-uuid';

export function GoToParentButton({ title, style }: GoToParentButtonProps) {
	const parentUuid = useMemo(() => getGoToParentUuid(title), [title]);

	if (!parentUuid) return null;

	return (
		<Link style={style} to={`/title/${parentUuid}`}>
			<Button nxstyle="primary-blue">Go To Parent</Button>
		</Link>
	);
}

interface GoToParentButtonProps {
	title: Title;
	style?: CSSProperties;
}
