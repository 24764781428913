import React, { useMemo } from 'react';
import { titlePaths } from '@warehouse/title/core';
import { TitleSingleDropdownField } from '@warehouse/title/ui';
import availableColorTypes from '../../../../src/assets/json-administration-profiles/availableColorType.json';

export function PictureColorTypeField() {
	const colorTypeOptions = useMemo(
		() =>
			availableColorTypes.map(({ name, label }) => ({
				label,
				value: name,
			})),
		[],
	);

	return (
		<TitleSingleDropdownField
			canClear
			label="Picture Color type"
			path={titlePaths.pictureColorType}
			options={colorTypeOptions}
			ariaLabel="Picture Color Type"
			view="productMetadata"
		/>
	);
}
