import React, { useCallback, useRef } from 'react';
import { CellContext } from '@tanstack/react-table';
import { ContentIdentifier } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/ContentIdentifier';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';
import { TableTextInput } from '../../../style';
import useBlurAndDebounceValue from '../../../../../hooks/useTitleRecordsAutoSave/useBlurAndDebounceValue';
import { CellWrapper } from '../../../../../../../components/library/SimpleTable/style';

export default function CellLocation({
	getValue,
	table,
}: CellContext<Inherited<ContentIdentifier>, Inherited<ContentIdentifier>>) {
	const { displayValue } = getValue();
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;

	const save = (v: string | undefined) => {
		if (v && v.trim()) {
			editRecord({
				...getValue(),
				displayValue: {
					...getValue().displayValue,
					location: v,
				},
			});
		} else {
			editRecord({
				...getValue(),
				displayValue: {
					...getValue().displayValue,
					location: undefined,
				},
			});
		}
	};
	const saveRef = useRef(save);

	saveRef.current = save;

	const { value, setValue, commit } = useBlurAndDebounceValue<string | undefined>({
		initialValue: displayValue.location,
		save: useCallback((v) => saveRef.current(v), [saveRef]),
	});

	return (
		<CellWrapper>
			<TableTextInput value={value ?? ''} onChange={(e) => setValue(e.target.value)} onBlur={() => commit()} />
		</CellWrapper>
	);
}
