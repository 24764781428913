import React, { Dispatch, SetStateAction, useContext } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { v4 as uuid } from 'uuid';
import Button from '../../../../../../components/library/Button';
import { CastAndCrewContext } from '../providers/castAndCrewProvider';

type AddCharacterButtonProps = {
	peopleIndex: number;
	roleIndex: number;
	setUuids: Dispatch<SetStateAction<string[]>>;
};

export default function AddCharacterButton({ peopleIndex, roleIndex, setUuids }: AddCharacterButtonProps) {
	const { addToArray, defaultLocale } = useContext(CastAndCrewContext);

	const onClick = () => {
		addToArray(`people.${peopleIndex}.jobs.${roleIndex}.characterInfos`, {
			characterNames: [
				{
					characterName: '',
					language: defaultLocale,
				},
			],
		});
		setUuids((prevUuids) => [...prevUuids, uuid()]);
	};

	return (
		<Button onClick={onClick} nxstyle="secondary-black" startIcon={<Icon path={mdiPlus} size={1} />}>
			Add Character
		</Button>
	);
}
