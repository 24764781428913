import React from 'react';
import { Snackbar as MUISnackbar, AlertColor } from '@mui/material';
import AlertSnackbar from './AlertSnackbar';

interface SnackbarProps {
	open: boolean;
	onClose?: () => void;
	title?: string;
	severity?: AlertColor;
	message: string;
}

function Snackbar({ open, onClose, title, message, severity }: SnackbarProps) {
	return (
		<MUISnackbar
			autoHideDuration={5000}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			open={open}
			onClose={(_, reason) => {
				if (reason !== 'clickaway') onClose?.();
			}}
		>
			<div>
				<AlertSnackbar style={{ display: 'flex', padding: '0px 12px' }} severity={severity ?? 'info'} title={title}>
					<p style={{ height: '8px', marginTop: '4px' }}>{message}</p>
				</AlertSnackbar>
			</div>
		</MUISnackbar>
	);
}

export default Snackbar;
