import { isDerived, isPrimaryAndDerivedFrom, TitleCommon } from '@warehouse/title/core';
import React from 'react';
import { getBackgroundColor, getWorkTypeObject } from '@warehouse/title/infra';
import { useNavigate } from 'react-router-dom';
import { TopBarLegacy } from '../../../src/views/title/TopBarLegacy';
import titleSearchDisplayNameBuilder from '../../../src/utils/titles/titleSearchDisplayNameBuilder';

type TitleOverviewTopBarProps = {
	titleCommon: TitleCommon;
	actions: React.ReactNode;
};

export function TitleOverviewTopBar({ titleCommon, actions }: TitleOverviewTopBarProps) {
	const { title, subTitle1, subTitle2 } = titleSearchDisplayNameBuilder({
		tokens: titleCommon.readonly.titleSearchDisplayNameFullHTML,
	});

	const workTypeObject = getWorkTypeObject(titleCommon.readonly.workType);

	const parentWorkTypeObject = isDerived(titleCommon.readonly)
		? getWorkTypeObject(titleCommon.readonly.parentAbstractionWorkType)
		: undefined;

	const backgroundColor = getBackgroundColor(workTypeObject.label);

	const navigate = useNavigate();

	return (
		<TopBarLegacy
			title={title}
			subTitle1={subTitle1}
			subTitle2={subTitle2}
			titleTypeName={workTypeObject.label}
			parentTitleTypeName={parentWorkTypeObject?.label}
			iconColor={backgroundColor}
			onClickBack={() => {
				navigate(-1);
			}}
			isPrimaryAndDerivedFrom={
				titleCommon.readonly.parents ? isPrimaryAndDerivedFrom(titleCommon.readonly.parents) : false
			}
			actions={actions}
		/>
	);
}
