import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { EditRelationshipDrawer } from './ui/EditRelationshipDrawer';
import { ParentRelationshipEditorLoader } from './ParentRelationshipEditor.loader';
import { CompObjRelationshipEditorLoader } from './CompObjRelationshipEditor.loader';

type RelationshipEditorProps = {
	childUuid?: string;
	parentUuid: string;
	onClose: (force?: boolean) => void;
} & CompObjChoice;

type CompObjChoice =
	| {
			isCompObj: true;
			relationshipType: undefined;
	  }
	| {
			isCompObj: false;
			relationshipType: string;
	  };

export function RelationshipEditor({
	childUuid,
	onClose,
	parentUuid,
	isCompObj,
	relationshipType,
}: RelationshipEditorProps) {
	const isOpen = useMemo(() => !!childUuid, [childUuid]);

	const displayParentRelationshipEditor = !!childUuid && !isCompObj && !!relationshipType;
	const displayCompObjRelationshipEditor = !!childUuid && isCompObj;
	const header = displayParentRelationshipEditor ? 'Relationship Settings' : 'Compilation Entry Settings';

	if (!isOpen) return null;

	return (
		<Overlay>
			<EditRelationshipDrawer open={isOpen} onClose={onClose} header={header}>
				{displayParentRelationshipEditor && (
					<ParentRelationshipEditorLoader
						childUuid={childUuid}
						parentUuid={parentUuid}
						relationshipType={relationshipType}
						onClose={onClose}
					/>
				)}
				{displayCompObjRelationshipEditor && (
					<CompObjRelationshipEditorLoader childUuid={childUuid} parentUuid={parentUuid} onClose={onClose} />
				)}
			</EditRelationshipDrawer>
		</Overlay>
	);
}

const Overlay = styled.div(
	() => css`
		height: 100vh;
		position: fixed;
		top: 0;
		width: 100vw;
	`,
);
