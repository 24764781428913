import { RelationshipType, WorkType } from '@warehouse/title/core';
import { RelationshipsService } from '@warehouse/title/domain';
import { WorkTypeSelector } from '@warehouse/title/ui';
import React from 'react';
import { Typography } from '@mui/material';
import { TitleInfoWrapper } from './styles';

export interface RelationshipModeWorkTypeSelectorContainerProps {
	relationshipType?: RelationshipType;
	selected?: WorkType;
	onSelect: (workType: WorkType) => void;
}

export function RelationshipModeWorkTypeSelectorContainer({
	relationshipType,
	selected,
	onSelect,
}: RelationshipModeWorkTypeSelectorContainerProps) {
	if (!relationshipType) {
		return (
			<WorkTypeSelecstorWrapper>
				<WorkTypeSelector disabled selected={selected} />
			</WorkTypeSelecstorWrapper>
		);
	}

	const supportedWorkTypes = RelationshipsService.getChildrenWorkTypesForRelationshipType(relationshipType).filter(
		(workType) => [WorkType.Supplemental, WorkType.Movie, WorkType.Compilation, WorkType.Series].includes(workType),
	);

	return (
		<WorkTypeSelecstorWrapper>
			<WorkTypeSelector supportedWorkTypes={supportedWorkTypes} selected={selected} onSelect={onSelect} />
		</WorkTypeSelecstorWrapper>
	);
}

function WorkTypeSelecstorWrapper({ children }: { children: React.ReactNode }) {
	return (
		<TitleInfoWrapper>
			<Typography variant="s2Medium" color="text.primary">
				Title Type* :
			</Typography>
			{children}
		</TitleInfoWrapper>
	);
}
