function computeTitlePlaceholder(isEpisode: boolean, isSeason: boolean, sequenceNumber: string, index?: number) {
	if (isEpisode && sequenceNumber !== '') {
		if (sequenceNumber !== '') {
			return `Episode ${parseInt(sequenceNumber, 10)}`;
		}
		if (!sequenceNumber && index) {
			return `Episode ${index + 1}`;
		}
	}
	if (isSeason) {
		if (sequenceNumber !== '') {
			return `Season ${parseInt(sequenceNumber, 10)}`;
		}
		if (!sequenceNumber && index) {
			return `Season ${index + 1}`;
		}
	}
	return undefined;
}

export default computeTitlePlaceholder;
