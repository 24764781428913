import React, { memo, useContext, useEffect, useState } from 'react';
import { CellContext } from '@tanstack/react-table';

// LIBRARY
import styled, { css } from 'styled-components';
import { DropdownV2 } from '@warehouse/shared/ui';
import { CellWrapper } from '../../../components/library/SimpleTable/style';
import TextInput from '../../../components/library/TextInput';
import TimeInput from '../../../components/library/TimeInput';
import { CustomColumnDef } from '../../../components/library/SimpleTable/customColumnDef';
import DatePicker from '../../../components/library/DatePicker/DatePicker';
import Dropdown from '../../../components/library/Dropdown/Dropdown';
import { LicensorPicklistContext } from './LicensorPicklistContext';
import computeSortableName from '../../../utils/computeSortableName';
import computeTitlePlaceholder from '../createTitle/computeTitlePlaceholder';

export const NumberInputCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper withHorizontalPadding style={{ width: '100px' }}>
		<TextInput
			height={32}
			type="number"
			value={(getValue() as string) || ''}
			onChange={(e) => {
				table.options.meta?.updateData?.(row.index, column.id!, e.target.value);
			}}
		/>
	</CellWrapper>
));

export const StringInputCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper withHorizontalPadding style={{ width: '100%' }}>
		<TextInput
			height={32}
			type="text"
			value={(getValue() as string) || ''}
			onChange={(e) => {
				table.options.meta?.updateData?.(row.index, column.id!, e.target.value);
			}}
		/>
	</CellWrapper>
));

export const TimeInputCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper withHorizontalPadding>
		<TimeInput
			value={getValue() as number}
			onChange={(e) => {
				table.options.meta?.updateData?.(row.index, column.id!, e);
			}}
			height={30}
		/>
	</CellWrapper>
));

export const DatePickerCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper withHorizontalPadding>
		<DatePicker
			{...(column.columnDef as CustomColumnDef<any>).cellProps}
			value={getValue() ? (getValue() as Date) : undefined}
			onChange={(date) => {
				table.options.meta?.updateData?.(row.index, column.id!, date);
			}}
			height={32}
		/>
	</CellWrapper>
));

export const SelectDropdownCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper style={{ overflow: 'hidden' }}>
		<DropdownV2
			{...(column.columnDef as CustomColumnDef<any>).cellProps}
			options={(column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
			getPopupContainer={() => document.body}
			mode="multiple"
			value={(getValue() as string[]) || []}
			transparentBorder
			backgroundColor="transparent"
			withSearch
			onChange={(values) => {
				table.options.meta?.updateData?.(row.index, column.id!, values);
			}}
		/>
	</CellWrapper>
));

export const DropdownCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper>
		<Dropdown
			options={(column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
			value={getValue() as string}
			onChange={(e) => table.options.meta?.updateData?.(row.index, column.id!, e as string)}
			enablePortal
			transparentBorder
			{...(column.columnDef as CustomColumnDef<any>).cellProps}
		/>
	</CellWrapper>
));

export const SearchDropdownCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper>
		<Dropdown
			withSearch
			options={(column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
			value={getValue() as string}
			onChange={(e) => table.options.meta?.updateData?.(row.index, column.id!, e as string)}
			enablePortal
			transparentBorder
		/>
	</CellWrapper>
));

const CustomTextInput = styled(TextInput)(
	({ theme }) => css`
		input {
			&:focus {
				border-radius: 0;
				border: solid 1px ${theme.palette.blue.main} !important;
			}

			&:focus-visible {
				outline: transparent;
			}
		}

		.input-wrapper {
			border: none;
			input {
				::placeholder {
					font-style: italic !important;
				}
			}
		}
	`,
);

export const TitleWrapper = styled.div(
	() => css`
		height: 48px;
		width: 100%;
		input {
			width: 100%;
		}
		& > * {
			.input-wrapper {
				background-color: inherit;
			}
			border-radius: 0 !important;
			margin: 0 !important;
		}
	`,
);

export const TitleCell = memo(
	({
		getValue,
		table,
		row,
		column,
		isEpisode,
		isSeason,
	}: CellContext<any, any> & { isEpisode: boolean; isSeason: boolean }) => {
		const [value, setValue] = useState<string | undefined>(getValue());
		useEffect(() => {
			setValue(getValue());
		}, [getValue()]);

		return (
			<TitleWrapper>
				<CustomTextInput
					onBlur={() => {
						table.options.meta?.updateData?.(row.index, column.id!, value);
					}}
					placeholder={computeTitlePlaceholder(isEpisode, isSeason, row.original.sequenceNumber, row.index)}
					value={value || ''}
					onChange={(e) => setValue(e.target.value)}
				/>
			</TitleWrapper>
		);
	},
);

export const SortTitleCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => {
	const [value, setValue] = useState<string | undefined>(getValue());
	useEffect(() => {
		setValue(getValue());
	}, [getValue()]);

	const displayTitle = (column.columnDef as CustomColumnDef<any>).cellProps?.displayTitles?.[row.index];

	return (
		<TitleWrapper>
			<CustomTextInput
				placeholder={computeSortableName(displayTitle)}
				onBlur={() => {
					table.options.meta?.updateData?.(row.index, column.id!, value);
				}}
				value={value || ''}
				onChange={(e) => setValue(e.target.value)}
			/>
		</TitleWrapper>
	);
});

export const TextInputCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => {
	const [value, setValue] = useState<string | undefined>(getValue());
	useEffect(() => {
		setValue(getValue());
	}, [getValue()]);

	return (
		<TitleWrapper>
			<CustomTextInput
				{...(column.columnDef as CustomColumnDef<any>).cellProps}
				onBlur={() => {
					table.options.meta?.updateData?.(row.index, column.id!, value);
				}}
				value={value || ''}
				onChange={(e) => setValue(e.target.value)}
			/>
		</TitleWrapper>
	);
});

export function titleCellWrapper(isEpisode: boolean, isSeason: boolean) {
	return function cell(props: CellContext<any, any>) {
		return <TitleCell {...props} isEpisode={isEpisode} isSeason={isSeason} />;
	};
}

export const LicensorPicklistCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => {
	const { options, onNewOptionAdded } = useContext(LicensorPicklistContext);

	return (
		<CellWrapper>
			<Dropdown
				options={options}
				value={getValue() as string}
				onChange={(e) => table.options.meta?.updateData?.(row.index, column.id!, e as string)}
				enablePortal
				onNewOptionAdded={onNewOptionAdded}
				transparentBorder
				withSearch
				pickList
			/>
		</CellWrapper>
	);
});
