import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { OriginalLanguage } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { CountryOfOriginRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';

export function sortListingOrder(
	a: { listingOrder: OptionalInherited<number> } | undefined,
	b: { listingOrder: OptionalInherited<number> } | undefined,
): number {
	const aVal = a?.listingOrder?.displayValue || 0;
	const bVal = b?.listingOrder?.displayValue || 0;

	return aVal - bVal;
}

export function sortListingOrderNonInherited(
	a: OriginalLanguage | CountryOfOriginRegion,
	b: OriginalLanguage | CountryOfOriginRegion,
): number {
	const aVal = a?.listingOrder || 0;
	const bVal = b?.listingOrder || 0;

	return aVal - bVal;
}
