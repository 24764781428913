import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

// TYPES
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { AssociatedOrg } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { Row } from '@tanstack/react-table';
import { isInherited, isPrimaryEdit } from '@warehouse/title/core';
import { Typography } from '@mui/material';
import { DropdownV2Option } from '@warehouse/shared/ui';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { CustomColumnDef } from '../../../../../../../components/library/SimpleTable/customColumnDef';

// HOOKS
import useTitleAutoSave from '../../../../../hooks/useTitleAutoSave/useTitleAutoSave';

// UTILS
import useMemoJsonPath from '../../../../../hooks/useMemoJsonPath';
import { fromBasic } from '../../../../../../../utils/titleGetProperty';
import computeErrors from '../../../../../utils/computeErrors';

// LIBRARY
import DisplayNameCell from './DisplayNameCell';
import RoleCell from './RoleCell';
import TitleEditableField from '../../../../../../../components/titles/TitleEditableField';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';

// JSON
import availablesAssociatedOrgsRoleOptions from '../../../../../../../assets/json-administration-profiles/availableAssociatedOrgsRole.json';
import useTooltip from '../../../../../../../utils/hooks/useTooltips';

interface AssociatedOrgsTableProps {
	editMode: boolean;
}

const Wrapper = styled.div(
	() => css`
		.table-wrapper {
			width: auto !important;
		}

		.title-editable-field {
			padding: 0 !important;
		}
	`,
);

function associatedOrgsFilterFn(row: Row<AssociatedOrg>, _: string, value: string): boolean {
	return row.original.role !== value;
}

function getAssociatedOrgsRoleOptions(associatedOrgs: AssociatedOrg[] | undefined): DropdownV2Option[] {
	if (!associatedOrgs) return [];
	const allRoles = associatedOrgs.map((ao) => ao.role);
	const uniqueRoles = Array.from(new Set(allRoles));
	return uniqueRoles.map((role) => ({
		label: role,
		value: role,
	}));
}

function AssociatedOrgsTable({ editMode }: AssociatedOrgsTableProps) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { readOnlyValue, value, setValue, commit, hasInvalidRow } = useTitleAutoSave<
		OptionalInherited<AssociatedOrg[]>,
		AssociatedOrg
	>({
		label: 'Associated Orgs',
		path: useMemoJsonPath(fromBasic(['associatedOrgs'])),
		isRowValid: useCallback((row: AssociatedOrg) => !!row.displayName?.trim() && !!row.role?.trim(), []),
	});
	const isInheritedValue = useMemo(
		() => (value ? isInherited<AssociatedOrg[]>({ inheritedObject: value }).isInherited : false),
		[value],
	);

	const displayNameTooltip = useTooltip('coreMetadata.basic.associatedOrgs.displayName');
	const roleTooltip = useTooltip('coreMetadata.basic.associatedOrgs.role');

	const [rolesOptions, setRolesOptions] = useState<DropdownV2Option[]>([]);
	const jsonRoleOptions = availablesAssociatedOrgsRoleOptions.map((role) => ({
		label: role.label,
		value: role.name,
	}));

	const initializeOptions = (associatedOrgsOptions: DropdownV2Option[]) => {
		const combinedRoleOptions = [...jsonRoleOptions, ...associatedOrgsOptions].filter(
			(option, index, array) =>
				option.value !== 'licensor' &&
				array.findIndex((o) => o.value === option.value) === index &&
				!!option.value &&
				!!option.label,
		);
		setRolesOptions([...combinedRoleOptions]);
	};

	useEffect(() => {
		if (rolesOptions.length === availablesAssociatedOrgsRoleOptions.length || rolesOptions.length === 0) {
			const rolesFromTitles = getAssociatedOrgsRoleOptions(value?.displayValue);
			initializeOptions(rolesFromTitles);
		}
	}, [value]);

	const columns: CustomColumnDef<AssociatedOrg>[] = useMemo(
		() => [
			{
				id: 'displayName',
				header: 'Display Name',
				tooltip: displayNameTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: (v) => (
					<Typography
						style={{ marginLeft: 12 }}
						className={isInheritedValue ? 'inherited' : 'default'}
						fontWeight={400}
						fontSize="14px"
					>
						{v?.displayName}
					</Typography>
				),
				cell: (info) => DisplayNameCell(info),
				width: 100,
			},
			{
				id: 'role',
				header: 'Role',
				tooltip: roleTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: (v) => {
					const role = v?.role;
					const toDisplay = role.charAt(0).toUpperCase() + role.slice(1);
					return (
						<Typography
							style={{ marginLeft: 12 }}
							className={isInheritedValue ? 'inherited' : 'default'}
							fontWeight={400}
							fontSize="14px"
						>
							{toDisplay}
						</Typography>
					);
				},
				filterFn: associatedOrgsFilterFn,
				width: 200,
				cell: (info) =>
					RoleCell({
						info,
						options: rolesOptions,
						onNewOptionAdded: (_value: string) => {
							const trimmedValue = _value?.trim();
							if (!trimmedValue || trimmedValue === '') return;

							setRolesOptions([
								{
									value: trimmedValue.toLowerCase(),
									label: trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1),
								},
								...rolesOptions,
							]);
						},
					}),
			},
		],
		[rolesOptions, isInheritedValue],
	);

	const onRowAdd = () => {
		const newAssociatedOrg: AssociatedOrg = {
			displayName: '',
			role: '',
			idType: 'nexspec.com/warehouse',
			organizationId: undefined,
		};

		setValue(
			(prev) => ({
				...prev,
				displayValue: [...(prev?.displayValue || []), newAssociatedOrg],
			}),
			false,
		);
	};

	const onRowDelete = (selectedIndexes: string[]) => {
		// We can not handle this case with the current implementation of resetInheritedValuesAllOrNothingTables
		// because we need to keep the licensor row
		setValue((prev) => {
			// So we update the data, filter the licensor out, and if there is no more rows it means we need to reset to inherited
			const updatedData = prev?.displayValue?.filter((_, index) => !selectedIndexes.includes(index.toString()));
			const licensorFilteredOut = updatedData?.filter((ao) => ao.role !== 'licensor');
			return {
				...prev,
				displayValue: !isPrimaryEdit(title) && !licensorFilteredOut?.length ? prev.inherited.value || [] : updatedData,
			};
		});
		commit();
	};

	const errors: { [key: string]: number[] } = useMemo(
		() => ({
			displayName: computeErrors<AssociatedOrg>(
				value?.displayValue?.filter((ao) => ao?.role !== 'licensor') || [],
				'displayName',
			),
			role: computeErrors<AssociatedOrg>(value?.displayValue?.filter((ao) => ao?.role !== 'licensor') || [], 'role'),
		}),
		[value],
	);

	return (
		<Wrapper>
			<TitleEditableField
				label="Associated Orgs"
				path="coreMetadata.basic.associatedOrgs"
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
				ruleOrigin={undefined}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<AssociatedOrg>
				selectRowModeByDefault
				enableAddMultipleRows={false}
				showBottomActionsBar={editMode}
				tableStyle="border"
				columnFilters={[{ id: 'role', value: 'licensor' }]}
				columns={columns}
				data={(editMode ? value?.displayValue : readOnlyValue?.displayValue) || []}
				onRowAdd={onRowAdd}
				onRowDelete={(selectedIndexes) => onRowDelete(selectedIndexes)}
				canAddRow={hasInvalidRow === false}
				errors={errors}
				editMode={editMode}
				customPropsCell={{ setValue, commit, isInherited: isInheritedValue }}
				noOverflow
			/>
		</Wrapper>
	);
}

export default AssociatedOrgsTable;
