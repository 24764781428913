import { Inherited, OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// LIBRARIES
import { transformEmptyStrings } from '@warehouse/shared/util';
import TextInput from '../../../../components/library/TextInput';
import Dropdown from '../../../../components/library/Dropdown/Dropdown';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';

// STYLES
import { DetailsWrapper } from '../style';

// JSON
import languagesWrittenJSON from '../../../../assets/json-administration-profiles/availableLanguageWritten.json';
import sortAlphabeticallyByLabel from '../../../../utils/sortAlphabeticallyByLabel';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface DisplayTitleFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	titlePlaceholder?: string;
	isRequired?: boolean;
	inheritedValues?: Title | undefined;
}

function DisplayTitleField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	titlePlaceholder,
	inheritedValues,
}: DisplayTitleFieldProps) {
	const languagesWritten = languagesWrittenJSON.sort(sortAlphabeticallyByLabel);
	const titleDisplayTooltip = useTooltip('coreMetadata.basic.localizedInfos.titleDisplayUnlimited');
	const titleLanguageTooltip = useTooltip('coreMetadata.basic.localizedInfos.language');

	const inheritedDisplayLanguage = getTitleData<Inherited<string>>(
		inheritedValues,
		fromBasic(['localizedInfos', '__default', 'language']),
	);
	const inheritedDisplayTitle = getTitleData<OptionalInherited<string>>(
		inheritedValues,
		fromBasic(['localizedInfos', '__default', 'titleDisplayUnlimited']),
	);

	const titleLanguageField = useMemo(
		() => (
			<Controller
				name="titleLanguage"
				control={control}
				render={({ field }) => {
					const inheritedLang = inheritedDisplayLanguage?.displayValue;
					const placeholderValue = inheritedLang
						? languagesWritten?.find((lang: any) => lang.termUid === inheritedLang)?.label || 'Unknown'
						: undefined;

					return (
						<Dropdown
							withSearch
							tooltip={titleLanguageTooltip?.tooltip}
							placeholder={placeholderValue}
							placeholderStyle="italic"
							label={getLabelWithRequired('Title Language', isRequired)}
							enablePortal
							error={errors?.titleLanguage?.message as string}
							options={
								languagesWritten?.map((lang: any) => ({
									label: lang.label,
									value: lang.uuid,
								})) || []
							}
							value={field.value}
							onChange={async (e) => {
								setValue('titleLanguage', e);
								if (isRequired && !inheritedLang) {
									await trigger('titleLanguage');
								}
							}}
						/>
					);
				}}
			/>
		),
		[
			control,
			inheritedDisplayLanguage?.displayValue,
			errors?.titleLanguage?.message,
			languagesWritten,
			setValue,
			trigger,
		],
	);

	const titleField = useMemo(
		() => (
			<Controller
				control={control}
				name="title"
				render={({ field }) => (
					<TextInput
						placeholder={titlePlaceholder}
						variant={titlePlaceholder && !transformEmptyStrings(field.value) ? 'prefilled' : 'default'}
						tooltip={titleDisplayTooltip?.tooltip}
						value={field.value}
						error={isRequired ? (errors?.title?.message as string) : ''}
						onChange={async (e) => {
							setValue('title', e.target.value);
							if (isRequired && !inheritedDisplayTitle?.displayValue) {
								await trigger('title');
							}
						}}
						label={getLabelWithRequired('Title', isRequired)}
					/>
				)}
			/>
		),
		[
			control,
			titlePlaceholder,
			inheritedDisplayTitle?.displayValue,
			isRequired,
			errors?.title?.message,
			setValue,
			trigger,
		],
	);

	return (
		<DetailsWrapper>
			{titleField}
			{titleLanguageField}
		</DetailsWrapper>
	);
}

export default DisplayTitleField;
