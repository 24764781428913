import React, { useMemo } from 'react';
import { titlePaths } from '@warehouse/title/core';
import { TitleSingleDropdownField } from '@warehouse/title/ui';
import availablePictureFormats from '../../../../src/assets/json-administration-profiles/availablePictureFormat.json';

export function PictureFormatField() {
	const pictureFormatOptions = useMemo(
		() =>
			availablePictureFormats.map(({ name, label }) => ({
				label,
				value: name,
			})),
		[],
	);

	return (
		<TitleSingleDropdownField
			canClear
			label="Picture Format"
			path={titlePaths.pictureFormat}
			options={pictureFormatOptions}
			ariaLabel="Picture Format"
			view="productMetadata"
		/>
	);
}
