import React from 'react';
import { TitleFull } from '@warehouse/title/core';
import { ParentTitleCard } from '../fields/ParentTitleCard';

export interface ManifestationProps {
	selectedParentTitle?: TitleFull;
}

export function Manifestation({ selectedParentTitle }: ManifestationProps) {
	return <ParentTitleCard selectedParentTitle={selectedParentTitle} />;
}
