import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';

import { alpha, IconButton } from '@mui/material';
import { DropdownV2 } from '@warehouse/shared/ui';
import Dropdown from '../../library/Dropdown/Dropdown';
import TextInput from '../../library/TextInput';
import NumberInput from '../../library/NumberInput';
import DatePicker from '../../library/DatePicker/DatePicker';
import SelectDropdown from '../../library/SelectDropdown/SelectDropdown';
import BackendSelectDropdown from './BackendSelectDropdown';
import { FilterField, isFilterFieldsRequirementBackendQuery } from '../../../utils/table/filtersUtils';
import BackendSearchDropdown from './BackendSearchDropdown';
import { TerritoriesSelector } from '../../titles/TerritoriesSelector';

const Wrapper = styled.div`
	${({ theme }) => css`
		background-color: ${alpha(theme.palette.blue.main, 0.05)};
		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 4px;
		display: flex;
		padding: ${theme.spacing(1)};
		position: relative;

		&:hover {
			border: 1px solid ${theme.palette.blue.main};
		}
	`}
`;

const InputsWrapper = styled.div`
	${({ theme }) => css`
		column-gap: ${theme.spacing(1)};
		display: grid;
		grid-template-columns: 3fr 3fr 5fr;
		width: 95%;

		.input-wrapper {
			height: 40px;
			min-height: 40px;
		}
	`}
`;

const FlexCenterWrapper = styled.div`
	${() => css`
		align-items: center;
		display: flex;
	`}
`;
const DeleteButton = styled(IconButton)`
	${({ theme }) => css`
		color: ${theme.palette.text.secondary};
		margin-left: ${theme.spacing(1)};
	`};
`;

const AuxiliaryDropdown = styled(Dropdown)`
	${({ theme }) => css`
		margin-right: ${theme.spacing(1)};
		min-width: 200px;
	`};
`;

const AuxiliaryNumber = styled(NumberInput)`
	${({ theme }) => css`
		flex-grow: 0;
		margin-right: ${theme.spacing(1)};
		max-width: 80px;
	`};
`;

const numberOperators: Omit<OperatorRestrictor, 'value'> = {
	operators: [
		{
			label: 'is more than',
			value: 'isMore',
		},
		{
			label: 'is less than',
			value: 'isLess',
		},
		{
			label: 'is equal or more than',
			value: 'isEqualOrMore',
		},
		{
			label: 'is equal or less than',
			value: 'isEqualOrLess',
		},
		{
			label: 'is equal to',
			value: 'equals',
		},
		{
			label: 'is not equal to',
			value: 'doesNotEqual',
		},
		{
			label: 'is empty',
			value: 'isEmpty',
		},
	],
};

const arrayOperators: OperatorRestrictor = {
	value: 'array',
	operators: [
		{
			label: 'includes',
			value: 'includes',
		},
		{
			label: 'includes only',
			value: 'includesOnly',
		},
		{
			label: 'does not include',
			value: 'doesNotInclude',
		},
	],
};

const intOperators: OperatorRestrictor = {
	...numberOperators,
	value: 'int',
};

const floatOperators: OperatorRestrictor = {
	...numberOperators,
	value: 'float',
};

const dateOperators: OperatorRestrictor = {
	value: 'date',
	operators: [
		{
			label: 'is before',
			value: 'isBefore',
		},
		{
			label: 'is after',
			value: 'isAfter',
		},
		{
			label: 'is on or before',
			value: 'isOnOrBefore',
		},
		{
			label: 'is on or after',
			value: 'isOnOrAfter',
		},
		{
			label: 'is equal to',
			value: 'equals',
		},
		{
			label: 'is not equal to',
			value: 'doesNotEqual',
		},
		{
			label: 'is empty',
			value: 'isEmpty',
		},
	],
};

const dateAuxiliaryOptions = [
	{
		label: 'the date',
		value: 'theDate',
	},
	{
		value: 'daysInThePast',
		label: 'day(s) in the past',
	},
	{
		label: 'yesterday',
		value: 'yesterday',
	},
	{
		label: 'today',
		value: 'today',
	},
	{
		label: 'tomorrow',
		value: 'tomorrow',
	},
	{
		value: 'daysInTheFuture',
		label: 'day(s) in the future',
	},
];

const booleanOperators: OperatorRestrictor = {
	value: 'boolean',
	operators: [
		{
			label: 'is equal to',
			value: 'equals',
		},
		{
			label: 'is empty',
			value: 'isEmpty',
		},
	],
};

const workTypeOperators: OperatorRestrictor = {
	value: 'workType',
	operators: [
		{
			label: 'is equal to',
			value: 'equals',
		},
		{
			label: 'is not equal to',
			value: 'doesNotEqual',
		},
	],
};

const workTypeDetailsOperators: OperatorRestrictor = {
	value: 'workTypeDetails',
	operators: [
		{
			label: 'is equal to',
			value: 'equals',
		},
		{
			label: 'is not equal to',
			value: 'doesNotEqual',
		},
	],
};

const titleStatusOperators: OperatorRestrictor = {
	value: 'titleStatus',
	operators: [
		{
			label: 'is equal to',
			value: 'equals',
		},
		{
			label: 'is not equal to',
			value: 'doesNotEqual',
		},
	],
};

const stringOperators: OperatorRestrictor = {
	value: 'string',
	operators: [
		{
			label: 'starts with',
			value: 'startsWith',
		},
		{
			label: 'ends with',
			value: 'endsWith',
		},
		{
			label: 'contains',
			value: 'contains',
		},
		{
			label: 'does not contain',
			value: 'doNotContains',
		},
		// {
		// 	label: 'is any of',
		// 	value: 'isAnyOf',
		// },
		// {
		// 	label: 'is none of',
		// 	value: 'isNoneOf',
		// },
		// {
		// 	label: 'is equal to',
		// 	value: 'equals',
		// },
		// {
		// 	label: 'is not equal to',
		// 	value: 'doesNotEqual',
		// },
		{
			label: 'is empty',
			value: 'isEmpty',
		},
	],
};

interface FilterItemProps {
	type: RestrictorType;
	fieldOptions: {
		label: string;
		value: string;
	}[];
	fieldValue: string | null;
	setFieldValue: (value: string | null) => void;
	operatorValue: string | null;
	setOperatorValue: (value: string | null) => void;
	value?: string | number | boolean | (string | number)[] | Date | undefined;
	setValue: (value: string | string[]) => void;
	valueOptions?: {
		label: string;
		value: string;
	}[];
	dateAuxiliary?: DateAuxiliary;
	setDateAuxiliary?: (v: DateAuxiliary) => void;
	onDelete: () => void;
	field: FilterField;
}

function FilterItem({
	type,
	fieldOptions,
	fieldValue,
	setFieldValue,
	operatorValue,
	setOperatorValue,
	value,
	setValue,
	onDelete,
	valueOptions,
	dateAuxiliary,
	setDateAuxiliary,
	field,
}: FilterItemProps) {
	const [hovered, setHovered] = useState(false);
	const [selectDropdownWidth, setSelectDropdownWidth] = useState<number>(0);
	const inputsWrapperEl = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (inputsWrapperEl.current) {
			const el = inputsWrapperEl.current;
			const maxWidth = el.clientWidth;
			// starts at 16 because of the column gap
			let total = 16;

			for (let i = 0; i < el.childNodes.length - 1; i += 1) {
				const tmp = el.childNodes[i] as Element;
				total += tmp.clientWidth;
			}
			setSelectDropdownWidth(maxWidth - total);
		}
	}, [inputsWrapperEl?.current]);

	const getOperatorOptions = () => {
		switch (type) {
			case 'int':
				return intOperators.operators;
			case 'float':
				return floatOperators.operators;
			case 'boolean':
				return booleanOperators.operators;
			case 'date':
				return dateOperators.operators;
			case 'array':
			case 'countries-array':
				return arrayOperators.operators;
			case 'workType':
				return workTypeOperators.operators;
			case 'workTypeDetails':
				return workTypeDetailsOperators.operators;
			case 'titleStatus':
				return titleStatusOperators.operators;
			case 'string':
			default:
				return stringOperators.operators;
		}
	};

	const getInput = () => {
		if (
			isFilterFieldsRequirementBackendQuery(field) &&
			field.isCustomQueryForOperators &&
			field.isCustomQueryForOperators.find((op) => op === operatorValue)
		) {
			if (operatorValue === 'isAnyOf' || operatorValue === 'isNoneOf') {
				return (
					<BackendSelectDropdown
						setValues={setValue}
						values={value as string[]}
						query={field.query}
						queryKey={field.queryKey}
						searchKey={field.id}
						documentsMapFn={field.documentsMapFn}
					/>
				);
			}
			if (operatorValue === 'equals' || operatorValue === 'doesNotEqual') {
				return (
					<BackendSearchDropdown
						setValue={setValue as (value: string | null) => void}
						value={value as string}
						query={field.query}
						queryKey={field.queryKey}
						searchKey={field.id}
						documentsMapFn={field.documentsMapFn}
					/>
				);
			}
		}

		if (operatorValue === 'isEmpty') return <TextInput disabled value="" onChange={() => {}} />;

		if (!Array.isArray(value) && typeof value === 'string') {
			if (type === 'boolean') {
				return (
					<Dropdown
						value={value || 'false'}
						height={40}
						onChange={(v) => {
							if (v) setValue(v);
							else setValue('false');
						}}
						options={[
							{ label: 'true', value: 'true' },
							{ label: 'false', value: 'false' },
						]}
						enablePortal
					/>
				);
			}
			if (type === 'int') {
				return <TextInput type="number" value={value} onChange={(v) => setValue(v.target.value)} />;
			}
			if (type === 'float') {
				return <TextInput type="number" value={value} step="0.01" onChange={(v) => setValue(v.target.value)} />;
			}
			if (
				(type === 'string' || type === 'workType' || type === 'workTypeDetails' || type === 'titleStatus') &&
				(operatorValue === 'equals' || operatorValue === 'doesNotEqual')
			) {
				if (valueOptions)
					return (
						<Dropdown
							withSearch
							value={value}
							height={40}
							onChange={(v) => {
								if (v) setValue(v);
							}}
							options={valueOptions}
							enablePortal
						/>
					);
				return <TextInput value={value} onChange={(v) => setValue(v.target.value)} />;
			}
			if (type === 'date' && !Array.isArray(value)) {
				return (
					<FlexCenterWrapper>
						{dateAuxiliary?.operator === 'theDate' ||
						dateAuxiliary?.operator === 'yesterday' ||
						dateAuxiliary?.operator === 'today' ||
						dateAuxiliary?.operator === 'tomorrow' ? (
							<AuxiliaryDropdown
								value={dateAuxiliary?.operator || ''}
								onChange={(v) => (setDateAuxiliary && v ? setDateAuxiliary({ ...dateAuxiliary, operator: v }) : null)}
								options={dateAuxiliaryOptions}
								enablePortal
								height={40}
							/>
						) : null}
						{dateAuxiliary?.operator === 'theDate' && (
							<DatePicker
								value={value ? new Date(value) : undefined}
								height={40}
								onChange={(date) => {
									if (date) {
										date.setHours(12);
										setValue(date.toISOString().slice(0, 10));
									} else {
										setValue('');
									}
								}}
							/>
						)}
						{dateAuxiliary?.operator === 'daysInThePast' || dateAuxiliary?.operator === 'daysInTheFuture' ? (
							<>
								<AuxiliaryNumber
									value={dateAuxiliary?.number?.toString() || '0'}
									min={0}
									onChange={(v) =>
										setDateAuxiliary
											? setDateAuxiliary({
													...dateAuxiliary,
													number: parseInt(v.target.value, 10),
											  })
											: null
									}
								/>
								<AuxiliaryDropdown
									value={dateAuxiliary?.operator || ''}
									onChange={(v) => (setDateAuxiliary && v ? setDateAuxiliary({ ...dateAuxiliary, operator: v }) : null)}
									options={dateAuxiliaryOptions}
									enablePortal
									height={40}
								/>
							</>
						) : null}
					</FlexCenterWrapper>
				);
			}
		}
		if (
			Array.isArray(value) &&
			type === 'string' &&
			(operatorValue === 'isAnyOf' || operatorValue === 'isNoneOf') &&
			valueOptions &&
			value
		) {
			const tmp = value as string[];
			return (
				<SelectDropdown
					values={tmp}
					height={40}
					width={selectDropdownWidth}
					onSelect={(v) => (tmp.includes(v) ? setValue(tmp.filter((item) => item !== v)) : setValue([...tmp, v]))}
					options={valueOptions}
					enablePortal
				/>
			);
		}

		if (type === 'array') {
			return (
				<DropdownV2
					getPopupContainer={() => document.body}
					mode="multiple"
					value={(value || []) as string[]}
					withSearch
					onChange={(v) => setValue(v)}
					height={40}
					options={valueOptions}
				/>
			);
		}

		if (type === 'countries-array') {
			return (
				<TerritoriesSelector
					getPopupContainer={() => document.body}
					multiple
					value={(value || []) as string[]}
					withSearch
					onChange={(v) => setValue(v)}
					height={40}
					options={valueOptions}
				/>
			);
		}

		return <TextInput value={value ? value.toString() : ''} onChange={(v) => setValue(v.target.value)} />;
	};

	return (
		<Wrapper onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
			<InputsWrapper ref={inputsWrapperEl}>
				<Dropdown
					withSearch
					value={fieldValue}
					onChange={(v) => setFieldValue(v)}
					options={fieldOptions}
					height={40}
					enablePortal
				/>
				<Dropdown
					value={operatorValue}
					onChange={(v) => setOperatorValue(v)}
					options={getOperatorOptions()}
					height={40}
					enablePortal
				/>
				{getInput()}
			</InputsWrapper>
			{hovered && (
				<FlexCenterWrapper>
					<DeleteButton onClick={onDelete}>
						<Icon path={mdiDelete} size="16px" />
					</DeleteButton>
				</FlexCenterWrapper>
			)}
		</Wrapper>
	);
}

export default FilterItem;
