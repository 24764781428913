import { updateValue as coreUpdateValue, OptionalNullInherited } from '@warehouse/title/core';
import { useCallback } from 'react';

interface UseUpdateValueProps<VALUE_TYPE, INPUT_TYPE = VALUE_TYPE> {
	value: OptionalNullInherited<VALUE_TYPE> | undefined;
	transform?: (value: INPUT_TYPE) => VALUE_TYPE;
}

export function useUpdateValue<VALUE_TYPE, INPUT_TYPE = VALUE_TYPE>({
	value,
	transform,
}: UseUpdateValueProps<VALUE_TYPE, INPUT_TYPE>) {
	const updateValue = useCallback(
		(newValue: INPUT_TYPE) => coreUpdateValue(newValue, value, { transform }),
		[transform, value],
	);

	return {
		updateValue,
	};
}
