import React, { ReactNode } from 'react';
import { SideModal } from '../../../../src/components/library/SideDrawer';

export function EditRelationshipDrawer({ open, onClose, children, header }: EditRelationshipDrawerProps) {
	return (
		<SideModal open={open} side="right" title={header} handleClose={onClose}>
			<SideModal.Content>{children}</SideModal.Content>
		</SideModal>
	);
}

interface EditRelationshipDrawerProps {
	open: boolean;
	onClose: () => void;
	children: ReactNode;
	header: string;
}
