import React, { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { lighten } from '@mui/material';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { INDENTATION_WIDTH } from '../../../views/profiles/editor/utilities';

const Wrapper = styled.div`
	${({ theme }) => css`
		align-items: stretch;
		display: flex;
		margin: ${theme.spacing(2)};
	`}
`;

const Spacer = styled.div<{
	connected: boolean;
	lastOfDepth?: boolean;
	noBorder?: boolean;
}>`
	// Uncomment for Spacer dev placement
	//border: 1px solid red;
	position: relative;
	width: ${INDENTATION_WIDTH}px;

	// Straight link
	${({ theme, noBorder }) =>
		!noBorder
			? css`
					&:after {
						border-left: 2px solid ${lighten(theme.palette.dark.main, 0.75)};
						bottom: 0;
						content: '';
						height: calc(100% + ${theme.spacing(2)});
						left: 45px;
						position: absolute;
						width: 13px;
					}
			  `
			: null}

	// Cutting at half if needed
	${({ theme, connected, lastOfDepth }) =>
		connected && lastOfDepth
			? css`
					&:after {
						bottom: calc(50%);
						height: calc(50% + ${theme.spacing(2)});
					}
			  `
			: null}
	
	// Curved link
	${({ theme, connected, noBorder }) =>
		connected && !noBorder
			? css`
					&:before {
						border-bottom: 2px solid ${lighten(theme.palette.dark.main, 0.75)};
						border-bottom-left-radius: 8px;

						border-left: 2px solid ${lighten(theme.palette.dark.main, 0.75)};
						content: '';
						height: 3px;
						position: absolute;
						right: 0;
						top: calc(50% - 1.5px);
						width: ${INDENTATION_WIDTH - 45 - 2}px;
					}
			  `
			: null}
`;

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) => !(isSorting || wasDragging);

function TreeItem({
	id,
	children,
	depth = 0,
	lastOfDepth,
	nextDepths,
}: {
	id: string;
	children: ReactNode;
	depth: number;
	lastOfDepth: boolean;
	nextDepths: number[];
}) {
	const getSpacers = () => {
		const res: ReactNode[] = [];
		for (let i = 1; i <= depth; i += 1) {
			const connected = i === depth;
			res.push(
				<Spacer
					key={i}
					className={`tree-spacer ${connected ? 'connected' : ''}`}
					connected={connected}
					lastOfDepth={lastOfDepth}
					noBorder={!nextDepths.includes(i) && i !== depth}
				/>,
			);
		}
		return res;
	};
	const spacers = getSpacers();

	const {
		attributes,
		// isDragging,
		// isSorting,
		listeners,
		setDraggableNodeRef,
		setDroppableNodeRef,
		transform,
		transition,
	} = useSortable({
		id,
		animateLayoutChanges,
	});
	const style: CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,
	};

	return (
		<Wrapper ref={setDroppableNodeRef}>
			{spacers}
			<div ref={setDraggableNodeRef} style={style} {...attributes} {...listeners}>
				{children}
			</div>
		</Wrapper>
	);
}

export default TreeItem;
