import { RelationshipType, CommonReadonly } from '@warehouse/title/core';
import { RelationshipTypeSelector } from '@warehouse/title/ui';
import { RelationshipsService } from '@warehouse/title/domain';
import React from 'react';
import { Typography } from '@mui/material';
import { TitleInfoWrapper } from './styles';

interface RelationshipModeRelationshipTypeSelectorContainerProps {
	parentTitle: CommonReadonly;
	selected?: RelationshipType;
	onSelect?: (relationshipType: RelationshipType) => void;
}

export function RelationshipModeRelationshipTypeSelectorContainer({
	parentTitle,
	selected,
	onSelect,
}: RelationshipModeRelationshipTypeSelectorContainerProps) {
	const supportedRelationshipTypes = RelationshipsService.getRelationshipTypesForParentWorkType(
		parentTitle.workType,
	).filter(
		(relationshipType) =>
			![RelationshipType.SeasonOf, RelationshipType.EpisodeOf, RelationshipType.DerivedFrom].includes(relationshipType),
	);

	return (
		<TitleInfoWrapper>
			<Typography variant="s2Medium" color="text.primary">
				Relationship Type* :
			</Typography>
			<RelationshipTypeSelector
				supportedRelationshipTypes={supportedRelationshipTypes}
				selected={selected}
				onSelect={onSelect}
			/>
		</TitleInfoWrapper>
	);
}
