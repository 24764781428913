import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// LIBRARIES
import Dropdown from '../../../../components/library/Dropdown/Dropdown';

// JSON
import availableRelationshipTypes from '../../../../assets/json-administration-profiles/availableRelationshipTypes.json';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface RelationshipTypeFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
}

function RelationshipTypeField({ control, errors, setValue, trigger, isRequired = false }: RelationshipTypeFieldProps) {
	const filteredRelationshipTypesOptions = availableRelationshipTypes.filter((type) =>
		[
			'isbasedon',
			'iscompositeof',
			'isdescendentof',
			'ispieceof',
			'ispromotionfor',
			'issupplementto',
			'isclipof',
		].includes(type.value),
	);

	const tooltip = useTooltip('coreMetadata.basic.parent.relationshipType');

	return useMemo(
		() => (
			<Controller
				name="relationshipType"
				control={control}
				render={({ field }) => (
					<div style={{ width: '300px' }}>
						<Dropdown
							withSearch
							enablePortal
							height={38}
							maxHeight={130}
							tooltip={tooltip?.tooltip}
							label={getLabelWithRequired('Relationship Type', isRequired)}
							error={isRequired ? (errors?.relationshipType?.message as string) : undefined}
							canReset
							options={filteredRelationshipTypesOptions}
							value={field.value}
							onChange={async (e) => {
								setValue('relationshipType', e);
								await trigger('parentTitle.title');
								if (isRequired) {
									await trigger('relationshipType');
								}
							}}
						/>
					</div>
				)}
			/>
		),
		[control, isRequired, errors?.relationshipType?.message, setValue, trigger],
	);
}

export default RelationshipTypeField;
