import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { TitleFull as Title } from '@warehouse/title/core';
import SelectDropdown from '../../../../components/library/SelectDropdown/SelectDropdown';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';
import LanguagesSpokenJson from '../../../../assets/json-administration-profiles/availableLanguageSpoken.json';

interface VersionLanguagesV2FieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	inheritedValues?: Title | undefined;
}

function VersionLanguagesV2Field({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	inheritedValues,
}: VersionLanguagesV2FieldProps) {
	const languagesSpoken = useMemo(
		() =>
			LanguagesSpokenJson.map((language) => ({
				value: language?.uuid,
				label: language?.label,
			})),
		[LanguagesSpokenJson],
	);
	const versionLanguagesTooltip = useTooltip('coreMetadata.basic.versionLanguages');

	const inheritedVersionLanguage = getTitleData<OptionalInherited<string[]>>(
		inheritedValues,
		fromBasic(['versionLanguages']),
	);

	return useMemo(
		() => (
			<Controller
				name="versionLanguages"
				control={control}
				render={({ field }) => (
					<SelectDropdown
						width={576}
						tooltip={versionLanguagesTooltip?.tooltip}
						placeholderArray={
							inheritedVersionLanguage?.displayValue?.length ? inheritedVersionLanguage?.displayValue : undefined
						}
						enablePortal
						options={languagesSpoken}
						values={field.value}
						error={errors?.versionLanguages?.message as string}
						onSelect={async (value) => {
							if (field.value.includes(value)) {
								setValue(
									'versionLanguages',
									field.value.filter((item: any) => item !== value),
								);
							} else {
								setValue('versionLanguages', [...field.value, value]);
							}
							if (isRequired && !inheritedVersionLanguage?.displayValue?.length) {
								await trigger('versionLanguages');
							}
						}}
						label={getLabelWithRequired('Version Languages', isRequired)}
					/>
				)}
			/>
		),
		[control, inheritedVersionLanguage?.displayValue, languagesSpoken, errors?.versionLanguages?.message, trigger],
	);
}

export default VersionLanguagesV2Field;
