import React, { useState, SyntheticEvent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiClose, mdiInformation, mdiMagnify } from '@mdi/js';
import { Typography } from '@mui/material';

import TextInput from '../library/TextInput';
import ContentLibTreeView, { Items } from './ContentLibTreeView';
import Button from '../library/Button';
import Tooltip from '../library/Tooltip';
import { TooltipIcon } from '../library/InputUtils';

interface ContentLibraryProps {
	data: Items[];
}

const ContentLibWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 ${theme.spacing(1)};
		width: 100%;
		& .input-wrapper {
			background-color: transparent;
			border-radius: 4px;
		}
	`,
);

const ContentLibTitleWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
	`,
);

const ContentLibTitle = styled((props) => <Typography {...props} variant="s2Medium" />)(
	({ theme }) => css`
		color: ${theme.palette.text.primary};
	`,
);

const StyledToolTipIcon = styled(TooltipIcon)(
	({ theme }) => css`
		color: ${theme.palette.text.secondary};
	`,
);

function ContentLibrary({ data }: ContentLibraryProps) {
	const [expanded, setExpanded] = useState<string[]>([]);
	const [selected, setSelected] = useState<string[]>([]);
	const [filteredData, setFilteredData] = useState<Items[]>([]);
	const [storeId, setStoreId] = useState<string[]>([]);
	const [searchInput, setSearchInput] = useState<string>('');

	const getAllIds = (nodes: Items[]) => {
		const ids: string[] = [];

		nodes.forEach((node) => {
			ids.push(node.id);

			if (node.children && node.children.length) {
				const childIds = getAllIds(node.children);
				ids.push(...childIds);
			}
		});

		return ids;
	};

	useEffect(() => {
		setFilteredData(() => data);
		setExpanded(() => getAllIds(data));
	}, [data]);

	const isNodeMatch = (node: Items, searchText: string): boolean =>
		node.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;

	/* Look for a matching node or if children exists and one of them match */
	const findNode = (node: Items, searchText: string, isMatch = isNodeMatch): boolean =>
		isMatch(node, searchText) ||
		((node.children &&
			node.children.length &&
			!!node.children.find((child) => findNode(child, searchText, isMatch))) as boolean);

	const filterTree = (node: Items, searchText: string, isMatch = isNodeMatch) => {
		if (isMatch(node, searchText) || !node.children) {
			return node;
		}

		const filteredChildren: Items[] = node.children
			.filter((child) => findNode(child, searchText, isMatch))
			.map((child) => filterTree(child, searchText, isMatch));
		return { ...node, children: filteredChildren };
	};

	const expandFilteredNodes = (
		node: Items,
		searchText: string,
		isMatch = isNodeMatch,
	): Items & { toggled: boolean } => {
		let { children } = node;

		if (!children || children.length === 0) {
			return { ...node, toggled: false };
		}

		const childrenWithMatches = children.filter((child) => findNode(child, searchText, isMatch));

		const shouldExpand = childrenWithMatches.length > 0;

		if (shouldExpand) {
			children = childrenWithMatches.map((child) => expandFilteredNodes(child, searchText, isMatch));
		}
		return { ...node, children, toggled: shouldExpand };
	};
	const getIdToExpandFromFilter = (node: Items): string[] => {
		const { children } = node;
		if (!children || children.length === 0) {
			return storeId;
		}
		if (node.root) {
			setStoreId([node.id]);
		}
		if (children) {
			children.map((child) => setStoreId((prev) => [...prev, child.id]));
		}
		node?.children?.map((child) => getIdToExpandFromFilter(child));
		return storeId;
	};

	const onFilterChange = (searchText: string) => {
		const filter = searchText?.trim();
		let expandedTmp: string[] = expanded;
		if (!filter) {
			setFilteredData(() => data);
			setExpanded(() => getAllIds(data));
			return;
		}
		let filtered: Items[] = [];
		data.forEach((node) => {
			const filteredNode = filterTree(node, filter);
			if (filteredNode) {
				filtered.push(filteredNode);
			}
		});

		filtered = filtered.map((node) => expandFilteredNodes(node, filter));

		if (filtered) {
			filtered.forEach((node) => {
				if (node.children) {
					expandedTmp = [];
					expandedTmp.push(...getIdToExpandFromFilter(node));
				}
			});
			setExpanded(expandedTmp);
			setFilteredData(filtered);
		}
	};

	useEffect(() => {
		onFilterChange(searchInput);
	}, [searchInput]);

	const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
		setExpanded(nodeIds);
	};

	const handleSelect = (event: SyntheticEvent, nodeIds: string[]) => {
		setSelected(nodeIds);
	};

	return (
		<ContentLibWrapper>
			<ContentLibTitleWrapper>
				<ContentLibTitle>Content Library</ContentLibTitle>
				<Tooltip title="Content Library" placement="right">
					<StyledToolTipIcon path={mdiInformation} size="12px" />
				</Tooltip>
			</ContentLibTitleWrapper>
			<TextInput
				height={33}
				startIcon={<Icon path={mdiMagnify} size={0.6} />}
				endIcon={
					<Button $disablePadding nxstyle="tertiary-light" onClick={() => setSearchInput('')}>
						<Icon path={mdiClose} size={0.5} />
					</Button>
				}
				placeholder="Search"
				value={searchInput}
				onChange={(e) => {
					setSearchInput(e.target.value);
				}}
			/>
			<ContentLibTreeView
				data={filteredData}
				expanded={expanded}
				selected={selected}
				handleNodeToggle={handleToggle}
				handleNodeSelect={handleSelect}
			/>
		</ContentLibWrapper>
	);
}

export default ContentLibrary;
