import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull } from '@warehouse/title/core';
import WorkTypeDetailField from '../fields/WorkTypeDetail';
import DisplayTitleField from '../fields/DisplayTitle';
import { ParentTitleCard } from '../fields/ParentTitleCard';

interface MovieProps {
	workType: string;
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	selectedParentTitle?: TitleFull;
}

function Movie({ workType, control, errors, setValue, trigger, selectedParentTitle }: MovieProps) {
	return (
		<div>
			<WorkTypeDetailField
				workType={workType}
				control={control}
				setValue={setValue}
				trigger={trigger}
				errors={errors}
				isRequired
			/>
			<DisplayTitleField control={control} setValue={setValue} errors={errors} trigger={trigger} isRequired />
			<ParentTitleCard selectedParentTitle={selectedParentTitle} />
		</div>
	);
}

export default Movie;
