import { ColumnSort } from '@warehouse/title/core';

export class RelationshipSorters {
	static byTitleSearchSortFull(desc: boolean = false): ColumnSort {
		return { id: 'indexed.titleSearchSortFull', desc };
	}

	static byEntryNumberFromParentCompilation(compilationUuid: string, desc: boolean = false): ColumnSort {
		return {
			id: 'indexed.partOfCompilations.compilationEntryNumber',
			desc,
			nestedFilter: JSON.stringify({
				nested: 'indexed.partOfCompilations',
				step: {
					combinationOperator: 'AND',
					filters: [
						{
							field: 'indexed.partOfCompilations.compilationId',
							operator: 'equals',
							value: compilationUuid,
						},
					],
				},
			}),
		};
	}

	static byRelationshipTypeFromParentRelationship(desc: boolean = false): ColumnSort {
		return {
			id: 'relationship.type',
			desc,
		};
	}

	static bySequenceNumberFromParentRelationship(desc: boolean = false): ColumnSort {
		return {
			id: 'relationship.sequenceInfo.number',
			desc,
		};
	}
}
