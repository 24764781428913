import { TitleFull as Title } from '@warehouse/title/core';
import { LocalizedInfo } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';
import getDeepProperty, { JsonPointer } from './getDeepProperty';

export function fromBasic(jsonPointer: JsonPointer): JsonPointer {
	return ['metadata', 'coreMetadata', 'basic', ...jsonPointer];
}

export function findDefaultUuidForKey(
	title: Title,
	key: 'countriesOfOrigin' | 'localizedInfos' | 'originalLanguages' | 'parents',
) {
	const jsonPointer = fromBasic([key]);
	const record = getDeepProperty<any>(title, jsonPointer);

	if (!record) return undefined;

	const foundKey = Array.from(Object.keys(record)).find((k: keyof any) => {
		if (key === 'localizedInfos') {
			return record[k]?.default?.displayValue;
		}

		return record[k]?.default;
	});

	if (foundKey) {
		return foundKey;
	}

	return undefined;
}

export function findDefaultLocaleUuidForTitle(title: Title) {
	const jsonPointer = fromBasic(['localizedInfos']);
	const record = getDeepProperty<Record<string, LocalizedInfo>>(title, jsonPointer);

	if (!record) return undefined;

	const defaultLocalizedInfo = Object.values(record).find((localizedInfo) => localizedInfo.default.displayValue);
	return defaultLocalizedInfo?.language?.displayValue;
}

/* value with default can be one of them :
 * - localizedInfos
 * - originalLanguages
 * - countiesOfOrigin
 * - parents
 */
export function mapPathDefaultAndFirstToUuid(path: string, title: Title) {
	const split = path.split('.');
	let hasFailedToFind = false;

	const defaultIndexes = split.map((s, idx) => (s === '__default' ? idx : -1)).filter((i) => i !== -1);

	defaultIndexes.forEach((defaultIndex: number) => {
		if (defaultIndex > 0) {
			const labelDefaultOf = split[defaultIndex - 1] as
				| 'localizedInfos'
				| 'parents'
				| 'countriesOfOrigin'
				| 'originalLanguages';

			if (!['localizedInfos', 'originalLanguages', 'countriesOfOrigin', 'parents'].includes(labelDefaultOf)) {
				hasFailedToFind = true;
				return;
			}

			const defaultUuid = findDefaultUuidForKey(title, labelDefaultOf);

			if (!defaultUuid) {
				hasFailedToFind = true;
				return;
			}

			split[defaultIndex] = defaultUuid;
		}
	});

	if (hasFailedToFind) return undefined;

	const firstIndexes = split.map((s, idx) => (s === '__first' ? idx : -1)).filter((i) => i !== -1);

	firstIndexes.forEach((firstIndex: number) => {
		const record = getDeepProperty<any>(title, split.slice(0, firstIndex));

		if (!record) {
			hasFailedToFind = true;
			return;
		}

		const key = Object.keys(record || {})[0];

		if (!key) {
			hasFailedToFind = true;
			return;
		}

		split[firstIndex] = key;
	});

	if (hasFailedToFind) return undefined;

	return split.join('.');
}

export function getTitleData<T>(title: Title | undefined, jsonPointer: JsonPointer): T | undefined {
	if (!title) return undefined;
	const pathWithUuids = mapPathDefaultAndFirstToUuid(jsonPointer.join('.'), title)?.split('.');

	if (!pathWithUuids) return undefined;

	return getDeepProperty<T>(title, pathWithUuids);
}
