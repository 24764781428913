import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { resetInheritedArrayValues, isInherited } from '@warehouse/title/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import ChipDisplay from '../../../../src/components/library/ChipDisplay';
import TagInput from '../../../../src/components/library/TagInput';
import { ChipDisplayWrapper } from '../../../../src/views/title/tab/productMetadata/style';

interface TitleChipEditableFieldProps {
	label: string;
	path: string;
	ariaLabel?: string;
	style?: React.CSSProperties;
}

function getVariant(value: OptionalInherited<string[]> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<string[]>({ inheritedObject: value }).isInherited ? 'prefilled' : 'default';
}

export function TitleChipEditableField({ label, path, style, ariaLabel }: TitleChipEditableFieldProps) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { readOnlyValue, setValue, value, commit } = useTitleAutoSave<OptionalInherited<string[]>>({
		path: useMemo(() => path.split('.'), [path]),
		label,
	});

	return (
		<TitleEditableField
			wrapperStyle={style}
			label={label}
			path={path}
			direction="column"
			readComponent={
				<ChipDisplayWrapper>
					<ChipDisplay variant={getVariant(value)} values={readOnlyValue?.displayValue || []} width={400} height={26} />
				</ChipDisplayWrapper>
			}
			editComponent={
				<TagInput
					ariaLabel={ariaLabel}
					height={38}
					values={value?.displayValue ?? []}
					onChange={(v) => {
						setValue(resetInheritedArrayValues<string>(v, title));
					}}
					disabledMargin
					variant={getVariant(value)}
					onBlur={() => commit()}
					onTagDelete={() => commit()}
				/>
			}
		/>
	);
}
