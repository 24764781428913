import { EditPreventerRepository } from './EditPreventer.repository';
import { EditPreventerVariant } from './EditPreventerVariant';

export class LocalStorageEditPreventerRepository implements EditPreventerRepository {
	private readonly STORAGE_KEY_PREFIX = 'edit-preventer-popup-disabled';

	private getStorageKey(variant: EditPreventerVariant): string {
		return `${this.STORAGE_KEY_PREFIX}-${variant}`;
	}

	async disablePopup(variant: EditPreventerVariant): Promise<void> {
		localStorage.setItem(this.getStorageKey(variant), 'true');
	}

	async getPopupDisableStatus(variant: EditPreventerVariant): Promise<boolean> {
		return localStorage.getItem(this.getStorageKey(variant)) === 'true';
	}
}
