import React from 'react';
import { AlternateTitlesTableWrapper } from './AlternateTitlesTableWrapper';
import { AlternateTitleTypePicklistContextProvider } from './AlternateTitleContext';

type AlternateTitlesTableProps = {
	localizedInfoUuid: string;
	defaultLocale: string | undefined;
};

export function AlternateTitlesTable({ localizedInfoUuid, defaultLocale }: AlternateTitlesTableProps) {
	return (
		<AlternateTitleTypePicklistContextProvider>
			<AlternateTitlesTableWrapper defaultLocale={defaultLocale} localizedInfoUuid={localizedInfoUuid} />
		</AlternateTitleTypePicklistContextProvider>
	);
}
