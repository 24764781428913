import deepEquality from '../../../src/utils/deepEquality';
import { hasInheritedStructure } from '../../../src/views/title/hooks/utils';

export function deepEqualityWithOrWithoutInheritance(prev: any, curr: any): boolean {
	if (hasInheritedStructure(prev) && hasInheritedStructure(curr)) {
		const prevValue = prev?.displayValue === '' ? undefined : prev?.displayValue;
		const currValue = curr?.displayValue === '' ? undefined : curr?.displayValue;
		return deepEquality(prevValue, currValue);
	}
	return deepEquality(prev, curr);
}
