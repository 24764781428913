import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Genre } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';
import { isInherited, resetInheritedArrayValues, titlePaths } from '@warehouse/title/core';
import React, { useMemo } from 'react';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import genresJSON from '../../../../src/assets/json-administration-profiles/availableGenres.json';
import sortAlphabeticallyByLabel from '../../../../src/utils/sortAlphabeticallyByLabel';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import { ChipDisplayWrapper } from '../../../../src/views/title/tab/productMetadata/style';
import ChipDisplay from '../../../../src/components/library/ChipDisplay';
import SelectDropdown from '../../../../src/components/library/SelectDropdown/SelectDropdown';

export function GenresField({ localizedInfoUuid }: { localizedInfoUuid: string }) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const genres = useTitleAutoSave<OptionalInherited<Genre[]>>({
		label: 'Genres',
		path: useMemo(() => titlePaths.genres(localizedInfoUuid).split('.'), [localizedInfoUuid]),
	});

	const genresData = genresJSON.sort(sortAlphabeticallyByLabel);

	const InheritedResult = isInherited({ inheritedObject: genres.value });

	return (
		<TitleEditableField
			label="Genres"
			path={titlePaths.genres(localizedInfoUuid)}
			direction="column"
			readComponent={
				<ChipDisplayWrapper>
					<ChipDisplay
						values={
							(genres?.readOnlyValue?.displayValue
								?.sort((a, b) => (a.level ?? 0) - (b.level ?? 0))
								.map((i) => genresData.find((g) => i.id === g.uuid)?.label) as string[]) || []
						}
						width={400}
						height={26}
						variant={InheritedResult.isInherited ? 'prefilled' : 'default'}
					/>
				</ChipDisplayWrapper>
			}
			editComponent={
				<SelectDropdown
					ariaLabel="Genres"
					disabledMargin
					chipStyle="default"
					enablePortal
					variant={InheritedResult.isInherited ? 'prefilled' : 'default'}
					onBlur={() => genres?.commit()}
					onSelect={(selectedValue) => {
						const selectedItem = genresData.find((i) => i.uuid === selectedValue);
						const existingItems = genres?.value?.displayValue || [];
						const itemExists = existingItems.some((item) => item.id === selectedItem?.uuid);

						if (itemExists) {
							const updatedItems = existingItems
								.filter((item) => item.id !== selectedItem?.uuid)
								.map((item, index) => ({ ...item, level: index + 1 }));
							genres?.setValue(resetInheritedArrayValues<Genre>(updatedItems, title));
						} else {
							const newLevel = existingItems.reduce((max, item) => Math.max(max, item.level ?? 0), 0) + 1;
							const newItem = {
								genre: selectedItem?.uuid as string,
								id: selectedItem?.uuid as string,
								source: 'nexspec.com/warehouse',
								level: newLevel,
							};
							genres?.setValue((prev) => ({
								...prev,
								displayValue: [...existingItems, newItem],
							}));
						}
					}}
					options={
						genresData?.map((i) => ({
							value: i.uuid,
							label: i.label,
						})) || []
					}
					values={(genres?.value?.displayValue?.map((i) => i.id) as string[]) || []}
					height={36}
				/>
			}
		/>
	);
}
