import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { SEARCH_TRACKS_NAME, SearchInput, SearchTracksOutput } from '@warehouse/graphql';

interface UseSearchTracksNameProps {
	searchValue?: string;
	pollInterval: number;
	perPage?: number;
	page?: number;
}

function useSearchTracksName({ pollInterval, searchValue, perPage = 1000, page = 1 }: UseSearchTracksNameProps) {
	const { loading, error, data, startPolling, refetch, fetchMore } = useQuery<
		{
			searchTitleEntities: SearchTracksOutput;
		},
		{
			search: SearchInput;
		}
	>(SEARCH_TRACKS_NAME, {
		variables: {
			search: {
				filters: JSON.stringify({
					step: {
						combinationOperator: 'AND',
						filters: [
							...(searchValue
								? [
										{
											field: 'name',
											operator: 'contains' as keyof FilterOperators,
											value: searchValue,
										},
								  ]
								: []),
						],
					},
				}),
				pagination: {
					page,
					perPage,
				},
			},
		},
	});

	// Fire search after search input or filtering
	useEffect(() => {
		refetch({
			search: {
				pagination: { page, perPage },
				filters: JSON.stringify({
					step: {
						combinationOperator: 'AND',
						filters: [
							...(searchValue
								? [
										{
											field: 'name',
											operator: 'contains' as keyof FilterOperators,
											value: searchValue,
										},
								  ]
								: []),
						],
					},
				}),
			},
		});
	}, [page, perPage, searchValue]);

	// Query polling
	useEffect(() => {
		startPolling(pollInterval);
	}, [startPolling]);

	return {
		loading,
		error,
		data,
		refetch,
		fetchMore,
	};
}

export default useSearchTracksName;
