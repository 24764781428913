import React, { ChangeEvent, useEffect, useState } from 'react';
import { CellContext } from '@tanstack/react-table';

// TYPES
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Rating, RatingDescription } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';

// HOOKS
import { isInherited } from '@warehouse/title/core';
import { WithUuid } from '../../../../hooks/useTitleRecordsAutoSave/recordToArray';
import { TableTextInput } from '../../style';

// UTILS
import deepEquality from '../../../../../../utils/deepEquality';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

function CellDescription(info: CellContext<WithUuid<Inherited<Rating>>, WithUuid<Inherited<Rating>>>) {
	const { table, getValue } = info;
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;
	const defaultLocale = table.options?.meta?.customPropsCell?.defaultLocale;
	const [localState, setLocaleState] = useState<RatingDescription[]>(getValue()?.displayValue?.descriptions || []);

	useEffect(() => {
		setLocaleState(getValue()?.displayValue?.descriptions || []);
	}, [getValue()]);

	const handleOnBlur = () => {
		const realDescriptions = getValue()?.displayValue?.descriptions || [];
		if (!deepEquality(localState, realDescriptions)) {
			editRecord({
				...getValue(),
				displayValue: {
					...getValue()?.displayValue,
					descriptions: localState,
				},
			});
		}
	};

	const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const newDescription = evt.target.value;
		setLocaleState((prevState) => {
			const newState = [...prevState];
			const index = newState.findIndex((desc) => desc.language === defaultLocale || desc.language === undefined);
			if (index !== -1 && newState[index].description !== newDescription) {
				newState[index] = { ...newState[index], description: newDescription };
			} else if (index === -1) {
				newState.push({
					description: newDescription,
					language: defaultLocale || undefined,
				});
			}
			return newState;
		});
	};

	const defaultLocaleDescription = localState?.find((desc) => desc.language === defaultLocale);

	const undefinedLocaleDescription = localState?.find((desc) => desc.language === undefined);

	return (
		<CellWrapper>
			<TableTextInput
				variant={isInherited({ inheritedObject: getValue() }).isInherited ? 'prefilled' : 'default'}
				value={defaultLocaleDescription?.description || undefinedLocaleDescription?.description || ''}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
			/>
		</CellWrapper>
	);
}

export default CellDescription;
