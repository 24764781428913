import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Row = styled.div(
	({ theme }) => css`
		& > div {
			width: 100%;
		}

		align-items: center;
		display: flex;
		flex-direction: row;
		gap: ${theme.spacing(2)};
	`,
);
