import React, { ReactNode, createContext, useContext, useReducer } from 'react';

type EditorContextState = {
	collapsedItems: string[];
	selected: string | null;
};

type EditorContextActionSelectId = {
	type: 'toggle' | 'collapse' | 'expand' | 'select';
	id: string;
};

const initialState: EditorContextState = {
	collapsedItems: [],
	selected: null,
};

const EditorContext = createContext<EditorContextState | null>(null);

const EditorDispatchContext = createContext<React.Dispatch<EditorContextActionSelectId> | null>(null);

function tasksReducer(state: EditorContextState, action: EditorContextActionSelectId) {
	switch (action.type) {
		case 'collapse': {
			return {
				...state,
				collapsedItems: state.collapsedItems.includes(action.id)
					? state.collapsedItems
					: [...state.collapsedItems, action.id],
				selected: null,
			};
		}
		case 'expand': {
			return {
				...state,
				collapsedItems: !state.collapsedItems.includes(action.id)
					? state.collapsedItems
					: [...state.collapsedItems].filter((i) => i !== action.id),
				selected: null,
			};
		}
		case 'toggle': {
			return {
				...state,
				collapsedItems: !state.collapsedItems.includes(action.id)
					? [...state.collapsedItems, action.id]
					: [...state.collapsedItems].filter((i) => i !== action.id),
				selected: null,
			};
		}
		case 'select': {
			return {
				...state,
				selected: state.selected !== action.id ? action.id : null,
			};
		}
		default: {
			throw Error(`Unknown action: ${action.type}`);
		}
	}
}

export function EditorProvider({ children }: { children: ReactNode }) {
	const [editorContext, dispatch] = useReducer(tasksReducer, initialState);

	return (
		<EditorContext.Provider value={editorContext}>
			<EditorDispatchContext.Provider value={dispatch}>{children}</EditorDispatchContext.Provider>
		</EditorContext.Provider>
	);
}

export function useEditorContext() {
	return useContext(EditorContext);
}

export function useEditorDispatch() {
	return useContext(EditorDispatchContext);
}
