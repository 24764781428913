import { useEffect } from 'react';

export function useURLQuery() {
	return new URLSearchParams(window.location.search);
}

function queryParamsToString(query: URLSearchParams) {
	return query.size > 0 ? `?${query.toString()}` : '';
}

export const encodeString = (toEncode: string) => btoa(toEncode);

export const decodeString = (encoded: string) => {
	try {
		return atob(encoded);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
	}
	return undefined;
};

export const setQuery = (label: string, encoded: string) => {
	const query = useURLQuery();

	query.set(label, encoded);
	window.history.replaceState(
		{},
		'',
		`${window.location.pathname}${queryParamsToString(query)}${window.location.hash}`,
	);
};

export const deleteQuery = (label: string) => {
	const query = useURLQuery();

	query.delete(label);
	window.history.replaceState(
		window.history.state,
		'',
		`${window.location.pathname}${queryParamsToString(query)}${window.location.hash}`,
	);
};

export const useStateToURLSync = <T>(value: T, initialState: T, urlKey: string) => {
	useEffect(() => {
		const stringified = JSON.stringify(value);
		if (stringified !== JSON.stringify(initialState)) {
			const enc = encodeString(stringified);
			setQuery(urlKey, enc);
		} else deleteQuery(urlKey);
	}, [value]);
};

export const useURLToStateSync = <T>(urlKey: string, callbackFn: (value: T | undefined) => void) => {
	useEffect(() => {
		const query = useURLQuery();

		const urlValue = query.get(urlKey);
		if (urlValue) {
			try {
				const decoded: T = JSON.parse(decodeString(urlValue) || '');
				callbackFn(decoded);
			} catch (err) {
				callbackFn(undefined);
			}
		}
	}, []);
};
