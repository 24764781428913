import { createTheme } from '@mui/material/styles';

export const getTheme = () => ({
	palette: {
		text: {
			primary: 'rgba(0, 0, 0, 0.8)',
			secondary: 'rgba(0, 0, 0, 0.5)',
			reverseMain: '#ffffff',
			reverseSecondary: 'rgba(255, 255, 255, 0.5)',
		},
		action: {
			active: 'rgba(0, 0, 0, 0.8)',
			placeholder: 'rgba(0, 0, 0, 0.5)',
			disabled: 'rgba(0, 0, 0, 0.3)',
			divider: 'rgba(0, 0, 0, 0.1)',
		},
		error: {
			main: '#FF5252',
			background: '#FFDCDC',
			text: '#8C0000',
		},
		warning: {
			main: '#FBB500',
			background: '#FEF0CC',
			text: '#8C5E00',
		},
		info: {
			main: '#2196F3',
			background: '#D3EAFD',
			text: '#004073',
		},
		success: {
			main: '#4CAF50',
			background: '#DBEFDC',
			text: '#004D03',
		},
		primary: {
			main: '#F27E30',
			orange: '#FEF3EB',
		},
		secondary: {
			main: '#C64C1C',
		},
		light: {
			main: '#FFFFFF',
			background: '#F6F8FA',
			backgroundAlt: '#DDE0E3',
		},
		dark: {
			main: '#000000',
			black: '#1B1B1B',
			background: '#4B4D4F',
			backgroundAlt: '#6A6E72',
		},
		blue: {
			main: '#2196F3',
			background: '#D3EAFD',
			text: '#004073',
		},
		red: {
			main: '#FF5252',
			background: '#FFDCDC',
			text: '#8C0000',
		},
		green: {
			main: '#4CAF50',
			background: '#DBEFDC',
			text: '#004D03',
		},
		yellow: {
			main: '#FEF0CC',
			background: '#FBB500',
			text: '#8C5E00',
		},
		black: {
			main: '#000000',
		},
		white: {
			main: '#ffffff',
		},
	},
	typography: {
		fontSize: 14,
		h1Bold: {
			fontSize: '48px',
			fontWeight: 700,
			lineHeight: '120%',
		},
		h1Regular: {
			fontSize: '48px',
			fontWeight: 400,
			lineHeight: '120%',
			letterSpacing: '-1%',
		},
		h2Bold: {
			fontSize: '34px',
			fontWeight: 700,
			lineHeight: '120%',
		},
		h2Regular: {
			fontSize: '34px',
			fontWeight: 400,
			lineHeight: '120%',
			letterSpacing: '-1%',
		},
		h3Bold: {
			fontSize: '30px',
			fontWeight: 700,
			lineHeight: '120%',
		},
		h3Regular: {
			fontSize: '30px',
			fontWeight: 400,
			lineHeight: '120%',
			letterSpacing: '-1%',
		},
		h4Bold: {
			fontSize: '24px',
			fontWeight: 700,
			lineHeight: '120%',
		},
		h4Regular: {
			fontSize: '24px',
			fontWeight: 400,
			lineHeight: '120%',
			letterSpacing: '-1%',
		},
		s1Medium: {
			fontSize: '20px',
			fontWeight: 500,
			lineHeight: '120%',
		},
		s1Regular: {
			fontSize: '20px',
			fontWeight: 400,
			lineHeight: '120%',
		},
		s2Medium: {
			fontSize: '18px',
			fontWeight: 500,
			lineHeight: '120%',
		},
		s2Regular: {
			fontSize: '18px',
			fontWeight: 400,
			lineHeight: '120%',
		},
		bodyLargeBold: {
			fontSize: '16px',
			fontWeight: 700,
			lineHeight: '120%',
			letterSpacing: '2%',
		},
		bodyLargeRegular: {
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: '120%',
			letterSpacing: '1%',
		},
		bodyMediumBold: {
			fontSize: '14px',
			fontWeight: 700,
			lineHeight: '120%',
			letterSpacing: '2%',
		},
		bodyMediumRegular: {
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '120%',
			letterSpacing: '1%',
		},
		bodySmallBold: {
			fontSize: '12px',
			fontWeight: 700,
			lineHeight: '120%',
			letterSpacing: '2%',
		},
		bodySmallRegular: {
			fontSize: '12px',
			fontWeight: 400,
			lineHeight: '120%',
			letterSpacing: '1%',
		},
		buttonLargeMedium: {
			fontSize: '16px',
			fontWeight: 500,
			lineHeight: '120%',
			letterSpacing: '1%',
		},
		buttonLargeRegular: {
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: '120%',
		},
		buttonMediumMedium: {
			fontSize: '14px',
			letterSpacing: '1%',
			fontWeight: 500,
			lineHeight: '120%',
		},
		buttonMediumRegular: {
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '120%',
		},
		buttonSmallMedium: {
			fontSize: '12px',
			fontWeight: 500,
			letterSpacing: '1%',
			lineHeight: '120%',
		},
		buttonSmallRegular: {
			fontSize: '12px',
			fontWeight: 400,
			lineHeight: '120%',
		},
		inherited: {
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '120%',
			fontStyle: 'italic',
		},
		tableCell: {
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '120%',
		},
	},
});

export const theme = createTheme(getTheme());

export type Theme = typeof theme;
