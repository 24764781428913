import React, { useCallback, useState } from 'react';
import { AlertColor } from '@mui/material';
import Snackbar from '../../components/library/Snackbar';

type SnackbarMessage = {
	title?: string;
	message: string;
	severity?: AlertColor;
};

function useSnackbar() {
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState<SnackbarMessage | undefined>();

	return {
		setMessage: useCallback((title: string | undefined, message: string, severity?: AlertColor) => {
			setValue({ title, message, severity });
			setOpen(true);
		}, []),
		snackbar: useCallback(
			() => (
				<Snackbar
					open={open}
					onClose={() => setOpen(false)}
					title={value?.title}
					message={value?.message ?? ''}
					severity={value?.severity ?? 'info'}
				/>
			),
			[open, value?.title, value?.message, value?.severity],
		),
	};
}

export default useSnackbar;
