import React, { useCallback, useMemo } from 'react';
import { useTheme, Portal } from '@mui/material';
import { TitleFull as Title } from '@warehouse/title/core';
import { Rating } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Basic';

// HOOKS
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { useURLFragment } from '../../../../../utils/hooks/useURLFragment';
import useSnackbar from '../../../../../utils/hooks/useSnackbar';
import useMemoJsonPath from '../../../hooks/useMemoJsonPath';
import useTitleRecordsAutoSave from '../../../hooks/useTitleRecordsAutoSave';

// LIBRARY
import { BottomRow, Column } from '../style';
import RatingsTable from './RatingsTable/index';
import ReleasesTable from './ReleasesTable';
import { FullWidthHeightWrapper } from '../../../../../components/common';
import { Field, TopRow } from '../localizedInfo';
import { TerritoriesSelector } from '../../../../../components/titles/TerritoriesSelector';
import { fromBasic } from '../../../../../utils/titleGetProperty';
import { filterSelectedTerritory } from './RatingsTable/utils';
import getDefaultLocale from '../../../utils/getDefaultLocale';

const regionalizedInfoFragmentStartWith = 'product-metadata/ratings-releases/';

interface ProductMetadataTabTerritoriesTabProps {
	editMode: boolean;
	titleEntity: Title | undefined;
}

function ProductMetadataTabTerritoriesTab({ editMode, titleEntity }: ProductMetadataTabTerritoriesTabProps) {
	const theme = useTheme();
	const [fragment, setFragment] = useURLFragment();
	const snackbar = useSnackbar();
	const defaultLocale = getDefaultLocale(titleEntity);

	const ratings = useTitleRecordsAutoSave<Inherited<Rating>>({
		label: 'Ratings',
		path: useMemoJsonPath(fromBasic(['ratingSet', 'ratings'])),
		isValidRow: (rating: Inherited<Rating>) => {
			const value = rating.displayValue;
			return !!((value.region.countryRegion || value.region.country) && value.system && value.value);
		},
	});

	const selectedTerritory = useMemo(() => {
		if (!fragment.startsWith(regionalizedInfoFragmentStartWith)) return 'default';
		return fragment.slice(regionalizedInfoFragmentStartWith.length);
	}, [fragment]);

	const memoizedRatings = useMemo(
		() => filterSelectedTerritory(ratings.data, selectedTerritory),
		[ratings.data, selectedTerritory],
	);

	const memoizedReadonlyRatings = useMemo(
		() => filterSelectedTerritory(ratings.readOnlyValue, selectedTerritory),
		[ratings.readOnlyValue, selectedTerritory],
	);

	const ratingTable = useMemo(
		() => (
			<RatingsTable
				defaultLocale={defaultLocale || ''}
				editMode={editMode}
				ratings={memoizedRatings}
				readOnlyValue={memoizedReadonlyRatings}
				selectedTerritory={selectedTerritory}
				ratingsAutoSave={ratings}
				origin=""
			/>
		),
		[editMode, defaultLocale, ratings, memoizedRatings, selectedTerritory],
	);

	const releasesTable = useMemo(
		() => <ReleasesTable selectedTerritoryUuid={selectedTerritory} />,
		[editMode, selectedTerritory],
	);

	const handleDropdownKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>) => {
			if (e.key === 'Escape' && selectedTerritory !== 'default')
				setFragment('product-metadata/ratings-releases/default');
		},
		[selectedTerritory, setFragment],
	);

	return (
		<FullWidthHeightWrapper style={{ flexDirection: 'column' }}>
			<Portal container={document.body}>{snackbar.snackbar()}</Portal>
			<TopRow>
				<Field rightBorder>
					<TerritoriesSelector
						disabledMargin
						height={36}
						listItemHeight={36}
						width={300}
						backgroundColor={theme.palette.light.main}
						showAllOptions
						value={selectedTerritory ?? 'default'}
						onChange={(selected) => setFragment(`${regionalizedInfoFragmentStartWith}${selected}`)}
						onKeyDown={handleDropdownKeyDown}
					/>
				</Field>
			</TopRow>
			<BottomRow withColumnFlex={false}>
				<Column width="60%">{ratingTable}</Column>
				<Column width="40%">{releasesTable}</Column>
			</BottomRow>
		</FullWidthHeightWrapper>
	);
}

export default ProductMetadataTabTerritoriesTab;
