export const titlePaths = {
	threeD: 'metadata.coreMetadata.basic.threeD',
	securityTitle: 'metadata.coreMetadata.basic.titleSecurity.titleSecurity',
	abbreviatedTitle: 'metadata.coreMetadata.basic.terms.titleAbbreviated',
	titleDisplayUnlimited: (localizedInfoUuid: string) =>
		`metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.titleDisplayUnlimited`,
	titleSort: (localizedInfoUuid: string) => `metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.titleSort`,
	originalTitle: (localizedInfoUuid: string) =>
		`metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.originalTitle`,
	copyrightLine: (localizedInfoUuid: string) =>
		`metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.copyrightLine`,
	titleStatus: 'metadata.coreMetadata.basic.terms.titleStatus',
	summary190: (localizedInfoUuid: string) =>
		`metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.summary190`,
	summary400: (localizedInfoUuid: string) =>
		`metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.summary400`,
	summary4000: (localizedInfoUuid: string) =>
		`metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.summary4000`,
	genres: (localizedInfoUuid: string) => `metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.genres`,
	keywords: (localizedInfoUuid: string) => `metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.keywords`,
	alternateTitles: (localizedInfoUuid: string) =>
		`metadata.coreMetadata.basic.localizedInfos.${localizedInfoUuid}.titleAlternates`,
	defaultLocalizedInfo: (defaultLocalizedInfoUuid: string) =>
		`metadata.coreMetadata.basic.localizedInfos.${defaultLocalizedInfoUuid}.default`,
	editClasses: 'metadata.coreMetadata.basic.versionIntent.editClasses',
	pictureColorType: 'metadata.coreMetadata.basic.pictureColorType',
	pictureFormat: 'metadata.coreMetadata.basic.pictureFormat',
	aspectRatio: 'metadata.coreMetadata.basic.aspectRatio',
	frameRate: 'metadata.coreMetadata.basic.frameRate',
	editUse: 'metadata.coreMetadata.basic.versionIntent.editUse',
	releaseDate: 'metadata.coreMetadata.basic.releaseDate',
	countriesOfOrigin: 'metadata.coreMetadata.basic.countriesOfOrigin',
	titleSecurity: 'metadata.coreMetadata.basic.titleSecurity',
	runLength: 'metadata.coreMetadata.basic.runLength',
	people: 'metadata.coreMetadata.basic.people',
	releaseYear: 'metadata.coreMetadata.basic.releaseYear',
	versionIntent: 'metadata.coreMetadata.basic.versionIntent',
	altIdentifiers: 'metadata.coreMetadata.basic.altIdentifiers',
	versionLanguages: 'metadata.coreMetadata.basic.versionLanguages',
	madeForRegions: 'metadata.coreMetadata.basic.versionIntent.madeForRegions',
	originalLanguages: 'metadata.coreMetadata.basic.originalLanguages',
	houseSequence: 'metadata.coreMetadata.basic.sequenceInfo.houseSequence',
};
