import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_useBlocker } from 'react-router-dom';
import UnsavedChangesModal from './UnsavedChangesModal';
import { TitleAutoSaveQueueContext } from '../TitleAutoSaveQueueContext';
import { TitleAutoSaveContext } from '../TitleAutoSaveContext';

type UnsavedChangesContextOutput = {
	promptUserIfUnsavedChanges: (onConfirm: () => void) => void;
};

export const UnsavedChangesContext = createContext<UnsavedChangesContextOutput>({
	promptUserIfUnsavedChanges: () => {},
});

function UnsavedChangesContextProvider({ children }: { children: ReactNode }) {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const confirmRef = useRef<(() => void) | null>(null);
	const { queueLength, loading } = useContext(TitleAutoSaveQueueContext);
	const { hasDirtyFields, clearDirtyFields } = useContext(TitleAutoSaveContext);
	const hasUnsavedChangesRef = useRef(queueLength > 0 || loading || hasDirtyFields);

	useEffect(() => {
		hasUnsavedChangesRef.current = loading || queueLength > 0 || hasDirtyFields;
	}, [loading, queueLength, hasDirtyFields]);

	const blocker = unstable_useBlocker(
		({ currentLocation, nextLocation }) =>
			hasUnsavedChangesRef.current && currentLocation.pathname !== nextLocation.pathname,
	);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (hasUnsavedChangesRef.current) {
				const message = 'You have unsaved changes. Are you sure you want to leave ?';
				// eslint-disable-next-line no-param-reassign
				event.returnValue = message; // Standard for most browsers;
				return message; // For some older browsers
			}
			return undefined;
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, []);

	const providedValue = useMemo(
		() => ({
			promptUserIfUnsavedChanges: (onConfirm: () => void) => {
				if (hasUnsavedChangesRef.current) {
					setModalIsOpen(true);
					confirmRef.current = onConfirm;
				} else {
					clearDirtyFields();
					onConfirm();
				}
			},
		}),
		[],
	);

	return (
		<UnsavedChangesContext.Provider value={providedValue}>
			{children}
			{blocker.state === 'blocked' && (
				<UnsavedChangesModal
					open
					handleClose={() => blocker.reset?.()}
					onConfirm={() => {
						clearDirtyFields();
						blocker.proceed?.();
					}}
				/>
			)}
			<UnsavedChangesModal
				open={modalIsOpen}
				handleClose={() => setModalIsOpen(false)}
				onConfirm={() => {
					setModalIsOpen(false);
					confirmRef.current?.();
					confirmRef.current = null;
					clearDirtyFields();
				}}
			/>
		</UnsavedChangesContext.Provider>
	);
}

export default UnsavedChangesContextProvider;
