import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import Dropdown from '../../../../components/library/Dropdown/Dropdown';
import availableWorkTypeDetailsByWorkType from '../../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import useWorkTypes from '../../../../utils/hooks/titles/useWorkTypes';
import sortAlphabeticallyByLabel from '../../../../utils/sortAlphabeticallyByLabel';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface WorkTypeDetailFieldProps {
	workType: string;
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
}

function WorkTypeDetailField({
	workType,
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
}: WorkTypeDetailFieldProps) {
	const { getNameByUuid } = useWorkTypes();

	const workTypeDetails =
		availableWorkTypeDetailsByWorkType[getNameByUuid(workType) as keyof typeof availableWorkTypeDetailsByWorkType];

	const label = workTypeDetails?.workTypeDetailLabel;
	const options = workTypeDetails?.availableWorkTypeDetails;
	const sortedOptions = options?.sort(sortAlphabeticallyByLabel);
	const workTypeDetailsTooltip = useTooltip('coreMetadata.basic.workTypeDetails');

	if (!label) return null;

	return useMemo(
		() => (
			<Controller
				control={control}
				name="workTypeDetail"
				render={({ field }) => (
					<Dropdown
						withSearch
						enablePortal
						tooltip={workTypeDetailsTooltip?.tooltip}
						options={sortedOptions}
						error={isRequired ? (errors?.workTypeDetail?.message as string) : ''}
						value={field.value}
						onChange={async (e) => {
							setValue('workTypeDetail', e);
							if (isRequired) {
								await trigger('workTypeDetail');
							}
						}}
						label={getLabelWithRequired(label, isRequired)}
					/>
				)}
			/>
		),
		[workType, label, sortedOptions, control, errors?.workTypeDetail?.message, setValue, trigger],
	);
}

export default WorkTypeDetailField;
