import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Grid from '@mui/material/Grid';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import Icon from '@mdi/react';
import {
	mdiMagnify,
	mdiClose,
	mdiChevronUp,
	mdiChevronDown,
	mdiChevronLeft,
	mdiChevronRight,
	mdiChevronDoubleLeft,
	mdiChevronDoubleRight,
	mdiInformation,
	mdiDotsVertical,
	mdiPin,
} from '@mdi/js';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import Droppable from '../../utils/dnd/StrictModeDroppable';

import ListItem from '../library/ListItem';
import Button from '../library/Button';
import Tooltip from '../library/Tooltip';
import useKeyPress from '../../utils/hooks/useKeyPress';
import MergeIcons from '../library/MergeIcons';
import { ColumnStickyState } from '../../utils/table/stickyUtils';
import escapeStringForRegexp from '../../utils/escapeStringForRegexp';

export type TransferListItem = {
	label: string;
	value: string;
};

interface TransferListProps {
	leftList: TransferListItem[];
	rightList: TransferListItem[];
	setLeftList: (value: TransferListItem[]) => void;
	setRightList: (value: TransferListItem[]) => void;
	columnSticky: ColumnStickyState;
	setColumnSticky: (value: ColumnStickyState) => void;
	labelLeft?: string;
	tooltipLeft?: string;
	labelRight?: string;
	tooltipRight?: string;
}

const SideWrapper = styled.div`
	${({ theme }) => css`
		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		width: 100%;
	`}

	ul {
		height: 300px;
		overflow: scroll;
	}
`;

const StickyDivider = styled.div`
	${({ theme }) => css`
		border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		width: 100%;
	`}
`;

const PinButton = styled(Button)`
	${({ theme }) => css`
		display: none;
		height: 100%;
		padding: 0;
		svg {
			color: ${theme.palette.primary.main};
		}
	`}
`;

const UnstickedItemWrapper = styled.div`
	${() => css`
		.MuiListItem-root:hover {
			button {
				display: flex;
			}
		}
	`}
`;

const DragHandleWrapper = styled.div<{ disabled: boolean }>`
	${({ theme, disabled }) => css`
		margin-right: ${theme.spacing(1)};

		svg {
			color: ${disabled ? theme.palette.action.disabled : theme.palette.text.secondary};
		}
	`}

	${({ disabled }) =>
		disabled
			? css`
					cursor: not-allowed;
			  `
			: null}
`;

const LabelWrapper = styled.div`
	${({ theme }) => css`
		align-items: center;
		display: flex;
		padding: ${theme.spacing(1)} 0;

		.MuiTypography-label {
			color: ${theme.palette.blue.main};
		}

		svg {
			color: ${theme.palette.text.secondary};
			margin-left: ${theme.spacing(1)};
		}
	`}
`;

const CenterWrapper = styled(Grid)`
	button {
		height: 36px;
		margin: 4px 0;
		width: 36px;
	}
`;

const UpDownWrapper = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacing(1)};

		button {
			height: 36px;
			margin-right: ${theme.spacing(1)};
			width: 36px;
		}
	`}
`;

const SearchWrapper = styled.div(
	({ theme }) => css`
		align-items: stretch;
		background-color: ${theme.palette.light.background};
		border-bottom: 1px solid ${theme.palette.action.divider};
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		box-sizing: border-box;
		color: ${theme.palette.text.primary};
		display: flex;
		height: 48px;

		svg {
			align-self: center;
			color: ${theme.palette.text.secondary};
		}
		.start {
			margin-left: ${theme.spacing(1)};
		}
		.end {
			margin-right: ${theme.spacing(1)};
		}

		button {
			align-self: center;
		}
	`,
);

const SearchInput = styled.input(
	({ theme }) => css`
		background-color: inherit;
		border: none;
		flex-grow: 1;
		font-size: 16px;
		padding: 0 ${theme.spacing(2)};
	`,
);

function TransferList(props: TransferListProps) {
	const {
		leftList,
		rightList,
		setLeftList,
		setRightList,
		columnSticky,
		setColumnSticky,
		labelLeft,
		labelRight,
		tooltipLeft,
		tooltipRight,
	} = props;
	const [searchValueLeft, setSearchValueLeft] = useState('');
	const [searchValueRight, setSearchValueRight] = useState('');
	const [filteredLeft, setFilteredLeft] = useState<TransferListItem[] | undefined>(leftList);
	const [filteredRight, setFilteredRight] = useState<TransferListItem[] | undefined>(rightList);
	const [nonStickyItems, setNonStickyItems] = useState<TransferListItem[]>([]);
	const [stickyItems, setStickyItems] = useState<TransferListItem[]>([]);
	const [selectedLeft, setSelectedLeft] = useState<TransferListItem[]>([]);
	const [selectedRight, setSelectedRight] = useState<TransferListItem[]>([]);

	// Catch cmd and ctrl key for multi select
	const cmdKey = useKeyPress('Meta');
	const ctrlKey = useKeyPress('Control');

	const searchFn = (value: string, items: TransferListItem[]) => {
		if (value.length) {
			const regexStr = `.*${escapeStringForRegexp(value).toLowerCase()}.*`;
			const searchRegex = new RegExp(regexStr);
			return items.filter((item) => {
				if (item) {
					return item.value.toLowerCase().match(searchRegex) || item.label.toLowerCase().match(searchRegex);
				}
				return false;
			});
		}
		return undefined;
	};

	// Search Left
	useEffect(() => {
		if (searchValueLeft.length) setFilteredLeft(searchFn(searchValueLeft, leftList));
		else setFilteredLeft(leftList);
	}, [searchValueLeft, leftList]);
	// Search Right
	useEffect(() => {
		if (searchValueRight.length) setFilteredRight(searchFn(searchValueRight, rightList));
		else setFilteredRight(rightList);
	}, [searchValueRight, rightList]);

	// Sort sticky and non sticky after filtered
	useEffect(() => {
		if (filteredRight) {
			setStickyItems(filteredRight.filter((item) => columnSticky.includes(item.value)));
			setNonStickyItems(filteredRight.filter((item) => !columnSticky.includes(item.value)));
		}
	}, [filteredRight, columnSticky]);

	// Utility to actually moving elements left/right
	const moveElementsSide = (
		source: TransferListItem[],
		target: TransferListItem[],
		moveCheck: (element: TransferListItem) => boolean,
	) => {
		for (let i = 0; i < source.length; i += 1) {
			const element = source[i];
			if (moveCheck(element)) {
				if (columnSticky.includes(element.value)) {
					const tmp = [...columnSticky];
					tmp.splice(tmp.indexOf(element.value), 1);
					setColumnSticky(tmp);
				}

				source.splice(i, 1);
				target.push(element);
				i -= 1;
			}
		}
	};

	// Utility to move selected up/down
	const moveElementUpOrDown = (
		list: TransferListItem[],
		stickCopy: ColumnStickyState,
		item: TransferListItem,
		direction: 'up' | 'down',
	) => {
		const initialIndex = list.indexOf(item);
		const unstickedIndex = nonStickyItems.indexOf(item);
		const stickedIndex = stickyItems.indexOf(item);

		if ((initialIndex === 0 && direction === 'up') || (initialIndex === list.length - 1 && direction === 'down')) {
			return;
		}

		// Move from sticky list to unsticked and other way
		if (unstickedIndex === 0 && stickedIndex === -1 && direction === 'up') stickCopy.push(item.value);
		else if (stickedIndex === stickyItems.length - 1 && unstickedIndex === -1 && direction === 'down') {
			stickCopy.splice(stickCopy.indexOf(item.value), 1);
		} else {
			const [removed] = list.splice(initialIndex, 1);
			list.splice(direction === 'up' ? initialIndex - 1 : initialIndex + 1, 0, removed);
		}
	};

	// Handle Move Up or Down
	const handleMoveUpOrDown = (direction: 'up' | 'down') => {
		const copy = [...rightList];
		const stickCopy = [...columnSticky];

		selectedRight.sort((a, b) =>
			direction === 'up' ? rightList.indexOf(a) - rightList.indexOf(b) : rightList.indexOf(b) - rightList.indexOf(a),
		);
		selectedRight.forEach((item) => moveElementUpOrDown(copy, stickCopy, item, direction));
		setRightList(copy);
		setFilteredRight(copy);
		setColumnSticky(stickCopy);
	};

	// Handle Move selected callback
	const handleMoveLeft = () => {
		const leftCopy = [...leftList];
		const rightCopy = [...rightList];

		moveElementsSide(rightCopy, leftCopy, (e) => selectedRight.includes(e));
		setLeftList(leftCopy);
		setRightList(rightCopy);
	};
	const handleMoveRight = () => {
		const leftCopy = [...leftList];
		const rightCopy = [...rightList];

		moveElementsSide(leftCopy, rightCopy, (e) => selectedLeft.includes(e));
		setLeftList(leftCopy);
		setRightList(rightCopy);
	};

	// Handle move all items
	const handleMoveAllLeft = () => {
		setLeftList(leftList.concat(rightList));
		setRightList([]);
	};
	const handleMoveAllRight = () => {
		setRightList(rightList.concat(leftList));
		setLeftList([]);
	};

	// Handle stick/unstick
	const handleStick = (id: string) => {
		setColumnSticky([...columnSticky, id]);

		const tmp = [...rightList];
		const toRemoveItem = rightList.find((item) => item.value === id);
		if (toRemoveItem) {
			const [removed] = tmp.splice(rightList.indexOf(toRemoveItem), 1);
			tmp.unshift(removed);
			setRightList(tmp);
		}
	};
	const handleUnstick = (id: string) => {
		const tmpSticky = [...columnSticky];
		tmpSticky.splice(columnSticky.indexOf(id), 1);
		setColumnSticky(tmpSticky);

		const tmp = [...rightList];
		const toRemoveItem = rightList.find((item) => item.value === id);
		if (toRemoveItem) {
			const [removed] = tmp.splice(rightList.indexOf(toRemoveItem), 1);
			tmp.push(removed);
			setRightList(tmp);
		}
	};

	// Handle Select from either list
	const handleSelect = (item: TransferListItem, side: 'left' | 'right') => {
		const list = side === 'left' ? selectedLeft : selectedRight;
		const setter = side === 'left' ? setSelectedLeft : setSelectedRight;

		if (ctrlKey || cmdKey) {
			if (list.includes(item)) {
				const res = [...list];
				res.splice(list.indexOf(item), 1);
				setter(res);
			} else setter([...list, item]);
		} else if (list.includes(item)) {
			// Going from multi select to one
			if (list.length > 1) setter([item]);
			else setter([]);
		} else {
			setter([item]);
		}
	};

	// Drag and drop callback
	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		if (rightList) {
			const res = Array.from(rightList);
			const convertedSourceIndex =
				result.source.droppableId === 'droppableSticky'
					? result.source.index
					: result.source.index + stickyItems.length;
			const convertedDestinationIndex =
				result.destination.droppableId === 'droppableSticky'
					? result.destination.index
					: result.destination.index +
					  (result.source.droppableId === result.destination.droppableId
							? stickyItems.length
							: stickyItems.length - 1);

			const [removed] = res.splice(convertedSourceIndex, 1);
			res.splice(convertedDestinationIndex, 0, removed);
			setFilteredRight(res);
			setRightList(res);

			// Handle Stick/Unstick
			if (result.source.droppableId === 'droppableNonSticky' && result.destination.droppableId === 'droppableSticky') {
				setColumnSticky([...columnSticky, removed.value]);
				setStickyItems(res.filter((item) => [...columnSticky, removed.value].includes(item.value)));
				setNonStickyItems(res.filter((item) => ![...columnSticky, removed.value].includes(item.value)));
			} else if (
				result.source.droppableId === 'droppableSticky' &&
				result.destination.droppableId === 'droppableNonSticky'
			) {
				const tmpSticky = [...columnSticky];
				tmpSticky.splice(columnSticky.indexOf(removed.value), 1);
				setStickyItems(res.filter((item) => tmpSticky.includes(item.value)));
				setNonStickyItems(res.filter((item) => !tmpSticky.includes(item.value)));

				setColumnSticky(tmpSticky);
			} else {
				setStickyItems(res.filter((item) => columnSticky.includes(item.value)));
				setNonStickyItems(res.filter((item) => !columnSticky.includes(item.value)));
			}
		}
	};

	return (
		<Grid container spacing={2} justifyContent="center" alignItems="center">
			<Grid item xs alignSelf="self-start">
				{labelLeft && (
					<LabelWrapper>
						<Typography variant="buttonLargeMedium">{labelLeft}</Typography>
						{tooltipLeft && (
							<Tooltip title={tooltipLeft}>
								<Icon path={mdiInformation} size="18px" />
							</Tooltip>
						)}
					</LabelWrapper>
				)}
				<SideWrapper>
					<SearchWrapper>
						<Icon path={mdiMagnify} size="24px" className="start" />
						<SearchInput
							placeholder="Search "
							value={searchValueLeft}
							onChange={(e) => setSearchValueLeft(e.target.value)}
						/>
						<Button
							nxstyle="tertiary-light"
							size="small"
							onClick={() => setSearchValueLeft('')}
							$disablePadding
							className="end"
						>
							<Icon path={mdiClose} size="20px" />
						</Button>
					</SearchWrapper>

					<List dense>
						{filteredLeft
							? filteredLeft.map((item) => (
									<ListItem disablePadding key={item.value}>
										<ListItemButton selected={selectedLeft.includes(item)} onClick={() => handleSelect(item, 'left')}>
											<ListItemText>{item.label}</ListItemText>
										</ListItemButton>
									</ListItem>
							  ))
							: null}
					</List>
				</SideWrapper>
			</Grid>
			<Grid item>
				<CenterWrapper container direction="column" alignItems="center">
					<Button nxstyle="normal" size="large" $disablePadding onClick={() => handleMoveAllLeft()}>
						<Icon path={mdiChevronDoubleLeft} size="24px" />
					</Button>
					<Button
						nxstyle="normal"
						size="large"
						$disablePadding
						disabled={!selectedRight.length}
						onClick={() => handleMoveLeft()}
					>
						<Icon path={mdiChevronLeft} size="24px" />
					</Button>
					<Button
						nxstyle="normal"
						size="large"
						$disablePadding
						disabled={!selectedLeft.length}
						onClick={() => handleMoveRight()}
					>
						<Icon path={mdiChevronRight} size="24px" />
					</Button>
					<Button nxstyle="normal" size="large" $disablePadding onClick={() => handleMoveAllRight()}>
						<Icon path={mdiChevronDoubleRight} size="24px" />
					</Button>
				</CenterWrapper>
			</Grid>
			<Grid item xs alignSelf="self-start">
				{labelRight && (
					<LabelWrapper>
						<Typography variant="buttonLargeMedium">{labelRight}</Typography>
						{tooltipRight && (
							<Tooltip title={tooltipRight}>
								<Icon path={mdiInformation} size="18px" />
							</Tooltip>
						)}
					</LabelWrapper>
				)}
				<SideWrapper>
					<SearchWrapper>
						<Icon path={mdiMagnify} size="24px" className="start" />
						<SearchInput
							placeholder="Search "
							value={searchValueRight}
							onChange={(e) => setSearchValueRight(e.target.value)}
						/>
						<Button
							nxstyle="tertiary-light"
							size="small"
							onClick={() => setSearchValueRight('')}
							$disablePadding
							className="end"
						>
							<Icon path={mdiClose} size="20px" />
						</Button>
					</SearchWrapper>

					<DragDropContext onDragEnd={handleDragEnd}>
						<List dense>
							<Droppable droppableId="droppableSticky">
								{(droppableProvided) => (
									<div ref={droppableProvided.innerRef}>
										{stickyItems
											? stickyItems.map((item, index) => (
													<Draggable
														key={item.value}
														draggableId={item.value}
														index={index}
														isDragDisabled={!!searchValueRight.length}
													>
														{(draggableProvided) => (
															<div
																key={item.value}
																ref={draggableProvided.innerRef}
																{...draggableProvided.draggableProps}
															>
																<ListItem disablePadding>
																	<ListItemButton
																		selected={selectedRight.includes(item)}
																		onClick={() => handleSelect(item, 'right')}
																	>
																		<DragHandleWrapper disabled={!!searchValueRight.length}>
																			<MergeIcons {...draggableProvided.dragHandleProps}>
																				<Icon path={mdiDotsVertical} size="20px" />
																				<Icon path={mdiDotsVertical} size="20px" />
																			</MergeIcons>
																		</DragHandleWrapper>
																		<ListItemText>{item.label}</ListItemText>
																		<Button
																			nxstyle="tertiary-light"
																			$disablePadding
																			onClick={(e) => {
																				handleUnstick(item.value);
																				e.stopPropagation();
																			}}
																		>
																			<Icon path={mdiPin} size="16px" />
																		</Button>
																	</ListItemButton>
																</ListItem>
															</div>
														)}
													</Draggable>
											  ))
											: null}
										{droppableProvided.placeholder}
									</div>
								)}
							</Droppable>

							{stickyItems.length ? <StickyDivider /> : null}
							<Droppable droppableId="droppableNonSticky">
								{(droppableProvided) => (
									<UnstickedItemWrapper ref={droppableProvided.innerRef}>
										{nonStickyItems
											? nonStickyItems.map((item, index) => (
													<Draggable
														key={item.value}
														draggableId={item.value}
														index={index}
														isDragDisabled={!!searchValueRight.length}
													>
														{(draggableProvided) => (
															<div
																key={item.value}
																ref={draggableProvided.innerRef}
																{...draggableProvided.draggableProps}
															>
																<ListItem disablePadding>
																	<ListItemButton
																		selected={selectedRight.includes(item)}
																		onClick={() => handleSelect(item, 'right')}
																	>
																		<DragHandleWrapper disabled={!!searchValueRight.length}>
																			<MergeIcons {...draggableProvided.dragHandleProps}>
																				<Icon path={mdiDotsVertical} size="20px" />
																				<Icon path={mdiDotsVertical} size="20px" />
																			</MergeIcons>
																		</DragHandleWrapper>
																		<ListItemText>{item.label}</ListItemText>
																		<PinButton
																			nxstyle="tertiary-light"
																			$disablePadding
																			onClick={(e) => {
																				handleStick(item.value);
																				e.stopPropagation();
																			}}
																		>
																			<Icon path={mdiPin} size="16px" />
																		</PinButton>
																	</ListItemButton>
																</ListItem>
															</div>
														)}
													</Draggable>
											  ))
											: null}
										{droppableProvided.placeholder}
									</UnstickedItemWrapper>
								)}
							</Droppable>
						</List>
					</DragDropContext>
				</SideWrapper>
				<UpDownWrapper>
					<Button
						nxstyle="normal"
						size="small"
						$disablePadding
						disabled={!selectedRight.length || !!searchValueRight.length}
						onClick={() => handleMoveUpOrDown('up')}
					>
						<Icon path={mdiChevronUp} size="24px" />
					</Button>
					<Button
						nxstyle="normal"
						size="small"
						$disablePadding
						disabled={!selectedRight.length || !!searchValueRight.length}
						onClick={() => handleMoveUpOrDown('down')}
					>
						<Icon path={mdiChevronDown} size="24px" />
					</Button>
				</UpDownWrapper>
			</Grid>
		</Grid>
	);
}

export default TransferList;
