import React from 'react';
// eslint-disable-next-line import/no-cycle
import { DropdownV2 } from '@warehouse/shared/ui';

interface ItemsPerPageSelectorProps {
	value: number;
	onChange: (value: number) => void;
}

function ItemsPerPageSelector({ value, onChange }: ItemsPerPageSelectorProps) {
	return (
		<DropdownV2
			withSearch={false}
			inlineLabel
			label="Items per page"
			height={32}
			listItemHeight={32}
			value={value.toString()}
			onChange={(v) => onChange(parseInt(v, 10))}
			disabledMargin
			options={[
				{
					label: '10',
					value: '10',
				},
				{
					label: '25',
					value: '25',
				},
				{
					label: '50',
					value: '50',
				},
				{
					label: '75',
					value: '75',
				},
				{
					label: '100',
					value: '100',
				},
			]}
		/>
	);
}

export default ItemsPerPageSelector;
