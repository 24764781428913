import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import Pagination from '../../../../src/components/library/Pagination';
import ItemsPerPageSelector from '../../../../src/components/library/ItemsPerPageSelector';
import { paginatorStoreSelector, usePaginatorStore } from './paginator.store';
import { useSessionShareableParams } from '../../../../src/utils/hooks/useSessionShareableParams';

export function PaginationNavigator({ centered = false, storageUniqueKey }: PaginationNavigatorProps) {
	const [perPageSessionStorage, setPerPageSessionStorage] = useSessionShareableParams<number>({
		uniqueKey: storageUniqueKey,
		name: 'perPage',
		defaultValue: 10,
	});

	const actions = usePaginatorStore(paginatorStoreSelector.actions);
	const page = usePaginatorStore(paginatorStoreSelector.page);
	const totalPages = usePaginatorStore(paginatorStoreSelector.totalPages);
	const perPage = usePaginatorStore(paginatorStoreSelector.perPage);

	// This useEffect default perPage to sessionStorage on mount
	useEffect(() => {
		actions.setPerPage(perPageSessionStorage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PaginationWrapper centered={centered}>
			{centered && <div />}
			<Pagination value={page} lastPage={totalPages} onChange={(v) => actions.setPage(v)} />
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				<ItemsPerPageSelector
					value={perPage}
					onChange={(v) => {
						setPerPageSessionStorage(v);
						actions.setPerPage(v);
					}}
				/>
			</div>
		</PaginationWrapper>
	);
}

interface PaginationNavigatorProps {
	centered?: boolean;
	storageUniqueKey?: string;
}

const PaginationWrapper = styled.div<{ centered: boolean }>(
	({ theme, centered }) => css`
		box-sizing: border-box;
		display: grid;
		grid-area: pagination;
		grid-template-columns: ${centered ? '1fr 3fr 1fr' : '5fr 2fr'};
		padding: 0 ${theme.spacing(2)};
		width: 100%;

		.MuiGrid-container {
			width: auto;
		}
	`,
);
