import { ColumnDef, VisibilityState } from '@tanstack/react-table';

// eslint-disable-next-line import/prefer-default-export
export function getCompleteVisibilityState(columns: ColumnDef<any, any>[], visibilityState: VisibilityState) {
	const res: VisibilityState = {};
	columns.forEach((item) => {
		if (item.id) res[item.id] = true;
	});
	return { ...res, ...visibilityState };
}
