import { useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';
import { CommonTitleFilters } from '@warehouse/title/domain';
import useSearchTitlesV2 from '../../../utils/hooks/titles/useSearchTitlesV2';

const loadingNetworkStatus = [NetworkStatus.setVariables, NetworkStatus.loading];

export function useHasEpisodeChildren(titleUuid: string, showHidden: boolean) {
	const { data, networkStatus } = useSearchTitlesV2({
		searchFields: [],
		debouncedSearch: '',
		pollInterval: 2000,
		filtering: useMemo<FilterStep>(
			() => ({
				step: {
					combinationOperator: 'AND',
					filters: [
						{
							nested: 'parents',
							step: {
								combinationOperator: 'AND',
								filters: [
									{ field: 'parents.parentContentId', operator: 'equals', value: titleUuid },
									{ field: 'parents.relationshipType', operator: 'equals', value: 'isepisodeof' },
								],
							},
						},
						...(showHidden ? [] : [CommonTitleFilters.excludeHiddenTitles()]),
					],
				},
			}),
			[titleUuid, showHidden],
		),
		page: 1,
		perPage: 1,
	});

	return [
		!!data?.searchTitlesV2?.page?.totalDocument && data.searchTitlesV2.page.totalDocument > 0,
		loadingNetworkStatus.includes(networkStatus),
	];
}
