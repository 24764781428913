import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';
import { StyledBadge } from '../Tag';
import Chip from '../Chip';

type TriggerWrapperProps = {
	active: boolean;
	error?: string;
	height?: number;
	transparentBorder?: boolean;
	placement?: 'bottom' | 'top';
};

type MenuWrapperProps = {
	active: boolean;
	height?: number;
	enablePortal?: boolean;
	bottomActions?: boolean;
	placement?: 'bottom' | 'top';
};

type StyledBadgeProps = {
	height?: number;
};

export const TriggerWrapper = styled.div<TriggerWrapperProps>(
	({ theme, active, error, height, transparentBorder, placement }) => css`
		align-items: center;
		background-color: ${theme.palette.light.background};
		border: 1px solid ${transparentBorder ? 'transparent' : theme.palette.light.backgroundAlt};
		border-radius: 8px;
		cursor: pointer;
		display: flex;
		font-size: 1rem;
		min-height: ${height ? `${height}px` : '48px'};
		justify-content: space-between;
		padding: 0 12px;
		position: relative;
		flex-grow: 1;

		&:hover {
			border-color: ${theme.palette.blue.main};
		}

		:focus-visible {
			outline: none;
		}

		${error
			? css`
					background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
					border-bottom-right-radius: 0;
					border-color: ${theme.palette.error.main} !important;
					color: ${theme.palette.error.text};

					input {
						color: ${theme.palette.error.text};
					}
			  `
			: null}

		// Active
		${active &&
		css`
			.caretIcon {
				transform: rotate(180deg);
			}
			background-color: ${theme.palette.light.main};
			border: 1px solid ${theme.palette.blue.main};
			filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.1));
			z-index: 99;
			${placement === 'top'
				? css`
						border-top: 1px solid transparent !important;
						border-top-left-radius: 0 !important;
						border-top-right-radius: 0 !important;
				  `
				: css`
						border-bottom: 1px solid transparent !important;
						border-bottom-left-radius: 0 !important;
						border-bottom-right-radius: 0 !important;
				  `}
		`};
	`,
);

export const MenuWrapper = styled.div<MenuWrapperProps>(
	({ theme, active, height, enablePortal, bottomActions, placement }) => css`
		background-color: ${theme.palette.light.main};
		height: auto;
		max-height: 0;
		left: -1px;
		position: absolute;
		top: ${height ? `${height - 1}px` : '47px'};
		transition: max-height 0.2s ease-in-out;
		width: 100%;
		overflow: hidden;

		// Portal
		${enablePortal &&
		css`
			z-index: 1800;
		`};

		// Active
		${active &&
		css`
			border: 1px solid ${theme.palette.blue.main};
			max-height: 280px;

			${placement === 'top'
				? css`
						border-bottom: 1px solid ${theme.palette.action.divider};
				  `
				: css`
						border-top: 1px solid ${theme.palette.action.divider};
				  `}
		`};

		${placement === 'top'
			? css`
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
					top: ${height ? `-${height - 1}px` : '-47px'};
			  `
			: css`
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
					top: ${height ? `${height - 1}px` : '47px'};
			  `}

		// Bottom actions
		${active &&
		bottomActions &&
		css`
			max-height: 350px;
		`};
	`,
);

export const MenuList = styled.ul(
	() => css`
		margin: 0;
		max-height: 224px;
		overflow: hidden;
		padding: 0;
	`,
);

export const MenuItem = styled.li<{ height?: number; highlighted?: boolean }>(
	({ theme, height, highlighted }) => css`
		box-sizing: border-box;
		color: ${theme.palette.text.secondary};
		cursor: pointer;
		display: block;
		font-size: 14px;
		font-weight: 400;
		list-style: none;
		min-height: ${height ? `${height}px` : '48px'};
		overflow: hidden;
		padding: ${theme.spacing(1)};
		text-overflow: ellipsis;
		white-space: nowrap;

		&:hover {
			background-color: ${theme.palette.blue.background};
		}

		${highlighted &&
		css`
			background-color: ${theme.palette.blue.background};
		`}
	`,
);

export const SelectedItemChipWrapper = styled.div(
	() => css`
		display: flex;
		gap: 4px;
		overflow: hidden;
		width: 100%;
	`,
);

export const SelectedChipHidden = styled.div`
	position: absolute;
	visibility: hidden;
`;

export const StyledCustomBadge = styled(StyledBadge)<StyledBadgeProps>(
	({ height }) => css`
		span {
			height: ${height ? `${height}px` : '20px'};
		}
		position: absolute;
		right: 50px;
		top: 50%;
	`,
);

export const PlaceholderChip = styled(Chip)(
	({ theme }) => css`
		color: ${theme.palette.action.placeholder};
		font-style: italic;
	`,
);

export const OptionsWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: flex-start;
		overflow: hidden;

		.select-dropdown-option-label {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	`,
);
