/* eslint-disable no-param-reassign */
import Ajv, { DefinedError, JSONSchemaType, Options } from 'ajv';
import { toNestErrors, validateFieldsNatively } from '@hookform/resolvers';
import addErrors from 'ajv-errors';
import addFormats from 'ajv-formats';
import { appendErrors, ResolverOptions, FieldError } from 'react-hook-form';

const parseErrorSchema = (ajvErrors: DefinedError[], validateAllFieldCriteria: boolean) => {
	// Ajv will return empty instancePath when require error
	ajvErrors.forEach((error) => {
		if (error.keyword === 'required') {
			error.instancePath += `/${error.params.missingProperty}`;
		}
	});

	return ajvErrors.reduce<Record<string, FieldError>>((previous, error) => {
		// `/deepObject/data` -> `deepObject.data`
		const path = error.instancePath.substring(1).replace(/\//g, '.');

		if (!previous[path]) {
			previous[path] = {
				message: error.message,
				type: error.keyword,
			};
		}

		if (validateAllFieldCriteria) {
			const { types } = previous[path];
			const messages = types && types[error.keyword];

			previous[path] = appendErrors(
				path,
				validateAllFieldCriteria,
				previous,
				error.keyword,
				messages ? ([] as string[]).concat(messages as string[], error.message || '') : error.message,
			) as FieldError;
		}

		return previous;
	}, {});
};

const ajvResolver =
	<T>(schema: JSONSchemaType<T>, schemaOptions?: Options) =>
	async (values: any, _: any, options: ResolverOptions<any>) => {
		const ajv = new Ajv({
			allErrors: true,
			strict: true,
			strictTypes: true,
			strictNumbers: true,
			validateSchema: true,
			$data: true,
			...schemaOptions,
		});
		addFormats(ajv);
		addErrors(ajv);

		const validate = ajv.compile(schema);

		const valid = validate(values);

		if (options.shouldUseNativeValidation) validateFieldsNatively({}, options);

		return valid
			? { values, errors: {} }
			: {
					values: {},
					errors: toNestErrors(
						parseErrorSchema(
							validate.errors as DefinedError[],
							!options.shouldUseNativeValidation && options.criteriaMode === 'all',
						),
						options,
					),
			  };
	};

export default ajvResolver;
