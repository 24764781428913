import { useRef } from 'react';

export function singletonFactory<T>(config: InjectableFactoryConfig<T>) {
	let overriden: T | undefined;
	let _instance: T | undefined;

	const get = () => {
		if (overriden !== undefined) {
			return overriden;
		}

		if (_instance !== undefined) {
			return _instance;
		}

		_instance = config.factory();
		return _instance;
	};

	const reset = () => {
		overriden = undefined;
	};

	const override = <OVERRIDE extends T>(instance: OVERRIDE): OVERRIDE => {
		if (overriden !== undefined && !Object.is(overriden, instance)) {
			throw new Error('Cannot re-override singleton');
		}
		overriden = instance;
		return instance;
	};

	const useOverride = <OVERRIDE extends T>(instance: OVERRIDE): OVERRIDE => {
		const repoInstance = useRef(instance);
		return override(repoInstance.current);
	};

	return {
		get,
		reset,
		override,
		useOverride,
	};
}

interface InjectableFactoryConfig<T> {
	factory: () => T;
}
