import React from 'react';
import { Typography } from '@mui/material';
import { FieldWrapper } from '../../ui/FieldWrapper';
import { ReadOnlyField } from '../../../../src/components/library/ReadOnlyField';

export function RelationshipTypeField({ relationshipType }: RelationshipTypeFieldProps) {
	return (
		<FieldWrapper>
			<Typography variant="buttonMediumMedium" color="text.primary">
				Relationship Type
			</Typography>
			<ReadOnlyField>{relationshipType}</ReadOnlyField>
		</FieldWrapper>
	);
}

interface RelationshipTypeFieldProps {
	relationshipType: string;
}
