import React from 'react';
import styled, { css } from 'styled-components';
import { alpha, ButtonGroup as MUIButtonGroup } from '@mui/material';
import { ButtonGroupProps } from '@mui/material/ButtonGroup/ButtonGroup';

interface IButtonGroupProps extends ButtonGroupProps {
	innerRef?: React.Ref<HTMLDivElement>;
	nxstyle?:
		| 'cta'
		| 'normal'
		| 'primary-blue'
		| 'primary-red'
		| 'secondary-blue'
		| 'secondary-red'
		| 'secondary-black'
		| 'tertiary-light'
		| 'tertiary-dark';
}

const StyledButtonGroup = styled(MUIButtonGroup)<IButtonGroupProps>(
	({ theme, nxstyle }) => css`
		outline: none !important;

		${['cta', 'primary-blue', 'primary-red'].includes(nxstyle || '') &&
		css`
			> * {
				border-color: white;
			}

			> *:hover {
				border-color: white;
			}
		`}

		${nxstyle === 'tertiary-light' &&
		css`
			background-color: white;

			> * {
				border-color: transparent;
			}

			> *:hover {
				border-color: transparent;
				border-right-color: white !important;
			}
		`}


    ${nxstyle === 'secondary-black' &&
		css`
			> *:active {
				outline: 1px solid ${alpha(theme.palette.dark.main, 0.5)};
			}

			> *:hover {
				border-right-color: ${alpha(theme.palette.dark.main, 0.5)} !important;
			}
		`}

		${nxstyle === 'normal' &&
		css`
			> * {
				border-color: ${theme.palette.light.background};
			}

			> *:hover {
				border-color: ${theme.palette.light.background};
				border-right-color: ${theme.palette.light.background} !important;
			}
		`}

    ${nxstyle === 'tertiary-dark' &&
		css`
			> * {
				border-color: transparent;
			}

			> *:hover {
				border-color: transparent !important;
			}
		`}
	`,
);

function ButtonGroup({ innerRef, ...props }: IButtonGroupProps) {
	return <StyledButtonGroup ref={innerRef} {...props} />;
}

export default ButtonGroup;
