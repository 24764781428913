import React from 'react';
import styled, { css } from 'styled-components';

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import canvasBg from '../../../assets/canvas-bg.png';
import NodeItem from '../../../components/profiles/NodeItem';
import ConditionBlock from '../../../components/profiles/ConditionBlock';
import NodeWrapper from '../../../components/profiles/NodeWrapper';
import { useEditorContext } from './EditorContext';
import { FlattenedItem } from './types';
import TreeItem from '../../../components/profiles/tree/TreeItem';

const EditorWrapper = styled.div`
	background-image: url(${canvasBg});
	background-repeat: repeat;
	flex-grow: 1;
	height: 100%;
	overflow: scroll;
`;

const TreeWrapper = styled.div`
	${({ theme }) => css`
		padding: ${theme.spacing(2)};
	`}
`;

function PlayableContentEditor({
	flattenedItems,
	sortedIds,
}: {
	flattenedItems: FlattenedItem[];
	sortedIds: string[];
}) {
	const editorContext = useEditorContext();

	const renderSingle = (el: FlattenedItem, index: number, lastOfDepth: boolean, nextDepths: number[]) => {
		switch (el.type) {
			case 'block':
				return (
					<TreeItem depth={el.depth} lastOfDepth={lastOfDepth} key={el.id} id={el.id} nextDepths={nextDepths}>
						<NodeWrapper id={el.id} isSelected={el.id === editorContext?.selected}>
							<NodeItem
								index={index + 1}
								title={el.name || ''}
								type={el.typeText || ''}
								iconPath={el.icon || ''}
								description={el.description}
								isSelected={el.id === editorContext?.selected}
							/>
							{el.attached && (
								<ConditionBlock
									index={index + 1 + 0.1}
									collapsibleId={el.id}
									options={[
										{ value: 'at_least_one_of', label: 'At least one of' },
										{ value: 'custom', label: 'Custom' },
										{ value: 'if_available', label: 'If available' },
										{ value: 'all_of', label: 'All of' },
									]}
									name={el.attached.name || ''}
									iconPath={el.attached.icon}
									isSelected={el.id === editorContext?.selected}
								/>
							)}
						</NodeWrapper>
					</TreeItem>
				);

			case 'condition':
				return (
					<TreeItem depth={el.depth} lastOfDepth={lastOfDepth} key={el.id} id={el.id} nextDepths={nextDepths}>
						<NodeWrapper id={el.id} isSelected={el.id === editorContext?.selected}>
							<ConditionBlock
								collapsibleId={el.id}
								index={index + 1}
								options={[
									{ value: 'at_least_one_of', label: 'At least one of' },
									{ value: 'custom', label: 'Custom' },
									{ value: 'if_available', label: 'If available' },
									{ value: 'all_of', label: 'All of' },
								]}
								name={el.name || ''}
								iconPath={el.icon}
								isSelected={el.id === editorContext?.selected}
							/>
						</NodeWrapper>
					</TreeItem>
				);

			case 'track':
				return (
					<TreeItem depth={el.depth} lastOfDepth={lastOfDepth} key={el.id} id={el.id} nextDepths={nextDepths}>
						<NodeWrapper key={el.id} id={el.id} isSelected={el.id === editorContext?.selected}>
							<NodeItem
								index={index + 1}
								title={el.name || ''}
								type={el.typeText || ''}
								iconPath={el.icon}
								description={el.description}
								isSelected={el.id === editorContext?.selected}
							/>
						</NodeWrapper>
					</TreeItem>
				);

			default:
				return null;
		}
	};

	return (
		<EditorWrapper>
			<TreeWrapper>
				<SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
					{flattenedItems.map((el, i, arr) => {
						const next: FlattenedItem | undefined = arr[i + 1];
						let lastOfDepth = false;

						const getNextLowestDepthItem = () => {
							let res = el;
							for (let j = i + 1; j < arr.length; j += 1) {
								if (arr[j] && res && arr[j].depth <= res.depth) {
									res = arr[j];
								}
							}
							return res;
						};

						const isEqualDepthPresentNext = () => {
							for (let j = i + 1; j < arr.length; j += 1) {
								if (arr[j] && arr[j].depth < el.depth) {
									return false;
								}
								if (arr[j] && arr[j].depth === el.depth) {
									return true;
								}
							}
							return false;
						};

						const getAllNextDepths = () => {
							const res: number[] = [];
							let latestClosed = el.depth;

							// From element until end
							if (i >= 1) {
								for (let j = i; j < arr.length; j += 1) {
									// Closing encountered
									if (arr[j] && arr[j].depth < el.depth && arr[j].depth < latestClosed) {
										if (!res.includes(arr[j].depth)) {
											res.push(arr[j].depth);
											latestClosed = arr[j].depth;
										}
									}
								}
							}
							return res;
						};

						const nextLowestItem = getNextLowestDepthItem();
						const allNextDepths = getAllNextDepths();

						// Next element is of lower depth so we need to cut the links
						if (next && next.depth < el.depth) lastOfDepth = true;
						// Element is last of lowest depth
						if (nextLowestItem.id === el.id) lastOfDepth = true;
						// Element has no matching depth next
						if (!isEqualDepthPresentNext()) lastOfDepth = true;

						// Last element
						if (!next) lastOfDepth = true;

						return renderSingle(el, i, lastOfDepth, allNextDepths);
					})}
				</SortableContext>
			</TreeWrapper>
		</EditorWrapper>
	);
}

export default PlayableContentEditor;
