import React, { forwardRef, ReactNode } from 'react';

import { CustomContentProps, SnackbarContent } from 'notistack';

interface ClickableSnackBarProps extends CustomContentProps {
	children: ReactNode;
}

const CustomSnackbar = forwardRef<HTMLDivElement, ClickableSnackBarProps>(({ id, ...props }, ref) => (
	<SnackbarContent ref={ref}>{props.children}</SnackbarContent>
));

CustomSnackbar.displayName = 'clickableError';

export default CustomSnackbar;
