import { alpha } from '@mui/material';
import styled, { css } from 'styled-components';

export const CustomInput = styled.input<{
	transparentBorder?: boolean;
	backgroundColor?: string;
	active?: boolean;
	error?: boolean;
	height?: number;
	verticalPadding?: boolean;
}>`
	${({ theme, transparentBorder, active, backgroundColor, height, error, verticalPadding }) => css`
		border-radius: 8px;
		${transparentBorder
			? css`
					background-color: inherit;
					border: 1px solid transparent;
			  `
			: css`
					background-color: ${backgroundColor || theme.palette.light.background};
					border: 1px solid ${theme.palette.light.backgroundAlt};
			  `}

		&:focus-visible {
			outline: none;
		}

		&:hover,
		&:focus-within {
			background-color: ${backgroundColor || theme.palette.light.main} !important;
			border-color: ${theme.palette.blue.main} !important;
			border-width: 1px !important;
			color: ${theme.palette.text.primary} !important;
		}

		box-sizing: border-box;

		${!error &&
		active &&
		css`
			background-color: ${backgroundColor || theme.palette.light.main} !important;
			border-color: ${theme.palette.blue.main} !important;
			border-width: 1px !important;
			color: ${theme.palette.text.primary} !important;
		`}

		color: ${theme.palette.text.secondary} !important;
		font-size: 14px;
		height: ${height}px;
		min-width: 130px;
		padding: ${theme.spacing(0, 2)};
		${verticalPadding &&
		css`
			padding: ${theme.spacing(2)};
		`}
		width: 100%;

		${error &&
		css`
			background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
			border-bottom-right-radius: 0;
			border-color: ${theme.palette.error.main} !important;
		`}
	`}
`;

export const Wrapper = styled.div<{
	error?: boolean;
	active?: boolean;
	width?: number;
}>(
	({ theme, error, active, width }) => css`
		position: relative;
		width: 100%;
		z-index: 1;

		${width &&
		css`
			width: ${width}px;
		`}

		${error &&
		css`
			.MuiTypography-root {
				color: ${theme.palette.error.text};
			}
		`}

		&:focus-within {
			.MuiTypography-root {
				color: ${theme.palette.blue.main};
			}
		}

		${active &&
		css`
			.MuiTypography-root {
				color: ${theme.palette.blue.main};
			}
		`}
	`,
);

export const TriggerWrapper = styled.div<{
	error?: boolean;
	backgroundColor?: string;
}>(
	({ theme, error, backgroundColor }) => css`
		&:hover {
			border-color: ${theme.palette.blue.main};
		}

		${backgroundColor &&
		css`
			background-color: ${backgroundColor};
		`}

		:focus-visible {
			outline: none;
		}

		${error
			? css`
					background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
					border-bottom-right-radius: 0;
					border-color: ${theme.palette.error.main} !important;
					color: ${theme.palette.error.text};

					input {
						color: ${theme.palette.error.text};
					}
			  `
			: null}
	`,
);

export const Popper = styled.div<{ active?: boolean; calendarHeight?: number }>(
	({ theme, active, calendarHeight }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: 8px;
		box-shadow: 0 ${theme.spacing(1)} ${theme.spacing(3)} 0 #00000040;
		height: auto;
		left: -1px;
		max-height: 0;
		overflow: hidden;
		position: absolute;
		transition: max-height 0.2s ease-in-out;
		width: auto !important;

		z-index: 1800;

		// Active
		${active &&
		css`
			max-height: ${calendarHeight ? calendarHeight + 40 : 320}px !important;
		`}

		:focus-visible {
			outline: none;
		}

		.rdp-selected {
			background-color: ${theme.palette.blue.main} !important;
			color: ${theme.palette.light.main} !important;
		}

		.rdp {
			--rdp-accent-color: transparent;
			--rdp-background-color: transparent;
		}

		.rdp-dropdown:focus-visible {
			outline: none !important;
		}
	`,
);
