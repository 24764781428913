import { useMemo } from 'react';
import { TitleLightWithSelectedParent } from '@warehouse/title/core';
import { CustomColumnDef } from '../../../src/components/library/SimpleTable/customColumnDef';
import {
	TitleCardColumn,
	ReleaseDateColumn,
	SequenceNumberColumn,
	RunLengthColumn,
	IDColumn,
} from '../ui/columns/common-columns';
import { RelationshipTypeColumn, RelationshipSubTypeColumn, TitleTypeColumn } from './columns/relationship-columns';

export function useRelationshipColumns(childrenParentMode: 'children' | 'parents') {
	return useMemo<CustomColumnDef<TitleLightWithSelectedParent>[]>(
		() => [
			RelationshipTypeColumn(childrenParentMode),
			RelationshipSubTypeColumn,
			TitleCardColumn(),
			ReleaseDateColumn(),
			TitleTypeColumn(),
			SequenceNumberColumn(),
			RunLengthColumn(),
			IDColumn(),
		],
		[childrenParentMode],
	);
}
