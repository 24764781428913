import React from 'react';
import { BottomActionClear, DropdownV2 } from '@warehouse/shared/ui';

export type StringifiedBoolean = '' | 'true' | 'false';

export interface BooleanFieldProps {
	value: boolean | null;
	onChange: (value: boolean | null) => void;
	onBlur?: () => void;
	variant?: 'prefilled' | 'default';
}

export function BooleanField({ value, onChange, onBlur, variant = 'default' }: BooleanFieldProps) {
	return (
		<DropdownV2<StringifiedBoolean>
			data-testid={`boolean-field-${variant}`}
			ariaLabel="3D Boolean field"
			disabledMargin
			bottomActions={
				<BottomActionClear
					action={() => {
						onChange(null);
						onBlur?.();
					}}
				/>
			}
			variant={variant}
			options={options}
			height={36}
			value={backendValueToBooleanFieldValue(value)}
			onChange={(v) => onChange(booleanFieldValueToBackendValue(v))}
			onBlur={onBlur}
		/>
	);
}

const options = [
	{ label: 'True', value: 'true' },
	{ label: 'False', value: 'false' },
];

function backendValueToBooleanFieldValue(v: boolean | null): StringifiedBoolean {
	if (v === true) return 'true';
	if (v === false) return 'false';
	return '';
}

function booleanFieldValueToBackendValue(v: StringifiedBoolean): boolean | null {
	if (v === 'true') return true;
	if (v === 'false') return false;
	return null;
}
