import { TitleFull as Title, titlePaths } from '@warehouse/title/core';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import useLocalizedInfosDropdown from '../../../../src/views/title/tab/productMetadata/localizedInfo/useLocalizedInfosDropdown';
import Dropdown from '../../../../src/components/library/Dropdown/Dropdown';
import { findDefaultUuidForKey, fromBasic } from '../../../../src/utils/titleGetProperty';
import sortAlphabeticallyByLabel from '../../../../src/utils/sortAlphabeticallyByLabel';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';

type DefaultLocalizedInfoProps = {
	title: Title | undefined;
};

export function DefaultLocalizedInfo({ title }: DefaultLocalizedInfoProps) {
	const changesAppliedRef = useRef(true);
	const localizedInfosDropdown = useLocalizedInfosDropdown(title);
	const defaultLocalizedInfoUuid = findDefaultUuidForKey(title as Title, 'localizedInfos');
	const memoizedPath = useMemo(
		() => fromBasic(['localizedInfos', localizedInfosDropdown.selected || '', 'default']),
		[localizedInfosDropdown.selected],
	);
	const { setValue, commit } = useTitleAutoSave<boolean>({
		label: 'Default Localized Info',
		path: memoizedPath,
		shouldDistinctUntilChanged: false,
	});

	const sortedOptions = useMemo(
		() => localizedInfosDropdown.options.sort(sortAlphabeticallyByLabel),
		[localizedInfosDropdown.options],
	);

	const onSelect = async (selectedUuid: string | null) => {
		if (selectedUuid === defaultLocalizedInfoUuid) return;
		if (!selectedUuid) return;
		if (!title) return;

		localizedInfosDropdown.setSelected(selectedUuid || undefined);
		changesAppliedRef.current = false;
	};

	useEffect(() => {
		if (changesAppliedRef.current) return;
		setValue(true);
		commit({ force: true });
	}, [localizedInfosDropdown.selected]);

	return useMemo(
		() =>
			defaultLocalizedInfoUuid ? (
				<FieldWrapper>
					<TitleEditableField
						label="Default Localized Info"
						path={titlePaths.defaultLocalizedInfo(defaultLocalizedInfoUuid)}
						direction="column"
						readComponent={undefined}
						editComponent={undefined}
					/>
					<Dropdown
						height={38}
						verticalPadding={0}
						disabledMargin
						withSearch
						options={sortedOptions || []}
						value={localizedInfosDropdown.selected || null}
						onChange={onSelect}
					/>
				</FieldWrapper>
			) : null,
		[localizedInfosDropdown.selected, defaultLocalizedInfoUuid, sortedOptions],
	);
}

const FieldWrapper = styled.div(
	({ theme }) => `
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(1)};
		svg {
			color: ${theme.palette.text.secondary};
		}
		margin-bottom: 14px;
		
				.title-editable-field {
			padding: 0;
		}
	`,
);
