export function getRowAtIndex(rows: any[], stringIndex: string) {
	if (stringIndex.includes('.')) {
		const indexes = stringIndex.split('.').map((i) => (Number.isNaN(parseInt(i, 10)) ? undefined : parseInt(i, 10)));

		if (indexes.some((i) => i === undefined)) {
			return undefined;
		}

		const definedIndexes = indexes as number[];

		let tmpRow = rows[definedIndexes[0]];

		if (!tmpRow) return undefined;

		definedIndexes.slice(1).forEach((rowIndex: number) => {
			if (!tmpRow?.subRows) return;

			tmpRow = tmpRow.subRows[rowIndex];
		});

		return tmpRow;
	}
	const index = parseInt(stringIndex, 10);

	if (Number.isNaN(index)) return undefined;

	return rows[index];
}

export function filterRowsByIndex(rows: any[] | undefined, indexes: string[]) {
	if (!rows) return undefined;
	return rows.filter((_, i) => !indexes.includes(i.toString()));
}

export function getParentGroupOfRowAtIndex(rows: any[], stringIndex: string) {
	if (stringIndex.includes('.')) {
		const indexes = stringIndex.split('.').map((i) => (Number.isNaN(parseInt(i, 10)) ? undefined : parseInt(i, 10)));

		if (indexes.some((i) => i === undefined)) {
			return undefined;
		}

		const definedIndexes = indexes as number[];

		let tmpRow = rows[definedIndexes[0]];

		if (!tmpRow) return undefined;

		definedIndexes.slice(1, -1).forEach((rowIndex: number) => {
			if (!tmpRow?.subRows) return;
			tmpRow = tmpRow.subRows[rowIndex];
		});

		return tmpRow;
	}
	const index = parseInt(stringIndex, 10);

	if (Number.isNaN(index)) return undefined;

	return rows[index];
}
