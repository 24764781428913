import React, { ChangeEvent, InputHTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import { alpha, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { mdiInformation } from '@mdi/js';
import {
	Label,
	Wrapper,
	ReadOnlyValue,
	SubMessageLayoutWrapper,
	SubMessageWrapper,
	IconWrapper,
	TooltipIcon,
	SubMessage,
	InputButton,
	InputIcons,
	Input,
	LabelBlockWrapper,
} from './InputUtils';
import Tooltip from './Tooltip';

type NumberInputVariant = 'default' | 'prefilled';

interface NumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
	ariaLabel?: string;
	label?: string;
	tooltip?: string;
	inlineLabel?: boolean;
	confirmation?: boolean;
	value?: string;
	error?: string;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	height?: number;
	width?: string;
	fullWidth?: boolean;
	disableRightIcon?: boolean;
	labelIcon?: React.ReactNode;
	variant?: NumberInputVariant;
	disableRightPadding?: boolean;
	disabledMargin?: boolean;
	disablePenIcon?: boolean;
}

type InputWrapperProps = {
	error?: string;
	disabled?: boolean;
	confirmation?: boolean;
	value?: string;
	tempValue?: string;
	height?: number;
};

const ArrowsWrapper = styled.div(
	() => css`
		display: none;
		flex-direction: column;
		justify-content: center;
	`,
);

const InputWrapper = styled.div<InputWrapperProps>(
	({ theme, error, disabled, confirmation, value, tempValue, height }) => css`
		align-items: stretch;
		background-color: ${theme.palette.light.background};
		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		flex-grow: 1;
		font-size: 14px;
		height: ${height ? `${height}px` : '48px'};
		justify-content: space-between;
		position: relative;

		input {
			color: ${theme.palette.text.secondary};
		}

		//Hiding Number input arrows
		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type='number'] {
			-moz-appearance: textfield;
		}

		&:hover {
			border-color: ${theme.palette.blue.main};
			background-color: ${theme.palette.light.main};
			${IconWrapper} {
				color: ${theme.palette.blue.main};
			}

			input {
				color: ${theme.palette.text.primary};
			}

			${!disabled &&
			css`
				.editIcon {
					display: block !important;
				}
			`}
		}

		&:focus-within {
			border-color: ${theme.palette.blue.main};
			background-color: ${theme.palette.light.main};
			${IconWrapper} {
				color: ${theme.palette.blue.main};
			}

			input {
				color: ${theme.palette.text.primary};
			}

			${!disabled &&
			css`
				.editIcon {
					display: none !important;
				}
				${ArrowsWrapper} {
					display: flex;
				}
			`}
		}

		:focus-visible {
			outline: none;
		}

		${confirmation && tempValue !== value
			? css`
					border-bottom-right-radius: 0;
			  `
			: null}

		${error && !disabled
			? css`
					background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
					border-bottom-right-radius: 0;
					border-color: ${theme.palette.error.main} !important;
					color: ${theme.palette.error.text};

					input {
						color: ${theme.palette.error.text};
					}
			  `
			: null}

		${disabled &&
		css`
			background: none !important;
			border: 1px solid ${theme.palette.light.backgroundAlt} !important;
			cursor: not-allowed;

			input {
				color: ${theme.palette.action.disabled};
				cursor: not-allowed;
			}
		`}
	`,
);

const ArrowButton = styled.button(
	() => css`
		align-items: center;
		background-color: inherit;
		border: none;
		cursor: pointer;
		display: flex;
		padding: 0;
	`,
);

const CustomInput = styled(Input)<{ width?: string }>(
	({ width }) => css`
		${width &&
		css`
			width: ${width};
		`}
	`,
);

function NumberInput(props: NumberInputProps) {
	const {
		ariaLabel,
		label,
		inlineLabel,
		readOnly,
		disabled,
		placeholder,
		confirmation,
		value,
		onChange,
		error,
		className,
		startIcon,
		endIcon,
		tooltip,
		height,
		width,
		labelIcon,
		fullWidth,
		disableRightIcon,
		disableRightPadding,
		disabledMargin,
		disablePenIcon,
		variant = 'default',
		...rest
	} = props;
	const [tempValue, setTempValue] = useState(value);

	useEffect(() => {
		setTempValue(value);
	}, [value]);

	return (
		<Wrapper
			inlineLabel={inlineLabel}
			disabled={disabled}
			fullWidth={fullWidth}
			className={className}
			disableRightPadding={disableRightPadding}
			disabledMargin={disabledMargin}
		>
			{label && (
				<Label variant="buttonLargeMedium" error={error}>
					<>
						<LabelBlockWrapper>
							{label}
							{tooltip && (
								<Tooltip title={tooltip} placement="right">
									<TooltipIcon path={mdiInformation} size="16px" />
								</Tooltip>
							)}
						</LabelBlockWrapper>
						{labelIcon}
					</>
				</Label>
			)}
			{readOnly ? (
				<ReadOnlyValue>{value || placeholder || ''}</ReadOnlyValue>
			) : (
				<SubMessageLayoutWrapper>
					<InputWrapper
						error={error}
						{...rest}
						disabled={disabled}
						className="input-wrapper"
						confirmation={confirmation}
						value={value}
						tempValue={tempValue}
						height={height}
					>
						{startIcon && <IconWrapper $start>{startIcon}</IconWrapper>}

						<CustomInput
							aria-label={ariaLabel ?? label}
							role="textbox"
							type="number"
							value={confirmation ? tempValue : value}
							placeholder={placeholder}
							disabled={disabled}
							onChange={
								confirmation
									? (e) => {
											setTempValue(e.target.value);
									  }
									: onChange
							}
							onKeyDown={(e) => {
								if (['e', '+'].includes(e.key)) {
									e.preventDefault();
								}
							}}
							width={width}
							{...rest}
							className={classNames({ inherited: variant === 'prefilled' })}
						/>
						<InputIcons disableMargin={disableRightIcon}>
							{confirmation && value !== tempValue ? (
								<>
									<InputButton onClick={() => setTempValue(value)}>
										<CancelIcon color="error" />
									</InputButton>
									<InputButton
										onClick={() => {
											if (onChange && value && tempValue) {
												const mockEvent = {
													target: { value: tempValue },
												} as ChangeEvent<HTMLInputElement>;
												onChange(mockEvent);
											}
										}}
									>
										<CheckCircleIcon color="info" />
									</InputButton>
								</>
							) : (
								!disableRightIcon &&
								!disablePenIcon && <EditIcon className="editIcon" style={{ display: 'none', alignSelf: 'center' }} />
							)}

							{!disableRightIcon && (
								<ArrowsWrapper>
									<ArrowButton
										tabIndex={-1}
										onClick={() => {
											if (confirmation && tempValue) {
												setTempValue((parseInt(tempValue, 10) + 1).toString());
											} else if (onChange && value !== undefined && value !== 'undefined') {
												const mockEvent = {
													target: {
														value: (parseInt(value, 10) + 1).toString(),
													},
												} as ChangeEvent<HTMLInputElement>;
												onChange(mockEvent);
											} else if (onChange && (!value || value === 'undefined')) {
												const mockEvent = {
													target: {
														value: '1',
													},
												} as ChangeEvent<HTMLInputElement>;
												onChange(mockEvent);
											}
										}}
									>
										<KeyboardArrowUpIcon fontSize="small" />
									</ArrowButton>
									<ArrowButton
										tabIndex={-1}
										onClick={() => {
											if (confirmation && tempValue) {
												setTempValue((parseInt(tempValue, 10) - 1).toString());
											} else if (onChange && value !== undefined && value !== 'undefined') {
												const mockEvent = {
													target: {
														value: (parseInt(value, 10) - 1).toString(),
													},
												} as ChangeEvent<HTMLInputElement>;
												onChange(mockEvent);
											} else if (onChange && (!value || value === 'undefined')) {
												const mockEvent = {
													target: {
														value: '0',
													},
												} as ChangeEvent<HTMLInputElement>;
												onChange(mockEvent);
											}
										}}
									>
										<KeyboardArrowDownIcon fontSize="small" />
									</ArrowButton>
								</ArrowsWrapper>
							)}
						</InputIcons>
						{endIcon && <IconWrapper>{endIcon}</IconWrapper>}
					</InputWrapper>
					{confirmation && value !== tempValue ? (
						<SubMessageWrapper>
							<SubMessage color="info">
								<ErrorIcon />
								<Typography>Please confirm or cancel your changes</Typography>
							</SubMessage>
						</SubMessageWrapper>
					) : null}
					{error ? (
						<SubMessageWrapper>
							<SubMessage color="error">
								<WarningIcon />
								<Typography>{error}</Typography>
							</SubMessage>
						</SubMessageWrapper>
					) : null}
				</SubMessageLayoutWrapper>
			)}
		</Wrapper>
	);
}

export default NumberInput;
