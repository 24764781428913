import styled from 'styled-components';

export const PaddingWrapper = styled.div(
	({ theme }) => `
		body {
			overflow: hidden !important;
		}
		
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	
		overflow-y: auto;
		height: calc(100vh - 128px);
		padding: ${theme.spacing(3, 2)};
		width: 700px;
		box-sizing: border-box;
	`,
);

export const ContentWrapper = styled.div(
	({ theme }) => `
		.MuiTypography-root {
			color: ${theme.palette.text.primary};
			font-size: ${theme.typography.buttonMediumMedium.fontSize};
		}
	`,
);

export const FieldWrapper = styled.div(
	({ theme }) => `
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(1)};
		svg {
			color: ${theme.palette.text.secondary};
		}
		margin-bottom: 14px;
	`,
);

export const TableWrapper = styled.div(
	({ theme }) => `
		.table-wrapper {
			margin-top: ${theme.spacing(1)};
		}
		
		.title-editable-field-label {
			font-size: ${theme.typography.buttonMediumMedium.fontSize} !important;
		}
		
		table {
			overflow: hidden;
		}
		
		display: flex;
		flex-direction: column;
		gap: 14px;
		`,
);

export const Row = styled.div(
	({ theme }) => `
		display: flex;
		flex-direction: row;
		align-items: start;
		gap: ${theme.spacing(1)};
		
    &>div:only-child {
      flex: 1;
		}

    &>div:first-child {
      flex: 7;
		}

    &>div:nth-child(2) {
      flex: 3;
    }
		
	`,
);

export const SharedRow = styled.div(
	({ theme }) => `
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: ${theme.spacing(1)};
		& > * {
			flex: 1;
		}
	`
)
