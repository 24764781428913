import React from 'react';
import { TitleDatePickerField } from '@warehouse/title/ui';
import { titlePaths } from '@warehouse/title/core';

export function ReleaseDateField() {
	return (
		<TitleDatePickerField
			label="Release Date:"
			path={titlePaths.releaseDate}
			ariaLabel="Release Date Field"
			jumpToParentButtonDisabled
		/>
	);
}
