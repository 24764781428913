import React, { useMemo } from 'react';
import { ContentIdentifier } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/ContentIdentifier';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

// JSON
import { isInherited } from '@warehouse/title/core';
import availContentIDNS from '../../../../../../../assets/json-administration-profiles/availableContentIDNamespaces.json';
import workTypes from '../../../../../../../assets/json-administration-profiles/workTypes.json';
import availWorkTypeDetailsByWorkType from '../../../../../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import { InheritedReadOnlyText } from '../../../../../../../../libs/title/ui/table/InheritedReadOnlyText';

export function RenderReadOnlyNamespace(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;
	const contentIDNamespace = useMemo(
		() => availContentIDNS.find(({ value: _value }) => _value === displayValue.namespace),
		[displayValue.namespace, availContentIDNS],
	);

	if (!contentIDNamespace) return null;
	return <InheritedReadOnlyText isInherited={isInheritedValue}>{contentIDNamespace.label}</InheritedReadOnlyText>;
}

export function RenderReadOnlyScope(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;
	const workType = useMemo(() => {
		if (!displayValue.scope?.scope) return undefined;
		return workTypes.find((wt) => wt.value === displayValue.scope?.scope);
	}, [displayValue.scope?.scope, workTypes]);

	if (!workType) return null;
	return <InheritedReadOnlyText isInherited={isInheritedValue}>{workType.label}</InheritedReadOnlyText>;
}

export function RenderReadOnlySubScope(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;
	const workTypeDetail = useMemo(() => {
		if (!displayValue.scope?.scope || !displayValue.scope?.subscope) return undefined;
		const workType = Object.values(availWorkTypeDetailsByWorkType).find(
			({ uuid }) => uuid === displayValue.scope?.scope,
		);
		if (!workType) return undefined;
		return workType.availableWorkTypeDetails.find(({ value: _value }) => _value === displayValue.scope?.subscope);
	}, [displayValue.scope?.scope, displayValue.scope?.subscope, availWorkTypeDetailsByWorkType]);

	if (!workTypeDetail) return null;
	return <InheritedReadOnlyText isInherited={isInheritedValue}>{workTypeDetail.label}</InheritedReadOnlyText>;
}

export function RenderReadOnlyIdentifier(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;

	return <InheritedReadOnlyText isInherited={isInheritedValue}>{displayValue.identifier}</InheritedReadOnlyText>;
}

export function RenderReadOnlyLocation(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;

	return <InheritedReadOnlyText isInherited={isInheritedValue}>{displayValue.location}</InheritedReadOnlyText>;
}
