import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { CellWrapper } from '../../../../../src/components/library/SimpleTable/style';
import { TableTextInput } from '../../../../../src/views/title/tab/productMetadata/style';
import { ChildrenColumnType } from '../../children-compilation-extended.model';
import { UseEditEntryNumberOutput } from './use-edit-entry-number';

export function EntryNumberEditCell<T extends ChildrenColumnType>(info: CellContext<T, T>) {
	const props = simpleTableInfoToEntryNumberEditProps(info);

	if (!props) return null;

	const { updateEntryNumber, commit, entryNumber } = props;

	return (
		<CellWrapper onClick={(e) => e.stopPropagation()}>
			<TableTextInput
				aria-label="Entry Number Input"
				enableFocusBorder={false}
				onChange={(e) => updateEntryNumber(e.target.value)}
				onBlur={() => commit()}
				value={entryNumber}
			/>
		</CellWrapper>
	);
}

function isUseEditEntryNumberOutput(payload: unknown): payload is UseEditEntryNumberOutput {
	const isDefinedAndObject = !!payload && typeof payload === 'object';
	if (!isDefinedAndObject) return false;

	const containsCommitFunction = 'commit' in payload && typeof payload.commit === 'function';
	if (!containsCommitFunction) return false;

	const containsUpdateEntryNumberFunction =
		'updateEntryNumber' in payload && typeof payload.updateEntryNumber === 'function';
	if (!containsUpdateEntryNumberFunction) return false;

	return true;
}

function simpleTableInfoToEntryNumberEditProps<T extends ChildrenColumnType>(
	info: CellContext<T, T>,
): EntryNumberEditCellProps | null {
	const editEntryNumber = info.table.options?.meta?.customPropsCell?.editEntryNumber;

	if (isUseEditEntryNumberOutput(editEntryNumber)) {
		const { commit, updateEntryNumber, compilationEntries } = editEntryNumber;

		const entryNumber = compilationEntries?.find(
			(entry) => entry.contentId === info.row.original.uuid,
		)?.compilationEntryNumber;

		return {
			commit,
			updateEntryNumber: (newEntryNumber: string | undefined) =>
				updateEntryNumber(newEntryNumber, info.row.original.uuid),
			entryNumber,
		};
	}

	return null;
}

export interface EntryNumberEditCellProps {
	entryNumber: string | undefined;
	commit: () => void;
	updateEntryNumber: (newEntryNumber: string | undefined) => void;
}
