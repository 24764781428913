import React, { useContext, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { TitleFull as Title } from '@warehouse/title/core';
import { Portal, useTheme } from '@mui/material';

// LIBRARY
import Dropdown from '../../../../../components/library/Dropdown/Dropdown';
import sortAlphabeticallyByLabel from '../../../../../utils/sortAlphabeticallyByLabel';
import { findDefaultLocaleUuidForTitle } from '../../../../../utils/titleGetProperty';
import { FullWidthHeightWrapper } from '../../../../../components/common';
import AddCastAndCrewDrawer from './drawer/AddCastAndCrewDrawer';
import CastTable from './CastTable';
import CrewTable from './CrewTable';
import { BottomRow, Column } from '../style';

// HOOKS
import { useURLFragment } from '../../../../../utils/hooks/useURLFragment';

// JSON
import languageWrittenJSON from '../../../../../assets/json-administration-profiles/availableLanguageWritten.json';
import { useStateQuery } from '../../../../../utils/hooks/useStateQuery';
import { CastAndCrewContext, getMaxBillingBlockOrder } from './providers/castAndCrewProvider';

const castAndCrewFragmentStartWith = 'product-metadata/cast-crew/';

export const TopRow = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		gap: ${theme.spacing(2)};
		height: 56px !important;
		justify-content: start;
		min-height: 56px !important;
		padding-left: ${theme.spacing(3)};
	`,
);

export const Field = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(1)};
	`,
);

function ProductMetadataTabPeopleTab({
	editMode,
	title,
	wrapperRef,
}: {
	title: Title | undefined;
	editMode: boolean;
	wrapperRef: React.RefObject<HTMLDivElement>;
}) {
	const { addToArray, data, castData, setCurrentLocale } = useContext(CastAndCrewContext);
	const theme = useTheme();
	const [fragment, setFragment] = useURLFragment();
	const [peopleIndex, setPeopleIndex] = useStateQuery<number | undefined>({
		queryName: 'peopleDetails',
		type: 'number',
	});

	const defaultLocaleUuid = useMemo(() => {
		if (!title) return undefined;
		return findDefaultLocaleUuidForTitle(title);
	}, [title]);

	const localeOptions = useMemo(
		() => languageWrittenJSON.sort(sortAlphabeticallyByLabel).map(({ label, uuid }) => ({ label, value: uuid })),
		[languageWrittenJSON],
	);

	const selectedValue = useMemo(() => {
		if (!fragment.startsWith(castAndCrewFragmentStartWith)) return null;
		const selected = fragment.slice(castAndCrewFragmentStartWith.length);
		if (selected === 'default') return defaultLocaleUuid ?? null;
		return selected;
	}, [fragment, defaultLocaleUuid]);

	useEffect(() => {
		setCurrentLocale(selectedValue || undefined);
	}, [selectedValue]);

	return (
		<FullWidthHeightWrapper style={{ flexDirection: 'column' }}>
			<Portal container={wrapperRef.current}>
				<AddCastAndCrewDrawer
					open={peopleIndex !== undefined}
					onClose={() => setPeopleIndex(null)}
					peopleIndex={peopleIndex ?? -1}
					title={title}
				/>
			</Portal>
			<TopRow>
				<Field>
					<Dropdown
						disabledMargin
						verticalPadding={0}
						height={36}
						width={300}
						withSearch
						backgroundColor={theme.palette.light.main}
						options={localeOptions}
						value={selectedValue}
						onChange={(selected) =>
							selected
								? setFragment(`${castAndCrewFragmentStartWith}${selected}`)
								: setFragment(`${castAndCrewFragmentStartWith}default`)
						}
						onKeyDown={(e) => {
							if (e.key === 'Escape') setFragment(`${castAndCrewFragmentStartWith}default`);
						}}
					/>
				</Field>
			</TopRow>
			<BottomRow>
				<Column>
					<CastTable
						editMode={editMode}
						localeUuid={selectedValue}
						origin=""
						defaultLocaleUuid={defaultLocaleUuid}
						onCastClick={(index) => setPeopleIndex(index)}
						onAddCast={() => {
							addToArray('people', {
								jobs: [
									{
										billingBlockOrders: castData
											? [
													{
														billingBlockOrder: getMaxBillingBlockOrder(castData) + 1,
													},
											  ]
											: [],
										characterInfos: [
											{
												characterNames: [
													{
														characterName: '',
														language: defaultLocaleUuid,
													},
												],
											},
										],
										jobFunction: 'actor',
									},
								],
								name: {
									displayNames: [
										{
											displayName: '',
											language: defaultLocaleUuid,
										},
									],
								},
							});
							setPeopleIndex(data?.displayValue?.length || 0);
						}}
					/>
				</Column>
				<Column>
					<CrewTable
						editMode={editMode}
						localeUuid={selectedValue}
						origin=""
						defaultLocaleUuid={defaultLocaleUuid}
						onCrewClick={(index) => setPeopleIndex(index)}
						onAddCrew={() => {
							addToArray('people', {
								jobs: [
									{
										billingBlockOrders: [],
										characterInfos: [],
									},
								],
								name: {
									displayNames: [
										{
											displayName: '',
											language: defaultLocaleUuid,
										},
									],
								},
							});
							setPeopleIndex(data?.displayValue?.length || 0);
						}}
					/>
				</Column>
			</BottomRow>
		</FullWidthHeightWrapper>
	);
}

export default ProductMetadataTabPeopleTab;
