import { RelationshipType, CommonReadonly, TitleLightWithSelectedParent, WorkType } from '@warehouse/title/core';
import { getRelationshipTypeObject, getWorkTypeObject, getRelationshipSubtypeObject } from '@warehouse/title/infra';
import { CustomColumnDef } from '../../../../src/components/library/SimpleTable/customColumnDef';

export function RelationshipTypeColumn<T extends TitleLightWithSelectedParent>(
	cellProps: 'children' | 'parents',
): CustomColumnDef<T, RelationshipType> {
	return {
		header: 'Relationship Type',
		accessorFn: (row) => row.selectedParent.relationshipType,
		renderReadOnly: (v) =>
			cellProps === 'children' ? getRelationshipTypeObject(v).labelTarget : getRelationshipTypeObject(v).label,
		id: 'relationshipType',
		editable: false,
	};
}

export const RelationshipSubTypeColumn: CustomColumnDef<TitleLightWithSelectedParent, string | undefined> = {
	header: 'Relationship Subtype',
	accessorFn: (row) => row.selectedParent.relationshipSubtypes?.[0],
	renderReadOnly: (v) => {
		if (!v) return null;
		try {
			const relationshipSubtypeObject = getRelationshipSubtypeObject(v);
			return relationshipSubtypeObject.label;
		} catch (_) {
			return v;
		}
	},
	id: 'relationshipSubType',
	editable: false,
};

export function TitleTypeColumn<T extends CommonReadonly>(): CustomColumnDef<T, WorkType> {
	return {
		header: 'Title Type',
		accessorFn: (row) => row.workType,
		renderReadOnly: (v) => getWorkTypeObject(v).label,
		id: 'workTypeUuid',
		editable: false,
	};
}
