import React from 'react';
import { RelationshipSubtypeSelector } from '@warehouse/title/ui';
import { Typography } from '@mui/material';
import { FieldWrapper } from '../../ui/FieldWrapper';

export function RelationshipSubtypeField({
	relationshipSubtype,
	onRelationshipSubtypeChange,
	commit,
}: RelationshipSubtypeFieldProps) {
	const label = 'Relationship Subtype';

	return (
		<FieldWrapper>
			<Typography variant="buttonMediumMedium" color="text.primary">
				{label}
			</Typography>
			<RelationshipSubtypeSelector
				ariaLabel={label}
				selected={relationshipSubtype}
				onSelect={onRelationshipSubtypeChange}
				onBlur={commit}
			/>
		</FieldWrapper>
	);
}

interface RelationshipSubtypeFieldProps {
	relationshipSubtype?: string;
	onRelationshipSubtypeChange: (relationshipSubtype: string) => void;
	commit: () => void;
}
