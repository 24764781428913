import React, { ReactElement } from 'react';

interface ConditionalWrapperProps {
	condition: boolean;
	wrapper: (children: ReactElement) => ReactElement | null;
	children: ReactElement;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
	condition ? wrapper(children) : children;

export default ConditionalWrapper;
