import React from 'react';
import { ConfigProvider } from 'antd';
import { alpha } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles/createTheme';

function AntdProvider({ theme, children }: { theme: Theme; children: React.ReactNode }) {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: theme.palette.blue.main,
					colorBgContainer: '#F6F8FA',
					colorText: 'rgba(0, 0, 0, 0.8)',
					colorTextPlaceholder: 'rgba(0, 0, 0, 0.5)',
					controlOutline: 'transparent',
					fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif;",
				},
				components: {
					Select: {
						controlHeight: 48,
						colorTextQuaternary: 'rgba(0, 0, 0, 0.8)',
						optionPadding: '8px 16px',
						optionActiveBg: alpha(theme.palette.blue.main, 0.3),
						optionSelectedBg: alpha(theme.palette.blue.main, 0.3),
						optionSelectedFontWeight: '400',
						optionLineHeight: '32px',
						paddingXXS: 0,
						borderRadiusSM: 0,
						borderRadius: 8,
					},
					Tag: {
						borderRadiusSM: 16,
						fontSizeSM: 12,
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	);
}

export default AntdProvider;
