import { Typography } from '@mui/material';
import React from 'react';
import { TextInput } from '@warehouse/shared/legacy-ui';
import { FieldWrapper } from '../../ui/FieldWrapper';

export function DistributionNumberField({
	distributionNumber,
	onDistributionNumberChange,
	onBlur,
	placeholder,
}: DistributionNumberFieldProps) {
	return (
		<FieldWrapper className="field-wrapper">
			<Typography variant="buttonMediumMedium" color="text.primary">
				Distribution Number
			</Typography>
			<TextInput
				ariaLabel="Distribution Number"
				value={distributionNumber}
				onChange={(e) => onDistributionNumberChange(e.target.value)}
				required={false}
				onBlur={onBlur}
				disabledMargin
				placeholder={placeholder}
			/>
		</FieldWrapper>
	);
}

type DistributionNumberFieldProps = {
	distributionNumber: string | undefined;
	onDistributionNumberChange: (distributionNumber: string | undefined) => void;
	onBlur?: () => void;
	placeholder?: string;
};
