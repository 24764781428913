import { ApolloError } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';
import { titleRepositorySingleton } from '@warehouse/title/infra';
import { EditTitleInput, EditTitleOptions } from '@warehouse/title/core';

interface UseEditTitleV2Options {
	onCompleted?: () => void;
	onError?: (error: ApolloError) => void;
}

export function useEditTitleV2(options: UseEditTitleV2Options = {}) {
	const repo = titleRepositorySingleton.get();

	return useMutation({
		mutationFn: async (args: { editTitleInput: EditTitleInput; options?: EditTitleOptions }) =>
			repo.editTitle(args.editTitleInput, args.options),
		onSuccess: options.onCompleted,
		onError: options.onError,
	});
}
