import React from 'react';
import styled from 'styled-components';

export function JumpToParentButtonHoverWrapper({ children }: { children: React.ReactNode }) {
	return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
	&:hover {
		.jump-to-parent-button {
			visibility: visible !important;
		}
	}
`;
