import React from 'react';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { Header } from '@tanstack/react-table';

import { DropDownButton, SubHeaderTH, CustomPopover } from '../style';
import Dropdown from '../../Dropdown/Dropdown';
import { CustomColumnDef } from '../customColumnDef';

interface DefaultDropdownProps {
	handleDropdownButton: (columnId: string, anchorEl?: HTMLButtonElement) => void;
	openDropdownButton: { [key: string]: boolean };
	header: Header<any, any>;
	popperRef: React.RefObject<HTMLDivElement>;
	anchorRef: HTMLButtonElement | null;
	handleSubHeaderDropdownChange: (id: string, value: string) => void;
	selectedDropdownValues: { [key: string]: string };
	dropdownProps: any;
}

function SubHeaderSearchDropdown({
	handleDropdownButton,
	openDropdownButton,
	header,
	popperRef,
	anchorRef,
	handleSubHeaderDropdownChange,
	selectedDropdownValues,
	dropdownProps,
}: DefaultDropdownProps) {
	return (
		<SubHeaderTH style={{ textTransform: 'none' }}>
			<DropDownButton
				disableRipple
				endIcon={<Icon path={mdiChevronDown} size={1} />}
				onClick={(e) =>
					handleDropdownButton(header.column.columnDef.id as string, e.currentTarget as HTMLButtonElement)
				}
				$isOpen={openDropdownButton[header.column.columnDef.id as string] || false}
			>
				{selectedDropdownValues[header.column.columnDef.id as string]
					? ((header.column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []).find(
							(option) => option.value === selectedDropdownValues[header.column.columnDef.id as string],
					  )?.label
					: 'Set default'}
			</DropDownButton>
			<CustomPopover
				ref={popperRef}
				open={openDropdownButton[header.column.columnDef.id as string] || false}
				anchorEl={anchorRef}
				placement="bottom-start"
			>
				<Dropdown
					withSearch
					portalRef={popperRef}
					options={(header.column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
					value={selectedDropdownValues[header.column.columnDef.id as string] || ''}
					onChange={(value: any) => {
						handleSubHeaderDropdownChange(header.column.columnDef.id as string, value);
						handleDropdownButton(header.column.columnDef.id as string);
					}}
					enablePortal
					forceActive
					{...dropdownProps}
				/>
			</CustomPopover>
		</SubHeaderTH>
	);
}

export default SubHeaderSearchDropdown;
