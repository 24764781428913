import { SearchFilters } from '../../title/domain/SearchFilters';

export class RelationshipFilters extends SearchFilters {
	static childrenOf(titleUuid: string, excludeHidden?: boolean): FilterStep {
		return {
			step: {
				combinationOperator: 'AND',
				filters: [
					{
						field: 'relationship.contentId',
						operator: 'equals',
						value: titleUuid,
					},
					{
						field: 'direction',
						operator: 'equals',
						value: 'parent',
					},
					...(excludeHidden
						? [
								{
									field: 'indexed.titleStatus',
									operator: 'doesNotEqual' as const,
									value: 'hidden',
								},
						  ]
						: []),
				],
			},
		};
	}
}
