import React from 'react';
import Button from '../Button';
import { ExpandButtonsWrapper } from './style';

interface TopActionBarProps {
	onExpandAll: () => void;
	onCollapseAll: () => void;
}

export function TopActionBar({ onExpandAll, onCollapseAll }: TopActionBarProps) {
	return (
		<ExpandButtonsWrapper>
			<Button nxstyle="tertiary-light" onClick={onExpandAll}>
				Expand all
			</Button>
			<Button nxstyle="tertiary-light" onClick={onCollapseAll}>
				Collapse all
			</Button>
		</ExpandButtonsWrapper>
	);
}
