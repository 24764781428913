import React, { useMemo } from 'react';

// TYPES
import { CountryOfOriginRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

// LIBRARY
import { titlePaths } from '@warehouse/title/core';
import useTerritories, { getLabel } from '../../../../src/utils/hooks/useTerritories';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import getMaxListingOrder from '../../../../src/components/titles/TitleOverview/getMaxListingOrder';
import { sortListingOrderNonInherited } from '../../../../src/components/titles/TitleOverview/sortListingOrder';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import ChipDisplay from '../../../../src/components/library/ChipDisplay';
import { isInherited as isInheritedFunction } from '../../core/title-inheritance';
import { SelectDropdownWrapper } from '../../../../src/components/titles/TitleOverview/fields/style';
import { TerritoriesSelector } from '../../../../src/components/titles/TerritoriesSelector';

export function ProductionCountriesField() {
	const { getKey } = useTerritories();

	const productionCountries = useTitleAutoSave<OptionalInherited<CountryOfOriginRegion[]>>({
		label: 'Production Countries',
		path: useMemo(() => titlePaths.countriesOfOrigin.split('.'), []),
	});

	const maxListingOrder = useMemo(
		() => getMaxListingOrder(productionCountries?.value?.displayValue),
		[productionCountries?.value?.displayValue],
	);

	const filteredProductionCountriesValue = useMemo(
		() => productionCountries?.value?.displayValue?.filter((coo) => coo?.interpretation === 'production') || [],
		[productionCountries?.value?.displayValue],
	);

	const filteredProductionCountriesReadOnlyValue = useMemo(
		() => productionCountries?.readOnlyValue?.displayValue?.filter((coo) => coo?.interpretation === 'production') || [],
		[productionCountries?.readOnlyValue?.displayValue],
	);

	const selectedIds = useMemo(
		() =>
			[...(filteredProductionCountriesValue || [])]
				?.sort(sortListingOrderNonInherited)
				?.map((pc) => pc.country || pc.countryRegion || '') || [],
		[filteredProductionCountriesValue],
	);

	const displayValues = useMemo(
		() =>
			[...(filteredProductionCountriesReadOnlyValue || [])]
				?.sort(sortListingOrderNonInherited)
				?.map((pc) => getLabel(pc.country || pc.countryRegion || ''))
				?.filter((item): item is string => item !== undefined) || [],
		[filteredProductionCountriesReadOnlyValue],
	);

	const { isInherited } = isInheritedFunction({ inheritedObject: productionCountries?.value });

	return (
		<TitleEditableField
			label="Production Countries:"
			path={titlePaths.countriesOfOrigin}
			jumpToParentButtonDisabled
			height={48}
			labelStyle={{
				fontSize: '13.3px',
			}}
			ruleOrigin={origin}
			readComponent={
				<ChipDisplay
					allowOverflow
					horizontalPadding={0}
					variant={isInherited ? 'prefilled' : 'default'}
					calcWidth="calc(100% - 170px)"
					values={displayValues}
					height={26}
				/>
			}
			editComponent={
				<SelectDropdownWrapper style={{ width: 'calc(100% - 145px)' }}>
					<TerritoriesSelector
						multiple
						variant={isInherited ? 'prefilled' : 'default'}
						value={selectedIds}
						height={38}
						listItemHeight={38}
						chipHeight={16}
						onBlur={() => productionCountries?.commit()}
						onSelect={(value) => {
							const regionToAdd: CountryOfOriginRegion = {
								[getKey(value)]: value,
								listingOrder: maxListingOrder + 1,
								interpretation: 'production',
							};
							productionCountries?.setValue((prev) => ({
								...prev,
								displayValue: [...(prev?.displayValue || []), regionToAdd],
							}));
						}}
						onDeselect={(value) => {
							const check = filteredProductionCountriesValue?.find((coo) => coo[getKey(value)] === value);
							if (check)
								productionCountries?.setValue((prev) => ({
									...prev,
									displayValue: prev?.displayValue?.filter((coo) => {
										if (coo.interpretation !== 'production') return true;
										return coo[getKey(value)] !== value;
									}),
								}));
						}}
					/>
				</SelectDropdownWrapper>
			}
		/>
	);
}
