import { useMutation } from '@apollo/client';
import { CreateTitleV2, GetTitleOutput, CREATE_TITLE_V2, GET_TITLE_V2 } from '@warehouse/graphql';

function useCreateTitleV2() {
	return useMutation<
		{
			createTitleV2: GetTitleOutput;
		},
		{ input: CreateTitleV2 }
	>(CREATE_TITLE_V2, {
		update(cache, { data }, { context }) {
			if (context?.dryRun) return;

			try {
				const title = data?.createTitleV2;

				if (title?.uuid) {
					cache.writeQuery({
						query: GET_TITLE_V2,
						variables: {
							uuid: title.uuid,
						},
						data: {
							getTitleV2: title,
						},
					});
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
	});
}

export default useCreateTitleV2;
