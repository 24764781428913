import { Typography } from '@mui/material';
import React from 'react';
import { ExpandableTitleWrapper } from './style';

function ExpandableTitle({ title, titlesNumber }: { title: string; titlesNumber: number }) {
	return (
		<ExpandableTitleWrapper>
			<Typography variant="buttonMediumRegular">{title}</Typography>
			<Typography variant="buttonMediumRegular">
				({titlesNumber} Title{titlesNumber > 1 ? 's' : ''})
			</Typography>
		</ExpandableTitleWrapper>
	);
}

export default ExpandableTitle;
