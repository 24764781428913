import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { CellContext } from '@tanstack/react-table';
import { CellWrapper } from '../../../../src/components/library/SimpleTable/style';
import { TableTextInput } from '../../../../src/views/title/tab/productMetadata/style';
import useBlurAndDebounceValue from '../../../../src/views/title/hooks/useTitleRecordsAutoSave/useBlurAndDebounceValue';
import { PartialDisplayName } from '../comp-obj-relationship.model';
import { CustomCellProps } from './DisplayNamesTable.props';

interface DisplayNameProps {
	displayName: CellContext<PartialDisplayName, string>;
}

export function CellDisplayName({ displayName }: DisplayNameProps) {
	const { updateDisplayNameDisplayName, commitChanges } = displayName.table.options.meta!
		.customPropsCell as CustomCellProps;

	const initialValue = displayName.getValue();
	const initialValueRef = useRef(initialValue);

	useEffect(() => {
		initialValueRef.current = displayName.getValue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayName.getValue()]);

	const saveRef = useRef<(val: string | undefined) => void>();

	useEffect(() => {
		saveRef.current = (val: string | undefined) => {
			if (val) {
				updateDisplayNameDisplayName(val, displayName.row.index);
				commitChanges();
			}
		};
	}, [updateDisplayNameDisplayName, commitChanges, displayName.row.index]);

	const {
		value,
		setValue: setValueBlurAndDebounce,
		commit: commitBlurAndDebounce,
	} = useBlurAndDebounceValue<string>({
		initialValue: initialValueRef.current,
		save: useCallback((val) => saveRef.current?.(val), [saveRef]),
	});

	useEffect(() => {
		setValueBlurAndDebounce(displayName.getValue(), false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayName.getValue()]);

	const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
		setValueBlurAndDebounce(evt.target.value);
	};

	return (
		<CellWrapper>
			<TableTextInput
				autoFocus={!initialValue}
				onBlur={commitBlurAndDebounce}
				value={value || ''}
				onChange={onChange}
			/>
		</CellWrapper>
	);
}
