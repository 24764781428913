import React from 'react';
import MUIRadio, { RadioProps } from '@mui/material/Radio';
import styled, { css } from 'styled-components';

const StyledRadio = styled(MUIRadio)<RadioProps>(
	({ theme, disabled }) => css`
		&.Mui-checked {
			color: ${disabled ? theme.palette.action.disabled : theme.palette.action.active} !important;
		}
	`,
);

function Radio(props: RadioProps) {
	return <StyledRadio {...props} />;
}

export default Radio;
