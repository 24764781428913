import React, { Dispatch, SetStateAction, useContext } from 'react';
import { CharacterInfo } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/People';
import CollapsableCard from '../../../../../../components/titles/CollapsableCard';
import CharacterNamesTable from './CharacterNamesTable';
import { CastAndCrewContext } from '../providers/castAndCrewProvider';

interface CharacterCardProps {
	characterInfo: CharacterInfo | undefined;
	peopleIndex: number;
	roleIndex: number;
	characterIndex: number;
	setUuids: Dispatch<SetStateAction<string[]>>;
}

export default function CharacterCard({
	characterInfo,
	characterIndex,
	peopleIndex,
	roleIndex,
	setUuids,
}: CharacterCardProps) {
	const { removeFromArray } = useContext(CastAndCrewContext);

	const onClickDeleteIcon = () => {
		removeFromArray(`people.${peopleIndex}.jobs.${roleIndex}.characterInfos`, [characterIndex.toString()]);
		setUuids((prevUuids) => prevUuids.filter((_, idx) => idx !== characterIndex));
	};

	return (
		<CollapsableCard showDeleteIcon onClickDeleteIcon={onClickDeleteIcon} label={`Character #${characterIndex + 1}`}>
			<CharacterNamesTable
				peopleIndex={peopleIndex}
				roleIndex={roleIndex}
				characterIndex={characterIndex}
				characterNames={characterInfo?.characterNames ?? []}
				canAddRow
				editMode
			/>
		</CollapsableCard>
	);
}
