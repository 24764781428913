import React from 'react';
import { Control, FieldErrors, UseFieldArrayAppend, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// GRAPHQL
import { TitleFull as Title } from '@warehouse/title/core';
import { Locale } from '../../localeType';

// STYLE
import { FirstTwoRowsWrapper, StepTwoWrapper } from '../style';
import Movie from './Movie';
import Compilation from './Compilation';
import Season from './Season';
import Series from './Series';
import Episode from './Episode';
import Supplemental from './Supplemental';
import Performance from './Performance';
import useWorkTypes from '../../../../utils/hooks/titles/useWorkTypes';
import { OriginalLanguageCreationType } from '../OriginalLanguagesTable';

interface StepTwoProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	errors: FieldErrors<any>;
	workType: string | undefined;
	trigger: UseFormTrigger<any>;
	appendOriginalLanguage: UseFieldArrayAppend<any, 'originalLanguages'>;
	appendVersionLanguage: UseFieldArrayAppend<any, 'versionLanguages'>;
	defaultLocale?: Locale;
	originalLanguages: OriginalLanguageCreationType[];
	versionLanguages: any[];
	inheritedValues: Title | undefined;
}

function StepTwo({
	control,
	errors,
	setValue,
	workType,
	trigger,
	appendOriginalLanguage,
	appendVersionLanguage,
	defaultLocale,
	originalLanguages,
	versionLanguages,
	inheritedValues,
}: StepTwoProps) {
	const { getNameByUuid } = useWorkTypes();

	const props = {
		control,
		setValue,
		trigger,
		errors,
		inheritedValues,
		appendOriginalLanguage,
		appendVersionLanguage,
		defaultLocale,
		originalLanguages,
		versionLanguages,
	};

	const fieldsByTitleType = {
		Movie: <Movie {...props} />,
		Compilation: <Compilation {...props} />,
		Season: <Season {...props} />,
		Series: <Series {...props} />,
		Episode: <Episode {...props} />,
		Supplemental: <Supplemental {...props} />,
		Edit: <Performance {...props} />,
	};

	const fieldsToDisplay = fieldsByTitleType[getNameByUuid(workType || '') as keyof typeof fieldsByTitleType] || null;

	return (
		<StepTwoWrapper>
			<FirstTwoRowsWrapper>{fieldsToDisplay}</FirstTwoRowsWrapper>
		</StepTwoWrapper>
	);
}

export default StepTwo;
