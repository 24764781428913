import React, { ChangeEvent, InputHTMLAttributes, forwardRef, useEffect, useState, ForwardedRef } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { alpha, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { mdiInformation } from '@mdi/js';
import Tooltip from './Tooltip';
import {
	Label,
	Wrapper,
	ReadOnlyValue,
	SubMessageWrapper,
	IconWrapper,
	TooltipIcon,
	SubMessage,
	InputButton,
	InputIcons,
	Input,
	LabelBlockWrapper,
} from './InputUtils';
import InputError, { WrapperInputError } from './InputError';

export type TextInputVariant = 'default' | 'prefilled';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
	ariaLabel?: string;
	label?: string;
	tooltip?: string;
	inlineLabel?: boolean;
	confirmation?: boolean;
	value?: string;
	error?: string;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	height?: number;
	disabledMargin?: boolean;
	type?: string;
	labelIcon?: React.ReactNode;
	width?: string | number;
	fullWidth?: boolean;
	disableRightPadding?: boolean;
	variant?: TextInputVariant;
}

type InputWrapperProps = {
	error?: string;
	disabled?: boolean;
	confirmation?: boolean;
	value?: string;
	tempValue?: string;
	height?: number;
};

const InputWrapper = styled.div<InputWrapperProps>(
	({ theme, error, disabled, confirmation, value, tempValue, height }) => css`
		align-items: stretch;
		background-color: ${theme.palette.light.background};
		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		flex-grow: 1;
		font-size: 14px;
		justify-content: space-between;
		min-height: ${height ? `${height}px` : '48px'};
		position: relative;

		input {
			color: ${theme.palette.text.secondary};
		}

		${IconWrapper} {
			color: ${theme.palette.text.secondary};
		}

		&:hover,
		&:focus-within {
			background-color: ${theme.palette.light.main};
			border-color: ${theme.palette.blue.main};

			input {
				color: ${theme.palette.text.primary};
			}

			${IconWrapper} {
				color: ${theme.palette.blue.main};
			}
		}

		:focus-visible {
			outline: none;
		}

		${confirmation && tempValue !== value
			? css`
					border-bottom-right-radius: 0;
			  `
			: null}

		${error && !disabled
			? css`
					background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
					border: solid 1px ${theme.palette.error.main} !important;
					border-bottom-right-radius: 0;
					color: ${theme.palette.error.text};

					input {
						color: ${theme.palette.error.text};
					}
			  `
			: null}

		${disabled &&
		css`
			background: none !important;
			border: 1px solid ${theme.palette.light.backgroundAlt} !important;
			cursor: not-allowed;

			input,
			input:hover {
				color: ${theme.palette.action.disabled};
				cursor: not-allowed;
			}
		`}
	`,
);

export const TextInput = forwardRef((props: TextInputProps, upperRef: ForwardedRef<HTMLInputElement>) => {
	const {
		ariaLabel,
		label,
		inlineLabel,
		readOnly,
		disabled,
		placeholder,
		confirmation,
		value,
		onBlur,
		onChange,
		error,
		className,
		startIcon,
		endIcon,
		startAdornment,
		endAdornment,
		tooltip,
		height,
		labelIcon,
		fullWidth = false,
		width,
		type = 'text',
		disableRightPadding = false,
		disabledMargin,
		step,
		variant = 'default',
		...rest
	} = props;
	const [tempValue, setTempValue] = useState(value);

	useEffect(() => {
		setTempValue(value);
	}, [value]);

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const trimmedValue = event.target.value?.trim();
		if (onBlur) {
			onBlur({ ...event, target: { ...event.target, value: trimmedValue } });
		}
	};

	const getValueOrEmptyString = () => (value !== undefined ? value : '');

	return (
		<Wrapper
			inlineLabel={inlineLabel}
			disabled={disabled}
			className={className}
			fullWidth={fullWidth}
			disableRightPadding={disableRightPadding}
			disabledMargin={disabledMargin}
			aria-label={ariaLabel ? `${ariaLabel} Wrapper` : undefined}
		>
			{label && (
				<Label variant="buttonLargeMedium" error={error} className="input-label">
					<>
						<LabelBlockWrapper>
							{label}
							{tooltip && (
								<Tooltip title={tooltip} placement="right">
									<TooltipIcon path={mdiInformation} size="16px" />
								</Tooltip>
							)}
						</LabelBlockWrapper>
						{labelIcon}
					</>
				</Label>
			)}
			{readOnly ? (
				<ReadOnlyValue>{value || placeholder || ''}</ReadOnlyValue>
			) : (
				<WrapperInputError>
					<InputWrapper
						error={error}
						{...rest}
						disabled={disabled}
						className="input-wrapper"
						confirmation={confirmation}
						value={value}
						tempValue={tempValue}
						height={height}
					>
						{startAdornment}
						{startIcon && <IconWrapper $start>{startIcon}</IconWrapper>}
						<Input
							type={type}
							role="textbox"
							ref={upperRef}
							value={confirmation ? tempValue : getValueOrEmptyString()}
							aria-label={ariaLabel ?? label}
							placeholder={placeholder}
							disabled={disabled}
							onBlur={handleBlur}
							step={step}
							lang="en"
							onKeyDown={(e) => {
								if (type === 'number' && ['e', '+', '.'].includes(e.key)) {
									e.preventDefault();
								}
							}}
							width={width}
							onChange={
								confirmation
									? (e) => {
											setTempValue(e.target.value);
									  }
									: onChange
							}
							{...rest}
							className={classNames({ inherited: variant === 'prefilled' })}
						/>
						{confirmation && value !== tempValue ? (
							<InputIcons>
								<InputButton onClick={() => setTempValue(value)}>
									<CancelIcon color="error" />
								</InputButton>
								<InputButton
									onClick={() => {
										if (onChange && value && tempValue) {
											const mockEvent = {
												target: { value: tempValue },
											} as ChangeEvent<HTMLInputElement>;
											onChange(mockEvent);
										}
									}}
								>
									<CheckCircleIcon color="info" />
								</InputButton>
							</InputIcons>
						) : null}
						{endIcon && <IconWrapper>{endIcon}</IconWrapper>}
						{endAdornment}
					</InputWrapper>
					{confirmation && value !== tempValue ? (
						<SubMessageWrapper style={{ position: 'absolute', right: 0, bottom: -36 }}>
							<SubMessage color="info">
								<ErrorIcon />
								<Typography>Please confirm or cancel your changes</Typography>
							</SubMessage>
						</SubMessageWrapper>
					) : null}
					{error ? <InputError message={error} /> : null}
				</WrapperInputError>
			)}
		</Wrapper>
	);
});

export default TextInput;
