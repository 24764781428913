import React from 'react';
import { mdiTextBox, mdiVideo, mdiVolumeHigh } from '@mdi/js';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { alpha } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Maybe } from 'graphql/jsutils/Maybe';
import SimpleTable from '../../../../../components/library/SimpleTable';
import { SectionTitle, Wrapper } from './style';
import { InventoryTrack } from '../../../../../graphql/codegen/graphql';

const TrackIconWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		padding-left: ${theme.spacing(2)};
	`,
);

const TableWrapper = styled.div(
	({ theme }) => css`
		margin-top: ${theme.spacing(2)};
	`,
);

const StyledIcon = styled(Icon)(
	({ theme }) => css`
		color: ${alpha(theme.palette.dark.main, 0.5)};
	`,
);

const columns: ColumnDef<Maybe<any>, any>[] = [
	{
		id: 'trackType',
		header: 'Track',
		maxSize: 40,
		cell: (info) => {
			const { trackType } = info.row.original;
			if (trackType === 'AUDIO_TRACK')
				return (
					<TrackIconWrapper>
						<StyledIcon path={mdiVideo} size="24px" />
					</TrackIconWrapper>
				);

			if (trackType === 'VIDEO_TRACK')
				return (
					<TrackIconWrapper>
						<StyledIcon path={mdiVolumeHigh} size="24px" />
					</TrackIconWrapper>
				);
			return (
				<TrackIconWrapper>
					<StyledIcon path={mdiTextBox} size="24px" />
				</TrackIconWrapper>
			);
		},
	},
	{ id: 'name', header: 'NAME', accessorKey: 'trackName' },
	// {
	// 	id: 'isDerivable',
	// 	header: 'IS DERIVABLE',
	// 	accessorFn: (row) => row.isDerivable,
	// 	cell: (info) => {
	// 		const { isDerivable } = info.row.original;
	// 		if (isDerivable) return <StyledIcon path={mdiCheckCircle} size="24px" />;
	// 		return null;
	// 	},
	// },
];

interface LinkedTracksProps {
	trackData: InventoryTrack | undefined;
}

function LinkedTracks({ trackData }: LinkedTracksProps) {
	return (
		<Wrapper>
			<SectionTitle title="Linked Tracks" tooltip="Linked Tracks" />
			<TableWrapper>
				<SimpleTable
					columns={columns}
					data={trackData?.asset?.linkedTracks || []}
					setData={() => {}}
					tableStyle="border"
				/>
			</TableWrapper>
		</Wrapper>
	);
}

export default LinkedTracks;
