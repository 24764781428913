import React from 'react';
import NxLoader from '../../../src/components/library/NxLoader';

export function CenteredNxLoader() {
	return (
		<div style={styles.centeredContainer}>
			<NxLoader />
		</div>
	);
}

const styles = {
	centeredContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		width: '100vw',
		backgroundColor: '#dde0e3',
	},
};
