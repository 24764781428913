import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { Typography } from '@mui/material';
import { TitleFull as Title } from '@warehouse/title/core';
import styled, { css } from 'styled-components';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { CustomColumnDef } from '../../../../../components/library/SimpleTable/customColumnDef';
import TitleEditableField from '../../../../../components/titles/TitleEditableField';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { TableTextInput } from '../style';
import { CellWrapper } from '../../../../../components/library/SimpleTable/style';
import Dropdown from '../../../../../components/library/Dropdown/Dropdown';
import { fromBasic } from '../../../../../utils/titleGetProperty';
import useMemoJsonPath from '../../../hooks/useMemoJsonPath';
import useTitleAutoSave, { UseTitleAutoSaveOutput } from '../../../hooks/useTitleAutoSave/useTitleAutoSave';

type CustomMetadataColumn = {
	name: string;
	value: string;
	type: string;
};

interface CustomMetadataProps {
	editMode: boolean;
	title: Title | undefined;
	selectedLocalizedInfo: string | undefined;
}

const StyledDiv = styled.div(
	({ theme }) => css`
		.table-wrapper {
			margin-left: ${theme.spacing(0.75)};
			margin-right: ${theme.spacing(0.75)};
			width: auto !important;
		}
	`,
);

function KeyCell(info: CellContext<CustomMetadataColumn, any>) {
	const { getValue } = info;
	return (
		<Typography variant="tableCell" style={{ marginLeft: 16 }}>
			{getValue()}
		</Typography>
	);
}

function TextInputCell(info: CellContext<any, any>) {
	const { getValue, row, table } = info;
	const initialValue = getValue();
	const autoSave = table.options.meta?.customPropsCell.stringAutoSave as UseTitleAutoSaveOutput<
		OptionalInherited<string | null>
	>;

	const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
		autoSave.setValue((prev) => ({
			...prev,
			displayValue: evt.target.value === '' ? null : evt.target.value,
		}));
	};

	const onBlur = useCallback(() => {
		if (autoSave.value?.displayValue?.trim() !== getValue().trim()) {
			autoSave.commit();
		}
	}, [row.index, autoSave.value]);

	return useMemo(
		() => (
			<CellWrapper>
				<TableTextInput
					placeholder={autoSave.value?.displayValue || ''}
					onBlur={onBlur}
					value={autoSave.value?.displayValue || ''}
					onChange={onChange}
				/>
			</CellWrapper>
		),
		[onBlur, autoSave.value, initialValue, row.index],
	);
}

function getFormattedValue(value: string | null) {
	if (value === '' || value === null) return null;
	return value === 'true';
}

function BooleanCell(info: CellContext<CustomMetadataColumn, any>) {
	const { table } = info;
	const autoSave = table.options.meta?.customPropsCell.booleanAutoSave as UseTitleAutoSaveOutput<
		OptionalInherited<boolean | null>
	>;

	return (
		<CellWrapper>
			<Dropdown
				placement="top"
				placeholderStyle="default"
				value={autoSave.value?.displayValue?.toString() || ''}
				onChange={(e) => {
					autoSave.setValue((prev) => ({
						...prev,
						displayValue: getFormattedValue(e),
					}));
					autoSave.commit();
				}}
				enablePortal
				transparentBorder
				options={[
					{
						label: '',
						value: '',
					},
					{
						label: 'True',
						value: 'true',
					},
					{
						label: 'False',
						value: 'false',
					},
				]}
			/>
		</CellWrapper>
	);
}

function ValueCell(info: CellContext<CustomMetadataColumn, any>) {
	const { row } = info;
	if (row.original.type === 'string') {
		return TextInputCell(info);
	}
	return BooleanCell(info);
}

function renderBooleanReadOnly(value: string | boolean | null | undefined) {
	if (value === true) return 'True';
	if (value === false) return 'False';
	return value;
}

function CustomMetadata({ editMode, title, selectedLocalizedInfo }: CustomMetadataProps) {
	const stringAutoSave = useTitleAutoSave<OptionalInherited<string>>({
		label: 'Custom metadata string',
		path: useMemoJsonPath(
			fromBasic(['localizedInfos', selectedLocalizedInfo || '__default', 'terms', 'customFieldExample']),
			selectedLocalizedInfo,
		),
		title,
	});

	const booleanAutoSave = useTitleAutoSave<OptionalInherited<boolean | null>>({
		label: 'Custom metadata boolean',
		path: useMemoJsonPath(
			fromBasic(['localizedInfos', selectedLocalizedInfo || '__default', 'terms', 'customBooleanField']),
			selectedLocalizedInfo,
		),
		title,
	});

	const [data, setData] = useState<
		{
			name: string;
			value: string | boolean | undefined;
			type: string;
		}[]
	>([
		{
			name: 'customFieldExample',
			value: undefined,
			type: 'string',
		},
		{
			name: 'customBooleanField',
			value: undefined,
			type: 'boolean',
		},
	]);

	useEffect(() => {
		setData([
			{
				name: 'customFieldExample',
				value: stringAutoSave?.readOnlyValue?.displayValue || '',
				type: 'string',
			},
			{
				name: 'customBooleanField',
				value: booleanAutoSave.readOnlyValue?.displayValue ?? '',
				type: 'boolean',
			},
		]);
	}, [selectedLocalizedInfo, booleanAutoSave.readOnlyValue, stringAutoSave.readOnlyValue, editMode]);

	const columns: CustomColumnDef<CustomMetadataColumn>[] = useMemo(
		() => [
			{
				id: 'attribute',
				header: 'Attribute',
				accessorFn: (row) => row?.name,
				renderReadOnly: (v) => v,
				width: 200,
				cell: (info) => KeyCell(info),
			},
			{
				id: 'value',
				header: 'Value',
				accessorFn: (row) => row?.value,
				renderReadOnly: renderBooleanReadOnly,
				cell: (info) => ValueCell(info),
			},
		],
		[],
	);

	return (
		<StyledDiv>
			<TitleEditableField
				label="Custom"
				editMode={editMode}
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
				ruleOrigin={undefined}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper
				editMode={editMode}
				tableStyle="border"
				columns={columns}
				data={data}
				customPropsCell={{ stringAutoSave, booleanAutoSave }}
			/>
		</StyledDiv>
	);
}

export default CustomMetadata;
