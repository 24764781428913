import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type SearchParamsData = { [k: string]: string };

export interface URLSearchParamsHook {
	searchParams: SearchParamsData;
	setQuery: (label: string, value?: string) => void;
	deleteQuery: (label: string) => void;
}

function searchParamsToString(data: SearchParamsData) {
	return Object.entries(data).reduce(
		(acc, [k, v], idx) => `${acc}${idx > 0 ? '&' : ''}${v ? `${k}=${encodeURIComponent(v)}` : k}`,
		'',
	);
}

function getCurrentSearchParams() {
	const sp = new URLSearchParams(window.location.search);
	return Object.fromEntries(sp.entries());
}

export function useURLSearchParams(): URLSearchParamsHook {
	const location = useLocation();
	const navigate = useNavigate();

	const searchParams = useMemo(() => getCurrentSearchParams(), [location.search]);

	const setQuery = useCallback((label: string, value?: string) => {
		const currentSearchParams = getCurrentSearchParams();
		currentSearchParams[label] = value || '';
		const updatedSearchParams = searchParamsToString(currentSearchParams);
		navigate(
			`${window.location.pathname}${updatedSearchParams ? `?${updatedSearchParams}` : ''}${window.location.hash}`,
			{ replace: true },
		);
	}, []);

	const deleteQuery = useCallback((label: string) => {
		const currentSearchParams = getCurrentSearchParams();
		delete currentSearchParams[label];
		const updatedSearchParams = searchParamsToString(currentSearchParams);
		navigate(
			`${window.location.pathname}${updatedSearchParams ? `?${updatedSearchParams}` : ''}${window.location.hash}`,
			{ replace: true },
		);
	}, []);

	return {
		searchParams,
		setQuery,
		deleteQuery,
	};
}
