import React, { useMemo, useState } from 'react';

import { useExportTitle } from '../../../utils/hooks/titles/useExportMEC';
import ErrorSnackbar from '../hooks/ErrorSnackbar';
import { AutoButtonGroup } from '../../../components/library/AutoButtonGroup';

type ExportMECButtonProps = {
	uuid: string | undefined;
	exportedFileName: string | undefined;
};

function ExportMECButton({ uuid, exportedFileName }: ExportMECButtonProps) {
	const { loadExport, loading, error } = useExportTitle(uuid!);
	const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);

	const handleClick = async (type: string) => {
		const { data } = await loadExport(type);

		if (!data) {
			setErrorSnackbarOpen(true);
			return;
		}

		const blob = new Blob([data.exportTitle.mec], { type: 'text/plain' });

		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `${(exportedFileName || 'No name').replaceAll(' ', '_')}.${type}`;
		document.body.appendChild(link);
		link.click();

		URL.revokeObjectURL(url);
		document.body.removeChild(link);
	};

	const errorSnackbar = useMemo(
		() => (
			<ErrorSnackbar
				open={errorSnackbarOpen}
				onClose={() => setErrorSnackbarOpen(false)}
				title={error?.name || ''}
				errors={[error?.message || '']}
			/>
		),
		[error, loadExport],
	);

	const exportMecOptions = useMemo(
		() => [
			{ id: 'xml', label: 'Export MEC (XML)', default: true },
			{ id: 'json', label: 'Export MEC (JSON)' },
		],
		[],
	);

	return (
		<>
			{errorSnackbar}
			<AutoButtonGroup
				nxstyle="secondary-black"
				loading={loading}
				storeKey="export-format"
				height={32}
				options={exportMecOptions}
				onOptionClick={handleClick}
			/>
		</>
	);
}

export default ExportMECButton;
