import React from 'react';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';

const StyledListItem = styled(ListItem)<ListItemProps>(
	({ theme }) => css`
		& .MuiListItemText-root .MuiTypography-root {
			color: ${alpha(theme.palette.dark.main, 0.8)};
			font-size: 1rem;
		}
		& .MuiListItemText-root .MuiTypography-root.MuiListItemText-secondary {
			color: ${alpha(theme.palette.dark.main, 0.5)};
			font-size: 0.9rem;
		}
		& .MuiListItemButton-root {
			padding: ${theme.spacing(1.6)};
		}
		& .MuiListItemButton-dense {
			padding: ${theme.spacing(1)};
		}
		& .Mui-selected {
			background-color: ${theme.palette.blue.background};
		}
		& .MuiListItemButton-root:hover {
			background-color: ${alpha(theme.palette.blue.main, 0.2)};
		}
		& .MuiListItemIcon-root,
		.MuiListItemSecondaryAction-root {
			color: ${theme.palette.dark.main};
			min-width: 30px;
			svg {
				font-size: 1rem;
			}
		}
	`,
);

function ListItemComponent(props: ListItemProps) {
	return <StyledListItem {...props} />;
}

export default ListItemComponent;
