import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { CheckCircleOutline } from '@mui/icons-material';
import UpdateIcon from '@mui/icons-material/Update';
import { Typography } from '@mui/material';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { Label, LabelValue, SectionTitle, Wrapper } from './style';
import Button from '../../../../../components/library/Button';
import SimpleTable from '../../../../../components/library/SimpleTable';
import { dateToString, stringToDate } from '../../../../../components/library/DatePicker/DatePicker';
import Tag from '../../../../../components/library/Tag';
import { InventoryTrack } from '../../../../../graphql/codegen/graphql';

const MainContainer = styled.div(
	({ theme }) => css`
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		margin-top: ${theme.spacing(1)};
	`,
);

const DoubleColumnsContainer = styled.div<{ enablePadding?: boolean }>(
	({ theme, enablePadding }) => css`
		display: flex;
		flex: 1;
		flex-direction: row;

		${enablePadding &&
		css`
			padding: ${theme.spacing(2)};
		`}
	`,
);

const Column = styled.div<{ borderRight?: boolean; enablePadding?: boolean }>(
	({ theme, borderRight, enablePadding }) => css`
		display: flex;
		flex-direction: column;
		width: 50%;

		${borderRight &&
		css`
			border-right: solid 1px ${theme.palette.light.backgroundAlt};
		`}

		${enablePadding &&
		css`
			padding: ${theme.spacing(2)};
		`}
	`,
);

const AlignEnd = styled.div(
	() => css`
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: flex-end;
	`,
);

const Row = styled.div<{ disableBorder?: boolean; disablePadding?: boolean }>(
	({ theme, disableBorder, disablePadding }) => css`
		${!disableBorder && `border-bottom: solid 1px ${theme.palette.light.backgroundAlt};`}

		${!disablePadding &&
		css`
			padding: ${theme.spacing(2)};
		`}
	`,
);

const SimpleTableWrapper = styled.div(
	({ theme }) => css`
		margin-top: ${theme.spacing(2)};
	`,
);

const CustomCheckCircleOutline = styled(CheckCircleOutline)(
	({ theme }) => css`
		height: 16px;
		margin-right: ${theme.spacing(1)};
		width: 16px;
	`,
);

interface FileInformationProps {
	trackData: InventoryTrack | undefined;
}

function FileInformation({ trackData }: FileInformationProps) {
	const styleByProxyStatus: { [k: string]: 'warning' | 'success' | 'error' } = {
		PENDING: 'warning',
		READY: 'success',
		ERRORED: 'error',
	};

	return (
		<Wrapper>
			<SectionTitle title="File Information" tooltip="File Information" />
			<MainContainer>
				<Row>
					<DoubleColumnsContainer>
						<Column>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Label>File Status:</Label>
								<Tag style={{ marginLeft: 8 }} nxstyle="success">
									<CustomCheckCircleOutline height={16} />
									<Typography style={{ lineHeight: 32 }} variant="buttonSmallMedium">
										{trackData?.asset?.fileStatus}
									</Typography>
								</Tag>
							</div>
						</Column>
						<Column>
							<LabelValue copyable label="File Link:" value={trackData?.asset?.assetUrl} />
						</Column>
					</DoubleColumnsContainer>
				</Row>
				<Row>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Label>Proxy Status:</Label>
						<Tag
							style={{ marginLeft: 8 }}
							nxstyle={styleByProxyStatus[trackData?.proxyStatus as keyof typeof styleByProxyStatus] || 'default'}
						>
							{trackData?.proxyStatus === 'READY' && <CustomCheckCircleOutline height={16} />}
							{trackData?.proxyStatus === 'PENDING' && <UpdateIcon style={{ height: '16px' }} />}
							{trackData?.proxyStatus === 'ERRORED' && <DoDisturbAltIcon style={{ height: '16px' }} />}
							<Typography style={{ lineHeight: 32 }} variant="buttonSmallMedium">
								{trackData?.proxyStatus}
							</Typography>
						</Tag>
					</div>
				</Row>
				<Row disablePadding>
					<DoubleColumnsContainer>
						<Column style={{ width: '45%' }} borderRight enablePadding>
							{/* <LabelValue label="File Size:" value="3.42 TB" /> */}
							<LabelValue label="File Type:" value={trackData?.asset?.fileType} />
						</Column>
						<Column enablePadding>
							<LabelValue
								label="Ingested Date:"
								value={
									trackData?.asset?.ingestedAt ? dateToString(stringToDate(trackData?.asset?.ingestedAt)) : undefined
								}
							/>
							{/* <LabelValue label="Organization:" value={trackData?.asset?.organization} /> */}
							{/* <LabelValue label="Ingested By:" value={trackData?.asset?.ingestedBy} /> */}
						</Column>
					</DoubleColumnsContainer>
				</Row>
				<Row>
					<Column>
						<LabelValue label="Storage Tier:" value={trackData?.asset?.storageTier} />
					</Column>
				</Row>
				<Row>
					<Label>Checksum</Label>
					<SimpleTableWrapper>
						<SimpleTable
							columns={[
								{
									id: 'checksumType',
									header: 'CHECKSUM TYPE',
									accessorKey: 'checksumType',
								},
								{ id: 'value', header: 'VALUE', accessorKey: 'value' },
							]}
							data={[
								{
									checksumType: 'etag',
									value: trackData?.asset?.checksums?.etag || '',
								},
							]}
							setData={() => {}}
							tableStyle="border"
						/>
					</SimpleTableWrapper>
				</Row>
				<Row disableBorder>
					<AlignEnd>
						<Button startIcon={<Icon path={mdiDelete} size="16px" />} nxstyle="primary-red">
							Delete File
						</Button>
					</AlignEnd>
				</Row>
			</MainContainer>
		</Wrapper>
	);
}

export default FileInformation;
