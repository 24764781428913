import React from 'react';
import { Header } from '@tanstack/react-table';
import { SubHeaderTH } from '../style';
import TextInput from '../../TextInput';

interface SubHeaderNumberProps {
	value: string;
	header: Header<any, any>;
	handleSubHeaderDropdownChange: (id: string, value: string) => void;
}

function SubHeaderNumber({ value, header, handleSubHeaderDropdownChange }: SubHeaderNumberProps) {
	return (
		<SubHeaderTH noPadding style={{ textTransform: 'none' }}>
			<TextInput
				type="number"
				value={value}
				onChange={(e) => {
					handleSubHeaderDropdownChange(header.column.columnDef.id as string, e.target.value);
				}}
				height={32}
			/>
		</SubHeaderTH>
	);
}

export default SubHeaderNumber;
