import { useMemo } from 'react';

function useFieldsByTitleTypeName(titleTypeName: string | undefined) {
	return useMemo(() => {
		const fieldsByTitleType = {
			Movie: [
				{ field: 'title', required: true },
				{ field: 'titleLanguage', required: true },
				{ field: 'countriesOfOrigin', required: true },
				{ field: 'releaseDate', required: true },
				{ field: 'sortTitle', required: false },
				{ field: 'approximateLength', required: true },
				{ field: 'originalLanguages', required: true },
			],
			Compilation: [
				{ field: 'title', required: true },
				{ field: 'titleLanguage', required: true },
				{ field: 'releaseDate', required: true },
				{ field: 'sortTitle', required: false },
			],
			Season: [
				{ field: 'title', required: false },
				{ field: 'titleLanguage', required: true },
				{ field: 'countriesOfOrigin', required: true },
				{ field: 'releaseDate', required: true },
				{ field: 'sequenceNumber', required: true },
				{ field: 'sortTitle', required: false },
				{ field: 'approximateLength', required: true },
				{ field: 'originalLanguages', required: true },
			],
			Series: [
				{ field: 'title', required: true },
				{ field: 'titleLanguage', required: true },
				{ field: 'countriesOfOrigin', required: true },
				{ field: 'releaseDate', required: true },
				{ field: 'sortTitle', required: false },
				{ field: 'approximateLength', required: false },
				{ field: 'originalLanguages', required: true },
			],
			Episode: [
				{ field: 'sequenceNumber', required: true },
				{ field: 'title', required: false },
				{ field: 'titleLanguage', required: true },
				{ field: 'countriesOfOrigin', required: true },
				{ field: 'releaseDate', required: true },
				{ field: 'sortTitle', required: false },
				{ field: 'approximateLength', required: true },
				{ field: 'houseSequence', required: false },
				{ field: 'distributionNumber', required: false },
				{ field: 'originalLanguages', required: true },
			],
			Supplemental: [
				{ field: 'title', required: true },
				{ field: 'titleLanguage', required: true },
				{ field: 'countriesOfOrigin', required: true },
				{ field: 'releaseDate', required: true },
				{ field: 'sortTitle', required: false },
				{ field: 'approximateLength', required: true },
				{ field: 'originalLanguages', required: true },
			],
			Edit: [
				{ field: 'editUse', required: true },
				{ field: 'editClass', required: false },
				{ field: 'madeForRegions', required: false },
				{ field: 'versionLanguages', required: false },
			],
		};

		if (!titleTypeName) return [];
		if (!fieldsByTitleType[titleTypeName as keyof typeof fieldsByTitleType]) return [];

		return fieldsByTitleType[titleTypeName as keyof typeof fieldsByTitleType];
	}, [titleTypeName]);
}

export default useFieldsByTitleTypeName;
