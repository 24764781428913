import React, { useMemo } from 'react';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// HOOKS
import { BottomActionClear, DropdownV2 } from '@warehouse/shared/ui';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';
import useTitleAutoSave from '../hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../hooks/useMemoJsonPath';

// UTILS
import getDeepProperty from '../../../utils/getDeepProperty';
import { fromBasic } from '../../../utils/titleGetProperty';
import sortAlphabeticallyByLabel from '../../../utils/sortAlphabeticallyByLabel';

// LIBRARY
import { FieldWrapper } from './style';

// JSON
import availableWorkTypeDetailsByWorkType from '../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';

type WorkTypeDetailsProps = {
	title: Title | undefined;
	parentAbstractionWorkTypeUuid?: string;
};

function getL1WorkType(
	workType: string | undefined,
	parentAbstractionWorkTypeUuid: string | undefined,
	getNameByUuid: (uuid: string) => string | undefined,
): string | undefined {
	if (getNameByUuid(workType || '') === 'Edit') {
		return parentAbstractionWorkTypeUuid;
	}
	return workType;
}

function getLabel(workTypeName: keyof typeof availableWorkTypeDetailsByWorkType): string | undefined {
	return availableWorkTypeDetailsByWorkType[workTypeName || '']?.workTypeDetailLabel || undefined;
}

function getOptions(
	workTypeName: keyof typeof availableWorkTypeDetailsByWorkType,
): { value: string; label: string }[] | undefined {
	return availableWorkTypeDetailsByWorkType[workTypeName || '']?.availableWorkTypeDetails || undefined;
}

function WorkTypeDetails({ title, parentAbstractionWorkTypeUuid }: WorkTypeDetailsProps) {
	const { getNameByUuid } = useWorkTypes();

	const workType = useMemo(() => getDeepProperty<string | undefined>(title, fromBasic(['workType'])), [title]);

	const { value, setValue, commit } = useTitleAutoSave<string[]>({
		label: 'Work Type Details',
		path: useMemoJsonPath(fromBasic(['workTypeDetails'])),
		/**
		 * As `useTitleAutoSave` returns `dirtyValue || value`, we can not set the dirtyValue to `null` as it will fallback to `value`.
		 * Instead, we set it to `['']`, and before saving, we check if the first element is an empty string, and if so, we return `null`.
		 */
		mutatePayloadBeforeSave: (_value: string[] | undefined) => {
			if (_value?.[0] === '') return null;
			return _value;
		},
		isInheritable: false,
	});

	const { label, options } = useMemo(() => {
		const l1WorkType = getL1WorkType(workType, parentAbstractionWorkTypeUuid, getNameByUuid);

		if (!l1WorkType) return { label: undefined, options: [] };

		const workTypeDetailLabel = getLabel(getNameByUuid(l1WorkType) as keyof typeof availableWorkTypeDetailsByWorkType);
		const workTypeDetailOptions = getOptions(
			getNameByUuid(l1WorkType) as keyof typeof availableWorkTypeDetailsByWorkType,
		);
		const sortedOptions = workTypeDetailOptions?.sort(sortAlphabeticallyByLabel) || [];

		return { label: workTypeDetailLabel, options: sortedOptions };
	}, [workType]);

	if (!label) return null;

	return useMemo(
		() => (
			<FieldWrapper className="field-wrapper">
				<DropdownV2
					height={38}
					disabledMargin
					withSearch
					options={options}
					value={value?.[0] || ''}
					onChange={(e) => {
						if (e) {
							setValue([e]);
							commit();
						}
					}}
					bottomActions={
						<BottomActionClear
							action={() => {
								setValue(['']);
								commit?.();
							}}
						/>
					}
					label={label}
				/>
			</FieldWrapper>
		),
		[label, options, value, commit],
	);
}

export default WorkTypeDetails;
