import { useMemo } from 'react';
import { DropdownV2Option } from '@warehouse/shared/ui';
import territoriesRating from '../../../assets/json-administration-profiles/availableRatingSystems.json';
import useTerritories from '../../../utils/hooks/useTerritories';

export default function useRatingSystemTerritoriesOptions() {
	const { isCountry, isTerritoryGroup, isCountryRegion } = useTerritories();

	return useMemo(() => {
		const countryOptions: DropdownV2Option[] = [];
		const territoryOptions: DropdownV2Option[] = [];
		const countryRegionOptions: DropdownV2Option[] = [];
		territoriesRating.forEach((territory) => {
			if (isCountry(territory.uuid)) countryOptions.push({ label: territory.label, value: territory.uuid });
			if (isTerritoryGroup(territory.uuid)) territoryOptions.push({ label: territory.label, value: territory.uuid });
			if (isCountryRegion(territory.uuid)) territoryOptions.push({ label: territory.label, value: territory.uuid });
		});
		const opts: DropdownV2Option[] = [];
		if (countryOptions.length > 0) opts.push({ label: 'Countries', options: countryOptions });
		if (territoryOptions.length > 0) opts.push({ label: 'Territory Groups', options: territoryOptions });
		if (countryRegionOptions.length > 0) opts.push({ label: 'Subdivisions', options: countryRegionOptions });
		return opts;
	}, [territoriesRating]);
}
