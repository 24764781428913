import React, { Dispatch, SetStateAction, useCallback, useContext, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { DropdownV2 } from '@warehouse/shared/ui';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { LicensorPicklistContext } from './LicensorPicklistContext';
import { TitleInfoWrapper } from './styles';
import ConfirmationModal from '../../../components/library/ConfirmationModal';

export interface LicensorSelectorProps {
	licensor: string;
	onLicensorChange: (licensor: string) => void;
	loading?: boolean;
	parentHasBeenSelected: boolean;
	setParent: Dispatch<SetStateAction<IndexedTitleV2 | undefined>>;
	readOnly?: boolean;
}

function LicensorSelector({
	parentHasBeenSelected,
	licensor,
	onLicensorChange,
	loading,
	readOnly,
	setParent,
}: LicensorSelectorProps) {
	const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
	const confirmFnRef = useRef<null | (() => void)>(null);
	const { options, onNewOptionAdded } = useContext(LicensorPicklistContext);

	const onConfirmConfirmationModal = () => {
		setConfirmationModalOpen(false);
		confirmFnRef?.current?.();
		confirmFnRef.current = null;
	};

	const onRequestChangeLicensor = async (newLicensor: string | null) => {
		if (parentHasBeenSelected) {
			setConfirmationModalOpen(true);
			confirmFnRef.current = () => {
				onChangeLicensorWrapped(newLicensor);
				setParent(undefined);
			};
		} else {
			await onChangeLicensorWrapped(newLicensor);
		}
	};

	const onChangeLicensorWrapped = async (newLicensor: string | null) => {
		if (!newLicensor) return;
		onLicensorChange(newLicensor);
	};

	return (
		<>
			<TitleInfoWrapper>
				<Typography variant="s2Medium" color="text.primary">
					Licensor :
				</Typography>
				<div style={{ width: '200px' }}>
					<DropdownV2
						ariaLabel="Select licensor"
						withSearch
						height={40}
						pickList
						loading={loading}
						options={options}
						readOnly={readOnly}
						value={licensor}
						onNewOptionAdded={useCallback(
							(newOption: string) =>
								onNewOptionAdded({
									label: newOption,
									value: newOption,
								}),
							[onNewOptionAdded],
						)}
						onChange={onRequestChangeLicensor}
					/>
				</div>
			</TitleInfoWrapper>
			<ConfirmationModal
				open={confirmationModalOpen}
				onConfirm={onConfirmConfirmationModal}
				onClose={() => setConfirmationModalOpen(false)}
				title="Are you sure?"
				message="This change will clear the parent field. Are you sure you wish to proceed?"
			/>
		</>
	);
}

export default LicensorSelector;
