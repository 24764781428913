import { SetStateAction, useEffect, useRef, useState } from 'react';
import { distinctUntilChanged, map, merge, Subject, switchMap, timer } from 'rxjs';
import { deepEqualityWithOrWithoutInheritance, transformEmptyStrings } from '@warehouse/shared/util';
import { isFunction, trimString } from '../utils';

const DELAY = 5000;

type UseBlurAndDebounceValueProps<T> = {
	save: (value: T) => void;
	initialValue: T;
	delay?: number;
};

function useBlurAndDebounceValue<T>({ save, initialValue, delay = DELAY }: UseBlurAndDebounceValueProps<T>) {
	const [value, setValue] = useState<T>(initialValue);
	const inputSubject = useRef(new Subject<T>());
	const commitSubject = useRef(new Subject<void>());
	const saveRef = useRef(save);
	saveRef.current = save;

	useEffect(() => {
		const debounce$ = inputSubject.current;
		const commit$ = commitSubject.current;

		const merged$ = debounce$
			.pipe(
				map((e) => trimString(e)),
				map(transformEmptyStrings),
				switchMap((_value) => merge(commit$, timer(delay)).pipe(map(() => _value))),
				distinctUntilChanged(deepEqualityWithOrWithoutInheritance),
			)
			.subscribe((...args) => saveRef.current(...args));

		return () => merged$.unsubscribe();
	}, [saveRef]);

	const handleOnChange = (valueOrFunction: SetStateAction<T>, commit = true) => {
		const newValue = isFunction(valueOrFunction) ? valueOrFunction(value!) : valueOrFunction;
		setValue(newValue);
		if (commit) inputSubject.current.next(newValue);
	};

	const commit = () => {
		setValue((prev) => trimString(prev));
		commitSubject.current.next();
	};

	return {
		value,
		setValue: handleOnChange,
		commit,
	};
}

export default useBlurAndDebounceValue;
