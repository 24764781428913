import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface Options {
	push?: boolean;
	keepQueryParams?: boolean | string[];
}

function getSearch(currentSearch: string, opts?: Options) {
	if (opts?.keepQueryParams === true) return currentSearch;
	if (typeof opts?.keepQueryParams === 'undefined' || typeof opts?.keepQueryParams === 'boolean') return '';
	const query = new URLSearchParams(currentSearch);
	if (Array.isArray(opts?.keepQueryParams)) {
		new Map(query.entries()).forEach((_, key) => {
			if (!(opts?.keepQueryParams as string[]).includes(key)) query.delete(key);
		});
		return query.size ? `?${query.toString()}` : '';
	}
	return '';
}

export function useURLFragment(): [string, (value: string, opts?: Options) => void] {
	const location = useLocation();
	const navigate = useNavigate();

	const setFragment = useCallback(
		(fragment: string, opts?: Options) => {
			if (!fragment) return;

			navigate(`${location.pathname}${getSearch(location.search, opts)}#${fragment}`, { replace: !opts?.push });
		},
		[location],
	);

	return [location.hash.length ? location.hash.slice(1) : '', setFragment];
}
