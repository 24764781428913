import styled, { css } from 'styled-components';
import DialogActions from '../../../components/library/DialogActions';
import Alert from '../../../components/library/Alert';
import Radio from '../../../components/library/Radio';

export const ContentWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
	`,
);

export const StepperWrapper = styled.div(
	({ theme }) => css`
		border-bottom: solid 1px ${theme.palette.divider};
		padding: ${theme.spacing(2, 12)};
	`,
);

export const ActionWrapper = styled(DialogActions)(
	({ theme }) => css`
		//margin-top: ${theme.spacing(10)};
	`,
);

export const CreateTitleForm = styled.form(
	() => css`
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
	`,
);

export const StepWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		padding: ${theme.spacing(2)};
	`,
);

export const StepTwoWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: ${theme.spacing(2)};
	`,
);

export const TableWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;

		table:hover {
			cursor: pointer;
		}
	`,
);

export const DatePickerWrapper = styled.div(
	({ theme }) => css`
		.react-datepicker-popper {
			z-index: 20;
		}

		.react-datepicker__triangle {
			transform: translate(124px, 0px) !important;
		}

		.react-datepicker__input-container > input {
			height: 48px;
			margin-top: ${theme.spacing(1)};
		}

		& > div {
			display: flex;
			flex-direction: column;
		}
	`,
);

export const FirstTwoRowsWrapper = styled.div(
	({ theme }) => css`
		& > div {
			width: 100%;
		}
		display: flex;
		gap: ${theme.spacing(2)};
		width: 100%;
	`,
);

export const InfoWrapper = styled.div(
	({ theme }) => css`
		border-top: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		gap: ${theme.spacing(2)};
		padding-top: ${theme.spacing(2)};

		& > div {
			width: 100%;
		}
	`,
);

export const CustomAlert = styled(Alert)(
	({ theme }) => css`
		margin: 0;
		margin-bottom: ${theme.spacing(2)};
	`,
);

export const StyledRadio = styled(Radio)(
	({ theme }) => css`
		&.Mui-checked {
			color: ${theme.palette.blue.main} !important;
		}
	`,
);

export const StepOneWrapper = styled.div(
	() => css`
		display: flex;
		flex: 1;
		flex-direction: column;
	`,
);

export const StepOneTopRow = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		gap: ${theme.spacing(2)};
		width: 100%;
		& > div {
			width: 100%;
		}
	`,
);

export const DetailsWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		gap: ${theme.spacing(2)};
		width: 100%;

		& > div {
			flex: 1;
		}
	`,
);
