import React from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import Icon from '@mdi/react';
import { mdiBullhorn } from '@mdi/js';

import { Maybe } from 'graphql/jsutils/Maybe';
import Tag from '../../../../components/library/Tag';
import crossedBullhorn from '../../../../assets/crossedBullhorn.svg';
import StatusCell from '../../../../components/table/columnsItems/Status';
import Checked from '../../../../components/table/columnsItems/Checked';
import MultiTags from '../../../../components/table/columnsItems/MultiTags';

// TODO Change the three first "any"
const columns: ColumnDef<Maybe<any>, any>[] = (() => {
	const announceCol: ColumnDef<Maybe<any>, any> = {
		id: 'announced',
		header: 'Announcement',
		cell: (info) => {
			const cellInfo = info as CellContext<any, any>;

			if (
				cellInfo.row.original.versionMetadata?.announced &&
				cellInfo.row.original.versionMetadata?.lastVersionAnnounced
			)
				return (
					<Tag
						nxstyle="primary"
						startIcon={<Icon path={mdiBullhorn} size="16px" />}
						height={28}
						$uppercase={false}
						badge={cellInfo.row.original.versionMetadata?.announcedTo?.length.toString()}
					>
						Announced V{cellInfo.row.original.versionMetadata?.lastVersionAnnounced}
					</Tag>
				);
			if (cellInfo.row.original.versionMetadata?.published && !cellInfo.row.original.versionMetadata?.announced)
				return (
					<Tag
						nxstyle="default"
						startIcon={<img src={crossedBullhorn} alt="Crossed Bullhorn" />}
						height={28}
						$uppercase={false}
					>
						Not Announced
					</Tag>
				);

			return null;
		},
	};
	const cols: ColumnDef<Maybe<any>, any>[] = [
		{
			header: 'ID',
			accessorKey: 'document.id',
			id: 'id',
		},
		{
			header: 'Name',
			accessorKey: 'document.name',
			id: 'name',
		},
		{
			header: 'Platform',
			accessorKey: 'document.platform',
			id: 'platform',
		},
		{
			header: 'Specification',
			accessorKey: 'document.specification',
			id: 'specification',
		},
		{
			header: 'Territory',
			accessorKey: 'document.territory',
			id: 'territory',
		},
		{
			header: 'Tier',
			accessorKey: 'document.tier',
			id: 'tier',
		},
		{
			header: 'MSR',
			accessorKey: 'document.msr',
			id: 'msr',
			// TODO : Change the first any
			cell: (info: CellContext<any, any>) => <Checked checked={Boolean(info.getValue())} />,
		},
		{
			header: 'Default',
			accessorKey: 'document.isDefault',
			id: 'isDefault',
			cell: (info: CellContext<any, any>) => <Checked checked={Boolean(info.getValue())} />,
		},
		{
			header: 'Tags',
			accessorKey: 'document.tags',
			id: 'tags',
			cell: (info) => <MultiTags tags={info.getValue()} />,
		},
		{
			id: 'status',
			header: 'Status',
			// TODO : Change this any
			cell: StatusCell<any>,
		},
	];

	if (process.env.REACT_APP_TYPE === 'admin') cols.splice(2, 0, announceCol);

	return cols;
})();

export default columns;
