import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircle, mdiCloseCircle, mdiCog } from '@mdi/js';
import { DialogContent } from '@mui/material';
import { ColumnDef, ColumnOrderState, VisibilityState } from '@tanstack/react-table';
import Dialog from '../library/Dialog';
import DialogTitle, { CloseButton } from '../library/DialogTitle';
import DialogActions from '../library/DialogActions';
import Button from '../library/Button';
import ColumnsTransferList, { TransferListItem } from './ColumnsTransferList';
import { ColumnStickyState } from '../../utils/table/stickyUtils';

interface SettingsModalProps<T> {
	open: boolean;
	handleClose: () => void;
	visibilityState: VisibilityState;
	setVisibilityState: (value: VisibilityState) => void;
	setOrderState: (value: ColumnOrderState) => void;
	columnSticky: ColumnStickyState;
	setColumnSticky: (value: ColumnStickyState) => void;
	columns: ColumnDef<T>[];
}

const ContentWrapper = styled.div`
	${({ theme }) => css`
		padding: ${theme.spacing(2)};
	`}
`;

function SettingsModal<T>(props: SettingsModalProps<T>) {
	const {
		open,
		handleClose,
		columns,
		visibilityState,
		setVisibilityState,
		setOrderState,
		columnSticky,
		setColumnSticky,
	} = props;

	// Local state for Sticky Columns
	const [stickyCopy, setStickyCopy] = useState<ColumnStickyState>(columnSticky);

	const getLabelFromId = (id: string) => columns.find((item) => item.id === id)?.header?.toString();

	const getAvailableColumns = (vState: VisibilityState) =>
		Object.entries(vState)
			.filter((item) => !item[1])
			.map((item) => ({
				label: getLabelFromId(item[0]) || item[0],
				value: item[0],
			}))
			.sort((a, b) => a.label.localeCompare(b.label));
	const getSelectedColumns = (vState: VisibilityState) =>
		Object.entries(vState)
			.filter((item) => item[1])
			.map((item) => ({
				label: getLabelFromId(item[0]) || item[0],
				value: item[0],
			}));

	const [availableColumns, setAvailableColumns] = useState<TransferListItem[]>(getAvailableColumns(visibilityState));
	const [selectedColumns, setSelectedColumns] = useState<TransferListItem[]>(getSelectedColumns(visibilityState));

	const handleSubmit = () => {
		const res: VisibilityState = {};
		availableColumns.forEach((item) => {
			res[item.value] = false;
		});
		selectedColumns.forEach((item) => {
			res[item.value] = true;
		});
		setVisibilityState(res);
		setOrderState(selectedColumns.map((item) => item.value));
		setColumnSticky(stickyCopy);
		handleClose();
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
			<DialogTitle>
				<Icon path={mdiCog} size="24px" />
				Customize Columns
				<CloseButton onClose={handleClose} />
			</DialogTitle>
			<DialogContent>
				<ContentWrapper>
					<ColumnsTransferList
						leftList={availableColumns}
						rightList={selectedColumns}
						setLeftList={(value) => setAvailableColumns(value)}
						setRightList={(value) => {
							setSelectedColumns(value);
						}}
						columnSticky={stickyCopy}
						setColumnSticky={setStickyCopy}
						labelLeft="Available Columns"
						labelRight="Selected Columns"
					/>
				</ContentWrapper>
			</DialogContent>
			<DialogActions>
				<Button
					nxstyle="secondary-black"
					endIcon={<Icon path={mdiCloseCircle} size="16px" />}
					onClick={() => handleClose()}
				>
					Cancel
				</Button>
				<Button
					nxstyle="primary-blue"
					endIcon={<Icon path={mdiCheckboxMarkedCircle} size="16px" />}
					onClick={() => handleSubmit()}
				>
					Apply
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SettingsModal;
