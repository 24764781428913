import React, { useCallback, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CompanyDisplayCreditContext } from '../providers/companyDisplayCreditsProvider';
import TextInput from '../../../../../../components/library/TextInput';
import TitleEditableField, { ReadOnlyTypography } from '../../../../../../components/titles/TitleEditableField';
import useBlurAndDebounceValue from '../../../../hooks/useTitleRecordsAutoSave/useBlurAndDebounceValue';

interface DisplaySequenceProps {
	companyDisplayCreditDisplayIndex: number;
	editMode: boolean;
}

const Wrapper = styled.div`
	input {
		width: 0 !important;
	}
`;

export default function DisplaySequence({ companyDisplayCreditDisplayIndex, editMode }: DisplaySequenceProps) {
	const companyDisplayCreditContext = useContext(CompanyDisplayCreditContext);
	const { isInherited } = companyDisplayCreditContext;

	const initialValue =
		companyDisplayCreditContext.data?.displayValue?.[companyDisplayCreditDisplayIndex]?.displaySequence?.toString();

	const saveRef = useRef<(val: number | undefined) => void>();

	useEffect(() => {
		saveRef.current = (val: number | undefined) => {
			companyDisplayCreditContext.editDisplaySequence(val, companyDisplayCreditDisplayIndex);
			companyDisplayCreditContext.commit();
		};
	}, [companyDisplayCreditContext.editDisplaySequence, companyDisplayCreditContext.commit]);

	const {
		value: displaySequence,
		setValue: setDisplaySequence,
		commit: commitBlurAndDebounce,
	} = useBlurAndDebounceValue<string | undefined>({
		initialValue,
		save: useCallback((val) => saveRef.current?.(Number(val)), [saveRef]),
	});

	return (
		<TitleEditableField
			direction="column"
			path="coreMetadata.companyDisplayCredits.displaySequence"
			label="Display Sequence"
			readComponent={
				<ReadOnlyTypography variant={isInherited ? 'prefilled' : 'default'}>
					{companyDisplayCreditContext.readonlyData?.displayValue?.[companyDisplayCreditDisplayIndex]?.displaySequence}
				</ReadOnlyTypography>
			}
			editComponent={
				<Wrapper>
					<TextInput
						variant={isInherited ? 'prefilled' : 'default'}
						disabled={!editMode}
						height={38}
						disabledMargin
						value={String(displaySequence)}
						onBlur={commitBlurAndDebounce}
						onChange={(e) => {
							setDisplaySequence(e.target.value);
						}}
						type="number"
					/>
				</Wrapper>
			}
		/>
	);
}
