import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

// TYPES
import { isInherited, OptionalNullInherited, titlePaths } from '@warehouse/title/core';
import { TitleSecurity } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Basic';

// HOOKS
import useMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';
import useTitleAutoSave, {
	optionalNullInheritedToOptionalInherited,
} from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';

// LIBRARY
import { FieldWrapper, Row } from '../../../../src/views/title/TitleSettings/style';
import TextInput from '../../../../src/components/library/TextInput';
import DatePicker, { dateToString, stringToDate } from '../../../../src/components/library/DatePicker/DatePicker';
import TitleTooltip from '../../../../src/components/titles/TitleTooltip';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';

function isNewValueDifferent(
	newTitleSecurity: OptionalNullInherited<TitleSecurity> | undefined,
	currentTitleSecurity: OptionalNullInherited<TitleSecurity> | undefined,
): boolean {
	const titleSecurityIsDifferent =
		newTitleSecurity?.displayValue?.titleSecurity.trim() !== currentTitleSecurity?.displayValue?.titleSecurity;
	const expirationIsDifferent =
		newTitleSecurity?.displayValue?.expiration?.trim() !== currentTitleSecurity?.displayValue?.expiration;

	return titleSecurityIsDifferent || expirationIsDifferent;
}

function trimValue(titleSecurity: OptionalNullInherited<TitleSecurity> | undefined) {
	if (titleSecurity?.displayValue?.titleSecurity) {
		return {
			...titleSecurity,
			displayValue: titleSecurity.displayValue
				? {
						titleSecurity: titleSecurity.displayValue.titleSecurity.trim(),
						expiration: titleSecurity.displayValue?.expiration?.trim(),
				  }
				: undefined,
		};
	}
	return titleSecurity;
}

export function TitleSecurityField() {
	const { value, setValue, commit } = useTitleAutoSave<OptionalNullInherited<TitleSecurity>>({
		label: 'Security Title',
		path: useMemoJsonPath(titlePaths.titleSecurity.split('.')),
		isNewValueDifferent,
		trimValue,
	});

	const memoExpirationDate = useMemo(
		() => (value?.displayValue?.expiration ? stringToDate(value?.displayValue?.expiration || '') : undefined),
		[value?.displayValue?.expiration],
	);

	return (
		<Row>
			<TitleEditableField
				label="Security Title"
				path={titlePaths.titleSecurity}
				wrapperStyle={{ padding: 0, marginBottom: 14 }}
				direction="column"
				forcedEditMode
				editComponent={
					<TextInput
						variant={getVariant(value)}
						height={38}
						disabledMargin
						value={value?.displayValue?.titleSecurity || ''}
						onBlur={() => {
							commit();
						}}
						onChange={(e) => {
							if (e)
								setValue((prev) => ({
									...prev,
									displayValue: {
										...(prev?.displayValue || {}),
										titleSecurity: e.target.value,
									},
								}));
							if (e.target.value === '') {
								setValue((prev) => ({
									...prev,
									displayValue: null,
								}));
							}
						}}
					/>
				}
			/>
			<TitleEditableField
				label="Expiration Date"
				path={titlePaths.titleSecurity}
				wrapperStyle={{ padding: 0, marginBottom: 14 }}
				direction="column"
				forcedEditMode
				editComponent={
					<DatePicker
						variant={getVariant(value)}
						height={38}
						value={memoExpirationDate}
						onChange={(v) => {
							if (v) {
								setValue((prev) => ({
									...prev,
									displayValue: {
										...(prev?.displayValue || { titleSecurity: '' }),
										expiration: dateToString(v),
									},
								}));
								commit();
							} else {
								setValue((prev) => ({
									...prev,
									displayValue: {
										...(prev?.displayValue || { titleSecurity: '' }),
										expiration: undefined,
									},
								}));
								commit();
							}
						}}
					/>
				}
			/>
		</Row>
	);
}

function getVariant(value: OptionalNullInherited<TitleSecurity> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<TitleSecurity>({ inheritedObject: optionalNullInheritedToOptionalInherited(value) }).isInherited
		? 'prefilled'
		: 'default';
}
