import React from 'react';
import MUILinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import styled, { css } from 'styled-components';

const StyledProgressBar = styled(MUILinearProgress)<LinearProgressProps>(
	({ theme }) => css`
		background-color: ${theme.palette.light.backgroundAlt};
		border-radius: 4px;
		height: 16px;
		width: 100%;
		.MuiLinearProgress-barColorPrimary {
			background-color: ${theme.palette.primary.main};
		}
	`,
);

function ProgressionBar(props: LinearProgressProps) {
	return <StyledProgressBar {...props} />;
}

export default ProgressionBar;
