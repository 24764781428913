import { CompilationObjectEntry } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/TitleMetadata';
import { CommonReadonly, TitleFull } from '@warehouse/title/core';
import { DisplayName, PartialDisplayName } from './comp-obj-relationship.model';
import { getWrittenLanguage } from '../core/languages';
import { getDefaultLocalizedInfo } from '../core/localized-infos';
import { ChildNotFoundError } from './common-relationship.error';

export interface CompObjRelationshipEditorAdapterInput {
	childUuid: string;
	compilationEntries: CompilationObjectEntry[];
	childData: TitleFull;
	parentData: TitleFull;
}

export interface CompObjRelationshipEditorAdapterOutput {
	childTitle: CommonReadonly;
	parentTitle: CommonReadonly;
	entryNumber: string | undefined;
	entryClass: string | undefined;
	defaultLanguageId: string;
	displayNames: DisplayName[];
}

export function adapt({
	childUuid,
	compilationEntries,
	childData,
	parentData,
}: CompObjRelationshipEditorAdapterInput): CompObjRelationshipEditorAdapterOutput {
	const compilationEntry = findCompilationEntry(compilationEntries, childUuid);
	if (!compilationEntry) {
		throw new ChildNotFoundError(`Compilation entry not found for child ${childUuid}`);
	}

	const entryNumber = compilationEntry.compilationEntryNumber;
	const entryClass = compilationEntry.compilationEntryClass;
	const displayNames = mapDisplayNames(compilationEntry.displayNames || []);

	const defaultLocalizedInfo = getDefaultLocalizedInfo(parentData);

	if (!defaultLocalizedInfo) {
		throw new Error(`Inconsistent state: default localized info not found for title ${parentData.uuid}`);
	}

	return {
		childTitle: childData.readonly,
		parentTitle: parentData.readonly,
		entryNumber,
		entryClass,
		displayNames,
		defaultLanguageId: defaultLocalizedInfo.language.displayValue,
	};
}

export function updateEntryNumberInCompilationEntries(
	entryNumber: string | undefined,
	childUuid: string,
	compilationEntries: CompilationObjectEntry[],
): CompilationObjectEntry[] {
	const entryIndex = findCompilationEntryIndex(compilationEntries, childUuid);

	const updatedEntries = [...compilationEntries];
	updatedEntries[entryIndex] = {
		...updatedEntries[entryIndex],
		compilationEntryNumber: stringToUndefined(entryNumber),
	};

	return updatedEntries;
}

export function updateEntryClassInCompilationEntries(
	entryClass: string,
	childUuid: string,
	compilationEntries: CompilationObjectEntry[],
): CompilationObjectEntry[] {
	const entryIndex = findCompilationEntryIndex(compilationEntries, childUuid);

	const updatedEntries = [...compilationEntries];
	updatedEntries[entryIndex] = {
		...updatedEntries[entryIndex],
		compilationEntryClass: entryClass,
	};

	return updatedEntries;
}

export function addEmptyDisplayNameInCompilationEntries(
	childUuid: string,
	compilationEntries: CompilationObjectEntry[],
	languageId?: string,
): CompilationObjectEntry[] {
	const entryIndex = findCompilationEntryIndex(compilationEntries, childUuid);

	const updatedEntries = [...compilationEntries];

	const languageAlreadyExists =
		updatedEntries[entryIndex].displayNames?.some((dn) => dn.language === languageId) || false;
	const languageToAdd = languageAlreadyExists ? undefined : languageId;

	updatedEntries[entryIndex] = {
		...updatedEntries[entryIndex],
		displayNames: [
			// @ts-ignore Hack until we refactor UseTitleAutoSave to maintain a proper local state
			...(updatedEntries[entryIndex].displayNames || []),
			// @ts-ignore Hack until we refactor UseTitleAutoSave to maintain a proper local state
			{ displayName: undefined, language: languageToAdd },
		],
	};

	return updatedEntries;
}

export function removeDisplayNameInCompilationEntries(
	displayNameIndexes: number[],
	childUuid: string,
	compilationEntries: CompilationObjectEntry[],
): CompilationObjectEntry[] {
	if (displayNameIndexes.length === 0) {
		return compilationEntries;
	}

	const entryIndex = findCompilationEntryIndex(compilationEntries, childUuid);

	const updatedEntries = [...compilationEntries];
	const displayNames = [...(updatedEntries[entryIndex].displayNames || [])];

	updatedEntries[entryIndex] = {
		...updatedEntries[entryIndex],
		displayNames: displayNames.filter((_, index) => !displayNameIndexes.includes(index)),
	};

	return updatedEntries;
}

export function updateDisplayNameDisplayNameInCompilationEntries(
	newDisplayName: string | undefined,
	displayNameIndex: number,
	childUuid: string,
	compilationEntries: CompilationObjectEntry[],
): CompilationObjectEntry[] {
	const entryIndex = findCompilationEntryIndex(compilationEntries, childUuid);

	const updatedEntries = [...compilationEntries];
	const displayNames = updatedEntries[entryIndex].displayNames || [];

	if (displayNameIndex < 0 || displayNameIndex >= displayNames.length) {
		throw new Error(`Invalid display name index: ${displayNameIndex}`);
	}

	updatedEntries[entryIndex] = {
		...updatedEntries[entryIndex],
		// @ts-ignore Hack until we refactor UseTitleAutoSave to maintain a proper local state
		displayNames: displayNames.map((dn, index) =>
			index === displayNameIndex ? { ...dn, displayName: newDisplayName } : dn,
		),
	};

	return updatedEntries;
}

export function updateDisplayNameLanguageInCompilationEntries(
	newLanguage: string | undefined,
	displayNameIndex: number,
	childUuid: string,
	compilationEntries: CompilationObjectEntry[],
): CompilationObjectEntry[] {
	const entryIndex = findCompilationEntryIndex(compilationEntries, childUuid);

	const updatedEntries = [...compilationEntries];
	const displayNames = updatedEntries[entryIndex].displayNames || [];

	if (displayNameIndex < 0 || displayNameIndex >= displayNames.length) {
		throw new Error(`Invalid display name index: ${displayNameIndex}`);
	}

	updatedEntries[entryIndex] = {
		...updatedEntries[entryIndex],
		displayNames: displayNames.map((dn, index) => (index === displayNameIndex ? { ...dn, language: newLanguage } : dn)),
	};

	return updatedEntries;
}

export function updateCompObjEntriesDelete(
	childUuidToDelete: string,
	compObjEntries: CompilationObjectEntry[],
): CompilationObjectEntry[] {
	return compObjEntries.filter((compObjEntry) => childUuidToDelete !== compObjEntry.contentId);
}

export function validateNoUndefinedDisplayNames(displayNames: PartialDisplayName[]): boolean {
	return displayNames.every((displayName) => displayName.displayName !== undefined);
}

function findCompilationEntry(
	compilationEntries: CompilationObjectEntry[],
	childUuid: string,
): CompilationObjectEntry | undefined {
	return compilationEntries.find((entry) => entry.contentId === childUuid);
}

function findCompilationEntryIndex(compilationEntries: CompilationObjectEntry[], childUuid: string): number {
	const entryIndex = compilationEntries.findIndex((entry) => entry.contentId === childUuid);
	if (entryIndex === -1) {
		throw new Error(`Compilation entry not found for child ${childUuid}`);
	}
	return entryIndex;
}

function mapDisplayNames(displayNames: CompilationObjectEntry['displayNames']): DisplayName[] {
	return (displayNames || []).map((displayName) => ({
		displayName: displayName.displayName,
		language: displayName.language ? getWrittenLanguage(displayName.language) : undefined,
	}));
}

function stringToUndefined(str: string | undefined): string | undefined {
	if (str === undefined || str === '') {
		return undefined;
	}
	return str;
}
