import { RelationshipSubtypeObject } from '@warehouse/title/core';
import availRelationshipSubtypes from '../../../src/assets/json-administration-profiles/availableRelationshipSubtypes.json';

export function getRelationshipSubtypeObject(relationshipSubtypeUuid: string): RelationshipSubtypeObject {
	const obj = availRelationshipSubtypes.find(({ uuid }) => uuid === relationshipSubtypeUuid);
	if (!obj) throw new Error(`Could not find relationship subtype with uuid ${relationshipSubtypeUuid}`);
	return {
		uuid: obj.uuid,
		label: obj.label,
	};
}

export function getAllRelationshipSubtypes(): RelationshipSubtypeObject[] {
	return availRelationshipSubtypes.map(({ uuid, label }) => ({ uuid, label }));
}
