import { Icon } from '@mdi/react';
import { mdiAppleKeyboardCommand, mdiChevronDown } from '@mdi/js';
import { alpha, useTheme } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ModalMenu, MenuListItem, Button } from '@warehouse/shared/legacy-ui';
import useWorkTypes from '../../../../src/utils/hooks/titles/useWorkTypes';

const MenuList = styled.ul<{ backgroundColor?: string }>(
	({ theme, backgroundColor }) => css`
		${backgroundColor &&
		css`
			background-color: ${theme.palette.light.background};
		`}
		margin: 0;
		padding: 0;
	`,
);

const StyledButton = styled(Button)<{ $active?: boolean; $error?: boolean }>(
	({ theme, $active, $error, disabled }) => css`
		border-bottom-right-radius: 0;
		border-right-width: 0;
		border-top-right-radius: 0;
		color: ${theme.palette.text.secondary};
		display: flex;
		font-size: 14px;
		font-weight: 500;

		${
			$error &&
			css`
				background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
				color: ${theme.palette.error.text};

				& .MuiButton-endIcon,
				.MuiButton-startIcon {
					svg {
						color: ${theme.palette.error.text} !important;
					}
				}
			`
		}

		& .MuiButton-endIcon {
			margin-left: auto;
		}

		height: 40px;
		justify-content: flex-start;
		min-width: 150px;

			${
				!disabled &&
				css`
					&:hover {
						background-color: ${theme.palette.light.backgroundAlt};
					}
				`
			}}
	

		${
			$active &&
			css`
				background-color: ${theme.palette.light.backgroundAlt};
			`
		}
	`,
);

interface TitleSearchFilterWorkTypeProps {
	onWorkTypeChange: (workTypeUuid: string | null) => void;
	allowedWorkTypeUuids: string[];
	workTypeUuid: string | null;
	setWorkTypeUuid: (workTypeUuid: string | null) => void;
	disabled: boolean;
}

export function TitleSearchFilterWorkType({
	onWorkTypeChange,
	allowedWorkTypeUuids,
	workTypeUuid,
	setWorkTypeUuid,
	disabled,
}: TitleSearchFilterWorkTypeProps) {
	const [openModalMenu, setOpenModalMenu] = useState<boolean>(false);
	const { getNameByUuid } = useWorkTypes();
	const theme = useTheme();
	const modalMenuAnchorRef = useRef(null);
	const displayedWorkTypesUuidOptions = useMemo(() => [null, ...allowedWorkTypeUuids], [allowedWorkTypeUuids]);

	return (
		<div style={{ display: 'flex' }}>
			<div ref={modalMenuAnchorRef}>
				<StyledButton
					$active={openModalMenu}
					startIcon={<Icon size="16px" path={mdiAppleKeyboardCommand} />}
					endIcon={<Icon size="16px" path={mdiChevronDown} />}
					onClick={() => setOpenModalMenu(true)}
					disabled={disabled}
				>
					{workTypeUuid === null ? 'All' : getNameByUuid(workTypeUuid)}
				</StyledButton>
			</div>
			<ModalMenu
				open={openModalMenu}
				disableOverlay
				anchorEl={modalMenuAnchorRef.current}
				onClose={() => setOpenModalMenu(false)}
				position="bottom"
				content={
					<MenuList backgroundColor={theme.palette.light.background}>
						{displayedWorkTypesUuidOptions.map((workType) => (
							<MenuListItem
								key={workType}
								height={32}
								onClick={() => {
									setWorkTypeUuid(workType);
									onWorkTypeChange(workType);
									setOpenModalMenu(false);
								}}
								icon={<Icon size="16px" path={mdiAppleKeyboardCommand} />}
								text={workType === null ? 'All' : getNameByUuid(workType) || 'All'}
							/>
						))}
					</MenuList>
				}
			/>
		</div>
	);
}
