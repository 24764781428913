import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import { mdiChevronDown } from '@mdi/js';
import Dropdown from './Dropdown';
import { IndexText, IndexWrapper } from './IndexWrapper';
import { useEditorContext, useEditorDispatch } from '../../views/profiles/editor/EditorContext';
import { CaretWrapper } from './CollapseComponents';

interface ConditionBlockProps {
	options: { label: string; value: string }[];
	name: string;
	iconPath: string;
	isSelected?: boolean;
	index?: number;
	collapsibleId: string;
}

interface ContainerProps {
	isSelected: boolean;
}

export const ConditionBlockContainer = styled.div<ContainerProps>(
	({ isSelected, theme }) => css`
		align-items: center;
		background-color: ${theme.palette.light.main};
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		display: flex;
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.05));
		flex-direction: row;
		height: 32px;
		svg,
		p,
		span {
			color: ${theme.palette.text.secondary};
		}
		width: 100%;

		${isSelected &&
		css`
			p,
			span,
			svg {
				color: ${theme.palette.blue.main};
			}

			border: solid 1px ${theme.palette.blue.main};
		`}
	`,
);

const NameContainer = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		overflow: hidden;
		padding: 0 ${theme.spacing(1.25)};
	`,
);

const IconWrapper = styled.div(
	() => css`
		height: 16px;
		width: 16px;
	`,
);

export const ConditionBlockNameText = styled((props) => <Typography {...props} variant="buttonSmallRegular" />)(
	({ theme }) => css`
		margin-left: ${theme.spacing(1.25)};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

function ConditionBlock({ isSelected = false, options, name, iconPath, index, collapsibleId }: ConditionBlockProps) {
	const context = useEditorContext();
	const dispatch = useEditorDispatch();
	const collapsed = context?.collapsedItems || [];
	const isCollapsed = collapsed.includes(collapsibleId);

	const ConditionBlockComp = (
		<ConditionBlockContainer isSelected={isSelected}>
			<Dropdown
				isSelected={isSelected}
				placement="bottom"
				enablePortal
				height={32}
				value="at_least_one_of"
				onChange={() => {}}
				options={options}
			/>
			<NameContainer>
				<IconWrapper>
					<Icon path={iconPath} size="16px" />
				</IconWrapper>
				<ConditionBlockNameText>{name}</ConditionBlockNameText>
			</NameContainer>
		</ConditionBlockContainer>
	);

	return (
		<IndexWrapper>
			<CaretWrapper
				closed={isCollapsed}
				data-no-dnd
				onClick={() => {
					if (dispatch)
						dispatch({
							type: 'toggle',
							id: collapsibleId,
						});
				}}
			>
				<Icon path={mdiChevronDown} size="28px" />
			</CaretWrapper>
			{index !== undefined && <IndexText>{index}</IndexText>}
			{ConditionBlockComp}
		</IndexWrapper>
	);
}

export default ConditionBlock;
