import styled, { css } from 'styled-components';
import { alpha, Switch as MUISwitch } from '@mui/material';

const Switch = styled(MUISwitch)`
	${({ theme }) => css`
		& .MuiSwitch-switchBase.Mui-checked {
			color: ${theme.palette.blue.main};
			&:hover {
				background-color: ${alpha(theme.palette.blue.main, theme.palette.action.hoverOpacity)};
			}
		}
		& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
			background-color: ${theme.palette.blue.main};
		}
	`}
`;

export const LabelSwitch = styled(MUISwitch)`
	${({ theme }) => css`
		height: 9px;
		padding: 0;
		width: 17px;

		& .MuiSwitch-thumb {
			width: 7px;
			height: 7px;
		}

		& .MuiButtonBase-root {
			top: -6px;
			left: -6px;
		}

		& .MuiSwitch-switchBase {
			padding: 7px;
			&.Mui-checked {
				transform: translateX(8px);
			}
		}
		& .MuiSwitch-switchBase.Mui-checked {
			color: ${theme.palette.blue.main};
			&:hover {
				background-color: ${alpha(theme.palette.blue.main, theme.palette.action.hoverOpacity)};
			}
		}
		& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
			background-color: ${theme.palette.blue.main};
		}
	`}
`;

export default Switch;
