import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { TitleAlternate } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';

// LIBRARY
import { DropdownV2 } from '@warehouse/shared/ui';
import sortAlphabeticallyByLabel from '../../../../../src/utils/sortAlphabeticallyByLabel';
import { CellWrapper } from '../../../../../src/components/library/SimpleTable/style';

// JSONS
import languagesWrittenJson from '../../../../../src/assets/json-administration-profiles/availableLanguageWritten.json';

function LocaleCell(info: CellContext<TitleAlternate, TitleAlternate>) {
	const { getValue, table, row } = info;
	const languagesWritten = languagesWrittenJson.sort(sortAlphabeticallyByLabel);
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options?.meta?.customPropsCell?.isInherited;

	return (
		<CellWrapper>
			<DropdownV2
				withSearch
				transparentBorder
				backgroundColor="transparent"
				variant={isInherited ? 'prefilled' : 'default'}
				value={getValue()?.language}
				onBlur={() => commit()}
				onChange={(e) => {
					setValue((prev: OptionalInherited<TitleAlternate[]>) => ({
						...prev,
						displayValue: prev.displayValue?.map((ta, index) => {
							if (index === row.index) {
								return {
									...ta,
									language: e,
								};
							}
							return ta;
						}),
					}));
				}}
				options={
					languagesWritten?.map((locale: any) => ({
						label: locale.label,
						value: locale.uuid,
					})) || []
				}
			/>
		</CellWrapper>
	);
}

export default LocaleCell;
