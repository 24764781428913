import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import Button from './Button';
import UploadDropAreaSVG from '../../assets/upload-drop-area.svg';

const Wrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		display: flex;
		flex: 1;
		padding: ${theme.spacing(2)};
	`,
);

const DashedSubWrapper = styled.div<{ isActive?: boolean }>(
	({ theme, isActive }) => css`
		align-items: center;
		background-color: ${isActive ? theme.palette.light.backgroundAlt : theme.palette.light.background} !important;
		border: dashed 1px ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		justify-content: center;
		padding: ${theme.spacing(2)};
	`,
);

interface UploadDropzoneProps {
	onDrop: (acceptedFiles: File[]) => void;
}

function UploadDropzone({ onDrop }: UploadDropzoneProps) {
	const onDropCB = useCallback(onDrop, [onDrop]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: onDropCB,
	});

	return (
		<Wrapper {...getRootProps()}>
			<DashedSubWrapper isActive={isDragActive}>
				<input {...getInputProps()} />
				<img src={UploadDropAreaSVG} alt="eidrIcon" />
				{isDragActive ? (
					<Typography variant="bodyLargeBold">Drop the files here ...</Typography>
				) : (
					<Typography variant="bodyLargeBold">Drag and drop files to upload</Typography>
				)}
				<Button nxstyle="secondary-black">Select Files</Button>
			</DashedSubWrapper>
		</Wrapper>
	);
}

export default UploadDropzone;
