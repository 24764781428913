import React, { CSSProperties, memo } from 'react';
import { useTheme } from '@mui/material';
import { DropdownOption } from './types';
import { MenuItem, OptionsWrapper } from './styles';
import Checkbox from '../Checkbox';
import { getOption } from '../DropdownUtils';

export const getTruncatedLabel = (str: string, width: number) => {
	let max;
	if (width < 150) max = 15;
	else max = ((width - 20) / 100) * 10;

	if (str.length > max) return `${str.substring(0, max)}...`;
	return str;
};

const Row = memo(
	({
		data,
		index,
		style,
	}: {
		data: {
			highlightedIndex: number;
			isSearchBackend?: boolean;
			searchValue: string;
			handleSelect: (value: string | null) => void;
			options: DropdownOption[];
			values: string[];
			filteredOptions: DropdownOption[];
			height?: number;
			defaultOptions?: DropdownOption[];
		};
		index: number;
		style: CSSProperties;
	}) => {
		const theme = useTheme();
		const {
			highlightedIndex,
			isSearchBackend,
			searchValue,
			handleSelect,
			options,
			values,
			filteredOptions,
			defaultOptions,
			height,
		} = data;

		const getMenuItem = (option: DropdownOption | undefined, withDivider = false) => {
			if (!option) return null;

			return (
				<MenuItem
					highlighted={highlightedIndex === index}
					tabIndex={0}
					key={option.value}
					onKeyDown={(e) => {
						if (e.code === 'Space' || e.code === 'Enter') handleSelect(option.value);
					}}
					onClick={(e) => {
						e.stopPropagation();
						handleSelect(option.value);
					}}
					style={{
						...style,
						...(withDivider
							? {
									// @ts-ignore
									borderBottom: `solid 1px ${theme.palette.light.backgroundAlt}`,
							  }
							: {}),
					}}
					height={height}
				>
					<OptionsWrapper>
						<Checkbox checked={values?.includes(option?.value || '')} />
						<span className="select-dropdown-option-label">{option.label}</span>
					</OptionsWrapper>
				</MenuItem>
			);
		};

		return getOption(getMenuItem, index, searchValue, options, defaultOptions, filteredOptions, isSearchBackend);
	},
);

export default Row;
