import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { mdiInformation } from '@mdi/js';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {
	autoPlacement,
	autoUpdate,
	FloatingFocusManager,
	FloatingPortal,
	offset,
	shift,
	size,
	useFloating,
} from '@floating-ui/react';
import { Label, LabelBlockWrapper, TooltipIcon } from '../InputUtils';
import Tooltip from '../Tooltip';
import useHandleClickOutside from '../../../utils/hooks/useHandleClickOutside';
import InputError, { WrapperInputError } from '../InputError';
import { handleBackSpaces, parseDateFromInput } from './dateUtils';
import { CustomInput, Popper, TriggerWrapper, Wrapper } from './style';

export type DatePickerVariant = 'default' | 'prefilled';

export interface DatePickerProps {
	value?: Date | undefined;
	onChange: (day: Date | undefined) => void;
	label?: string;
	tooltip?: string;
	error?: string;
	transparentBorder?: boolean;
	placeholder?: string;
	variant?: DatePickerVariant;
	verticalPadding?: boolean;
	height?: number;
	labelIcon?: React.ReactNode;
	ariaLabel?: string;
}

function containsOnlyDigitsOrDashes(str: string) {
	if (str === '') return true;
	return /^[\d-]+$/.test(str);
}

export function dateToString(date: Date | null) {
	if (!date) return '';

	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString();
	const day = date.getDate().toString();

	return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export function timeToString(date: Date) {
	const dateString = dateToString(date);

	const hours = date.getHours().toString();
	const minutes = date.getMinutes().toString();
	return `${dateString} ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
}

// This function is necessary because the Date constructor uses UTC timezone if simply given YYYY-MM-DD
export function stringToDate(str: string): Date {
	return new Date(`${str}T00:00:00`);
}

function DatePicker({
	value,
	onChange,
	label,
	tooltip,
	error,
	transparentBorder,
	placeholder,
	variant = 'default',
	labelIcon,
	height = 48,
	verticalPadding = true,
	ariaLabel,
}: DatePickerProps) {
	const onChangeCalled = useRef(false);
	const onChangeWrapper = (d: Date | undefined) => {
		onChange(d);
		onChangeCalled.current = true;
	};
	const [inputValue, setInputValue] = useState<string>(value ? dateToString(value) : '');
	const [displayedMonth, setDisplayedMonth] = useState<Date | undefined>(value);
	const [isFloatingOpen, setIsFloatingOpen] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const [calendarHeight, setCalendarHeight] = useState<number | undefined>(undefined);
	const { refs, floatingStyles, context } = useFloating({
		open: isFloatingOpen,
		onOpenChange: setIsFloatingOpen,
		middleware: [
			autoPlacement({
				allowedPlacements: ['top', 'bottom'],
				padding: 8,
			}),
			offset(8),
			shift({ padding: 8 }),
			size({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.reference.width - 2}px`,
					});
				},
			}),
		],
		whileElementsMounted: autoUpdate,
	});

	useHandleClickOutside(refs.reference, () => {
		setIsFloatingOpen(false);
	});

	useEffect(() => {
		if (value) {
			setDisplayedMonth(value);
			if (onChangeCalled.current) {
				onChangeCalled.current = false;
			} else {
				setInputValue(dateToString(value));
			}
		} else {
			setInputValue('');
		}
	}, [value]);

	useEffect(() => {
		setCalendarHeight(refs.floating?.current?.children[0]?.getBoundingClientRect()?.height);
	}, [value, displayedMonth, isFloatingOpen]);

	const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const eValue = e.target.value;

		if (eValue === '') {
			onChange(undefined);
			return;
		}

		// Delete last characters if it's not a digit or a dash or a slash
		if (handleBackSpaces(eValue, inputValue, setInputValue)) return;

		// Open the floating if it's not open
		if (!isFloatingOpen) setIsFloatingOpen(true);

		// If the value is already filled, can not type more
		if (eValue.length > 'YYYY-MM-DD'.length) return;

		let addDash = false;
		// Add dashes as separators between each date element
		if ([4, 7].includes(eValue.length)) {
			addDash = true;
		}

		// If the user tries to type a character that is not a digit or a dash or a slash, do not type it
		if (!containsOnlyDigitsOrDashes(eValue)) return;

		const parsedValue = parseDateFromInput(eValue);

		if (parsedValue) {
			onChangeWrapper(parsedValue);
		}

		if (addDash) {
			setInputValue(`${eValue}-`);
		} else {
			setInputValue(eValue);
		}
	};

	return (
		<Wrapper error={!!error} active={isFloatingOpen} role="combobox">
			<WrapperInputError>
				{label && (
					<Label variant="buttonLargeMedium" error={error} className="input-label">
						<>
							<LabelBlockWrapper>
								{label}
								{tooltip && (
									<Tooltip title={tooltip} placement="right">
										<TooltipIcon path={mdiInformation} size="16px" />
									</Tooltip>
								)}
							</LabelBlockWrapper>
							{labelIcon}
						</>
					</Label>
				)}
				<TriggerWrapper
					ref={refs.setReference}
					className="input-wrapper"
					tabIndex={0}
					onClick={(event) => {
						if (inputRef.current && inputRef.current === event.target) {
							inputRef.current.focus();
							setIsFloatingOpen(true);
						}
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter' && inputRef.current) {
							if (inputRef.current === document.activeElement) {
								inputRef.current.blur();
							} else {
								inputRef.current.focus();
							}
							setIsFloatingOpen((prev) => !prev);
						}
						if (['Escape', 'Tab'].includes(e.key) && inputRef.current) {
							inputRef.current.blur();
							setIsFloatingOpen(false);
						}
					}}
					onFocus={() => {
						setIsFloatingOpen(true);
						inputRef.current?.focus();
					}}
					error={!!error}
				>
					<CustomInput
						aria-label={ariaLabel}
						error={!!error}
						ref={inputRef}
						className={classNames({ inherited: variant === 'prefilled' })}
						value={inputValue}
						active={isFloatingOpen}
						height={height}
						transparentBorder={transparentBorder}
						placeholder={placeholder || 'YYYY-MM-DD'}
						verticalPadding={verticalPadding}
						onChange={handleOnChangeInput}
					/>
					<FloatingPortal root={document.body}>
						<FloatingFocusManager context={context} modal={false} returnFocus={false}>
							<Popper
								ref={refs.setFloating}
								className="menu-wrapper"
								active={isFloatingOpen}
								style={floatingStyles}
								calendarHeight={calendarHeight}
							>
								<DayPicker
									captionLayout="dropdown-buttons"
									mode="single"
									modifiersClassNames={{
										selected: 'rdp-selected',
									}}
									fromYear={1900}
									toYear={2100}
									month={displayedMonth}
									onMonthChange={(month) => setDisplayedMonth(month)}
									selected={value}
									onSelect={(v) => {
										if (v) {
											setInputValue(dateToString(v));
										} else {
											setInputValue('');
										}
										setTimeout(() => {
											setIsFloatingOpen(false);
										}, 50);
										onChangeWrapper(v);
									}}
								/>
							</Popper>
						</FloatingFocusManager>
					</FloatingPortal>
				</TriggerWrapper>
				{error ? <InputError message={error} /> : null}
			</WrapperInputError>
		</Wrapper>
	);
}

export default DatePicker;
