import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { IndexedTitleV2 } from '@warehouse/graphql';
import styled, { css } from 'styled-components';
import { TitleFull } from '@warehouse/title/core';
import DisplayTitleField from '../fields/DisplayTitle';
import WorkTypeDetailField from '../fields/WorkTypeDetail';
import ParentField from '../fields/ParentField';
import RelationshipTypeField from '../fields/RelationshipType';

const Row = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: ${theme.spacing(2)};
	`,
);

interface SupplementalProps {
	workType: string;
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	watch: UseFormWatch<any>;
	errors: FieldErrors<any>;
	parentTitle?: {
		title: IndexedTitleV2 | undefined;
		parentType: string | undefined;
	};
	selectedParentTitle?: TitleFull;
}

function Supplemental({
	control,
	errors,
	setValue,
	trigger,
	watch,
	workType,
	parentTitle,
	selectedParentTitle,
}: SupplementalProps) {
	const relationshipType = watch('relationshipType');
	const licensor = watch('licensor');

	return (
		<div>
			<WorkTypeDetailField
				workType={workType}
				control={control}
				setValue={setValue}
				trigger={trigger}
				errors={errors}
				isRequired
			/>
			<DisplayTitleField control={control} setValue={setValue} errors={errors} trigger={trigger} isRequired />
			<Row>
				{!selectedParentTitle?.uuid && (
					<RelationshipTypeField
						control={control}
						setValue={setValue}
						errors={errors}
						trigger={trigger}
						isRequired={!!parentTitle?.title}
					/>
				)}
				<ParentField
					isRequired={!!relationshipType}
					control={control}
					setValue={setValue}
					trigger={trigger}
					parentTitle={parentTitle}
					selectedParentTitle={selectedParentTitle}
					licensor={licensor}
					errors={errors}
					workType={workType}
				/>
			</Row>
		</div>
	);
}

export default Supplemental;
