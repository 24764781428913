import styled, { css } from 'styled-components';
import { Tabs as MUITabs } from '@mui/material';

const Tabs = styled(MUITabs)`
	${({ theme }) => css`
		&.MuiTabs-root {
			border-bottom: 1px solid ${theme.palette.light.backgroundAlt} !important;
		}

		.Mui-selected {
			color: ${theme.palette.blue.main};
		}

		.Mui-disabled {
			color: ${theme.palette.action.disabled};
		}

		.MuiTabs-indicator {
			background-color: ${theme.palette.blue.main};
		}

		.MuiTabs-flexContainer > .MuiButtonBase-root {
			display: flex;
			flex: 1;
			font-size: 18px;
			font-weight: 500;
			padding: 13px 0;
			text-align: center;
			text-transform: none;
		}

		.MuiTabs-flexContainer {
			justify-content: center;
		}
	`}
`;

export default Tabs;
