import { TitleFull as Title } from '@warehouse/title/core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LocalizedInfo } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';
import { fromBasic, getTitleData } from '../../../../../utils/titleGetProperty';
import { dateToString, stringToDate } from '../../../../../components/library/DatePicker/DatePicker';

export interface UseLocalizedInfosIntervalsReturn {
	options: { value: string; label: string }[];
	intervals: Interval[];
	setIntervals: Dispatch<SetStateAction<Interval[]>>;
}

export interface Interval {
	startDate: string;
	description: string;
	endDate: string;
}

function useLocalizedInfosIntervals(title: Title | undefined): UseLocalizedInfosIntervalsReturn {
	const [intervals, setIntervals] = useState<Interval[]>([]);

	useEffect(() => {
		if (title) {
			const localizedInfos = getTitleData<Record<string, LocalizedInfo>>(title, fromBasic(['localizedInfos']));

			const allIntervals = Object.keys(localizedInfos || {})?.map((key) =>
				Object.keys(localizedInfos?.[key]?.targetAudiences || {})?.map((taKey): Interval => {
					const ta = localizedInfos?.[key]?.targetAudiences?.[taKey];
					const when = ta?.whens?.displayValue?.[0];

					return {
						startDate: when?.startDate || '',
						endDate: when?.endDate || '',
						description: ta?.description?.displayValue || '',
					};
				}),
			);

			const allIntervalsFlatten = allIntervals?.reduce((prev, curr) => [...prev, ...curr]);

			const uniqueIntervalsSet = new Set(allIntervalsFlatten.map((interval) => JSON.stringify(interval)));
			const uniqueIntervals: Interval[] = Array.from(uniqueIntervalsSet).map((intervalStr) => JSON.parse(intervalStr));

			setIntervals(uniqueIntervals);
		}
	}, [title]);

	return {
		options: intervals?.map((interval) => ({
			label: `${interval.description} (${dateToString(stringToDate(interval.startDate))} to ${dateToString(
				stringToDate(interval.endDate),
			)})`,
			value: JSON.stringify(interval, Object.keys(interval).sort()),
		})),
		intervals,
		setIntervals,
	};
}

export default useLocalizedInfosIntervals;
