import { Subject, timer } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

export function createCancellableTimer(delay: number = 1000) {
	const timerTrigger = new Subject<void>();
	const cancelTrigger = new Subject<void>();

	const timerObservable = timerTrigger.pipe(switchMap(() => timer(delay).pipe(takeUntil(cancelTrigger))));

	return [timerObservable, timerTrigger, cancelTrigger] as const;
}

/**
 * // Usage:
 * const [timer, start, cancel] = createCancellableTimer(5000);
 *
 * timer.subscribe(() => console.log('activated'));
 *
 * // Start the timer
 * start.next();
 *
 * // Cancel the timer before it fires
 * cancel.next();
 *
 * // Start the timer again
 * start.next();
 */
