import { apolloAdapter } from '@warehouse/shared/infra';
import { map, Observable } from 'rxjs';
import { Order } from '@warehouse/graphql';
import { singletonFactory } from '@warehouse/shared/util';
import { SEARCH_RELATIONSHIPS } from '../../../src/graphql/queries/relationships';
import { RelationshipRepository, WatchSearchOptions } from '../core/relationship.repository';
import {
	computeAPISort,
	computeWildcardSearch,
	formatFilters,
} from '../../../src/utils/hooks/titles/useSearchTitlesV2';
import { SearchRelationshipsOutput } from '../core/relationship';

export class GqlRelationshipRepository implements RelationshipRepository {
	private _apolloAdapter = apolloAdapter;

	watchSearch(options: WatchSearchOptions): Observable<SearchRelationshipsOutput> {
		const apiSort: Order[] | undefined = computeAPISort(options.sorting);
		const apiFilters = formatFilters(options.filtering);
		const wildcardSearch = computeWildcardSearch(options.searchValue, options.searchFields);

		const response$ = this._apolloAdapter.watchObservableQuery<{ searchRelationships: SearchRelationshipsOutput }>({
			query: SEARCH_RELATIONSHIPS,
			variables: {
				search: {
					pagination: { page: options.page, perPage: options.perPage },
					orderBy: apiSort,
					filters: JSON.stringify(apiFilters),
					wildcardSearch,
				},
			},
			pollInterval: options.pollInterval,
		});

		return response$.pipe(map((apolloResponse) => apolloResponse.data.searchRelationships));
	}
}

export const relationshipRepositorySingleton = singletonFactory<RelationshipRepository>({
	factory: () => new GqlRelationshipRepository(),
});
