import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { alpha, Portal } from '@mui/material';

import { SubMessageLayoutWrapper, ReadOnlyValue, DisabledWrapper, Label } from '../library/DropdownUtils';

interface DropdownProps {
	className?: string;
	isSelected?: boolean;
	value: string | null;
	onChange: (value: string | null) => void;
	options: {
		label: string | ReactNode;
		value: string | null;
	}[];
	readOnly?: boolean;
	disabled?: boolean;
	height?: number;
	placement?: 'bottom' | 'top';
	enablePortal?: boolean;
	tooltip?: string;
}

type TriggerWrapperProps = {
	isSelected: boolean;
	active: boolean;
	height?: number;
	placement?: 'bottom' | 'top';
};

type MenuWrapperProps = {
	active: boolean;
	height?: number;
	placement?: 'bottom' | 'top';
	width?: number;
	enablePortal?: boolean;
};

type MenuItemProps = {
	height?: number;
};

type TriggerIconWrapperProps = MenuItemProps;

type WrapperProps = {
	inlineLabel?: boolean;
	disabled?: boolean;
	width?: number;
};

const Wrapper = styled.div<WrapperProps>(
	({ theme, disabled }) => css`
		align-items: center;
		margin: ${theme.spacing(1.75)} 0;
		margin-left: -1px;
		&:focus-within {
			${Label} {
				color: ${theme.palette.blue.main};
			}
		}

		${disabled &&
		css`
			${Label}, ${ReadOnlyValue} {
				color: ${theme.palette.action.disabled};
			}
			cursor: not-allowed;
		`}
	`,
);

const TriggerWrapper = styled.div<TriggerWrapperProps>(
	({ theme, active, height, placement, isSelected }) => css`
		align-items: center;
		background-color: ${theme.palette.light.background};
		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 7px 0 0 7px;
		cursor: pointer;
		display: flex;
		flex-grow: 1;
		font-size: 14px;
		justify-content: space-between;
		min-height: ${height ? `${height}px` : '48px'};
		padding: 0 0 0 ${theme.spacing(1.5)};
		position: relative;
		width: 140px;

		&:hover {
			border-color: ${theme.palette.blue.main};
		}

		:focus-visible {
			outline: none;
		}

		.caretIcon {
			font-size: 16px;
			transition: transform 0.2s ease-in-out;
		}

		${isSelected &&
		css`
			border: solid 1px ${theme.palette.blue.main};
		`}

		${active &&
		css`
			.caretIcon {
				transform: rotate(180deg);
			}
			background-color: ${theme.palette.light.main};
			border: 1px solid ${theme.palette.blue.main};
			${placement === 'top'
				? css`
						border-top: 1px solid transparent !important;
						border-top-left-radius: 0 !important;
						border-top-right-radius: 0 !important;
				  `
				: css`
						border-bottom: 1px solid transparent !important;
						border-bottom-left-radius: 0 !important;
						border-bottom-right-radius: 0 !important;
				  `}
			filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.1));
			z-index: 99;
		`};
	`,
);

const MenuWrapper = styled.div<MenuWrapperProps>(
	({ theme, active, height, placement, enablePortal, width }) => css`
		background-color: ${theme.palette.light.main};
		height: auto;
		max-height: 0;
		position: absolute;
		overflow: hidden;

		${placement === 'top'
			? css`
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
					bottom: ${height ? `${height - 1}px` : '47px'};
			  `
			: css`
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
			  `}

		transition: all 0.2s ease-in-out, border 1ms, margin-top 1ms;
		width: ${width ? `${width}px` : '100%'};

		// Portal
		${enablePortal &&
		css`
			z-index: 1800;
		`};

		// Active
		${active &&
		css`
			border: 1px solid ${theme.palette.blue.main};
			max-height: 280px;
			${placement === 'top'
				? css`
						border-bottom: 1px solid ${theme.palette.action.divider};
				  `
				: css`
						border-top: 1px solid ${theme.palette.action.divider};
						margin-top: -1px;
				  `}
		`};
	`,
);

const MenuList = styled.ul(
	() => css`
		margin: 0;
		max-height: 184px;
		overflow: scroll;
		padding: 0;
	`,
);

const MenuItem = styled.li<MenuItemProps>(
	({ theme, height }) => css`
		align-items: center;
		box-sizing: border-box;
		color: ${theme.palette.text.primary};
		display: flex;
		font-size: 14px;
		list-style: none;
		min-height: ${height ? `${height}px` : '48px'};
		padding: ${theme.spacing(1)} ${theme.spacing(2)};

		&:hover {
			background-color: ${alpha(theme.palette.blue.main, 0.3)};
		}
	`,
);

const TriggerIconWrapper = styled.div<TriggerIconWrapperProps>(
	({ theme, height }) => css`
		align-items: center;
		border-left: 1px solid ${theme.palette.light.backgroundAlt};
		display: flex;
		height: ${height}px;
		justify-content: center;
		margin-left: ${theme.spacing(1)};
	`,
);

const TriggerIcons = styled.div(
	({ theme }) => css`
		align-items: center;
		color: ${theme.palette.text.secondary};
		display: flex;
		justify-content: center;
		width: 32px;
	`,
);

const SelectedItemText = styled.span<{ active: boolean }>(
	({ theme, active }) => css`
		color: ${theme.palette.text.secondary};
		font-size: 12px;

		${active &&
		css`
			color: ${theme.palette.text.primary};
			font-weight: bold;
		`};
	`,
);

function Dropdown(props: DropdownProps) {
	const {
		options,
		value,
		onChange,
		readOnly,
		disabled,
		className,
		height,
		placement,
		enablePortal,
		isSelected = false,
	} = props;
	const [active, setActive] = useState(false);
	const [displayValue, setDisplayValue] = useState<string | ReactNode>('');
	const menuEl = useRef<HTMLUListElement>(null);
	const menuWrapperEl = useRef<HTMLDivElement>(null);

	/* ReferenceElement is the element we want to attach the telement that we pop
         up
    */
	const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>();
	// PopperElement is for the element that we want to pop up
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
	/* Popper hook : need to know about state that is why we use useState and not
         ref
         placement : positionning of the popper element compared to the reference
    */
	const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'flip',
				options: {
					// disable flip when the reference element is at the edge of the viewport
					fallbackPlacements: [],
				},
			},
		],
	});

	useEffect(() => {
		const option = options.find((item) => item.value === value);
		if (option) {
			setDisplayValue(option.label);
		} else setDisplayValue('');
	}, [value]);

	/* Need this useEffect to update the popper position when we add new dropdown
	 */
	useEffect(() => {
		if (update) update();
	}, [active]);

	const handleSelect = (selected: string | null) => {
		/*
            WorkAround to avoid going through each list item in the focus tabIndex after select
            To avoid this we force focus the last element in the menu
        */
		if (menuEl.current) {
			const lastChild = menuEl.current.children[menuEl.current.children.length - 1] as HTMLElement;
			if (lastChild) lastChild.focus();
		}
		// Resetting scroll to the beginning of the list
		if (menuWrapperEl.current) menuWrapperEl.current.scroll(0, 0);

		onChange(selected);
		setActive(false);
	};

	return (
		<Wrapper disabled={disabled} className={className}>
			{readOnly && <ReadOnlyValue $height={height ?? 46}>{displayValue || ''}</ReadOnlyValue>}
			{disabled && <DisabledWrapper>{displayValue}</DisabledWrapper>}
			{!readOnly && !disabled && (
				<SubMessageLayoutWrapper>
					<TriggerWrapper
						isSelected={isSelected}
						ref={setReferenceElement}
						className={active ? 'input-wrapper-active' : 'input-wrapper'}
						active={active}
						tabIndex={0}
						onFocus={() => {
							setActive(true);
						}}
						onBlur={() => {
							setActive(false);
						}}
						height={height}
						placement={placement}
						data-no-dnd
					>
						<SelectedItemText active={active}>{displayValue || ''}</SelectedItemText>
						<TriggerIconWrapper height={height}>
							<TriggerIcons>
								<DownIcon className="caretIcon" />
							</TriggerIcons>
						</TriggerIconWrapper>
						{enablePortal ? (
							<Portal container={document.body}>
								<MenuWrapper
									ref={setPopperElement}
									enablePortal={enablePortal}
									active={active}
									height={height}
									width={referenceElement?.clientWidth || 1}
									placement={placement}
									style={styles.popper}
									{...attributes.popper}
								>
									<MenuList ref={menuEl}>
										{options.map((option) => (
											<MenuItem
												tabIndex={0}
												key={option.value}
												onKeyDown={(e) => {
													if (e.code === 'Space' || e.code === 'Enter') handleSelect(option.value);
												}}
												onClick={() => handleSelect(option.value)}
												height={height}
											>
												{option.label}
											</MenuItem>
										))}
									</MenuList>
								</MenuWrapper>
							</Portal>
						) : (
							<MenuWrapper active={active} ref={menuWrapperEl} height={height} placement={placement}>
								<MenuList ref={menuEl}>
									{options.map((option) => (
										<MenuItem
											tabIndex={0}
											key={option.value}
											onKeyDown={(e) => {
												if (e.code === 'Space' || e.code === 'Enter') handleSelect(option.value);
											}}
											onClick={() => handleSelect(option.value)}
											height={height}
										>
											{option.label}
										</MenuItem>
									))}
								</MenuList>
							</MenuWrapper>
						)}
					</TriggerWrapper>
				</SubMessageLayoutWrapper>
			)}
		</Wrapper>
	);
}

export default Dropdown;
