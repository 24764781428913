import React from 'react';
import { CommonReadonly } from '@warehouse/title/core';
import { TitleSearchDisplayNameFullHTML } from '@warehouse/graphql';
import ChipDisplay from '../../../../src/components/library/ChipDisplay';
import { CustomColumnDef } from '../../../../src/components/library/SimpleTable/customColumnDef';

export function EditUseColumn<T extends CommonReadonly>(): CustomColumnDef<T, string> {
	return {
		header: 'Edit Use',
		accessorFn: (row) => row.titleSearchDisplayNameFullHTML.editUse || '',
		renderReadOnly: (value) => value,
		editable: false,
		id: 'editUse',
	};
}

export function EditClassesColumn<T extends CommonReadonly>(): CustomColumnDef<T, string[]> {
	return {
		header: 'Edit Classes ',
		accessorFn: (row) => row.titleSearchDisplayNameFullHTML.editClasses || [],
		editable: false,
		width: 200,
		id: 'editClasses',
		renderReadOnly: (values) => (
			<div style={{ display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
				<ChipDisplay allowOverflow width={180} variant="default" horizontalPadding={0} values={values} height={26} />
			</div>
		),
	};
}

export function MadeForRegionsColumn<T extends CommonReadonly>(): CustomColumnDef<
	T,
	TitleSearchDisplayNameFullHTML | undefined
> {
	return {
		header: 'Made For Regions',
		accessorFn: (row) => row.titleSearchDisplayNameFullHTML,
		id: 'madeForRegions',
		editable: false,
		width: 200,
		renderReadOnly: (titleSearchDisplayNameFullHTML) => {
			const regionsToDisplay = titleSearchDisplayNameFullHTML?.madeForRegions
				? titleSearchDisplayNameFullHTML?.madeForRegions
				: titleSearchDisplayNameFullHTML?.madeForRegionsExclude;

			return (
				<div style={{ display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
					{titleSearchDisplayNameFullHTML?.madeForRegionsExclude ? 'Excl. ' : ''}
					<div style={{ marginLeft: titleSearchDisplayNameFullHTML?.madeForRegionsExclude ? '8px' : undefined }}>
						<ChipDisplay
							allowOverflow
							variant="default"
							horizontalPadding={0}
							width={170}
							values={regionsToDisplay || []}
							height={26}
						/>
					</div>
				</div>
			);
		},
	};
}
