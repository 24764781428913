import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { DialogContent } from '@mui/material';
import {
	mdiFilterVariant,
	mdiPlus,
	mdiClose,
	mdiArrowRightThin,
	mdiCloseCircle,
	mdiCheckboxMarkedCircle,
	mdiDotsVertical,
} from '@mdi/js';
import Icon from '@mdi/react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { SortingState } from '@tanstack/react-table';
import Droppable from '../../utils/dnd/StrictModeDroppable';

import { FilterSortModalItem } from '../../types/table';
import Dialog from '../library/Dialog';
import DialogTitle, { CloseButton } from '../library/DialogTitle';
import DialogActions from '../library/DialogActions';
import Dropdown from '../library/Dropdown/Dropdown';
import InputsGroup from '../library/InputsGroup';
import Button from '../library/Button';
import InlineIcon from '../library/InlineIcon';
import MergeIcons from '../library/MergeIcons';

type SortModalProps = {
	open: boolean;
	handleClose: (value: SortingState) => void;
	items: FilterSortModalItem[];
	values: SortingState;
	onSubmit: (value: SortingState) => void;
};

const ContentWrapper = styled.div(
	() => css`
		max-height: 50vh;
		overflow-y: scroll;
	`,
);

const RowWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		display: flex;
		padding: ${theme.spacing(2)};

		> div {
			flex-grow: 1;
		}

		> button.delete-button {
			height: 48px;
			margin: 0 ${theme.spacing(2)};
			width: 48px;
		}
	`,
);

const AddingRowWrapper = styled.div(
	({ theme }) => css`
		padding: ${theme.spacing(2)};
		padding-top: 0;
	`,
);

export default function SortModal(props: SortModalProps) {
	const { items, values, open, handleClose, onSubmit } = props;
	const [localValues, setLocalValues] = useState<SortingState>(values);

	const [firstValue, setFirstValue] = useState<string | null>(null);
	const [isAdding, setIsAdding] = useState<boolean>(false);

	const handleSubmit = () => {
		onSubmit(localValues);
		setLocalValues(values);
	};

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const res = Array.from(localValues);
		const [removed] = res.splice(result.source.index, 1);
		res.splice(result.destination.index, 0, removed);
		setLocalValues(res);
	};

	const columnOptions = useMemo(() => (
		items.map((item) => ({
			label: item.label || item.id,
			value: item.id,
		}))
	), [items]);

	return (
		<Dialog open={open} onClose={() => handleClose(localValues)} maxWidth="md" fullWidth>
			<DialogTitle>
				<Icon path={mdiFilterVariant} size="24px" />
				Sort by
				<CloseButton onClose={() => handleClose(localValues)} />
			</DialogTitle>
			<DialogContent>
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="droppable">
						{(droppableProvided) => (
							<ContentWrapper ref={droppableProvided.innerRef}>
								{localValues.map((value, index) => (
									<Draggable key={`${value.id}-${index}`} draggableId={`${value.id}-${index}`} index={index}>
										{(draggableProvided) => (
											<RowWrapper ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
												<InputsGroup>
													<Dropdown
														withSearch
														className="first"
														value={value.id}
														onChange={(v) => {
															if (v) {
																const tmp = structuredClone(localValues);
																tmp[index].id = v;
																setLocalValues(tmp);
															}
														}}
														options={columnOptions}
														enablePortal
													/>
													<Dropdown
														className="last"
														value={value.desc ? 'desc' : 'asc'}
														onChange={(v) => {
															if (v) {
																const tmp = structuredClone(localValues);
																tmp[index].desc = v === 'desc';
																setLocalValues(tmp);
															}
														}}
														options={[
															{
																label: (
																	<InlineIcon>
																		A <Icon path={mdiArrowRightThin} size={1} /> Z
																	</InlineIcon>
																),
																value: 'asc',
															},
															{
																label: (
																	<InlineIcon>
																		Z <Icon path={mdiArrowRightThin} size={1} /> A
																	</InlineIcon>
																),
																value: 'desc',
															},
														]}
														enablePortal
													/>
												</InputsGroup>
												<Button
													className="delete-button"
													onClick={() => {
														const tmp = structuredClone(localValues);
														tmp.splice(index, 1);
														setLocalValues(tmp);
													}}
												>
													<Icon path={mdiClose} size="20px" />
												</Button>
												<MergeIcons {...draggableProvided.dragHandleProps}>
													<Icon path={mdiDotsVertical} size="20px" />
													<Icon path={mdiDotsVertical} size="20px" />
												</MergeIcons>
											</RowWrapper>
										)}
									</Draggable>
								))}
								{droppableProvided.placeholder}
							</ContentWrapper>
						)}
					</Droppable>
				</DragDropContext>
				{!localValues.length || isAdding ? (
					<AddingRowWrapper>
						<Dropdown
							withSearch
							label="Pick a field to sort by"
							value={firstValue}
							onChange={(value) => {
								if (value) {
									setLocalValues([...localValues, { id: value, desc: false }]);
									setFirstValue(null);
									setIsAdding(false);
								}
							}}
							options={columnOptions}
						/>
					</AddingRowWrapper>
				) : null}
				{!isAdding && localValues.length ? (
					<RowWrapper>
						<Button
							nxstyle="tertiary-light"
							startIcon={<Icon path={mdiPlus} size={1} />}
							onClick={() => setIsAdding(true)}
						>
							Add another sort
						</Button>
					</RowWrapper>
				) : null}
			</DialogContent>
			<DialogActions>
				<Button
					nxstyle="secondary-black"
					endIcon={<Icon path={mdiCloseCircle} size="16px" />}
					onClick={() => handleClose(localValues)}
				>
					Cancel
				</Button>
				<Button
					nxstyle="primary-blue"
					endIcon={<Icon path={mdiCheckboxMarkedCircle} size="16px" />}
					onClick={() => handleSubmit()}
				>
					Apply
				</Button>
			</DialogActions>
		</Dialog>
	);
}
