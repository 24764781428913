import { useContext, useEffect, useState } from 'react';
import { TitleAutoSaveContext } from '../contexts/TitleAutoSaveContext';

function useControl<T>(key: string, initialValue: T) {
	const [value, setValue] = useState<T>(initialValue);
	const [dirtyValue, setDirtyValue] = useState<T | undefined>(undefined);
	const { update, register, destroy } = useContext(TitleAutoSaveContext);

	useEffect(() => {
		update(key, { dirtyValue, setDirtyValue });
	}, [dirtyValue]);

	useEffect(() => {
		register(key, { dirtyValue, setDirtyValue });

		return () => destroy(key);
	}, []);

	return {
		value,
		setValue,
		setDirtyValue,
		dirtyValue,
	};
}

export default useControl;
