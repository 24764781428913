import React, { useMemo, useState } from 'react';
import { PaginationNavigator, PaginatorProvider, ToggleButtons } from '@warehouse/shared/ui';
import styled, { css } from 'styled-components';
import { TitleFull, TitleLightWithSelectedParent } from '@warehouse/title/core';
import { AddChildrenStoreProvider } from '@warehouse/title/add-children-feature';
import { LoaderWrapper } from '../../../src/components/library/LoaderWrapper';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../src/components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { fromBasic, getTitleData } from '../../../src/utils/titleGetProperty';
import { useStateQuery } from '../../../src/utils/hooks/useStateQuery';
import { SearchProvider } from '../../shared/ui/search/search.store';
import { SearchInput } from '../../shared/ui/search/SearchInput';
import { ChildrenParentMode } from './children-parent-mode';
import { CreateRelationshipsButton } from './CreateRelationshipsButton';
import { useRelationshipColumns } from './useRelationshipColumns';
import { AddRelationshipChildren } from '../add-relationship-children-feature';
import { useSearchRelationshipsParentsOrChildren } from '../domain/useSearchRelationshipsParentsOrChildren';

function RelationshipsListComp({ title, onChildrenClick }: RelationshipsListProps) {
	const [childrenParentMode, setChildrenParentMode] = useStateQuery<ChildrenParentMode>({
		queryName: 'relationshipMode',
		defaultValue: 'children',
	});
	const [openExistingChildrenModal, setOpenExistingChildrenModal] = useState(false);

	const titleStatus = useMemo(
		() => getTitleData<string>(title, fromBasic(['terms', 'titleStatus', 'displayValue'])),
		[title],
	);

	const columns = useRelationshipColumns(childrenParentMode);
	const { data, loading } = useSearchRelationshipsParentsOrChildren({
		titleUuid: title.uuid,
		workType: title.readonly.workType,
		mode: childrenParentMode,
		showHiddenTitles: titleStatus === 'hidden',
	});

	if (!title.readonly) {
		return null;
	}

	return (
		<Wrapper>
			<ToggleButtonRow>
				<ToggleButtons<ChildrenParentMode>
					value={childrenParentMode}
					onChange={(value) => setChildrenParentMode(value)}
					options={[
						{ value: 'parents', label: 'Parents' },
						{ value: 'children', label: 'Children' },
					]}
				/>
			</ToggleButtonRow>
			<TopRow>
				<SearchInput label={childrenParentMode} />
				<CreateRelationshipsButton
					workType={title.readonly.workType}
					childrenParentMode={childrenParentMode}
					openExistingChildrenModal={() => setOpenExistingChildrenModal(true)}
					parentUuid={title.uuid}
				/>
			</TopRow>
			<LoaderWrapper loading={loading} opacity={0.3} style={{ flex: 1 }}>
				<InheritanceOnValueChangeSimpleTableWrapper<TitleLightWithSelectedParent>
					ariaLabel={`${childrenParentMode} relationships`}
					paddedCells
					columns={columns}
					editMode={false}
					data={data}
					tableStyle="border"
					forceHover
					onClickRow={(row: TitleLightWithSelectedParent) => onChildrenClick?.(childrenParentMode, row)}
				/>
			</LoaderWrapper>
			{openExistingChildrenModal && (
				<AddChildrenStoreProvider>
					<AddRelationshipChildren
						open={openExistingChildrenModal}
						onClose={() => setOpenExistingChildrenModal(false)}
						title={title.readonly}
					/>
				</AddChildrenStoreProvider>
			)}
			<PaginationNavigator centered storageUniqueKey="relationship-list" />
		</Wrapper>
	);
}

export function RelationshipsList({ ...props }: RelationshipsListProps) {
	return (
		<SearchProvider>
			<PaginatorProvider>
				<RelationshipsListComp {...props} />
			</PaginatorProvider>
		</SearchProvider>
	);
}

interface RelationshipsListProps {
	title: TitleFull;
	onChildrenClick?: (mode: ChildrenParentMode, child: TitleLightWithSelectedParent) => void;
}

const Wrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 16px;
		height: calc(100% - 32px);
		padding: ${theme.spacing(2)};
	`,
);

const TopRow = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(2)};
		width: 100%;
	`,
);

const ToggleButtonRow = styled.div(
	() => css`
		display: flex;
		justify-content: center;
		margin-top: 16px;
	`,
);
