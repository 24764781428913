// import { isValidUUID } from './uuidUtils';

export default function recordToArray<T>(input: Record<string, T> | undefined): WithUuid<T>[] {
	if (!input) return [];

	const keys = Object.keys(input || {});

	return keys.map((key) => ({
		...input[key],
		__uuid: key,
	}));
}

// function isARecord(obj: any) {
// 	if (!obj || typeof obj !== 'object' || Array.isArray(obj)) return false;
// 	const keys = Object.keys(obj);
// 	if (keys.length === 0) return false;
// 	console.log(obj);
// 	return keys.every((key) => isValidUUID(key));
// }

export function nestedRecordToArray<T>(record: Record<string, T> | undefined): WithUuid<T>[] {
	if (!record) return [];

	const keys = Object.keys(record || {});

	return keys.map((key) => ({
		...record[key],
		__uuid: key,
	}));
}

export type WithUuid<T> = T & { __uuid: string };

// const a = {
// 	'eda8b901-d371-42ff-80cb-9f2cbd5f5f78': {
// 		name: 'Test 1',
// 		lastname: 'Test 2',
// 		otherField: {
// 			label: 'Test 3',
// 			value: 'Test 4',
// 		},
// 		territories: [
// 			{
// 				__uuid: 'd6d8a0ad-7c93-4333-887a-5d3dbf01d73a',
// 				value: 'en',
// 			},
// 			// '2bd05831-8d43-42d9-9326-e8705b15c9cb': 'fr',
// 		],
// 		// territories: {
// 		// 	'd6d8a0ad-7c93-4333-887a-5d3dbf01d73a': 'en',
// 		// 	'2bd05831-8d43-42d9-9326-e8705b15c9cb': 'fr',
// 		// },
// 	},
// 	'de8cbc65-1143-4aa5-9a0b-476f96f548b2': {
// 		name: 'Test 5',
// 		lastname: 'Test 6',
// 		otherField: {
// 			label: 'Test 7',
// 			value: 'Test 8',
// 		},
// 		territories: {
// 			'8548c771-4315-4995-896f-4c75407831df': 'en',
// 			'e62daeb5-8055-47a2-abd1-64df9415e02a': 'fr',
// 		},
// 	},
// };
//
// console.log('\n\n', nestedRecordToArray(a));
