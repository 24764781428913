import EntryClassJson from '../../../src/assets/json-administration-profiles/entryClass.json';

export interface EntryClass {
	uuid: string;
	label: string;
}

export function getEntryClass(uuid: string): EntryClass {
	const entryClass = EntryClassJson.find(({ value }) => value === uuid);
	if (!entryClass) throw new Error(`Entry class ${uuid} not found`);
	return {
		uuid: entryClass.value,
		label: entryClass.label,
	};
}

export function getEntryClasses(): EntryClass[] {
	return EntryClassJson.map(({ value, label }) => ({
		uuid: value,
		label,
	}));
}
