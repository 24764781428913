import { useEffect } from 'react';

function useOnTabFocus(onFocus: EventListenerOrEventListenerObject) {
	useEffect(() => {
		window.addEventListener('focus', onFocus);

		return () => {
			window.removeEventListener('focus', onFocus);
		};
	});
}

export default useOnTabFocus;
