import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HeaderGroup } from '@tanstack/react-table';
import { StyledTH, SubHeaderTR } from '../style';
import { CustomColumnDef } from '../customColumnDef';
import SubHeaderDefaultDropdown from './SubHeaderDefaultDropdown';
import SubHeaderSearchDropdown from './SubHeaderSearchDropdown';
import SubHeaderMultiSelectDropdown from './SubHeaderMultiSelectDropdown';
import SubHeaderDatePicker from './SubHeaderDatePicker';
import SubHeaderTimePicker from './SubHeaderTimePicker';
import SubHeaderNumber from './SubHeaderNumber';
import SubHeaderString from './SubHeaderString';

export type SubHeaderProps = {
	headerGroup: HeaderGroup<any>;
	subheaderValues: Record<string, any>;
	handleSubheaderChange: (columnId: string, value: any) => void;
};

export function SubHeader({ headerGroup, subheaderValues, handleSubheaderChange }: SubHeaderProps) {
	const [multiSelectValuesRecord, setMultiSelectValuesRecord] = useState<{ [columnId: string]: string[] }>({});

	const [anchorRef, setAnchorRef] = useState<HTMLButtonElement | null>(null);

	const popperRef = useRef<HTMLDivElement>(null);

	const [openDropdownButton, setOpenDropdownButton] = useState<{
		[columnId: string]: boolean;
	}>({});

	// Register click outside event listener
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
				setOpenDropdownButton({});
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [popperRef]);

	const handleDropdownButton = useCallback(
		(columnId: string, anchorEl?: HTMLButtonElement) => {
			setOpenDropdownButton((prevState) => ({
				...prevState,
				[columnId]: !prevState[columnId],
			}));
			if (anchorEl) {
				setAnchorRef(anchorEl);
			}
		},
		[setAnchorRef, setOpenDropdownButton],
	);

	const removeOrAddBaseOnInclusion = (array: string[], newValue: string) => {
		if (array.includes(newValue)) {
			return array.filter((item) => item !== newValue);
		}
		return [...array, newValue];
	};

	const handleSubHeaderMultiSelectDropdownEvent = (columnId: string, value: string) => {
		const columnValues = multiSelectValuesRecord[columnId] || [];

		// The same event is fired when a value is selected and deselected.
		// When the values already include value (which is therefore selected), we remove it from the selection
		// When the values do not include value (which is therefore deselected), we add it to the selection
		const newColumnValues = removeOrAddBaseOnInclusion(columnValues, value);

		setMultiSelectValuesRecord({
			...multiSelectValuesRecord,
			[columnId]: newColumnValues,
		});

		handleSubheaderChange(columnId, newColumnValues);
	};

	return (
		<SubHeaderTR>
			{headerGroup.headers.map((header, index) => {
				const headerColumnDef = header.column.columnDef as CustomColumnDef<any>;

				if (headerColumnDef.customSubHeader) {
					const CustomSubHeader = headerColumnDef.customSubHeader;
					return (
						<CustomSubHeader
							key={`custom-${index}`}
							handleDropdownButton={handleDropdownButton}
							openDropdownButton={openDropdownButton}
							header={header}
							popperRef={popperRef}
							anchorRef={anchorRef}
							handleSubHeaderDropdownChange={handleSubheaderChange}
							selectedDropdownValues={subheaderValues}
						/>
					);
				}
				if (headerColumnDef.subHeaderDropdownType === 'default') {
					return (
						<SubHeaderDefaultDropdown
							key={`dropdown-${index}`}
							handleDropdownButton={handleDropdownButton}
							openDropdownButton={openDropdownButton}
							header={header}
							popperRef={popperRef}
							anchorRef={anchorRef}
							handleSubHeaderDropdownChange={handleSubheaderChange}
							selectedDropdownValues={subheaderValues}
						/>
					);
				}
				if (headerColumnDef.subHeaderDropdownType === 'searchDropdown') {
					return (
						<SubHeaderSearchDropdown
							key={`dropdown-${index}`}
							handleDropdownButton={handleDropdownButton}
							openDropdownButton={openDropdownButton}
							header={header}
							popperRef={popperRef}
							anchorRef={anchorRef}
							handleSubHeaderDropdownChange={handleSubheaderChange}
							selectedDropdownValues={subheaderValues}
							dropdownProps={headerColumnDef.subHeaderDropdownProps}
						/>
					);
				}
				if (headerColumnDef.subHeaderDropdownType === 'multiSelect') {
					return (
						<SubHeaderMultiSelectDropdown
							key={`dropdown-${index}`}
							handleDropdownButton={handleDropdownButton}
							openDropdownButton={openDropdownButton}
							header={header}
							values={multiSelectValuesRecord}
							popperRef={popperRef}
							anchorRef={anchorRef}
							handleSubHeaderDropdownSelect={handleSubHeaderMultiSelectDropdownEvent}
						/>
					);
				}
				if (headerColumnDef.subHeaderDropdownType === 'date') {
					return (
						<SubHeaderDatePicker
							key={`dropdown-${index}`}
							startDate={subheaderValues[header.column.columnDef.id as string] || undefined}
							header={header}
							handleSubHeaderDropdownChange={handleSubheaderChange}
						/>
					);
				}
				if (headerColumnDef.subHeaderDropdownType === 'time') {
					return (
						<SubHeaderTimePicker
							key={`dropdown-${index}`}
							handleDropdownButton={handleDropdownButton}
							openDropdownButton={openDropdownButton}
							header={header}
							value={subheaderValues[header.column.columnDef.id as string] || 0}
							popperRef={popperRef}
							anchorRef={anchorRef}
							handleSubHeaderDropdownChange={handleSubheaderChange}
						/>
					);
				}
				if (headerColumnDef.subHeaderDropdownType === 'number') {
					return (
						<SubHeaderNumber
							key={`dropdown-${index}`}
							header={header}
							handleSubHeaderDropdownChange={handleSubheaderChange}
							value={subheaderValues[header.column.columnDef.id as string] || ''}
						/>
					);
				}
				if (headerColumnDef.subHeaderDropdownType === 'string') {
					return (
						<SubHeaderString
							key={`dropdown-${index}`}
							header={header}
							handleSubleheaderValueChange={handleSubheaderChange}
							value={subheaderValues[header.column.columnDef.id as string] || ''}
							{...headerColumnDef?.subHeaderProps}
						/>
					);
				}
				return <StyledTH key={index} />;
			})}
		</SubHeaderTR>
	);
}
