import { Maybe, TitleSearchDisplayNameFullHTML } from '../../graphql/codegen/graphql';

interface TitleSearchDisplayName {
	title: string;
	subTitle1?: string;
	subTitle2?: string;
}

export default function titleSearchDisplayNameBuilder({
	tokens,
	titleSort = false,
}: {
	tokens?: TitleSearchDisplayNameFullHTML;
	titleSort?: boolean;
}): TitleSearchDisplayName {
	if (!tokens) {
		return { title: '' };
	}
	const title = titleSort ? tokens.titleSortFrontEnd || tokens.titleFrontEnd! : tokens.titleFrontEnd!;
	switch (tokens.workType) {
		case 'Season': {
			const seasonPresentInName = stringContainsSeason(title);
			const titlePre = `Season ${tokens.sequenceNumber}${seasonPresentInName ? '' : ` - ${title}`}`;
			return {
				title: prefixWithParentTitleSearchDisplayName(tokens, titlePre, columnSeparator),
				subTitle1: mergeValues(
					columnSeparator,
					tokens.madeForRegions?.join(', '),
					tokens.madeForRegionsExclude ? `Excl. ${tokens.madeForRegionsExclude.join(', ')}` : undefined,
				),
			};
		}
		case 'Episode': {
			const episodePresentInName = stringContainsEpisode(title);
			const titlePre = `Ep ${tokens.sequenceNumber}${episodePresentInName ? '' : ` - ${title}`}`;
			return { title: prefixWithParentTitleSearchDisplayName(tokens, titlePre, columnSeparator) };
		}
		case 'Compilation':
		case 'Supplemental': {
			if (tokens.workTypeDetails?.length) {
				return {
					title: prefixWithParentTitleSearchDisplayName(
						tokens,
						mergeValues(columnSeparator, tokens.workTypeDetails[0], title),
						columnSeparator,
					),
				};
			}
			return { title: tokens.parentTitleSearchDisplayNameFullForChild ?? '' };
		}
		case 'Edit': {
			return {
				title: mergeValues(
					dashSeparator,
					tokens.parentTitleSearchDisplayNameFullForChild,
					stringContainsEpisode(title) ? undefined : title,
				),
				subTitle1: mergeValues(
					columnSeparator,
					tokens.editUse,
					tokens.editClasses?.join(', '),
					tokens.madeForRegions?.join(', '),
					tokens.madeForRegionsExclude ? `Excl. ${tokens.madeForRegionsExclude.join(', ')}` : undefined,
					tokens.versionLanguages?.join(', '),
				),
			};
		}
		case 'Manifestation': {
			return {
				title: prefixWithParentTitleSearchDisplayName(
					tokens,
					stringContainsEpisode(title) ? undefined : title,
					dashSeparator,
				),
				subTitle1: mergeValues(
					columnSeparator,
					tokens.editUse,
					tokens.editClasses?.join(', '),
					tokens.madeForRegions?.join(', '),
					tokens.madeForRegionsExclude ? `Excl. ${tokens.madeForRegionsExclude.join(', ')}` : undefined,
					tokens.versionLanguages?.join(', '),
				),
				subTitle2: tokens.identification ?? '',
			};
		}
		default:
			return { title: prefixWithParentTitleSearchDisplayName(tokens, title, columnSeparator) };
	}
}

const columnSeparator = ': ';
const dashSeparator = ' - ';

const stringContainsEpisode = (str?: Maybe<string>) => !!str?.match(/\b(?:E|EP|EPS|EPISODE)\s*#?:?\s*(\d+)/gim);

const stringContainsSeason = (str?: Maybe<string>) => !!str?.match(/\b(?:SEASON|S)\s*#?:?\s*(\d+)/gim);

function prefixWithParentTitleSearchDisplayName(
	tokens: TitleSearchDisplayNameFullHTML,
	suffix: Maybe<string> | undefined,
	separator: string,
): string {
	if (
		(tokens.parentTitleSearchDisplayNameFullForChild === undefined ||
			tokens.parentTitleSearchDisplayNameFullForChild === null) &&
		(suffix === undefined || suffix === null)
	) {
		return '';
	}
	if (tokens.parentTitleSearchDisplayNameFullForChild) {
		return `${tokens.parentTitleSearchDisplayNameFullForChild}${separator}${suffix}`;
	}
	return suffix ?? '';
}

function mergeValues(separator: string, ...values: (undefined | Maybe<string>)[]): string {
	return values.filter((token): token is string => token !== undefined && token !== null).join(separator);
}
