import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { InventoryTrack } from '../../../graphql/codegen/graphql';
import { GET_TRACK_DETAILS } from '../../../graphql/queries/tracks';

function useGetTrackDetails({ uuid, pollInterval }: { uuid: string; pollInterval?: number }) {
	const { loading, error, data, startPolling } = useQuery<{
		getTrack: InventoryTrack;
	}>(GET_TRACK_DETAILS, {
		variables: { uuid },
	});

	// Query polling
	useEffect(() => {
		if (pollInterval) startPolling(pollInterval);
	}, [startPolling, pollInterval]);

	return { loading, error, data: data?.getTrack };
}

export default useGetTrackDetails;
