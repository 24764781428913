import React from 'react';

export function replaceUUID(str: string) {
	// UUID pattern: 8-4-4-4-12 hexadecimal characters
	const uuidPattern = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
	const matches = str.match(uuidPattern);
	if (!matches) return str;
	return (
		<span>
			{str.replace(uuidPattern, '').trim()}{' '}
			<a href={`${window.location.origin}/title/${matches[0]}`} target="_blank" rel="noreferrer">
				{matches[0]}
			</a>
		</span>
	);
}
