import styled from 'styled-components';

const Overlay = styled.div`
	background-color: rgba(0, 0, 0, 0.2);
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 90;
`;

export default Overlay;
