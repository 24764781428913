import React from 'react';
import {
	EditPreventerPopupStoreProvider,
	editPreventerPopupStoreSelector,
	useEditPreventerPopupStore,
} from './EditPreventerStore';
import { EditPreventerPopup } from './EditPreventerStorePopup';

export function EditPreventer({ children }: { children: React.ReactNode }) {
	return (
		<EditPreventerPopupStoreProvider>
			<EditPreventerPopup />
			{children}
		</EditPreventerPopupStoreProvider>
	);
}

export function useEditPreventer() {
	const { act } = useEditPreventerPopupStore(editPreventerPopupStoreSelector.actions);

	return {
		act,
	};
}
