export class ChildNotFoundError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'ChildNotFoundError';
	}
}

export class SelectedParentNotFoundError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'SelectedParentNotFoundError';
	}
}
