import { createContext } from 'react';
import { WorkType } from '@warehouse/graphql';
import { RelationshipType } from './relationship-types';

export interface TitleAddChildrenMutationOptions {
	onCompleted?: () => void;
}

export interface ChildToAdd {
	uuid: string;
	workType?: WorkType;
}

export interface TitleAddChildrenMutation {
	(args: { options?: TitleAddChildrenMutationOptions }): {
		loading: boolean;
		error: unknown;
		mutate: (args: {
			titleUuid: string;
			children: ChildToAdd[];
			workTypeUuid: string;
			relationshipType?: string;
		}) => void;
	};
}

export const TitleAddChildrenMutationContext = createContext<TitleAddChildrenMutation | null>(null);
