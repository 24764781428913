import { ReactElement, ReactNode, useMemo } from 'react';

function DomOrderer({ children }: { children: ReactNode }) {
	const nodes = useMemo(() => {
		if (Array.isArray(children)) {
			const flattenNodes = children.reduce<ReactElement[]>((acc, child) => {
				if (child?.props?.children && Array.isArray(child.props.children)) return [...acc, ...child.props.children];
				return [...acc, child];
			}, []);
			flattenNodes.sort((a, b) => {
				if (a.props?.['data-order'] && b.props?.['data-order']) {
					return a.props['data-order'] - b.props['data-order'];
				}
				if (a.props?.['data-order'] && !b.props?.['data-order']) {
					return -1;
				}
				if (!a.props?.['data-order'] && b.props?.['data-order']) {
					return 1;
				}
				return 0;
			});
			return flattenNodes;
		}
		return children;
	}, [children]);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment,react/react-in-jsx-scope
		<>{nodes}</>
	);
}

export default DomOrderer;
