import { WorkType } from '@warehouse/title/worktype';
import { workTypeFromLabel } from '@warehouse/title/infra';
import { CreateTitleV2 } from '@warehouse/graphql';
import { isEpisodeOf, isSeasonOf } from '@warehouse/title/core';
import { isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import { secondsToIBMDurationFormat } from '../../components/library/TimeInput';
import availableWorkTypeDetailsByWorkType from '../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import { getNameByUuid } from '../../utils/hooks/titles/useWorkTypes';
import mapToCountryOrCountryRegion from '../../utils/mapToCountryOrCountryRegion';
import { dateToString, stringToDate } from '../../components/library/DatePicker/DatePicker';

function fillIfValueIsDefined(obj: any, optionalValue: any) {
	if (optionalValue) return obj;
	return {};
}

const valueGetters = {
	releaseDate: (data: any) => (data.releaseDate ? dateToString(new Date(data.releaseDate)) : undefined),
	releaseYear: (data: any) => data.releaseDate?.getFullYear(),
	titleLanguage: (data: any) => data.titleLanguage,
	titleSort: (data: any) => data.sortTitle || undefined,
	sequenceNumber: (data: any) => {
		const parsedInt = parseInt(data.sequenceNumber, 10);
		if (data.sequenceNumber && !Number.isNaN(parsedInt)) {
			return parsedInt;
		}
		return undefined;
	},
	distributionNumber: (data: any) => data.distributionNumber,
	houseSequence: (data: any) => data.houseSequence,
	parentPrimary: (data: any, relationshipType: string) => {
		if ([isEpisodeOf(), isSeasonOf()].includes(relationshipType)) {
			return true;
		}
		return data.parentTitle?.primary;
	},
	parentContentID: (data: any) => data.parentTitle?.title?.uuid || undefined,
	runLength: (data: any) => (data.approximateLength ? secondsToIBMDurationFormat(data.approximateLength) : undefined),
	countriesOfOrigin: (data: any) =>
		!data.countriesOfOrigin?.length
			? undefined
			: mapToCountryOrCountryRegion(data.countriesOfOrigin)?.map((co) => ({
					...co,
					interpretation: 'creative',
			  })),
	originalLanguages: (data: any) =>
		data?.originalLanguages?.length
			? data.originalLanguages.map((item: any, index: number) => ({
					originalLanguage: item.language,
					listingOrder: parseInt(item.listingOrder, 10) || index + 1,
					spoken: item.spoken ?? true,
					written: item.written ?? true,
					signed: item.signed ?? false,
			  }))
			: undefined,
	title: (data: any) => data.title || undefined,
	editUse: (data: any) => data.editUse || undefined,
	editClass: (data: any) => data.editClass || undefined,
	madeForRegions: (data: any) =>
		!data.madeForRegions?.madeForRegions?.length
			? undefined
			: mapToCountryOrCountryRegion(data.madeForRegions.madeForRegions),
	isIncludeMadeForRegions: (data: any) => data.madeForRegions?.isInclude ?? true,
	licensor: (data: any) => data.licensor || undefined,
	workTypeDetail: (data: any) => data.workTypeDetail || undefined,
	versionLanguages: (data: any) => data.versionLanguages || undefined,
	relationshipType: (data: any) => data.relationshipType || undefined,
};

export function getRelationshipTypePerWorkTypeName(_workTypeName: string) {
	if (_workTypeName.toLowerCase() === 'all') return null;
	const workType = workTypeFromLabel(_workTypeName);

	switch (workType) {
		case WorkType.Series:
		case WorkType.Movie:
		case WorkType.Compilation:
			return 'isdescendentof';
		case WorkType.Edit:
			return 'isderivedfrom';
		case WorkType.Manifestation:
			return 'isderivedfrom';
		case WorkType.Supplemental:
			return 'issupplementto';
		case WorkType.Episode:
			return 'isepisodeof';
		case WorkType.Season:
			return 'isseasonof';
		default:
			return null;
	}
}

function mapTitleDataToMutation(
	data: any,
	workType: string,
	forcedRelationshipType?: string,
): CreateTitleV2 | undefined {
	const workTypeName = getNameByUuid(workType);
	const workTypeDetails =
		availableWorkTypeDetailsByWorkType[getNameByUuid(workType) as keyof typeof availableWorkTypeDetailsByWorkType];

	const getWorkTypeDetails = (_workTypeName: string) => {
		switch (_workTypeName) {
			case 'Series':
			case 'Season':
				return [workTypeDetails?.availableWorkTypeDetails[0]?.value];
			default:
				return [valueGetters.workTypeDetail(data)];
		}
	};

	const getTitleDisplayUnlimited = () => {
		if (workTypeName === 'Episode') return valueGetters.title(data) || `Episode ${valueGetters.sequenceNumber(data)}`;
		if (workTypeName === 'Season') return valueGetters.title(data) || `Season ${valueGetters.sequenceNumber(data)}`;
		return valueGetters.title(data);
	};

	const getRelationshipType = () => {
		if (forcedRelationshipType) return forcedRelationshipType;
		return workTypeName === 'Supplemental'
			? valueGetters.relationshipType(data)
			: getRelationshipTypePerWorkTypeName(workTypeName || '');
	};

	const getLocalizedInfos = () => {
		if (workTypeName === 'Edit') {
			if (isFeatureEnabledForCurrentTenant('inheritance') && data.parentTitle?.title?.uuid) return [];
			return [
				{
					default: true,
					language: '8fa1f157-131a-4461-93fa-acf6ed5a18f5',
					titleDisplayUnlimited: data.parentTitle?.title?.titleDisplayUnlimited,
				},
			];
		}
		return [
			{
				default: true,
				language: valueGetters.titleLanguage(data),
				titleSort: valueGetters.titleSort(data),
				titleDisplayUnlimited: getTitleDisplayUnlimited(),
			},
		];
	};

	const relationshipType = getRelationshipType();
	const base: CreateTitleV2 = {
		metadata: {
			coreMetadata: {
				basic: {
					...fillIfValueIsDefined(
						{
							parents: [
								{
									primary: valueGetters.parentPrimary(data, relationshipType),
									parentContentId: valueGetters.parentContentID(data),
									relationshipType,
								},
							],
						},
						valueGetters.parentContentID(data),
					),
					terms: {
						titleStatus: isFeatureEnabledForCurrentTenant('inheritance') ? undefined : 'valid',
					},
					countriesOfOrigin: valueGetters.countriesOfOrigin(data),
					runLength: valueGetters.runLength(data),
					releaseYear:
						valueGetters.releaseYear(data) || stringToDate(valueGetters.releaseDate(data) as string).getFullYear(),
					releaseDate: valueGetters.releaseDate(data),
					workType,
					workTypeDetails: getWorkTypeDetails(workTypeName || ''),
					originalLanguages: workTypeName !== 'Edit' ? valueGetters.originalLanguages(data) : undefined,
					localizedInfos: getLocalizedInfos(),
				},
			},
		},
	};

	if (valueGetters.licensor(data)) {
		base.metadata.coreMetadata.basic.associatedOrgs = [
			{
				displayName: valueGetters.licensor(data),
				role: 'licensor',
			},
		];
	}

	if (workTypeName === 'Season' && valueGetters.madeForRegions(data)) {
		base.metadata.coreMetadata.basic.versionIntent = {
			[getMadeForRegionsKey(valueGetters.isIncludeMadeForRegions(data))]: valueGetters.madeForRegions(data),
		};
	}

	if (workTypeName === 'Edit') {
		base.metadata.coreMetadata.basic.versionIntent = {
			editUse: valueGetters.editUse(data),
			editClasses: valueGetters.editClass(data),
			[getMadeForRegionsKey(valueGetters.isIncludeMadeForRegions(data))]: valueGetters.madeForRegions(data),
		};
		base.metadata.coreMetadata.basic.versionLanguages = valueGetters.versionLanguages(data);
		base.metadata.coreMetadata.basic.countriesOfOrigin = undefined;
		base.metadata.coreMetadata.basic.workTypeDetails = undefined;
		base.metadata.coreMetadata.basic.releaseYear = isFeatureEnabledForCurrentTenant('inheritance') ? undefined : '1993';
	}

	if (workTypeName && ['Episode', 'Season'].includes(workTypeName)) {
		if (base.metadata.coreMetadata.basic.parents) {
			base.metadata.coreMetadata.basic.parents[0].sequenceInfo = {
				number: valueGetters.sequenceNumber(data),
				...(valueGetters.distributionNumber(data)
					? {
							distributionNumber: {
								distributionNumber: valueGetters.distributionNumber(data)?.toString(),
								domain: 'nexspec.com/warehouse',
							},
					  }
					: {}),
				...(valueGetters.houseSequence(data)
					? {
							houseSequence: {
								houseSequence: valueGetters.houseSequence(data)?.toString(),
								domain: 'nexspec.com/warehouse',
							},
					  }
					: {}),
			};
		}

		base.metadata.coreMetadata.basic.sequenceInfo = {
			number: valueGetters.sequenceNumber(data),
			...(valueGetters.distributionNumber(data)
				? {
						distributionNumber: {
							distributionNumber: valueGetters.distributionNumber(data)?.toString(),
							domain: 'nexspec.com/warehouse',
						},
				  }
				: {}),
			...(valueGetters.houseSequence(data)
				? {
						houseSequence: {
							houseSequence: valueGetters.houseSequence(data)?.toString(),
							domain: 'nexspec.com/warehouse',
						},
				  }
				: {}),
		};
	}

	return base;
}

function getMadeForRegionsKey(isInclude?: boolean): 'madeForRegions' | 'madeForRegionsExclude' {
	return isInclude ? 'madeForRegions' : 'madeForRegionsExclude';
}

// eslint-disable-next-line import/no-default-export
export default mapTitleDataToMutation;
