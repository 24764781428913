import React from 'react';
import styled, { css } from 'styled-components';
import { alpha, Tooltip, Typography } from '@mui/material';

interface MultiTagsProps {
	tags: string[];
}

const Container = styled.div(
	() => css`
		align-items: center;
		display: flex;
	`,
);

const Tag = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.light.background};
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: 28px;
		display: flex;
		height: 12px;
		justify-content: center;
		margin: 0 ${theme.spacing(0.25)};
		padding: ${theme.spacing(0.75)} ${theme.spacing(1)};
	`,
);

const TagText = styled((props) => <Typography variant="buttonSmallMedium" {...props} />)(
	({ theme }) => css`
		color: ${alpha(theme.palette.dark.main, 0.6)};
		text-align: center;
		width: 100%;
	`,
);

const TagNumber = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.blue.main};
		border-radius: 28px;
		display: flex;
		height: 16px;
		justify-content: center;
		margin-left: ${theme.spacing(0.5)};
		padding: 0 ${theme.spacing(1)};
	`,
);

const TagNumberText = styled((props) => <Typography {...props} variant="buttonSmallMedium" />)(
	({ theme }) => css`
		color: ${theme.palette.light.main};
	`,
);

function MultiTags({ tags }: MultiTagsProps) {
	if (tags.length === 0) {
		return <Container />;
	}

	return (
		<Container>
			{tags.slice(0, 2).map((tag) => (
				<Tag key={tag}>
					<TagText>{tag}</TagText>
				</Tag>
			))}
			{tags.length > 2 && (
				<Tooltip
					title={
						<div>
							{tags.slice(2).map((tag, index) => (
								<p key={tag + index.toString()}>{tag}</p>
							))}
						</div>
					}
				>
					<TagNumber>
						<TagNumberText>+{tags.length - 2}</TagNumberText>
					</TagNumber>
				</Tooltip>
			)}
		</Container>
	);
}

export default MultiTags;
