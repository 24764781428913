import React from 'react';
import { titlePaths } from '@warehouse/title/core';
import { TitleTextInputField } from '../../ui/fields/TitleTextInputField';

export function AbbreviatedTitleField() {
	return (
		<TitleTextInputField
			forcedEditMode
			style={{ padding: 0, marginBottom: 14 }}
			label="Abbreviated Title"
			path={titlePaths.abbreviatedTitle}
		/>
	);
}
