import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight } from '@mdi/js';
import React from 'react';
import styled, { css } from 'styled-components';
import { Row } from '@tanstack/react-table';
import Button from '../Button';
import { StyledTD } from './style';

interface ExpandableRowProps {
	row: Row<any>;
}

export const StyledTDExpandable = styled(StyledTD)(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		border-top: 1px solid ${theme.palette.light.backgroundAlt};
		height: 100%;
	`,
);

export const StyledButton = styled(Button)<{ depth: number }>(
	({ depth }) => css`
		border-radius: 0;
		display: flex;
		height: 40px;
		justify-content: start;
		margin: 0;
		padding: 0;
		padding-left: ${depth * 20 + 10}px;
		width: 100%;
	`,
);

function ExpandableRow({ row }: ExpandableRowProps) {
	return (
		<StyledTDExpandable colSpan={100} role="cell">
			<StyledButton depth={row.depth} onClick={row.getToggleExpandedHandler()} nxstyle="tertiary-light">
				<Icon path={row.getIsExpanded() ? mdiChevronDown : mdiChevronRight} size="24px" />
				{row.original.title}
			</StyledButton>
		</StyledTDExpandable>
	);
}

export default ExpandableRow;
