import { Row } from '@tanstack/react-table';
import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight } from '@mdi/js';

import { StyledButton, StyledTDExpandable } from '../library/SimpleTable/ExpandableRow';

interface ExpandableRowProps<T = any> {
	row: Row<Maybe<T>>;
}

function ExpandableRow<T = any>({ row }: ExpandableRowProps<T>) {
	return (
		<StyledTDExpandable colSpan={100}>
			<StyledButton depth={row.depth} onClick={row.getToggleExpandedHandler()} nxstyle="tertiary-light">
				<Icon path={row.getIsExpanded() ? mdiChevronDown : mdiChevronRight} size="24px" />
				{(row?.original as any)?.title}
			</StyledButton>
		</StyledTDExpandable>
	);
}

export default ExpandableRow;
