import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { SortingState } from '@tanstack/react-table';
import { Order, SearchInput, SearchTracksOutput } from '../../../graphql/codegen/graphql';
import { SEARCH_TRACKS } from '../../../graphql/queries/tracks';

interface UseSearchTracksProps {
	searchValue: string;
	filtering?: FilterStep;
	sorting?: SortingState;
	pollInterval: number;
	titleUuid: string;
	perPage?: number;
}

interface UseAllSearchTracksProps {
	pollInterval: number;
}

function useSearchTracks({ searchValue, filtering, pollInterval, sorting, titleUuid, perPage }: UseSearchTracksProps) {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(50);
	const [apiFilters, setApiFilters] = useState<FilterStep | undefined>(undefined);
	const [apiSort, setApiSort] = useState<Order[] | undefined>(undefined);

	const sortingStateToOrderArray = (v?: SortingState) => {
		if (v)
			return v.map((i) => ({
				field: i.id,
				direction: i.desc ? 'DESC' : 'ASC',
			}));
		return undefined;
	};

	const formatTitleType = (tree: FilterStep | undefined): FilterStep | undefined => {
		if (tree === undefined) {
			return undefined;
		}
		const replacedFilters: (Filter | FilterStep)[] = [];

		tree.step.filters.forEach((filterOrStep) => {
			if ('field' in filterOrStep) {
				if (filterOrStep.field === 'releaseDate.value') {
					replacedFilters.push({
						...filterOrStep,
						value: new Date(filterOrStep.value as string).toDateString(),
					});
				} else {
					replacedFilters.push(filterOrStep);
				}
			} else if ('step' in filterOrStep) {
				const replaced = formatTitleType(filterOrStep);
				if (replaced) {
					replacedFilters.push(replaced);
				}
			} else {
				replacedFilters.push(filterOrStep);
			}
		});

		return {
			step: {
				combinationOperator: tree.step.combinationOperator,
				filters: replacedFilters,
			},
		};
	};

	const { loading, error, data, networkStatus, startPolling, stopPolling, refetch, fetchMore } = useQuery<
		{
			searchTracks: SearchTracksOutput;
		},
		{
			search: SearchInput;
		}
	>(SEARCH_TRACKS, {
		variables: {
			search: {
				pagination: { page: currentPage, perPage: perPage || pageSize },
				orderBy: apiSort,
				filters: JSON.stringify(apiFilters),
			},
		},
		notifyOnNetworkStatusChange: true,
	});

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
		refetch({
			search: {
				pagination: { page: newPage, perPage: perPage || pageSize },
				filters: JSON.stringify(apiFilters),
				orderBy: apiSort,
			},
		});
	};

	const handlePageSizeChange = (newPageSize: number) => {
		setPageSize(newPageSize);
		setCurrentPage(1);
		refetch({
			search: {
				pagination: { page: currentPage, perPage: newPageSize },
				filters: JSON.stringify(apiFilters),
				orderBy: apiSort,
			},
		});
	};

	// Format filters for API
	useEffect(() => {
		const formattedFilters = formatTitleType(filtering);
		const searchNoFilterStep: FilterStep = {
			step: {
				combinationOperator: 'OR',
				filters: [
					{
						field: 'assetUuid',
						operator: 'contains',
						value: searchValue,
					},
					{
						field: 'trackName',
						operator: 'contains',
						value: searchValue,
					},
				],
			},
		};

		const filterByTitleUuid = (filters?: FilterStep): FilterStep => {
			if (!titleUuid) return filters ?? { step: { combinationOperator: 'AND', filters: [] } };
			const tmp: FilterStep = {
				step: {
					combinationOperator: 'AND',
					filters: [
						{
							field: 'titleUuid',
							operator: 'equals',
							value: titleUuid,
						},
					],
				},
			};

			if (filters) tmp.step.filters.push(filters);

			return tmp;
		};

		const searchFilterStep: FilterStep = {
			step: {
				combinationOperator: 'AND',
				filters: [searchNoFilterStep, formattedFilters!],
			},
		};

		if (searchValue.length)
			setApiFilters(filtering ? filterByTitleUuid(searchFilterStep) : filterByTitleUuid(searchNoFilterStep));
		else setApiFilters(filterByTitleUuid(formattedFilters));
	}, [searchValue, filtering]);

	// Format sorting for API
	useEffect(() => {
		if (sorting) {
			setApiSort(sortingStateToOrderArray(sorting) || []);
		}
	}, [sorting]);

	// Fire request on change of Sorting of Filtering
	useEffect(() => {
		refetch({
			search: {
				pagination: { page: currentPage, perPage: perPage || pageSize },
				filters: JSON.stringify(apiFilters),
				orderBy: apiSort,
			},
		});
	}, [apiSort, apiFilters]);

	// Query polling
	useEffect(() => {
		startPolling(pollInterval);
	}, [startPolling]);

	return {
		loading,
		error,
		data,
		startPolling,
		stopPolling,
		refetch,
		networkStatus,
		fetchMore,
		handlePageChange,
		handlePageSizeChange,
	};
}

// Util hook to retrieve all tracks without filtering
export function useAllSearchTracks({ pollInterval }: UseAllSearchTracksProps) {
	const { loading, error, data, startPolling } = useQuery<
		{
			searchTracks: SearchTracksOutput;
		},
		{
			search: SearchInput;
		}
	>(SEARCH_TRACKS, {
		variables: {
			search: {
				pagination: { page: 1, perPage: 1000 },
			},
		},
	});

	// Query polling
	useEffect(() => {
		startPolling(pollInterval);
	}, [startPolling]);

	return {
		loading,
		error,
		data,
	};
}

export default useSearchTracks;
