import React, { useMemo } from 'react';
import { CellContext } from '@tanstack/react-table';
import { ContentIdentifier } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/ContentIdentifier';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

// LIBRARY
import { DropdownV2 } from '@warehouse/shared/ui';

// JSON
import workTypeDetails from '../../../../../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import { CellWrapper } from '../../../../../../../components/library/SimpleTable/style';

export default function CellSubScope({
	getValue,
	table,
}: CellContext<Inherited<ContentIdentifier>, Inherited<ContentIdentifier>>) {
	const { displayValue } = getValue();
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;

	const options = useMemo(() => {
		if (displayValue.scope?.scope === undefined || displayValue.scope?.scope.trim() === '') return [];
		const workType = Object.values(workTypeDetails).find(({ uuid }) => uuid === displayValue.scope?.scope);
		if (!workType) return [];
		return workType.availableWorkTypeDetails;
	}, [displayValue.scope?.scope, workTypeDetails]);

	if (!options.length) return null;
	return (
		<CellWrapper>
			<DropdownV2
				value={displayValue.scope?.subscope}
				onChange={(value) => {
					editRecord({
						...getValue(),
						displayValue: {
							...getValue().displayValue,
							scope: {
								...getValue().displayValue.scope,
								subscope: value,
							},
						},
					});
				}}
				transparentBorder
				backgroundColor="transparent"
				withSearch={options.length > 10}
				options={options}
			/>
		</CellWrapper>
	);
}
