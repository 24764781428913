export default function computeSortableName(input?: string): string {
	const ARTICLES = ['the', 'a', 'an'];

	return ARTICLES.reduce((acc, article) => {
		if (acc.toLowerCase().startsWith(`${article.toLowerCase()} `)) {
			return `${acc.slice(article.length + 1)}, ${acc.slice(0, article.length)}`;
		}
		return acc;
	}, input || '');
}
