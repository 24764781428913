import React from 'react';
import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';
import { CombinedWorkType, isParentAbstractionWorkTypeAndWorkType, WorkType } from '@warehouse/title/core';
import AbstractionMovie from '../../../src/assets/title-icons/movie/movie_abstraction_black.svg';
import AbstractionEpisode from '../../../src/assets/title-icons/episode/episode_abstraction_black.svg';
import AbstractionSupplemental from '../../../src/assets/title-icons/supplemental/supplemental_abstraction_black.svg';
import Compilation from '../../../src/assets/title-icons/compilation/compilation_abstraction_black.svg';
import PerformanceMovie from '../../../src/assets/title-icons/movie/movie_performance_black.svg';
import PerformanceSupplemental from '../../../src/assets/title-icons/supplemental/supplemental_performance_black.svg';
import Season from '../../../src/assets/title-icons/season/season_abstraction_black.svg';
import Series from '../../../src/assets/title-icons/series/series_abstraction_black.svg';
import PerformanceEpisode from '../../../src/assets/title-icons/episode/episode_performance_black.svg';

interface TitleIconProps {
	combinedWorkType: CombinedWorkType;
	size?: number;
	iconSize?: number;
	noBackground?: boolean;
	radius?: 'default' | 'full';
}

export function TitleIcon({
	combinedWorkType,
	radius = 'default',
	iconSize,
	size = 48,
	noBackground = false,
}: TitleIconProps) {
	const icon = getIconByEntityNameAndTitleType(combinedWorkType);
	const bgColor = getBackgroundColorByTitleType(
		isParentAbstractionWorkTypeAndWorkType(combinedWorkType) ? combinedWorkType.workType : combinedWorkType,
	);

	return (
		<ImageWrapper size={size} iconSize={iconSize} bgColor={bgColor} noBackground={noBackground} radius={radius}>
			<img src={icon || undefined} alt="eidrIcon" />
		</ImageWrapper>
	);
}

const ImageWrapper = styled.div<{
	noBackground: boolean;
	size: number;
	bgColor: string;
	iconSize?: number;
	radius: 'default' | 'full';
}>(
	({ noBackground, bgColor, size, radius, iconSize }) => css`
		align-items: center;

		${!noBackground &&
		css`
			background-color: ${alpha(bgColor, 0.2)};
		`};
		border-radius: ${radius === 'default' ? '8px 0 0 8px' : '8px'};
		display: flex;
		height: ${size}px;
		justify-content: center;
		min-width: ${size}px;
		width: ${size}px;

		& > img {
			height: ${iconSize ? `${iconSize}px` : `${size / 2}px`};
			width: ${iconSize ? `${iconSize}px` : `${size / 2}px`};
		}
	`,
);

function getIconByEntityNameAndTitleType(combinedWorkType: CombinedWorkType) {
	if (isParentAbstractionWorkTypeAndWorkType(combinedWorkType)) {
		const { parentAbstractionWorkType, workType } = combinedWorkType;
		if (workType === WorkType.Edit) {
			if (parentAbstractionWorkType === WorkType.Episode) return PerformanceEpisode;
			if (parentAbstractionWorkType === WorkType.Movie) return PerformanceMovie;
			if (parentAbstractionWorkType === WorkType.Supplemental) return PerformanceSupplemental;
		}
	} else {
		const workType = combinedWorkType;
		if (workType === WorkType.Series) return Series;
		if (workType === WorkType.Season) return Season;
		if (workType === WorkType.Episode) return AbstractionEpisode;
		if (workType === WorkType.Compilation) return Compilation;
		if (workType === WorkType.Movie) return AbstractionMovie;
		if (workType === WorkType.Supplemental) return AbstractionSupplemental;
	}

	return PerformanceMovie;
}

function getBackgroundColorByTitleType(workType: WorkType) {
	switch (workType) {
		case WorkType.Edit:
		case WorkType.Manifestation:
			return '#2196F3';
		case WorkType.Movie:
		case WorkType.Series:
		case WorkType.Episode:
		case WorkType.Season:
		case WorkType.Compilation:
		default:
			return '#F27E30';
	}
}
