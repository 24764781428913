import React from 'react';
import { titlePaths } from '@warehouse/title/core';
import { TitleSingleDropdownField } from '@warehouse/title/ui';
import titleStatusJSON from '../../../../src/assets/json-administration-profiles/availableTitleStatus.json';

export function TitleStatusField() {
	return (
		<TitleSingleDropdownField
			label="Status"
			path={titlePaths.titleStatus}
			options={titleStatusJSON}
			view="titleSettings"
		/>
	);
}
