import React, { useCallback, useContext } from 'react';
import { Portal } from '@mui/material';
import { RelationshipType, TitleFull, TitleLightWithSelectedParent } from '@warehouse/title/core';
import { RelationshipEditor } from '@warehouse/title/edit-relationship-feature';
import { ChildrenParentMode, RelationshipsList } from '@warehouse/title/list-relationships-feature';
import { useSnackbar } from 'notistack';
import { ErrorBoundary } from '../../../../../libs/title/edit-relationship-feature/ErrorBoundary';
import { useStateQuery } from '../../../../utils/hooks/useStateQuery';
import { UnsavedChangesContext } from '../../contexts/UnsavedChangesContext';

interface EditRelationshipState {
	relationshipType: RelationshipType;
	parentUuid: string;
	childUuid: string;
	isCompObj: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Relationships({ editMode, title }: { title: TitleFull; editMode: boolean }) {
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	const [editRelationship, setEditRelationship] = useStateQuery<EditRelationshipState | undefined>({
		queryName: 'editRelationship',
		type: 'object',
	});

	const { enqueueSnackbar } = useSnackbar();

	if (!title.uuid) return null;

	const handleChildrenClick = useCallback(
		(mode: ChildrenParentMode, selectedTitle: TitleLightWithSelectedParent) => {
			if (!title.uuid) return;
			switch (mode) {
				case 'children':
					setEditRelationship({
						parentUuid: title.uuid,
						childUuid: selectedTitle.uuid,
						isCompObj: selectedTitle.selectedParent.relationshipType === RelationshipType.EntryOf,
						relationshipType: selectedTitle.selectedParent.relationshipType,
					});
					break;
				case 'parents':
					setEditRelationship({
						parentUuid: selectedTitle.selectedParent.contentId,
						childUuid: title.uuid,
						isCompObj: selectedTitle.selectedParent.relationshipType === RelationshipType.EntryOf,
						relationshipType: selectedTitle.selectedParent.relationshipType,
					});
					break;
				default:
					break;
			}
		},
		[title.uuid, setEditRelationship],
	);

	return (
		<>
			<RelationshipsList title={title} onChildrenClick={handleChildrenClick} />
			<Portal container={document.body}>
				<ErrorBoundary
					onErrorRender={() => {
						enqueueSnackbar('Child not found', { variant: 'warning' });
						setEditRelationship(null);
					}}
				>
					<RelationshipEditor
						parentUuid={editRelationship?.parentUuid || title.uuid}
						childUuid={editRelationship?.childUuid}
						{...(editRelationship && !editRelationship.isCompObj
							? {
									isCompObj: false,
									relationshipType: editRelationship.relationshipType,
							  }
							: {
									isCompObj: true,
									relationshipType: undefined,
							  })}
						onClose={(force?: boolean) => {
							if (force) setEditRelationship(null);
							else promptUserIfUnsavedChanges(() => setEditRelationship(null));
						}}
					/>
				</ErrorBoundary>
			</Portal>
		</>
	);
}
