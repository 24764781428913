import { useWatchObservableQuery } from '@warehouse/shared/util';
import { relationshipRepositorySingleton } from '../infra';
import { WatchSearchOptions } from '../core/relationship.repository';

export const useSearchRelationships = (options: WatchSearchOptions) => {
	const repo = relationshipRepositorySingleton.get();

	return useWatchObservableQuery({
		queryKey: ['search-relationships', options],
		queryFn: () => repo.watchSearch(options),
	});
};
