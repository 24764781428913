import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';
import Icon from '@mdi/react';

export const FlexWrapper = styled.div<{ expandable?: boolean }>(
	({ theme, expandable }) => css`
		align-items: center;
		display: flex;
		flex-grow: 1;
		font-size: ${theme.typography.buttonMediumRegular.fontSize};
		font-weight: ${theme.typography.buttonMediumRegular.fontWeight};
		height: 100%;
		line-height: 115%;
		padding: 0 12px;

		${expandable &&
		css`
			&:hover {
				cursor: pointer;
			}
		`}
	`,
);

export const ValueWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		height: 100%;
		width: 100%;
		> div {
			align-items: center;
			display: flex;
			height: 100%;
			width: 100%;
		}
	`,
);

export const TableWrapper = styled.div<{ height?: number }>`
	height: 100%;
	white-space: nowrap;
	${({ height }) =>
		height
			? css`
					max-height: ${height}px;
			  `
			: null}
`;

export const Table = styled.table(
	({ theme }) => css`
		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		border-spacing: 0;
		color: ${theme.palette.text.primary};
		width: 100%;

		th,
		td {
			padding: ${theme.spacing(2)};
		}
	`,
);

export const THead = styled.thead(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		width: 100%;

		tr:first-child th:first-child {
			border-top-left-radius: 8px;
		}
		tr:first-child th:last-child {
			border-top-right-radius: 8px;
		}
	`,
);

export const TR = styled.tr<{
	rowIndex?: number;
}>(
	({ theme, rowIndex }) => css`
		${rowIndex !== undefined && rowIndex % 2 === 0
			? css`
					background-color: ${theme.palette.light.main};
			  `
			: css`
					background-color: ${theme.palette.light.background};
			  `}

		.edit-pencil {
			visibility: hidden;
		}

		&:hover {
			background-color: ${theme.palette.blue.background};
			.edit-pencil {
				visibility: visible;
			}
		}

		&:last-child td:first-child {
			border-bottom-left-radius: 8px;
		}

		&:last-child td:last-child {
			border-bottom-right-radius: 8px;
		}

		height: 48px;
		text-align: left;
	`,
);

export const TH = styled.th(
	({ theme }) => css`
		border: 1px solid transparent;
		color: ${alpha(theme.palette.dark.main, 0.8)};
		cursor: pointer;
		font-size: 12px;
		font-weight: 500;
		padding: 0 12px !important;
		position: sticky;
		text-align: left;
		text-transform: uppercase;
	`,
);

export const TD = styled.td<{ depth?: number; isEditing?: boolean }>(
	({ depth }) => css`
		height: 48px;
		padding: 0 !important;

		${depth &&
		css`
			padding-left: ${depth * 20 + 3}px !important;
		`}
	`,
);

export const StyledIcon = styled(Icon)(
	({ theme }) => css`
		color: ${alpha(theme.palette.dark.main, 0.5)};
	`,
);

export const EditPencilIcon = styled(Icon)(
	({ theme }) => css`
		color: ${theme.palette.blue.main} !important;
	`,
);

export const RowContent = styled.div(
	({ theme }) => css`
		align-items: center;
		border: 1px solid transparent;
		box-sizing: border-box;
		display: flex;
		font-size: ${theme.typography.bodyMediumRegular.fontSize};
		font-weight: ${theme.typography.bodyMediumRegular.fontWeight};
		height: 100%;
		padding: 0 12px;
		width: 100%;
	`,
);

export const RowInput = styled.input<{ width?: number }>(
	({ theme, width }) => css`
		background-color: inherit;
		border: 1px solid transparent;
		box-sizing: border-box;
		color: ${alpha(theme.palette.dark.main, 0.5)};
		font-size: ${theme.typography.bodyMediumRegular.fontSize};
		font-weight: ${theme.typography.bodyMediumRegular.fontWeight};
		height: 100%;
		padding: 0 12px;
		width: ${width ? `${width}px` : '100%'};
		&:focus-visible {
			outline: transparent;
		}
		&:focus {
			border: 1px solid ${theme.palette.blue.main};
		}
	`,
);

export const EditableCellWrapper = styled.div(
	() => css`
		height: 100%;
		width: 100%;
	`,
);

export const DropdownWrapper = styled.div(
	() => css`
		width: 100%;
		.input-wrapper {
			padding: 0 12px !important;
			> input {
				padding: 0 !important;
			}
		}
		.MuiChip-root {
			height: 24px;
		}
	`,
);

export const DatePickerWrapper = styled.div(
	() => css`
		width: 100%;
		.input-wrapper {
			padding: 0 12px !important;
			> input {
				padding: 0 10px !important;
			}
		}
		.MuiChip-root {
			height: 24px;
		}
	`,
);
