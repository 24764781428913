import { Dispatch, SetStateAction } from 'react';
import { TitleFull as Title } from '@warehouse/title/core';
import { deepEqualityWithOrWithoutInheritance } from '@warehouse/shared/util';
import { useEditPreventer } from '@warehouse/title/feature-edit-preventer-popup';
import { WithUuid } from './recordToArray';
import { EditObject, OnRemoveSuccessFn } from '../../contexts/TitleAutoSaveQueueContext';
import { JsonPointer } from '../../../../utils/getDeepProperty';
import { buildCommonPayload, getFormattedPath } from './common';

interface UseRemoveProps<T> {
	value: WithUuid<T>[];
	setValue: Dispatch<SetStateAction<WithUuid<T>[]>>;
	setDirtyValue: Dispatch<SetStateAction<WithUuid<T>[] | undefined>>;
	title: Title | undefined;
	path: JsonPointer;
	label: string;
	addToQueue: (editObject: EditObject<T>) => void;
}

export default function useRemove<T>({
	addToQueue,
	value,
	setValue,
	setDirtyValue,
	title,
	path,
	label,
}: UseRemoveProps<T>) {
	const { act } = useEditPreventer();
	const onRemoveSuccess: OnRemoveSuccessFn = (removedUuids: string[]) => {
		setDirtyValue(undefined);
		setValue((prev) => removeRemovedRowsFromPrev<T>(prev, removedUuids));
	};

	const removeRecord = (uuids: string[]) => {
		// If the dirty value goes back to the original value, the field is not dirty anymore
		setDirtyValue((prev) => {
			const valueToReturn = (prev || value || []).filter((el) => !uuids.includes(el.__uuid));
			return deepEqualityWithOrWithoutInheritance(valueToReturn, value) ? undefined : valueToReturn;
		});

		const filteredUuids = uuids.filter((uuid) => !uuid.startsWith('tmp-'));

		if (title && filteredUuids.length > 0 && getFormattedPath(path, title)) {
			act({
				save: () =>
					addToQueue({
						mutation: 'remove',
						onSuccess: onRemoveSuccess,
						recordUuids: filteredUuids,
						...buildCommonPayload(path, title, label),
					}),
				onDenied: () => {
					setDirtyValue(undefined);
				},
				current: null,
				title,
				path,
			});
		}
	};

	return {
		removeRecord,
	};
}

function removeRemovedRowsFromPrev<T>(prev: WithUuid<T>[], removedUuids: string[]) {
	return prev.filter((el) => !removedUuids.includes(el.__uuid));
}
