import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { FieldWrapper } from './style';
import TitleTooltip from '../../../components/titles/TitleTooltip';
import { ReadOnlyField } from '../../../components/library/ReadOnlyField';

const ReadOnlyTitleId = styled(ReadOnlyField)`
	font-family: monospace;
	user-select: all;
`;

type TitleIdProps = {
	titleId: string | undefined;
};

function TitleId({ titleId }: TitleIdProps) {
	return useMemo(
		() => (
			<FieldWrapper className="field-wrapper">
				<Typography variant="buttonLargeMedium">
					Title ID
					<TitleTooltip path="coreMetadata.contentId" />
				</Typography>
				<ReadOnlyTitleId variant="buttonMediumRegular">{titleId}</ReadOnlyTitleId>
			</FieldWrapper>
		),
		[titleId],
	);
}

export default TitleId;
