import styled from 'styled-components';

const MergeIcons = styled.span`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: space-around;

	svg {
		margin: -7px;
	}
`;

export default MergeIcons;
