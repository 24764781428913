import React from 'react';
import { CommonReadonly } from '@warehouse/title/core';
import { ContentField } from './ui/ContentField';
import { DeleteButtonField } from './ui/DeleteButtonField';
import { RelationshipTypeField } from './ui/RelationshipTypeField';
import { ParentField } from './ui/ParentField';
import { DisplayName } from './comp-obj-relationship.model';
import { EntryNumberField } from './ui/EntryNumberField';
import { EntryClassField } from './ui/EntryClassField';
import { DisplayNamesTable } from './ui/DisplayNamesTable';

export function CompObjRelationshipEditor({
	parentTitle,
	childTitle,
	entryNumber,
	entryClass,
	displayNames,
	defaultLanguageId,
	updateEntryNumber,
	updateEntryClass,
	addEmptyDisplayName,
	removeDisplayNames,
	updateDisplayNameDisplayName,
	updateDisplayNameLanguage,
	deleteChild,
	commitChanges,
}: CompObjRelationshipEditorProps) {
	return (
		<>
			<ContentField titleLight={childTitle} />
			<RelationshipTypeField relationshipType="Entry of" />
			<ParentField titleLight={parentTitle} />
			<EntryNumberField entryNumber={entryNumber} onEntryNumberChange={updateEntryNumber} onBlur={commitChanges} />
			<EntryClassField entryClass={entryClass} onEntryClassChange={updateEntryClass} onBlur={commitChanges} />
			<DisplayNamesTable
				displayNames={displayNames}
				updateDisplayNameDisplayName={updateDisplayNameDisplayName}
				updateDisplayNameLanguage={updateDisplayNameLanguage}
				commitChanges={commitChanges}
				onAddDisplayName={() => addEmptyDisplayName(defaultLanguageId)}
				onRemoveDisplayNames={removeDisplayNames}
			/>
			<DeleteButtonField onDelete={deleteChild} />
		</>
	);
}

interface CompObjRelationshipEditorProps {
	parentTitle: CommonReadonly;
	childTitle: CommonReadonly;
	entryNumber: string | undefined;
	entryClass: string | undefined;
	displayNames: DisplayName[];
	defaultLanguageId: string;
	updateEntryNumber: (entryNumber: string | undefined) => void;
	updateEntryClass: (entryClass: string) => void;
	addEmptyDisplayName: (languageId?: string) => void;
	removeDisplayNames: (displayNameIndexes: number[]) => void;
	updateDisplayNameDisplayName: (newDisplayName: string | undefined, displayNameIndex: number) => void;
	updateDisplayNameLanguage: (newLanguage: string | undefined, displayNameIndex: number) => void;
	deleteChild: () => void;
	commitChanges: () => void;
}
