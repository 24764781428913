import React from 'react';
import MUICard, { CardProps } from '@mui/material/Card';
import styled, { css } from 'styled-components';

const StyledCard = styled(MUICard)<CardProps>(
	({ theme }) => css`
		border-radius: 8px;
		box-shadow: none;
		// margin: ${theme.spacing(1.5)};
		// padding: ${theme.spacing(1.5)};
		width: 100%;

		.MuiCardActions-root {
			padding: ${theme.spacing(2)};
		}
	`,
);

function Card(props: CardProps) {
	return <StyledCard {...props} />;
}

export default Card;
