import React from 'react';
import { TitleFull as Title } from '@warehouse/title/core';

// LIBRARY
import AncillaryTab from './AncillaryTab';

interface ProductMetadataTabAncillaryTabProps {
	editMode: boolean;
	title: Title | undefined;
}

function ProductMetadataTabAncillaryTab({ editMode, title }: ProductMetadataTabAncillaryTabProps) {
	return <AncillaryTab editMode={editMode} titleEntity={title} />;
}

export default ProductMetadataTabAncillaryTab;
