import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import { ExpandedState, flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import { mdiChevronDown, mdiChevronRight, mdiCircleSmall, mdiPencil } from '@mdi/js';

// LIBRARY
import { CustomColumnDef } from '../SimpleTable/customColumnDef';
import {
	Table,
	TableWrapper,
	TH,
	THead,
	TR,
	FlexWrapper,
	StyledIcon,
	TD,
	ValueWrapper,
	EditableCellWrapper,
	EditPencilIcon,
	RowContent,
	RowInput,
	DropdownWrapper,
	DatePickerWrapper,
} from './style';
import { DropdownOption } from '../Dropdown/types';
import Dropdown from '../Dropdown/Dropdown';
import TextInput from '../TextInput';
import DatePicker, { dateToString } from '../DatePicker/DatePicker';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import ChipDisplay from '../ChipDisplay';
import TimeInput, { EIDRtoTime, secondsToIBMDurationFormat } from '../TimeInput';

interface DetailsTableProps {
	columns: CustomColumnDef<any>[];
	data: any[];
	setData: Dispatch<SetStateAction<any[]>>;
	setOriginalData: Dispatch<SetStateAction<any[]>>;
	editable?: boolean;
	expandedByDefault?: boolean;
}

type TypeMapping = {
	id: string;
	type: string;
	dropdownOptions?: DropdownOption[];
};

export type DetailsTableData = {
	name: string;
	value?: any;
	type?: string;
	dropdownOptions?: DropdownOption[];
	subRows?: DetailsTableData[];
};

interface EditableCellProps {
	value: any;
	type?:
		| 'default'
		| 'number'
		| 'boolean'
		| 'date'
		| 'time'
		| 'dropdown'
		| 'selectDropdown'
		| 'searchDropdown'
		| undefined;
	dropdownOptions?: DropdownOption[];
	editingCell: {
		index: string | null;
		id: string | null;
	};
	row: {
		index: string;
	};
	column: {
		id: string;
	};
	uniqueId?: string | undefined;
	updateNestedData: ((columnId: string, value: any, uniqueId?: string | undefined) => void) | undefined;
	handleToggleEditing: (index: string | null, id: string | null) => void;
	editable?: boolean;
}

const renderEditableCell = (
	value: any,
	type:
		| 'default'
		| 'number'
		| 'boolean'
		| 'date'
		| 'time'
		| 'dropdown'
		| 'selectDropdown'
		| 'searchDropdown'
		| undefined,
	dropdownOptions: DropdownOption[],
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
	onBlur: () => void,
	inputRef: React.RefObject<HTMLInputElement>,
	divRef: React.RefObject<HTMLDivElement>,
	updateNestedData: ((columnId: string, value: any, uniqueId?: string | undefined) => void) | undefined,
	id: string,
	setValue: Dispatch<SetStateAction<any>>,
	uniqueId?: string | undefined,
) => {
	if (type === 'boolean') {
		return (
			<DropdownWrapper>
				<Dropdown
					options={[
						{
							label: 'true',
							value: 'true',
						},
						{
							label: 'false',
							value: 'false',
						},
					]}
					value={value}
					onChange={(e) => {
						if (updateNestedData) {
							updateNestedData(id, e, uniqueId);
							setValue(EIDRtoTime(e as string));
						}
					}}
					transparentBorder
					height={30}
				/>
			</DropdownWrapper>
		);
	}
	if (type === 'dropdown') {
		return (
			<DropdownWrapper>
				<Dropdown
					options={dropdownOptions}
					value={value}
					onChange={(e) => {
						if (updateNestedData) {
							updateNestedData(id, e, uniqueId);
							setValue(e);
						}
					}}
					transparentBorder
					height={30}
				/>
			</DropdownWrapper>
		);
	}
	if (type === 'searchDropdown') {
		return (
			<DropdownWrapper>
				<Dropdown
					withSearch
					options={dropdownOptions}
					value={value}
					onChange={(e) => {
						if (updateNestedData) {
							updateNestedData(id, e, uniqueId);
							setValue(e);
						}
					}}
					transparentBorder
					height={30}
				/>
			</DropdownWrapper>
		);
	}
	if (type === 'selectDropdown') {
		return (
			<DropdownWrapper style={{ width: '262px' }}>
				<SelectDropdown
					options={dropdownOptions}
					values={(value as string[]) || []}
					onSelect={(e) => {
						let values = (value as string[]) || [];
						if (values.includes(e)) {
							values = values.filter((v) => v !== e);
						} else {
							values = [...values, e];
						}
						if (updateNestedData) {
							updateNestedData(id, values, uniqueId);
							setValue(values);
						}
					}}
					transparentBorder
					height={30}
				/>
			</DropdownWrapper>
		);
	}
	if (type === 'number') {
		return (
			<DropdownWrapper>
				<TextInput
					height={30}
					type="number"
					value={value}
					onChange={(e) => {
						if (updateNestedData) {
							updateNestedData(id, e.target.value, uniqueId);
							setValue(e.target.value);
						}
					}}
				/>
			</DropdownWrapper>
		);
	}
	if (type === 'date') {
		return (
			<DatePickerWrapper>
				<DatePicker
					value={new Date(value)}
					height={30}
					onChange={(date) => {
						if (updateNestedData) {
							updateNestedData(id, dateToString(date || null));
							setValue(dateToString(date || null));
						}
					}}
				/>
			</DatePickerWrapper>
		);
	}
	if (type === 'time') {
		return (
			<DropdownWrapper>
				<TimeInput
					onChange={(e) => {
						if (updateNestedData) {
							updateNestedData(id, secondsToIBMDurationFormat(e), uniqueId);
							setValue(secondsToIBMDurationFormat(e));
						}
					}}
					value={value}
					height={30}
				/>
			</DropdownWrapper>
		);
	}
	return (
		<RowInput
			ref={inputRef}
			value={value || ''}
			onChange={onChange}
			onBlur={onBlur}
			width={divRef.current?.offsetWidth}
		/>
	);
};

function findItem(data: any[], uniqueId: string | undefined) {
	const foundItem = data.find((item) => item.name === uniqueId);
	if (foundItem) {
		return foundItem;
	}

	let foundSubItem = null;
	data.some((item) => {
		if (item.subRows) {
			foundSubItem = findItem(item.subRows, uniqueId);
			return foundSubItem;
		}
		return false;
	});

	return foundSubItem;
}

export function EditableCell({
	value: initialValue,
	type,
	dropdownOptions,
	editingCell,
	row: { index },
	column: { id },
	uniqueId,
	updateNestedData,
	handleToggleEditing,
	editable,
}: EditableCellProps) {
	const [localValue, setLocalValue] = useState<any>(null);
	const isEditing = editingCell.index === index && editingCell.id === id;

	const inputRef = useRef<HTMLInputElement>(null);
	const divRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setLocalValue(initialValue);
	}, [initialValue]);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLocalValue(e.target.value);
	};

	const onBlur = () => {
		updateNestedData?.(id, localValue, uniqueId);
		handleToggleEditing(null, null);
	};

	const toggleEditing = () => {
		handleToggleEditing(index, id);
	};

	useEffect(() => {
		if (isEditing) {
			inputRef.current?.focus();
		}
	}, [isEditing]);

	const renderEditingCell = () => {
		if (isEditing) {
			return renderEditableCell(
				localValue,
				type,
				dropdownOptions || [],
				onChange,
				onBlur,
				inputRef,
				divRef,
				updateNestedData,
				id,
				setLocalValue,
				uniqueId,
			);
		}
		if (type === 'selectDropdown' && Array.isArray(localValue)) {
			return (
				<div style={{ width: '262px' }}>
					<ChipDisplay values={localValue} allowOverflow />
				</div>
			);
		}
		if (type === 'time') return <RowContent>{EIDRtoTime(localValue)}</RowContent>;
		return <RowContent ref={divRef}>{localValue}</RowContent>;
	};

	const renderCell = () => {
		if (editable) {
			return (
				<EditableCellWrapper onDoubleClick={toggleEditing}>
					{renderEditingCell()}
					<IconButton disableRipple onClick={toggleEditing}>
						<EditPencilIcon className="edit-pencil" path={mdiPencil} size="24px" />
					</IconButton>
				</EditableCellWrapper>
			);
		}
		if (type === 'selectDropdown' && Array.isArray(localValue)) {
			return <ChipDisplay values={localValue} />;
		}
		if (type === 'time') return <RowContent>{EIDRtoTime(localValue)}</RowContent>;
		return <RowContent>{localValue}</RowContent>;
	};

	return <>{renderCell()}</>;
}

export const transformAndFormatData = (data: any, typeMapping: TypeMapping[], keys?: string[]): DetailsTableData[] => {
	if (data == null) return [];

	const keysToUse = !keys || keys.length === 0 ? Object.keys(data) : keys;

	return keysToUse
		.filter((key) => key !== '__typename')
		.map((key) => {
			const value = data[key];
			const mapping = typeMapping.find((v) => v.id === key);

			let formattedObj: DetailsTableData;
			if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
				formattedObj = {
					name: key.replace(/^./, (char) => char.toUpperCase()),
					value: null,
					subRows: transformAndFormatData(value, typeMapping, Object.keys(value)),
				};
			} else if (Array.isArray(value)) {
				formattedObj = {
					name: key.replace(/^./, (char) => char.toUpperCase()),
					value: null,
					subRows: value?.flatMap((item: any) => transformAndFormatData(item, typeMapping)),
				};
			} else {
				formattedObj = {
					name: key.replace(/^./, (char) => char.toUpperCase()),
					value,
				};
			}

			if (mapping) {
				formattedObj.type = mapping.type;
				formattedObj.dropdownOptions = mapping.dropdownOptions;
			} else {
				formattedObj.type = 'default';
			}

			return formattedObj;
		});
};

function DetailsTable({
	columns,
	data,
	setData,
	setOriginalData,
	editable,
	expandedByDefault = false,
}: DetailsTableProps) {
	const [expanded, setExpanded] = useState<ExpandedState>({});
	const [editingCell, setEditingCell] = useState<{
		index: string | null;
		id: string | null;
	}>({ index: null, id: null });

	const tableRef = useRef<HTMLTableElement>(null);

	const handleToggleEditing = (index: string | null, id: string | null) => {
		setEditingCell((prev) => (prev.index === index && prev.id === id ? { index: null, id: null } : { index, id }));
	};

	const table = useReactTable({
		data,
		state: {
			expanded,
		},
		onExpandedChange: setExpanded,
		columns,
		getSubRows: (row) => row.subRows,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		// Provide our updateData function to our table
		meta: {
			updateNestedData: (columnId: string, value: any, uniqueId?: string) => {
				setData((prevData) => {
					const dataCopy = [...prevData];
					const item = findItem(dataCopy, uniqueId);
					if (item) {
						item[columnId] = value;
					}
					return dataCopy;
				});
				setOriginalData((prevData) => {
					const dataCopy = [...prevData];
					const item = findItem(dataCopy, uniqueId);
					if (item) {
						item[columnId] = value;
					}
					return dataCopy;
				});
			},
		},
	});

	useEffect(() => {
		if (expandedByDefault !== undefined && table) {
			table.toggleAllRowsExpanded(expandedByDefault);
		}
	}, [expandedByDefault]);

	return (
		<div style={{ width: '100%' }}>
			<TableWrapper>
				<Table ref={tableRef}>
					<THead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TR style={{ backgroundColor: 'inherit' }} key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<TH
										key={header.id}
										colSpan={header.colSpan}
										style={{
											width: `${(header.column.columnDef as CustomColumnDef<any>).width}px`,
											minWidth: `${(header.column.columnDef as CustomColumnDef<any>).minWidth}px`,
											maxWidth: `${(header.column.columnDef as CustomColumnDef<any>).maxWidth}px`,
										}}
									>
										{flexRender(header.column.columnDef.header, header.getContext())}
									</TH>
								))}
							</TR>
						))}
					</THead>
					<tbody>
						{table.getRowModel().rows.map((row, rowIndex) => (
							<TR key={row.original.name + rowIndex} rowIndex={rowIndex}>
								{row.getCanExpand() ? (
									<>
										<TD depth={row.depth} colSpan={1}>
											<FlexWrapper expandable onClick={row.getToggleExpandedHandler()}>
												<StyledIcon path={row.getIsExpanded() ? mdiChevronDown : mdiChevronRight} size="24px" />

												{row.original.name}
											</FlexWrapper>
										</TD>
										<TD>
											<FlexWrapper>
												<ValueWrapper>
													{flexRender(
														<EditableCell
															value={row.original.value}
															type={row.original.type || 'default'}
															dropdownOptions={row.original.dropdownOptions}
															editingCell={editingCell}
															row={{ index: row.id }}
															column={{ id: 'value' }}
															uniqueId={row.original.name}
															updateNestedData={table.options.meta?.updateNestedData}
															handleToggleEditing={handleToggleEditing}
															editable={editable}
														/>,
														{ row, column: { id: 'value' } },
													)}
												</ValueWrapper>
											</FlexWrapper>
										</TD>
									</>
								) : (
									<>
										{row.getVisibleCells().map((cell, cellIndex) => (
											<TD key={cell.id + cellIndex} depth={cellIndex === 0 ? row.depth : undefined}>
												<FlexWrapper>
													{cellIndex === 0 && <StyledIcon path={mdiCircleSmall} size="24px" />}
													<ValueWrapper>
														{cellIndex === 0
															? flexRender(cell.column.columnDef.cell, cell.getContext())
															: flexRender(
																	<EditableCell
																		value={cell.row.original.value}
																		type={cell.row.original.type || 'default'}
																		dropdownOptions={cell.row.original.dropdownOptions}
																		editingCell={editingCell}
																		row={{ index: row.id }}
																		column={{ id: cell.column.id }}
																		uniqueId={cell.row.original.name + rowIndex}
																		updateNestedData={table.options.meta?.updateNestedData}
																		handleToggleEditing={handleToggleEditing}
																		editable={editable}
																	/>,
																	{ row, column: cell.column.id },
															  )}
													</ValueWrapper>
												</FlexWrapper>
											</TD>
										))}
									</>
								)}
							</TR>
						))}
					</tbody>
				</Table>
			</TableWrapper>
		</div>
	);
}

export default DetailsTable;
