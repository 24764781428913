import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';

// TYPES
import { Job } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/People';

// LIBRARY
import CollapsableCard from '../../../../../../components/titles/CollapsableCard';
import RoleAndBBO from './RoleAndBBO';
import CharactersList from './CharactersList';
import { CastAndCrewContext } from '../providers/castAndCrewProvider';

// JSON
import RoleJSON from '../../../../../../assets/json-administration-profiles/availableRoleType.json';

interface RoleCardProps {
	role: Job | undefined;
	listLength: number;
	peopleIndex: number;
	roleIndex: number;
	setUuids: Dispatch<SetStateAction<string[]>>;
}

export default function RoleCard({ peopleIndex, roleIndex, listLength, role, setUuids }: RoleCardProps) {
	const { removeFromArray } = useContext(CastAndCrewContext);

	const getFormattedRole = () => {
		const jobFunction = role?.jobFunction;
		if (!jobFunction) return undefined;

		const jsonRole = RoleJSON.find((_role) => _role.value === jobFunction);
		return jsonRole?.label || `${jobFunction.charAt(0).toUpperCase()}${jobFunction.slice(1)}`;
	};

	const onClickDeleteIcon = () => {
		removeFromArray(`people.${peopleIndex}.jobs`, [roleIndex.toString()]);
		setUuids((prevUuids) => prevUuids.filter((_, idx) => idx !== roleIndex));
	};

	const showCharactersList = useMemo(
		() => ['actor', 'self', 'guest'].includes(role?.jobFunction || ''),
		[role?.jobFunction],
	);

	return (
		<CollapsableCard
			onClickDeleteIcon={onClickDeleteIcon}
			showDeleteIcon
			deletedIconIsDisabled={listLength <= 1}
			label={`Role${getFormattedRole() ? ` - ${getFormattedRole()}` : ''}`}
		>
			<RoleAndBBO
				peopleIndex={peopleIndex}
				roleIndex={roleIndex}
				role={role?.jobFunction}
				bbo={
					typeof role?.billingBlockOrders?.[0]?.billingBlockOrder === 'number'
						? role.billingBlockOrders[0].billingBlockOrder
						: undefined
				}
			/>
			{showCharactersList && (
				<CharactersList peopleIndex={peopleIndex} roleIndex={roleIndex} characters={role?.characterInfos} />
			)}
		</CollapsableCard>
	);
}
