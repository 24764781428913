import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import { lighten, IconButton, alpha } from '@mui/material';
import { mdiPlus, mdiFormatListGroupPlus, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import Button from '../../library/Button';
import ButtonGroup from '../../library/ButtonGroup';

const MAX_CONDITION_NESTING = 5;
interface ConditionBlockProps {
	children?: ReactNode;
	conditionalOperator: 'AND' | 'OR';
	setConditionalOperator: (value: 'AND' | 'OR') => void;
	onDelete: () => void;
	onAddItem: () => void;
	onAddStep: () => void;
	level: number;
}

const Wrapper = styled.div<{ hovered: boolean }>`
	${({ theme, hovered }) => css`
		background-color: ${alpha(theme.palette.blue.main, 0.05)};
		border: ${hovered ? `1px solid ${theme.palette.blue.main};` : `1px solid ${theme.palette.light.backgroundAlt}`};
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		padding: ${theme.spacing(1)};
		position: relative;
	`}
`;

const OnHoverActionsGroup = styled.div`
	${() => css`
		align-items: center;
		align-self: flex-end;
		display: flex;
	`}
`;

const ActionsWrapper = styled.div`
	${() => css`
		display: flex;
		justify-content: space-between;
	`}
`;

const DeleteButton = styled(IconButton)`
	${({ theme }) => css`
		color: ${theme.palette.text.secondary};
		margin-left: ${theme.spacing(1)};
	`};
`;

const ChildrenWrapper = styled.div`
	${({ theme }) => css`
		align-self: flex-end;
		width: 98%;

		> * {
			margin: ${theme.spacing(1)};

			&:before {
				border-bottom: 1.5px solid ${lighten(theme.palette.dark.main, 0.9)};
				border-bottom-left-radius: 4px;

				border-left: 1.5px solid ${lighten(theme.palette.dark.main, 0.9)};
				content: '';
				height: 3px;
				left: -11.5px;
				position: absolute;
				top: calc(50% - 1.5px);
				width: 10px;
			}

			&:after {
				border-left: 1.5px solid ${lighten(theme.palette.dark.main, 0.9)};
				bottom: 0;
				content: '';
				height: calc(100% + ${theme.spacing(1)} + 2px);
				left: -11.5px;
				position: absolute;
				width: 8px;
			}

			&:last-child:after {
				bottom: calc(50% + 2px);
				height: calc(50% + ${theme.spacing(1)});
			}
		}
	`}
`;

function ConditionBlock({
	children,
	conditionalOperator,
	setConditionalOperator,
	onAddItem,
	onAddStep,
	onDelete,
	level,
}: ConditionBlockProps) {
	const [actionsHovered, setActionsHovered] = useState(false);

	return (
		<Wrapper hovered={actionsHovered}>
			<ActionsWrapper onMouseEnter={() => setActionsHovered(true)} onMouseLeave={() => setActionsHovered(false)}>
				<ButtonGroup nxstyle="tertiary-light">
					<Button
						nxstyle="tertiary-light"
						onClick={() => setConditionalOperator('AND')}
						$forceActive={conditionalOperator === 'AND'}
					>
						AND
					</Button>
					<Button
						nxstyle="tertiary-light"
						onClick={() => setConditionalOperator('OR')}
						$forceActive={conditionalOperator === 'OR'}
					>
						OR
					</Button>
				</ButtonGroup>
				<OnHoverActionsGroup>
					<ButtonGroup nxstyle="tertiary-light">
						<Button nxstyle="tertiary-light" startIcon={<Icon path={mdiPlus} size="16px" />} onClick={onAddItem}>
							Add Parameter
						</Button>
						{level < MAX_CONDITION_NESTING && (
							<Button
								nxstyle="tertiary-light"
								startIcon={<Icon path={mdiFormatListGroupPlus} size="16px" />}
								onClick={onAddStep}
							>
								Add Condition
							</Button>
						)}
					</ButtonGroup>
					<DeleteButton onClick={onDelete}>
						<Icon path={mdiDelete} size="16px" />
					</DeleteButton>
				</OnHoverActionsGroup>
			</ActionsWrapper>
			<ChildrenWrapper>{children}</ChildrenWrapper>
		</Wrapper>
	);
}

export default ConditionBlock;
