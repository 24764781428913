// eslint-disable-next-line max-classes-per-file
import { arrayMove } from '@dnd-kit/sortable';
import type { MouseEvent, KeyboardEvent } from 'react';
import { MouseSensor as LibMouseSensor, KeyboardSensor as LibKeyboardSensor } from '@dnd-kit/core';
import { EditorElement, FlattenedItem } from './types';
import { Items } from '../../../components/ContentLibrary/ContentLibTreeView';

export const INDENTATION_WIDTH = 70;

export function flatten(items: EditorElement[], parentId: string | null = null, depth = 0): FlattenedItem[] {
	return items.reduce<FlattenedItem[]>(
		(acc, item, index) => [...acc, { ...item, parentId, depth, index }, ...flatten(item.children, item.id, depth + 1)],
		[],
	);
}

export function flattenTree(items: EditorElement[]): FlattenedItem[] {
	return flatten(items);
}

export function findItem(items: EditorElement[], itemId: string) {
	return items.find(({ id }) => id === itemId);
}

export function searchTree(tree: EditorElement[] | Items[], value: string, reverse = false) {
	const stack = [tree[0]];
	while (stack.length) {
		const node = stack[reverse ? 'pop' : 'shift']();
		if (node) {
			if (node.id === value) return node;
			if (node.children) stack.push(...node.children);
		}
	}
	return null;
}

export function buildTree(flattenedItems: FlattenedItem[]): EditorElement[] {
	const root: EditorElement = {
		id: 'root',
		children: [],
		type: 'block',
		icon: '',
	};
	const nodes: Record<string, EditorElement> = { [root.id]: root };
	const items = flattenedItems.map((item) => ({ ...item, children: [] }));

	// eslint-disable-next-line no-restricted-syntax
	for (const item of items) {
		const { id } = item;
		const parentId = item.parentId ?? root.id;
		const parent = nodes[parentId] ?? findItem(items, parentId);

		nodes[id] = item;
		parent.children.push(item);
	}

	return root.children;
}

function getMaxDepth({ previousItem }: { previousItem: FlattenedItem }) {
	if (previousItem) {
		return previousItem.depth + 1;
	}

	return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedItem }) {
	if (nextItem) {
		return nextItem.depth;
	}

	return 0;
}

function getDragDepth(offset: number, indentationWidth: number) {
	return Math.round(offset / indentationWidth);
}

export function getProjection(
	items: FlattenedItem[],
	activeId: string,
	overId: string,
	dragOffset: number,
	indentationWidth: number,
) {
	const overItemIndex = items.findIndex(({ id }) => id === overId);
	const activeItemIndex = items.findIndex(({ id }) => id === activeId);
	const activeItem = items[activeItemIndex];
	const newItems = arrayMove(items, activeItemIndex, overItemIndex);
	const previousItem = newItems[overItemIndex - 1];
	const nextItem = newItems[overItemIndex + 1];
	const dragDepth = getDragDepth(dragOffset, indentationWidth);
	const projectedDepth = (activeItem ? activeItem.depth : 0) + dragDepth;
	const maxDepth = getMaxDepth({
		previousItem,
	});
	const minDepth = getMinDepth({ nextItem });
	let depth = projectedDepth;

	if (projectedDepth >= maxDepth) {
		depth = maxDepth;
	} else if (projectedDepth < minDepth) {
		depth = minDepth;
	}

	function getParentId() {
		if (depth === 0 || !previousItem) {
			return null;
		}

		if (depth === previousItem.depth) {
			return previousItem.parentId;
		}

		if (depth > previousItem.depth) {
			return previousItem.id;
		}

		const newParent = newItems
			.slice(0, overItemIndex)
			.reverse()
			.find((item) => item.depth === depth)?.parentId;

		return newParent ?? null;
	}

	return { depth, maxDepth, minDepth, parentId: getParentId() };
}

export function removeChildrenOf(items: FlattenedItem[], ids: string[]) {
	const excludeParentIds = [...ids];

	return items.filter((item) => {
		if (item.parentId && excludeParentIds.includes(item.parentId)) {
			if (item.children.length) {
				excludeParentIds.push(item.id);
			}
			return false;
		}

		return true;
	});
}

// An extended sensor to prevent triggering dnd on element where data-no-dnd is set to true
function shouldHandleEvent(element: HTMLElement | null) {
	let cur = element;

	while (cur) {
		if (cur.dataset && cur.dataset.noDnd) {
			return false;
		}
		cur = cur.parentElement;
	}

	return true;
}

export class MouseSensor extends LibMouseSensor {
	static activators = [
		{
			eventName: 'onMouseDown' as const,
			handler: ({ nativeEvent: event }: MouseEvent) => shouldHandleEvent(event.target as HTMLElement),
		},
	];
}

export class KeyboardSensor extends LibKeyboardSensor {
	static activators = [
		{
			eventName: 'onKeyDown' as const,
			handler: ({ nativeEvent: event }: KeyboardEvent<Element>) => shouldHandleEvent(event.target as HTMLElement),
		},
	];
}
