import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';
import TextInput from '../../../../components/library/TextInput';

export const Column = styled.div<{ width?: string }>(
	({ theme, width }) => css`
		border-right: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		${width
			? css`
					width: ${width};
			  `
			: css`
					flex: 1;
			  `}
		flex-direction: column;
		padding: ${theme.spacing(2)};
	`,
);

export const BottomRow = styled.div<{ withColumnFlex?: boolean }>(
	({ theme, withColumnFlex = true }) => css`
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		border-bottom-right-radius: 8px;
		display: flex;
		flex: 1;
		& > div:last-child {
			border-bottom-right-radius: 8px;
		}
		${withColumnFlex &&
		css`
			${Column} {
				flex: 1;
			}
		`}
	`,
);

export const ChipDisplayWrapper = styled.div(
	({ theme }) => css`
		margin-left: ${theme.spacing(-1.5)};
		padding: ${theme.spacing(0.25)};
	`,
);

export const TableTextInput = styled(TextInput)<{
	hasError?: boolean;
	width?: number;
	enableFocusBorder?: boolean;
}>(
	({ theme, hasError, width, enableFocusBorder = true }) => css`
		${width &&
		css`
			width: ${width}px !important;
		`}

		input {
			border: solid 1px transparent;
			border-radius: 8px;
			padding: 0 12px;
			transition: border 0.3s ease-in-out;
		}

		.input-wrapper {
			background-color: transparent !important;
			border: transparent !important;

			${width &&
			css`
				width: ${width}px !important;
			`}

			${hasError &&
			css`
				background-color: ${theme.palette.error.background} !important;
				border: solid 1px ${theme.palette.error.main} !important;
				border-radius: 0 !important;
			`}

			&:active,
			&:focus-within,
			&:hover {
				${!hasError &&
				css`
					background-color: transparent !important;
					border: transparent !important;
				`}

				input {
					${width &&
					css`
						width: ${width}px !important;
					`}
					color: ${alpha(theme.palette.dark.main, 0.5)};
					&:focus {
						${enableFocusBorder &&
						css`
							border: solid 1px ${theme.palette.blue.main} !important;
						`}
					}

					&:focus-visible {
						outline: transparent;
					}
				}
			}
		}
	`,
);
