import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Icon from '@mdi/react';
import { mdiCog, mdiTableColumn, mdiArrowULeftBottomBold, mdiClose } from '@mdi/js';
import { IconButton, MenuList } from '@mui/material';
import Tag from '../library/Tag';
import Button from '../library/Button';
import ModalMenu, { MenuListItem } from '../library/ModalMenu';

type TableActionsProps = {
	itemsNb: number;
	onSortClick?: () => void;
	onFilterClick?: () => void;
	onSettingsClick?: () => void;
	onResetClick?: () => void;
	activeFilter?: boolean;
	resetFilter?: () => void;
	resetSort?: () => void;
	showResetFilterButton?: boolean;
	showResetSortButton?: boolean;
};

const Wrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: space-between;
		padding: ${theme.spacing(2)};
	`,
);

const SettingsIcon = styled(Icon)`
	${({ theme }) => css`
		color: ${theme.palette.text.secondary};
	`}
`;

function TableActions(props: TableActionsProps) {
	const {
		itemsNb,
		onSortClick,
		resetSort,
		showResetSortButton,
		onFilterClick,
		resetFilter,
		showResetFilterButton,
		onSettingsClick,
		onResetClick,
		activeFilter,
	} = props;
	const [openMenuModal, setOpenMenuModal] = useState<boolean>(false);
	const settingsButtonRef = useRef<HTMLButtonElement>(null);

	return (
		<Wrapper>
			<ModalMenu
				position="bottom-start"
				enableArrow={false}
				open={openMenuModal}
				anchorEl={settingsButtonRef.current}
				onClose={() => setOpenMenuModal(false)}
				content={
					<MenuList>
						{onSettingsClick ? (
							<MenuListItem
								onClick={() => {
									onSettingsClick();
									setOpenMenuModal(false);
								}}
								icon={<Icon size="18px" path={mdiTableColumn} />}
								text="Column Settings"
							/>
						) : null}
						{onResetClick ? (
							<MenuListItem
								onClick={() => {
									onResetClick();
									setOpenMenuModal(false);
								}}
								icon={<Icon size="18px" path={mdiArrowULeftBottomBold} />}
								text="Reset Table Settings"
							/>
						) : null}
					</MenuList>
				}
			/>
			<Tag>{itemsNb} records found</Tag>
			<div>
				<div style={{ display: 'inline', position: 'relative', marginRight: '80px' }}>
					<Button
						onClick={onSortClick ? () => onSortClick() : undefined}
						nxstyle="tertiary-light"
						startIcon={<FilterListIcon />}
						disabled={!onSortClick}
						style={{ paddingRight: showResetSortButton ? '28px' : '12px' }}
					>
						Sort
					</Button>
					{showResetSortButton && (
						<Button
							style={{ position: 'absolute', right: 0, paddingRight: '2px', paddingLeft: '2px' }}
							$disablePadding
							nxstyle="tertiary-light"
							onClick={(e) => {
								e.stopPropagation();
								if (resetSort) {
									resetSort();
								}
							}}
						>
							<Icon path={mdiClose} size="20px" />
						</Button>
					)}
				</div>
				<div style={{ display: 'inline', position: 'relative' }}>
					<Button
						onClick={onFilterClick ? () => onFilterClick() : undefined}
						nxstyle="tertiary-light"
						startIcon={<FilterAltIcon />}
						disabled={!onFilterClick}
						$forceActive={activeFilter}
						style={{ paddingRight: showResetFilterButton ? '28px' : '12px' }}
					>
						Filter
					</Button>
					{showResetFilterButton && (
						<Button
							style={{ position: 'absolute', right: 0, paddingRight: '2px', paddingLeft: '2px' }}
							$disablePadding
							nxstyle="tertiary-light"
							onClick={(e) => {
								e.stopPropagation();
								if (resetFilter) {
									resetFilter();
								}
							}}
						>
							<Icon path={mdiClose} size="20px" />
						</Button>
					)}
				</div>
			</div>
			{onSettingsClick || onResetClick ? (
				<IconButton ref={settingsButtonRef} onClick={() => setOpenMenuModal(true)}>
					<SettingsIcon path={mdiCog} size="20px" />
				</IconButton>
			) : null}
		</Wrapper>
	);
}

export default TableActions;
