import { addSelectedParent, indexedTitleToTitleCommon } from '@warehouse/title/infra';
import React, { useMemo } from 'react';
import { TitleFull as Title } from '@warehouse/title/core';
import { PaginationNavigator, PaginatorProvider } from '@warehouse/shared/ui';
import styled, { css } from 'styled-components';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { LoaderWrapper } from '../../../src/components/library/LoaderWrapper';
import getDeepProperty from '../../../src/utils/getDeepProperty';
import useWorkTypes from '../../../src/utils/hooks/titles/useWorkTypes';
import { fromBasic } from '../../../src/utils/titleGetProperty';
import { useSearchChildrenQuery } from '../domain/useSearchChildrenQuery';
import { SearchProvider } from '../../shared/ui/search/search.store';
import { ChildrenColumnType } from './children-compilation-extended.model';
import { computeWarnings } from './computeWarnings';
import { mapEntryClassAndEntryNumberToData } from './helpers/map-entry-class-and-entry-number-to-data';
import { useChildrenColumnsPerWorkType } from './useChildrenColumnsPerWorkType';
import { SearchInput } from '../../shared/ui/search/SearchInput';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../src/components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { useEditEntryNumber, UseEditEntryNumberOutput } from './inline-edit/entry-number/use-edit-entry-number';

interface ChildrenListProps {
	label: string;
	title: Title;
	relationshipType?: string;
	searchInputAction?: React.ReactNode;
	onChildrenClick?: (child: IndexedTitleV2) => void;
	editMode: boolean;
}

function ChildrenListComp({
	label,
	editMode,
	title,
	searchInputAction,
	relationshipType,
	onChildrenClick,
}: ChildrenListProps) {
	const workTypes = useWorkTypes();
	const workTypeUuid = useMemo(() => getDeepProperty<string>(title, fromBasic(['workType'])), [title]);
	const titleStatus = useMemo(
		() => getDeepProperty<string>(title, fromBasic(['terms', 'titleStatus', 'displayValue'])),
		[title],
	);

	const [columns, searchFields] = useChildrenColumnsPerWorkType(workTypeUuid!, relationshipType);

	const { data, loading } = useSearchChildrenQuery({
		titleUuid: title.uuid,
		searchFields,
		showHiddenTitles: titleStatus === 'hidden',
	});

	const adaptedData = useMemo(() => (
		workTypeUuid === workTypes.compilationUuid()
			? mapEntryClassAndEntryNumberToData(data, title)
			: data.map((child) => addSelectedParent(indexedTitleToTitleCommon(child), title.uuid))
	), [title, workTypes, workTypeUuid, data]);

	const editEntryNumber = useEditEntryNumber({ parentTitle: title });

	return (
		<>
			<TopRow>
				<SearchInput label={label} />
				{searchInputAction}
			</TopRow>
			<LoaderWrapper
				loading={loading}
				opacity={0.3}
				style={{ flex: 1 }}
			>
				<InheritanceOnValueChangeSimpleTableWrapper<ChildrenColumnType>
					paddedCells
					columns={columns}
					editMode={editMode}
					data={adaptedData}
					tableStyle="border"
					forceHover
					customPropsCell={{ editEntryNumber } satisfies {editEntryNumber: UseEditEntryNumberOutput|null}}
					warnings={computeWarnings(adaptedData)}
					onClickRow={(row: IndexedTitleV2) => onChildrenClick?.(row)}
				/>
			</LoaderWrapper>
			<PaginationNavigator centered storageUniqueKey="children-list" />
		</>
	);
}

export function ChildrenList({
	label,
	title,
	searchInputAction,
	relationshipType,
	onChildrenClick,
	editMode,
}: ChildrenListProps) {
	return (
		<SearchProvider>
			<PaginatorProvider>
				<ChildrenListComp
					label={label}
					title={title}
					editMode={editMode}
					searchInputAction={searchInputAction}
					relationshipType={relationshipType}
					onChildrenClick={onChildrenClick}
				/>
			</PaginatorProvider>
		</SearchProvider>
	);
}

const TopRow = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(2)};
		width: 100%;
	`,
);
