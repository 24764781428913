import { useContext, useEffect, useMemo } from 'react';
import { paginatorStoreSelector, usePaginatorStore } from '@warehouse/shared/ui';
import { TitleSearchCriteria, TitleSearchQuery, TitleSearchQueryContext } from '@warehouse/title/core';
import useSearchTitlesV2 from '../../../src/utils/hooks/titles/useSearchTitlesV2';
import { CommonTitleFilters } from './CommonTitleFilters';

const POLL_INTERVAL = 500;
const SEARCH_FIELDS: string[] = ['licensor.label', 'titleSearchDisplayNameFull', 'releaseDate', 'workType.label'];

interface UseTitleSearchProps {
	searchFields?: string[];
	searchCriteria: TitleSearchCriteria;
	skip?: boolean;
	showHiddenTitles?: boolean;
}

export function useTitleSearchQuery({
	skip = false,
	searchCriteria,
	searchFields = SEARCH_FIELDS,
	showHiddenTitles = false,
}: UseTitleSearchProps) {
	const actions = usePaginatorStore(paginatorStoreSelector.actions);
	const page = usePaginatorStore(paginatorStoreSelector.page);
	const perPage = usePaginatorStore(paginatorStoreSelector.perPage);

	const filtering: FilterStep | undefined = useMemo(
		() => ({
			step: {
				combinationOperator: 'AND',
				filters: [
					...(showHiddenTitles ? [] : [CommonTitleFilters.excludeHiddenTitles()]),
					...(searchCriteria.workTypeUuids ? [getWorkTypeFilterStep(searchCriteria.workTypeUuids)] : []),
					...(searchCriteria.licensorDisplayName
						? [CommonTitleFilters.searchByLicensorDisplayName(searchCriteria.licensorDisplayName)]
						: []),
				],
			},
		}),
		[searchCriteria.licensorDisplayName, searchCriteria.workTypeUuids, showHiddenTitles],
	);

	const { data, loading, networkStatus } = useTitleSearchQueryImpl({
		perPage,
		page,
		pollInterval: POLL_INTERVAL,
		searchFields,
		debouncedSearch: searchCriteria.searchValue,
		filtering,
		sorting: useMemo(() => [{ id: 'titleSearchSortFull', desc: false }], []),
		skip,
	});

	useEffect(() => {
		if (data?.searchTitlesV2?.page?.totalPage) actions.setTotalPages(data?.searchTitlesV2?.page?.totalPage);
		if (data?.searchTitlesV2?.page?.totalDocument) actions.setTotalCount(data?.searchTitlesV2?.page?.totalDocument);
	}, [actions, data?.searchTitlesV2?.page.totalPage, data?.searchTitlesV2?.page.totalDocument]);

	return {
		networkStatus,
		data,
		loading,
	};
}

function getWorkTypeFilterStep(workTypeUuids: string[]): Filter | FilterStep {
	if (workTypeUuids.length > 1) {
		return {
			step: {
				combinationOperator: 'OR',
				filters: workTypeUuids.map((workTypeUuid) => ({
					value: workTypeUuid,
					field: 'workType.uuid',
					operator: 'equals',
				})),
			},
		};
	}
	return {
		value: workTypeUuids[0],
		field: 'workType.uuid',
		operator: 'equals',
	};
}

const useTitleSearchQueryImpl: TitleSearchQuery = (args) => {
	const searchTitleRepositoryContext = useContext(TitleSearchQueryContext);
	const useRepo: TitleSearchQuery = searchTitleRepositoryContext ?? useSearchTitlesV2;
	return useRepo(args);
};
