import React from 'react';
import { StyledLink } from '../../../shared/ui/StyledLink';

export function TitleCardLink({
	fullWidth = false,
	uuid,
	children,
}: {
	fullWidth?: boolean;
	uuid: string;
	children: React.ReactNode;
}) {
	return (
		<StyledLink onClick={(e) => e.stopPropagation()} tabIndex={-1} to={`/title/${uuid}`} fullWidth={fullWidth}>
			{children}
		</StyledLink>
	);
}
