import { Typography } from '@mui/material';
import React from 'react';
import { DropdownV2 } from '@warehouse/shared/ui';
import { TitleInfoWrapper } from './styles';
import availableWorkTypeDetailsByWorkType from '../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import sortAlphabeticallyByLabel from '../../../utils/sortAlphabeticallyByLabel';

interface WorkTypeDetailsSelectorProps {
	workTypeName: string | undefined;
	workTypeDetails: string | undefined;
	setWorkTypeDetails: (workTypeDetails: string | undefined) => void;
	loading?: boolean;
}

function WorkTypeDetailsSelector({
	workTypeName,
	workTypeDetails,
	setWorkTypeDetails,
	loading = false,
}: WorkTypeDetailsSelectorProps) {
	const workTypeDetailsFromJson =
		availableWorkTypeDetailsByWorkType[workTypeName as keyof typeof availableWorkTypeDetailsByWorkType];

	const label = workTypeDetailsFromJson?.workTypeDetailLabel;
	const options = workTypeDetailsFromJson?.availableWorkTypeDetails;
	const sortedOptions = options?.sort(sortAlphabeticallyByLabel);

	if (!label) return null;

	return (
		<TitleInfoWrapper>
			<Typography variant="s2Medium" color="text.primary">
				{label}* :
			</Typography>
			<div style={{ width: '150px' }}>
				<DropdownV2
					withSearch
					height={40}
					error={!loading && !workTypeDetails ? 'Required' : undefined}
					value={workTypeDetails || null}
					onChange={(rt) => setWorkTypeDetails(rt || undefined)}
					options={sortedOptions}
				/>
			</div>
		</TitleInfoWrapper>
	);
}

export default WorkTypeDetailsSelector;
