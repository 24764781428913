import React, { useCallback, useEffect, useMemo } from 'react';

// TYPES
import { CellContext } from '@tanstack/react-table';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { AssociatedOrg } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';

// HOOKS
import useBlurAndDebounceValue from '../../../../../hooks/useTitleRecordsAutoSave/useBlurAndDebounceValue';

// LIBRARY
import { TableTextInput } from '../../../style';
import { CellWrapper } from '../../../../../../../components/library/SimpleTable/style';

function DisplayNameCell(info: CellContext<AssociatedOrg, AssociatedOrg>) {
	const { getValue, row, table } = info;
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options?.meta?.customPropsCell.isInherited;

	const {
		value,
		setValue: setDebounceValue,
		commit: commitDebounceValue,
	} = useBlurAndDebounceValue({
		initialValue: getValue()?.displayName || '',
		save: useCallback(
			(v: string) => {
				setValue((prev: OptionalInherited<AssociatedOrg[]>) => ({
					...prev,
					displayValue:
						prev.displayValue?.map((ao, index) => {
							if (index === row.index) {
								return {
									...ao,
									displayName: v ?? '',
								};
							}
							return ao;
						}) ?? [],
				}));
				commit();
			},
			[setValue, commit, row.index],
		),
		delay: 500,
	});

	useEffect(() => {
		if (getValue().displayName !== value) setDebounceValue(getValue().displayName ?? '', false);
	}, [getValue().displayName]);

	return useMemo(
		() => (
			<CellWrapper>
				<TableTextInput
					variant={isInherited ? 'prefilled' : 'default'}
					autoFocus={value === ''}
					onBlur={() => commitDebounceValue()}
					value={value}
					onChange={(evt) => setDebounceValue(evt.target.value)}
				/>
			</CellWrapper>
		),
		[value, setDebounceValue, commitDebounceValue, row.index],
	);
}

export default DisplayNameCell;
