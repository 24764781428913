import React, { useCallback, useEffect, useMemo } from 'react';
import { CellContext } from '@tanstack/react-table';

// TYPES
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { TitleAlternate } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';

// HOOKS
import useBlurAndDebounceValue from '../../../../../src/views/title/hooks/useTitleRecordsAutoSave/useBlurAndDebounceValue';

// LIBRARY
import { TableTextInput } from '../../../../../src/views/title/tab/productMetadata/style';
import { CellWrapper } from '../../../../../src/components/library/SimpleTable/style';

function TitleCell(info: CellContext<TitleAlternate, TitleAlternate>) {
	const { getValue, row, table } = info;
	const setValue = table.options?.meta?.customPropsCell?.setValue;
	const commit = table.options?.meta?.customPropsCell?.commit;
	const isInherited = table.options?.meta?.customPropsCell?.isInherited;

	const {
		value,
		setValue: setDebounceValue,
		commit: commitDebounceValue,
	} = useBlurAndDebounceValue({
		initialValue: getValue()?.titleAlternate || '',
		save: useCallback(
			(v: string) => {
				setValue((prev: OptionalInherited<TitleAlternate[]>) => ({
					...prev,
					displayValue:
						prev.displayValue?.map((ta, index) => {
							if (index === row.index) {
								return {
									...ta,
									titleAlternate: v ?? '',
								};
							}
							return ta;
						}) ?? [],
				}));
				commit();
			},
			[setValue, commit, row.index],
		),
		delay: 500,
	});

	useEffect(() => {
		if (getValue().titleAlternate !== value) setDebounceValue(getValue().titleAlternate, false);
	}, [getValue().titleAlternate]);

	return useMemo(
		() => (
			<CellWrapper>
				<TableTextInput
					variant={isInherited ? 'prefilled' : 'default'}
					autoFocus={!value}
					placeholder={value}
					onBlur={() => commitDebounceValue()}
					value={value}
					onChange={(evt) => setDebounceValue(evt.target.value)}
				/>
			</CellWrapper>
		),
		[value, setDebounceValue, commitDebounceValue, row.index],
	);
}

export default TitleCell;
