import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_POSTER } from '../../../graphql/queries/poster';

function usePoster({ uuid }: { uuid: string }) {
	const { loading, error, data, refetch } = useQuery<
		{
			getPoster: {
				backdrop: string;
				poster: string;
			};
		},
		{
			uuid: string;
		}
	>(GET_POSTER, {
		variables: {
			uuid,
		},
	});

	useEffect(() => {
		refetch({
			uuid,
		});
	}, [uuid]);

	return {
		loading,
		error,
		data: data?.getPoster,
	};
}

export default usePoster;
