import React, { useState, useMemo, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Divider, Typography } from '@mui/material';
import { mdiMagnify, mdiClose, mdiPlus, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import Card from '../../../components/library/Card';
import TextInput from '../../../components/library/TextInput';
import Button from '../../../components/library/Button';
// Hooks
import useWindowSize, { Size } from '../../../utils/hooks/useWindowSize';
import { SearchInput } from '../../../graphql/codegen/graphql';
import useDebounce from '../../../utils/hooks/useDebounce';
import {
	dataToFilterFields,
	FilterField,
	FilterStepWithId,
	prepareDataForAPI,
} from '../../../utils/table/filtersUtils';
import { useStateToURLSync, useURLToStateSync } from '../../../utils/hooks/useURLQuery';
import useSearchProfilesAdmin, {
	useAllSearchProfilesAdmin,
} from '../../../utils/hooks/profiles/useSearchProfilesAdmin';
import Alert from '../../../components/library/Alert';
import FullTable from '../../../components/table/FullTable';
import columns from './table/columns';

const Wrapper = styled.div(
	() => css`
		background-color: #dde0e3;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		width: 100%;
	`,
);

const CardWrapper = styled.div(
	({ theme }) => css`
		box-sizing: border-box;
		display: flex;
		padding: ${theme.spacing(4)};
		width: calc(100% - 40px);
	`,
);

const TopPageWrapper = styled.div(
	({ theme }) => css`
		padding: ${theme.spacing(2)};
	`,
);

const TopActionWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: space-between;
		padding-top: ${theme.spacing(2)};
	`,
);

const TopActionButtonWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: ${theme.spacing(2)};
		margin: 14px;
	`,
);

const SearchBar = styled(TextInput)(
	() => css`
		width: 50%;
	`,
);

function ProfilesList() {
	const POLL_INTERVAL = 8000;
	const [searchInput, setSearchInput] = useState('');
	const debouncedSearch = useDebounce(searchInput, 500);
	const initialFilterState: FilterStep | undefined = undefined;
	const size: Size = useWindowSize();
	const wrapperEl = useRef<HTMLDivElement>(null);

	// Refs to get Table Wrapper height for table scroll
	const cardEl = useRef<HTMLDivElement>(null);
	const topPageWrapperEl = useRef<HTMLDivElement>(null);
	const [tableHeight, setTableHeight] = useState(0);

	// Filters
	const [filtering, setFiltering] = useState<FilterStepWithId | undefined>(initialFilterState);
	const [curatedFilters, setCuratedFilters] = useState<FilterStep | undefined>(initialFilterState);

	// Set state on load if URL Queries are set
	useURLToStateSync<string>('search', (v) => setSearchInput(v!));
	useURLToStateSync<FilterStepWithId | undefined>('filters', setFiltering);
	// Set URL Queries on state change
	useStateToURLSync(filtering, initialFilterState, 'filters');
	useStateToURLSync(debouncedSearch, '', 'search');

	// Dynamic table height
	useEffect(() => {
		const cardChildren = cardEl?.current?.children[0];
		if (topPageWrapperEl.current && cardChildren) {
			setTableHeight(cardChildren.clientHeight - topPageWrapperEl.current.clientHeight);
		}
	}, [topPageWrapperEl?.current?.clientHeight, cardEl?.current?.children[0].clientHeight, size]);

	// Base Search params
	const baseSearchParams: SearchInput = {
		pagination: { page: 1, perPage: 1000 },
	};

	const { error, data, networkStatus } = useSearchProfilesAdmin({
		baseSearchParams,
		debouncedSearch,
		filtering: curatedFilters,
		pollInterval: POLL_INTERVAL,
	});
	const allProfilesNoFilter = useAllSearchProfilesAdmin({
		pollInterval: 30000,
	});

	// Filters Fields
	const [filtersFields, setFiltersFields] = useState<FilterField[]>([]);

	useEffect(() => {
		if (!allProfilesNoFilter.loading && allProfilesNoFilter.data?.searchProfilesAdmin.documents) {
			const profiles = allProfilesNoFilter.data?.searchProfilesAdmin.documents;
			setFiltersFields(
				// TODO : Change this any
				dataToFilterFields<any>(profiles, [
					{
						id: 'id',
						label: 'ID',
						type: 'string',
						accessorFn: (i) => i?.document.id.toString(),
					},
					{
						id: 'name',
						label: 'Name',
						type: 'string',
						accessorFn: (i) => i?.document.name,
					},
					{
						id: 'versionMetadata.status',
						label: 'Status',
						type: 'string',
						options: [
							{
								label: 'Published',
								value: 'published',
							},
							{
								label: 'Unpublished',
								value: 'unpublished',
							},
							{
								label: 'Draft',
								value: 'draft',
							},
						],
					},
				]),
			);
		}
	}, [allProfilesNoFilter.loading]); // , allProfilesNoFilter.data]); TODO : This fields must be in the dependency array

	// Curate filters from Front end properties for API
	useEffect(() => {
		if (filtersFields && filtering) setCuratedFilters(prepareDataForAPI({ ...filtering }, filtersFields));
		if (!filtering) setCuratedFilters(undefined);
	}, [filtersFields, filtering]);

	const table = useMemo(
		() => (
			<FullTable<any> // TODO : Change this type
				cookiesName="profiles"
				initialSorting={[{ id: 'name', desc: false }]}
				columns={columns}
				data={data?.searchProfilesAdmin.documents as unknown as any} // TODO : Change this type conversion
				height={tableHeight}
				filtering={filtering}
				filterFields={filtersFields}
				setFiltering={setFiltering}
				networkStatus={networkStatus}
			/>
		),
		[networkStatus, error, data, tableHeight, filtersFields, filtering, setFiltering],
	);

	return (
		<Wrapper ref={wrapperEl}>
			<CardWrapper ref={cardEl}>
				<Card sx={{ minWidth: 275 }}>
					<TopPageWrapper ref={topPageWrapperEl}>
						<Typography variant="h2Regular">Avail Profiles</Typography>
						<TopActionWrapper>
							<SearchBar
								placeholder="Search avaible profiles"
								startIcon={<Icon path={mdiMagnify} size="24px" />}
								endIcon={
									<Button $disablePadding nxstyle="tertiary-light" onClick={() => setSearchInput('')}>
										<Icon path={mdiClose} size="16px" />
									</Button>
								}
								value={searchInput}
								onChange={(event) => setSearchInput(event.target.value)}
							/>
							<TopActionButtonWrapper>
								<Button
									nxstyle="cta"
									size="large"
									$fullHeight
									startIcon={<Icon path={mdiPlus} size="20px" />}
									onClick={() => {}}
								>
									New Avail Profile
								</Button>
								<Button
									nxstyle="secondary-blue"
									size="large"
									$fullHeight
									endIcon={<Icon path={mdiOpenInNew} size="20px" />}
								>
									Export
								</Button>
							</TopActionButtonWrapper>
						</TopActionWrapper>
					</TopPageWrapper>
					<Divider />
					{error && <Alert severity="error">{error.message}</Alert>}
					{table}
				</Card>
			</CardWrapper>
		</Wrapper>
	);
}

export default ProfilesList;
