import React, { useState, useEffect, ReactNode } from 'react';
import { alpha, Grid, Typography } from '@mui/material';
import { Icon } from '@mdi/react';
import { mdiPageFirst, mdiChevronLeft, mdiChevronRight, mdiPageLast } from '@mdi/js';
import styled, { css } from 'styled-components';
import { DropdownV2 } from '@warehouse/shared/ui';
import Button from './Button';

type PaginationProps = {
	value: number;
	lastPage: number;
	onChange: (value: number) => void;
};

const CustomButton = styled(Button)`
	${({ theme }) => css`
		svg {
			color: ${alpha(theme.palette.dark.main, 0.5)};
		}
	`}
`;

function Pagination({ value, lastPage, onChange }: PaginationProps) {
	const [optionArray, setOptionArray] = useState<{ label: string | ReactNode; value: string | null }[]>([]);

	// Creating options with lastPage value
	useEffect(() => {
		if (lastPage && lastPage > 0) {
			const tmpArray = Array.from({ length: lastPage }, (_, i) => ({
				label: (i + 1).toString(),
				value: (i + 1).toString(),
			}));
			setOptionArray(tmpArray);
		}
	}, [lastPage]);

	return (
		<Grid container direction="row" justifyContent="center" alignItems="center" spacing="12px">
			<Grid item>
				<CustomButton
					nxstyle="tertiary-light"
					size="large"
					onClick={() => (value && value > 1 ? onChange(1) : null)}
					disabled={value <= 1}
				>
					<Icon size={1} path={mdiPageFirst} />
				</CustomButton>
			</Grid>
			<Grid item>
				<Button
					nxstyle="tertiary-light"
					disabled={value <= 1}
					size="large"
					startIcon={<Icon size={1} path={mdiChevronLeft} />}
					onClick={() => (value && value > 1 ? onChange(value - 1) : null)}
				>
					Previous
				</Button>
			</Grid>
			<Grid item>
				{optionArray.length && (
					<DropdownV2
						withSearch={false}
						options={optionArray}
						value={value.toString()}
						onChange={(e) => (e ? onChange(parseInt(e, 10)) : null)}
						height={32}
						listItemHeight={32}
						disabledMargin
					/>
				)}
			</Grid>
			<Grid item>
				<Typography fontWeight={500}>of {lastPage}</Typography>
			</Grid>
			<Grid item>
				<Button
					size="large"
					nxstyle="tertiary-light"
					endIcon={<Icon size={1} path={mdiChevronRight} />}
					disabled={value >= lastPage}
					onClick={() => (value + 1 <= lastPage ? onChange(value + 1) : null)}
				>
					Next
				</Button>
			</Grid>
			<Grid item>
				<CustomButton
					nxstyle="tertiary-light"
					size="large"
					disabled={value >= lastPage}
					onClick={() => onChange(lastPage)}
				>
					<Icon size={1} path={mdiPageLast} />
				</CustomButton>
			</Grid>
		</Grid>
	);
}

export default Pagination;
