import { useCallback, useEffect, useMemo, useState } from 'react';

export type Placement = 'top' | 'bottom';

export function useTopBottomObserver(internalDropdownRef: HTMLElement | null) {
	const [placement, setPlacement] = useState<Placement>();

	// Compute a ref up to popup container
	const antDropdownRef = useMemo(() => {
		if (!internalDropdownRef) return null;
		return internalDropdownRef.parentElement?.parentElement ?? null;
	}, [internalDropdownRef]);

	const callback = useCallback(
		(records: MutationRecord[]) => {
			let computedPlacement: Placement | undefined;
			records.forEach((record) => {
				if (
					record.type !== 'attributes' ||
					record.attributeName !== 'class' ||
					record.target.nodeType !== Node.ELEMENT_NODE
				)
					return;
				const { classList } = record.target as HTMLElement;
				if (
					classList.contains('ant-select-dropdown-placement-topLeft') ||
					classList.contains('ant-select-dropdown-placement-topRight')
				) {
					computedPlacement = 'top';
				} else {
					computedPlacement = 'bottom';
				}
			});
			if (computedPlacement) setPlacement(computedPlacement);
		},
		[setPlacement],
	);
	const observer = useMemo(() => new MutationObserver(callback), [callback]);

	useEffect(() => {
		if (antDropdownRef) observer.observe(antDropdownRef, { attributes: true, attributeFilter: ['class'] });
		return () => {
			if (antDropdownRef) observer.disconnect();
		};
	}, [observer, antDropdownRef]);

	return placement;
}
