import { useContext, useEffect, useMemo } from 'react';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { JsonPointer } from '../../../../utils/getDeepProperty';
import { nestedRecordToArray, WithUuid } from './recordToArray';
import { getTitleData } from '../../../../utils/titleGetProperty';
import useControl from '../useControl';
import useEdit from './useEdit';
import useRemove from './useRemove';
import { dataToState } from './common';
import useAdd from './useAdd';
import { TitleAutoSaveQueueContext } from '../../contexts/TitleAutoSaveQueueContext';

type UseTitleRecordsAutoSaveProps<T> = {
	path: JsonPointer;
	label: string;
	isValidRow: (row: T) => boolean;
};

export type UseTitleRecordsAutoSaveOutput<T> = {
	readOnlyValue: WithUuid<T>[];
	data: WithUuid<T>[];
	addRecord: (data: T) => void;
	removeRecord: (uuids: string[]) => void;
	editRecord: (data: WithUuid<T>, fieldToEdit?: string) => void;
};

function useTitleRecordsAutoSave<T>({
	label,
	path,
	isValidRow,
}: UseTitleRecordsAutoSaveProps<T>): UseTitleRecordsAutoSaveOutput<T> {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { addToQueue } = useContext(TitleAutoSaveQueueContext);
	const { value, setValue, dirtyValue, setDirtyValue } = useControl<WithUuid<T>[]>(
		path.join('.'),
		dataToState<T>(title, path),
	);
	const useOperationProps = useMemo(
		() => ({
			value,
			setValue,
			setDirtyValue,
			title,
			path,
			isValidRow,
			label,
			addToQueue,
		}),
		[value, setValue, setDirtyValue, title, path, isValidRow, label],
	);
	const { editRecord } = useEdit<T>(useOperationProps);
	const { removeRecord } = useRemove<T>(useOperationProps);
	const { addRecord } = useAdd<T>(useOperationProps);

	useEffect(() => {
		setValue(dataToState<T>(title, path));
	}, [path]);

	return {
		readOnlyValue: nestedRecordToArray(getTitleData<Record<string, T>>(title, path)),
		removeRecord,
		addRecord,
		editRecord,
		data: dirtyValue || value,
	};
}

export default useTitleRecordsAutoSave;
