import { TitleIndexedParent, TitleIndexedSequenceInfo } from '@warehouse/graphql';
import { RelationshipType, TitleLightParent, TitleLightSequenceInfo } from '@warehouse/title/core';

export function relationshipTypeToEnum(rt: string): RelationshipType {
	const item = Object.values(RelationshipType).find((value) => value === rt);
	if (!item) throw new Error(`Unknown relationship type ${rt}`);
	return item;
}

export function titleIndexedSequenceInfoToTitleLightSequenceInfo(
	sequenceInfo?: TitleIndexedSequenceInfo,
): TitleLightSequenceInfo {
	return {
		distributionNumber: sequenceInfo?.distributionNumber?.distributionNumber,
		number: sequenceInfo?.number ?? undefined,
		houseSequence: sequenceInfo?.houseSequence?.houseSequence,
	};
}

export function titleIndexedParentToTitleLightParent(parent: TitleIndexedParent): TitleLightParent {
	const primary = parent.primary ?? false;

	if (!parent.relationshipType) throw new Error(`Missing relationshipType in parent ${parent}`);

	return {
		contentId: parent.parentContentId,
		primary,
		relationshipType: relationshipTypeToEnum(parent.relationshipType),
		relationshipSubtypes: parent.relationshipSubtypes?.map((subType) => subType.uuid) ?? undefined,
		sequenceInfo: titleIndexedSequenceInfoToTitleLightSequenceInfo(parent.sequenceInfo ?? undefined),
	};
}
