import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth0, PopupCancelledError, PopupTimeoutError } from '@auth0/auth0-react';

import { Outlet } from 'react-router-dom';

import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { apolloAdapter } from '@warehouse/shared/infra';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthLoader, AuthLoaderErrorMessage } from './components/auth/AuthLoader';
import { EditorProvider } from './views/profiles/editor/EditorContext';
import Navigation from './components/library/Navigation';
import CustomSnackbar from './components/library/CustomSnackbar';

const AppWrapper = styled.div`
	display: flex;
	flex-basis: 100vh;
	flex-direction: column;
	height: 100vh;
`;

const Wrapper = styled.div`
	align-items: stretch;
	box-sizing: border-box;
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	position: relative;
`;

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-success': {
		backgroundColor: '#DBEFDC',
		color: '#004D03',
		fontFamily: 'Roboto',
		fontWeight: 400,
		boxShadow: 'rgba(0,0,0, 0.2) 0px 4px 12px',
		borderRadius: '8px',
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: '#FFDCDC',
		color: '#8C0000',
		fontFamily: 'Roboto',
		fontWeight: 400,
		boxShadow: 'rgba(0,0,0, 0.2) 0px 4px 12px',
		borderRadius: '8px',
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: '#FEF0CC',
		color: '#8C5E00',
		fontFamily: 'Roboto',
		fontWeight: 400,
		boxShadow: 'rgba(0,0,0, 0.2) 0px 4px 12px',
		borderRadius: '8px',
	},
}));

function App() {
	const { isLoading, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, logout } = useAuth0();
	const [token, setToken] = useState<string | null>(null);
	const [getTokenError, setGetTokenError] = useState<AuthLoaderErrorMessage | null>(null);

	const getAccessToken = useCallback(async () => {
		try {
			const silentToken = await getAccessTokenSilently();
			if (silentToken) return silentToken;
		} catch (e: any) {
			// Do nothing here, we will retry with getAccessTokenWithPopup
			// This is to handle cases where the access token can be still be refreshed
			// but would require user interaction (organisation selection, MFA, ...)
		}
		const popupToken = await getAccessTokenWithPopup();
		if (popupToken) return popupToken;
		throw new Error('Unexpected error during authentication');
	}, [getAccessTokenSilently, getAccessTokenWithPopup]);

	const retrieveToken = useCallback(async () => {
		try {
			const res = await getAccessToken();
			if (res) {
				apolloAdapter.setToken(res);
				setToken(res);
			}
		} catch (err: any) {
			if (err instanceof Error && err.message.includes('window.open returned `null`')) {
				setGetTokenError({ message: 'The authorization window could not be opened.', retryable: true });
			} else if (err instanceof PopupCancelledError) {
				setGetTokenError({ message: 'The authorization window was closed.', retryable: true });
			} else if (err instanceof PopupTimeoutError) {
				setGetTokenError({ message: 'The authorization window has timed out.', retryable: true });
			} else {
				setGetTokenError({ message: err.message ?? err.toString() ?? 'Unexpected error occurred.', retryable: false });
			}
		}
	}, [getAccessToken]);

	useEffect(() => {
		if (isAuthenticated && !isLoading) retrieveToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [retrieveToken]);

	if (token) {
		return (
			<QueryClientProvider client={new QueryClient()}>
				<CookiesProvider>
					<EditorProvider>
						<ApolloProvider client={apolloAdapter.client}>
							<SnackbarProvider
								Components={{
									success: StyledMaterialDesignContent,
									error: StyledMaterialDesignContent,
									warning: StyledMaterialDesignContent,
									customSnackbar: CustomSnackbar,
								}}
								maxSnack={5}
							>
								<AppWrapper className="App">
									<Navigation />
									<Wrapper>
										<Outlet />
									</Wrapper>
								</AppWrapper>
							</SnackbarProvider>
						</ApolloProvider>
					</EditorProvider>
				</CookiesProvider>
			</QueryClientProvider>
		);
	}
	return (
		<AuthLoader
			isLoading={!getTokenError}
			errorMessage={getTokenError ?? undefined}
			onRetry={() => {
				setGetTokenError(null);
				retrieveToken();
			}}
			onLogout={() => logout()}
		/>
	);
}

export default App;
