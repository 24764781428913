import styled, { css } from 'styled-components';

const Column = styled.div(
	({ theme }) => css`
		border-right: 1px solid ${theme.palette.light.backgroundAlt};
		height: 100vh;
		width: 100%;
	`,
);

export default Column;
