import { InheritanceType } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import lodash from 'lodash';

import { OptionalNullInherited } from './models/inherited';

export function isInherited<T>(args: { inheritedObject: OptionalNullInherited<T> | null | undefined }): {
	isInherited: boolean;
} {
	if (!args.inheritedObject) return { isInherited: false };
	const { inheritedObject } = args;

	if (inheritedObject.displayValue === undefined || inheritedObject.inherited?.value === undefined) {
		return { isInherited: false };
	}

	return {
		isInherited: lodash.isEqual(inheritedObject.displayValue, inheritedObject.inherited.value),
	};
}

export function isUnknownInherited(input: unknown): boolean {
	if (!isUnknownOptionalInherited(input)) return false;

	return isInherited({ inheritedObject: input }).isInherited;
}

function isUnknownOptionalInherited(input: unknown): input is OptionalNullInherited<unknown> {
	if (typeof input !== 'object' || input === null) {
		return false;
	}

	if (!('inherited' in input)) return false;

	return !(typeof input.inherited !== 'object' || input.inherited === null);
}

export function updateValue<VALUE_TYPE, INPUT_TYPE = VALUE_TYPE>(
	newValue: INPUT_TYPE,
	currentValue: OptionalNullInherited<VALUE_TYPE> | undefined,
	options?: {
		transform?: (value: INPUT_TYPE) => VALUE_TYPE;
	},
): OptionalNullInherited<VALUE_TYPE> {
	const transformedValue: VALUE_TYPE = options?.transform
		? options.transform(newValue)
		: (newValue as INPUT_TYPE extends VALUE_TYPE ? INPUT_TYPE : never);

	if (!currentValue)
		return {
			explicitValue: transformedValue ?? null,
			displayValue: transformedValue ?? null,
			inherited: {
				type: 'waitingForInheritance' as InheritanceType,
			},
		};

	return {
		...currentValue,
		explicitValue: transformedValue ?? null,
		displayValue: transformedValue ?? currentValue.inherited?.value ?? null,
	};
}
