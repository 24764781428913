import { Typography } from '@mui/material';
import React from 'react';
import { TextInput } from '@warehouse/shared/legacy-ui';
import { FieldWrapper } from '../../ui/FieldWrapper';

export function HouseSequenceField({
	houseSequence,
	onHouseSequenceChange,
	onBlur,
	placeholder,
}: HouseSequenceFieldProps) {
	return (
		<FieldWrapper className="field-wrapper">
			<Typography variant="buttonMediumMedium" color="text.primary">
				House Sequence
			</Typography>
			<TextInput
				ariaLabel="House Sequence"
				value={houseSequence}
				onChange={(e) => onHouseSequenceChange(e.target.value)}
				required={false}
				onBlur={onBlur}
				disabledMargin
				placeholder={placeholder}
			/>
		</FieldWrapper>
	);
}

type HouseSequenceFieldProps = {
	houseSequence: string | undefined;
	onHouseSequenceChange: (houseSequence: string | undefined) => void;
	onBlur?: () => void;
	placeholder?: string;
};
