import React from 'react';
import styled, { css } from 'styled-components';
import { ConditionBlockContainer, ConditionBlockNameText } from './ConditionBlock';
import { NodeItemBody, NodeItemContainer } from './NodeItem';
import { useEditorDispatch } from '../../views/profiles/editor/EditorContext';

interface NodeWrapperProps {
	children: React.ReactNode;
	id: string;
	isSelected?: boolean;
	width?: number;
}

interface WrapperProps {
	$isSelected: boolean;
	width: number;
}

const Wrapper = styled.div<WrapperProps>(
	({ theme, width, $isSelected }) => css`
		cursor: pointer;
		position: relative;
		width: ${width}px;

		${!$isSelected &&
		css`
			&:hover {
				.input-wrapper {
					border: 1px solid ${theme.palette.blue.main};
				}

				${ConditionBlockContainer},
				${NodeItemContainer} {
					border: 1px solid ${theme.palette.blue.main};
				}

				${ConditionBlockNameText},
				${NodeItemBody} *,
                  ${NodeItemContainer} svg {
					color: ${theme.palette.dark.main};
				}
			}
		`}
	`,
);

function NodeWrapper({ id, children, isSelected = false, width = 400 }: NodeWrapperProps) {
	const editorDispatch = useEditorDispatch();

	return (
		<Wrapper
			width={width}
			$isSelected={isSelected}
			onClick={() =>
				editorDispatch
					? editorDispatch({
							type: 'select',
							id,
					  })
					: null
			}
		>
			{children}
		</Wrapper>
	);
}

export default NodeWrapper;
