import React from 'react';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import styled from 'styled-components';

export function CheckIcon({ dataTestId }: CheckIconProps) {
	return (
		<IconWrapper>
			<Icon data-testid={dataTestId} path={mdiCheck} size={0.6} color="rgba(0,0,0,0.7)" />
		</IconWrapper>
	);
}

interface CheckIconProps {
	dataTestId?: string;
}

const IconWrapper = styled.div`
	align-items: center;
	background-color: rgba(0, 0, 0, 0.06);
	border: 2px solid #c0c0c0;
	border-radius: 50%;
	display: flex;
	height: 1rem;
	justify-content: center;
	width: 1rem;
`;
