/**
 * Default: The errors in a given row are displayed whenever the row is not in focus
 * MultiTileCreate: The errors are displayed whenever there are errors in the row (only after the create button has been
 * pressed)
 */
// eslint-disable-next-line import/prefer-default-export
export enum ErrorHandlingMode {
	Default = 'default',
	MultiTitleCreate = 'multiTitleCreate',
}
