import { graphql } from '../codegen/gql';

export const GET_TITLE_V2 = graphql(`
	query GetTitleV2($uuid: ID!) {
		getTitleV2(uuid: $uuid) {
			...TitleFragment
		}
	}
`);

export const EXPORT_TITLE = graphql(`
	query ExportTitle($input: ExportTitleInput!) {
		exportTitle(input: $input) {
			mec
		}
	}
`);
