import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import Icon from '@mdi/react';

type WrapperProps = {
	inlineLabel?: boolean;
	disabled?: boolean;
	disabledMargin?: boolean;
	fullWidth?: boolean;
	disableRightPadding?: boolean;
};

export const ReadOnlyValue = styled(Typography)(() => css``);

export const Label = styled(Typography)<{ error?: string }>(
	({ theme, error }) => css`
		align-items: center;
		color: ${theme.palette.text.secondary};
		display: flex;
		justify-content: space-between;
		margin-bottom: ${theme.spacing(1.25)};

		${error &&
		css`
			color: ${theme.palette.red.text};
		`}
	`,
);

export const LabelBlockWrapper = styled.div(
	() => css`
		display: flex;
	`,
);

export const Wrapper = styled.div<WrapperProps>(
	({ inlineLabel, theme, disabled, disabledMargin, fullWidth, disableRightPadding }) => css`
		align-items: baseline;
		&:focus-within {
			${Label} {
				color: ${theme.palette.blue.main};
			}
		}

		${disableRightPadding &&
		css`
			input {
				padding-right: 0;
			}
		`}

		${inlineLabel &&
		css`
			display: flex;

			${Label} {
				margin-right: ${theme.spacing(2)};
			}
		`}

		${!inlineLabel &&
		css`
			${ReadOnlyValue} {
				margin-left: ${theme.spacing(2)};
			}
		`}

		${disabled &&
		css`
			${Label}, ${ReadOnlyValue} {
				color: ${theme.palette.action.disabled};
			}
			cursor: not-allowed;
		`}
		
		${!disabledMargin &&
		css`
			margin: ${theme.spacing(1.75)} 0;
		`}
		
		${fullWidth &&
		css`
			width: 100%;
		`}
		position: relative;
	`,
);

export const SubMessageLayoutWrapper = styled.div<{ height?: number }>(
	({ height }) => css`
		display: flex;
		flex-direction: column;
		${height &&
		css`
			height: ${height}px !important;
		`}
	`,
);

export const SubMessageWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		flex-direction: row-reverse;

		.MuiSvgIcon-root {
			font-size: 18px;
		}

		.MuiTypography-root {
			font-size: 14px;
			font-weight: bold;
			margin-left: ${theme.spacing(1)};
		}
	`,
);

export const IconWrapper = styled.div<{ $start?: boolean }>(
	({ theme, $start }) => css`
		align-items: center;
		display: flex;
		padding: 0;

		${$start
			? css`
					padding-left: ${theme.spacing(1.5)};
			  `
			: css`
					padding-right: ${theme.spacing(1.5)};
			  `}

		> * {
			width: 20px;
		}
	`,
);

export const TooltipIcon = styled(Icon)(
	({ theme }) => css`
		padding: 0 ${theme.spacing(1)};
	`,
);

export const SubMessage = styled.div<{ color: 'info' | 'error' }>(
	({ theme, color }) => css`
		align-items: center;
		background-color: ${theme.palette[color].main};
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		.MuiTypography-root,
		svg {
			color: ${theme.palette.text.reverseMain} !important;
		}
		display: flex;
		padding: ${theme.spacing(0, 1)};
	`,
);

export const InputButton = styled.button(
	() => css`
		background-color: inherit;
		border: none;
		cursor: pointer;
		margin: 2px;
		padding: 0;

		.MuiSvgIcon-root {
			font-size: 18px;
		}
	`,
);

export const InputIcons = styled.div<{ disableMargin?: boolean }>(
	({ theme, disableMargin }) => css`
		align-items: stretch;
		display: flex;
		z-index: 1;
		${!disableMargin &&
		css`
			margin: 0 ${theme.spacing(1)};
		`}
	`,
);

export const Input = styled.input<{
	width?: string | number;
}>(
	({ theme, width }) => css`
		background: none;
		border: none;
		border-radius: 8px;
		flex-grow: 1;
		font-size: 14px;
		padding: 0 ${theme.spacing(2)};

		${width &&
		css`
			width: ${typeof width === 'string' ? width : `${width}px`};
		`}

		&:focus-visible {
			outline: none;
		}
	`,
);
