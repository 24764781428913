import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';
import { replaceUUID } from '@warehouse/shared/util';

const NotificationContainer = styled.div(
	() => css`
		align-items: center; /* Light red background */
		background-color: #ffdcdc;
		border-radius: 8px;
		box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
		color: #8c0000;
		display: flex;
		font-weight: 400;
		min-width: 288px;
		padding: 6px 16px 6px 20px;
		width: 100%;
	`,
);

const ContentWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		font-family: 'Roboto';
		font-size: 14px;
		font-weight: 400;
		padding: 8px 0;

		svg {
			display: inline-block;
			fill: currentcolor;
			flex-shrink: 0;
			font-size: 20px;
			height: 1em;
			margin-inline-end: 8px;
			user-select: none;
			width: 1em;
		}
	`,
);

type UniquenessErrorProps = {
	message: string;
};

function UniquenessError({ message }: UniquenessErrorProps) {
	return (
		<NotificationContainer>
			<ContentWrapper role="alert">
				<Icon style={{ marginInlineEnd: '8px' }} path={mdiCloseCircle} color="#8c0000" />
				{replaceUUID(message)}
			</ContentWrapper>
		</NotificationContainer>
	);
}

export default UniquenessError;
