export type WorkTypeObject = {
	workType: WorkType;
	uuid: string;
	label: string;
};

export enum WorkType {
	Movie = 'Movie',
	Season = 'Season',
	Series = 'Series',
	Edit = 'Edit',
	Compilation = 'Compilation',
	Episode = 'Episode',
	Supplemental = 'Supplemental',
	Manifestation = 'Manifestation',
}

export type StandaloneWorkType =
	| WorkType.Series
	| WorkType.Season
	| WorkType.Episode
	| WorkType.Movie
	| WorkType.Supplemental
	| WorkType.Compilation;

export function isStandaloneWorkType(workType: WorkType): workType is StandaloneWorkType {
	return [
		WorkType.Series,
		WorkType.Season,
		WorkType.Episode,
		WorkType.Movie,
		WorkType.Supplemental,
		WorkType.Compilation,
	].includes(workType);
}

export type DerivableWorkType = WorkType.Movie | WorkType.Episode | WorkType.Supplemental;

export function isDerivableWorkType(workType: WorkType): workType is DerivableWorkType {
	return [WorkType.Movie, WorkType.Episode, WorkType.Supplemental].includes(workType);
}

export type DerivedWorkType = WorkType.Edit | WorkType.Manifestation;

export function isDerivedWorkType(workType: WorkType): workType is DerivedWorkType {
	return [WorkType.Edit, WorkType.Manifestation].includes(workType);
}

export type ParentAbstractionWorkTypeAndWorkType = {
	parentAbstractionWorkType: DerivableWorkType;
	workType: DerivedWorkType;
};

export type CombinedWorkType = StandaloneWorkType | ParentAbstractionWorkTypeAndWorkType;

export function isParentAbstractionWorkTypeAndWorkType(
	combinedWorkType: CombinedWorkType,
): combinedWorkType is ParentAbstractionWorkTypeAndWorkType {
	return typeof combinedWorkType === 'object';
}
