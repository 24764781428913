import React, { useEffect, useMemo, useState } from 'react';
import { CellContext } from '@tanstack/react-table';

// TYPES
import { Rating, Reason } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { DropdownV2 } from '@warehouse/shared/ui';
import { isInherited } from '@warehouse/title/core';
import { WithUuid } from '../../../../hooks/useTitleRecordsAutoSave/recordToArray';

// LIBRARY
import { findRatingSystem } from './utils';
import deepEquality from '../../../../../../utils/deepEquality';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

function sortReason(a: Reason, b: Reason, rating: Rating) {
	const aLabel = findRatingSystem(rating)?.ratingTags?.find((tag) => tag.uuid === a.reason)?.name;

	const bLabel = findRatingSystem(rating)?.ratingTags?.find((tag) => tag.uuid === b.reason)?.name;

	if (!aLabel && !bLabel) return 0;
	if (!aLabel) return -1;
	if (!bLabel) return 1;

	return aLabel.localeCompare(bLabel);
}

function CellReason(info: CellContext<WithUuid<Inherited<Rating>>, WithUuid<Inherited<Rating>>>) {
	const { table, getValue } = info;
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;
	const [localState, setLocaleState] = useState(getValue()?.displayValue?.reasons);

	useEffect(() => {
		setLocaleState(getValue()?.displayValue?.reasons);
	}, [getValue()]);

	const onBlur = () => {
		const sortedLocalState = [...(localState || []).sort((a, b) => sortReason(a, b, getValue()?.displayValue))];
		const sortedReelState = [...(getValue()?.displayValue?.reasons || [])].sort((a, b) =>
			sortReason(a, b, getValue()?.displayValue),
		);

		if (!deepEquality(sortedLocalState, sortedReelState)) {
			editRecord({
				...getValue(),
				displayValue: {
					...getValue()?.displayValue,
					reasons: localState,
				},
			});
		}
	};

	const options = useMemo(
		() =>
			(findRatingSystem(getValue()?.displayValue)?.ratingTags || []).map((ratingValue) => ({
				value: ratingValue.uuid,
				label: ratingValue.name,
			})),
		[
			getValue()?.displayValue?.region?.countryRegion,
			getValue()?.displayValue?.region?.country,
			getValue()?.displayValue?.system,
		],
	);

	const sortedLocalState =
		localState
			?.map((item: Reason) => item.reason)
			?.sort((a, b) => {
				const aLabel = options.find((option) => option.value === a)?.label || '';
				const bLabel = options.find((option) => option.value === b)?.label || '';
				return aLabel?.localeCompare(bLabel);
			}) || [];

	if (options.length > 0) {
		return (
			<CellWrapper>
				<DropdownV2
					variant={isInherited({ inheritedObject: getValue() }).isInherited ? 'prefilled' : 'default'}
					onBlur={onBlur}
					value={sortedLocalState}
					transparentBorder
					onTagRemoved={(value) => {
						editRecord({
							...getValue(),
							displayValue: {
								...getValue()?.displayValue,
								reasons: localState?.filter((prev) => prev.reason !== value) || [],
							},
						});
					}}
					backgroundColor="transparent"
					width={150}
					mode="multiple"
					onChange={(e: string[]) => setLocaleState(e.map((reason) => ({ reason })) || [])}
					options={options}
				/>
			</CellWrapper>
		);
	}
	return null;
}

export default CellReason;
