import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getRelationshipSubtypeObject, getAllRelationshipSubtypes } from '@warehouse/title/infra';
import { DropdownV2 } from '@warehouse/shared/ui';

export function RelationshipSubtypeSelector({
	ariaLabel,
	supportedRelationshipSubtypes,
	selected,
	onSelect,
	onBlur,
	placeholder,
}: RelationshipSubtypeSelectorProps) {
	const [localSelected, setLocalSelected] = useState<string | undefined>(selected);

	// Synchronize localSelected with selected so that the external state always takes precedence
	useEffect(() => {
		setLocalSelected(selected);
	}, [selected]);

	const handleSelect = useCallback(
		(nextSelected: string) => {
			setLocalSelected(nextSelected);
			onSelect?.(nextSelected);
		},
		[setLocalSelected, onSelect],
	);

	const options = useMemo(() => {
		if (supportedRelationshipSubtypes) {
			return supportedRelationshipSubtypes.map((relationshipSubtype) => {
				const relationshipSubtypeObject = getRelationshipSubtypeObject(relationshipSubtype);
				return {
					label: relationshipSubtypeObject.label,
					value: relationshipSubtypeObject.uuid,
				};
			});
		}
		return getAllRelationshipSubtypes().map(({ label, uuid }) => ({ label, value: uuid }));
	}, [supportedRelationshipSubtypes]);

	return (
		<DropdownV2
			height={40}
			ariaLabel={ariaLabel}
			value={localSelected}
			options={options}
			onSelect={handleSelect}
			onBlur={onBlur}
			placeholder={placeholder ?? 'Relationship Subtype'}
			disabledMargin
		/>
	);
}

interface RelationshipSubtypeSelectorProps {
	ariaLabel?: string;
	supportedRelationshipSubtypes?: string[];
	selected?: string;
	onSelect?: (relationshipSubtype: string) => void;
	onBlur?: () => void;
	placeholder?: string;
}
