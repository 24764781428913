import React, { useState, useEffect } from 'react';
import { RelationshipType } from '@warehouse/title/core';
import { getRelationshipTypeObject } from '@warehouse/title/infra';
import { DropdownV2 } from '@warehouse/shared/ui';

interface RelationshipTypeSelectorProps {
	ariaLabel?: string;
	supportedRelationshipTypes: RelationshipType[];
	selected?: RelationshipType | null;
	onSelect?: (relationshipType: RelationshipType) => void;
}

export function RelationshipTypeSelector({
	ariaLabel = 'Relationship Type',
	supportedRelationshipTypes,
	selected,
	onSelect,
}: RelationshipTypeSelectorProps) {
	const [localSelected, setLocalSelected] = useState<RelationshipType | null | undefined>(selected);

	// Synchronize localSelected with selected so that the external state always takes precedence
	useEffect(() => {
		setLocalSelected(selected);
	}, [selected]);

	const options = supportedRelationshipTypes.map((relationshipType) => {
		const relationshipTypeObject = getRelationshipTypeObject(relationshipType);
		return {
			label: relationshipTypeObject.labelTarget,
			value: relationshipTypeObject.relationshipType,
		};
	});

	const handleSelect = (value: string) => {
		// While not ideal, the cast here is valid because the string value is always selected from the supportedRelationshipType list and therefore a valid RelationshipType enum value.
		setLocalSelected(value as RelationshipType);
		onSelect?.(value as RelationshipType);
	};

	return (
		<div style={{ width: '220px' }}>
			<DropdownV2
				disabledMargin
				height={40}
				ariaLabel={ariaLabel}
				options={options}
				value={localSelected}
				onSelect={handleSelect}
				placeholder="Relationship Type"
			/>
		</div>
	);
}
