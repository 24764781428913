import React from 'react';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { Header } from '@tanstack/react-table';

import TimeInput from '../../TimeInput';
import { DropDownButton, SubHeaderTH, CustomPopover } from '../style';

const secondsToHms = (seconds: number) => {
	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.floor((seconds % 3600) % 60);

	return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
};

interface SubHeaderTimePickerProps {
	handleDropdownButton: (columnId: string, anchorEl?: HTMLButtonElement) => void;
	openDropdownButton: { [key: string]: boolean };
	header: Header<any, any>;
	value: number;
	popperRef: React.RefObject<HTMLDivElement>;
	anchorRef: HTMLButtonElement | null;
	handleSubHeaderDropdownChange: (columnId: string, value: number) => void;
}

function SubHeaderTimePicker({
	handleDropdownButton,
	openDropdownButton,
	header,
	value,
	popperRef,
	anchorRef,
	handleSubHeaderDropdownChange,
}: SubHeaderTimePickerProps) {
	return (
		<SubHeaderTH style={{ textTransform: 'none' }}>
			<DropDownButton
				disableRipple
				endIcon={<Icon path={mdiChevronDown} size={1} />}
				onClick={(e) =>
					handleDropdownButton(header.column.columnDef.id as string, e.currentTarget as HTMLButtonElement)
				}
				$isOpen={openDropdownButton[header.column.columnDef.id as string] || false}
			>
				{value === 0 ? 'Set default' : secondsToHms(value)}
			</DropDownButton>
			<CustomPopover
				style={{ width: '170px' }}
				ref={popperRef}
				open={openDropdownButton[header.column.columnDef.id as string] || false}
				anchorEl={anchorRef}
				placement="bottom-start"
			>
				<TimeInput
					fullWidth={false}
					value={value}
					onChange={(newValue) => {
						handleSubHeaderDropdownChange(header.column.columnDef.id as string, newValue);
					}}
				/>
			</CustomPopover>
		</SubHeaderTH>
	);
}

export default SubHeaderTimePicker;
