import { Maybe } from 'graphql/jsutils/Maybe';
import { ApolloError } from '@apollo/client';

export type PaginatedVirtualizedQueryHook = ({
	pollInterval,
	perPage,
	page,
	searchValue,
	filtering,
}: {
	searchValue?: string;
	pollInterval: number;
	perPage?: number;
	page?: number;
	filtering?: FilterStep;
}) => {
	loading: boolean;
	error: ApolloError | undefined;
	data: any;
	refetch: (args: any) => any;
	fetchMore: (args: any) => any;
};

type FilterFieldsRequirementBase = {
	id: string;
	label: string;
	type: RestrictorType;
};

type FilterFieldsRequirementAccessorFn<T> = {
	accessorFn: (item: Maybe<T>) => Maybe<string> | undefined;
};

type FilterFieldsRequirementOptions = {
	options: {
		label: string;
		value: string;
	}[];
};

type FilterFieldsRequirementBackendQuery<T> = {
	query: PaginatedVirtualizedQueryHook;
	isCustomQueryForOperators: (keyof FilterOperators)[];
	queryKey: string;
	documentsMapFn: (
		item: T,
		index: number,
	) => {
		value: string;
		label: string;
	};
};

type FilterFieldsRequirements<T> = FilterFieldsRequirementBase &
	({} | FilterFieldsRequirementAccessorFn<T> | FilterFieldsRequirementOptions | FilterFieldsRequirementBackendQuery<T>);

function isFilterFieldsRequirementOptions(
	obj: any,
): obj is FilterFieldsRequirementBase & FilterFieldsRequirementOptions {
	return 'options' in obj;
}

function isFilterFieldsRequirementAccessorFn<T>(
	obj: any,
): obj is FilterFieldsRequirementBase & FilterFieldsRequirementAccessorFn<T> {
	return 'accessorFn' in obj;
}

export function isFilterFieldsRequirementBackendQuery<T>(
	obj: any,
): obj is FilterFieldsRequirementBase & FilterFieldsRequirementBackendQuery<T> {
	return 'query' in obj && 'queryKey' in obj && 'isCustomQueryForOperators' in obj && 'documentsMapFn' in obj;
}

export interface FilterField {
	id: string;
	label: string;
	type: RestrictorType;
	options?: {
		label: string;
		value: string;
	}[];
	query?: PaginatedVirtualizedQueryHook;
	isCustomQueryForOperators?: (keyof FilterOperators)[];
	queryKey?: string;
	documentsMapFn?: (
		item: any,
		index: number,
	) => {
		value: string;
		label: string;
	};
	searchKey?: string;
}

export interface FilterWithId extends Filter {
	id?: string;
}

export interface FilterStepWithId extends FilterStep {
	id?: string;
	step: {
		combinationOperator: 'AND' | 'OR';
		filters: Array<FilterWithId | FilterStepWithId>;
	};
}

// Type guard to use in recursion
export function isStep(item: FilterWithId | FilterStepWithId | Filter | FilterStep): item is FilterStepWithId {
	return (item as FilterStepWithId).step !== undefined;
}

export function isFilter(item: FilterWithId | FilterStepWithId | Filter | FilterStep): item is FilterWithId {
	return (item as FilterWithId).field !== undefined;
}
export function dataToFilterFields<T>(data: Maybe<T>[], requirements: FilterFieldsRequirements<T>[]): FilterField[] {
	const res: FilterField[] = requirements.map((i) => ({
		options: [],
		...i,
	}));

	data.forEach((i) => {
		requirements.forEach((req, index) => {
			if (res[index] && res[index].options) {
				if (isFilterFieldsRequirementOptions(req)) {
					res[index].options = req.options;
				} else if (isFilterFieldsRequirementAccessorFn(req)) {
					const accessedValue = req.accessorFn(i);
					if (accessedValue)
						res[index].options?.push({
							label: accessedValue,
							value: accessedValue,
						});
				}
			}
		});
	});

	// Removing duplicates and sorting
	res.forEach((r) => {
		// eslint-disable-next-line no-param-reassign
		r.options = r.options
			?.filter((value, index, self) => index === self.findIndex((t) => t.value === value.value))
			.sort((a, b) => a.label.localeCompare(b.label));
	});
	return res.sort((a, b) => a.label.localeCompare(b.label));
}

const formatDate = (d: Date) => d.toISOString().substring(0, 10);

const minusDate = (d: Date, delta: number) => new Date(d.setDate(d.getDate() + delta));

// Utility to remove Ids and convert strings to real types (boolean, number, ...) for API
export function prepareDataForAPI(data: FilterStepWithId, fields: FilterField[]) {
	const tmp: FilterStepWithId = structuredClone(data);

	const browseItems = (items: (FilterStepWithId | FilterWithId)[]) => {
		items.forEach((i, index) => {
			const filter = items[index];
			if (isStep(filter)) {
				delete filter.id;
				// eslint-disable-next-line no-param-reassign
				items[index] = filter;
				browseItems(filter.step.filters);
			}
			if (isFilter(filter)) {
				delete filter.id;
				const field = fields.find((f) => f.id === filter.field);
				if (field) {
					if (field.type === 'float' && filter.value) filter.value = parseFloat(filter.value as string);
					else if (field.type === 'int' && filter.value) filter.value = parseInt(filter.value as string, 10);
					else if (field.type === 'boolean' && filter.value) filter.value = filter.value === 'true';
					else if (field.type === 'date') {
						const dateAux = filter.dateAuxiliary;
						if (dateAux?.operator === 'theDate') filter.value = formatDate(new Date(filter.value as string));
						else if (dateAux?.operator === 'today') filter.value = formatDate(new Date());
						else if (dateAux?.operator === 'yesterday') {
							filter.value = formatDate(minusDate(new Date(), -1));
						} else if (dateAux?.operator === 'tomorrow') {
							filter.value = formatDate(minusDate(new Date(), +1));
						} else if (dateAux?.operator === 'daysInThePast' && dateAux?.number !== undefined) {
							filter.value = formatDate(minusDate(new Date(), -dateAux.number));
						} else if (dateAux?.operator === 'daysInTheFuture' && dateAux?.number !== undefined) {
							filter.value = formatDate(minusDate(new Date(), +dateAux.number));
						}
					}
					delete filter.dateAuxiliary;

					// eslint-disable-next-line no-param-reassign
					items[index] = filter;
				}
			}
		});
		return items;
	};

	delete tmp.id;
	tmp.step.filters = browseItems(tmp.step.filters);
	return tmp;
}
