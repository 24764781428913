import { graphql } from '@warehouse/graphql';

export const SEARCH_RELATIONSHIPS = graphql(`
	query searchRelationships($search: SearchInput!) {
		searchRelationships(search: $search) {
			documents {
				direction
				indexed {
					uuid
					parentAbstractionWorkType {
						uuid
						name
						label
					}
					licensor {
						label
						uuid
					}
					titleSearchSortFull
					titleDisplayUnlimited
					titleSearchDisplayNameFull
					titleSearchDisplayNameFullHTML {
						editClasses
						editUse
						identification
						madeForRegions
						madeForRegionsExclude
						parentTitleSearchDisplayNameFullForChild
						parentTitleSearchSortFullForChild
						sequenceNumber
						titleFrontEnd
						titleSortFrontEnd
						useTitleSecurity
						versionLanguages
						workType
						workTypeDetails
					}
					releaseYear
					workType {
						label
						uuid
						name
					}
					parents {
						parentContentId
						primary
						relationshipType
						relationshipSubtypes {
							uuid
							label
						}
						sequenceInfo {
							distributionNumber {
								distributionNumber
								domain
							}
							houseSequence {
								houseSequence
								domain
							}
							number
						}
					}
					partOfCompilations {
						compilationId
						contentId
						compilationEntryClass {
							label
							uuid
						}
						compilationEntryNumber
					}
					createdAt
					createdBy {
						name
						userId
					}
					editedAt
					editedBy {
						name
						userId
					}
					titleStatus
				}
				relationship {
					contentId
					primary
					sequenceInfo {
						distributionNumber {
							distributionNumber
							domain
						}
						houseSequence {
							houseSequence
							domain
						}
						number
					}
					subtypes {
						label
						uuid
					}
					type
				}
				state
				titleUuid
				uuid
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`);
