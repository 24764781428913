import React, { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

export type Control = {
	dirtyValue: any;
	setDirtyValue: Dispatch<SetStateAction<any>>;
};

type TitleAutoSaveContextProps = {
	children: ReactNode;
};
type TitleAutoSaveContextOutput = {
	hasDirtyFields: boolean;
	clearDirtyFields: () => void;
	register: (key: string, control: Control) => void;
	update: (key: string, control: Control) => void;
	destroy: (key: string) => void;
};

export const TitleAutoSaveContext = createContext<TitleAutoSaveContextOutput>({
	hasDirtyFields: false,
	clearDirtyFields: () => {},
	register: () => {},
	destroy: () => {},
	update: () => {},
});

function TitleAutoSaveContextProvider({ children }: TitleAutoSaveContextProps) {
	const [controls, setControls] = useState<{ [k: string]: Control }>({});

	const clearDirtyFields = () => {
		const tmp = { ...controls };
		Object.keys(tmp).forEach((key) => {
			tmp[key].dirtyValue = undefined;
			tmp[key].setDirtyValue(undefined);
		});
		setControls(tmp);
	};

	const register = (key: string, control: Control) => {
		setControls((prev) => ({ ...prev, [key]: control }));
	};

	const destroy = (key: string) => {
		const tmp = { ...controls };
		delete tmp[key];
		setControls(tmp);
	};

	const update = (key: string, control: Control) => {
		const tmp = {
			...controls,
			[key]: {
				dirtyValue: structuredClone(control.dirtyValue),
				setDirtyValue: control.setDirtyValue,
			},
		};
		setControls(tmp);
	};

	const providedValues = useMemo(
		() => ({
			register,
			update,
			destroy,
			clearDirtyFields,
			hasDirtyFields: Object.entries(controls).some((ctrl) => ctrl[1].dirtyValue !== undefined),
		}),
		[controls],
	);

	return <TitleAutoSaveContext.Provider value={providedValues}>{children}</TitleAutoSaveContext.Provider>;
}

export default TitleAutoSaveContextProvider;
