import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull } from '@warehouse/title/core';
import { AssociatedOrg } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import Dropdown from '../../../../components/library/Dropdown/Dropdown';
import useLicensors from '../../../../utils/hooks/licensor/useLicensors';
import { DropdownOption } from '../../../../components/library/Dropdown/types';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import ConfirmationModal from '../../../../components/library/ConfirmationModal';

interface LicensorFieldProps {
	control: Control<any>;
	selectedParentTitle?: TitleFull;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	inheritedValues?: TitleFull | undefined;
	parentHasBeenSelected?: boolean;
}

export function LicensorField({
	control,
	errors,
	setValue,
	selectedParentTitle,
	trigger,
	isRequired = false,
	inheritedValues,
	parentHasBeenSelected = false,
}: LicensorFieldProps) {
	const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
	const [options, setOptions] = useState<DropdownOption[]>([]);
	const inheritedAssociatedOrg = getTitleData<OptionalInherited<AssociatedOrg[]>>(
		inheritedValues,
		fromBasic(['associatedOrg']),
	);
	const inheritedLicensor = inheritedAssociatedOrg?.displayValue?.find((ao) => ao.role === 'licensor')?.displayName;
	const { data: licensorsData, refetch, getDefault: getDefaultLicensor } = useLicensors({});
	const tooltip = useTooltip('coreMetadata.basic.associatedOrgs');
	const confirmFnRef = useRef<null | (() => void)>(null);

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		setOptions(
			licensorsData?.getLicensors?.map((licensor) => ({
				value: licensor.displayName,
				label: licensor.displayName,
			})) || [],
		);

		if (!selectedParentTitle?.uuid) {
			// Get default from normal logic
			const defaultLicensor = getDefaultLicensor();

			if (defaultLicensor) {
				setValue('licensor', defaultLicensor?.displayName);
			}
		} else {
			// Set default from parent title
			const associatedOrgs = getTitleData<AssociatedOrg[]>(
				selectedParentTitle,
				fromBasic(['associatedOrgs', 'displayValue']),
			);
			const licensorOrg = associatedOrgs?.find((org) => org.role === 'licensor');
			setValue('licensor', licensorOrg?.displayName);
		}
	}, [licensorsData?.getLicensors, selectedParentTitle]);

	const onNewOptionAdded = (newOption: DropdownOption) => {
		if (
			(typeof newOption.label === 'string' && newOption.label?.trim() === '') ||
			(typeof newOption.value === 'string' && newOption.value?.trim() === '')
		)
			return;
		setOptions((prev) => [...prev, newOption]);
	};

	const onConfirmConfirmationModal = () => {
		setConfirmationModalOpen(false);
		confirmFnRef?.current?.();
		confirmFnRef.current = null;
	};

	const onRequestChangeLicensor = async (newLicensor: string | null) => {
		if (parentHasBeenSelected) {
			setConfirmationModalOpen(true);

			confirmFnRef.current = () => {
				onChangeLicensor(newLicensor);
				setValue('parentTitle.title', undefined);
			};
		} else {
			await onChangeLicensor(newLicensor);
		}
	};

	const onChangeLicensor = async (newLicensor: string | null) => {
		setValue('licensor', newLicensor);
		if (isRequired && !inheritedLicensor) {
			await trigger('licensor');
		}
	};

	return useMemo(
		() => (
			<>
				<Controller
					control={control}
					name="licensor"
					render={({ field }) => (
						<Dropdown
							ariaLabel="Licensor Selector"
							withSearch
							onNewOptionAdded={onNewOptionAdded}
							pickList
							readOnly={!!selectedParentTitle?.uuid}
							enablePortal
							placeholder={inheritedLicensor}
							placeholderStyle="italic"
							options={options}
							tooltip={tooltip?.tooltip}
							value={field.value}
							error={isRequired ? (errors?.licensor?.message as string) : undefined}
							onChange={onRequestChangeLicensor}
							label={getLabelWithRequired('Licensor', isRequired)}
						/>
					)}
				/>
				<ConfirmationModal
					open={confirmationModalOpen}
					onConfirm={onConfirmConfirmationModal}
					onClose={() => setConfirmationModalOpen(false)}
					title="Are you sure?"
					message="This change will clear the parent field. Are you sure you wish to proceed?"
				/>
			</>
		),
		[
			options,
			control,
			confirmationModalOpen,
			parentHasBeenSelected,
			inheritedLicensor,
			isRequired,
			licensorsData,
			errors?.licensor?.message,
			setValue,
			trigger,
		],
	);
}
