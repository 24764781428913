import { CellContext } from '@tanstack/react-table';
import React from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Release } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import DatePicker, { dateToString, stringToDate } from '../../../../../../components/library/DatePicker/DatePicker';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

function CellDate(info: CellContext<Release, Release>) {
	const { getValue, row, table } = info;
	const value = getValue()?.date;
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options?.meta?.customPropsCell.isInherited;

	const dateValue = value ? stringToDate(value) : null;

	const onChange = (e: Date) => {
		setValue((prev: OptionalInherited<Release[]>) => {
			const updatedDisplayValue = prev.displayValue?.map((item, index) => {
				if (index === row.index) {
					return {
						...item,
						date: dateToString(e),
					};
				}
				return item;
			});
			return {
				...prev,
				displayValue: updatedDisplayValue,
			};
		});
	};

	return (
		<CellWrapper withHorizontalPadding>
			<DatePicker
				variant={isInherited ? 'prefilled' : 'default'}
				value={dateValue || undefined}
				height={36}
				transparentBorder
				onChange={(e) => {
					if (e) {
						onChange(e);
						commit();
					}
				}}
			/>
		</CellWrapper>
	);
}

export default CellDate;
