import lodash from 'lodash';
import React from 'react';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { Inherited, isUnknownInherited, TitleFull } from '@warehouse/title/core';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useTheme } from '@mui/material';
import { StyledLink } from '../../shared/ui/StyledLink';

export type JumpToParentButtonPresenterOutput =
	| {
			shouldDisplayJumpToParentButton: true;
			parentTitleLink: string;
	  }
	| {
			shouldDisplayJumpToParentButton: false;
			parentTitleLink?: never;
	  };

export function jumpToParentButtonPresenter({
	path,
	title,
	disabled,
}: {
	path?: string;
	title: TitleFull;
	disabled?: boolean;
}): JumpToParentButtonPresenterOutput {
	if (disabled)
		return {
			shouldDisplayJumpToParentButton: false,
		};

	if (!path)
		return {
			shouldDisplayJumpToParentButton: false,
		};

	const value = lodash.get(title, path);

	if (!isUnknownInherited(value)) {
		return {
			shouldDisplayJumpToParentButton: false,
		};
	}

	const { origin } = (value as Inherited<unknown>).inherited;

	if (!origin) {
		return {
			shouldDisplayJumpToParentButton: false,
		};
	}

	return {
		shouldDisplayJumpToParentButton: true,
		parentTitleLink: `/title/${origin}`,
	};
}

export function JumpToParentButton({ path, disabled }: { path: string; disabled?: boolean }) {
	const theme = useTheme();
	const title = useTitleEditorStore(titleEditorStoreSelector.titleRequired);

	const { shouldDisplayJumpToParentButton, parentTitleLink } = jumpToParentButtonPresenter({ path, title, disabled });

	if (!shouldDisplayJumpToParentButton) return null;

	return (
		<StyledLink
			className="jump-to-parent-button"
			onClick={(e) => e.stopPropagation()}
			style={{ display: 'flex', cursor: 'pointer', visibility: 'hidden', alignItems: 'center' }}
			tabIndex={-1}
			to={parentTitleLink}
		>
			<AccountTreeIcon style={{ cursor: 'pointer', color: theme.palette.text.secondary, fontSize: '16px' }} />
		</StyledLink>
	);
}
