import React, { useMemo, useState } from 'react';
import SelectDropdown from '../../library/SelectDropdown/SelectDropdown';
import useDebounce from '../../../utils/hooks/useDebounce';
import { PaginatedVirtualizedQueryHook } from '../../../utils/table/filtersUtils';

interface BackendFilterInputProps<T> {
	query: PaginatedVirtualizedQueryHook;
	setValues: (value: string[]) => void;
	values: string[];
	queryKey: string;
	documentsMapFn: (
		item: T,
		index: number,
	) => {
		value: string;
		label: string;
	};
	searchKey: string;
}

const PER_PAGE = 30;

function BackendSelectDropdown<T>({
	values,
	setValues,
	query,
	queryKey,
	documentsMapFn,
	searchKey,
}: BackendFilterInputProps<T>) {
	const [searchValue, setSearchValue] = useState<string>('');
	const debouncedSearchValue = useDebounce(searchValue, 500);
	const { data, fetchMore, loading } = query({
		pollInterval: 10000,
		perPage: PER_PAGE,
		searchValue: debouncedSearchValue,
	});

	const filtering: FilterStep = useMemo(
		() => ({
			step: {
				combinationOperator: 'AND',
				filters: [
					...(searchValue
						? [
								{
									field: searchKey,
									operator: 'contains' as keyof FilterOperators,
									value: debouncedSearchValue,
								},
						  ]
						: []),
					{
						field: searchKey,
						operator: 'isDefined' as keyof FilterOperators,
						value: true,
					},
				],
			},
		}),
		[debouncedSearchValue],
	);

	const handleLoadMore = () => {
		if (!loading && fetchMore) {
			return fetchMore({
				variables: {
					search: {
						pagination: {
							page: (data?.[queryKey]?.page?.currentPage || 0) + 1,
							perPage: PER_PAGE,
						},
						filters: JSON.stringify(filtering),
					},
				},
				updateQuery: (prev: any, { fetchMoreResult }: any) => {
					if (!fetchMoreResult) return prev;
					return {
						...fetchMoreResult,
						searchTitleEntities: {
							...fetchMoreResult?.[queryKey],
							documents: [...(prev?.[queryKey]?.documents || []), ...(fetchMoreResult?.[queryKey]?.documents || [])],
						},
					};
				},
			});
		}

		return Promise.resolve();
	};

	return (
		<div>
			<SelectDropdown
				values={values || []}
				enablePortal
				height={40}
				onSelect={(v) =>
					values.includes(v) ? setValues(values.filter((item) => item !== v)) : setValues([...values, v])
				}
				options={data?.[queryKey]?.documents?.map(documentsMapFn) || []}
				backendSearch={{
					searchValue,
					setSearchValue,
				}}
				infiniteLoaderProps={{
					isItemLoaded: (index) => !!data?.[queryKey].documents?.[index],
					itemCount: data?.[queryKey]?.page.totalDocument || 0,
					loadMoreItems: loading ? () => {} : handleLoadMore,
				}}
			/>
		</div>
	);
}

export default BackendSelectDropdown;
