import { CastAndCrewMember, TranslatedString, TranslatedStringArray } from './providers/castAndCrewProvider';

function errorOnDisplayNames(name: TranslatedString) {
	const locale = Object.keys(name || {});

	if (locale.includes('undefined')) return true;
	for (let i = 0; i < locale.length; i += 1) {
		if (!name[locale[i]] || name[locale[i]].trim() === '') return true; // Display Name is empty or undefined
	}
	return false;
}

function errorOnCharacterNames(characters: TranslatedStringArray | undefined) {
	if (!characters) return false;
	const locale = Object.keys(characters || {});
	if (locale.includes('undefined')) return true;
	for (let i = 0; i < locale.length; i += 1) {
		if (characters?.[locale[i]].includes('')) return true; // Character name is empty or undefined
		if (locale[i] === '') return true; // Locale is empty
	}
	return false;
}

export function computeCastErrors(castData: CastAndCrewMember[] | undefined) {
	return (
		castData
			?.map((cast, index) => {
				if (errorOnDisplayNames(cast.name)) return index;
				if (!cast.role) return index;
				if (errorOnCharacterNames(cast.characters)) return index;

				return -1;
			})
			.filter((index) => index !== -1) || []
	);
}

export function computeCrewErrors(crewData: CastAndCrewMember[] | undefined) {
	return (
		crewData
			?.map((crew, index) => {
				if (errorOnDisplayNames(crew.name)) return index;
				if (!crew.role) return index;
				return -1;
			})
			.filter((index) => index !== -1) || []
	);
}
