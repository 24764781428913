import React from 'react';
import ConfirmationModal from '../library/ConfirmationModal';

type WillClearFieldsConfirmModalProps = {
	open: boolean;
	handleClose: () => void;
	onConfirm: () => void;
};

function WillClearFieldsConfirmModal({ open, handleClose, onConfirm }: WillClearFieldsConfirmModalProps) {
	return (
		<ConfirmationModal
			open={open}
			title="Are you sure?"
			message="This change will clear some fields in this row. Are you sure you wish to proceed?"
			onConfirm={onConfirm}
			onClose={handleClose}
		/>
	);
}

export default WillClearFieldsConfirmModal;
