import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { mdiLaptop, mdiListBox, mdiPlayBoxMultiple, mdiVolumeHigh } from '@mdi/js';

import {
	closestCenter,
	DndContext,
	DragEndEvent,
	DragMoveEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	MeasuringStrategy,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import Icon from '@mdi/react';
import { v4 as uuid } from 'uuid';

import PlayableContentEditor from './PlayableContentEditor';
import SideDrawer, { SideDrawerContent, Trigger } from '../../../components/library/SideDrawer';
import { EditorElement, FlattenedItem } from './types';
import { useEditorContext, useEditorDispatch } from './EditorContext';
import {
	buildTree,
	flattenTree,
	getProjection,
	INDENTATION_WIDTH,
	MouseSensor,
	searchTree,
	removeChildrenOf,
	findItem,
} from './utilities';
import SideMenu from './SideMenu';
import { Items, TreeItemOverlay } from '../../../components/ContentLibrary/ContentLibTreeView';

const Wrapper = styled.div`
	align-items: stretch;
	background-color: #dde0e3;
	display: flex;
	height: 100%;
	width: 100vw;
`;

const presentations: Items = {
	id: '38',
	root: true,
	title: 'Presentations',
	tooltip: 'Presentations',
	children: [
		{
			id: '1',
			title: 'Feature Presentation',
			startIcon: <Icon path={mdiLaptop} size={0.6} />,
			children: [
				{
					id: '7',
					title: 'Track: Audio',
					tooltip: 'Track: Audio',
					children: [
						{
							id: '8',
							title: 'EN Stereo Audio',
							startIcon: <Icon path={mdiVolumeHigh} size={0.6} />,
						},
						{
							id: '9',
							title: 'EN 5.1 Audio',
							startIcon: <Icon path={mdiVolumeHigh} size={0.6} />,
						},
						{
							id: '10',
							title: 'EN AD 5.1 Audio',
							startIcon: <Icon path={mdiVolumeHigh} size={0.6} />,
						},
						{
							id: '11',
							title: 'EN Atmos Audio',
							startIcon: <Icon path={mdiVolumeHigh} size={0.6} />,
						},
						{
							id: '12',
							title: 'EN AD Atmos Audio',
							startIcon: <Icon path={mdiVolumeHigh} size={0.6} />,
						},
					],
				},
			],
		},
	],
};

// DEV
const devData: EditorElement[] = [
	{
		id: 'c6e9e6ec-a366-48d2-84c8-949236054531',
		type: 'block',
		typeText: 'Playlist',
		name: 'Feature Delivery',
		icon: mdiPlayBoxMultiple,
		description: 'Minima enim perspiciatis unde iusto velit lorem...',
		attached: {
			id: 'e6ddb8eb-d204-4011-b5d5-d94c9a12437d',
			type: 'condition',
			name: 'Track Definition Plan',
			icon: mdiListBox,
			children: [],
		},
		children: [
			{
				id: 'f368a27a-ec83-407d-8134-d15d756e42c5',
				type: 'track',
				typeText: 'Audio',
				name: 'EN Dubbed Audio 7',
				icon: mdiVolumeHigh,
				children: [],
			},
			{
				id: 'bfa10410-7ef9-4f3f-a60e-9d55f5a4ebc3',
				type: 'block',
				typeText: 'Playlist',
				name: 'Feature Delivery',
				icon: mdiPlayBoxMultiple,
				description: 'Minima enim perspiciatis unde iusto velit lorem...',
				attached: {
					id: '8f185a36-8f43-4a38-aa4f-1719c456f6f4',
					type: 'condition',
					name: 'Track Definition Plan',
					icon: mdiListBox,
					children: [],
				},
				children: [
					{
						id: 'a5b1fdff-56dd-4741-bd1e-29a721d2f55e',
						type: 'track',
						typeText: 'Audio',
						name: 'EN Dubbed Audio 1',
						icon: mdiVolumeHigh,
						children: [],
					},
					{
						id: '73601105-3e4e-463d-b342-c8d6c052c92b',
						type: 'track',
						typeText: 'Audio',
						name: 'EN Dubbed Audio 2',
						icon: mdiVolumeHigh,
						children: [],
					},
					{
						id: 'b6f59ace-78e5-464e-b5a4-701ea7feb1fd',
						type: 'track',
						typeText: 'Audio',
						name: 'EN Dubbed Audio 3',
						icon: mdiVolumeHigh,
						children: [],
					},
				],
			},
			{
				id: '4809cc00-27c8-45fd-82dc-59901bd69e0e',
				type: 'condition',
				name: 'Dubbed Audio',
				icon: mdiListBox,
				children: [
					{
						id: 'd2b1c6c3-7fd4-449c-ad6e-0bff0f90b47a',
						type: 'condition',
						name: 'Dubbed Audio',
						icon: mdiListBox,
						children: [
							{
								id: 'dffe8e67-5f37-45b4-a676-ccf4e7805182',
								type: 'track',
								typeText: 'Audio',
								name: 'FR Dubbed Audio 1',
								icon: mdiVolumeHigh,
								children: [],
							},
							{
								id: '440b54c6-cb8c-43a7-b096-ea5d3e3d11c5',
								type: 'track',
								typeText: 'Audio',
								name: 'FR Dubbed Audio 2',
								icon: mdiVolumeHigh,
								children: [],
							},
							{
								id: 'c604dc11-83cf-40a9-9591-c17cc33d6162',
								type: 'track',
								typeText: 'Audio',
								name: 'FR Dubbed Audio 3',
								icon: mdiVolumeHigh,
								children: [],
							},
						],
					},
					{
						id: 'b0dabe7f-fd75-4d41-9d4b-a04cd672d646',
						type: 'track',
						typeText: 'Audio',
						name: 'EN Dubbed Audio 4',
						icon: mdiVolumeHigh,
						children: [],
					},
					{
						id: '0595b3cd-2e50-4d09-af2a-c5d7ba67ec19',
						type: 'track',
						typeText: 'Audio',
						name: 'EN Dubbed Audio 5',
						icon: mdiVolumeHigh,
						children: [],
					},
					{
						id: '47599153-93ba-4a91-b834-27da40dedc90',
						type: 'track',
						typeText: 'Audio',
						name: 'EN Dubbed Audio 6',
						icon: mdiVolumeHigh,
						children: [],
					},
				],
			},
		],
	},
];

function Editor() {
	const editorContext = useEditorContext();
	const collapsedItems = editorContext?.collapsedItems || [];
	const dispatch = useEditorDispatch();
	const selectedId = editorContext?.selected;
	const [selectedItem, setSelectedItem] = useState<EditorElement | null>(null);
	const wrapperEl = useRef<HTMLDivElement>(null);
	const [sideDrawerOpen, setSideDrawerOpen] = useState(true);
	const [items, setItems] = useState<EditorElement[]>(devData);
	const [libItems] = useState<Items[]>([presentations]);

	// Drag and Drop
	const [offsetLeft, setOffsetLeft] = useState(0);
	const [activeId, setActiveId] = useState<string | null>(null);
	const [overId, setOverId] = useState<string | null>(null);
	const [draggedFromLib, setDraggedFromLib] = useState(false);
	const [activeItem, setActiveItem] = useState<EditorElement | Items | null>(null);

	useEffect(() => {
		if (activeId) {
			let res: EditorElement | Items | null;
			res = searchTree(items, activeId);
			if (!res) res = searchTree(libItems, activeId);
			if (res) setActiveItem(res);
		}
	}, [activeId]);

	const flattenedItems = useMemo(() => {
		const flattenedTree = flattenTree(items);

		return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
	}, [activeId, items, collapsedItems]);

	const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);

	// Reopen drawer on Select
	useEffect(() => {
		if (selectedId) {
			setSideDrawerOpen(true);
			const item = searchTree(items, selectedId);
			if (item) setSelectedItem(item as EditorElement);
			else setSelectedItem(null);
		}
	}, [selectedId]);

	const projected =
		activeId && overId ? getProjection(flattenedItems, activeId, overId, offsetLeft, INDENTATION_WIDTH) : null;

	const sensors = useSensors(
		useSensor(MouseSensor),
		// useSensor(KeyboardSensor, {
		// 	coordinateGetter,
		// }),
	);

	const measuring = {
		droppable: {
			strategy: MeasuringStrategy.Always,
		},
	};

	function resetState() {
		setOverId(null);
		setActiveId(null);
		setOffsetLeft(0);
		setDraggedFromLib(false);

		document.body.style.setProperty('cursor', '');
	}

	const handleDragStart = (e: DragStartEvent) => {
		const actId = e.active.id;
		if (!e.active?.data?.current) setDraggedFromLib(true);
		setActiveId(actId as string);
		setOverId(actId as string);
		document.body.style.setProperty('cursor', 'grabbing');
	};

	const handleDragMove = ({ delta }: DragMoveEvent) => {
		setOffsetLeft(delta.x);
	};

	const handleDragOver = ({ over }: DragOverEvent) => {
		setOverId((over?.id as string) ?? null);
	};

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		resetState();

		// Dropped on library, do nothing
		if (over?.id === 'library') return;

		// Just clicked, selecting
		if (over && active.id === over.id && dispatch) {
			dispatch({
				type: 'select',
				id: active.id as string,
			});
		}

		if (projected && over) {
			const { depth, parentId } = projected;
			const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
			const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
			const itemInEditor = findItem(clonedItems, active.id as string);

			// Intra editor move
			if (itemInEditor) {
				const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
				const activeTreeItem = clonedItems[activeIndex];

				clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

				const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
				const newItems = buildTree(sortedItems);

				setItems(newItems);
			}
			// Move from outside
			else {
				const item: Items | null = searchTree([presentations], active.id as string) as Items;
				if (item) {
					const newItem: FlattenedItem = {
						id: uuid(),
						type: 'track',
						typeText: 'Audio',
						name: item.title,
						icon: mdiVolumeHigh,
						children: [],
						depth,
						parentId,
						index: overIndex,
					};
					clonedItems.push(newItem);
					const sortedItems = arrayMove(clonedItems, clonedItems.length - 1, overIndex);
					const newItems = buildTree(sortedItems);

					setItems(newItems);
				}
			}

			// console.log(newItems);
		}
	};

	const handleDragCancel = () => {
		resetState();
	};

	return (
		<Wrapper ref={wrapperEl}>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				measuring={measuring}
				onDragStart={handleDragStart}
				onDragMove={handleDragMove}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
			>
				<SideMenu items={libItems} />
				<PlayableContentEditor flattenedItems={flattenedItems} sortedIds={sortedIds} />
				{editorContext?.selected && (
					<>
						{!sideDrawerOpen && <Trigger side="right" onClick={() => setSideDrawerOpen(true)} />}
						<SideDrawer
							side="right"
							open={sideDrawerOpen}
							handleClose={() => setSideDrawerOpen(false)}
							wrapperRef={wrapperEl}
						>
							<SideDrawerContent minWidth={400}>
								SELECTED:
								{selectedItem?.name}
								<br />
								<br />
								More info will appear here
							</SideDrawerContent>
						</SideDrawer>
					</>
				)}
				{draggedFromLib && (
					<DragOverlay>{activeId && activeItem ? <TreeItemOverlay item={activeItem as Items} /> : null}</DragOverlay>
				)}
			</DndContext>
		</Wrapper>
	);
}

export default Editor;
