import { Divider, Portal, Tab, Typography } from '@mui/material';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { mdiClose, mdiMagnify, mdiVideo, mdiVolumeHigh } from '@mdi/js';
import Icon from '@mdi/react';

// GRAPHQL
import { InventoryTrack } from '../../../../graphql/codegen/graphql';

// HOOKS
import useDebounce from '../../../../utils/hooks/useDebounce';
import useGetTrackDetails from '../../../../utils/hooks/tracks/useGetTrackDetails';
import { useURLSearchParams } from '../../../../utils/hooks/useURLSearchParams';
import { useStateQuery } from '../../../../utils/hooks/useStateQuery';

// LIBRARY
import { SideModal } from '../../../../components/library/SideDrawer';
import Tabs from '../../../../components/library/Tabs';
import Button from '../../../../components/library/Button';
import TextInput from '../../../../components/library/TextInput';
import Switch from '../../../../components/library/Switch';
import { CustomColumnDef } from '../../../../components/library/SimpleTable/customColumnDef';
import DetailsTable, {
	transformAndFormatData,
	DetailsTableData,
} from '../../../../components/library/DetailsTable/DetailsTable';
import TrackAssets from './trackAssets';

interface TrackDetailsProps {
	open: boolean;
	handleClose: () => void;
	wrapperRef: RefObject<HTMLDivElement>;
	track: InventoryTrack;
}

const PaddingWrapper = styled.div(
	({ theme }) => `
		padding: ${theme.spacing(2)};
	`,
);

const ContentWrapper = styled.div(
	({ theme }) => `
		.MuiTypography-root {
			color: ${theme.palette.text.primary};
		}
		
		svg {
			color: ${theme.palette.text.secondary};
		}
	`,
);

const HeaderRow = styled.div(
	({ theme }) => `
		display: flex;
		align-items: start;
		justify-content: space-between;
		gap: ${theme.spacing(1)};
	`,
);

const IconTypeWrapper = styled.div(
	({ theme }) => `
		display: flex;
		align-items: center;
		gap: ${theme.spacing(1)};
		
		color: ${theme.palette.text.secondary};
		
		svg {
			color: ${theme.palette.text.secondary};
		}
	`,
);

const TrackTypeWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.light.background};
		border-radius: 8px;
		display: flex;
		justify-content: center;
		margin-left: ${theme.spacing(4)};
		padding: ${theme.spacing(0.75, 1)};
	`,
);

const TabsWrapper = styled.div(
	() => css`
		display: flex;
		width: 100%;
	`,
);

const CustomTabs = styled(Tabs)(
	() => css`
		width: 100%;
	`,
);

const SearchInput = styled(TextInput)(
	({ theme }) => css`
		.input-wrapper {
			background-color: ${theme.palette.light.main};
			height: 33px;
			min-height: 33px;
		}
		width: 100%;
	`,
);

const SearchWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(1)};
	`,
);

const SwitchWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		.MuiTypography-root {
			color: ${theme.palette.text.secondary};
		}
		display: flex;
		justify-content: end;
		margin-left: ${theme.spacing(1)};
		width: 160px;
	`,
);

const InformationTitle = styled(Typography)(
	({ theme }) => css`
		color: ${theme.palette.text.primary};
		font-size: ${theme.typography.s1Regular.fontSize};
		font-weight: ${theme.typography.s1Regular.fontWeight};
	`,
);

const columns: CustomColumnDef<any>[] = [
	{
		id: 'name',
		header: 'Attributes',
		accessorFn: (row) => row.name,
		minWidth: 300,
	},
	{
		id: 'value',
		header: '',
		minWidth: 300,
		accessorFn: (row) => row.value,
	},
];

const recursiveFilter = (rowData: any, searchInput: string) =>
	rowData
		.map((row: any) => {
			const newSubRows = row.subRows ? recursiveFilter(row.subRows, searchInput) : null;

			return Object.values(row).some((value) => value?.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
				(newSubRows && newSubRows.length > 0)
				? { ...row, subRows: newSubRows }
				: null;
		})
		.filter(Boolean);

function getTrackTypeName(trackType: string | undefined) {
	switch (trackType) {
		case 'AUDIO_TRACK':
			return 'Audio Track';
		case 'VIDEO_TRACK':
			return 'Video Track';
		case 'SUBTITLE_TRACK':
			return 'Subtitle Track';
		default:
			return '';
	}
}

function getTrackTypeIcon(trackType: string | undefined) {
	switch (trackType) {
		case 'AUDIO_TRACK':
			return mdiVolumeHigh;
		case 'VIDEO_TRACK':
			return mdiVideo;
		default:
			return '';
	}
}

const tabNameToIndexMap = {
	details: 0,
	'track-assets': 1,
	usage: 2,
};

const tabIndexToNameMap = Object.entries(tabNameToIndexMap).reduce(
	(acc, [name, index]) => ({ ...acc, [index]: name }),
	{},
);

function TrackDetails({ open, handleClose, wrapperRef, track }: TrackDetailsProps) {
	const { searchParams, setQuery } = useURLSearchParams();
	const [searchInput, setSearchInput] = useStateQuery<string>({ queryName: 'trackInformationSearch' });
	const [showAll, setShowAll] = useStateQuery<boolean>({ queryName: 'trackInformationShowAll', type: 'boolean' });
	const [detailsFormattedData, setDetailsFormattedData] = useState<DetailsTableData[]>([]);
	const [details, setDetails] = useState<any[]>(detailsFormattedData);
	const [originalData, setOriginalData] = useState<any[]>([]);
	const debouncedSearchInput = useDebounce(searchInput, 1000);

	const { data } = useGetTrackDetails({
		uuid: track?.uuid || '',
		pollInterval: 10000,
	});

	useEffect(() => {
		const transformedAndFormattedData = transformAndFormatData(data, []);
		setDetailsFormattedData(transformedAndFormattedData || []);
	}, [data]);

	useEffect(() => {
		setOriginalData(detailsFormattedData);
	}, [detailsFormattedData]);

	useEffect(() => {
		if (debouncedSearchInput) {
			setDetails(recursiveFilter(originalData, debouncedSearchInput));
		} else {
			setDetails(originalData || []);
		}
	}, [debouncedSearchInput, originalData]);

	useEffect(() => {
		if (!open) {
			setSearchInput('');
			setShowAll(false);
		}
	}, [open]);

	const TrackInformationTable = useMemo(
		() => (
			<DetailsTable
				columns={columns}
				data={details}
				setData={setDetails}
				setOriginalData={setOriginalData}
				expandedByDefault={showAll}
				editable
			/>
		),
		[details, showAll],
	);

	const selectedTab = useMemo(() => {
		const tab = searchParams.trackDetails?.split('/')[1];
		if (!tab) return 0;
		return tabNameToIndexMap[tab as keyof typeof tabNameToIndexMap] || 0;
	}, [searchParams.trackDetails]);

	return (
		<Portal container={wrapperRef.current}>
			<SideModal
				wrapperRef={wrapperRef}
				side="right"
				open={open}
				overflow="auto"
				handleClose={handleClose}
				header={
					<>
						<PaddingWrapper>
							<HeaderRow>
								<IconTypeWrapper>
									<Icon path={getTrackTypeIcon(track?.trackType || undefined)} size="24px" />
									<Typography variant="buttonLargeMedium">{getTrackTypeName(track?.trackType || undefined)}</Typography>
								</IconTypeWrapper>
								<TrackTypeWrapper>
									<Typography variant="buttonSmallMedium">Explicit Track</Typography>
								</TrackTypeWrapper>
							</HeaderRow>
							<HeaderRow style={{ marginTop: 8 }}>
								<Typography style={{ maxWidth: '650px' }} variant="h4Regular">
									{track?.trackName || 'Track Name'}
								</Typography>
								<Button style={{ padding: 0 }} nxstyle="tertiary-light" onClick={handleClose}>
									<Icon path={mdiClose} size="24px" />
								</Button>
							</HeaderRow>
						</PaddingWrapper>
						<Divider />
					</>
				}
			>
				<TabsWrapper>
					<CustomTabs
						value={selectedTab}
						onChange={(evt, newSelectedTab) =>
							setQuery(
								'trackDetails',
								`${track.uuid}/${tabIndexToNameMap[newSelectedTab as keyof typeof tabIndexToNameMap] ?? 'details'}`,
							)
						}
					>
						<Tab label="Details" />
						<Tab label="Track Assets" />
						<Tab disabled label="Usage" />
					</CustomTabs>
				</TabsWrapper>
				<PaddingWrapper>
					<div role="tabpanel" hidden={selectedTab !== 0} id="simple-tabpanel-0" aria-labelledby="simple-tab-0">
						<ContentWrapper>
							<InformationTitle>Track Information</InformationTitle>
							<SearchWrapper>
								<SearchInput
									placeholder="Search"
									startIcon={<Icon path={mdiMagnify} size="14px" />}
									endIcon={
										<Button $disablePadding nxstyle="tertiary-light" onClick={() => setSearchInput('')}>
											<Icon path={mdiClose} size="14px" />
										</Button>
									}
									value={searchInput}
									onChange={(event) => setSearchInput(event.target.value)}
								/>
								<SwitchWrapper>
									<Switch value={showAll} onChange={(e) => setShowAll(e.target.checked)} />
									<Typography variant="buttonMediumMedium">Show All</Typography>
								</SwitchWrapper>
							</SearchWrapper>
							{TrackInformationTable}
						</ContentWrapper>
					</div>
					<div
						role="tabpanel"
						hidden={selectedTab !== 1}
						id="simple-tabpanel-1"
						aria-labelledby="simple-tab-1"
						style={{ height: '95%' }}
					>
						<TrackAssets trackId={track?.uuid || ''} />
					</div>
				</PaddingWrapper>
			</SideModal>
		</Portal>
	);
}

export default TrackDetails;
