import React, { useMemo } from 'react';
import { titlePaths } from '@warehouse/title/core';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';
import TextInput from '../../../library/TextInput';

interface HouseSequenceNumberFieldProps {
	editMode: boolean;
	houseSequenceNumber: string;
	setHouseSequenceNumber: (houseSequenceNumber: string) => void;
	origin: string;
	isInherited: boolean;
	onBlur: () => void;
}

function HouseSequenceNumberField({
	editMode,
	houseSequenceNumber,
	setHouseSequenceNumber,
	origin,
	onBlur,
}: HouseSequenceNumberFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="House Sequence:"
				path={titlePaths.houseSequence}
				jumpToParentButtonDisabled
				editMode={editMode}
				ruleOrigin={origin}
				readComponent={<ReadOnlyTypography>{houseSequenceNumber}</ReadOnlyTypography>}
				editComponent={
					<TextInput
						fullWidth
						value={houseSequenceNumber}
						onChange={(e) => setHouseSequenceNumber(e.target.value)}
						onBlur={onBlur}
						height={38}
						width="50%"
					/>
				}
			/>
		),
		[houseSequenceNumber, origin, editMode],
	);
}

export default HouseSequenceNumberField;
