import React, { useEffect } from 'react';

function useHandleClickOutside<T>(ref: React.MutableRefObject<T | null>, func: () => void) {
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target as Node)) {
				func();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, func]);
}

export default useHandleClickOutside;
