import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

import Chip from './Chip';
import { Label, ReadOnlyValue } from './DropdownUtils';
import Tooltip from './Tooltip';

export type ChipDisplayVariant = 'default' | 'alt' | 'prefilled' | 'prefilled-alt';

export interface ChipDisplayProps {
	values: (string | React.ReactNode)[];
	width?: number;
	calcWidth?: string;
	height?: number;
	allowOverflow?: boolean;
	variant?: ChipDisplayVariant;
	horizontalPadding?: number;
	chipBackground?: string;
}

// TODO: Fix bug on column change width
function ChipDisplay(props: ChipDisplayProps) {
	const {
		values,
		width,
		height,
		allowOverflow,
		variant = 'default',
		calcWidth,
		horizontalPadding = 1.5,
		chipBackground,
	} = props;
	const [visibleChips, setVisibleChips] = useState<(string | React.ReactNode)[]>([]);
	const [overflowChips, setOverflowChips] = useState<(string | React.ReactNode)[]>([]);
	const chipContainerEl = useRef<HTMLDivElement>(null);
	const hiddenContainerEl = useRef<HTMLDivElement>(null);

	const calculateChips = () => {
		if (allowOverflow) {
			if (chipContainerEl.current && hiddenContainerEl.current) {
				const containerWidth = chipContainerEl.current.clientWidth;
				let totalWidth = 0;
				let cutoffIndex = 0;

				Array.from(hiddenContainerEl.current.children).forEach((child, index) => {
					totalWidth += (child as HTMLElement).offsetWidth;
					if (totalWidth < containerWidth) {
						cutoffIndex = index + 1;
					}
				});

				const computedCutoffIndex = Math.max(cutoffIndex, 1);

				setVisibleChips(values.slice(0, computedCutoffIndex));
				setOverflowChips(values.slice(computedCutoffIndex));
			}
		} else {
			setVisibleChips(values);
			setOverflowChips([]);
		}
	};

	useEffect(() => {
		calculateChips();
		const resizeObserver = new ResizeObserver(() => {
			calculateChips();
		});

		if (chipContainerEl.current) {
			resizeObserver.observe(chipContainerEl.current);
		}

		return () => {
			if (chipContainerEl.current) {
				resizeObserver.unobserve(chipContainerEl.current);
			}
		};
	}, [values, allowOverflow]);

	return (
		<Wrapper width={width} calcWidth={calcWidth}>
			<TriggerWrapper horizontalPadding={horizontalPadding} className="input-wrapper" ref={chipContainerEl}>
				<SelectedItemChipWrapper allowOverflow={allowOverflow}>
					{visibleChips.map((chip, index) =>
						typeof chip === 'string' ? (
							<StyledChip
								withHover={false}
								$chipBackground={chipBackground}
								$maxWidth="100%"
								key={`${chip}${index}`}
								label={chip}
								height={height}
								variant={isVariantPrefilled(variant) ? 'prefilled' : 'default'}
							/>
						) : (
							chip
						),
					)}
					{allowOverflow && overflowChips.length > 0 && (
						<Tooltip
							followCursor={false}
							placement="top"
							variant={isVariantAlt(variant) ? 'light' : 'dark'}
							title={
								<>
									{overflowChips.map((value, index) => (
										<div key={index}>
											{isVariantAlt(variant) && typeof value === 'string' && (
												<StyledChip
													$chipBackground={chipBackground}
													key={`${value}${index}`}
													label={value}
													height={height}
													style={{ marginBottom: '4px' }}
												/>
											)}
											{isVariantDefault(variant) && typeof value === 'string' && (
												<Typography variant="buttonSmallRegular" color="inherit">
													{value}
													{index < overflowChips.length - 1 && ', '}
												</Typography>
											)}
											{typeof value !== 'string' && value}
										</div>
									))}
								</>
							}
						>
							{isVariantAlt(variant) ? (
								<div>
									<StyledChip
										$chipBackground={chipBackground}
										key={overflowChips.length}
										label={`+${overflowChips.length}`}
										height={height}
									/>
								</div>
							) : (
								<StyledCustomBadge>{`+${overflowChips.length}`}</StyledCustomBadge>
							)}
						</Tooltip>
					)}
				</SelectedItemChipWrapper>
			</TriggerWrapper>
			<div ref={hiddenContainerEl} style={{ left: 0, visibility: 'hidden', position: 'absolute' }}>
				{values.map((value) => (typeof value === 'string' ? <Chip key={value} label={value} /> : value))}
			</div>
		</Wrapper>
	);
}

type WrapperProps = {
	inlineLabel?: boolean;
	disabled?: boolean;
	width?: number;
	calcWidth?: string;
};

const Wrapper = styled.div<WrapperProps>(
	({ inlineLabel, theme, disabled, width, calcWidth }) => css`
		align-items: center;
		&:focus-within {
			${Label} {
				color: ${theme.palette.blue.main};
			}
		}

		${width &&
		css`
			width: ${width}px !important;
		`}

		${calcWidth &&
		css`
			width: ${calcWidth};
		`}

		${inlineLabel &&
		css`
			display: flex;

			${Label} {
				margin-right: ${theme.spacing(2)};
			}
		`}

		${!inlineLabel &&
		css`
			${ReadOnlyValue} {
				margin-left: ${theme.spacing(2)};
			}
		`}

		${disabled &&
		css`
			${Label}, ${ReadOnlyValue} {
				color: ${theme.palette.action.disabled};
			}
			cursor: not-allowed;
		`}
	`,
);

const TriggerWrapper = styled.div<{ horizontalPadding?: number }>(
	({ theme, horizontalPadding }) => css`
		align-items: center;
		background-color: inherit;
		box-sizing: border-box;
		display: flex;
		flex-grow: 1;
		font-size: 1rem;
		justify-content: space-between;
		min-height: 38px;
		padding: ${theme.spacing(0, horizontalPadding ?? 1.5)};
	`,
);

const SelectedItemChipWrapper = styled.div<{ allowOverflow?: boolean }>(
	({ allowOverflow }) => css`
		align-items: center;
		display: flex;
		flex-wrap: ${allowOverflow ? 'nowrap' : 'wrap'};
		gap: 4px;
		width: 100%;
	`,
);

const StyledCustomBadge = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.info.main};
		border-radius: 10px;
		box-sizing: border-box;
		color: ${theme.palette.light.main};
		display: flex;
		font-family: Roboto, Helvetica, Arial, sans-serif;
		font-size: 12px;
		font-weight: 500;
		height: 20px;
		justify-content: center;
		line-height: 1;
		min-width: 24px;
		padding: 1px 5px;
	`,
);

const StyledChip = styled(Chip)<{
	$chipBackground?: string;
	$maxWidth?: string;
}>(
	({ theme, $chipBackground, $maxWidth }) => css`
		background-color: ${$chipBackground || theme.palette.light.background};
		${$maxWidth !== undefined ? `max-width: ${$maxWidth};` : ''}
	`,
);

function isVariantPrefilled(variant: ChipDisplayVariant): variant is 'prefilled' | 'prefilled-alt' {
	return variant === 'prefilled' || variant === 'prefilled-alt';
}

function isVariantAlt(variant: ChipDisplayVariant): variant is 'alt' | 'prefilled-alt' {
	return variant === 'alt' || variant === 'prefilled-alt';
}

function isVariantDefault(variant: ChipDisplayVariant): variant is 'default' | 'prefilled' {
	return variant === 'default' || variant === 'prefilled';
}

export default ChipDisplay;
