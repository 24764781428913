import { gql } from '@apollo/client';

export const FRAGMENTS = gql`
	fragment TitleFragment on GetTitleOutput {
		uuid
		state
		indexed {
			uuid
			parentAbstractionWorkType {
				uuid
				name
				label
			}
			licensor {
				label
				uuid
			}
			titleDisplayUnlimited
			titleSearchDisplayNameFull
			titleSearchDisplayNameFullHTML {
				editClasses
				editUse
				identification
				madeForRegions
				madeForRegionsExclude
				parentTitleSearchDisplayNameFullForChild
				parentTitleSearchSortFullForChild
				sequenceNumber
				titleFrontEnd
				titleSortFrontEnd
				useTitleSecurity
				versionLanguages
				workType
				workTypeDetails
			}
			releaseYear
			workType {
				label
				uuid
				name
			}
			parents {
				parentContentId
				primary
				relationshipType
				relationshipSubtypes {
					uuid
					label
				}
				sequenceInfo {
					distributionNumber {
						distributionNumber
						domain
					}
					houseSequence {
						houseSequence
						domain
					}
					number
				}
			}
			partOfCompilations {
				compilationId
				contentId
				compilationEntryClass {
					label
					uuid
				}
				compilationEntryNumber
			}
			createdAt
			createdBy {
				name
				userId
			}
			editedAt
			editedBy {
				name
				userId
			}
			titleStatus
		}
	}
`;
