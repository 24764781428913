import { useMutation, ApolloCache } from '@apollo/client';
import { AddParentsInput, AddParentsResult, ADD_PARENTS, GET_TITLE_V2 } from '@warehouse/graphql';

interface UseAddParentsOptions {
	onSuccess?: (successfulResults: AddParentsResult[]) => void;
	onError?: (error: string) => void;
}

export function useAddParents(options: UseAddParentsOptions = {}) {
	return useMutation<
		{
			addParents: AddParentsResult[];
		},
		{ input: AddParentsInput }
	>(ADD_PARENTS, {
		update: (cache, { data }) => handleMutationUpdate(cache, data, options),
		onError: (error) => options?.onError?.(error.message),
	});
}

function handleMutationUpdate(
	cache: ApolloCache<any>,
	data: { addParents: AddParentsResult[] } | null | undefined,
	options: UseAddParentsOptions,
) {
	if (!data?.addParents) return;

	const { successfulResults, failedResults } = categorizeResults(data.addParents);

	updateCacheForSuccessfulResults(cache, successfulResults);
	handleSuccessCallback(successfulResults, options);
	handleFailedResults(failedResults, options);
}

function categorizeResults(results: AddParentsResult[]) {
	const successfulResults: AddParentsResult[] = [];
	const failedResults: AddParentsResult[] = [];

	results.forEach((result) => {
		if (result.success) {
			successfulResults.push(result);
		} else {
			failedResults.push(result);
		}
	});

	return { successfulResults, failedResults };
}

function updateCacheForSuccessfulResults(cache: ApolloCache<any>, successfulResults: AddParentsResult[]) {
	successfulResults.forEach((result) => {
		if (result.output) {
			cache.writeQuery({
				query: GET_TITLE_V2,
				variables: {
					uuid: result.childUuid,
				},
				data: {
					getTitleV2: result.output,
				},
			});
		}
	});
}

function handleSuccessCallback(successfulResults: AddParentsResult[], options: UseAddParentsOptions) {
	if (successfulResults.length > 0) {
		options?.onSuccess?.(successfulResults);
	}
}

function handleFailedResults(failedResults: AddParentsResult[], options: UseAddParentsOptions) {
	failedResults.forEach((result) => {
		if (result.errors) {
			options?.onError?.(result.errors.join(', '));
		}
	});
}
