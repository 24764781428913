import { useMutation } from '@apollo/client';
import { AddedRecord, AddRecord, ADD_RECORD, GET_TITLE_V2 } from '@warehouse/graphql';

// eslint-disable-next-line import/prefer-default-export
export function useAddRecord() {
	return useMutation<
		{
			addRecord: AddedRecord;
		},
		{ input: AddRecord }
	>(ADD_RECORD, {
		update(cache, { data }, { context }) {
			try {
				if (!data?.addRecord) return;
				context?.onSuccess?.(data.addRecord);

				const title = data?.addRecord?.title;

				cache.writeQuery({
					query: GET_TITLE_V2,
					variables: {
						uuid: title?.uuid,
					},
					data: {
						getTitleV2: title,
					},
				});
			} catch (error) {
				console.error(error);
			}
		},
	});
}
