import React, { useMemo } from 'react';
import TitleEditableField, { ReadOnlyTypography } from '../../TitleEditableField';
import NumberInput from '../../../library/NumberInput';

interface ReleaseYearFieldProps {
	editMode: boolean;
	releaseYear: string | undefined;
	setReleaseYear: (year: string | undefined) => void;
	origin: string;
	isInherited: boolean;
}

function ReleaseYearField({ editMode, releaseYear, setReleaseYear, origin, isInherited }: ReleaseYearFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="Release Year:"
				path="coreMetadata.basic.releaseYear"
				editMode={editMode}
				readComponent={<ReadOnlyTypography>{releaseYear}</ReadOnlyTypography>}
				editComponent={
					<NumberInput
						disableRightPadding
						disableRightIcon
						fullWidth
						variant={isInherited ? 'prefilled' : 'default'}
						value={releaseYear || ''}
						onChange={(evt) => setReleaseYear(evt.target.value)}
						height={38}
					/>
				}
				ruleOrigin={origin}
			/>
		),
		[origin, isInherited, releaseYear, editMode],
	);
}

export default ReleaseYearField;
