import React, { useCallback, useContext, useMemo } from 'react';
import { TitleAlternate } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import styled, { css } from 'styled-components';

// TYPES

// HOOKS
import { Typography } from '@mui/material';
import { isInherited, resetInheritedArrayValues, titlePaths } from '@warehouse/title/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { UseTitleAutoSaveOutput } from '../../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import useTooltip from '../../../../../src/utils/hooks/useTooltips';

// LIBRARIES
import { CustomColumnDef } from '../../../../../src/components/library/SimpleTable/customColumnDef';
import computeErrors from '../../../../../src/views/title/utils/computeErrors';
import TitleEditableField from '../../../../../src/components/titles/TitleEditableField';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../src/components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { AlternateTitleTypePicklistContext } from './AlternateTitleContext';
import sortAlphabeticallyByLabel from '../../../../../src/utils/sortAlphabeticallyByLabel';
import LocaleCell from './LocaleCell';
import TypeCell from './TypeCell';
import TitleCell from './TitleCell';

// JSON
import languagesWrittenJson from '../../../../../src/assets/json-administration-profiles/availableLanguageWritten.json';

interface AlternateTitlesTableProps {
	editMode: boolean;
	titleAlternatesAutoSave: UseTitleAutoSaveOutput<OptionalInherited<TitleAlternate[]>>;
	defaultLocale: string | undefined;
	localizedInfoUuid: string;
}

const StyledDiv = styled.div(
	({ theme }) => css`
		.table-wrapper {
			margin-left: ${theme.spacing(0.75)};
			margin-right: ${theme.spacing(0.75)};
			width: auto !important;
		}
	`,
);

function RenderReadonlyType(value?: string, isInheritedValue?: boolean) {
	const { options } = useContext(AlternateTitleTypePicklistContext);

	return (
		<Typography
			style={{ marginLeft: 12 }}
			className={isInheritedValue ? 'inherited' : 'default'}
			fontWeight={400}
			fontSize="14px"
		>
			{options?.find((o) => o.value === value)?.label || value}
		</Typography>
	);
}

export function AlternateTitlesTableTable({
	editMode,
	titleAlternatesAutoSave,
	defaultLocale,
	localizedInfoUuid,
}: AlternateTitlesTableProps) {
	const { setValue, commit, value } = titleAlternatesAutoSave;
	const isInheritedValue = useMemo(
		() => (value ? isInherited<TitleAlternate[]>({ inheritedObject: value }).isInherited : false),
		[value],
	);
	const languagesWritten = languagesWrittenJson.sort(sortAlphabeticallyByLabel);
	const titleTooltip = useTooltip('coreMetadata.basic.localizedInfos.titleAlternates.titleAlternate');
	const typeTooltip = useTooltip('coreMetadata.basic.localizedInfos.titleAlternates.type');
	const languageTooltip = useTooltip('coreMetadata.basic.localizedInfos.titleAlternates.language');
	const title = useTitleEditorStore(titleEditorStoreSelector.title);

	const alternateTitlesColumns: CustomColumnDef<TitleAlternate>[] = useMemo(
		() => [
			{
				id: 'titleAlternate',
				tooltip: titleTooltip?.tooltip,
				header: 'Title',
				accessorFn: (row) => row,
				renderReadOnly: (v) => (
					<Typography
						style={{ marginLeft: 12 }}
						className={isInheritedValue ? 'inherited' : 'default'}
						fontWeight={400}
						fontSize="14px"
					>
						{v?.titleAlternate}
					</Typography>
				),
				cell: TitleCell,
				minWidth: 200,
				required: true,
			},
			{
				id: 'type',
				tooltip: typeTooltip?.tooltip,
				header: 'Type',
				accessorFn: (row) => row,
				renderReadOnly: (v: TitleAlternate) => RenderReadonlyType(v?.type, isInheritedValue),
				cell: TypeCell,
				minWidth: 140,
			},
			{
				id: 'language',
				tooltip: languageTooltip?.tooltip,
				header: 'Language',
				accessorFn: (row) => row,
				renderReadOnly: (v) => (
					<Typography
						style={{ marginLeft: 12 }}
						className={isInheritedValue ? 'inherited' : 'default'}
						fontWeight={400}
						fontSize="14px"
					>
						{languagesWritten?.find((l) => l.uuid === v.language)?.label}
					</Typography>
				),
				cell: LocaleCell,
				minWidth: 180,
			},
		],
		[],
	);

	const getNewRoadPayload = (): TitleAlternate => ({
		titleAlternate: '',
		language: defaultLocale,
	});

	const onRowAdd = useCallback(() => {
		setValue((prev) => ({
			...prev,
			displayValue: [...(prev.displayValue ?? []), getNewRoadPayload()],
		}));
	}, [setValue]);

	const onRowDelete = useCallback(
		(indexes: string[]) => {
			const updatedData = value?.displayValue?.filter((_, index) => !indexes.includes(index.toString()));
			setValue(resetInheritedArrayValues<TitleAlternate>(updatedData, title));
			commit();
		},
		[setValue, commit],
	);

	const errors = useMemo<Partial<Record<keyof TitleAlternate, number[]>>>(
		() => ({
			titleAlternate: computeErrors(titleAlternatesAutoSave?.value?.displayValue ?? [], 'titleAlternate'),
		}),
		[titleAlternatesAutoSave?.value?.displayValue],
	);

	return (
		<StyledDiv>
			<TitleEditableField
				label="Alternate Titles"
				path={titlePaths.alternateTitles(localizedInfoUuid)}
				editMode={editMode}
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
				ruleOrigin={origin}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<TitleAlternate>
				editMode={editMode}
				newRowPayload={getNewRoadPayload()}
				tableStyle="border"
				selectRowModeByDefault
				columns={alternateTitlesColumns}
				showBottomActionsBar={editMode}
				enableAddMultipleRows={false}
				data={titleAlternatesAutoSave.value?.displayValue || []}
				onRowDelete={onRowDelete}
				onRowAdd={onRowAdd}
				canAddRow={titleAlternatesAutoSave.hasInvalidRow === false}
				errors={errors}
				customPropsCell={{
					setValue,
					commit,
					defaultLocale,
					isInherited: isInheritedValue,
				}}
				noOverflow
			/>
		</StyledDiv>
	);
}
