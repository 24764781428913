import React from 'react';
import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import styled, { css } from 'styled-components';

const StyledCheckbox = styled(MUICheckbox)<CheckboxProps>(
	({ theme, disabled }) => css`
		&.Mui-checked {
			color: ${disabled ? theme.palette.action.disabled : theme.palette.blue.main} !important;
		}

		svg {
			height: 18px;
			width: 18px;
		}
	`,
);

export function Checkbox(props: CheckboxProps) {
	return <StyledCheckbox {...props} />;
}

export default Checkbox;
