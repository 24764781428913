import React from 'react';
import ConfirmationModal from '../../../../components/library/ConfirmationModal';

type UnsavedChangesModalProps = {
	open: boolean;
	handleClose: () => void;
	onConfirm: () => void;
};

function UnsavedChangesModal({ open, handleClose, onConfirm }: UnsavedChangesModalProps) {
	return (
		<ConfirmationModal
			open={open}
			title="Are you sure?"
			message="You have unsaved changes, do you wish to proceed?"
			onConfirm={onConfirm}
			onClose={handleClose}
		/>
	);
}

export default UnsavedChangesModal;
