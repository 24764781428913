import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { SEARCH_TITLE_ENTITIES_V2, Order, SearchInput, SearchTitlesV2Output } from '@warehouse/graphql';
import { SortingState } from '@warehouse/title/core';

export interface UseSearchTitlesProps {
	debouncedSearch: string;
	filtering?: FilterStep;
	sorting?: SortingState;
	pollInterval?: number;
	page: number;
	perPage: number;
	searchFields: string[];
	skip?: boolean;
}

function useSearchTitlesV2({
	debouncedSearch,
	filtering,
	pollInterval,
	sorting,
	page,
	perPage,
	searchFields,
	skip = false,
}: UseSearchTitlesProps) {
	const apiFilters = useMemo(() => formatFilters(filtering), [filtering]);
	const apiSort: Order[] | undefined = useMemo(() => computeAPISort(sorting), [sorting]);
	const wildcardSearch = useMemo(
		() => computeWildcardSearch(debouncedSearch, searchFields),
		[debouncedSearch, searchFields],
	);
	const { loading, error, data, networkStatus, startPolling, stopPolling, refetch, fetchMore } = useQuery<
		{
			searchTitlesV2: SearchTitlesV2Output;
		},
		{
			search: SearchInput;
		}
	>(SEARCH_TITLE_ENTITIES_V2, {
		variables: {
			search: {
				pagination: { page, perPage },
				orderBy: apiSort,
				filters: JSON.stringify(apiFilters),
				wildcardSearch,
			},
		},
		notifyOnNetworkStatusChange: true,
		skip,
	});

	// TODO: It looks that this function and its effect is useless
	// const refetchData = () => {
	// 	fetchMore({
	// 		variables: {
	// 			search: {
	// 				pagination: { page, perPage },
	// 				filters: JSON.stringify(apiFilters),
	// 				orderBy: apiSort,
	// 				wildcardSearch,
	// 			},
	// 		},
	// 	});
	// };

	// Fire request on change of Sorting or Filtering
	// useEffect(() => {
	// 	if (!skip) refetchData();
	// }, [apiSort, apiFilters]);

	// Query polling
	useEffect(() => {
		if (pollInterval) startPolling(pollInterval);
	}, [startPolling]);

	return {
		loading,
		error,
		data,
		startPolling,
		stopPolling,
		refetch,
		networkStatus,
		fetchMore,
	};
}

// Util hook to retrieve all title entities without filtering
export function useAllSearchTitlesEntitiesV2({ pollInterval }: { pollInterval: number }) {
	const { loading, error, data, startPolling } = useQuery<
		{
			searchTitlesV2: SearchTitlesV2Output;
		},
		{
			search: SearchInput;
		}
	>(SEARCH_TITLE_ENTITIES_V2, {
		variables: {
			search: {
				pagination: { page: 1, perPage: 1000 },
			},
		},
		notifyOnNetworkStatusChange: true,
	});

	// Query polling
	useEffect(() => {
		startPolling(pollInterval);
	}, [startPolling]);

	return {
		loading,
		error,
		data,
	};
}

export function computeWildcardSearch(search: string, searchFields: string[]) {
	if (search) {
		return {
			query: search,
			fields: searchFields,
		};
	}
	return undefined;
}

export function computeAPISort(sorting?: SortingState) {
	if (sorting) {
		return sortingStateToOrderArray(sorting) || [];
	}
	return undefined;
}

export function sortingStateToOrderArray(v?: SortingState) {
	if (v)
		return v.map((i) => ({
			field: i.id,
			direction: i.desc ? 'DESC' : 'ASC',
			nestedFilter: i.nestedFilter,
		}));
	return undefined;
}

export function formatFilters(tree: FilterStep | undefined) {
	if (tree === undefined) {
		return undefined;
	}
	const replacedFilters: (Filter | FilterStep)[] = [];

	tree.step.filters.forEach((filterOrStep) => {
		if ('field' in filterOrStep) {
			if (filterOrStep.field === 'releaseDate.value') {
				replacedFilters.push({
					...filterOrStep,
					value: new Date(filterOrStep.value as string).toDateString(),
				});
			} else {
				replacedFilters.push(filterOrStep);
			}
		} else if ('step' in filterOrStep) {
			const replaced = formatFilters(filterOrStep);
			if (replaced) {
				replacedFilters.push(replaced);
			}
		} else {
			replacedFilters.push(filterOrStep);
		}
	});

	return {
		nested: tree.nested,
		step: {
			combinationOperator: tree.step.combinationOperator,
			filters: replacedFilters,
		},
	};
}

export default useSearchTitlesV2;
