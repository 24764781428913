import { useMemo } from 'react';
import tooltips from '../../assets/json-administration-profiles/tooltips.json';
import { isValidUUID } from '../../views/title/hooks/useTitleRecordsAutoSave/uuidUtils';

type Tooltip = {
	tooltip: string;
};

const useTooltip = (path: string): Tooltip | undefined => {
	const pathWithoutMetadataPrefix = removeMetadataPrefixFromPath(path);
	const pathWithoutUuids = removeUuids(pathWithoutMetadataPrefix);
	const attribute = useMemo(
		() => (tooltips as Record<string, Tooltip | undefined>)[pathWithoutUuids],
		[pathWithoutUuids],
	);

	if (attribute?.tooltip) {
		return {
			tooltip: attribute.tooltip,
		};
	}
	return undefined;
};

function removeUuids(path: string): string {
	return path
		.split('.')
		.filter((key) => !isValidUUID(key))
		.join('.');
}

function removeMetadataPrefixFromPath(path: string): string {
	if (path.startsWith('metadata.')) {
		return path.slice('metadata.'.length);
	}
	return path;
}

export default useTooltip;
