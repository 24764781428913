import React from 'react';
import { EnqueueSnackbar } from 'notistack';
import UniquenessError from '../components/library/UniquenessError';

function handleClickableErrors(enqueueSnackbar: EnqueueSnackbar, message: string, titleUuid?: string) {
	if (titleUuid) {
		enqueueSnackbar(message, {
			variant: 'customSnackbar',
			children: <UniquenessError message={message} />,
		});
	} else {
		enqueueSnackbar(message, {
			variant: 'error',
		});
	}
}

export default handleClickableErrors;
