import styled, { css } from 'styled-components';
import { FormControlLabel as MUIFormControlLabel } from '@mui/material';

export const FormControlLabel = styled(MUIFormControlLabel)`
	${({ theme }) => css`
		.MuiTypography-root {
			color: ${theme.palette.text.secondary};
			font-size: 14px;
		}
	`}
`;

export default FormControlLabel;
