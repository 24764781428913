import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import React from 'react';
import { DropdownV2 } from '@warehouse/shared/ui';
import { CustomColumnDef, CustomSubHeaderProps } from '../../../components/library/SimpleTable/customColumnDef';
import { CustomPopover, DropDownButton, SubHeaderTH } from '../../../components/library/SimpleTable/style';
import IncludeExcludeBottomAction from '../../../components/titles/IncludeExcludeBottomAction';

export function MadeForRegionsSubHeader({
	handleDropdownButton,
	openDropdownButton,
	header,
	popperRef,
	anchorRef,
	handleSubHeaderDropdownChange,
	selectedDropdownValues,
}: CustomSubHeaderProps) {
	const madeForRegions = getMadeForRegionsValues(selectedDropdownValues);
	const isInclude = getMadeForRegionsIsInclude(selectedDropdownValues);

	return (
		<SubHeaderTH style={{ textTransform: 'none' }}>
			<DropDownButton
				disableRipple
				endIcon={<Icon path={mdiChevronDown} size={1} />}
				onClick={(e) =>
					handleDropdownButton(header.column.columnDef.id as string, e.currentTarget as HTMLButtonElement)
				}
				$isOpen={openDropdownButton[header.column.columnDef.id as string] || false}
			>
				{madeForRegions.length === 0 ? 'Set default' : `${madeForRegions.length} selected`}
			</DropDownButton>
			<CustomPopover
				ref={popperRef}
				open={openDropdownButton[header.column.columnDef.id as string] || false}
				anchorEl={anchorRef}
				placement="bottom-start"
			>
				<DropdownV2
					getPopupContainer={() => popperRef.current ?? document.body}
					value={madeForRegions || []}
					onChange={(v) =>
						handleSubHeaderDropdownChange(header.column.columnDef.id as string, {
							madeForRegions: v,
							isInclude,
						})
					}
					mode="multiple"
					bottomActions={
						<IncludeExcludeBottomAction
							value={isInclude ? 'includes' : 'excludes'}
							defaultValue="includes"
							onChange={(event) => {
								handleSubHeaderDropdownChange(header.column.columnDef.id as string, {
									madeForRegions,
									isInclude: event.target.value === 'includes',
								});
							}}
						/>
					}
					options={(header.column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
					withSearch
					forceOpen
				/>
			</CustomPopover>
		</SubHeaderTH>
	);
}

function getMadeForRegionsValues(v: unknown): string[] {
	if (
		!!v &&
		typeof v === 'object' &&
		'madeForRegions' in v &&
		!!v.madeForRegions &&
		typeof v.madeForRegions === 'object' &&
		'madeForRegions' in v.madeForRegions &&
		Array.isArray(v.madeForRegions.madeForRegions)
	) {
		return v.madeForRegions.madeForRegions || [];
	}
	return [];
}

function getMadeForRegionsIsInclude(v: unknown): boolean {
	if (
		!!v &&
		typeof v === 'object' &&
		'madeForRegions' in v &&
		!!v.madeForRegions &&
		typeof v.madeForRegions === 'object' &&
		'isInclude' in v.madeForRegions
	) {
		return Boolean(v.madeForRegions.isInclude);
	}
	return true;
}
