import React, { useContext } from 'react';
import styled from 'styled-components';
import CollapsableCard from '../../../../../../components/titles/CollapsableCard';
import DisplaySequence from './DisplaySequence';
import DisplayStringTable from './DisplayStringTable';
import Regions from './Regions';
import { CompanyDisplayCreditContext } from '../providers/companyDisplayCreditsProvider';

export interface CompanyDisplayCreditCardProps {
	index: number;
	editMode: boolean;
	defaultLocale: string | undefined;
}

const FlexWrapper = styled.div(
	({ theme }) => `
		display: flex;
		gap: ${theme.spacing(2)};
		
		.title-editable-field {
			padding: 0;
		}
	`,
);

export default function CompanyDisplayCreditCard({ editMode, index, defaultLocale }: CompanyDisplayCreditCardProps) {
	const companyDisplayCreditContext = useContext(CompanyDisplayCreditContext);

	return (
		<CollapsableCard
			label={`Company #${index + 1}`}
			showDeleteIcon={editMode}
			onClickDeleteIcon={() => {
				companyDisplayCreditContext.removeCompanyDisplayCredit(index);
				companyDisplayCreditContext.commit();
			}}
		>
			<FlexWrapper>
				<div style={{ width: '70%' }}>
					<Regions companyDisplayCreditDisplayIndex={index} />
				</div>
				<div style={{ width: '30%' }}>
					<DisplaySequence companyDisplayCreditDisplayIndex={index} editMode={editMode} />
				</div>
			</FlexWrapper>
			<DisplayStringTable editMode={editMode} companyDisplayCreditDisplayIndex={index} defaultLocale={defaultLocale} />
		</CollapsableCard>
	);
}
