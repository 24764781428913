import React from 'react';
import { TitleFull as Title } from '@warehouse/title/core';
import ProductMetadataTabLocalizedInfoTab from './localizedInfo';
import SideMenuTabs from '../../../../components/library/SideMenuTabs';
import ProductMetadataTabAncillaryTab from './ancillary';
import ProductMetadataTabTerritoriesTab from './regionalizedInfos';
import ProductMetadataTabCastAndCrewTab from './castAndCrew';
import { CastAndCrewContextProvider } from './castAndCrew/providers/castAndCrewProvider';

interface ProductMetadataTabProps {
	title: Title | undefined;
	editMode: boolean;
	wrapperRef: React.RefObject<HTMLDivElement>;
}

export function ProductMetadataTab({ title, editMode, wrapperRef }: ProductMetadataTabProps) {
	const tabs = [
		{
			label: 'Localized Info',
			urlState: 'product-metadata/localized-info/',
			defaultUrlState: 'product-metadata/localized-info/default',
			component: <ProductMetadataTabLocalizedInfoTab title={title} editMode={editMode} />,
		},
		{
			label: 'Cast & Crew',
			urlState: 'product-metadata/cast-crew/',
			defaultUrlState: 'product-metadata/cast-crew/default',
			component: (
				<CastAndCrewContextProvider title={title}>
					<ProductMetadataTabCastAndCrewTab editMode={editMode} title={title} wrapperRef={wrapperRef} />
				</CastAndCrewContextProvider>
			),
		},
		{
			label: 'Ratings & Releases',
			urlState: 'product-metadata/ratings-releases/',
			defaultUrlState: 'product-metadata/ratings-releases/default',
			component: <ProductMetadataTabTerritoriesTab titleEntity={title} editMode={editMode} />,
		},
		{
			label: 'Ancillary',
			urlState: 'product-metadata/ancillary',
			component: <ProductMetadataTabAncillaryTab title={title} editMode={editMode} />,
		},
	];

	return <SideMenuTabs leftColumnWidth={200} tabs={tabs} />;
}
