import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';

// LIBRARY
import { alpha, Typography, useTheme } from '@mui/material';
import { titlePaths } from '@warehouse/title/core';
import TitleEditableField from '../../../../../components/titles/TitleEditableField';
import { CustomColumnDef } from '../../../../../components/library/SimpleTable/customColumnDef';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import ChipDisplay from '../../../../../components/library/ChipDisplay';

// PROVIDER
import { CastAndCrewContext, TranslatedString, TranslatedStringArray } from './providers/castAndCrewProvider';

// JSON
import castAndCrewRoles from '../../../../../assets/json-administration-profiles/availableRoleType.json';
import { computeCastErrors } from './computeTableErrors';
import Chip from '../../../../../components/library/Chip';
import useTooltip from '../../../../../utils/hooks/useTooltips';

export type Cast = {
	bbo: string;
	name: string;
	role: string;
	characters: string[];
	__uuid: string;
};

interface CastTableProps {
	editMode: boolean;
	localeUuid: string | null;
	origin?: string;
	defaultLocaleUuid?: string;
	onCastClick?: (peopleIndex: number) => void;
	onAddCast?: () => void;
}

const StyledDiv = styled.div(
	({ theme }) => css`
		.table-wrapper {
			margin-left: ${theme.spacing(0.75)};
			margin-right: ${theme.spacing(0.75)};
			width: auto !important;
		}
	`,
);

export const formatName = (
	name: TranslatedString,
	localeUuid: string | null,
	defaultLocaleUuid: string | undefined,
) => {
	if (name[localeUuid || ''] === undefined || '')
		return {
			label:
				name[defaultLocaleUuid || ''] === undefined || name[defaultLocaleUuid || ''] === ''
					? ''
					: `(${name[defaultLocaleUuid || '']})`,
			default: true,
		};

	return { label: name[localeUuid || ''], default: false };
};

function findLargestArrayProperty(obj: TranslatedStringArray | undefined): string | undefined {
	let largestKey: string | undefined;
	let maxLength = -1;

	// eslint-disable-next-line no-restricted-syntax
	for (const key in obj) {
		if (Array.isArray(obj[key]) && obj[key].length > maxLength) {
			maxLength = obj[key].length;
			largestKey = key;
		}
	}

	return largestKey;
}

const formatCharacters = (
	characters: TranslatedStringArray | undefined,
	localeUuid: string | null,
	defaultLocaleUuid: string | undefined,
): { label: string; isDefault: boolean }[] => {
	if (!characters) return [];
	const largestKey = findLargestArrayProperty(characters);
	const result = [];

	for (let i = 0; i < characters?.[largestKey || '']?.length || 0; i += 1) {
		const labelValue = characters?.[localeUuid || '']?.[i];
		if (labelValue === undefined) {
			result.push({
				label: `(${characters?.[defaultLocaleUuid || '']?.[i] ?? ''})`,
				isDefault: true,
			});
		} else {
			result.push({
				label: labelValue,
				isDefault: false,
			});
		}
	}
	return result;
};

function getClassName(isInherited: boolean) {
	return isInherited ? 'inherited' : '';
}

function CastTable({ editMode, localeUuid, origin, defaultLocaleUuid, onCastClick, onAddCast }: CastTableProps) {
	const theme = useTheme();
	const { castData, isInherited } = useContext(CastAndCrewContext);
	const billingBlockOrderTooltip = useTooltip('coreMetadata.basic.people.jobs.billingBlockOrders.billingBlockOrder');
	const nameTooltip = useTooltip('coreMetadata.basic.people.name');
	const roleTooltip = useTooltip('coreMetadata.basic.people.jobs.jobFunction');
	const charactersTooltip = useTooltip('coreMetadata.basic.people.jobs.characterInfos');

	const castAndCrewRolesMapped = useMemo<{
		[k: string]: typeof castAndCrewRoles[number];
	}>(() => castAndCrewRoles.reduce((acc, role) => ({ ...acc, [role.value]: role }), {}), [castAndCrewRoles]);

	const formattedCastData = useMemo(
		() =>
			castData?.map((cast) => ({
				...cast,
				name: formatName(cast.name, localeUuid, defaultLocaleUuid),
				characters: formatCharacters(cast.characters, localeUuid, defaultLocaleUuid),
			})),
		[castData, localeUuid],
	);

	const castColumns: CustomColumnDef<any>[] = useMemo(
		() => [
			{
				id: 'billingBlockOrder',
				tooltip: billingBlockOrderTooltip?.tooltip,
				header: '#',
				accessorFn: (row) => row.billingBlockOrder,
				width: 25,
			},
			{
				id: 'name',
				tooltip: nameTooltip?.tooltip,
				header: 'Name',
				accessorFn: (row) => row.name,
				renderReadOnly: (value) =>
					value.label ? (
						<div style={{ marginLeft: '12px' }}>
							<Typography
								className={getClassName(isInherited)}
								fontWeight={400}
								fontSize="14px"
								color={value.default ? 'text.disabled' : 'text.primary'}
							>
								{value.label}
							</Typography>
						</div>
					) : null,
			},
			{
				id: 'role',
				tooltip: roleTooltip?.tooltip,
				header: 'Role',
				accessorFn: (row) => row.role,
				renderReadOnly: (v) => {
					const role = castAndCrewRolesMapped[v]?.label || v;
					const toDisplay = role ? role.charAt(0).toUpperCase() + role.slice(1) : '';
					return (
						<Typography className={getClassName(isInherited)} fontWeight={400} fontSize="14px">
							{toDisplay}
						</Typography>
					);
				},
			},
			{
				id: 'characters',
				tooltip: charactersTooltip?.tooltip,
				header: 'Characters',
				accessorFn: (row) => row.characters,
				renderReadOnly: (value) =>
					value.length > 0 ? (
						<ChipDisplay
							allowOverflow
							variant={isInherited ? 'prefilled-alt' : 'alt'}
							width={250}
							values={value.map((v: { label: string; isDefault: false }, idx: number) => (
								<Chip
									variant={isInherited ? 'prefilled' : 'default'}
									style={{
										color: v.isDefault ? alpha(theme.palette.dark.main, 0.3) : undefined,
									}}
									key={v.label + idx}
									label={v.label}
								/>
							))}
						/>
					) : null,
				minWidth: 220,
			},
		],
		[isInherited],
	);

	return (
		<StyledDiv>
			<TitleEditableField
				label="Cast"
				editMode={editMode}
				path={titlePaths.people}
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
				ruleOrigin={origin}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<Cast>
				showBottomActionsBar={editMode}
				editMode={false}
				cantSelectRows
				addRowLabel="Add Cast"
				forceHover
				errors={{ __rows: computeCastErrors(castData) }}
				enableAddMultipleRows={false}
				tableStyle="border"
				columns={castColumns}
				data={formattedCastData || []}
				onClickRow={({ peopleIndex }) => {
					if (onCastClick) onCastClick(peopleIndex);
				}}
				onRowAdd={() => {
					if (onAddCast) onAddCast();
				}}
			/>
		</StyledDiv>
	);
}

export default CastTable;
