import React from 'react';
import { Tooltip } from '@mui/material';
import { mdiInformation } from '@mdi/js';
import { flexRender, Header } from '@tanstack/react-table';
import { StyledTH, StyledToolTipIcon } from './style';
import { CustomColumnDef } from './customColumnDef';
import { TableStyle } from './TableStyle';

interface DefaultTableHeadProps {
	ariaColindex?: number;
	header: Header<any, unknown>;
	tableStyle: TableStyle;
}

export function DefaultTableHead({ ariaColindex, header, tableStyle }: DefaultTableHeadProps) {
	return (
		<StyledTH
			key={header.id}
			aria-colindex={ariaColindex}
			colSpan={header.colSpan}
			tableStyle={tableStyle}
			style={{
				...((header.column.columnDef as CustomColumnDef<any>).width
					? { width: `${(header.column.columnDef as CustomColumnDef<any>).width}px` }
					: {}),
				minWidth: `${(header.column.columnDef as CustomColumnDef<any>).minWidth}px`,
				maxWidth: `${(header.column.columnDef as CustomColumnDef<any>).maxWidth}px`,
			}}
		>
			<div>
				{flexRender(
					addRequiredSymbol(header.column.columnDef.header, (header.column.columnDef as CustomColumnDef<any>).required),
					header.getContext(),
				)}
				{(header.column.columnDef as CustomColumnDef<any>).tooltip ? (
					<Tooltip title={(header.column.columnDef as CustomColumnDef<any>).tooltip as string} placement="right">
						<StyledToolTipIcon path={mdiInformation} size="16px" />
					</Tooltip>
				) : null}
			</div>
		</StyledTH>
	);
}

function addRequiredSymbol<T>(header: T, required?: boolean) {
	if (typeof header !== 'string') return header;
	return required ? `${header} *` : header;
}
