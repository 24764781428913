import React from 'react';
import styled, { css } from 'styled-components';
import Pagination from '../library/Pagination';
import ItemsPerPageSelector from '../library/ItemsPerPageSelector';

const BottomPaginationWrapper = styled.div<{
	tableStyle?: 'default' | 'border';
}>(
	({ theme, tableStyle }) => css`
		${tableStyle === 'default' &&
		css`
			border-top: 1px solid ${theme.palette.action.divider};
		`}
		display: grid;
		grid-template-columns: 1fr 3fr 1fr;
		justify-items: center;
		min-height: 35px;
		padding: ${theme.spacing(2)};
	`,
);

interface BottomTablePaginationProps {
	pageSize: number;
	onPageSizeChange: (value: number) => void;
	currentPage: number;
	pageCount: number;
	onPageChange: (value: number) => void;
	tableStyle?: 'default' | 'border';
}

function BottomTablePagination({
	pageSize,
	onPageSizeChange,
	currentPage,
	pageCount,
	onPageChange,
	tableStyle,
}: BottomTablePaginationProps) {
	return (
		<BottomPaginationWrapper tableStyle={tableStyle}>
			<div />
			<Pagination value={currentPage} lastPage={pageCount} onChange={onPageChange} />
			<ItemsPerPageSelector value={pageSize} onChange={onPageSizeChange} />
		</BottomPaginationWrapper>
	);
}

export default BottomTablePagination;
