import { useEffect, useMemo, useState } from 'react';
import { TitleFull as Title } from '@warehouse/title/core';
import { debounce } from '@mui/material';
import { CreateTitleV2Metadata } from '@warehouse/graphql';
import useCreateTitleV2 from '../../utils/hooks/titles/useCreateTitleV2';

interface UseDryRunProps {
	payload: CreateTitleV2Metadata | undefined;
}

function useDryRun({ payload }: UseDryRunProps) {
	const [errors, setErrors] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [createTitleV2] = useCreateTitleV2();

	const [inheritedValues, setInheritedValues] = useState<Title>();

	async function runDryRun(_payload: CreateTitleV2Metadata) {
		setLoading(true);

		if (!_payload.coreMetadata.basic.workType) return;

		try {
			const inheritedValuesTmp = await createTitleV2({
				variables: {
					input: {
						dryRun: true,
						metadata: _payload,
					},
				},
				context: {
					dryRun: true,
				},
			});
			setInheritedValues(JSON.parse(inheritedValuesTmp?.data?.createTitleV2?.state || '') as unknown as Title);
			setErrors([]);
		} catch (e: any) {
			setErrors(
				e?.graphQLErrors?.filter(
					(gqlError: any) => !gqlError.message.startsWith("Variable 'input' has coerced Null value"),
				),
			);
		} finally {
			setLoading(false);
		}
	}

	const debouncedRunDryRun = useMemo(() => debounce(runDryRun, 1000), []);

	useEffect(() => {
		if (!payload) return;
		debouncedRunDryRun(payload);
	}, [payload, debouncedRunDryRun]);

	return {
		inheritedValues,
		loading,
		errors,
		setErrors,
	};
}

export default useDryRun;
