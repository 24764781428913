import React from 'react';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import styled, { css } from 'styled-components';

const ColoredIcon = styled(Icon)(
	({ theme }) => css`
		color: ${theme.palette.dark.background};
	`,
);

function Checked({ checked }: { checked: boolean }) {
	if (!checked) return null;
	return <ColoredIcon path={mdiCheckCircle} size="16px" />;
}

export default Checked;
