import React from 'react';
import { Typography } from '@mui/material';
import { CommonReadonly } from '@warehouse/title/core';
import { TitleCard } from '@warehouse/title/ui';
import { FieldWrapper } from '../../ui/FieldWrapper';

export function ParentField({ titleLight }: ParentFieldProps) {
	return (
		<FieldWrapper>
			<Typography variant="buttonMediumMedium" color="text.primary">
				Parent
			</Typography>
			<TitleCard variant="colored" titleLight={titleLight} />
		</FieldWrapper>
	);
}

type ParentFieldProps = {
	titleLight: CommonReadonly;
};
