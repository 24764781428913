import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { LocalizedInfo, TitleAlternate } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { titlePaths } from '@warehouse/title/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import recordToArray from '../../../../../src/views/title/hooks/useTitleRecordsAutoSave/recordToArray';
import { fromBasic, getTitleData } from '../../../../../src/utils/titleGetProperty';
import { DropdownOption } from '../../../../../src/components/library/Dropdown/types';
import { AlternateTitleTypePicklistContext } from './AlternateTitleContext';
import { AlternateTitlesTableTable } from './AlternateTitlesTableTable';
import useTitleAutoSave from '../../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import availableAlternateTitleTypes from '../../../../../src/assets/json-administration-profiles/availableTitleAlternateType.json';

type AlternateTitlesTableWrapperProps = {
	localizedInfoUuid: string;
	defaultLocale: string | undefined;
};

function getAlternateTitleTypes(localizedInfos: Record<string, LocalizedInfo> | undefined): DropdownOption[] {
	if (!localizedInfos) return [];
	const localizedInfosArray = recordToArray<LocalizedInfo>(localizedInfos);
	const allAlternateTitles: string[] = [];
	localizedInfosArray.forEach((info) => {
		info.titleAlternates?.displayValue?.forEach((alt) => {
			if (!alt.type) return;
			if (!allAlternateTitles.includes(alt.type)) allAlternateTitles.push(alt.type);
		});
	});

	return allAlternateTitles.map((altTitleType) => ({
		label: altTitleType,
		value: altTitleType,
	}));
}

export function AlternateTitlesTableWrapper({ defaultLocale, localizedInfoUuid }: AlternateTitlesTableWrapperProps) {
	const path = useMemo(() => titlePaths.alternateTitles(localizedInfoUuid).split('.'), [localizedInfoUuid]);
	const title = useTitleEditorStore(titleEditorStoreSelector.titleRequired);
	const editMode = useTitleEditorStore(titleEditorStoreSelector.editMode);

	const titleAlternates = useTitleAutoSave<OptionalInherited<TitleAlternate[]>, TitleAlternate>({
		label: 'Alternate Titles',
		path,
		isRowValid: useCallback((e: TitleAlternate) => !!e.titleAlternate.trim(), []),
	});

	const { initializeOptions, options } = useContext(AlternateTitleTypePicklistContext);
	const localizedInfos = getTitleData<Record<string, LocalizedInfo>>(title, fromBasic(['localizedInfos']));

	useEffect(() => {
		if (options.length === availableAlternateTitleTypes.length || options.length === 0) {
			const typesFromTitles = getAlternateTitleTypes(localizedInfos);
			initializeOptions(typesFromTitles);
		}
	}, [titleAlternates]);

	return useMemo(
		() => (
			<AlternateTitlesTableTable
				editMode={editMode}
				localizedInfoUuid={localizedInfoUuid}
				titleAlternatesAutoSave={titleAlternates}
				defaultLocale={defaultLocale}
			/>
		),
		[editMode, titleAlternates, defaultLocale],
	);
}
