import React, { useMemo } from 'react';
import { OriginalLanguage } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Control, FieldErrors, UseFieldArrayAppend, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import OriginalLanguagesTable, { OriginalLanguageCreationType } from '../OriginalLanguagesTable';
import { Locale } from '../../localeType';
import getLabelWithRequired from '../getLabelWithRequired';

interface OriginalLanguagesFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	inheritedValues?: Title | undefined;
	appendOriginalLanguage: UseFieldArrayAppend<any, 'originalLanguages'>;
	defaultLocale?: Locale;
	graphQLError?: string;
	originalLanguages: OriginalLanguageCreationType[];
}

function OriginalLanguagesField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	inheritedValues,
	appendOriginalLanguage,
	graphQLError,
	defaultLocale,
	originalLanguages,
}: OriginalLanguagesFieldProps) {
	const inheritedOriginalLanguages = getTitleData<OptionalInherited<OriginalLanguage[]>>(
		inheritedValues,
		fromBasic(['originalLanguages']),
	);

	return useMemo(
		() => (
			<OriginalLanguagesTable
				errors={errors}
				setValue={setValue}
				isRequired={isRequired}
				trigger={trigger}
				appendLanguage={appendOriginalLanguage}
				defaultLocale={defaultLocale}
				graphQLError={graphQLError}
				controlName="originalLanguages"
				label={getLabelWithRequired('Original Languages', isRequired)}
				languages={originalLanguages}
			/>
		),
		[
			control,
			errors?.originalLanguages?.message,
			setValue,
			trigger,
			inheritedOriginalLanguages?.displayValue,
			appendOriginalLanguage,
			defaultLocale,
			graphQLError,
			originalLanguages,
		],
	);
}

export default OriginalLanguagesField;
