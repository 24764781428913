import React, { useCallback, useEffect } from 'react';
import {
	TitleEditorStoreEffects,
	TitleEditorStoreProvider,
	titleEditorStoreSelector,
	useTitle,
	useTitleEditorStore,
} from '@warehouse/title/domain';
import { LoaderWrapper } from '../../../src/components/library/LoaderWrapper';
import { ParentRelationshipEditorAdapter } from './ParentRelationshipEditor.adapter';

export function ParentRelationshipEditorLoader({
	childUuid,
	parentUuid,
	relationshipType,
	onClose,
}: ParentRelationshipEditorLoaderProps) {
	const childQueryOutput = useTitle({ uuid: childUuid });
	const parentQueryOutput = useTitle({ uuid: parentUuid });

	const SetUuidEffect = useCallback(() => {
		const { setTitleUuid } = useTitleEditorStore(titleEditorStoreSelector.actions);

		// Set titleUuid to the child uuid in the TitleEditorStore
		useEffect(() => {
			setTitleUuid(childUuid);
		}, [setTitleUuid]);

		return null;
	}, [childUuid]);

	if (!childQueryOutput.data || !parentQueryOutput.data) {
		return (
			<div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<LoaderWrapper loading opacity={100} />
			</div>
		);
	}

	return (
		<TitleEditorStoreProvider>
			<TitleEditorStoreEffects />
			<SetUuidEffect />
			<ParentRelationshipEditorAdapter
				parentUuid={parentUuid}
				relationshipTypeId={relationshipType}
				childData={childQueryOutput.data}
				parentData={parentQueryOutput.data}
				onClose={onClose}
			/>
		</TitleEditorStoreProvider>
	);
}

interface ParentRelationshipEditorLoaderProps {
	childUuid: string;
	parentUuid: string;
	relationshipType: string;
	onClose: () => void;
}
