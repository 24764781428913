import { TitleFull as Title } from '@warehouse/title/core';
import { Inherited, OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { fromBasic, getTitleData } from '../../../utils/titleGetProperty';

function getBasic(inheritedValues: Title | undefined) {
	return inheritedValues?.metadata?.coreMetadata?.basic;
}

const validateTitlesData = (
	fields: { field: string; required: boolean }[],
	titlesData: any[],
	setTitlesErrors: (errors: { [columnId: string]: number[] }) => void,
	inheritedValues: Title | undefined,
) => {
	const errors: { [columnId: string]: number[] } = {
		title: [],
		titleLanguage: [],
		countriesOfOrigin: [],
		releaseDate: [],
		licensor: [],
		mode: [],
		originalLanguages: [],
		sortTitle: [],
		approximateLength: [],
		compilationClass: [],
		sequenceNumber: [],
		distributionNumber: [],
		houseSequence: [],
		madeForRegions: [],
		editClass: [],
		editUse: [],
		versionLanguages: [],
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	titlesData.forEach((titleData: any, index: number) => {
		const titleField = fields.find(({ field }) => field === 'title');
		if (
			titleField &&
			titleField.required &&
			!titleData.title?.length &&
			!getTitleData<OptionalInherited<string>>(
				inheritedValues,
				fromBasic(['localizedInfos', '__default', 'titleDisplayUnlimited']),
			)?.displayValue
		) {
			errors.title.push(index);
		}

		const countriesOfOriginField = fields.find(({ field }) => field === 'countriesOfOrigin');
		if (
			countriesOfOriginField &&
			countriesOfOriginField.required &&
			!titleData.countriesOfOrigin?.length &&
			!getBasic(inheritedValues)?.countriesOfOrigin?.displayValue?.length
		) {
			errors.countriesOfOrigin.push(index);
		}

		const distributionNumberField = fields.find(({ field }) => field === 'distributionNumber');
		if (
			distributionNumberField &&
			distributionNumberField.required &&
			!titleData.distributionNumber &&
			!getBasic(inheritedValues)?.sequenceInfo?.distributionNumber?.displayValue?.distributionNumber
		) {
			errors.distributionNumber.push(index);
		}

		const originalLanguagesField = fields.find(({ field }) => field === 'originalLanguages');
		if (
			originalLanguagesField &&
			originalLanguagesField.required &&
			!titleData.originalLanguages?.length &&
			!getBasic(inheritedValues)?.originalLanguages?.displayValue?.length
		) {
			errors.originalLanguages.push(index);
		}

		const releaseDateField = fields.find(({ field }) => field === 'releaseDate');
		if (
			releaseDateField &&
			releaseDateField.required &&
			!titleData.releaseDate &&
			!getBasic(inheritedValues)?.releaseDate?.displayValue
		) {
			errors.releaseDate.push(index);
		}

		const sequenceNumberField = fields.find(({ field }) => field === 'sequenceNumber');
		if (
			sequenceNumberField &&
			sequenceNumberField.required &&
			!titleData.sequenceNumber &&
			getBasic(inheritedValues)?.sequenceInfo?.number?.displayValue === undefined
		) {
			errors.sequenceNumber.push(index);
		}

		const titleLanguageField = fields.find(({ field }) => field === 'titleLanguage');
		if (
			titleLanguageField &&
			titleLanguageField.required &&
			!titleData.titleLanguage &&
			!getTitleData<Inherited<string>>(inheritedValues, fromBasic(['localizedInfos', '__default', 'language']))
				?.displayValue
		) {
			errors.titleLanguage.push(index);
		}

		const editUseField = fields.find(({ field }) => field === 'editUse');
		if (
			editUseField &&
			editUseField.required &&
			!titleData.editUse &&
			!getBasic(inheritedValues)?.versionIntent?.editUse?.displayValue
		) {
			errors.editUse.push(index);
		}

		const editClassField = fields.find(({ field }) => field === 'editClass');
		if (
			editClassField &&
			editClassField.required &&
			!titleData.editClass &&
			!getBasic(inheritedValues)?.versionIntent?.editClasses?.displayValue?.length
		) {
			errors.editClass.push(index);
		}

		const licensorField = fields.find(({ field }) => field === 'licensor');
		if (licensorField && licensorField.required && !titleData.licensor) {
			errors.licensor.push(index);
		}

		const sortTitleField = fields.find(({ field }) => field === 'sortTitle');
		if (
			sortTitleField &&
			sortTitleField.required &&
			!titleData.sortTitle &&
			!getTitleData<OptionalInherited<string>>(inheritedValues, fromBasic(['localizedInfos', '__default', 'titleSort']))
				?.displayValue
		) {
			errors.sortTitle.push(index);
		}

		const houseSequenceField = fields.find(({ field }) => field === 'houseSequence');
		if (
			houseSequenceField &&
			houseSequenceField.required &&
			!titleData.houseSequence &&
			!getBasic(inheritedValues)?.sequenceInfo?.houseSequence?.displayValue?.houseSequence
		) {
			errors.houseSequence.push(index);
		}

		const madeForRegionsField = fields.find(({ field }) => field === 'madeForRegions');
		if (
			madeForRegionsField &&
			madeForRegionsField.required &&
			!titleData.madeForRegions?.length &&
			!getBasic(inheritedValues)?.versionIntent?.madeForRegions?.displayValue?.length
		) {
			errors.madeForRegions.push(index);
		}

		const approximateLengthField = fields.find(({ field }) => field === 'approximateLength');
		if (
			approximateLengthField &&
			approximateLengthField.required &&
			!titleData.approximateLength &&
			!getBasic(inheritedValues)?.runLength?.displayValue
		) {
			errors.approximateLength.push(index);
		}

		const compilationClassField = fields.find(({ field }) => field === 'compilationClass');
		if (compilationClassField && compilationClassField.required && !titleData.compilationClass) {
			errors.compilationClass.push(index);
		}

		const versionLanguagesField = fields.find(({ field }) => field === 'versionLanguages');
		if (
			versionLanguagesField &&
			versionLanguagesField.required &&
			!titleData.versionLanguages?.length &&
			!getBasic(inheritedValues)?.versionLanguages?.displayValue?.length
		) {
			errors.versionLanguages.push(index);
		}
	});

	setTitlesErrors(errors);

	return Object.keys(errors).every((key) => !errors[key].length);
};

export default validateTitlesData;
