import React from 'react';
import styled, { css } from 'styled-components';
import { useDroppable } from '@dnd-kit/core';
import ContentLibrary from '../../../components/ContentLibrary';
import { Items } from '../../../components/ContentLibrary/ContentLibTreeView';

const Wrapper = styled.div`
	background-color: white;
	height: 100%;
	width: 400px;

	${({ theme }) => css`
		padding: ${theme.spacing(2)};
	`}
`;

function SideMenu({ items }: { items: Items[] }) {
	const { setNodeRef } = useDroppable({
		id: 'library',
	});

	return (
		<Wrapper ref={setNodeRef}>
			<ContentLibrary data={items} />
		</Wrapper>
	);
}

export default SideMenu;
