import React, { ReactNode, CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import NxLoader from './NxLoader';

export interface LoaderWrapperProps {
	children?: ReactNode;
	style?: CSSProperties;
	loading?: boolean;
	opacity?: number;
}

const Wrapper = styled.div(
	() => css`
		position: relative;
	`,
);

const ChildrenWrapper = styled.div<{ $loading: boolean; $opacity: number }>(
	({ $loading, $opacity }) => css`
		opacity: ${$loading ? $opacity : 1};
		transition: opacity 300ms ease-out;
	`,
);

const LoaderIconWrapper = styled.div<{ $loading: boolean }>(
	({ $loading }) => css`
		left: 50%;
		opacity: 1;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: visibility 0s ease-out, opacity 300ms ease-out;
		visibility: visible;

		${!$loading &&
		css`
			opacity: 0;
			transition: opacity 300ms ease-out, visibility 0s ease-out 300ms;
			visibility: hidden;
		`}
	`,
);

export function LoaderWrapper({ children, style, loading = false, opacity = 0 }: LoaderWrapperProps) {
	return (
		<Wrapper style={style}>
			<ChildrenWrapper $loading={loading} $opacity={opacity}>
				{children}
			</ChildrenWrapper>
			<LoaderIconWrapper $loading={loading}>
				<NxLoader />
			</LoaderIconWrapper>
		</Wrapper>
	);
}
