import { TitleFull as Title } from '@warehouse/title/core';
import { JsonPointer } from '../../../../utils/getDeepProperty';
import { CommonEditObject } from '../../contexts/TitleAutoSaveQueueContext';
import { getTitleData, mapPathDefaultAndFirstToUuid } from '../../../../utils/titleGetProperty';
import { nestedRecordToArray, WithUuid } from './recordToArray';

export function buildCommonPayload(path: JsonPointer, title: Title | undefined, label: string): CommonEditObject {
	return {
		path: getFormattedPath(path, title)!,
		titleUuid: title?.uuid,
		label,
	};
}

export function getFormattedPath(path: JsonPointer, title: Title | undefined) {
	if (!title) return undefined;
	return mapPathDefaultAndFirstToUuid(path.join('.'), title)?.split('.');
}

export function dataToState<T>(title: Title | undefined, path: JsonPointer): WithUuid<T>[] {
	return nestedRecordToArray(getTitleData<Record<string, T>>(title, path));
}

export function withNewUuid<T>(record: T, newUuid: string): WithUuid<T> {
	return { ...record, __uuid: newUuid };
}

export function isATmpRow<T>(row: WithUuid<T>) {
	return row.__uuid.startsWith('tmp-');
}
