import React from 'react';
import styled, { css } from 'styled-components';

type InputsGroupProps = {
	children?: React.ReactNode;
};

const Wrapper = styled.div(
	() => css`
		display: flex;
		> * {
			flex-grow: 1;
			flex-basis: 0;
		}

		.input-wrapper,
		input.first {
			border-radius: 0px;
		}

		.first .input-wrapper,
		input.first {
			border-bottom-left-radius: 8px;
			border-top-left-radius: 8px;
		}

		.last .input-wrapper,
		input.last {
			.input-wrapper-active {
				border-bottom-right-radius: 0px !important;
			}
			border-bottom-right-radius: 8px !important;
			border-top-right-radius: 8px;
		}
	`,
);

function InputsGroup(props: InputsGroupProps) {
	const { children } = props;
	return <Wrapper>{children}</Wrapper>;
}

export default InputsGroup;
