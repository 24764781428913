import React from 'react';
import { AssociatedOrg } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';

// TYPES
import { CellContext } from '@tanstack/react-table';

// LIBRARY
import { DropdownV2, DropdownV2Option } from '@warehouse/shared/ui';
import { CellWrapper } from '../../../../../../../components/library/SimpleTable/style';

function RoleCell({
	info,
	options,
	onNewOptionAdded,
}: {
	info: CellContext<any, any>;
	options: DropdownV2Option[];
	onNewOptionAdded: (_value: string) => void;
}) {
	const { row, getValue, table } = info;
	const value = getValue()?.role;
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options?.meta?.customPropsCell.isInherited;

	return (
		<CellWrapper>
			<DropdownV2
				transparentBorder
				variant={isInherited ? 'prefilled' : 'default'}
				backgroundColor="transparent"
				pickList
				withSearch
				value={value}
				options={options}
				onBlur={() => commit()}
				onChange={(e) => {
					setValue((prev: any) => {
						const updatedDisplayValue = prev.displayValue?.map((ao: AssociatedOrg, index: number) => {
							if (index === row.index) {
								return {
									...ao,
									role: e,
								};
							}
							return ao;
						});
						return {
							...prev,
							displayValue: updatedDisplayValue,
						};
					});
				}}
				onNewOptionAdded={onNewOptionAdded}
				forbiddenPickListValues={['licensor']}
			/>
		</CellWrapper>
	);
}

export default RoleCell;
