import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import TextInput from '../../../../components/library/TextInput';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface SequenceNumberFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	inheritedValues?: Title | undefined;
}

function SequenceNumberField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	inheritedValues,
}: SequenceNumberFieldProps) {
	const sequenceNumberTooltip = useTooltip('coreMetadata.basic.sequenceInfo.number');
	const inheritedSequenceNumber = getTitleData<OptionalInherited<number>>(
		inheritedValues,
		fromBasic(['sequenceInfo', 'number']),
	);

	return useMemo(
		() => (
			<Controller
				name="sequenceNumber"
				control={control}
				render={({ field }) => (
					<TextInput
						ariaLabel="Sequence Number"
						tooltip={sequenceNumberTooltip?.tooltip}
						placeholder={inheritedSequenceNumber?.displayValue?.toString()}
						variant={inheritedSequenceNumber?.displayValue && field.value === undefined ? 'prefilled' : 'default'}
						type="number"
						error={errors?.sequenceNumber?.message as string}
						value={field.value}
						onChange={async (e) => {
							setValue('sequenceNumber', e.target.value);
							if (isRequired && inheritedSequenceNumber?.displayValue === undefined) {
								await trigger('sequenceNumber');
							}
						}}
						label={getLabelWithRequired('Sequence #', isRequired)}
					/>
				)}
			/>
		),
		[control, errors?.sequenceNumber?.message, inheritedSequenceNumber?.displayValue, setValue, trigger],
	);
}

export default SequenceNumberField;
