import React, { CSSProperties } from 'react';
import { replaceUUID } from '@warehouse/shared/util';
import Alert from '../../../src/components/library/Alert';

export function ErrorAlert({ errorMessage, onClose, style }: ErrorAlertProps) {
	const formattedMessage = replaceUUID(errorMessage);

	return (
		<Alert style={{ margin: 0, ...style }} onClose={onClose} severity="error">
			{formattedMessage}
		</Alert>
	);
}

interface ErrorAlertProps {
	errorMessage: string;
	onClose?: () => void;
	style?: CSSProperties;
}
