import { Dispatch, SetStateAction } from 'react';
import { TitleFull as Title } from '@warehouse/title/core';
import { useEditPreventer } from '@warehouse/title/feature-edit-preventer-popup';
import { WithUuid } from './recordToArray';
import { EditObject, OnAddSuccessFn } from '../../contexts/TitleAutoSaveQueueContext';
import { JsonPointer } from '../../../../utils/getDeepProperty';
import { withTmpUuid } from './uuidUtils';
import { buildCommonPayload, getFormattedPath } from './common';

interface UseAddProps<T> {
	value: WithUuid<T>[];
	setValue: Dispatch<SetStateAction<WithUuid<T>[]>>;
	setDirtyValue: Dispatch<SetStateAction<WithUuid<T>[] | undefined>>;
	title: Title | undefined;
	path: JsonPointer;
	label: string;
	isValidRow: (row: WithUuid<T>) => boolean;
	addToQueue: (editObject: EditObject<T>) => void;
}

export default function useAdd<T>({
	addToQueue,
	value,
	setValue,
	setDirtyValue,
	isValidRow,
	title,
	path,
	label,
}: UseAddProps<T>) {
	const { act } = useEditPreventer();
	const onAddSuccess: OnAddSuccessFn = (record: unknown) => {
		setDirtyValue(undefined);
		setValue((prev) => [...(prev || []), record as WithUuid<T>]);
	};

	const addRecord = (el: T) => {
		const elToAdd = withTmpUuid(el);

		setDirtyValue((prev) => [...(prev || value || []), elToAdd]);

		if (!isValidRow(elToAdd) || !title || !getFormattedPath(path, title)) return;

		act({
			save: () =>
				addToQueue({
					mutation: 'add',
					value: el,
					onSuccess: onAddSuccess,
					...buildCommonPayload(path, title, label),
				}),
			onDenied: () => {
				setDirtyValue(undefined);
			},
			current: el,
			title,
			path,
		});
	};

	return {
		addRecord,
	};
}
