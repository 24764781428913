import React, { useCallback, useMemo, useState } from 'react';
import { Row } from '@tanstack/react-table';
import { isInherited, TitleFull as Title } from '@warehouse/title/core';
import { ContentIdentifier } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/ContentIdentifier';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

// HOOK
import { makeItInherited } from '@warehouse/title/domain';
import useTitleRecordsAutoSave from '../../../../../hooks/useTitleRecordsAutoSave';
import useMemoJsonPath from '../../../../../hooks/useMemoJsonPath';
import { fromBasic, getTitleData } from '../../../../../../../utils/titleGetProperty';
import { WithUuid } from '../../../../../hooks/useTitleRecordsAutoSave/recordToArray';

// LIBRARY
import TitleEditableField from '../../../../../../../components/titles/TitleEditableField';
import { CustomColumnDef } from '../../../../../../../components/library/SimpleTable/customColumnDef';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import deepEquality from '../../../../../../../utils/deepEquality';

import CellIdentifier from './CellIdentifier';
import CellNamespace from './CellNamespace';
import CellLocation from './CellLocation';
import CellScope from './CellScope';
import CellSubScope from './CellSubScope';
import computeErrors from './computeErrors';
import {
	RenderReadOnlyIdentifier,
	RenderReadOnlyLocation,
	RenderReadOnlyNamespace,
	RenderReadOnlyScope,
	RenderReadOnlySubScope,
} from './ReadOnlyRenderers';
import { sortScope, sortNamespace, sortLocation, sortIdentifier } from './sortingFns';
import useTooltip from '../../../../../../../utils/hooks/useTooltips';
import { isATmpRow } from '../../../../../hooks/useTitleRecordsAutoSave/common';

export interface AlternateIDsProps {
	titleEntity?: Title;
	editMode: boolean;
}

export function AlternateIDs({ titleEntity, editMode }: AlternateIDsProps) {
	const [internalRows, setInternalRows] = useState<Inherited<WithUuid<ContentIdentifier>>[]>([]);

	const altIdentifier = useTitleRecordsAutoSave<Inherited<ContentIdentifier>>({
		path: useMemoJsonPath(fromBasic(['altIdentifiers'])),
		label: 'Alternate IDs',
		isValidRow: (row) =>
			row.displayValue.identifier !== undefined &&
			row.displayValue.identifier.trim() !== '' &&
			row.displayValue.namespace !== undefined &&
			row.displayValue.namespace.trim() !== '',
	});
	const identifierTooltip = useTooltip('coreMetadata.basic.altIdentifiers.identifier');
	const namespaceTooltip = useTooltip('coreMetadata.basic.altIdentifiers.namespace');
	const locationTooltip = useTooltip('coreMetadata.basic.altIdentifiers.location');
	const scopeTooltip = useTooltip('coreMetadata.basic.altIdentifiers.scope.scope');
	const subscopeTooltip = useTooltip('coreMetadata.basic.altIdentifiers.scope.subscope');

	const workType = getTitleData<string | undefined>(titleEntity, useMemoJsonPath(fromBasic(['workType'])));
	const workTypeDetails = getTitleData<string[] | undefined>(
		titleEntity,
		useMemoJsonPath(fromBasic(['workTypeDetails'])),
	);

	const columns = useMemo<CustomColumnDef<Inherited<ContentIdentifier>>[]>(
		() => [
			{
				id: 'identifier',
				header: 'Identifier',
				tooltip: identifierTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlyIdentifier,
				sortingFn: sortIdentifier,
				cell: CellIdentifier,
				width: 170,
			},
			{
				id: 'namespace',
				header: 'Namespace',
				tooltip: namespaceTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlyNamespace,
				sortingFn: sortNamespace,
				cell: CellNamespace,
				width: 130,
			},
			{
				id: 'location',
				header: 'Location',
				tooltip: locationTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlyLocation,
				sortingFn: sortLocation,
				cell: CellLocation,
				width: 150,
			},
			{
				id: 'scope',
				header: 'Scope',
				tooltip: scopeTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlyScope,
				sortingFn: sortScope(workType, workTypeDetails?.[0]),
				cell: CellScope,
				width: 150,
			},
			{
				id: 'subscope',
				header: 'Subscope',
				tooltip: subscopeTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlySubScope,
				cell: CellSubScope,
				width: 150,
			},
		],
		[
			identifierTooltip?.tooltip,
			locationTooltip?.tooltip,
			namespaceTooltip?.tooltip,
			scopeTooltip?.tooltip,
			subscopeTooltip?.tooltip,
			workType,
			workTypeDetails,
		],
	);

	const handleInternalRowChange = (rows: Row<Inherited<WithUuid<ContentIdentifier>>>[]) => {
		if (
			!deepEquality(
				rows.map((row) => row.original),
				internalRows,
			)
		) {
			setInternalRows(rows.map((row) => row.original));
		}
	};

	const handleRowAdd = useCallback(() => {
		altIdentifier.addRecord(
			makeItInherited({
				identifier: '',
				namespace: '',
				scope: {
					scope: workType,
					subscope: workTypeDetails?.[0],
				},
			}),
		);
	}, [altIdentifier, workType, workTypeDetails]);

	const handleRowDelete = useCallback(
		(selectedIndexes: string[]) => {
			altIdentifier.removeRecord(selectedIndexes);
		},
		[altIdentifier],
	);

	const errors = useMemo(() => (editMode ? computeErrors(internalRows) : {}), [editMode, internalRows]);

	return (
		<div>
			<TitleEditableField
				label="Alternate IDs"
				path="coreMetadata.basic.alternateIdentifiers"
				direction="column"
				readComponent={null}
				editComponent={null}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<Inherited<ContentIdentifier>>
				selectionKey="__uuid"
				sorting={[
					{ id: 'scope', desc: false },
					{ id: 'namespace', desc: false },
					{ id: 'location', desc: false },
					{ id: 'identifier', desc: false },
				]}
				isRowReadonlyFn={(row) => isInherited({ inheritedObject: row }).isInherited}
				editMode={editMode}
				showBottomActionsBar={editMode}
				columns={columns}
				data={editMode ? altIdentifier.data : altIdentifier.readOnlyValue ?? []}
				canAddRow={!altIdentifier.data?.some?.((row) => isATmpRow(row))}
				tableStyle="border"
				onRowAdd={handleRowAdd}
				onRowDelete={handleRowDelete}
				customPropsCell={{
					editRecord: altIdentifier.editRecord,
				}}
				onInternalRowsChange={handleInternalRowChange}
				errors={errors}
				selectRowModeByDefault
				enableAddMultipleRows={false}
				noOverflow
			/>
		</div>
	);
}
