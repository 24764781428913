import _ from 'lodash';

export function deepDeleteKey<T = unknown>(object: T, keyToDelete: string): T {
	const obj = _.cloneDeep(object);

	if (_.isObject(obj)) {
		_.forEach(obj, (value, key) => {
			if (key === keyToDelete) {
				delete obj[key as keyof typeof obj];
			} else {
				(obj as any)[key] = deepDeleteKey(value, keyToDelete);
			}
		});
	} else if (_.isArray(obj)) {
		return obj.map((value) => deepDeleteKey(value, keyToDelete)) as T;
	}
	return obj;
}
