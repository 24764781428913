import styled from 'styled-components';
import { DialogContent } from '@mui/material';

const ScrollableDialogContent = styled(DialogContent)`
	display: flex;
	flex: 1;
	flex-direction: column;
	min-height: 500px;
	overflow: scroll;
`;

export default ScrollableDialogContent;
