import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { TitleFull } from '@warehouse/title/core';
import DisplayTitleField from '../fields/DisplayTitle';
import ParentField from '../fields/ParentField';
import WorkTypeDetailField from '../fields/WorkTypeDetail';

interface EpisodeProps {
	workType: string;
	watch: UseFormWatch<any>;
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	parentTitle?: {
		title: IndexedTitleV2 | undefined;
		parentType: string | undefined;
	};
	selectedParentTitle?: TitleFull;
}

function Episode({
	workType,
	watch,
	control,
	errors,
	setValue,
	trigger,
	parentTitle,
	selectedParentTitle,
}: EpisodeProps) {
	const sequenceNumber = watch('sequenceNumber');
	const licensor = watch('licensor');

	return (
		<div>
			<WorkTypeDetailField
				workType={workType}
				control={control}
				setValue={setValue}
				trigger={trigger}
				errors={errors}
				isRequired
			/>
			<DisplayTitleField
				titlePlaceholder={sequenceNumber ? `Episode ${sequenceNumber}` : undefined}
				control={control}
				setValue={setValue}
				errors={errors}
				trigger={trigger}
				isRequired={sequenceNumber === undefined || sequenceNumber === ''}
			/>
			<ParentField
				control={control}
				setValue={setValue}
				trigger={trigger}
				licensor={licensor}
				errors={errors}
				parentTitle={parentTitle}
				workType={workType}
				selectedParentTitle={selectedParentTitle}
				isRequired
			/>
		</div>
	);
}

export default Episode;
