import { SetStateAction } from 'react';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { deepEqualityWithOrWithoutInheritance } from '@warehouse/shared/util';

export function hasInheritedStructure(input: unknown): input is Inherited<unknown> {
	return typeof input === 'object' && input !== null && ('inherited' in input || 'displayValue' in input);
}

export function removeInheritanceStructure<T>(payload: T) {
	if (typeof payload !== 'object' || Array.isArray(payload) || payload === null || payload === undefined) {
		return payload;
	}

	const copy = { ...payload };

	Object.keys(copy || {}).forEach((key) => {
		const castedKey = key as keyof T;
		if (hasInheritedStructure(copy[castedKey])) {
			copy[castedKey] = (copy[castedKey] as any).displayValue;
		} else if (key === '__uuid') {
			delete copy[castedKey];
		} else {
			copy[castedKey] = removeInheritanceStructure(copy[castedKey]);
		}
	});

	return copy;
}

export function trimString<T>(value: T): any {
	if (typeof value === 'string') {
		return value;
	}
	if (hasInheritedStructure(value) && typeof value.displayValue === 'string') {
		return {
			...value,
			displayValue: value.displayValue === '' ? null : value.displayValue,
		};
	}
	return value;
}

export function isFunction<T>(_valueOrFunction: SetStateAction<T>): _valueOrFunction is (prevState: T) => T {
	return typeof _valueOrFunction === 'function';
}

export function defaultIsNewValueDifferent<T>(newValue: T | undefined, currentValue: T | undefined): boolean {
	return !deepEqualityWithOrWithoutInheritance(trimString(newValue), currentValue);
}

export function filterInvalidRow<T, RowType = T>(
	e: T | undefined,
	isRowValid?: (row: RowType) => boolean,
): T | undefined {
	if (!e || !isRowValid) return e;
	if (Array.isArray(e)) {
		return e.filter(isRowValid) as T;
	}
	if (hasInheritedStructure(e) && Array.isArray(e.displayValue)) {
		return {
			...e,
			displayValue: e.displayValue?.filter(isRowValid),
		};
	}
	return e;
}

export function hasInvalidRow<T, RowType = T>(e: T | undefined, isRowValid?: (row: RowType) => boolean): boolean {
	if (!e || !isRowValid) return false;
	if (Array.isArray(e)) return !e.every(isRowValid);
	if (hasInheritedStructure(e) && Array.isArray(e.displayValue)) return !e.displayValue.every(isRowValid);
	return false;
}

export function getRealValueFromInheritance<T>(value: T | Inherited<T> | OptionalInherited<T>) {
	return hasInheritedStructure(value) ? value.displayValue : value;
}
