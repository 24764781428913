import React, { useMemo } from 'react';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiVideo, mdiVolumeHigh, mdiTextBox } from '@mdi/js';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import UpdateIcon from '@mui/icons-material/Update';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';

// GRAPHQL
import { Maybe } from 'graphql/jsutils/Maybe';

// LIBRARY
import StatusChips from '../../../../../components/library/StatusChips';
import Tag from '../../../../../components/library/Tag';
import { dateToString, stringToDate } from '../../../../../components/library/DatePicker/DatePicker';
import Tooltip from '../../../../../components/library/Tooltip';

const TrackIconWrapper = styled.div`
	display: flex;
`;

const StyledIcon = styled(Icon)(
	({ theme }) => css`
		color: ${alpha(theme.palette.dark.main, 0.5)};
	`,
);

const getColumns = (isFake?: boolean): ColumnDef<Maybe<any>, any>[] =>
	useMemo(
		() => [
			{
				id: 'trackType',
				header: 'Track',
				accessorFn: (row) => row.trackType,
				maxSize: 40,
				cell: (info) => {
					const { trackType } = info.row.original;
					if (trackType === 'VIDEO_TRACK')
						return (
							<TrackIconWrapper>
								<StyledIcon path={mdiVideo} size="24px" />
							</TrackIconWrapper>
						);

					if (trackType === 'AUDIO_TRACK')
						return (
							<TrackIconWrapper>
								<StyledIcon path={mdiVolumeHigh} size="24px" />
							</TrackIconWrapper>
						);
					return (
						<TrackIconWrapper>
							<StyledIcon path={mdiTextBox} size="24px" />
						</TrackIconWrapper>
					);
				},
			},
			{
				id: 'name',
				header: 'Name',
				accessorFn: (row) => row.trackName || 'NAME',
				minSize: 150,
			},
			{
				id: 'fileStatus',
				header: 'File Status',
				minSize: 100,
				cell: (info) => {
					const fileStatus = info.row.original?.asset?.fileStatus || null;
					const isProxyReady = info.row.original.proxyStatus === 'READY';
					let chip = null;

					switch (fileStatus) {
						case 'AVAILABLE':
							chip = (
								<StatusChips
									variant="outlined"
									label="Available"
									color={isProxyReady ? 'success' : 'warning'}
									icon={<CheckCircleOutline />}
								/>
							);
							if (!isProxyReady)
								return (
									<Tooltip title="Proxy is pending" placement="top">
										<div>{chip}</div>
									</Tooltip>
								);
							return chip;
						case 'PENDING':
							return <StatusChips variant="outlined" label="Pending" color="info" icon={<UpdateIcon />} />;
						case 'REJECTED':
							return <StatusChips variant="outlined" label="Rejected" color="error" icon={<DoDisturbAltIcon />} />;
						case 'ERROR':
							return <StatusChips variant="outlined" label="Rejected" color="error" icon={<WarningIcon />} />;
						case 'MISSING':
							return <StatusChips variant="outlined" label="Rejected" color="warning" icon={<ErrorOutlineIcon />} />;
						default:
							return null;
					}
				},
			},
			...(isFake
				? [
						{
							id: 'assignmentStatus',
							header: 'Assignment Status',
							accessorFn: (row: any) => row.assignmentStatus,
							minSize: 150,
							cell: (info: CellContext<any, any>) => {
								const { assignmentStatus } = info.row.original;
								return (
									<Tag $uppercase={false} nxstyle="default" height={25}>
										{assignmentStatus}
									</Tag>
								);
							},
						},
				  ]
				: []),
			{
				id: 'ingestedAt',
				header: 'Date Received / Date Expected',
				accessorFn: (row) => (row?.asset?.ingestedAt ? dateToString(stringToDate(row.asset.ingestedAt)) : '-/-'),
			},
			...(isFake
				? [
						{
							id: 'derived',
							header: 'Derived',
							accessorFn: (row: any) => row.derived,
							cell: (info: CellContext<any, any>) => {
								const { derived } = info.row.original;
								if (derived) return <StyledIcon path={mdiCheckCircle} size="24px" />;
								return null;
							},
						},
				  ]
				: []),
			{
				id: 'fileTrackId',
				header: 'File Track ID',
				accessorFn: (row) => row?.asset?.uuid || 'uuid',
			},
		],
		[isFake],
	);

export default getColumns;
