import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Typography } from '@mui/material';
import availableRelationshipTypes from '../../../assets/json-administration-profiles/availableRelationshipTypes.json';
import Dropdown from '../../../components/library/Dropdown/Dropdown';
import { TitleInfoWrapper } from './styles';

interface RelationshipTypeFieldProps {
	isRequired?: boolean;
	relationshipType: string | undefined;
	setRelationshipType: (relationshipType: string | undefined) => void;
	setParentTitleErrors: Dispatch<SetStateAction<string>>;
}

function RelationshipTypeField({
	isRequired = false,
	relationshipType,
	setRelationshipType,
	setParentTitleErrors,
}: RelationshipTypeFieldProps) {
	const filteredRelationshipTypesOptions = availableRelationshipTypes.filter((type) =>
		[
			'isbasedon',
			'iscompositeof',
			'isdescendentof',
			'ispieceof',
			'ispromotionfor',
			'issupplementto',
			'isclipof',
		].includes(type.value),
	);

	return useMemo(
		() => (
			<TitleInfoWrapper>
				<Typography variant="s2Medium" color="text.primary">
					Relationship Type
				</Typography>
				<div style={{ width: '180px' }}>
					<Dropdown
						withSearch
						enablePortal
						height={38}
						error={isRequired && !relationshipType ? 'The type is required' : undefined}
						canReset
						options={filteredRelationshipTypesOptions}
						value={relationshipType || null}
						onChange={(v) => {
							if (!v) {
								setParentTitleErrors('');
							}
							setRelationshipType(v || undefined);
						}}
					/>
				</div>
			</TitleInfoWrapper>
		),
		[relationshipType, isRequired],
	);
}

export default RelationshipTypeField;
