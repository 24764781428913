export default function computeErrors<T>(obj: T[], key: keyof T) {
	return (
		obj
			?.map((value, index) => ({
				...value,
				index,
			}))
			.filter((value) => !value[key])
			.map((value) => value.index) || []
	);
}
