import { TitleFull as Title, RelationshipType } from '@warehouse/title/core';
import { IndexedTitleV2, TitleIndexedPartOfCompilation } from '@warehouse/graphql';
import { indexedTitleToCommonReadonly } from '@warehouse/title/infra';
import { ChildrenColumnType } from '../children-compilation-extended.model';

export function mapEntryClassAndEntryNumberToData(data: IndexedTitleV2[], compilation: Title): ChildrenColumnType[] {
	return data.map((child) => {
		const compilationObjectEntry = getCompilationObjectEntry(child, compilation.uuid);

		return {
			entryClass: compilationObjectEntry?.compilationEntryClass?.label,
			entryNumber: compilationObjectEntry?.compilationEntryNumber,
			...indexedTitleToCommonReadonly(child),
			selectedParent: {
				contentId: compilation.uuid,
				primary: false,
				relationshipType: RelationshipType.EntryOf,
				sequenceInfo: {},
			},
		};
	});
}

function getCompilationObjectEntry(
	child: IndexedTitleV2,
	compilationUuid: string,
): TitleIndexedPartOfCompilation | undefined {
	return child.partOfCompilations?.find((compilation) => compilation.compilationId === compilationUuid);
}
