import React from 'react';
import { Cell, Row } from '@tanstack/react-table';
import { useTheme } from '@mui/material';
import { StyledTD } from './style';
import { ErrorHandlingMode } from './ErrorHandlingMode';
import { CustomColumnDef } from './customColumnDef';

interface SimpleRowProps {
	row: Row<any>;
	errors: { [p: string]: number[] };
	warnings: { [p: string]: number[] };
	rowIndex: number;
	renderSelectedRowCell: (cell: any) => React.ReactNode;
	paddedCells: boolean;
	editable: boolean | undefined;
	errorHandlingMode?: ErrorHandlingMode;
}
function SimpleRow({
	row,
	errors,
	warnings,
	renderSelectedRowCell,
	rowIndex,
	paddedCells,
	editable,
	errorHandlingMode = ErrorHandlingMode.Default,
}: SimpleRowProps) {
	const theme = useTheme();

	const applyErrorStyle = (cell: Cell<any, unknown>): { [key: string]: string } =>
		errors?.[cell.column.id]?.includes(rowIndex) && errorHandlingMode === ErrorHandlingMode.MultiTitleCreate
			? {
					backgroundColor:
						// @ts-ignore
						theme.palette.error.background,
					border: `solid 1px ${theme.palette.error.main}`,
					boxSizing: 'border-box',
			  }
			: {};

	const applyWarningStyle = (cell: Cell<any, unknown>): { [key: string]: string } =>
		warnings?.[cell.column.id]?.includes(rowIndex)
			? {
					backgroundColor:
						// @ts-ignore
						theme.palette.warning.background,
					boxSizing: 'border-box',
			  }
			: {};

	const applyActionStyle = (cell: Cell<any, unknown>): { [key: string]: string } =>
		cell.id.includes('actions') ? { width: '48px', textAlign: 'center' } : {};

	const computePaddingLeft = (index: number) => {
		if (paddedCells) return 8;
		return index === 0 ? row.depth * 20 : undefined;
	};

	return (
		<>
			{row.getVisibleCells().map((cell, index) => (
				<StyledTD
					role="cell"
					editable={editable}
					data-testid={`cell-${cell.column.columnDef.id}`}
					key={cell.id}
					className={`${
						errorHandlingMode === ErrorHandlingMode.Default && errors?.[cell.column.id]?.includes(rowIndex)
							? 'errored'
							: ''
					}`}
					style={{
						...applyActionStyle(cell),
						...applyErrorStyle(cell),
						...applyWarningStyle(cell),
					}}
				>
					<div
						style={{ paddingLeft: computePaddingLeft(index), height: '100%' }}
						className={shouldHighlight(cell, editable) ? 'highlighted-cell' : ''}
					>
						{renderSelectedRowCell(cell)}
					</div>
				</StyledTD>
			))}
		</>
	);
}

export default SimpleRow;

function shouldHighlight(cell: Cell<any, unknown>, editable: boolean | undefined) {
	const columnDef = cell.column.columnDef as CustomColumnDef<unknown>;

	if (!columnDef.highlightOptions) return false;
	if (!columnDef.highlightOptions.highlight) return false;

	if (!editable && columnDef.highlightOptions?.onlyOnEditMode) return false;

	return columnDef.highlightOptions?.highlight;
}
