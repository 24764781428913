import { TitleLightWithSelectedParent } from '@warehouse/title/core';
import { CustomColumnDef } from '../../../../src/components/library/SimpleTable/customColumnDef';

export function DistributionNumberColumn<T extends TitleLightWithSelectedParent>(): CustomColumnDef<T, string> {
	return {
		header: 'Distribution Number',
		accessorFn: (row) => row.selectedParent.sequenceInfo.distributionNumber || '',
		id: 'distributionNumber',
		renderReadOnly: (v) => v,
		editable: false,
	};
}

export function HouseSequenceColumn<T extends TitleLightWithSelectedParent>(): CustomColumnDef<T, string> {
	return {
		header: 'House Sequence',
		accessorFn: (row) => row.selectedParent.sequenceInfo.houseSequence || '',
		id: 'houseSequence',
		renderReadOnly: (v) => v,
		editable: false,
	};
}
