import React from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { TitleFull as Title, isInherited } from '@warehouse/title/core';
import styled, { css } from 'styled-components';
import { DropdownV2 } from '@warehouse/shared/ui';
import TitleEditableField from '../../../../../../components/titles/TitleEditableField';
import ChipDisplay from '../../../../../../components/library/ChipDisplay';
import SpokenLanguages from '../../../../../../assets/json-administration-profiles/availableLanguageSpoken.json';
import IndexedSpokenLanguages from '../../../../../../assets/json-administration-profiles/availableLanguageSpoken.map.value.json';
import useTitleAutoSave from '../../../../hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../../../../hooks/useMemoJsonPath';
import { fromBasic } from '../../../../../../utils/titleGetProperty';
import { sortAlphabetically } from '../../../../../../utils/sortAlphabeticallyByLabel';

type VersionLanguagesProps = {
	editMode: boolean;
	titleEntity: Title | undefined;
};

function sortUuidAlphabeticallyByLabel(a: LocaleUuid, b: LocaleUuid) {
	const aLabel = IndexedSpokenLanguages[a]?.label || '';
	const bLabel = IndexedSpokenLanguages[b]?.label || '';

	return aLabel.localeCompare(bLabel);
}

const options = SpokenLanguages.map((language) => ({
	value: language.uuid,
	label: language.label,
}));

type LocaleUuid = keyof typeof IndexedSpokenLanguages;

const Wrapper = styled.div(
	({ theme }) => css`
		.title-editable-field {
			padding: 0 !important;
			width: 100%;
		}

		margin-bottom: ${theme.spacing(1)};
	`,
);

export default function VersionLanguages({ editMode, titleEntity }: VersionLanguagesProps) {
	const { value, setValue, readOnlyValue, commit } = useTitleAutoSave<OptionalInherited<LocaleUuid[]>>({
		label: 'Version Languages',
		path: useMemoJsonPath(fromBasic(['versionLanguages'])),
		title: titleEntity,
	});

	const onChange = (newValue: LocaleUuid[]) =>
		setValue((prev) => ({
			...prev,
			displayValue: newValue.sort(sortUuidAlphabeticallyByLabel),
		}));

	const mapLanguageUuidToLabel = (uuid: string) =>
		(IndexedSpokenLanguages as Record<string, { label: string }>)[uuid]?.label || '';

	const isInheritedResult = isInherited({ inheritedObject: value });

	return (
		<Wrapper>
			<TitleEditableField
				label="Version Languages"
				path="coreMetadata.basic.versionLanguages"
				editMode={editMode}
				ruleOrigin=""
				direction="column"
				readComponent={
					<ChipDisplay
						variant={isInheritedResult.isInherited ? 'prefilled' : 'default'}
						values={readOnlyValue?.displayValue?.map(mapLanguageUuidToLabel).sort(sortAlphabetically) || []}
						horizontalPadding={0}
						allowOverflow
						calcWidth="calc(100% - 20px)"
					/>
				}
				editComponent={
					<DropdownV2
						variant={isInheritedResult.isInherited ? 'prefilled' : 'default'}
						withSearch
						mode="multiple"
						disabledMargin
						value={value?.displayValue}
						onChange={onChange}
						options={options}
						onBlur={commit}
					/>
				}
			/>
		</Wrapper>
	);
}
