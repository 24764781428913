import React, { useCallback, useMemo, useState, useRef, ReactNode, useEffect } from 'react';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { MenuList } from '@mui/material';
import styled, { css } from 'styled-components';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import ModalMenu, { MenuListItem } from './ModalMenu';
import Loader from './Loader';

export interface AutoButtonGroupOption<ActionId> {
	id: ActionId;
	label: string;
	icon?: ReactNode;
	default?: boolean;
	onClick?: () => void;
}

export interface AutoButtonGroupProps<ActionId> {
	options: AutoButtonGroupOption<ActionId>[];
	storeKey?: string;
	nxstyle?: Parameters<typeof ButtonGroup>[0]['nxstyle'];
	height?: number;
	hideVisibleOption?: boolean;
	onOptionClick?: (id: ActionId) => void;
	startIcon?: ReactNode;
	disabled?: boolean;
	loading?: boolean;
	expandButtonAriaLabel?: string;
}

const VisibleButton = styled(Button)<{ $height: number; $arrow?: boolean }>(
	({ $height, $arrow = false }) => css`
		height: ${$height}px;
		${!$arrow &&
		css`
			width: 100%;
		`}
	`,
);

const StyledMenuList = styled(MenuList)(
	() => css`
		padding: 0;
	`,
);

export class StoredDefaultButtonChanged extends Event {
	public storageKey: string;

	public value: string;

	constructor(storageKey: string, value: string) {
		super('storedDefaultButtonChanged');
		this.storageKey = storageKey;
		this.value = value;
	}
}

export function AutoButtonGroup<ActionId extends string>({
	options,
	storeKey,
	onOptionClick,
	nxstyle = 'primary-blue',
	height = 38,
	hideVisibleOption = false,
	startIcon,
	disabled,
	loading,
	expandButtonAriaLabel,
}: AutoButtonGroupProps<ActionId>) {
	const storageKey = useMemo(() => (storeKey ? `${storeKey}-btn-group-last-clicked` : undefined), [storeKey]);
	const [modalMenuOpen, setModalMenuOpen] = useState(false);
	const [shownId, setShownId] = useState<string | undefined>(getValueFromStorage(storageKey));

	const modalMenuAnchorRef = useRef(null);

	// Compute visible button and other options
	const [shownButton, restOfOptions] = useMemo<
		[AutoButtonGroupOption<ActionId> | undefined, AutoButtonGroupOption<ActionId>[]]
	>(() => {
		const toShowOptionIndex =
			shownId !== undefined
				? options.findIndex((opt) => opt.id === shownId)
				: options.findIndex((opt) => opt.default === true);

		const optIdx = toShowOptionIndex !== -1 ? toShowOptionIndex : 0;
		if (options.length <= 1) return [options[0], []];
		return [options[optIdx], hideVisibleOption ? [...options.slice(0, optIdx), ...options.slice(optIdx + 1)] : options];
	}, [options, hideVisibleOption, shownId]);

	// Watch storage key for changes
	useEffect(() => {
		setShownId(getValueFromStorage(storageKey));
		if (!storageKey) return () => {};
		const storageChangeHandler = (evt: StorageEventInit) => {
			if (evt.key === storageKey && evt.newValue) setShownId(evt.newValue);
		};
		const localChangeHandler = (evt: StoredDefaultButtonChanged) => {
			if (evt.storageKey === storageKey) setShownId(evt.value);
		};

		window.addEventListener('storage', storageChangeHandler);
		window.addEventListener('storedDefaultButtonChanged', localChangeHandler);
		return () => {
			if (!storageKey) return;
			window.removeEventListener('storage', storageChangeHandler);
			window.removeEventListener('storedDefaultButtonChanged', localChangeHandler);
		};
	}, [storageKey]);

	const handleButtonClicked = useCallback(
		(opt: AutoButtonGroupOption<ActionId>) => {
			setModalMenuOpen(false);
			setShownId(opt.id);
			if (storageKey) {
				window.dispatchEvent(new StoredDefaultButtonChanged(storageKey, opt.id));
				window.localStorage.setItem(storageKey, opt.id);
			}
			if (opt.onClick) opt.onClick();
			if (onOptionClick) onOptionClick(opt.id);
		},
		[setModalMenuOpen, setShownId, storageKey, onOptionClick],
	);

	if (!shownButton) return null;
	return (
		<ButtonGroup nxstyle={nxstyle} innerRef={modalMenuAnchorRef}>
			<VisibleButton
				nxstyle={nxstyle}
				$height={height}
				onClick={() => handleButtonClicked(shownButton)}
				startIcon={startIcon || shownButton.icon}
				disabled={disabled || loading}
			>
				{shownButton.label}
			</VisibleButton>
			{restOfOptions.length > 0 && (
				<>
					<VisibleButton
						aria-label={expandButtonAriaLabel}
						nxstyle={nxstyle}
						$height={height}
						$arrow
						onClick={() => setModalMenuOpen(true)}
						disabled={disabled || loading}
					>
						{loading ? <Loader size={20} /> : <Icon path={mdiChevronDown} size="20px" />}
					</VisibleButton>
					<ModalMenu
						position="bottom"
						open={modalMenuOpen}
						onClose={() => setModalMenuOpen(false)}
						anchorEl={modalMenuAnchorRef.current}
						content={
							<StyledMenuList>
								{restOfOptions.map((opt) => (
									<MenuListItem
										key={opt.id}
										icon={opt.icon}
										text={opt.label}
										onClick={() => handleButtonClicked(opt)}
									/>
								))}
							</StyledMenuList>
						}
					/>
				</>
			)}
		</ButtonGroup>
	);
}

function getValueFromStorage(storageKey: string | undefined): undefined | string {
	if (!storageKey) return undefined;
	const storedValue = window.localStorage.getItem(storageKey);
	if (storedValue === null) return undefined;
	return storedValue;
}
