import React from 'react';
import queryString from 'query-string';
import { useAuth0 } from '@auth0/auth0-react';

import { AuthLoader } from './AuthLoader';

function Callback() {
	const { isLoading, isAuthenticated, logout } = useAuth0();

	const fragment = queryString.parse(window.location.search || window.location.hash);
	const errorMessage =
		fragment.error_description !== null && !Array.isArray(fragment.error_description)
			? fragment.error_description
			: undefined;

	return (
		<AuthLoader
			isLoading={isLoading}
			isAuthenticated={isAuthenticated}
			errorMessage={errorMessage ? { message: errorMessage, retryable: false } : undefined}
			onLogout={() => logout()}
		/>
	);
}

export default Callback;
