import React, { ReactNode } from 'react';
import { useDraggable } from '@dnd-kit/core';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;

	&:last-child > li::after {
		display: none;
	}
`;

function DraggableItem({ children, id }: { children?: ReactNode; id: string }) {
	const { attributes, listeners, setNodeRef } = useDraggable({
		id,
	});

	return (
		<Wrapper ref={setNodeRef} {...attributes} {...listeners}>
			{children}
		</Wrapper>
	);
}

export default DraggableItem;
