import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import React from 'react';
import { CustomColumnDef } from '../customColumnDef';

export const InheritedWrapper = styled.div(
	({ theme }) => css`
		margin-left: ${theme.spacing(1.5)};
	`,
);

export function StringOverrideCell({ value }: { value: string }) {
	return (
		<InheritedWrapper>
			<Typography variant="inherited">{value}</Typography>
		</InheritedWrapper>
	);
}

export function InheritedReadonlyOverrideCell<TData>({ column, cell }: CellContext<TData, any>) {
	const { renderReadOnly } = column.columnDef as CustomColumnDef<any>;

	if (renderReadOnly) {
		const rendered = renderReadOnly(cell.getValue());

		if (typeof rendered === 'string') {
			return <StringOverrideCell value={rendered} />;
		}

		return renderReadOnly(cell.getValue(), { isInherited: true });
	}

	return <StringOverrideCell value={cell.getValue()} />;
}
