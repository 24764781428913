import { Typography } from '@mui/material';
import React from 'react';
import { TextInput } from '@warehouse/shared/legacy-ui';
import { FieldWrapper } from '../../ui/FieldWrapper';

export function EntryNumberField({ entryNumber, onEntryNumberChange, onBlur }: EntryNumberFieldProps) {
	return (
		<FieldWrapper className="field-wrapper">
			<Typography variant="buttonMediumMedium" color="text.primary">
				Entry Number
			</Typography>
			<TextInput
				aria-label="Entry Number Input"
				value={entryNumber}
				onChange={(e) => onEntryNumberChange(e.target.value)}
				required={false}
				onBlur={onBlur}
				disabledMargin
			/>
		</FieldWrapper>
	);
}

type EntryNumberFieldProps = {
	entryNumber: string | undefined;
	onEntryNumberChange: (entryNumber: string | undefined) => void;
	onBlur?: () => void;
};
