import { useCallback, useEffect, useMemo } from 'react';
import { useURLSearchParams } from './useURLSearchParams';

export interface Options<T> {
	queryName: string;
	type?: 'string' | 'number' | 'boolean' | 'object';
	defaultValue?: T;
}

export function useStateQuery<T>({ queryName, type, defaultValue }: Options<T>): [T, (value: T | null) => void] {
	const { searchParams, setQuery, deleteQuery } = useURLSearchParams();

	const setValue = useCallback(
		(newValue: T | null) => {
			if (newValue === null) {
				deleteQuery(queryName);
				return;
			}
			if (type === 'boolean') {
				if (newValue) setQuery(queryName);
				else deleteQuery(queryName);
			}
			if (type === 'string' || type === undefined) {
				if (newValue !== '') setQuery(queryName, newValue as unknown as string);
				else deleteQuery(queryName);
			}
			if (type === 'number') setQuery(queryName, (newValue as unknown as number)?.toString());
			if (type === 'object') {
				if (newValue) setQuery(queryName, btoa(JSON.stringify(newValue)));
				else deleteQuery(queryName);
			}
		},
		[setQuery, deleteQuery],
	);

	const value = useMemo<T>(() => {
		const v = searchParams[queryName];
		if (type === 'boolean') return v !== undefined;
		if (type === 'string' || type === undefined) return v ?? '';
		if (v === undefined) return undefined;
		if (type === 'number') return parseInt(v, 10);
		try {
			if (type === 'object') return JSON.parse(atob(v));
		} catch (_) {
			deleteQuery(queryName);
			return undefined;
		}
		return v;
	}, [searchParams[queryName]]);

	useEffect(() => {
		const v = searchParams[queryName];
		if (!v && defaultValue) {
			setValue(defaultValue);
		}
	}, []);

	return [value, setValue];
}
