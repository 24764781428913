import styled, { css } from 'styled-components';
import { alpha, Popper } from '@mui/material';
import MUIButton from '@mui/material/Button';
import { Table, TH, THead, TR } from '../../table/TableUtils';
import { TooltipIcon } from '../InputUtils';
import Button from '../Button';
import Dropdown from '../Dropdown/Dropdown';
import NumberInput from '../NumberInput';

export const TableWrapper = styled.div<{ height?: number; noOverflow?: boolean }>(
	({ height, noOverflow }) => css`
		${!noOverflow &&
		css`
			overflow-x: auto;
		`}

		${height &&
		css`
			max-height: ${height}px;
			overflow-y: auto;
		`}
		white-space: nowrap;
	`,
);

export const StyledTable = styled(Table)<{
	tableStyle?: string;
	showBottomActionsBar?: boolean;
}>(
	({ theme, tableStyle, showBottomActionsBar }) => css`
		border-bottom: ${tableStyle === 'border' ? `1px solid ${theme.palette.light.backgroundAlt}` : 'none'};
		border-left: ${tableStyle === 'border' ? `1px solid ${theme.palette.light.backgroundAlt}` : 'none'};
		border-radius: ${showBottomActionsBar ? '8px 8px 0 0' : '8px 8px 8px 8px'};

		${tableStyle === 'subheader' &&
		css`
			border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		`}

		tr:last-child td:first-child {
			border-bottom-left-radius: ${showBottomActionsBar ? '0px' : '8px'};
		}
		tr:last-child td:last-child {
			border-bottom-right-radius: ${showBottomActionsBar ? '0px' : '8px'};
		}

		border-right: ${tableStyle === 'border' ? `1px solid ${theme.palette.light.backgroundAlt}` : 'none'};
	`,
);

export const StyledTHead = styled(THead)(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		position: sticky;
		width: 100%;
	`,
);

export const StyledTD = styled.td<{
	paddingLeft?: number;
	editable?: boolean;
}>(
	({ theme }) => css`
		height: 48px;
		padding: 0 !important;

		:focus {
			border: 1px solid ${theme.palette.blue.main};
		}
	`,
);

export const StyledTR = styled(TR)(
	({ theme }) => css`
		:has(td.errored) {
			&:not(:focus-within) {
				background-color: ${alpha(theme.palette.error.background, 0.5)} !important;
			}
		}

		&:not(:focus-within) {
			.errored {
				background-color: ${theme.palette.error.background} !important;
				border: solid 1px ${theme.palette.error.main} !important;
				box-sizing: border-box !important;
			}
		}

		&:hover {
			.highlighted-cell {
				border: solid 1px ${theme.palette.blue.main};
				border-radius: 9px;
				box-sizing: border-box;
			}
		}
		.highlighted-cell {
			border: solid 1px transparent;
		}
	`,
);

export const TDDndWrapper = styled.div(
	() => css`
		height: 100%;
		width: 62px;
	`,
);

export const StyledTH = styled(TH)<{ tableStyle?: string }>(
	({ theme, tableStyle }) => css`
		border-top: ${tableStyle === 'border' ? `1px solid ${theme.palette.light.backgroundAlt}` : 'none'};
		color: ${alpha(theme.palette.dark.main, 0.8)};
		font-size: 12px;
		font-weight: 500;
		padding: 0 12px !important;
	`,
);

export const SubHeaderTH = styled(TH)<{ noPadding?: boolean }>(
	({ theme, noPadding }) => css`
		color: ${alpha(theme.palette.dark.main, 0.8)};
		font-size: 12px;
		font-weight: 500;
		padding: 0 12px !important;
		text-transform: none;
		> div {
			margin: 0 !important;
			padding: ${noPadding ? 'padding: 0;' : 'padding: 0 8px;'};
		}

		.react-datepicker__triangle {
			display: none;
		}
	`,
);

export const SubHeaderTR = styled(TR)(
	({ theme }) => css`
		background-color: ${theme.palette.light.main} !important;
		th {
			border-bottom: 1px solid ${theme.palette.light.backgroundAlt} !important;
		}
	`,
);

export const TBody = styled.tbody<{ hoverable?: boolean }>(
	({ theme, hoverable }) => css`
		.drag-button {
			display: none;
		}

		tr:hover {
			${hoverable &&
			css`
				background-color: ${theme.palette.blue.background} !important;
			`}

			.drag-button {
				display: flex;
			}

			.preferences {
				display: none;
			}
		}
	`,
);

export const RowInput = styled.input(
	({ theme }) => css`
		background-color: inherit;
		border: 1px solid transparent;
		box-sizing: border-box;
		color: ${alpha(theme.palette.dark.main, 0.5)};
		font-size: ${theme.typography.bodyMediumRegular.fontSize};
		font-weight: ${theme.typography.bodyMediumRegular.fontWeight};
		height: 100%;
		padding: 0 12px;
		width: 100%;
		&:focus {
			border-color: ${theme.palette.blue.main};
		}
		&:focus-visible {
			outline: transparent;
		}
	`,
);

export const ActionRowWrapper = styled.div<{ tableStyle?: string }>(
	({ theme, tableStyle }) => css`
		background-color: ${theme.palette.light.background};
		border-bottom: ${tableStyle === 'border' ? `1px solid ${theme.palette.light.backgroundAlt}` : 'transparent'};
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		border-left: ${tableStyle === 'border' ? `1px solid ${theme.palette.light.backgroundAlt}` : 'transparent'};
		border-right: ${tableStyle === 'border' ? `1px solid ${theme.palette.light.backgroundAlt}` : 'transparent'};
		display: flex;
		justify-content: space-between;
		max-height: 31px;
		padding: 6px 0 6px 6px;
	`,
);

export const StyledToolTipIcon = styled(TooltipIcon)(
	({ theme }) => css`
		color: ${alpha(theme.palette.dark.main, 0.5)};
	`,
);

export const SelectionButton = styled(Button)<{
	$isSelectionEnabled?: boolean;
}>(
	({ theme, $isSelectionEnabled }) => css`
		${$isSelectionEnabled
			? css`
					color: ${alpha(theme.palette.dark.main, 0.8)};
			  `
			: null}
	`,
);

export const CellWrapper = styled.div<{ withHorizontalPadding?: boolean }>(
	({ theme, withHorizontalPadding = false }) => css`
		${withHorizontalPadding &&
		css`
			padding: ${theme.spacing(0, 1.5)};
		`}
		width: 100%;
		input {
			width: 100%;
		}
		& > * {
			.input-wrapper {
				background-color: inherit;
			}
			border-radius: 0 !important;
			margin: 0 !important;
		}
	`,
);

export const DragButtonWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: 12px;
		height: 100%;
		justify-content: center;
		padding: 0 ${theme.spacing(2)};
	`,
);

export const RowWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		font-size: 14px;
		font-weight: 400;
		height: 100%;

		> div {
			width: 100%;
		}
	`,
);

export const DropDownButton = styled(MUIButton)<{
	$isOpen?: boolean;
	isItalic?: boolean;
}>(
	({ theme, $isOpen, isItalic }) => css`
		background-color: ${theme.palette.light.background};
		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		box-sizing: border-box;
		color: ${alpha(theme.palette.dark.main, 0.5)};
		font-size: 14px;
		font-weight: 500;
		height: 32px;
		text-transform: none;

		${isItalic &&
		css`
			font-style: italic;
			font-weight: 400;
		`}

		&:hover {
			background-color: ${theme.palette.light.background};
			border: 1px solid ${theme.palette.blue.main};
		}

		${$isOpen
			? css`
					border: 1px solid ${theme.palette.blue.main};
					svg {
						color: ${theme.palette.blue.main};
					}
			  `
			: null}
	`,
);

export const CustomDefaultDropDown = styled(Dropdown)(
	() => css`
		span {
			font-size: 14px;
			font-weight: 500;
		}
	`,
);

export const StyledNumberInput = styled(NumberInput)(
	() => css`
		margin: 0;
		.input-wrapper {
			height: 32px;
			width: 32px;
		}
		input {
			max-width: 32px;
			padding: 0;
			text-align: center;
		}
	`,
);

export const LeftWrapper = styled.div(
	() => css`
		display: flex;
	`,
);

export const CustomPopover = styled(Popper)(
	() => css`
		width: 519px;
		z-index: 20;
	`,
);

export const ExpandButtonsWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: ${theme.spacing(1)};
		padding: ${theme.spacing(1, 0)};
	`,
);

export const WrapperTD = styled.div`
	padding: 0 12px;
`;

export const RowContent = styled.div(
	({ theme }) => css`
		font-size: ${theme.typography.bodyMediumRegular.fontSize};
		font-weight: ${theme.typography.bodyMediumRegular.fontWeight};
		padding: 0 12px;
		width: 100%;
	`,
);

export const ExpandableTitleWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: ${theme.spacing(1)};

		*:first-child {
			font-weight: 500 !important;
		}
	`,
);

export const StyledCheckboxContainer = styled.div(
	() => css`
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
	`,
);
