import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as SvgLogo } from '../../assets/loader-logo.svg';

interface NxLoaderProps {
	size?: number;
}

const rotate = keyframes`
	from {
		-ms-transform: rotate(0deg);
		'-moz-transform': rotate(0deg);
		'-webkit-transform': rotate(0deg);
		'-o-transform': rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		'-moz-transform': rotate(360deg);
		'-webkit-transform': rotate(360deg);
		'-o-transform': rotate(360deg);
		transform: rotate(360deg);
	}
`;

const LoaderWrapper = styled.div`
	align-items: center;
	display: flex;
	height: ${(props: { size: number }) => props.size}px;
	justify-content: center;
	position: relative;
	width: ${(props: { size: number }) => props.size}px;
`;

const SpinnerCircle = styled.div`
	-moz-animation: rotatingSpinner 1s linear infinite;
	'-ms-animation': rotatingSpinner 1 s linear infinite;
	-o-animation: rotatingSpinner 1s linear infinite;
	-webkit-animation: rotatingSpinner 1s linear infinite;
	animation: ${rotate} 1s linear infinite;
	position: absolute;
	width: 100%;
	height: 100%;
	border: 2px solid transparent;
	border-top-color: rgb(0, 0, 0, 0.44);
	border-radius: 50%;
	z-index: 1;
`;
const InnerCircle = styled.div(
	() => css`
		border: 2px solid rgb(0, 0, 0, 0.15);
		border-radius: 50%;
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 0;
	`,
);

const CenteredLogo = styled(SvgLogo)`
	height: 40%;
	position: absolute;
	width: 40%;
`;

function NxLoader({ size = 60 }: NxLoaderProps) {
	return (
		<LoaderWrapper size={size}>
			<SpinnerCircle />
			<InnerCircle />
			<CenteredLogo />
		</LoaderWrapper>
	);
}

export default NxLoader;
