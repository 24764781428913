import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { DropdownV2 } from '@warehouse/shared/ui';
import { FieldWrapper } from '../../ui/FieldWrapper';
import { getEntryClasses } from '../../core/entry-class';

export function EntryClassField({ entryClass, onEntryClassChange, onBlur }: EntryClassFieldProps) {
	const entryClasses = useMemo(() => getEntryClasses(), []);

	return (
		<FieldWrapper>
			<Typography variant="buttonMediumMedium" color="text.primary">
				Entry Class
			</Typography>
			<DropdownV2
				options={entryClasses.map(({ uuid, label }) => ({ label, value: uuid }))}
				value={entryClass}
				onChange={(value) => {
					onEntryClassChange(value);
				}}
				withSearch
				placeholder="Select Entry Class"
				onBlur={onBlur}
				disabledMargin
			/>
		</FieldWrapper>
	);
}
type EntryClassFieldProps = {
	entryClass: string | undefined;
	onEntryClassChange: (entryClass: string) => void;
	onBlur?: () => void;
};
