import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

// TYPE
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Rating } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { Typography } from '@mui/material';
import { Row, SortingFn } from '@tanstack/react-table';
import { makeItInherited, titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { isInherited, isPrimaryEdit } from '@warehouse/title/core';
import { DropdownV2Option } from '@warehouse/shared/ui';
import { WithUuid } from '../../../../hooks/useTitleRecordsAutoSave/recordToArray';
import { CustomColumnDef } from '../../../../../../components/library/SimpleTable/customColumnDef';

// HOOKS
import { UseTitleRecordsAutoSaveOutput } from '../../../../hooks/useTitleRecordsAutoSave';

// UTILS
import { findRatingReason, findRatingSystem, findRatingValue, findTerritory } from './utils';

// LIBRARY
import TitleEditableField from '../../../../../../components/titles/TitleEditableField';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import ChipDisplay from '../../../../../../components/library/ChipDisplay';
import CellSystem from './CellSystem';
import CellSystemValue from './CellSystemValue';
import CellReason from './CellReason';
import CellTerritory from './CellTerritory';
import CellDescription from './CellDescription';
import computeErrors from './computeErrors';
import useTerritories, { territories } from '../../../../../../utils/hooks/useTerritories';
import deepEquality from '../../../../../../utils/deepEquality';
import useTooltip from '../../../../../../utils/hooks/useTooltips';
import useRatingSystemTerritoriesOptions from '../../../../hooks/useRatingSystemTerritoriesOptions';
import { InheritedReadOnlyText } from '../../../../../../../libs/title/ui/table/InheritedReadOnlyText';

const StyledDiv = styled.div(
	({ theme }) => css`
		.table-wrapper {
			margin-left: ${theme.spacing(0.75)};
			margin-right: ${theme.spacing(0.75)};
			width: auto !important;
		}
	`,
);

const StyledDescription = styled(Typography)(
	() => css`
		margin-left: 10px;
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

const SHOW_ALL = 'default';

function RenderDescriptionReadOnly(value: Inherited<Rating>, defaultLocale: string) {
	const isInheritedValue = isInherited({ inheritedObject: value }).isInherited;
	const withDefaultLocale = value?.displayValue?.descriptions?.find(
		(desc) => desc.language === defaultLocale,
	)?.description;
	const withUndefinedLocale = value?.displayValue?.descriptions?.find(
		(desc) => desc.language === undefined,
	)?.description;

	return (
		<StyledDescription className={isInheritedValue ? 'inherited' : ''} variant="tableCell">
			{withDefaultLocale || withUndefinedLocale || ''}
		</StyledDescription>
	);
}

const territorySortingFn: SortingFn<WithUuid<Inherited<Rating>>> = (
	rowA: Row<WithUuid<Inherited<Rating>>>,
	rowB: Row<WithUuid<Inherited<Rating>>>,
) => {
	const aLabel = territories.find(
		(territory) =>
			territory.value ===
			(rowA.original.displayValue.region.country || rowA.original.displayValue.region.countryRegion),
	)?.label;
	const bLabel = territories.find(
		(territory) =>
			territory.value ===
			(rowB.original.displayValue.region.country || rowB.original.displayValue.region.countryRegion),
	)?.label;

	return aLabel?.localeCompare(bLabel || '') || 0;
};

function RatingsTable({
	editMode,
	ratings,
	readOnlyValue,
	selectedTerritory,
	ratingsAutoSave,
	origin,
	defaultLocale,
}: {
	editMode: boolean;
	ratings: WithUuid<Inherited<Rating>>[];
	readOnlyValue?: WithUuid<Inherited<Rating>>[];
	ratingsAutoSave: UseTitleRecordsAutoSaveOutput<Inherited<Rating>>;
	defaultLocale: string;
	selectedTerritory?: string;
	origin?: string;
}) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { getKey } = useTerritories();
	const ratingsTerritories = useRatingSystemTerritoriesOptions();

	const [internalRows, setInternalRows] = useState<WithUuid<Inherited<Rating>>[]>([]);
	const territoriesTooltip = useTooltip('coreMetadata.basic.ratingSet.ratings.region');
	const ratingSystemTooltip = useTooltip('coreMetadata.basic.ratingSet.ratings.system');
	const ratingValueTooltip = useTooltip('coreMetadata.basic.ratingSet.ratings.value');
	const ratingReasonTooltip = useTooltip('coreMetadata.basic.ratingSet.ratings.reason');
	const ratingDescriptionTooltip = useTooltip('coreMetadata.basic.ratingSet.ratings.descriptions');

	const onInternalRowsChange = (rows: Row<WithUuid<Inherited<Rating>>>[]) => {
		if (
			!deepEquality(
				rows.map((row) => row.original),
				internalRows,
			)
		) {
			setInternalRows(rows.map((row) => row.original));
		}
	};
	const ratingColumns: CustomColumnDef<WithUuid<Inherited<Rating>>, any>[] = useMemo(
		() => [
			{
				id: 'territory',
				header: 'Territory',
				tooltip: territoriesTooltip?.tooltip,
				width: 200,
				accessorFn: (row) => row,
				sortingFn: territorySortingFn,
				renderReadOnly: (v) => (
					<InheritedReadOnlyText isInherited={isInherited({ inheritedObject: v }).isInherited}>
						{findTerritory(v?.displayValue)?.label}
					</InheritedReadOnlyText>
				),
				cell: CellTerritory,
			},
			{
				width: 200,
				id: 'ratingSystem',
				header: 'Rating System',
				tooltip: ratingSystemTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: (v) => (
					<InheritedReadOnlyText isInherited={isInherited({ inheritedObject: v }).isInherited}>
						{findRatingSystem(v?.displayValue)?.name}
					</InheritedReadOnlyText>
				),
				cell: CellSystem,
			},
			{
				width: 150,
				id: 'ratingValue',
				header: 'Value',
				tooltip: ratingValueTooltip?.tooltip,
				accessorFn: (row) => row,
				cell: CellSystemValue,
				renderReadOnly: (v) => (
					<InheritedReadOnlyText isInherited={isInherited({ inheritedObject: v }).isInherited}>
						{findRatingValue(v?.displayValue)?.name}
					</InheritedReadOnlyText>
				),
			},
			{
				id: 'reasons',
				width: 150,
				header: 'Reasons',
				tooltip: ratingReasonTooltip?.tooltip,
				accessorFn: (row) => row,
				cell: CellReason,
				renderReadOnly: (v) => {
					const isInheritedValue = isInherited({ inheritedObject: v }).isInherited;
					const foundLabels =
						findRatingReason(v?.displayValue)?.sort((a, b) => {
							if (!a && !b) return 0;
							if (!a) return -1;
							if (!b) return 1;

							return a.localeCompare(b);
						}) || [];
					return (
						<ChipDisplay
							variant={isInheritedValue ? 'prefilled' : 'default'}
							allowOverflow
							values={foundLabels}
							width={150}
							height={26}
						/>
					);
				},
			},
			{
				id: 'description',
				width: 50,
				header: 'Description',
				tooltip: ratingDescriptionTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: (v) => RenderDescriptionReadOnly(v, defaultLocale),
				cell: CellDescription,
			},
		],
		[],
	);

	const getNewRoadPayload = (): Inherited<Rating> =>
		makeItInherited<Inherited<Rating>>({
			region:
				selectedTerritory !== SHOW_ALL
					? {
							[getKey(selectedTerritory || '')]: selectedTerritory,
					  }
					: {},
			system: '',
			value: '',
		});

	const onRowAdd = () => {
		ratingsAutoSave.addRecord(getNewRoadPayload());
	};

	const canAddRow = () => {
		const noPartialRating = ratings ? ratings?.every((rating) => !rating.__uuid.startsWith('tmp-')) : true;
		if (selectedTerritory === SHOW_ALL) {
			return noPartialRating;
		}
		const territoryHasRatingSystem = ratingsTerritories
			.map((typeTerritories) =>
				typeTerritories.options.some((territory: DropdownV2Option) => territory.value === selectedTerritory),
			)
			.some((hasRatingSystem) => hasRatingSystem === true);

		return territoryHasRatingSystem && noPartialRating;
	};

	const onRowDelete = (selectedIndexes: string[]) => {
		ratingsAutoSave.removeRecord(selectedIndexes);
	};

	return (
		<StyledDiv>
			<TitleEditableField
				label="Ratings"
				path="coreMetadata.basic.ratingSet.ratings"
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
				ruleOrigin={origin}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<Inherited<Rating>>
				selectionKey="__uuid"
				sorting={[{ id: 'territory', desc: false }]}
				isRowReadonlyFn={(row) => isInherited({ inheritedObject: row }).isInherited && !isPrimaryEdit(title)}
				showBottomActionsBar={editMode}
				editMode={editMode}
				tableStyle="border"
				columns={ratingColumns}
				onInternalRowsChange={onInternalRowsChange}
				errors={editMode ? computeErrors(internalRows) : {}}
				data={editMode ? ratings : readOnlyValue || []}
				onRowAdd={onRowAdd}
				canAddRow={canAddRow()}
				onRowDelete={onRowDelete}
				enableAddMultipleRows={false}
				selectRowModeByDefault
				customPropsCell={{
					editRecord: ratingsAutoSave.editRecord,
					defaultLocale,
				}}
				noOverflow
			/>
		</StyledDiv>
	);
}

export default RatingsTable;
