import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { CustomColumnDef } from '../../../../src/components/library/SimpleTable/customColumnDef';
import { CellDisplayName } from './CellDisplayName';
import { CellLanguage } from './CellLanguage';
import { PartialDisplayName } from '../comp-obj-relationship.model';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../src/components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { CustomCellProps, DisplayNamesTableProps } from './DisplayNamesTable.props';
import { computeExistingLanguageIds, filterOutExistingLanguages } from '../comp-obj-relationship.core';
import { getWrittenLanguagesOptions } from '../../core/languages';
import { FieldWrapper } from '../../ui/FieldWrapper';

export function DisplayNamesTable({
	displayNames,
	onAddDisplayName,
	onRemoveDisplayNames,
	updateDisplayNameDisplayName,
	updateDisplayNameLanguage,
	commitChanges,
}: DisplayNamesTableProps) {
	const existingLanguageIds = useMemo(() => computeExistingLanguageIds(displayNames), [displayNames]);
	const fullLanguageOptions = useMemo(() => getWrittenLanguagesOptions(), [])
	const languageOptions = useMemo(() => filterOutExistingLanguages(existingLanguageIds, fullLanguageOptions), [existingLanguageIds, fullLanguageOptions]);

	const onRowDelete = (selectedIndexes: string[]) => {
		const selectedIndexesToDelete = selectedIndexes.map((index) => parseInt(index, 10));
		onRemoveDisplayNames(selectedIndexesToDelete);
		commitChanges();
	};

	const onRowAdd = () => {
		onAddDisplayName();
	}

	return (
		<FieldWrapper className="field-wrapper">
			<Typography variant="buttonMediumMedium" color="text.primary">
				Display Names
			</Typography>
		<Wrapper>
			<InheritanceOnValueChangeSimpleTableWrapper
				editMode
				noOverflow
				columns={displayNamesTableColumns}
				selectRowModeByDefault
				enableAddMultipleRows={false}
				data={displayNames}
				onRowAdd={onRowAdd}
				onRowDelete={onRowDelete}
				tableStyle="border"
				showBottomActionsBar
				errors={computeErrors(displayNames)}
				customPropsCell={{
					updateDisplayNameDisplayName,
					updateDisplayNameLanguage,
					commitChanges,
					languageOptions,
				} satisfies CustomCellProps}
			/>
		</Wrapper>
		</FieldWrapper>
	);
}

function computeErrors(displayNames: PartialDisplayName[]) {
	const result: { [columnId: string]: number[] } = {
		displayName: [],
	};

	displayNames.forEach((displayName, index) => {
		if (!displayName.displayName) {
			result.displayName.push(index);
		}
	});

	return result;
}

const displayNamesTableColumns: CustomColumnDef<PartialDisplayName>[] = [
	{
		id: 'displayName',
		header: 'Display Name',
		cell: (info) =>
			CellDisplayName({
				displayName: info,
			}),
		accessorFn: (row) => row.displayName,
	},
	{
		id: 'language',
		header: 'Language',
		cell: (info) =>
			CellLanguage({
				language: info,
			}),
		accessorFn: (row) => row.language,
	},
]

const Wrapper = styled.div(
	() => css`
		table {
			table-layout: fixed;
		}
	`,
);
