import React from 'react';
import classNames from 'classnames';
import MUIChip, { ChipProps as MUIChipProps } from '@mui/material/Chip';
import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';

export type ChipVariant = 'default' | 'prefilled';

export interface ChipProps extends Omit<MUIChipProps, 'variant'> {
	height?: number;
	maxWidth?: string;
	chipsWidth?: number;
	error?: boolean;
	variant?: ChipVariant;
	withHover?: boolean;
}

interface StyledChipProps extends MUIChipProps {
	height?: number;
	$maxWidth?: string;
	$chipsWidth?: number;
	$error?: boolean;
	$chipStyle?: 'default' | 'italic';
	$withHover?: boolean;
}

const StyledChip = styled(MUIChip)<StyledChipProps>(
	({ theme, height, $withHover = true, $chipsWidth, $error, $maxWidth }) => css`
		& .MuiChip-label {
			padding: 7px 8px;
		}

		& .MuiChip-deleteIcon {
			:hover {
				color: ${alpha(theme.palette.dark.main, 0.5)};
			}

			color: ${alpha(theme.palette.dark.main, 0.5)};
		}

		${$maxWidth !== undefined ? `max-width: ${$maxWidth};` : ''}
		background-color: ${alpha(theme.palette.dark.main, 0.05)};
		border: 1px solid ${alpha(theme.palette.dark.main, 0.1)};
		color: ${$error ? theme.palette.error.text : alpha(theme.palette.dark.main, 0.6)};
		cursor: initial !important;
		font-size: 12px;
		font-weight: 500;
		height: ${height ? `${height}px` : '28px'};
		min-width: ${$chipsWidth ? `${$chipsWidth}px` : ''};

		:hover,
		:focus {
			& .MuiChip-label {
				padding: 7px 8px;
			}

			& .MuiChip-deleteIcon {
				:hover {
					color: ${alpha(theme.palette.dark.main, 0.8)};
				}

				color: ${alpha(theme.palette.dark.main, 0.8)};
			}

			${$withHover &&
			css`
				background-color: ${alpha(theme.palette.dark.main, 0.1)};
				border: 1px solid ${alpha(theme.palette.dark.main, 0.3)};
				color: ${theme.palette.dark.main};
			`}
			font-size: 12px;
			font-weight: 500;
		}
	`,
);

export function Chip({ maxWidth, chipsWidth, withHover, error, className, variant = 'default', ...rest }: ChipProps) {
	return (
		<StyledChip
			{...rest}
			className={classNames({ inherited: variant === 'prefilled' }, className)}
			$chipsWidth={chipsWidth}
			$error={error}
			$maxWidth={maxWidth}
			$withHover={withHover}
		/>
	);
}

export default Chip;
