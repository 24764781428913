import React from 'react';
import { AddChildrenPrompt } from '@warehouse/title/add-children-feature';
import { CommonReadonly } from '@warehouse/title/core';
import { RelationshipTypeSelectorContainer } from './ui/RelationshipTypeSelectorContainer';

export function AddRelationshipChildren({ onClose, title, open }: AddRelationshipChildrenProps) {
	return (
		<AddChildrenPrompt
			open={open}
			onClose={onClose}
			titleUuid={title.uuid}
			headerText="Add Existing Children"
			searchAndSelectedPluralLabel="Titles"
			headerSlot={<RelationshipTypeSelectorContainer title={title} />}
		/>
	);
}

export interface AddRelationshipChildrenProps {
	open: boolean;
	onClose: () => void;
	title: CommonReadonly;
}
