import React from 'react';
import { RelationshipType, CommonReadonly, TitleLightWithSelectedParent } from '@warehouse/title/core';
import { ContentField } from './ui/ContentField';
import { DeleteButtonField } from './ui/DeleteButtonField';
import { RelationshipSubtypeField } from './ui/RelationshipSubtypeField';
import { RelationshipTypeField } from './ui/RelationshipTypeField';
import { ParentField } from './ui/ParentField';
import { SequenceNumberField } from './ui/SequenceNumberField';
import { DistributionNumberField } from './ui/DistributionNumberField';
import { HouseSequenceField } from './ui/HouseSequenceField';
import { RelationshipSequenceInfo } from './parent-relationship.model';
import { PrimaryField } from './ui/PrimaryField';

export function ParentRelationshipEditor({
	childTitle,
	parentTitle,
	relationshipType,
	relationshipTypeLabel,
	relationshipSubtype,
	isPrimaryRelationship,
	sequenceInfo,
	sequenceInfoPlaceholders,
	primary,
	updateRelationshipSubtype,
	updateSequenceNumber,
	updateDistributionNumber,
	updateHouseSequence,
	updatePrimary,
	deleteChild,
	commitChanges,
}: ParentRelationshipEditorProps) {
	return (
		<>
			<ContentField titleLight={childTitle} />
			<RelationshipTypeField relationshipType={relationshipTypeLabel} />
			{![RelationshipType.EpisodeOf, RelationshipType.SeasonOf, RelationshipType.DerivedFrom].includes(
				relationshipType,
			) && (
				<RelationshipSubtypeField
					relationshipSubtype={relationshipSubtype}
					onRelationshipSubtypeChange={updateRelationshipSubtype}
					commit={commitChanges}
				/>
			)}
			<ParentField titleLight={parentTitle} />
			<PrimaryField
				primary={primary}
				primaryChange={updatePrimary}
				commit={commitChanges}
				relationshipType={relationshipType}
			/>
			{relationshipType !== RelationshipType.DerivedFrom && (
				<SequenceNumberField
					sequenceNumber={sequenceInfo.sequenceNumber}
					placeholder={sequenceInfoPlaceholders.sequenceNumber}
					onSequenceNumberChange={updateSequenceNumber}
					onBlur={commitChanges}
				/>
			)}
			{[RelationshipType.EpisodeOf, RelationshipType.SeasonOf].includes(relationshipType) && (
				<DistributionNumberField
					distributionNumber={sequenceInfo.distributionNumber}
					placeholder={sequenceInfoPlaceholders.distributionNumber}
					onDistributionNumberChange={updateDistributionNumber}
					onBlur={commitChanges}
				/>
			)}
			{[RelationshipType.EpisodeOf, RelationshipType.SeasonOf].includes(relationshipType) && (
				<HouseSequenceField
					houseSequence={sequenceInfo.houseSequence}
					placeholder={sequenceInfoPlaceholders.houseSequence}
					onHouseSequenceChange={updateHouseSequence}
					onBlur={commitChanges}
				/>
			)}
			{relationshipType !== RelationshipType.DerivedFrom && (
				<DeleteButtonField
					onDelete={deleteChild}
					disabled={
						isPrimaryRelationship && [RelationshipType.EpisodeOf, RelationshipType.SeasonOf].includes(relationshipType)
					}
				/>
			)}
		</>
	);
}

interface ParentRelationshipEditorProps {
	childTitle: TitleLightWithSelectedParent;
	parentTitle: CommonReadonly;
	relationshipType: RelationshipType;
	relationshipTypeLabel: string;
	relationshipSubtype?: string;
	isPrimaryRelationship: boolean;
	sequenceInfo: RelationshipSequenceInfo;
	sequenceInfoPlaceholders: RelationshipSequenceInfo;
	primary: boolean | undefined;
	updateRelationshipSubtype: (relationshipSubtype: string) => void;
	updateSequenceNumber: (sequenceNumber: number | undefined) => void;
	updateDistributionNumber: (distributionNumber: string | undefined) => void;
	updateHouseSequence: (houseSequence: string | undefined) => void;
	updatePrimary: (primary: boolean) => void;
	deleteChild: () => void;
	commitChanges: () => void;
}
