import { Typography } from '@mui/material';
import React from 'react';
import { FieldWrapper } from '../../ui/FieldWrapper';
import { IntegerInput } from '../../ui/IntegerInput';

export function SequenceNumberField({
	sequenceNumber,
	onSequenceNumberChange,
	onBlur,
	placeholder,
}: SequenceNumberFieldProps) {
	return (
		<FieldWrapper className="field-wrapper">
			<Typography variant="buttonMediumMedium" color="text.primary">
				Sequence Number
			</Typography>
			<IntegerInput
				ariaLabel="Sequence Number"
				value={sequenceNumber}
				onChange={onSequenceNumberChange}
				required={false}
				onBlur={onBlur}
				placeholder={placeholder}
			/>
		</FieldWrapper>
	);
}

type SequenceNumberFieldProps = {
	sequenceNumber: number | undefined;
	onSequenceNumberChange: (sequenceNumber: number | undefined) => void;
	onBlur?: () => void;
	placeholder?: number;
};
