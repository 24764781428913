import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { mdiAlert, mdiChevronRight, mdiDelete, mdiInformation } from '@mdi/js';
import { DialogContent, Tab, Typography, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import { Track, ReadChunkFunc, Result, ResultObject } from 'mediainfo.js/dist/types';
import { mediaInfoPlus } from '@nexspec/mediainfo-plus';
import Button from '../../../../../components/library/Button';
import Loader from '../../../../../components/library/Loader';
import {
	FileList,
	FileItem,
	FileItemName,
	FilesListColumn,
	LoaderWrapper,
	MidColumn,
	RightColumn,
	TitleRow,
	TopRowMidColumn,
	TrackItem,
	TrackList,
	TrackListHeader,
	TabsWrapper,
	TrackTitle,
	Wrapper,
	UploadFilesTitle,
	FileItemButtonContainer,
	TrackIndex,
} from './style';
import Dialog from '../../../../../components/library/Dialog';
import DialogTitle, { CloseButton } from '../../../../../components/library/DialogTitle';
import DialogActions from '../../../../../components/library/DialogActions';
import UploadDropzone from '../../../../../components/library/UploadDropzone';
import Tabs from '../../../../../components/library/Tabs';

const { MediaInfo } = window;

interface TrackMediaInfoSuccess {
	status: 'success';
	file: File;
	mediainfo: Result;
}
interface TrackMediaInfoFailed {
	status: 'failed';
	file: File;
	reason: string;
}
type TrackMediaInfo = TrackMediaInfoSuccess | TrackMediaInfoFailed;

interface IngestedFileValid {
	status: 'valid';
	file: File;
	mediainfo: Result;
}
interface IngestedFileInvalid {
	status: 'invalid';
	file: File;
	reason: string;
}
type IngestedFile = IngestedFileValid | IngestedFileInvalid;

function readChunk(file: File): ReadChunkFunc {
	return (chunkSize: number, offset: number) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (event: ProgressEvent<any>) => {
				if (event.target.error) {
					reject(event.target.error);
				}
				resolve(new Uint8Array(event.target.result));
			};
			reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
		});
}

function isTrackValid(track: Track): boolean {
	return ['Video', 'Audio', 'Text'].includes(track['@type']);
}

function getTrackName(track: Track): string {
	if (track['@type'] === 'Video') {
		return `${track.Width || 0}x${track.Height || 0} | ${track.Format || ''}${
			track.Framerate ? ` | ${track.Framerate}` : ''
		}`;
	}
	if (track['@type'] === 'Audio') {
		return `${track.Format || ''} | (${track.ChannelLayout || `${track.Channels} Channels`})`;
	}
	if (track['@type'] === 'Text') {
		return `${track.Format || ''}`;
	}
	return `${track['@type']}`;
}

function getTrackIndex(track: Track, index: number): string {
	if (track['@type'] === 'Video') {
		return `${index}V`;
	}
	if (track['@type'] === 'Audio') {
		return `${index}A`;
	}
	if (track['@type'] === 'Text') {
		return `${index}T`;
	}
	return `${track.ID || ''}${track['@type']?.[0]}`;
}

interface IngestModalProps {
	open: boolean;
	onClose: () => void;
	uploadedFiles: File[];
}

function TabPanel(props: { children?: React.ReactNode; index: number; value: number }) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ height: '95%' }}
		>
			{value === index && children}
		</div>
	);
}

function IngestModal({ open, onClose, uploadedFiles }: IngestModalProps) {
	const theme = useTheme();
	const [files, setFiles] = useState<IngestedFile[]>([]);
	const [selectedTab, setSelectedTab] = useState(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [analyzing, setAnalyzing] = useState<boolean>(false);
	const [selectedFile, setSelectedFile] = useState<number>(-1);
	const [selectedTrack, setSelectedTrack] = useState<number>(-1);

	useEffect(() => {
		setSelectedFile(0);
		setSelectedTrack(-1);
		setFiles([]);
	}, [open]);

	const processFiles = useCallback(
		async (_files: File[]) => {
			setAnalyzing(true);
			const tmpFiles: IngestedFile[] = [];

			if (_files) {
				const mediaInfoResults = await Promise.all(
					_files.map(async (file: File): Promise<TrackMediaInfo> => {
						let miPayload: Result | undefined;
						let mipPayload: Result | undefined;
						try {
							const mediaInfo = await MediaInfo();
							miPayload = await mediaInfo.analyzeData(() => file.size, readChunk(file));
							mipPayload = await mediaInfoPlus(miPayload);
						} catch (e) {
							return { status: 'failed', file, reason: (e as any).toString() || 'Unable to run mediainfo on file' };
						}
						if (typeof mipPayload === 'object' && mipPayload.media.track.length === 0)
							return { status: 'failed', file, reason: 'No tracks found' };
						if (
							typeof miPayload === 'object' &&
							typeof mipPayload === 'object' &&
							miPayload.media.track.length !== mipPayload.media.track.length
						)
							return { status: 'failed', file, reason: 'Invalid audio channel layout' };
						return { status: 'success', file, mediainfo: mipPayload };
					}),
				);
				mediaInfoResults.forEach((rst) => {
					if (rst.status === 'success') {
						tmpFiles.push({ status: 'valid', file: rst.file, mediainfo: rst.mediainfo });
					} else {
						tmpFiles.push({ status: 'invalid', file: rst.file, reason: rst.reason });
					}
				});
			}

			setAnalyzing(false);
			setFiles((prev) => [...prev, ...tmpFiles]);
			setSelectedFile(files.length);
		},
		[files],
	);

	useEffect(() => {
		processFiles(uploadedFiles).catch();
	}, [uploadedFiles]);

	const tabs = useMemo(
		() => [
			{
				label: 'Editable',
				component: <div>Editable</div>,
			},
			{
				label: 'All',
				component: <div>All</div>,
			},
		],
		[],
	);

	const onIngest = () => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			onClose();
		}, 2000);
	};

	const onDeleteFile = (index: number) => {
		const tmpFiles = [...files];
		tmpFiles.splice(index, 1);
		setFiles(tmpFiles);
		if (selectedFile === index) setSelectedFile(-1);
		else if (selectedFile > index) setSelectedFile(selectedFile - 1);
	};

	return (
		<Dialog maxWidth="xl" open={open} onClose={onClose}>
			<DialogTitle>
				Ingest Tracks
				<CloseButton onClose={onClose} />
			</DialogTitle>
			<DialogContent>
				<Wrapper>
					<FilesListColumn>
						<UploadFilesTitle>
							<Typography variant="h4Regular">Upload Files</Typography>
						</UploadFilesTitle>
						<FileList>
							{files.map((file, index) => (
								<FileItem selected={selectedFile === index} key={file.file.name + index.toString()}>
									<FileItemName
										failed={file.status === 'invalid'}
										title={file.status === 'invalid' ? file.reason : undefined}
									>
										{file.status === 'invalid' && <Icon path={mdiAlert} size="14px" />}
										{file.file.name}
									</FileItemName>
									<FileItemButtonContainer>
										<Button onClick={() => onDeleteFile(index)} style={{ border: 'none' }} nxstyle="tertiary-dark">
											<Icon path={mdiDelete} size="14px" />
										</Button>
										<Button
											onClick={() => {
												setSelectedTrack(-1);
												setSelectedFile(index);
											}}
											nxstyle="tertiary-light"
										>
											<Icon path={mdiChevronRight} size="14px" />
										</Button>
									</FileItemButtonContainer>
								</FileItem>
							))}
						</FileList>
						<UploadDropzone onDrop={processFiles} />
					</FilesListColumn>
					<MidColumn isActive={selectedFile !== -1}>
						{analyzing && (
							<LoaderWrapper>
								<Loader />
							</LoaderWrapper>
						)}
						{!analyzing && selectedFile !== -1 && selectedFile < files.length && (
							<>
								<TopRowMidColumn>
									<Typography>Map Tracks</Typography>
								</TopRowMidColumn>
								<TitleRow errored={files[selectedFile].status === 'invalid'}>
									<Typography noWrap>{files[selectedFile]?.file.name}</Typography>
								</TitleRow>
								<TrackList>
									<TrackListHeader>
										<div>
											{files[selectedFile].status === 'valid' ? (
												<>
													<Typography>File Tracks</Typography>
													<Icon path={mdiInformation} size="20px" />
												</>
											) : (
												<Typography>File errored</Typography>
											)}
										</div>
										{/* <div> */}
										{/*	<Typography>Order Tracks</Typography> */}
										{/*	<Icon path={mdiInformation} size="20px" /> */}
										{/* </div> */}
									</TrackListHeader>
									{files[selectedFile].status === 'invalid' && (
										<TrackItem selected={false}>
											<Typography>{(files[selectedFile] as IngestedFileInvalid).reason}</Typography>
										</TrackItem>
									)}
									{files[selectedFile].status === 'valid' &&
										((files[selectedFile] as IngestedFileValid).mediainfo as ResultObject)?.media?.track?.map(
											(track: Track, index: number) => {
												if (!isTrackValid(track)) return null;
												return (
													<TrackItem key={track.name + index.toString()} selected={selectedTrack === index}>
														<TrackIndex>{getTrackIndex(track, index)}</TrackIndex>
														<TrackTitle>{getTrackName(track)}</TrackTitle>
														{/* <div style={{ display: 'flex', flex: 1 }}> */}
														{/*	<Dropdown */}
														{/*		disabledMargin */}
														{/*		verticalPadding={0} */}
														{/*		className="track-dropdown" */}
														{/*		height={36} */}
														{/*		value="Test" */}
														{/*		backgroundColor={theme.palette.light.main} */}
														{/*		onChange={() => {}} */}
														{/*		options={[{ label: 'Test', value: 'Test' }]} */}
														{/*	/> */}
														{/* </div> */}
														{/* <Button */}
														{/*	style={{ width: 36, height: 36 }} */}
														{/*	onClick={() => setSelectedTrack(index)} */}
														{/* > */}
														{/*	<Icon size="16px" path={mdiChevronRight} /> */}
														{/* </Button> */}
													</TrackItem>
												);
											},
										)}
								</TrackList>
							</>
						)}
					</MidColumn>
					<RightColumn isActive={selectedTrack !== -1}>
						{selectedFile !== -1 &&
							selectedFile < files.length &&
							files[selectedFile].status === 'valid' &&
							selectedTrack !== -1 &&
							selectedTrack <
								((files[selectedFile] as IngestedFileValid).mediainfo as ResultObject).media.track.length && (
								<>
									<TopRowMidColumn>
										<Typography>Map Tracks</Typography>
									</TopRowMidColumn>
									<TabsWrapper>
										<Tabs value={selectedTab} onChange={(evt, newSelectedTab) => setSelectedTab(newSelectedTab)}>
											{tabs.map((tab, index) => (
												<Tab key={index} label={tab.label} id={`simple-tab-${index}`} />
											))}
										</Tabs>
										{tabs.map((tab, index) => (
											<TabPanel key={index} value={selectedTab} index={index}>
												{tab.component}
											</TabPanel>
										))}
									</TabsWrapper>
								</>
							)}
					</RightColumn>
				</Wrapper>
			</DialogContent>
			<DialogActions>
				<Button $fullHeight onClick={onClose}>
					Cancel
				</Button>
				<Button
					$fullHeight
					type="button"
					onClick={onIngest}
					disabled={analyzing || !files.filter((file) => file.status === 'valid').length}
					nxstyle="cta"
				>
					{loading ? <Loader size={20} color={theme.palette.light.main} /> : <span>Ingest</span>}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default IngestModal;
