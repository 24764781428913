import { CellContext } from '@tanstack/react-table';
import Icon from '@mdi/react';
import { mdiDelete, mdiTextBox, mdiTextBoxOutline } from '@mdi/js';
import React from 'react';
import Tag from '../../library/Tag';

interface CellContextTemplateType {
	versionMetadata?: {
		status: string;
		publishedVersion?: number;
	};
}

function StatusCell<T extends CellContextTemplateType>(info: CellContext<T, any>) {
	const cellInfo = info as CellContext<T, any>;

	if (cellInfo.row.original.versionMetadata?.status === 'published')
		return (
			<Tag nxstyle="success" startIcon={<Icon path={mdiTextBox} size="16px" />} height={28} $uppercase={false}>
				Published V{cellInfo.row.original.versionMetadata.publishedVersion}
			</Tag>
		);
	if (cellInfo.row.original.versionMetadata?.status === 'unpublished')
		return (
			<Tag nxstyle="error" startIcon={<Icon path={mdiDelete} size="16px" />} height={28} $uppercase={false}>
				Unpublished
			</Tag>
		);
	if (cellInfo.row.original.versionMetadata?.status === 'draft')
		return (
			<Tag nxstyle="info" startIcon={<Icon path={mdiTextBoxOutline} size="16px" />} height={28} $uppercase={false}>
				Draft
			</Tag>
		);
	return null;
}

export default StatusCell;
