import { CellContext } from '@tanstack/react-table';
import React from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Release } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { DropdownV2 } from '@warehouse/shared/ui';
import releaseTypeList from '../../../../../../assets/json-administration-profiles/releaseTypeList.json';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

function CellReleaseType(info: CellContext<Release, Release>) {
	const { getValue, row, table } = info;
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options?.meta?.customPropsCell.isInherited;

	const options =
		releaseTypeList.map((option) => ({
			value: option.name,
			label: option.label,
		})) || [];

	if (options.length > 0) {
		return (
			<CellWrapper>
				<DropdownV2
					variant={isInherited ? 'prefilled' : 'default'}
					autoFocus={shouldAutoFocus(getValue())}
					withSearch
					value={getValue().releaseType || null}
					backgroundColor="transparent"
					transparentBorder
					onChange={(e) => {
						if (e) {
							setValue((prev: OptionalInherited<Release[]>) => {
								const updatedDisplayValue = prev.displayValue?.map((item, index) => {
									if (index === row.index) {
										return {
											...item,
											releaseType: e,
										};
									}
									return item;
								});
								return {
									...prev,
									displayValue: updatedDisplayValue,
								};
							});
							commit();
						}
					}}
					options={options}
				/>
			</CellWrapper>
		);
	}
	return null;
}

export default CellReleaseType;

function shouldAutoFocus(value: Release) {
	return value.distrTerritories !== undefined && value.distrTerritories!.length > 0 && !value.releaseType;
}
