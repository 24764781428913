import styled from 'styled-components';

export const FieldWrapper = styled.div(
	({ theme }) => `
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(1)};
		margin-bottom: 14px;
	`,
);
