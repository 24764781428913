import React, { ReactElement } from 'react';

export interface ConditionalTreeProps {
	condition?: boolean;
	wrapper?: (children: ReactElement | ReactElement[]) => ReactElement;
	wrapperFalse?: (children: ReactElement | ReactElement[]) => ReactElement;
	children: ReactElement | ReactElement[];
}

export function ConditionalTree({
	condition,
	children,
	wrapper,
	wrapperFalse,
}: ConditionalTreeProps): ReactElement {
	const convertReactElement = (input: ReactElement | ReactElement[]): ReactElement => {
		// eslint-disable-next-line react/jsx-no-useless-fragment
		if (Array.isArray(input)) return <>{input}</>;
		return input;
	}
	if (!condition) return wrapperFalse ? wrapperFalse(children) : convertReactElement(children);
	return wrapper ? wrapper(children) : convertReactElement(children);
}
