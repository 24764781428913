import React, { memo } from 'react';
import { CellContext } from '@tanstack/react-table';
import { DropdownV2 } from '@warehouse/shared/ui';
import { CellWrapper } from '../../../components/library/SimpleTable/style';
import { CustomColumnDef } from '../../../components/library/SimpleTable/customColumnDef';
import IncludeExcludeBottomAction from '../../../components/titles/IncludeExcludeBottomAction';

export const MadeForRegionsCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper style={{ overflow: 'hidden' }}>
		<DropdownV2
			{...(column.columnDef as CustomColumnDef<any>).cellProps}
			options={(column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
			getPopupContainer={() => document.body}
			mode="multiple"
			value={(getValue()?.madeForRegions as string[]) || []}
			transparentBorder
			bottomActions={
				<IncludeExcludeBottomAction
					value={row.original.madeForRegions?.isInclude ? 'includes' : 'excludes'}
					defaultValue="includes"
					onChange={(e) => {
						table.options.meta?.updateData?.(row.index, column.id!, {
							madeForRegions: {
								isInclude: e.target.value === 'includes',
								madeForRegions: row.original.madeForRegions?.madeForRegions || [],
							},
						});
					}}
				/>
			}
			backgroundColor="transparent"
			withSearch
			onChange={(values) => {
				table.options.meta?.updateData?.(row.index, column.id!, {
					madeForRegions: {
						isInclude: row.original.madeForRegions?.isInclude,
						madeForRegions: values,
					},
				});
			}}
		/>
	</CellWrapper>
));
