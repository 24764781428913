import { FeatureConfig } from '@warehouse/shared/config';
import { singletonFactory } from '@warehouse/shared/util';
import { FeatureFlagRepository } from '@warehouse/shared/core';
import tenantFeatureVisibilityConfig from '../config/tenant-visibility-config/tenant-visibility-config.json';

class FeatureFlagRepositoryImpl implements FeatureFlagRepository {
	private _config: FeatureConfig = tenantFeatureVisibilityConfig;

	getConfig(): FeatureConfig {
		return this._config;
	}
}

export const featureFlagSingleton = singletonFactory<FeatureFlagRepository>({
	factory: () => new FeatureFlagRepositoryImpl(),
});
