import sanitizeHtml from 'sanitize-html';
import React from 'react';
import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

const EllipsisTypography = styled(Typography)(
	() => css`
		margin: 0;
		max-width: 350px;
		vertical-align: center;
	`,
);

export default function SummaryCell({ summary }: { summary: string | undefined | null }) {
	if (!summary) return null;

	return (
		<EllipsisTypography noWrap paragraph variant="tableCell">
			{sanitizeHtml(summary, { allowedTags: [] })}
		</EllipsisTypography>
	);
}
