import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Tab, Tabs, useTheme } from '@mui/material';
import { useURLFragment } from '../../utils/hooks/useURLFragment';
import { UnsavedChangesContext } from '../../views/title/contexts/UnsavedChangesContext';

interface TitleTabProps {
	tabs: {
		label: string;
		component: React.ReactNode;
		urlState: string;
		defaultUrlState?: string;
		specialTabComponent?: React.ReactNode;
		disabled?: boolean;
	}[];
	leftBottomActions?: React.ReactNode;
	leftColumnWidth?: number;
}

const Wrapper = styled.div<{ width?: number }>(
	({ width }) => css`
		display: flex;
		flex: 1;
		height: 100%;

		${width &&
		css`
			width: ${width}px;
		`}
	`,
);

const LeftColumn = styled.div<{ width?: number }>(
	({ theme, width }) => css`
		border-right: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 220px;
		${width &&
		css`
			width: ${width}px;
		`}
	`,
);

const CustomTab = styled(Tab)(
	({ theme }) => css`
		align-items: flex-start;
		display: flex;
		height: 56px;
		justify-content: center;
		text-align: left;

		text-transform: none;

		&.Mui-selected {
			color: ${theme.palette.blue.main} !important;
		}

		&.MuiTouchRipple-root {
			color: ${theme.palette.text.secondary} !important;
		}
	`,
);

const RightColumn = styled.div(
	() => css`
		flex: 1;
		width: 0;
	`,
);

const CustomTabWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: row;
		gap: ${theme.spacing(1)};
		justify-content: space-between;
	`,
);

function SideMenuTabs({ tabs, leftBottomActions, leftColumnWidth }: TitleTabProps) {
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	const theme = useTheme();
	const [fragment, setFragment] = useURLFragment();
	const [value, setValue] = useState<number>(0);

	const handleChange = (_: React.SyntheticEvent | null, tabIndex: number) => {
		if (tabIndex === value) return;
		if (!tabs[tabIndex]) return;
		setFragment(tabs[tabIndex].defaultUrlState || tabs[tabIndex].urlState);
	};

	useEffect(() => {
		if (!fragment) {
			setFragment(tabs[0]?.urlState);
			return;
		}
		const index = tabs.findIndex((tab) => fragment.startsWith(tab.urlState));
		if (index >= 0) setValue(index);
	}, [fragment]);

	return (
		<Wrapper>
			<LeftColumn width={leftColumnWidth}>
				<div>
					<Tabs
						TabIndicatorProps={{
							style: { backgroundColor: theme.palette.blue.main },
							sx: {
								left: 0,
							},
						}}
						value={value}
						onChange={handleChange}
						orientation="vertical"
					>
						{tabs.map((tab, index) => (
							<CustomTabWrapper
								key={tab.label}
								id={`simple-tab-${index}`}
								aria-controls={`simple-tabpanel-${index}`}
								onClick={() => {
									if (!tab.disabled) {
										promptUserIfUnsavedChanges(() => handleChange(null, index));
									}
								}}
							>
								<CustomTab
									disabled={tab.disabled}
									label={tab.label}
									id={`simple-tab-${index}`}
									aria-controls={`simple-tabpanel-${index}`}
								/>
								{tab.specialTabComponent}
							</CustomTabWrapper>
						))}
					</Tabs>
				</div>
				<div>{leftBottomActions}</div>
			</LeftColumn>
			<RightColumn>
				{tabs.map((tab, index) => (
					<div
						key={tab.label}
						role="tabpanel"
						hidden={value !== index}
						id={`simple-tabpanel-${index}`}
						aria-labelledby={`simple-tab-${index}`}
						style={{ height: '100%' }}
					>
						{value === index && tab.component}
					</div>
				))}
			</RightColumn>
		</Wrapper>
	);
}

export default SideMenuTabs;
