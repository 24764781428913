export function transformEmptyStrings(input: unknown): unknown {
	if (input === null || input === undefined) {
		return input;
	}

	if (typeof input === 'string') {
		return input === '' ? null : input;
	}

	const cloned = structuredClone(input);

	return transformEmptyStringsRecursive(cloned);
}

function transformEmptyStringsRecursive(input: any): unknown {
	if (typeof input === 'string') {
		return input === '' ? null : input;
	}

	// If it's an object (including arrays), recurse through its properties and transform empty strings to null
	if (typeof input === 'object' && input !== null) {
		Object.entries(input).forEach(([key, value]) => {
			// eslint-disable-next-line no-param-reassign
			input[key] = transformEmptyStringsRecursive(value);
		});
	}

	return input;
}
