import React from 'react';
import { useTitle } from '@warehouse/title/domain';
import { CompObjRelationshipEditorAdapter } from './CompObjRelationshipEditor.adapter';
import { LoaderWrapper } from '../../../src/components/library/LoaderWrapper';

export function CompObjRelationshipEditorLoader({
	childUuid,
	parentUuid,
	onClose,
}: CompObjRelationshipEditorLoaderProps) {
	const childQueryOutput = useTitle({ uuid: childUuid });
	const parentQueryOutput = useTitle({ uuid: parentUuid });

	if (!childQueryOutput.data || !parentQueryOutput.data) {
		return (
			<div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<LoaderWrapper loading opacity={100} />
			</div>
		);
	}

	return (
		<CompObjRelationshipEditorAdapter
			childUuid={childUuid}
			childData={childQueryOutput.data}
			parentData={parentQueryOutput.data}
			onClose={onClose}
		/>
	);
}

interface CompObjRelationshipEditorLoaderProps {
	childUuid: string;
	parentUuid: string;
	onClose: (force?: boolean) => void;
}
