import React from 'react';
import { DialogContent, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { replaceUUID } from '@warehouse/shared/util';
import Dialog from '../../../components/library/Dialog';
import DialogTitle, { CloseButton } from '../../../components/library/DialogTitle';
import Alert from '../../../components/library/Alert';
import Button from '../../../components/library/Button';
import { isValidUUID } from '../../title/hooks/useTitleRecordsAutoSave/uuidUtils';

function ErrorMessage({ rowIndex, reason }: { rowIndex: number; reason: any }) {
	return (
		<li>
			<p>Row: {rowIndex + 1}</p>
			<ul>
				{reason?.graphQLErrors?.map((error: any) => (
					<li>
						{formatPath(error?.errorInfo?.path)}: {replaceUUID(error?.message)}
					</li>
				))}
			</ul>
		</li>
	);
}

export function TitleCreationSummary({
	open,
	handleClose,
	errorNumber,
	successNumber,
	creationResults,
	createdFromTitle,
}: TitleCreationSummaryProps) {
	const navigate = useNavigate();

	return (
		<Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
			<DialogTitle>
				Title Creation Summary
				<CloseButton onClose={handleClose} />
			</DialogTitle>
			<DialogContent>
				<ContentWrapper>
					{successNumber > 0 && (
						<Alert style={{ margin: 0 }} severity="success" title={`${successNumber} Titles Created`}>
							You&apos;ve successfully created {successNumber} titles.
						</Alert>
					)}
					{errorNumber > 0 && (
						<Alert style={{ margin: 0 }} severity="error" title={`${errorNumber} Titles Failed`}>
							There were errors with {errorNumber} of the titles you tried to create.
							<ErrorList>
								{(
									creationResults
										.map((_, rowIndex) => ({
											..._,
											rowIndex,
										}))
										.filter((result) => result.status === 'rejected') as (PromiseRejectedResult & {
										rowIndex: number;
									})[]
								).map((result, rejectedIndex) => (
									<ErrorMessage
										rowIndex={result.rowIndex}
										key={`${result.reason?.message}${rejectedIndex}`}
										reason={result.reason}
									/>
								))}
							</ErrorList>
						</Alert>
					)}
					<Typography variant="s2Regular" color="text.primary">
						You can view created titles through the title summary screen. To view errors and make changes, go back to
						Title Creation and adjust your inputs.
					</Typography>
				</ContentWrapper>
				<ActionWrapper>
					<Button nxstyle="tertiary-light" onClick={handleClose}>
						Back to Title Creation
					</Button>
					<Button
						nxstyle="primary-blue"
						onClick={() => {
							navigate(-1);
						}}
					>
						<span>{createdFromTitle ? 'Return to Title' : 'Go to Summary Screen'}</span>
					</Button>
				</ActionWrapper>
			</DialogContent>
		</Dialog>
	);
}

interface TitleCreationSummaryProps {
	open: boolean;
	handleClose: () => void;
	errorNumber: number;
	successNumber: number;
	creationResults: PromiseSettledResult<any>[];
	createdFromTitle: boolean;
}

function formatPath(path: string | undefined): string | undefined {
	if (!path) return undefined;
	return path
		.split('.')
		.filter((part) => !isValidUUID(part))
		.map((part) => part.replace(/([A-Z])/g, ' $1'))
		.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
		.join(' > ');
}

const ContentWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		padding: ${theme.spacing(2)};
	`,
);

const ActionWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(2)};
		justify-content: center;
		margin-bottom: ${theme.spacing(2)};
		margin-top: ${theme.spacing(4)};
		padding: ${theme.spacing(0, 2)};

		.MuiButton-root {
			width: 100%;
		}

		.MuiButton-root:first-child {
			border: solid 1px ${theme.palette.light.backgroundAlt};
		}
	`,
);

const ErrorList = styled.ul(
	() => css`
		max-height: 150px !important;
		overflow: auto;
	`,
);
