import React, { useState, useEffect } from 'react';
import { WorkType } from '@warehouse/title/core';
import { getWorkTypeObject } from '@warehouse/title/infra';
import { DropdownV2 } from '@warehouse/shared/ui';

type WorkTypeSelectorProps = EnabledWorkTypeSelectorProps | DisabledWorkTypeSelectorProps;

interface DisabledWorkTypeSelectorProps extends Partial<Omit<EnabledWorkTypeSelectorProps, 'disabled'>> {
	disabled: true;
	selected?: WorkType;
}

interface EnabledWorkTypeSelectorProps {
	ariaLabel?: string;
	disabled?: false;
	supportedWorkTypes: WorkType[];
	selected?: WorkType;
	onSelect: (workType: WorkType) => void;
}

export function WorkTypeSelector({
	supportedWorkTypes,
	selected,
	onSelect,
	disabled,
	ariaLabel = 'Title Type',
}: WorkTypeSelectorProps) {
	const [localSelected, setLocalSelected] = useState<WorkType | undefined>(selected);

	// Synchronize localSelected with selected so that the external state always takes precedence
	useEffect(() => {
		setLocalSelected(selected);
	}, [selected]);

	if (disabled) {
		return (
			<div style={{ width: '180px' }}>
				<DropdownV2 height={40} disabled value={localSelected} placeholder="Title Type" ariaLabel={ariaLabel} />
			</div>
		);
	}
	const options = supportedWorkTypes.map((workType) => {
		const workTypeObject = getWorkTypeObject(workType);
		return {
			label: workTypeObject.label,
			value: workTypeObject.workType,
		};
	});

	const handleSelect = (value: string) => {
		// While not ideal, the cast here is valid because the string value is always selected from the supportedWorkTypes list and therefore a valid worktype enum value.
		const selectedWorkTypeObject = getWorkTypeObject(value as WorkType);
		setLocalSelected(selectedWorkTypeObject.workType);
		onSelect?.(selectedWorkTypeObject.workType);
	};

	return (
		<div style={{ width: '180px' }}>
			<DropdownV2
				height={40}
				options={options}
				value={localSelected}
				onSelect={handleSelect}
				placeholder="Title Type"
				ariaLabel={ariaLabel}
			/>
		</div>
	);
}
