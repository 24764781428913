const fieldsToValidateByTitleType = {
	Movie: ['titleType', 'title', 'titleLanguage', 'workTypeDetail', 'licensor'],
	Season: ['titleType', 'sequenceNumber', 'titleLanguage', 'parentTitle'],
	Series: ['titleType', 'title', 'titleLanguage', 'licensor'],
	Edit: ['titleType', 'parentTitle'],
	Episode: ['titleType', 'titleLanguage', 'parentTitle', 'workTypeDetail', 'licensor', 'sequenceNumber'],
	Compilation: ['titleType', 'title', 'titleLanguage', 'workTypeDetail', 'licensor'],
	Supplemental: ['titleType', 'title', 'titleLanguage', 'workTypeDetail', 'licensor'],
};

export default fieldsToValidateByTitleType;
