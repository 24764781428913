import React from 'react';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { Header } from '@tanstack/react-table';

import { DropdownV2 } from '@warehouse/shared/ui';
import { DropDownButton, SubHeaderTH, CustomPopover } from '../style';
import { CustomColumnDef } from '../customColumnDef';

interface DefaultDropdownProps {
	handleDropdownButton: (columnId: string, anchorEl?: HTMLButtonElement) => void;
	openDropdownButton: { [key: string]: boolean };
	header: Header<any, any>;
	values: { [key: string]: string[] };
	popperRef: React.RefObject<HTMLDivElement>;
	anchorRef: HTMLButtonElement | null;
	handleSubHeaderDropdownSelect: (columnId: string, value: string) => void;
}

function SubHeaderMultiSelectDropdown({
	handleDropdownButton,
	openDropdownButton,
	header,
	values,
	popperRef,
	anchorRef,
	handleSubHeaderDropdownSelect,
}: DefaultDropdownProps) {
	return (
		<SubHeaderTH style={{ textTransform: 'none' }}>
			<DropDownButton
				disableRipple
				endIcon={<Icon path={mdiChevronDown} size={1} />}
				onClick={(e) =>
					handleDropdownButton(header.column.columnDef.id as string, e.currentTarget as HTMLButtonElement)
				}
				$isOpen={openDropdownButton[header.column.columnDef.id as string] || false}
			>
				{(values[header.column.columnDef.id as string] || []).length === 0
					? 'Set default'
					: `${(values[header.column.columnDef.id as string] || []).length} selected`}
			</DropDownButton>
			<CustomPopover
				ref={popperRef}
				open={openDropdownButton[header.column.columnDef.id as string] || false}
				anchorEl={anchorRef}
				placement="bottom-start"
			>
				<DropdownV2
					getPopupContainer={() => popperRef.current ?? document.body}
					options={(header.column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
					withSearch
					mode="multiple"
					forceOpen
					value={values[header.column.columnDef.id as string] || []}
					onSelect={(v) => handleSubHeaderDropdownSelect(header.column.columnDef.id as string, v)}
					onDeselect={(v) => handleSubHeaderDropdownSelect(header.column.columnDef.id as string, v)}
				/>
			</CustomPopover>
		</SubHeaderTH>
	);
}

export default SubHeaderMultiSelectDropdown;
