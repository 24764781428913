import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { ContentIdentifier } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/ContentIdentifier';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

// LIBRARY
import { DropdownV2 } from '@warehouse/shared/ui';

// JSON
import availContentIDNS from '../../../../../../../assets/json-administration-profiles/availableContentIDNamespaces.json';
import { CellWrapper } from '../../../../../../../components/library/SimpleTable/style';

export default function CellNamespace({
	getValue,
	table,
}: CellContext<Inherited<ContentIdentifier>, Inherited<ContentIdentifier>>) {
	const { displayValue } = getValue();
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;

	return (
		<CellWrapper>
			<DropdownV2
				value={displayValue.namespace}
				onChange={(value) => {
					editRecord({
						...getValue(),
						displayValue: {
							...getValue().displayValue,
							namespace: value,
						},
					});
				}}
				options={availContentIDNS}
				transparentBorder
				backgroundColor="transparent"
			/>
		</CellWrapper>
	);
}
