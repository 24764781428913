import React, { useMemo, useState } from 'react';
import Dropdown from '../../library/Dropdown/Dropdown';
import { PaginatedVirtualizedQueryHook } from '../../../utils/table/filtersUtils';
import useDebounce from '../../../utils/hooks/useDebounce';

interface BackendSearchDropdownProps<T> {
	query: PaginatedVirtualizedQueryHook;
	setValue: (value: string | null) => void;
	value: string;
	queryKey: string;
	documentsMapFn: (
		item: T,
		index: number,
	) => {
		value: string;
		label: string;
	};
	searchKey: string;
	defaultFiltering?: FilterStep;
}

const PER_PAGE = 30;

function BackendSearchDropdown<T>({
	value,
	setValue,
	query,
	queryKey,
	documentsMapFn,
	searchKey,
	defaultFiltering,
}: BackendSearchDropdownProps<T>) {
	const [searchValue, setSearchValue] = useState<string>('');
	const debouncedSearchValue = useDebounce(searchValue, 500);
	const { data, fetchMore, loading } = query({
		pollInterval: 10000,
		perPage: PER_PAGE,
		searchValue: debouncedSearchValue,
		...(defaultFiltering
			? {
					filtering: defaultFiltering,
			  }
			: {}),
	});

	const filtering: FilterStep = useMemo(
		() => ({
			step: {
				combinationOperator: 'AND',
				filters: [
					...(searchValue
						? [
								{
									field: searchKey,
									operator: 'contains' as keyof FilterOperators,
									value: debouncedSearchValue,
								},
						  ]
						: []),
					{
						field: searchKey,
						operator: 'isDefined' as keyof FilterOperators,
						value: true,
					},
				],
			},
		}),
		[debouncedSearchValue],
	);

	const handleLoadMore = () => {
		if (!loading && fetchMore) {
			return fetchMore({
				variables: {
					search: {
						pagination: {
							page: (data?.[queryKey]?.page?.currentPage || 0) + 1,
							perPage: PER_PAGE,
						},
						...(defaultFiltering
							? {
									filters: JSON.stringify({
										step: {
											combinationOperator: 'AND',
											filters: [defaultFiltering, filtering],
										},
									}),
							  }
							: {
									filters: JSON.stringify(filtering),
							  }),
					},
				},
				updateQuery: (prev: any, { fetchMoreResult }: any) => {
					if (!fetchMoreResult) return prev;
					return {
						...fetchMoreResult,
						[queryKey]: {
							...fetchMoreResult?.[queryKey],
							documents: [...(prev?.[queryKey]?.documents || []), ...(fetchMoreResult?.[queryKey]?.documents || [])],
						},
					};
				},
			});
		}

		return Promise.resolve();
	};

	return (
		<div>
			<Dropdown
				value={value}
				enablePortal
				height={40}
				withSearch
				onChange={(v) => setValue(v)}
				options={data?.[queryKey]?.documents?.map(documentsMapFn) || []}
				backendSearch={{
					searchValue,
					setSearchValue,
				}}
				infiniteLoaderProps={{
					itemCount: data?.[queryKey]?.page.totalDocument || 0,
					loadMoreItems: loading ? () => {} : handleLoadMore,
				}}
			/>
		</div>
	);
}

export default BackendSearchDropdown;
