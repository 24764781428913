import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { TitleFull } from '@warehouse/title/core';
import ParentField from '../fields/ParentField';

interface PerformanceProps {
	workType: string;
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	watch: UseFormWatch<any>;
	errors: FieldErrors<any>;
	parentTitle?: {
		title: IndexedTitleV2 | undefined;
		parentType: string | undefined;
	};
	selectedParentTitle?: TitleFull;
}

function Performance({
	workType,
	control,
	errors,
	setValue,
	trigger,
	parentTitle,
	watch,
	selectedParentTitle,
}: PerformanceProps) {
	const licensor = watch('licensor');

	return (
		<div>
			<ParentField
				control={control}
				setValue={setValue}
				trigger={trigger}
				errors={errors}
				workType={workType}
				licensor={licensor}
				parentTitle={parentTitle}
				selectedParentTitle={selectedParentTitle}
				isRequired
			/>
		</div>
	);
}

export default Performance;
