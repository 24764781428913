import { isInherited, TitleFull as Title } from '@warehouse/title/core';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { getTitleData } from '../../../utils/titleGetProperty';

function getTiniestSummary(title: Title | undefined): {
	data: OptionalInherited<string> | undefined;
	isInherited: boolean;
} {
	const jsonPaths = ['metadata', 'coreMetadata', 'basic', 'localizedInfos', '__default'];

	const summary190 = getTitleData<OptionalInherited<string>>(title, [...jsonPaths, 'summary190']);

	const summary400 = getTitleData<OptionalInherited<string>>(title, [...jsonPaths, 'summary400']);

	const summary4000 = getTitleData<OptionalInherited<string>>(title, [...jsonPaths, 'summary4000']);

	if (summary400?.displayValue?.length)
		return {
			data: summary400,
			isInherited: isInherited<string>({ inheritedObject: summary400 }).isInherited,
		};
	if (summary190?.displayValue?.length)
		return {
			data: summary190,
			isInherited: isInherited<string>({ inheritedObject: summary190 }).isInherited,
		};
	return {
		data: summary4000,
		isInherited: isInherited<string>({ inheritedObject: summary4000 }).isInherited,
	};
}

function useTiniestSummaryTitleData({ title }: { title: Title | undefined }) {
	return getTiniestSummary(title);
}

export default useTiniestSummaryTitleData;
