import React from 'react';
import { Header } from '@tanstack/react-table';
import { SubHeaderTH } from '../style';
import TextInput, { TextInputProps } from '../../TextInput';

export interface SubHeaderStringProps {
	value: string;
	header: Header<any, any>;
	handleSubleheaderValueChange: (id: string, value: string) => void;
	textInputProps?: TextInputProps;
}

function SubHeaderString({ value, header, handleSubleheaderValueChange, textInputProps }: SubHeaderStringProps) {
	return (
		<SubHeaderTH noPadding style={{ textTransform: 'none' }}>
			<TextInput
				type="text"
				value={value}
				onChange={(e) => {
					handleSubleheaderValueChange(header.column.columnDef.id as string, e.target.value);
				}}
				height={32}
				{...textInputProps}
			/>
		</SubHeaderTH>
	);
}

export default SubHeaderString;
