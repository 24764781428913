import React, { useMemo, useState } from 'react';
import { Skeleton, Typography } from '@mui/material';
import styled from 'styled-components';
import SmoothImage from '../../common/SmoothImage';
import usePoster from '../../../utils/hooks/poster/usePoster';

const Wrapper = styled.div`
	height: 100%;
	position: relative;
	width: 100%;

	.skeleton {
		aspect-ratio: 0.675; // Most common aspect ratio for posters
		border-radius: 8px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	img {
		height: 100%;
		position: absolute;
		left: 50%;
		top: 0;
		border-radius: 8px;
		transform: translateX(-50%);
	}

	.not-avail {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const NotAvailWrapper = styled(Typography)`
	opacity: 0.5;
`;

const OpacifiedWrapper = styled.div<{ opacity: number }>(
	({ opacity }) => `
    position: absolute;
    top: 0;
    left: 0;
    
    height: 100%;
    opacity: ${opacity};
    transition: opacity 0.5s;
    width: 100%;
`,
);

interface PosterProps {
	posterQuery?: ReturnType<typeof usePoster>;
}

function Poster({ posterQuery }: PosterProps) {
	const [isLoading, setIsLoading] = useState(true);

	const hasData = useMemo(
		() => !posterQuery?.loading && typeof posterQuery?.data !== 'undefined' && posterQuery.data.poster !== null,
		[posterQuery],
	);

	const posterNotAvailable = useMemo(
		() => <NotAvailWrapper variant="bodySmallRegular">Poster not available</NotAvailWrapper>,
		[],
	);

	const handleLoad = () => {
		setIsLoading(false);
	};

	if (!posterQuery) return posterNotAvailable;
	return (
		<Wrapper>
			<OpacifiedWrapper opacity={isLoading ? 1 : 0}>
				<Skeleton
					className="skeleton"
					variant="rectangular"
					height="100%"
					style={{ borderRadius: '8px' }}
					animation={(!isLoading && !hasData) || posterQuery?.data?.poster === null ? false : 'pulse'}
				/>
			</OpacifiedWrapper>
			<OpacifiedWrapper
				className="not-avail"
				opacity={(!isLoading && !hasData) || posterQuery?.data?.poster === null ? 1 : 0}
			>
				{posterNotAvailable}
			</OpacifiedWrapper>
			<SmoothImage transitionTime={500} src={posterQuery.data?.poster} onLoad={handleLoad} alt="Poster" />
		</Wrapper>
	);
}

export default Poster;
