import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { DropdownV2 } from '@warehouse/shared/ui';
import { CellWrapper } from '../../../../src/components/library/SimpleTable/style';
import { PartialDisplayName } from '../comp-obj-relationship.model';
import { CustomCellProps } from './DisplayNamesTable.props';

interface CellLanguageProps {
	language: CellContext<PartialDisplayName, string>;
}

export function CellLanguage({ language }: CellLanguageProps) {
	const { updateDisplayNameLanguage, commitChanges, languageOptions } = language.table.options.meta!
		.customPropsCell as CustomCellProps;

	return (
		<CellWrapper>
			<DropdownV2
				transparentBorder
				backgroundColor="transparent"
				options={languageOptions}
				value={language.getValue()}
				withSearch
				onChange={(e) => {
					updateDisplayNameLanguage(e, language.row.index);
					commitChanges();
				}}
			/>
		</CellWrapper>
	);
}
