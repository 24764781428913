import { ContentIdentifier } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/ContentIdentifier';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';
import { WithUuid } from '../../../../../hooks/useTitleRecordsAutoSave/recordToArray';

export default function computeError(contentIdentifiers: Inherited<WithUuid<ContentIdentifier>>[]): {
	[columnId: string]: number[];
} {
	const result: Record<'identifier' | 'namespace', number[]> = {
		identifier: [],
		namespace: [],
	};
	contentIdentifiers.forEach((contentIdentifier, idx) => {
		if (contentIdentifier.displayValue.identifier === undefined || contentIdentifier.displayValue.identifier === '') {
			result.identifier.push(idx);
		}
		if (contentIdentifier.displayValue.namespace === undefined || contentIdentifier.displayValue.namespace === '') {
			result.namespace.push(idx);
		}
	});
	return result;
}
