import React from 'react';
import { CompilationObjectEntry } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/TitleMetadata';
import { TitleFull } from '@warehouse/title/core';
import useTitleAutoSave from '../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../../../src/views/title/hooks/useMemoJsonPath';
import { LoaderWrapper } from '../../../src/components/library/LoaderWrapper';
import {
	adapt,
	addEmptyDisplayNameInCompilationEntries,
	removeDisplayNameInCompilationEntries,
	updateCompObjEntriesDelete,
	updateDisplayNameDisplayNameInCompilationEntries,
	updateDisplayNameLanguageInCompilationEntries,
	updateEntryClassInCompilationEntries,
	updateEntryNumberInCompilationEntries,
} from './comp-obj-relationship.adapter';
import { CompObjRelationshipEditor } from './CompObjRelationshipEditor';

export function CompObjRelationshipEditorAdapter({
	childUuid,
	childData,
	parentData,
	onClose,
}: CompObjRelationshipEditorAdapterProps) {
	const {
		value: compilationEntries,
		setValue: setCompilationEntries,
		commit,
	} = useTitleAutoSave<CompilationObjectEntry[]>({
		path: useMemoJsonPath(['metadata', 'coreMetadata', 'compilationObject', 'compilationEntries']),
		label: 'Compilation entry',
		isInheritable: false,
	});

	// UseTitleAutoSave can return undefined on the first render
	if (!compilationEntries) {
		return (
			<div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<LoaderWrapper loading opacity={100} />
			</div>
		);
	}

	// prettier-ignore
	const {
		childTitle,
		parentTitle,
		entryClass,
		entryNumber,
		displayNames,
		defaultLanguageId
	} = adapt({
		childUuid,
		compilationEntries,
		childData,
		parentData,
	});

	const updateEntryNumber = (newEntryNumber: string | undefined) => {
		setCompilationEntries(updateEntryNumberInCompilationEntries(newEntryNumber, childUuid, compilationEntries));
	};

	const updateEntryClass = (newEntryClass: string) => {
		setCompilationEntries(updateEntryClassInCompilationEntries(newEntryClass, childUuid, compilationEntries));
	};

	const addEmptyDisplayName = (languageId?: string) => {
		setCompilationEntries(addEmptyDisplayNameInCompilationEntries(childUuid, compilationEntries, languageId));
	};

	const removeDisplayNames = (displayNameIndexes: number[]) => {
		setCompilationEntries(removeDisplayNameInCompilationEntries(displayNameIndexes, childUuid, compilationEntries));
	};

	const updateDisplayNameDisplayName = (newDisplayName: string | undefined, displayNameIndex: number) => {
		setCompilationEntries(
			updateDisplayNameDisplayNameInCompilationEntries(newDisplayName, displayNameIndex, childUuid, compilationEntries),
		);
	};

	const updateDisplayNameLanguage = (newLanguage: string | undefined, displayNameIndex: number) => {
		setCompilationEntries(
			updateDisplayNameLanguageInCompilationEntries(newLanguage, displayNameIndex, childUuid, compilationEntries),
		);
	};

	const deleteChild = () => {
		setCompilationEntries(updateCompObjEntriesDelete(childUuid, compilationEntries));
		commit();
		onClose(true);
	};

	return (
		<CompObjRelationshipEditor
			childTitle={childTitle}
			parentTitle={parentTitle}
			entryClass={entryClass}
			entryNumber={entryNumber}
			displayNames={displayNames}
			defaultLanguageId={defaultLanguageId}
			updateEntryNumber={updateEntryNumber}
			updateEntryClass={updateEntryClass}
			addEmptyDisplayName={addEmptyDisplayName}
			removeDisplayNames={removeDisplayNames}
			updateDisplayNameDisplayName={updateDisplayNameDisplayName}
			updateDisplayNameLanguage={updateDisplayNameLanguage}
			deleteChild={deleteChild}
			commitChanges={commit}
		/>
	);
}

interface CompObjRelationshipEditorAdapterProps {
	childUuid: string;
	childData: TitleFull;
	parentData: TitleFull;
	onClose: (force?: boolean) => void;
}
