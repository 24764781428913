import {
	isDerivedFrom,
	isEpisodeOf,
	isSeasonOf,
	isBasedOn,
	isClipOf,
	isCompositeOf,
	isDescendentOf,
	isPromotionFor,
	isSupplementTo,
	isEntryOf,
	RelationshipType,
	RelationshipTypeObject,
} from '@warehouse/title/core';
import relationships from '../../../src/assets/json-administration-profiles/availableRelationshipTypes.json';

export function getRelationshipType(relationshipTypeId: string): RelationshipType {
	switch (relationshipTypeId) {
		case isBasedOn():
			return RelationshipType.BasedOn;
		case isClipOf():
			return RelationshipType.ClipOf;
		case isCompositeOf():
			return RelationshipType.CompositeOf;
		case isDerivedFrom():
			return RelationshipType.DerivedFrom;
		case isDescendentOf():
			return RelationshipType.DescendentOf;
		case isEpisodeOf():
			return RelationshipType.EpisodeOf;
		case isPromotionFor():
			return RelationshipType.PromotionFor;
		case isSeasonOf():
			return RelationshipType.SeasonOf;
		case isSupplementTo():
			return RelationshipType.SupplementTo;
		case isEntryOf():
			return RelationshipType.EntryOf;
		default:
			throw new Error(`Unknown relationship type "${relationshipTypeId}"`);
	}
}

export function getRelationshipTypeObject(relationshipType: RelationshipType): RelationshipTypeObject {
	const relationshipTypeId = getRelationshipTypeId(relationshipType);
	const label = getRelationshipTypeLabel(relationshipTypeId);
	const labelTarget = getRelationshipTypeLabelTarget(relationshipTypeId);
	return {
		relationshipType,
		relationshipTypeId,
		label,
		labelTarget,
	};
}

/**
 @deprecated Use getRelationshipTypeObject instead
 */
export function getRelationshipTypeLabel(relationshipTypeId: string): string {
	if (relationshipTypeId === 'isentryof') return 'Entry of';
	const label = relationships.find((relationship) => relationship.value === relationshipTypeId)?.label;
	if (!label)
		throw new Error(`No label found in availableRelationships JSON for relationship type ${relationshipTypeId}`);
	return label;
}

function getRelationshipTypeLabelTarget(relationshipTypeId: string): string {
	if (relationshipTypeId === 'isentryof') return 'Entry';
	const label = relationships.find((relationship) => relationship.value === relationshipTypeId)?.labelTarget;
	if (!label)
		throw new Error(`No label target found in availableRelationships JSON for relationship type ${relationshipTypeId}`);
	return label;
}

function getRelationshipTypeId(relationshipType: RelationshipType): string {
	switch (relationshipType) {
		case RelationshipType.BasedOn:
			return 'isbasedon';
		case RelationshipType.ClipOf:
			return 'isclipof';
		case RelationshipType.CompositeOf:
			return 'iscompositeof';
		case RelationshipType.DerivedFrom:
			return 'isderivedfrom';
		case RelationshipType.DescendentOf:
			return 'isdescendentof';
		case RelationshipType.EpisodeOf:
			return 'isepisodeof';
		case RelationshipType.PromotionFor:
			return 'ispromotionfor';
		case RelationshipType.SeasonOf:
			return 'isseasonof';
		case RelationshipType.SupplementTo:
			return 'issupplementto';
		case RelationshipType.EntryOf:
			return 'isentryof';
		default:
			throw new Error(`Unknown relationship type ${relationshipType}`);
	}
}
