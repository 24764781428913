import { Release } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';

export default function computeErrors(releases: Release[]): {
	[columnId: string]: number[];
} {
	const result: { [columnId: string]: number[] } = {
		territories: [],
		type: [],
		date: [],
	};

	releases?.forEach((release, index) => {
		if (!release.distrTerritories || !release.distrTerritories.length) result.territories.push(index);
		if (!release.releaseType) result.type.push(index);
		if (!release.date) result.date.push(index);
	});

	return result;
}
